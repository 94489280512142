import { FullPageError } from 'components/app-ui/FullPageError';
import { Modal } from 'components/core/Modal';
import { OpenfundContext } from 'contexts/Openfund';
import { identity } from 'deso-protocol';
import { useIsMounted } from 'hooks/useIsMounted';
import { useContext, useEffect, useState } from 'react';
import { Button } from '../core/Button';

export function NoDesoModal(props: { isOpen: boolean; onClose: () => void }) {
  const { currentUser } = useContext(OpenfundContext);

  const isMounted = useIsMounted();
  const [loadingError, setLoadingError] = useState<any>(false);

  useEffect(() => {
    if (!isMounted) {
      return;
    }
    if (!currentUser) {
      setLoadingError('No current user');
      return;
    }
  }, [currentUser]);

  const modalBody = loadingError ? (
    <FullPageError error={loadingError} />
  ) : (
    <div className="flex" style={{ flexDirection: 'column' }}>
      <div>
        You can't post to the decentralized social network unless you have some starter DESO.
        <br />
        <br />
        Most features won't work without it.
      </div>
      <div className="flex pt-6">
        <Button kind="btn-tertiary" onClick={() => props.onClose()} className="mr-6">
          Skip (not advised)
        </Button>
        <Button
          kind="btn-primary"
          onClick={() => {
            identity.getDeso();
            props.onClose();
          }}
        >
          Get Starter $DESO
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      theme="default"
      title="Get $DESO"
      width={550}
      description={modalBody}
      footer={undefined}
    />
  );
}
