import { ApolloProvider } from '@apollo/client';
import { DESO_IDENTITY_URL, MIN_FEE_RATE_NANOS_PER_KB, NODE_URL } from 'constants/AppConstants';
import { configure } from 'deso-protocol';
import 'globals.css';
import 'index.css';
import { createRoot } from 'react-dom/client';
import { AppRouter } from './components/AppRouter';
import { ThemeProvider } from './contexts/ThemeContext';
import { client } from './graphql/client';
import reportWebVitals from './reportWebVitals';

configure({
  spendingLimitOptions: {
    IsUnlimited: true,
  },
  nodeURI: new URL(NODE_URL).origin,
  identityURI: DESO_IDENTITY_URL,
  appName: 'openfund',
  MinFeeRateNanosPerKB: MIN_FEE_RATE_NANOS_PER_KB,
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ApolloProvider client={client}>
    <ThemeProvider>
      <AppRouter />
    </ThemeProvider>
  </ApolloProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
