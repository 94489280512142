import { createContext } from 'react';
import { OpenfundUser } from '../services/Openfund';

type IOpenfundContext = {
  currentUser: OpenfundUser | null;
  setCurrentUser: (currentUser: OpenfundUser | null) => void;
  diamondLevelMap: Record<string, number>;
};

export const OpenfundContext = createContext<IOpenfundContext>({
  currentUser: null,
  setCurrentUser: () => {},
  diamondLevelMap: {},
});
