import type { ReactNode } from 'react';
import { formatDecimalValue, formatUSD } from '../../utils/currency';
import { abbreviateNumber } from './USDValueWithDESO';
import { cn } from '../../utils/shadcn';

interface CoinPriceFormatterProps {
  price: number;
  className?: string;
  maxZeroDigits?: number;
  subClassName?: string;
  maxDecimalsDigitsAfterZero?: number;
  hasTooltip?: boolean;
  abbreviatePriceThreshold?: number;
  highPriceThreshold?: number;
  lowPriceThreshold?: number;
  isUsd?: boolean;
}

const trimAndRoundToSignificantDigits = (num: number, limit = 2): number => {
  if (num === 0) return 0;

  const divisor = Math.pow(10, Math.max(0, Math.floor(Math.log10(num)) - limit + 1));

  const rounded = Math.round(num / divisor);

  return rounded;
};

const LowNumFormatter = ({
  price,
  className = '',
  maxZeroDigits = 2,
  maxDecimalsDigitsAfterZero = 4,
  subClassName = '',
  hasTooltip = true,
  abbreviatePriceThreshold = 10000,
  highPriceThreshold = 1000,
  lowPriceThreshold = 1,
  isUsd = true,
}: CoinPriceFormatterProps) => {
  if (price >= abbreviatePriceThreshold) {
    return (
      <span className={cn('text-sm', className)}>
        {isUsd ? '$' : ''}
        {abbreviateNumber(price)}
      </span>
    );
  }

  const formatterFunction = isUsd
    ? (priceToFormat: number, numDecimals: number) => formatUSD(priceToFormat, true, numDecimals)
    : (priceToFormat: number, numDecimals: number) => formatDecimalValue(priceToFormat, numDecimals);

  if (price >= highPriceThreshold) {
    return <span className={cn('text-sm', className)}>{formatterFunction(price, 2)}</span>;
  }
  if (price >= lowPriceThreshold) {
    return <span className={cn('text-sm', className)}>{formatterFunction(price, 4)}</span>;
  }
  const fixedPrice = price.toFixed(20); // 20 is the maximum number of decimals that can be displayed in a number in JavaScript. This is done to avoid floating point errors.
  const trimmed = fixedPrice.toString().replace(/0+$/, '').replace(/\.$/, '');
  const decimalIndex = trimmed.indexOf('.');
  const significantIndex = trimmed.slice(decimalIndex + 1).search(/[^0]/);
  const digitsAfterSignificantIndex = trimmed.slice(decimalIndex + 1 + significantIndex).length;

  const renderFormattedPrice = (limit: number) => {
    return (
      <>
        {isUsd ? '$' : ''}
        {trimmed.slice(0, decimalIndex + 2)}
        <sub className={subClassName}>{significantIndex}</sub>
        {trimAndRoundToSignificantDigits(+trimmed.slice(significantIndex), limit).toString().padEnd(4, '0')}
      </>
    );
  };

  const formatPrice = (): ReactNode => {
    const maxNumAfterZerosLimit =
      digitsAfterSignificantIndex > maxDecimalsDigitsAfterZero
        ? maxDecimalsDigitsAfterZero
        : digitsAfterSignificantIndex;

    if (significantIndex > maxZeroDigits) {
      return renderFormattedPrice(maxNumAfterZerosLimit);
    } else {
      return <>{formatterFunction(+trimmed, significantIndex + maxNumAfterZerosLimit)}</>;
    }
  };

  return <div className={cn('inline-flex items-center text-sm', className)}>{formatPrice()}</div>;
};

export default LowNumFormatter;
