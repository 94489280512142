import { desoToUSD } from 'utils/currency';
import LowNumFormatter from './LowNumFormatter';

type USDValueWithDESOProps = {
  desoValue: number;
  showDESOValue?: boolean;
  showUSDLabel?: boolean;
  usdCentsPerDeSoExchangeRate: number;
  align?: 'left' | 'right';
};

export function USDValueWithDESO({
  showDESOValue = true,
  desoValue,
  usdCentsPerDeSoExchangeRate,
  showUSDLabel = true,
  align = 'left',
}: USDValueWithDESOProps) {
  return (
    <div className="ml-auto">
      <div className={`font-mono text-green-600 font-shadow-green ${align === 'right' ? 'text-right' : ''}`}>
        <LowNumFormatter price={desoToUSD(desoValue, usdCentsPerDeSoExchangeRate)} />
        {showUSDLabel && <span className="text-xs inline-block ml-1">USD</span>}
      </div>
      <div className={'flex' + (align === 'right' ? ' text-right' : '')}>
        {showDESOValue && (
          <span className={'text-xs text-muted' + (align === 'right' ? ' ml-auto' : '')}>
            <LowNumFormatter price={desoValue} isUsd={false} /> DESO
          </span>
        )}
      </div>
    </div>
  );
}

/*
 * Converts long numbers to convenient abbreviations with 3-4 digits of precision
 * Examples:
 *   value: 0.12345 => 0.123
 *   value: 1.2345  => 1.23
 *   value: 12.345  => 12.3
 *   value: 123.45  => 123
 *   value: 1234.5  => 1.23K
 *   value: 12345   => 12.3K
 *   value: 3492311 => 3.49M
 *   value: 3492311000 => 3.49B
 * */
function abbreviateBigNumber(value: number) {
  const scientific = value.toExponential(1); // Use 1 digit after the decimal
  return scientific.length <= 7 ? scientific : scientific.slice(0, 7); // Ensure <= 7 characters
}

export function abbreviateNumber(value: number): string {
  if (value === 0) {
    return '0';
  }

  if (value < 0) {
    return value.toString();
  }
  if (value < 0.01) {
    return value.toFixed(5);
  }
  if (value < 0.1) {
    return value.toFixed(4);
  }

  if (value > 1e20) {
    return abbreviateBigNumber(value);
  }

  let shortValue;
  const suffixes = ['', 'k', 'M', 'B', 'e12', 'e15', 'e18', 'e21'];
  const suffixNum = Math.floor((('' + value.toFixed(0)).length - 1) / 3);
  shortValue = value / Math.pow(1000, suffixNum);
  if (Math.floor(shortValue / 100) > 0 || shortValue / 1 === 0 || suffixNum > 3) {
    return shortValue.toFixed(0) + suffixes[suffixNum];
  }
  if (Math.floor(shortValue / 10) > 0 || Math.floor(shortValue) > 0) {
    return shortValue.toFixed(2) + suffixes[suffixNum];
  }
  return shortValue.toFixed(3) + suffixes[suffixNum];
}
