import { deso } from 'services';
import { isDesoPublicKey } from 'utils/deso';
import { cn } from '../../utils/shadcn';

const SIZES = Object.freeze({
  xxs: 'h-4 w-4',
  xs: 'h-6 w-6',
  sm: 'h-7 w-7',
  md: 'h-8 w-8 md:h-12 md:w-12 ',
  lg: 'h-10 w-10 md:h-14 md:w-14 ',
  xl: 'h-12 w-12 md:h-16 md:w-16 ',
});

const BORDER = Object.freeze({
  none: '',
  black: 'border border-black',
  white: 'border border-white',
});

type AvatarProps = { size?: 'sm' | 'md' | 'xs' | 'xxs' | 'lg' | 'xl'; border?: 'black' | 'white' | 'none' };
export function Avatar({
  size = 'sm',
  src = '/images/ghost-profile-image.png',
  alt,
  border = 'black',
  className,
  ...rest
}: AvatarProps & React.ImgHTMLAttributes<HTMLImageElement>) {
  const imgClasses = cn(SIZES[size], BORDER[border], 'inline-block rounded-full', className);

  return (
    <img
      {...{
        src: isDesoPublicKey(src) ? deso.profilePicUrl(src) : src,
        alt,
        border,
        loading: 'lazy',
        className: imgClasses,
        ...rest,
      }}
    />
  );
}
