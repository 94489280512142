/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: { input: any; output: any };
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: { input: any; output: any };
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: { input: any; output: any };
  /** The day, does not include a time. */
  Date: { input: any; output: any };
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: { input: any; output: any };
};

export type AccessGroup = Node & {
  __typename?: 'AccessGroup';
  accessGroupKeyName?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `AccessGroupMember`. */
  accessGroupMembers: AccessGroupMembersConnection;
  accessGroupOwnerPublicKey?: Maybe<Scalars['String']['output']>;
  accessGroupPublicKey?: Maybe<Scalars['String']['output']>;
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** Reads and enables pagination through a set of `FeedUserAssociation`. */
  feedAssociations: FeedUserAssociationsConnection;
  /** Reads and enables pagination through a set of `Message`. */
  groupMessagesReceived: MessagesConnection;
  /** Reads and enables pagination through a set of `Message`. */
  groupMessagesSent: MessagesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  /** Reads a single `Account` that is related to this `AccessGroup`. */
  owner?: Maybe<Account>;
  /** Reads and enables pagination through a set of `Payment`. */
  payments: PaymentsConnection;
  /** Reads and enables pagination through a set of `PostSubscriptionStat`. */
  postSubscriptionStats: PostSubscriptionStatsConnection;
  /** Reads and enables pagination through a set of `Post`. */
  subscriptionPosts: PostsConnection;
  /** Reads a single `SubscriptionTier` that is related to this `AccessGroup`. */
  subscriptionTier?: Maybe<SubscriptionTier>;
  /** Reads and enables pagination through a set of `Subscription`. */
  subscriptions: SubscriptionsConnection;
  /** Reads and enables pagination through a set of `UserAssociation`. */
  userAssociations: UserAssociationsConnection;
};

export type AccessGroupAccessGroupMembersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AccessGroupMemberCondition>;
  filter?: InputMaybe<AccessGroupMemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGroupMembersOrderBy>>;
};

export type AccessGroupFeedAssociationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedUserAssociationCondition>;
  filter?: InputMaybe<FeedUserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedUserAssociationsOrderBy>>;
};

export type AccessGroupGroupMessagesReceivedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

export type AccessGroupGroupMessagesSentArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

export type AccessGroupPaymentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PaymentCondition>;
  filter?: InputMaybe<PaymentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};

export type AccessGroupPostSubscriptionStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostSubscriptionStatCondition>;
  filter?: InputMaybe<PostSubscriptionStatFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostSubscriptionStatsOrderBy>>;
};

export type AccessGroupSubscriptionPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostCondition>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};

export type AccessGroupSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SubscriptionCondition>;
  filter?: InputMaybe<SubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>;
};

export type AccessGroupUserAssociationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

/**
 * A condition to be used against `AccessGroup` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AccessGroupCondition = {
  /** Checks for equality with the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `accessGroupPublicKey` field. */
  accessGroupPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
};

/** A filter to be used against `AccessGroup` object types. All fields are combined with a logical ‘and.’ */
export type AccessGroupFilter = {
  /** Filter by the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupMembers` relation. */
  accessGroupMembers?: InputMaybe<AccessGroupToManyAccessGroupMemberFilter>;
  /** Some related `accessGroupMembers` exist. */
  accessGroupMembersExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupPublicKey` field. */
  accessGroupPublicKey?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AccessGroupFilter>>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `feedAssociations` relation. */
  feedAssociations?: InputMaybe<AccessGroupToManyFeedUserAssociationFilter>;
  /** Some related `feedAssociations` exist. */
  feedAssociationsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `groupMessagesReceived` relation. */
  groupMessagesReceived?: InputMaybe<AccessGroupToManyMessageFilter>;
  /** Some related `groupMessagesReceived` exist. */
  groupMessagesReceivedExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `groupMessagesSent` relation. */
  groupMessagesSent?: InputMaybe<AccessGroupToManyMessageFilter>;
  /** Some related `groupMessagesSent` exist. */
  groupMessagesSentExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not?: InputMaybe<AccessGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AccessGroupFilter>>;
  /** Filter by the object’s `owner` relation. */
  owner?: InputMaybe<AccountFilter>;
  /** A related `owner` exists. */
  ownerExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `payments` relation. */
  payments?: InputMaybe<AccessGroupToManyPaymentFilter>;
  /** Some related `payments` exist. */
  paymentsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `postSubscriptionStats` relation. */
  postSubscriptionStats?: InputMaybe<AccessGroupToManyPostSubscriptionStatFilter>;
  /** Some related `postSubscriptionStats` exist. */
  postSubscriptionStatsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `subscriptionPosts` relation. */
  subscriptionPosts?: InputMaybe<AccessGroupToManyPostFilter>;
  /** Some related `subscriptionPosts` exist. */
  subscriptionPostsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `subscriptionTier` relation. */
  subscriptionTier?: InputMaybe<SubscriptionTierFilter>;
  /** A related `subscriptionTier` exists. */
  subscriptionTierExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `subscriptions` relation. */
  subscriptions?: InputMaybe<AccessGroupToManySubscriptionFilter>;
  /** Some related `subscriptions` exist. */
  subscriptionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `userAssociations` relation. */
  userAssociations?: InputMaybe<AccessGroupToManyUserAssociationFilter>;
  /** Some related `userAssociations` exist. */
  userAssociationsExist?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccessGroupMember = Node & {
  __typename?: 'AccessGroupMember';
  /** Reads a single `AccessGroup` that is related to this `AccessGroupMember`. */
  accessGroup?: Maybe<AccessGroup>;
  accessGroupKeyName: Scalars['String']['output'];
  accessGroupMemberKeyName: Scalars['String']['output'];
  accessGroupMemberPublicKey: Scalars['String']['output'];
  accessGroupOwnerPublicKey: Scalars['String']['output'];
  encryptedKey?: Maybe<Scalars['String']['output']>;
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  /** Reads a single `Account` that is related to this `AccessGroupMember`. */
  member?: Maybe<Account>;
  /** Reads a single `MessageThread` that is related to this `AccessGroupMember`. */
  messageThread?: Maybe<MessageThread>;
};

/**
 * A condition to be used against `AccessGroupMember` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AccessGroupMemberCondition = {
  /** Checks for equality with the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `accessGroupMemberKeyName` field. */
  accessGroupMemberKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `accessGroupMemberPublicKey` field. */
  accessGroupMemberPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `encryptedKey` field. */
  encryptedKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
};

/** A filter to be used against `AccessGroupMember` object types. All fields are combined with a logical ‘and.’ */
export type AccessGroupMemberFilter = {
  /** Filter by the object’s `accessGroup` relation. */
  accessGroup?: InputMaybe<AccessGroupFilter>;
  /** Filter by the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupMemberKeyName` field. */
  accessGroupMemberKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupMemberPublicKey` field. */
  accessGroupMemberPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AccessGroupMemberFilter>>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `member` relation. */
  member?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `messageThread` relation. */
  messageThread?: InputMaybe<MessageThreadFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AccessGroupMemberFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AccessGroupMemberFilter>>;
};

/** A connection to a list of `AccessGroupMember` values. */
export type AccessGroupMembersConnection = {
  __typename?: 'AccessGroupMembersConnection';
  /** A list of edges which contains the `AccessGroupMember` and cursor to aid in pagination. */
  edges: Array<AccessGroupMembersEdge>;
  /** A list of `AccessGroupMember` objects. */
  nodes: Array<Maybe<AccessGroupMember>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AccessGroupMember` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `AccessGroupMember` edge in the connection. */
export type AccessGroupMembersEdge = {
  __typename?: 'AccessGroupMembersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `AccessGroupMember` at the end of the edge. */
  node?: Maybe<AccessGroupMember>;
};

/** Methods to use when ordering `AccessGroupMember`. */
export enum AccessGroupMembersOrderBy {
  AccessGroupKeyNameAsc = 'ACCESS_GROUP_KEY_NAME_ASC',
  AccessGroupKeyNameDesc = 'ACCESS_GROUP_KEY_NAME_DESC',
  AccessGroupMemberKeyNameAsc = 'ACCESS_GROUP_MEMBER_KEY_NAME_ASC',
  AccessGroupMemberKeyNameDesc = 'ACCESS_GROUP_MEMBER_KEY_NAME_DESC',
  AccessGroupMemberPublicKeyAsc = 'ACCESS_GROUP_MEMBER_PUBLIC_KEY_ASC',
  AccessGroupMemberPublicKeyDesc = 'ACCESS_GROUP_MEMBER_PUBLIC_KEY_DESC',
  AccessGroupOwnerPublicKeyAsc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC',
  AccessGroupOwnerPublicKeyDesc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC',
  EncryptedKeyAsc = 'ENCRYPTED_KEY_ASC',
  EncryptedKeyDesc = 'ENCRYPTED_KEY_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** A filter to be used against many `AccessGroupMember` object types. All fields are combined with a logical ‘and.’ */
export type AccessGroupToManyAccessGroupMemberFilter = {
  /** Every related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AccessGroupMemberFilter>;
  /** No related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AccessGroupMemberFilter>;
  /** Some related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AccessGroupMemberFilter>;
};

/** A filter to be used against many `FeedUserAssociation` object types. All fields are combined with a logical ‘and.’ */
export type AccessGroupToManyFeedUserAssociationFilter = {
  /** Every related `FeedUserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FeedUserAssociationFilter>;
  /** No related `FeedUserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FeedUserAssociationFilter>;
  /** Some related `FeedUserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FeedUserAssociationFilter>;
};

/** A filter to be used against many `Message` object types. All fields are combined with a logical ‘and.’ */
export type AccessGroupToManyMessageFilter = {
  /** Every related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MessageFilter>;
  /** No related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MessageFilter>;
  /** Some related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MessageFilter>;
};

/** A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’ */
export type AccessGroupToManyPaymentFilter = {
  /** Every related `Payment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PaymentFilter>;
  /** No related `Payment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PaymentFilter>;
  /** Some related `Payment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PaymentFilter>;
};

/** A filter to be used against many `Post` object types. All fields are combined with a logical ‘and.’ */
export type AccessGroupToManyPostFilter = {
  /** Every related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostFilter>;
  /** No related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostFilter>;
  /** Some related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostFilter>;
};

/** A filter to be used against many `PostSubscriptionStat` object types. All fields are combined with a logical ‘and.’ */
export type AccessGroupToManyPostSubscriptionStatFilter = {
  /** Every related `PostSubscriptionStat` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostSubscriptionStatFilter>;
  /** No related `PostSubscriptionStat` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostSubscriptionStatFilter>;
  /** Some related `PostSubscriptionStat` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostSubscriptionStatFilter>;
};

/** A filter to be used against many `Subscription` object types. All fields are combined with a logical ‘and.’ */
export type AccessGroupToManySubscriptionFilter = {
  /** Every related `Subscription` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SubscriptionFilter>;
  /** No related `Subscription` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SubscriptionFilter>;
  /** Some related `Subscription` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SubscriptionFilter>;
};

/** A filter to be used against many `UserAssociation` object types. All fields are combined with a logical ‘and.’ */
export type AccessGroupToManyUserAssociationFilter = {
  /** Every related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserAssociationFilter>;
  /** No related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserAssociationFilter>;
  /** Some related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserAssociationFilter>;
};

/** A connection to a list of `AccessGroup` values. */
export type AccessGroupsConnection = {
  __typename?: 'AccessGroupsConnection';
  /** A list of edges which contains the `AccessGroup` and cursor to aid in pagination. */
  edges: Array<AccessGroupsEdge>;
  /** A list of `AccessGroup` objects. */
  nodes: Array<Maybe<AccessGroup>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AccessGroup` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `AccessGroup` edge in the connection. */
export type AccessGroupsEdge = {
  __typename?: 'AccessGroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `AccessGroup` at the end of the edge. */
  node?: Maybe<AccessGroup>;
};

/** Methods to use when ordering `AccessGroup`. */
export enum AccessGroupsOrderBy {
  AccessGroupKeyNameAsc = 'ACCESS_GROUP_KEY_NAME_ASC',
  AccessGroupKeyNameDesc = 'ACCESS_GROUP_KEY_NAME_DESC',
  AccessGroupOwnerPublicKeyAsc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC',
  AccessGroupOwnerPublicKeyDesc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC',
  AccessGroupPublicKeyAsc = 'ACCESS_GROUP_PUBLIC_KEY_ASC',
  AccessGroupPublicKeyDesc = 'ACCESS_GROUP_PUBLIC_KEY_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type Account = Node & {
  __typename?: 'Account';
  /** Reads and enables pagination through a set of `AccessGroupMember`. */
  accessGroupMemberships: AccessGroupMembersConnection;
  /** Reads and enables pagination through a set of `AccessGroup`. */
  accessGroupsOwned: AccessGroupsConnection;
  /** Reads a single `AccountWealth` that is related to this `Account`. */
  accountWealth?: Maybe<AccountWealth>;
  /** Reads a single `AccountWealthChainUser` that is related to this `Account`. */
  accountWealthChainUser?: Maybe<AccountWealthChainUser>;
  /** Reads and enables pagination through a set of `BlsPublicKeyPkidPairSnapshotEntry`. */
  blsPublicKeyPkidPairSnapshotEntries: BlsPublicKeyPkidPairSnapshotEntriesConnection;
  ccCoinsInCirculationNanos?: Maybe<Scalars['BigInt']['output']>;
  coinPriceDesoNanos?: Maybe<Scalars['BigFloat']['output']>;
  coinWatermarkNanos?: Maybe<Scalars['BigInt']['output']>;
  creatorBasisPoints?: Maybe<Scalars['BigInt']['output']>;
  /** Reads and enables pagination through a set of `CreatorCoinBalance`. */
  creatorCoinBalances: CreatorCoinBalancesConnection;
  /** Reads and enables pagination through a set of `CreatorCoinBalance`. */
  creatorCoinBalancesAsCreator: CreatorCoinBalancesConnection;
  /** Reads a single `CreatorStat` that is related to this `Account`. */
  creatorStats?: Maybe<CreatorStat>;
  daoCoinMintingDisabled?: Maybe<Scalars['Boolean']['output']>;
  daoCoinTransferRestrictionStatus?: Maybe<Scalars['Int']['output']>;
  daoCoinsInCirculationNanosHex?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `DerivedKey`. */
  derivedKeys: DerivedKeysConnection;
  description?: Maybe<Scalars['String']['output']>;
  /** Reads a single `DesoBalance` that is related to this `Account`. */
  desoBalance?: Maybe<DesoBalance>;
  desoBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  desoBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  desoLockedNanos?: Maybe<Scalars['BigInt']['output']>;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrder`. */
  desoTokenLimitOrderByCreatorBought: DesoTokenLimitOrdersConnection;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrder`. */
  desoTokenLimitOrderByCreatorSold: DesoTokenLimitOrdersConnection;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrder`. */
  desoTokenLimitOrderByTransactor: DesoTokenLimitOrdersConnection;
  /** Reads and enables pagination through a set of `Diamond`. */
  diamondsReceived: DiamondsConnection;
  /** Reads and enables pagination through a set of `Diamond`. */
  diamondsSent: DiamondsConnection;
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** Reads and enables pagination through a set of `FeedUserAssociation`. */
  feedsOwned: FeedUserAssociationsConnection;
  /** Reads and enables pagination through a set of `FilterableMessageThread`. */
  filterableMessageThreadsAsInitiator: FilterableMessageThreadsConnection;
  /** Reads and enables pagination through a set of `FilterableMessageThread`. */
  filterableMessageThreadsAsParticipant: FilterableMessageThreadsConnection;
  /** Reads and enables pagination through a set of `FocusListMember`. */
  focusListsAsMember: FocusListMembersConnection;
  /** Reads and enables pagination through a set of `FocusList`. */
  focusListsOwned: FocusListsConnection;
  focusTokenBalanceBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
  focusTokenBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  focusTokenLockedBalanceBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
  focusTokenLockedBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  focusTokenTotalBalanceBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
  focusTokenTotalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  /** Reads and enables pagination through a set of `Follow`. */
  followers: FollowsConnection;
  /** Reads and enables pagination through a set of `FollowFast`. */
  followersFast: FollowFastsConnection;
  /** Reads and enables pagination through a set of `Follow`. */
  following: FollowsConnection;
  /** Reads and enables pagination through a set of `FollowFast`. */
  followingFast: FollowFastsConnection;
  /** Reads and enables pagination through a set of `GlobalVal`. */
  globalValsDesoToken: GlobalValsConnection;
  /** Reads and enables pagination through a set of `GlobalVal`. */
  globalValsDusdToken: GlobalValsConnection;
  /** Reads and enables pagination through a set of `GlobalVal`. */
  globalValsFocusToken: GlobalValsConnection;
  /** Reads and enables pagination through a set of `LockedBalanceEntry`. */
  hodlerLockedBalanceEntries: LockedBalanceEntriesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  /** Reads and enables pagination through a set of `JailedHistoryEvent`. */
  jailedHistoryEvents: JailedHistoryEventsConnection;
  /** Reads and enables pagination through a set of `LeaderScheduleEntry`. */
  leaderScheduleEntries: LeaderScheduleEntriesConnection;
  /** Reads and enables pagination through a set of `LegacyMessage`. */
  legacyMessagesReceived: LegacyMessagesConnection;
  /** Reads and enables pagination through a set of `LegacyMessage`. */
  legacyMessagesSent: LegacyMessagesConnection;
  /** Reads and enables pagination through a set of `Like`. */
  likes: LikesConnection;
  /** Reads and enables pagination through a set of `LockedBalance`. */
  lockedBalancesAsHodler: LockedBalancesConnection;
  /** Reads and enables pagination through a set of `LockedBalance`. */
  lockedBalancesAsProfile: LockedBalancesConnection;
  /** Reads and enables pagination through a set of `LockedStakeEntry`. */
  lockedStakeEntries: LockedStakeEntriesConnection;
  /** Reads and enables pagination through a set of `Message`. */
  messagesReceived: MessagesConnection;
  /** Reads and enables pagination through a set of `Message`. */
  messagesSent: MessagesConnection;
  metadata?: Maybe<AccountMetadata>;
  mintingDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `MyStakeSummary` that is related to this `Account`. */
  myStakeSummary?: Maybe<MyStakeSummary>;
  /** Reads and enables pagination through a set of `NftBid`. */
  nftBids: NftBidsConnection;
  /** Reads and enables pagination through a set of `Nft`. */
  nftsAsLastOwner: NftsConnection;
  /** Reads and enables pagination through a set of `Nft`. */
  nftsOwned: NftsConnection;
  /** Reads and enables pagination through a set of `PageView`. */
  pageViews: PageViewsConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  payments: PaymentsConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsReceived: PaymentsConnection;
  pkid?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `PostAssociation`. */
  postAssociations: PostAssociationsConnection;
  /** Reads and enables pagination through a set of `PostAssociation`. */
  postAssociationsAsAppOwner: PostAssociationsConnection;
  /** Reads and enables pagination through a set of `PostAssociation`. */
  postAssociationsAsSender: PostAssociationsConnection;
  /** Reads and enables pagination through a set of `PostSubscriptionStat`. */
  postSubscriptionStats: PostSubscriptionStatsConnection;
  /** Reads and enables pagination through a set of `PostView`. */
  postViews: PostViewsConnection;
  /** Reads and enables pagination through a set of `Post`. */
  posts: PostsConnection;
  /** Reads a single `Profile` that is related to this `Account`. */
  profile?: Maybe<Profile>;
  /** Reads and enables pagination through a set of `LockedBalanceEntry`. */
  profileLockedBalanceEntries: LockedBalanceEntriesConnection;
  profilePic?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `EpochDetailsForBlock`. */
  proposedBlockHashes: EpochDetailsForBlocksConnection;
  publicKey: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `StakeEntry`. */
  stakeEntries: StakeEntriesConnection;
  /** Reads and enables pagination through a set of `StakeReward`. */
  stakeRewards: StakeRewardsConnection;
  /** Reads and enables pagination through a set of `SubscriptionTier`. */
  subscriptionTiers: SubscriptionTiersConnection;
  /** Reads and enables pagination through a set of `Subscription`. */
  subscriptions: SubscriptionsConnection;
  /** Reads a single `TokenBalanceAgg` that is related to this `Account`. */
  tokenAggAsCreator?: Maybe<TokenBalanceAgg>;
  /** Reads and enables pagination through a set of `TokenBalanceSummary`. */
  tokenBalanceSummariesAsCreator: TokenBalanceSummariesConnection;
  /** Reads and enables pagination through a set of `TokenBalanceSummary`. */
  tokenBalanceSummariesAsHodler: TokenBalanceSummariesConnection;
  /** Reads and enables pagination through a set of `TokenBalance`. */
  tokenBalances: TokenBalancesConnection;
  /** Reads and enables pagination through a set of `TokenBalance`. */
  tokenBalancesAsCreator: TokenBalancesConnection;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrderBidAsk`. */
  tokenMarketAsCreator: DesoTokenLimitOrderBidAsksConnection;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrderBidAsk`. */
  tokenMarketAsCreator2: DesoTokenLimitOrderBidAsksConnection;
  /** Reads and enables pagination through a set of `TokenMarketVolumeSummary`. */
  tokenMarketVolumeAsCreator: TokenMarketVolumeSummariesConnection;
  /** Reads and enables pagination through a set of `TokenMarketVolumeSummary`. */
  tokenMarketVolumeAsCreator2: TokenMarketVolumeSummariesConnection;
  totalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  /** Reads a single `TotalUnclaimedMessageTip` that is related to this `Account`. */
  total_unclaimed_message_tips_usd_cents?: Maybe<TotalUnclaimedMessageTip>;
  /** Reads and enables pagination through a set of `AffectedPublicKey`. */
  transactionHashes: AffectedPublicKeysConnection;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions: TransactionsConnection;
  /** Reads and enables pagination through a set of `UnreadMessagesCount`. */
  unreadMessagesCount: UnreadMessagesCountsConnection;
  /** Reads a single `UnreadThreadsCount` that is related to this `Account`. */
  unreadThreadsCount?: Maybe<UnreadThreadsCount>;
  /** Reads a single `UnreadMessagesTotalCount` that is related to this `Account`. */
  unreadTotalMessagesCount?: Maybe<UnreadMessagesTotalCount>;
  usdBalanceBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
  usdBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  /** Reads and enables pagination through a set of `UserAssociation`. */
  userAssociationsAsAppOwner: UserAssociationsConnection;
  /** Reads and enables pagination through a set of `UserAssociation`. */
  userAssociationsAsTarget: UserAssociationsConnection;
  /** Reads and enables pagination through a set of `UserAssociation`. */
  userAssociationsAsTransactor: UserAssociationsConnection;
  username?: Maybe<Scalars['String']['output']>;
  /** Reads a single `ValidatorEntry` that is related to this `Account`. */
  validatorEntry?: Maybe<ValidatorEntry>;
  /** Reads and enables pagination through a set of `LockedStakeEntry`. */
  validatorLockedStakeEntries: LockedStakeEntriesConnection;
  /** Reads and enables pagination through a set of `StakeEntry`. */
  validatorStakeEntries: StakeEntriesConnection;
  /** Reads and enables pagination through a set of `StakeReward`. */
  validatorStakeRewards: StakeRewardsConnection;
  /** Reads and enables pagination through a set of `YieldCurvePoint`. */
  yieldCurvePoints: YieldCurvePointsConnection;
};

export type AccountAccessGroupMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AccessGroupMemberCondition>;
  filter?: InputMaybe<AccessGroupMemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGroupMembersOrderBy>>;
};

export type AccountAccessGroupsOwnedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AccessGroupCondition>;
  filter?: InputMaybe<AccessGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGroupsOrderBy>>;
};

export type AccountBlsPublicKeyPkidPairSnapshotEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryCondition>;
  filter?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlsPublicKeyPkidPairSnapshotEntriesOrderBy>>;
};

export type AccountCreatorCoinBalancesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CreatorCoinBalanceCondition>;
  filter?: InputMaybe<CreatorCoinBalanceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorCoinBalancesOrderBy>>;
};

export type AccountCreatorCoinBalancesAsCreatorArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CreatorCoinBalanceCondition>;
  filter?: InputMaybe<CreatorCoinBalanceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorCoinBalancesOrderBy>>;
};

export type AccountDerivedKeysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DerivedKeyCondition>;
  filter?: InputMaybe<DerivedKeyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DerivedKeysOrderBy>>;
};

export type AccountDesoTokenLimitOrderByCreatorBoughtArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DesoTokenLimitOrderCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy>>;
};

export type AccountDesoTokenLimitOrderByCreatorSoldArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DesoTokenLimitOrderCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy>>;
};

export type AccountDesoTokenLimitOrderByTransactorArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DesoTokenLimitOrderCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy>>;
};

export type AccountDiamondsReceivedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DiamondCondition>;
  filter?: InputMaybe<DiamondFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DiamondsOrderBy>>;
};

export type AccountDiamondsSentArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DiamondCondition>;
  filter?: InputMaybe<DiamondFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DiamondsOrderBy>>;
};

export type AccountFeedsOwnedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedUserAssociationCondition>;
  filter?: InputMaybe<FeedUserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedUserAssociationsOrderBy>>;
};

export type AccountFilterableMessageThreadsAsInitiatorArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FilterableMessageThreadCondition>;
  filter?: InputMaybe<FilterableMessageThreadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilterableMessageThreadsOrderBy>>;
};

export type AccountFilterableMessageThreadsAsParticipantArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FilterableMessageThreadCondition>;
  filter?: InputMaybe<FilterableMessageThreadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilterableMessageThreadsOrderBy>>;
};

export type AccountFocusListsAsMemberArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FocusListMemberCondition>;
  filter?: InputMaybe<FocusListMemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FocusListMembersOrderBy>>;
};

export type AccountFocusListsOwnedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FocusListCondition>;
  filter?: InputMaybe<FocusListFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FocusListsOrderBy>>;
};

export type AccountFollowersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FollowCondition>;
  filter?: InputMaybe<FollowFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowsOrderBy>>;
};

export type AccountFollowersFastArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FollowFastCondition>;
  filter?: InputMaybe<FollowFastFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowFastsOrderBy>>;
};

export type AccountFollowingArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FollowCondition>;
  filter?: InputMaybe<FollowFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowsOrderBy>>;
};

export type AccountFollowingFastArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FollowFastCondition>;
  filter?: InputMaybe<FollowFastFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowFastsOrderBy>>;
};

export type AccountGlobalValsDesoTokenArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GlobalValCondition>;
  filter?: InputMaybe<GlobalValFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GlobalValsOrderBy>>;
};

export type AccountGlobalValsDusdTokenArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GlobalValCondition>;
  filter?: InputMaybe<GlobalValFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GlobalValsOrderBy>>;
};

export type AccountGlobalValsFocusTokenArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GlobalValCondition>;
  filter?: InputMaybe<GlobalValFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GlobalValsOrderBy>>;
};

export type AccountHodlerLockedBalanceEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockedBalanceEntryCondition>;
  filter?: InputMaybe<LockedBalanceEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockedBalanceEntriesOrderBy>>;
};

export type AccountJailedHistoryEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<JailedHistoryEventCondition>;
  filter?: InputMaybe<JailedHistoryEventFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JailedHistoryEventsOrderBy>>;
};

export type AccountLeaderScheduleEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LeaderScheduleEntryCondition>;
  filter?: InputMaybe<LeaderScheduleEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LeaderScheduleEntriesOrderBy>>;
};

export type AccountLegacyMessagesReceivedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LegacyMessageCondition>;
  filter?: InputMaybe<LegacyMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LegacyMessagesOrderBy>>;
};

export type AccountLegacyMessagesSentArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LegacyMessageCondition>;
  filter?: InputMaybe<LegacyMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LegacyMessagesOrderBy>>;
};

export type AccountLikesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

export type AccountLockedBalancesAsHodlerArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockedBalanceCondition>;
  filter?: InputMaybe<LockedBalanceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockedBalancesOrderBy>>;
};

export type AccountLockedBalancesAsProfileArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockedBalanceCondition>;
  filter?: InputMaybe<LockedBalanceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockedBalancesOrderBy>>;
};

export type AccountLockedStakeEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockedStakeEntryCondition>;
  filter?: InputMaybe<LockedStakeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockedStakeEntriesOrderBy>>;
};

export type AccountMessagesReceivedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

export type AccountMessagesSentArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

export type AccountNftBidsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<NftBidCondition>;
  filter?: InputMaybe<NftBidFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<NftBidsOrderBy>>;
};

export type AccountNftsAsLastOwnerArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<NftCondition>;
  filter?: InputMaybe<NftFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<NftsOrderBy>>;
};

export type AccountNftsOwnedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<NftCondition>;
  filter?: InputMaybe<NftFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<NftsOrderBy>>;
};

export type AccountPageViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PageViewCondition>;
  filter?: InputMaybe<PageViewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PageViewsOrderBy>>;
};

export type AccountPaymentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PaymentCondition>;
  filter?: InputMaybe<PaymentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};

export type AccountPaymentsReceivedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PaymentCondition>;
  filter?: InputMaybe<PaymentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};

export type AccountPostAssociationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostAssociationCondition>;
  filter?: InputMaybe<PostAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostAssociationsOrderBy>>;
};

export type AccountPostAssociationsAsAppOwnerArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostAssociationCondition>;
  filter?: InputMaybe<PostAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostAssociationsOrderBy>>;
};

export type AccountPostAssociationsAsSenderArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostAssociationCondition>;
  filter?: InputMaybe<PostAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostAssociationsOrderBy>>;
};

export type AccountPostSubscriptionStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostSubscriptionStatCondition>;
  filter?: InputMaybe<PostSubscriptionStatFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostSubscriptionStatsOrderBy>>;
};

export type AccountPostViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostViewCondition>;
  filter?: InputMaybe<PostViewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostViewsOrderBy>>;
};

export type AccountPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostCondition>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};

export type AccountProfileLockedBalanceEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockedBalanceEntryCondition>;
  filter?: InputMaybe<LockedBalanceEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockedBalanceEntriesOrderBy>>;
};

export type AccountProposedBlockHashesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EpochDetailsForBlockCondition>;
  filter?: InputMaybe<EpochDetailsForBlockFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EpochDetailsForBlocksOrderBy>>;
};

export type AccountStakeEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StakeEntryCondition>;
  filter?: InputMaybe<StakeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StakeEntriesOrderBy>>;
};

export type AccountStakeRewardsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StakeRewardCondition>;
  filter?: InputMaybe<StakeRewardFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StakeRewardsOrderBy>>;
};

export type AccountSubscriptionTiersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SubscriptionTierCondition>;
  filter?: InputMaybe<SubscriptionTierFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SubscriptionTiersOrderBy>>;
};

export type AccountSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SubscriptionCondition>;
  filter?: InputMaybe<SubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>;
};

export type AccountTokenBalanceSummariesAsCreatorArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TokenBalanceSummaryCondition>;
  filter?: InputMaybe<TokenBalanceSummaryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TokenBalanceSummariesOrderBy>>;
};

export type AccountTokenBalanceSummariesAsHodlerArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TokenBalanceSummaryCondition>;
  filter?: InputMaybe<TokenBalanceSummaryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TokenBalanceSummariesOrderBy>>;
};

export type AccountTokenBalancesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TokenBalanceCondition>;
  filter?: InputMaybe<TokenBalanceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TokenBalancesOrderBy>>;
};

export type AccountTokenBalancesAsCreatorArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TokenBalanceCondition>;
  filter?: InputMaybe<TokenBalanceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TokenBalancesOrderBy>>;
};

export type AccountTokenMarketAsCreatorArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DesoTokenLimitOrderBidAskCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderBidAskFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrderBidAsksOrderBy>>;
};

export type AccountTokenMarketAsCreator2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DesoTokenLimitOrderBidAskCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderBidAskFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrderBidAsksOrderBy>>;
};

export type AccountTokenMarketVolumeAsCreatorArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TokenMarketVolumeSummaryCondition>;
  filter?: InputMaybe<TokenMarketVolumeSummaryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TokenMarketVolumeSummariesOrderBy>>;
};

export type AccountTokenMarketVolumeAsCreator2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TokenMarketVolumeSummaryCondition>;
  filter?: InputMaybe<TokenMarketVolumeSummaryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TokenMarketVolumeSummariesOrderBy>>;
};

export type AccountTransactionHashesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AffectedPublicKeyCondition>;
  filter?: InputMaybe<AffectedPublicKeyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AffectedPublicKeysOrderBy>>;
};

export type AccountTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

export type AccountUnreadMessagesCountArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UnreadMessagesCountCondition>;
  filter?: InputMaybe<UnreadMessagesCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UnreadMessagesCountsOrderBy>>;
};

export type AccountUserAssociationsAsAppOwnerArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

export type AccountUserAssociationsAsTargetArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

export type AccountUserAssociationsAsTransactorArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

export type AccountValidatorLockedStakeEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockedStakeEntryCondition>;
  filter?: InputMaybe<LockedStakeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockedStakeEntriesOrderBy>>;
};

export type AccountValidatorStakeEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StakeEntryCondition>;
  filter?: InputMaybe<StakeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StakeEntriesOrderBy>>;
};

export type AccountValidatorStakeRewardsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StakeRewardCondition>;
  filter?: InputMaybe<StakeRewardFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StakeRewardsOrderBy>>;
};

export type AccountYieldCurvePointsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<YieldCurvePointCondition>;
  filter?: InputMaybe<YieldCurvePointFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<YieldCurvePointsOrderBy>>;
};

/** A condition to be used against `Account` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AccountCondition = {
  /** Checks for equality with the object’s `ccCoinsInCirculationNanos` field. */
  ccCoinsInCirculationNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `coinPriceDesoNanos` field. */
  coinPriceDesoNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `coinWatermarkNanos` field. */
  coinWatermarkNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `creatorBasisPoints` field. */
  creatorBasisPoints?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `daoCoinMintingDisabled` field. */
  daoCoinMintingDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `daoCoinTransferRestrictionStatus` field. */
  daoCoinTransferRestrictionStatus?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `daoCoinsInCirculationNanosHex` field. */
  daoCoinsInCirculationNanosHex?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `desoBalanceNanos` field. */
  desoBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `desoBalanceUsdCents` field. */
  desoBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `desoLockedNanos` field. */
  desoLockedNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `focusTokenBalanceBaseUnits` field. */
  focusTokenBalanceBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `focusTokenBalanceUsdCents` field. */
  focusTokenBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `focusTokenLockedBalanceBaseUnits` field. */
  focusTokenLockedBalanceBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `focusTokenLockedBalanceUsdCents` field. */
  focusTokenLockedBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `focusTokenTotalBalanceBaseUnits` field. */
  focusTokenTotalBalanceBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `focusTokenTotalBalanceUsdCents` field. */
  focusTokenTotalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `isVerified` field. */
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `mintingDisabled` field. */
  mintingDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `profilePic` field. */
  profilePic?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `totalBalanceUsdCents` field. */
  totalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `usdBalanceBaseUnits` field. */
  usdBalanceBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `usdBalanceUsdCents` field. */
  usdBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Account` object types. All fields are combined with a logical ‘and.’ */
export type AccountFilter = {
  /** Filter by the object’s `accessGroupMemberships` relation. */
  accessGroupMemberships?: InputMaybe<AccountToManyAccessGroupMemberFilter>;
  /** Some related `accessGroupMemberships` exist. */
  accessGroupMembershipsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `accessGroupsOwned` relation. */
  accessGroupsOwned?: InputMaybe<AccountToManyAccessGroupFilter>;
  /** Some related `accessGroupsOwned` exist. */
  accessGroupsOwnedExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `accountWealth` relation. */
  accountWealth?: InputMaybe<AccountWealthFilter>;
  /** Filter by the object’s `accountWealthChainUser` relation. */
  accountWealthChainUser?: InputMaybe<AccountWealthChainUserFilter>;
  /** A related `accountWealthChainUser` exists. */
  accountWealthChainUserExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** A related `accountWealth` exists. */
  accountWealthExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AccountFilter>>;
  /** Filter by the object’s `blsPublicKeyPkidPairSnapshotEntries` relation. */
  blsPublicKeyPkidPairSnapshotEntries?: InputMaybe<AccountToManyBlsPublicKeyPkidPairSnapshotEntryFilter>;
  /** Some related `blsPublicKeyPkidPairSnapshotEntries` exist. */
  blsPublicKeyPkidPairSnapshotEntriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `ccCoinsInCirculationNanos` field. */
  ccCoinsInCirculationNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `coinPriceDesoNanos` field. */
  coinPriceDesoNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coinWatermarkNanos` field. */
  coinWatermarkNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `creatorBasisPoints` field. */
  creatorBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `creatorCoinBalances` relation. */
  creatorCoinBalances?: InputMaybe<AccountToManyCreatorCoinBalanceFilter>;
  /** Filter by the object’s `creatorCoinBalancesAsCreator` relation. */
  creatorCoinBalancesAsCreator?: InputMaybe<AccountToManyCreatorCoinBalanceFilter>;
  /** Some related `creatorCoinBalancesAsCreator` exist. */
  creatorCoinBalancesAsCreatorExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Some related `creatorCoinBalances` exist. */
  creatorCoinBalancesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `creatorStats` relation. */
  creatorStats?: InputMaybe<CreatorStatFilter>;
  /** A related `creatorStats` exists. */
  creatorStatsExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `daoCoinMintingDisabled` field. */
  daoCoinMintingDisabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `daoCoinTransferRestrictionStatus` field. */
  daoCoinTransferRestrictionStatus?: InputMaybe<IntFilter>;
  /** Filter by the object’s `daoCoinsInCirculationNanosHex` field. */
  daoCoinsInCirculationNanosHex?: InputMaybe<StringFilter>;
  /** Filter by the object’s `derivedKeys` relation. */
  derivedKeys?: InputMaybe<AccountToManyDerivedKeyFilter>;
  /** Some related `derivedKeys` exist. */
  derivedKeysExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `desoBalance` relation. */
  desoBalance?: InputMaybe<DesoBalanceFilter>;
  /** A related `desoBalance` exists. */
  desoBalanceExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `desoBalanceNanos` field. */
  desoBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `desoBalanceUsdCents` field. */
  desoBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `desoLockedNanos` field. */
  desoLockedNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `desoTokenLimitOrderByCreatorBought` relation. */
  desoTokenLimitOrderByCreatorBought?: InputMaybe<AccountToManyDesoTokenLimitOrderFilter>;
  /** Some related `desoTokenLimitOrderByCreatorBought` exist. */
  desoTokenLimitOrderByCreatorBoughtExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `desoTokenLimitOrderByCreatorSold` relation. */
  desoTokenLimitOrderByCreatorSold?: InputMaybe<AccountToManyDesoTokenLimitOrderFilter>;
  /** Some related `desoTokenLimitOrderByCreatorSold` exist. */
  desoTokenLimitOrderByCreatorSoldExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `desoTokenLimitOrderByTransactor` relation. */
  desoTokenLimitOrderByTransactor?: InputMaybe<AccountToManyDesoTokenLimitOrderFilter>;
  /** Some related `desoTokenLimitOrderByTransactor` exist. */
  desoTokenLimitOrderByTransactorExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `diamondsReceived` relation. */
  diamondsReceived?: InputMaybe<AccountToManyDiamondFilter>;
  /** Some related `diamondsReceived` exist. */
  diamondsReceivedExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `diamondsSent` relation. */
  diamondsSent?: InputMaybe<AccountToManyDiamondFilter>;
  /** Some related `diamondsSent` exist. */
  diamondsSentExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `feedsOwned` relation. */
  feedsOwned?: InputMaybe<AccountToManyFeedUserAssociationFilter>;
  /** Some related `feedsOwned` exist. */
  feedsOwnedExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filterableMessageThreadsAsInitiator` relation. */
  filterableMessageThreadsAsInitiator?: InputMaybe<AccountToManyFilterableMessageThreadFilter>;
  /** Some related `filterableMessageThreadsAsInitiator` exist. */
  filterableMessageThreadsAsInitiatorExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filterableMessageThreadsAsParticipant` relation. */
  filterableMessageThreadsAsParticipant?: InputMaybe<AccountToManyFilterableMessageThreadFilter>;
  /** Some related `filterableMessageThreadsAsParticipant` exist. */
  filterableMessageThreadsAsParticipantExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `focusListsAsMember` relation. */
  focusListsAsMember?: InputMaybe<AccountToManyFocusListMemberFilter>;
  /** Some related `focusListsAsMember` exist. */
  focusListsAsMemberExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `focusListsOwned` relation. */
  focusListsOwned?: InputMaybe<AccountToManyFocusListFilter>;
  /** Some related `focusListsOwned` exist. */
  focusListsOwnedExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `focusTokenBalanceBaseUnits` field. */
  focusTokenBalanceBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `focusTokenBalanceUsdCents` field. */
  focusTokenBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `focusTokenLockedBalanceBaseUnits` field. */
  focusTokenLockedBalanceBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `focusTokenLockedBalanceUsdCents` field. */
  focusTokenLockedBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `focusTokenTotalBalanceBaseUnits` field. */
  focusTokenTotalBalanceBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `focusTokenTotalBalanceUsdCents` field. */
  focusTokenTotalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `followers` relation. */
  followers?: InputMaybe<AccountToManyFollowFilter>;
  /** Some related `followers` exist. */
  followersExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `followersFast` relation. */
  followersFast?: InputMaybe<AccountToManyFollowFastFilter>;
  /** Some related `followersFast` exist. */
  followersFastExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `following` relation. */
  following?: InputMaybe<AccountToManyFollowFilter>;
  /** Some related `following` exist. */
  followingExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `followingFast` relation. */
  followingFast?: InputMaybe<AccountToManyFollowFastFilter>;
  /** Some related `followingFast` exist. */
  followingFastExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `globalValsDesoToken` relation. */
  globalValsDesoToken?: InputMaybe<AccountToManyGlobalValFilter>;
  /** Some related `globalValsDesoToken` exist. */
  globalValsDesoTokenExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `globalValsDusdToken` relation. */
  globalValsDusdToken?: InputMaybe<AccountToManyGlobalValFilter>;
  /** Some related `globalValsDusdToken` exist. */
  globalValsDusdTokenExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `globalValsFocusToken` relation. */
  globalValsFocusToken?: InputMaybe<AccountToManyGlobalValFilter>;
  /** Some related `globalValsFocusToken` exist. */
  globalValsFocusTokenExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `hodlerLockedBalanceEntries` relation. */
  hodlerLockedBalanceEntries?: InputMaybe<AccountToManyLockedBalanceEntryFilter>;
  /** Some related `hodlerLockedBalanceEntries` exist. */
  hodlerLockedBalanceEntriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `isVerified` field. */
  isVerified?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `jailedHistoryEvents` relation. */
  jailedHistoryEvents?: InputMaybe<AccountToManyJailedHistoryEventFilter>;
  /** Some related `jailedHistoryEvents` exist. */
  jailedHistoryEventsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `leaderScheduleEntries` relation. */
  leaderScheduleEntries?: InputMaybe<AccountToManyLeaderScheduleEntryFilter>;
  /** Some related `leaderScheduleEntries` exist. */
  leaderScheduleEntriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `legacyMessagesReceived` relation. */
  legacyMessagesReceived?: InputMaybe<AccountToManyLegacyMessageFilter>;
  /** Some related `legacyMessagesReceived` exist. */
  legacyMessagesReceivedExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `legacyMessagesSent` relation. */
  legacyMessagesSent?: InputMaybe<AccountToManyLegacyMessageFilter>;
  /** Some related `legacyMessagesSent` exist. */
  legacyMessagesSentExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `likes` relation. */
  likes?: InputMaybe<AccountToManyLikeFilter>;
  /** Some related `likes` exist. */
  likesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `lockedBalancesAsHodler` relation. */
  lockedBalancesAsHodler?: InputMaybe<AccountToManyLockedBalanceFilter>;
  /** Some related `lockedBalancesAsHodler` exist. */
  lockedBalancesAsHodlerExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `lockedBalancesAsProfile` relation. */
  lockedBalancesAsProfile?: InputMaybe<AccountToManyLockedBalanceFilter>;
  /** Some related `lockedBalancesAsProfile` exist. */
  lockedBalancesAsProfileExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `lockedStakeEntries` relation. */
  lockedStakeEntries?: InputMaybe<AccountToManyLockedStakeEntryFilter>;
  /** Some related `lockedStakeEntries` exist. */
  lockedStakeEntriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `messagesReceived` relation. */
  messagesReceived?: InputMaybe<AccountToManyMessageFilter>;
  /** Some related `messagesReceived` exist. */
  messagesReceivedExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `messagesSent` relation. */
  messagesSent?: InputMaybe<AccountToManyMessageFilter>;
  /** Some related `messagesSent` exist. */
  messagesSentExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `mintingDisabled` field. */
  mintingDisabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `myStakeSummary` relation. */
  myStakeSummary?: InputMaybe<MyStakeSummaryFilter>;
  /** A related `myStakeSummary` exists. */
  myStakeSummaryExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `nftBids` relation. */
  nftBids?: InputMaybe<AccountToManyNftBidFilter>;
  /** Some related `nftBids` exist. */
  nftBidsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `nftsAsLastOwner` relation. */
  nftsAsLastOwner?: InputMaybe<AccountToManyNftFilter>;
  /** Some related `nftsAsLastOwner` exist. */
  nftsAsLastOwnerExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `nftsOwned` relation. */
  nftsOwned?: InputMaybe<AccountToManyNftFilter>;
  /** Some related `nftsOwned` exist. */
  nftsOwnedExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not?: InputMaybe<AccountFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AccountFilter>>;
  /** Filter by the object’s `pageViews` relation. */
  pageViews?: InputMaybe<AccountToManyPageViewFilter>;
  /** Some related `pageViews` exist. */
  pageViewsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `payments` relation. */
  payments?: InputMaybe<AccountToManyPaymentFilter>;
  /** Some related `payments` exist. */
  paymentsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `paymentsReceived` relation. */
  paymentsReceived?: InputMaybe<AccountToManyPaymentFilter>;
  /** Some related `paymentsReceived` exist. */
  paymentsReceivedExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postAssociations` relation. */
  postAssociations?: InputMaybe<AccountToManyPostAssociationFilter>;
  /** Filter by the object’s `postAssociationsAsAppOwner` relation. */
  postAssociationsAsAppOwner?: InputMaybe<AccountToManyPostAssociationFilter>;
  /** Some related `postAssociationsAsAppOwner` exist. */
  postAssociationsAsAppOwnerExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `postAssociationsAsSender` relation. */
  postAssociationsAsSender?: InputMaybe<AccountToManyPostAssociationFilter>;
  /** Some related `postAssociationsAsSender` exist. */
  postAssociationsAsSenderExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Some related `postAssociations` exist. */
  postAssociationsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `postSubscriptionStats` relation. */
  postSubscriptionStats?: InputMaybe<AccountToManyPostSubscriptionStatFilter>;
  /** Some related `postSubscriptionStats` exist. */
  postSubscriptionStatsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `postViews` relation. */
  postViews?: InputMaybe<AccountToManyPostViewFilter>;
  /** Some related `postViews` exist. */
  postViewsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `posts` relation. */
  posts?: InputMaybe<AccountToManyPostFilter>;
  /** Some related `posts` exist. */
  postsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `profile` relation. */
  profile?: InputMaybe<ProfileFilter>;
  /** A related `profile` exists. */
  profileExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `profileLockedBalanceEntries` relation. */
  profileLockedBalanceEntries?: InputMaybe<AccountToManyLockedBalanceEntryFilter>;
  /** Some related `profileLockedBalanceEntries` exist. */
  profileLockedBalanceEntriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `proposedBlockHashes` relation. */
  proposedBlockHashes?: InputMaybe<AccountToManyEpochDetailsForBlockFilter>;
  /** Some related `proposedBlockHashes` exist. */
  proposedBlockHashesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stakeEntries` relation. */
  stakeEntries?: InputMaybe<AccountToManyStakeEntryFilter>;
  /** Some related `stakeEntries` exist. */
  stakeEntriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `stakeRewards` relation. */
  stakeRewards?: InputMaybe<AccountToManyStakeRewardFilter>;
  /** Some related `stakeRewards` exist. */
  stakeRewardsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `subscriptionTiers` relation. */
  subscriptionTiers?: InputMaybe<AccountToManySubscriptionTierFilter>;
  /** Some related `subscriptionTiers` exist. */
  subscriptionTiersExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `subscriptions` relation. */
  subscriptions?: InputMaybe<AccountToManySubscriptionFilter>;
  /** Some related `subscriptions` exist. */
  subscriptionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `tokenAggAsCreator` relation. */
  tokenAggAsCreator?: InputMaybe<TokenBalanceAggFilter>;
  /** A related `tokenAggAsCreator` exists. */
  tokenAggAsCreatorExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `tokenBalanceSummariesAsCreator` relation. */
  tokenBalanceSummariesAsCreator?: InputMaybe<AccountToManyTokenBalanceSummaryFilter>;
  /** Some related `tokenBalanceSummariesAsCreator` exist. */
  tokenBalanceSummariesAsCreatorExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `tokenBalanceSummariesAsHodler` relation. */
  tokenBalanceSummariesAsHodler?: InputMaybe<AccountToManyTokenBalanceSummaryFilter>;
  /** Some related `tokenBalanceSummariesAsHodler` exist. */
  tokenBalanceSummariesAsHodlerExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `tokenBalances` relation. */
  tokenBalances?: InputMaybe<AccountToManyTokenBalanceFilter>;
  /** Filter by the object’s `tokenBalancesAsCreator` relation. */
  tokenBalancesAsCreator?: InputMaybe<AccountToManyTokenBalanceFilter>;
  /** Some related `tokenBalancesAsCreator` exist. */
  tokenBalancesAsCreatorExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Some related `tokenBalances` exist. */
  tokenBalancesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `tokenMarketAsCreator` relation. */
  tokenMarketAsCreator?: InputMaybe<AccountToManyDesoTokenLimitOrderBidAskFilter>;
  /** Filter by the object’s `tokenMarketAsCreator2` relation. */
  tokenMarketAsCreator2?: InputMaybe<AccountToManyDesoTokenLimitOrderBidAskFilter>;
  /** Some related `tokenMarketAsCreator2` exist. */
  tokenMarketAsCreator2Exist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Some related `tokenMarketAsCreator` exist. */
  tokenMarketAsCreatorExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `tokenMarketVolumeAsCreator` relation. */
  tokenMarketVolumeAsCreator?: InputMaybe<AccountToManyTokenMarketVolumeSummaryFilter>;
  /** Filter by the object’s `tokenMarketVolumeAsCreator2` relation. */
  tokenMarketVolumeAsCreator2?: InputMaybe<AccountToManyTokenMarketVolumeSummaryFilter>;
  /** Some related `tokenMarketVolumeAsCreator2` exist. */
  tokenMarketVolumeAsCreator2Exist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Some related `tokenMarketVolumeAsCreator` exist. */
  tokenMarketVolumeAsCreatorExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `totalBalanceUsdCents` field. */
  totalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `total_unclaimed_message_tips_usd_cents` relation. */
  total_unclaimed_message_tips_usd_cents?: InputMaybe<TotalUnclaimedMessageTipFilter>;
  /** A related `total_unclaimed_message_tips_usd_cents` exists. */
  total_unclaimed_message_tips_usd_centsExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `transactionHashes` relation. */
  transactionHashes?: InputMaybe<AccountToManyAffectedPublicKeyFilter>;
  /** Some related `transactionHashes` exist. */
  transactionHashesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `transactions` relation. */
  transactions?: InputMaybe<AccountToManyTransactionFilter>;
  /** Some related `transactions` exist. */
  transactionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `unreadMessagesCount` relation. */
  unreadMessagesCount?: InputMaybe<AccountToManyUnreadMessagesCountFilter>;
  /** Some related `unreadMessagesCount` exist. */
  unreadMessagesCountExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `unreadThreadsCount` relation. */
  unreadThreadsCount?: InputMaybe<UnreadThreadsCountFilter>;
  /** A related `unreadThreadsCount` exists. */
  unreadThreadsCountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `unreadTotalMessagesCount` relation. */
  unreadTotalMessagesCount?: InputMaybe<UnreadMessagesTotalCountFilter>;
  /** A related `unreadTotalMessagesCount` exists. */
  unreadTotalMessagesCountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `usdBalanceBaseUnits` field. */
  usdBalanceBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `usdBalanceUsdCents` field. */
  usdBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `userAssociationsAsAppOwner` relation. */
  userAssociationsAsAppOwner?: InputMaybe<AccountToManyUserAssociationFilter>;
  /** Some related `userAssociationsAsAppOwner` exist. */
  userAssociationsAsAppOwnerExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `userAssociationsAsTarget` relation. */
  userAssociationsAsTarget?: InputMaybe<AccountToManyUserAssociationFilter>;
  /** Some related `userAssociationsAsTarget` exist. */
  userAssociationsAsTargetExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `userAssociationsAsTransactor` relation. */
  userAssociationsAsTransactor?: InputMaybe<AccountToManyUserAssociationFilter>;
  /** Some related `userAssociationsAsTransactor` exist. */
  userAssociationsAsTransactorExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
  /** Filter by the object’s `validatorEntry` relation. */
  validatorEntry?: InputMaybe<ValidatorEntryFilter>;
  /** A related `validatorEntry` exists. */
  validatorEntryExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `validatorLockedStakeEntries` relation. */
  validatorLockedStakeEntries?: InputMaybe<AccountToManyLockedStakeEntryFilter>;
  /** Some related `validatorLockedStakeEntries` exist. */
  validatorLockedStakeEntriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `validatorStakeEntries` relation. */
  validatorStakeEntries?: InputMaybe<AccountToManyStakeEntryFilter>;
  /** Some related `validatorStakeEntries` exist. */
  validatorStakeEntriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `validatorStakeRewards` relation. */
  validatorStakeRewards?: InputMaybe<AccountToManyStakeRewardFilter>;
  /** Some related `validatorStakeRewards` exist. */
  validatorStakeRewardsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `yieldCurvePoints` relation. */
  yieldCurvePoints?: InputMaybe<AccountToManyYieldCurvePointFilter>;
  /** Some related `yieldCurvePoints` exist. */
  yieldCurvePointsExist?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountMetadata = {
  __typename?: 'AccountMetadata';
  email?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

/** A filter to be used against many `AccessGroup` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyAccessGroupFilter = {
  /** Every related `AccessGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AccessGroupFilter>;
  /** No related `AccessGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AccessGroupFilter>;
  /** Some related `AccessGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AccessGroupFilter>;
};

/** A filter to be used against many `AccessGroupMember` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyAccessGroupMemberFilter = {
  /** Every related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AccessGroupMemberFilter>;
  /** No related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AccessGroupMemberFilter>;
  /** Some related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AccessGroupMemberFilter>;
};

/** A filter to be used against many `AffectedPublicKey` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyAffectedPublicKeyFilter = {
  /** Every related `AffectedPublicKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AffectedPublicKeyFilter>;
  /** No related `AffectedPublicKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AffectedPublicKeyFilter>;
  /** Some related `AffectedPublicKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AffectedPublicKeyFilter>;
};

/** A filter to be used against many `BlsPublicKeyPkidPairSnapshotEntry` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyBlsPublicKeyPkidPairSnapshotEntryFilter = {
  /** Every related `BlsPublicKeyPkidPairSnapshotEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  /** No related `BlsPublicKeyPkidPairSnapshotEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  /** Some related `BlsPublicKeyPkidPairSnapshotEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
};

/** A filter to be used against many `CreatorCoinBalance` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyCreatorCoinBalanceFilter = {
  /** Every related `CreatorCoinBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<CreatorCoinBalanceFilter>;
  /** No related `CreatorCoinBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<CreatorCoinBalanceFilter>;
  /** Some related `CreatorCoinBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<CreatorCoinBalanceFilter>;
};

/** A filter to be used against many `DerivedKey` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyDerivedKeyFilter = {
  /** Every related `DerivedKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DerivedKeyFilter>;
  /** No related `DerivedKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DerivedKeyFilter>;
  /** Some related `DerivedKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DerivedKeyFilter>;
};

/** A filter to be used against many `DesoTokenLimitOrderBidAsk` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyDesoTokenLimitOrderBidAskFilter = {
  /** Every related `DesoTokenLimitOrderBidAsk` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DesoTokenLimitOrderBidAskFilter>;
  /** No related `DesoTokenLimitOrderBidAsk` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DesoTokenLimitOrderBidAskFilter>;
  /** Some related `DesoTokenLimitOrderBidAsk` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DesoTokenLimitOrderBidAskFilter>;
};

/** A filter to be used against many `DesoTokenLimitOrder` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyDesoTokenLimitOrderFilter = {
  /** Every related `DesoTokenLimitOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DesoTokenLimitOrderFilter>;
  /** No related `DesoTokenLimitOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DesoTokenLimitOrderFilter>;
  /** Some related `DesoTokenLimitOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DesoTokenLimitOrderFilter>;
};

/** A filter to be used against many `Diamond` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyDiamondFilter = {
  /** Every related `Diamond` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DiamondFilter>;
  /** No related `Diamond` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DiamondFilter>;
  /** Some related `Diamond` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DiamondFilter>;
};

/** A filter to be used against many `EpochDetailsForBlock` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyEpochDetailsForBlockFilter = {
  /** Every related `EpochDetailsForBlock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<EpochDetailsForBlockFilter>;
  /** No related `EpochDetailsForBlock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<EpochDetailsForBlockFilter>;
  /** Some related `EpochDetailsForBlock` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<EpochDetailsForBlockFilter>;
};

/** A filter to be used against many `FeedUserAssociation` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyFeedUserAssociationFilter = {
  /** Every related `FeedUserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FeedUserAssociationFilter>;
  /** No related `FeedUserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FeedUserAssociationFilter>;
  /** Some related `FeedUserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FeedUserAssociationFilter>;
};

/** A filter to be used against many `FilterableMessageThread` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyFilterableMessageThreadFilter = {
  /** Every related `FilterableMessageThread` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FilterableMessageThreadFilter>;
  /** No related `FilterableMessageThread` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FilterableMessageThreadFilter>;
  /** Some related `FilterableMessageThread` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FilterableMessageThreadFilter>;
};

/** A filter to be used against many `FocusList` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyFocusListFilter = {
  /** Every related `FocusList` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FocusListFilter>;
  /** No related `FocusList` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FocusListFilter>;
  /** Some related `FocusList` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FocusListFilter>;
};

/** A filter to be used against many `FocusListMember` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyFocusListMemberFilter = {
  /** Every related `FocusListMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FocusListMemberFilter>;
  /** No related `FocusListMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FocusListMemberFilter>;
  /** Some related `FocusListMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FocusListMemberFilter>;
};

/** A filter to be used against many `FollowFast` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyFollowFastFilter = {
  /** Every related `FollowFast` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FollowFastFilter>;
  /** No related `FollowFast` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FollowFastFilter>;
  /** Some related `FollowFast` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FollowFastFilter>;
};

/** A filter to be used against many `Follow` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyFollowFilter = {
  /** Every related `Follow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FollowFilter>;
  /** No related `Follow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FollowFilter>;
  /** Some related `Follow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FollowFilter>;
};

/** A filter to be used against many `GlobalVal` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyGlobalValFilter = {
  /** Every related `GlobalVal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<GlobalValFilter>;
  /** No related `GlobalVal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<GlobalValFilter>;
  /** Some related `GlobalVal` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<GlobalValFilter>;
};

/** A filter to be used against many `JailedHistoryEvent` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyJailedHistoryEventFilter = {
  /** Every related `JailedHistoryEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<JailedHistoryEventFilter>;
  /** No related `JailedHistoryEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<JailedHistoryEventFilter>;
  /** Some related `JailedHistoryEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<JailedHistoryEventFilter>;
};

/** A filter to be used against many `LeaderScheduleEntry` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyLeaderScheduleEntryFilter = {
  /** Every related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LeaderScheduleEntryFilter>;
  /** No related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LeaderScheduleEntryFilter>;
  /** Some related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LeaderScheduleEntryFilter>;
};

/** A filter to be used against many `LegacyMessage` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyLegacyMessageFilter = {
  /** Every related `LegacyMessage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LegacyMessageFilter>;
  /** No related `LegacyMessage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LegacyMessageFilter>;
  /** Some related `LegacyMessage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LegacyMessageFilter>;
};

/** A filter to be used against many `Like` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyLikeFilter = {
  /** Every related `Like` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LikeFilter>;
  /** No related `Like` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LikeFilter>;
  /** Some related `Like` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LikeFilter>;
};

/** A filter to be used against many `LockedBalanceEntry` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyLockedBalanceEntryFilter = {
  /** Every related `LockedBalanceEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LockedBalanceEntryFilter>;
  /** No related `LockedBalanceEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LockedBalanceEntryFilter>;
  /** Some related `LockedBalanceEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LockedBalanceEntryFilter>;
};

/** A filter to be used against many `LockedBalance` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyLockedBalanceFilter = {
  /** Every related `LockedBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LockedBalanceFilter>;
  /** No related `LockedBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LockedBalanceFilter>;
  /** Some related `LockedBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LockedBalanceFilter>;
};

/** A filter to be used against many `LockedStakeEntry` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyLockedStakeEntryFilter = {
  /** Every related `LockedStakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LockedStakeEntryFilter>;
  /** No related `LockedStakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LockedStakeEntryFilter>;
  /** Some related `LockedStakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LockedStakeEntryFilter>;
};

/** A filter to be used against many `Message` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyMessageFilter = {
  /** Every related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MessageFilter>;
  /** No related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MessageFilter>;
  /** Some related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MessageFilter>;
};

/** A filter to be used against many `NftBid` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyNftBidFilter = {
  /** Every related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NftBidFilter>;
  /** No related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NftBidFilter>;
  /** Some related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NftBidFilter>;
};

/** A filter to be used against many `Nft` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyNftFilter = {
  /** Every related `Nft` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NftFilter>;
  /** No related `Nft` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NftFilter>;
  /** Some related `Nft` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NftFilter>;
};

/** A filter to be used against many `PageView` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyPageViewFilter = {
  /** Every related `PageView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PageViewFilter>;
  /** No related `PageView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PageViewFilter>;
  /** Some related `PageView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PageViewFilter>;
};

/** A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyPaymentFilter = {
  /** Every related `Payment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PaymentFilter>;
  /** No related `Payment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PaymentFilter>;
  /** Some related `Payment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PaymentFilter>;
};

/** A filter to be used against many `PostAssociation` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyPostAssociationFilter = {
  /** Every related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostAssociationFilter>;
  /** No related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostAssociationFilter>;
  /** Some related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostAssociationFilter>;
};

/** A filter to be used against many `Post` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyPostFilter = {
  /** Every related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostFilter>;
  /** No related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostFilter>;
  /** Some related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostFilter>;
};

/** A filter to be used against many `PostSubscriptionStat` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyPostSubscriptionStatFilter = {
  /** Every related `PostSubscriptionStat` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostSubscriptionStatFilter>;
  /** No related `PostSubscriptionStat` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostSubscriptionStatFilter>;
  /** Some related `PostSubscriptionStat` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostSubscriptionStatFilter>;
};

/** A filter to be used against many `PostView` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyPostViewFilter = {
  /** Every related `PostView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostViewFilter>;
  /** No related `PostView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostViewFilter>;
  /** Some related `PostView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostViewFilter>;
};

/** A filter to be used against many `StakeEntry` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyStakeEntryFilter = {
  /** Every related `StakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<StakeEntryFilter>;
  /** No related `StakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<StakeEntryFilter>;
  /** Some related `StakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<StakeEntryFilter>;
};

/** A filter to be used against many `StakeReward` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyStakeRewardFilter = {
  /** Every related `StakeReward` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<StakeRewardFilter>;
  /** No related `StakeReward` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<StakeRewardFilter>;
  /** Some related `StakeReward` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<StakeRewardFilter>;
};

/** A filter to be used against many `Subscription` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManySubscriptionFilter = {
  /** Every related `Subscription` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SubscriptionFilter>;
  /** No related `Subscription` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SubscriptionFilter>;
  /** Some related `Subscription` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SubscriptionFilter>;
};

/** A filter to be used against many `SubscriptionTier` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManySubscriptionTierFilter = {
  /** Every related `SubscriptionTier` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SubscriptionTierFilter>;
  /** No related `SubscriptionTier` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SubscriptionTierFilter>;
  /** Some related `SubscriptionTier` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SubscriptionTierFilter>;
};

/** A filter to be used against many `TokenBalance` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyTokenBalanceFilter = {
  /** Every related `TokenBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TokenBalanceFilter>;
  /** No related `TokenBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TokenBalanceFilter>;
  /** Some related `TokenBalance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TokenBalanceFilter>;
};

/** A filter to be used against many `TokenBalanceSummary` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyTokenBalanceSummaryFilter = {
  /** Every related `TokenBalanceSummary` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TokenBalanceSummaryFilter>;
  /** No related `TokenBalanceSummary` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TokenBalanceSummaryFilter>;
  /** Some related `TokenBalanceSummary` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TokenBalanceSummaryFilter>;
};

/** A filter to be used against many `TokenMarketVolumeSummary` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyTokenMarketVolumeSummaryFilter = {
  /** Every related `TokenMarketVolumeSummary` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TokenMarketVolumeSummaryFilter>;
  /** No related `TokenMarketVolumeSummary` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TokenMarketVolumeSummaryFilter>;
  /** Some related `TokenMarketVolumeSummary` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TokenMarketVolumeSummaryFilter>;
};

/** A filter to be used against many `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyTransactionFilter = {
  /** Every related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TransactionFilter>;
  /** No related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TransactionFilter>;
  /** Some related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TransactionFilter>;
};

/** A filter to be used against many `UnreadMessagesCount` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyUnreadMessagesCountFilter = {
  /** Every related `UnreadMessagesCount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UnreadMessagesCountFilter>;
  /** No related `UnreadMessagesCount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UnreadMessagesCountFilter>;
  /** Some related `UnreadMessagesCount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UnreadMessagesCountFilter>;
};

/** A filter to be used against many `UserAssociation` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyUserAssociationFilter = {
  /** Every related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserAssociationFilter>;
  /** No related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserAssociationFilter>;
  /** Some related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserAssociationFilter>;
};

/** A filter to be used against many `YieldCurvePoint` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyYieldCurvePointFilter = {
  /** Every related `YieldCurvePoint` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<YieldCurvePointFilter>;
  /** No related `YieldCurvePoint` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<YieldCurvePointFilter>;
  /** Some related `YieldCurvePoint` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<YieldCurvePointFilter>;
};

export type AccountWealth = Node & {
  __typename?: 'AccountWealth';
  /** Reads a single `Account` that is related to this `AccountWealth`. */
  account?: Maybe<Account>;
  desoBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  desoBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  focusTokenBalanceBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
  focusTokenBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  focusTokenLockedBalanceBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
  focusTokenLockedBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  focusTokenTotalBalanceBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
  focusTokenTotalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  lockedStakeDesoBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  pkid?: Maybe<Scalars['String']['output']>;
  publicKey: Scalars['String']['output'];
  stakedDesoBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  totalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  unstakedDesoBalanceNanos?: Maybe<Scalars['BigInt']['output']>;
  usdBalanceBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
  usdBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type AccountWealthChainUser = Node & {
  __typename?: 'AccountWealthChainUser';
  /** Reads a single `Account` that is related to this `AccountWealthChainUser`. */
  account?: Maybe<Account>;
  desoBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  desoBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  focusTokenBalanceBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
  focusTokenBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  focusTokenLockedBalanceBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
  focusTokenLockedBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  focusTokenTotalBalanceBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
  focusTokenTotalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  lockedStakeDesoBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  pkid?: Maybe<Scalars['String']['output']>;
  publicKey: Scalars['String']['output'];
  stakedDesoBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  totalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  unstakedDesoBalanceNanos?: Maybe<Scalars['BigInt']['output']>;
  usdBalanceBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
  usdBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `AccountWealthChainUser` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type AccountWealthChainUserCondition = {
  /** Checks for equality with the object’s `desoBalanceNanos` field. */
  desoBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `desoBalanceUsdCents` field. */
  desoBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `focusTokenBalanceBaseUnits` field. */
  focusTokenBalanceBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `focusTokenBalanceUsdCents` field. */
  focusTokenBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `focusTokenLockedBalanceBaseUnits` field. */
  focusTokenLockedBalanceBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `focusTokenLockedBalanceUsdCents` field. */
  focusTokenLockedBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `focusTokenTotalBalanceBaseUnits` field. */
  focusTokenTotalBalanceBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `focusTokenTotalBalanceUsdCents` field. */
  focusTokenTotalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `lockedStakeDesoBalanceNanos` field. */
  lockedStakeDesoBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `stakedDesoBalanceNanos` field. */
  stakedDesoBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalBalanceUsdCents` field. */
  totalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `unstakedDesoBalanceNanos` field. */
  unstakedDesoBalanceNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `usdBalanceBaseUnits` field. */
  usdBalanceBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `usdBalanceUsdCents` field. */
  usdBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `AccountWealthChainUser` object types. All fields are combined with a logical ‘and.’ */
export type AccountWealthChainUserFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AccountWealthChainUserFilter>>;
  /** Filter by the object’s `desoBalanceNanos` field. */
  desoBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `desoBalanceUsdCents` field. */
  desoBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `focusTokenBalanceBaseUnits` field. */
  focusTokenBalanceBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `focusTokenBalanceUsdCents` field. */
  focusTokenBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `focusTokenLockedBalanceBaseUnits` field. */
  focusTokenLockedBalanceBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `focusTokenLockedBalanceUsdCents` field. */
  focusTokenLockedBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `focusTokenTotalBalanceBaseUnits` field. */
  focusTokenTotalBalanceBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `focusTokenTotalBalanceUsdCents` field. */
  focusTokenTotalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `lockedStakeDesoBalanceNanos` field. */
  lockedStakeDesoBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AccountWealthChainUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AccountWealthChainUserFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stakedDesoBalanceNanos` field. */
  stakedDesoBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalBalanceUsdCents` field. */
  totalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `unstakedDesoBalanceNanos` field. */
  unstakedDesoBalanceNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `usdBalanceBaseUnits` field. */
  usdBalanceBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `usdBalanceUsdCents` field. */
  usdBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** A connection to a list of `AccountWealthChainUser` values. */
export type AccountWealthChainUsersConnection = {
  __typename?: 'AccountWealthChainUsersConnection';
  /** A list of edges which contains the `AccountWealthChainUser` and cursor to aid in pagination. */
  edges: Array<AccountWealthChainUsersEdge>;
  /** A list of `AccountWealthChainUser` objects. */
  nodes: Array<Maybe<AccountWealthChainUser>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AccountWealthChainUser` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `AccountWealthChainUser` edge in the connection. */
export type AccountWealthChainUsersEdge = {
  __typename?: 'AccountWealthChainUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `AccountWealthChainUser` at the end of the edge. */
  node?: Maybe<AccountWealthChainUser>;
};

/** Methods to use when ordering `AccountWealthChainUser`. */
export enum AccountWealthChainUsersOrderBy {
  DesoBalanceNanosAsc = 'DESO_BALANCE_NANOS_ASC',
  DesoBalanceNanosDesc = 'DESO_BALANCE_NANOS_DESC',
  DesoBalanceUsdCentsAsc = 'DESO_BALANCE_USD_CENTS_ASC',
  DesoBalanceUsdCentsDesc = 'DESO_BALANCE_USD_CENTS_DESC',
  FocusTokenBalanceBaseUnitsAsc = 'FOCUS_TOKEN_BALANCE_BASE_UNITS_ASC',
  FocusTokenBalanceBaseUnitsDesc = 'FOCUS_TOKEN_BALANCE_BASE_UNITS_DESC',
  FocusTokenBalanceUsdCentsAsc = 'FOCUS_TOKEN_BALANCE_USD_CENTS_ASC',
  FocusTokenBalanceUsdCentsDesc = 'FOCUS_TOKEN_BALANCE_USD_CENTS_DESC',
  FocusTokenLockedBalanceBaseUnitsAsc = 'FOCUS_TOKEN_LOCKED_BALANCE_BASE_UNITS_ASC',
  FocusTokenLockedBalanceBaseUnitsDesc = 'FOCUS_TOKEN_LOCKED_BALANCE_BASE_UNITS_DESC',
  FocusTokenLockedBalanceUsdCentsAsc = 'FOCUS_TOKEN_LOCKED_BALANCE_USD_CENTS_ASC',
  FocusTokenLockedBalanceUsdCentsDesc = 'FOCUS_TOKEN_LOCKED_BALANCE_USD_CENTS_DESC',
  FocusTokenTotalBalanceBaseUnitsAsc = 'FOCUS_TOKEN_TOTAL_BALANCE_BASE_UNITS_ASC',
  FocusTokenTotalBalanceBaseUnitsDesc = 'FOCUS_TOKEN_TOTAL_BALANCE_BASE_UNITS_DESC',
  FocusTokenTotalBalanceUsdCentsAsc = 'FOCUS_TOKEN_TOTAL_BALANCE_USD_CENTS_ASC',
  FocusTokenTotalBalanceUsdCentsDesc = 'FOCUS_TOKEN_TOTAL_BALANCE_USD_CENTS_DESC',
  LockedStakeDesoBalanceNanosAsc = 'LOCKED_STAKE_DESO_BALANCE_NANOS_ASC',
  LockedStakeDesoBalanceNanosDesc = 'LOCKED_STAKE_DESO_BALANCE_NANOS_DESC',
  Natural = 'NATURAL',
  PkidAsc = 'PKID_ASC',
  PkidDesc = 'PKID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  StakedDesoBalanceNanosAsc = 'STAKED_DESO_BALANCE_NANOS_ASC',
  StakedDesoBalanceNanosDesc = 'STAKED_DESO_BALANCE_NANOS_DESC',
  TotalBalanceUsdCentsAsc = 'TOTAL_BALANCE_USD_CENTS_ASC',
  TotalBalanceUsdCentsDesc = 'TOTAL_BALANCE_USD_CENTS_DESC',
  UnstakedDesoBalanceNanosAsc = 'UNSTAKED_DESO_BALANCE_NANOS_ASC',
  UnstakedDesoBalanceNanosDesc = 'UNSTAKED_DESO_BALANCE_NANOS_DESC',
  UsdBalanceBaseUnitsAsc = 'USD_BALANCE_BASE_UNITS_ASC',
  UsdBalanceBaseUnitsDesc = 'USD_BALANCE_BASE_UNITS_DESC',
  UsdBalanceUsdCentsAsc = 'USD_BALANCE_USD_CENTS_ASC',
  UsdBalanceUsdCentsDesc = 'USD_BALANCE_USD_CENTS_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
}

/**
 * A condition to be used against `AccountWealth` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AccountWealthCondition = {
  /** Checks for equality with the object’s `desoBalanceNanos` field. */
  desoBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `desoBalanceUsdCents` field. */
  desoBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `focusTokenBalanceBaseUnits` field. */
  focusTokenBalanceBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `focusTokenBalanceUsdCents` field. */
  focusTokenBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `focusTokenLockedBalanceBaseUnits` field. */
  focusTokenLockedBalanceBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `focusTokenLockedBalanceUsdCents` field. */
  focusTokenLockedBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `focusTokenTotalBalanceBaseUnits` field. */
  focusTokenTotalBalanceBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `focusTokenTotalBalanceUsdCents` field. */
  focusTokenTotalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `lockedStakeDesoBalanceNanos` field. */
  lockedStakeDesoBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `stakedDesoBalanceNanos` field. */
  stakedDesoBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalBalanceUsdCents` field. */
  totalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `unstakedDesoBalanceNanos` field. */
  unstakedDesoBalanceNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `usdBalanceBaseUnits` field. */
  usdBalanceBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `usdBalanceUsdCents` field. */
  usdBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `AccountWealth` values. */
export type AccountWealthConnection = {
  __typename?: 'AccountWealthConnection';
  /** A list of edges which contains the `AccountWealth` and cursor to aid in pagination. */
  edges: Array<AccountWealthEdge>;
  /** A list of `AccountWealth` objects. */
  nodes: Array<Maybe<AccountWealth>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AccountWealth` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `AccountWealth` edge in the connection. */
export type AccountWealthEdge = {
  __typename?: 'AccountWealthEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `AccountWealth` at the end of the edge. */
  node?: Maybe<AccountWealth>;
};

/** A filter to be used against `AccountWealth` object types. All fields are combined with a logical ‘and.’ */
export type AccountWealthFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AccountWealthFilter>>;
  /** Filter by the object’s `desoBalanceNanos` field. */
  desoBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `desoBalanceUsdCents` field. */
  desoBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `focusTokenBalanceBaseUnits` field. */
  focusTokenBalanceBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `focusTokenBalanceUsdCents` field. */
  focusTokenBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `focusTokenLockedBalanceBaseUnits` field. */
  focusTokenLockedBalanceBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `focusTokenLockedBalanceUsdCents` field. */
  focusTokenLockedBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `focusTokenTotalBalanceBaseUnits` field. */
  focusTokenTotalBalanceBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `focusTokenTotalBalanceUsdCents` field. */
  focusTokenTotalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `lockedStakeDesoBalanceNanos` field. */
  lockedStakeDesoBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AccountWealthFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AccountWealthFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stakedDesoBalanceNanos` field. */
  stakedDesoBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalBalanceUsdCents` field. */
  totalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `unstakedDesoBalanceNanos` field. */
  unstakedDesoBalanceNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `usdBalanceBaseUnits` field. */
  usdBalanceBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `usdBalanceUsdCents` field. */
  usdBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** Methods to use when ordering `AccountWealth`. */
export enum AccountWealthOrderBy {
  DesoBalanceNanosAsc = 'DESO_BALANCE_NANOS_ASC',
  DesoBalanceNanosDesc = 'DESO_BALANCE_NANOS_DESC',
  DesoBalanceUsdCentsAsc = 'DESO_BALANCE_USD_CENTS_ASC',
  DesoBalanceUsdCentsDesc = 'DESO_BALANCE_USD_CENTS_DESC',
  FocusTokenBalanceBaseUnitsAsc = 'FOCUS_TOKEN_BALANCE_BASE_UNITS_ASC',
  FocusTokenBalanceBaseUnitsDesc = 'FOCUS_TOKEN_BALANCE_BASE_UNITS_DESC',
  FocusTokenBalanceUsdCentsAsc = 'FOCUS_TOKEN_BALANCE_USD_CENTS_ASC',
  FocusTokenBalanceUsdCentsDesc = 'FOCUS_TOKEN_BALANCE_USD_CENTS_DESC',
  FocusTokenLockedBalanceBaseUnitsAsc = 'FOCUS_TOKEN_LOCKED_BALANCE_BASE_UNITS_ASC',
  FocusTokenLockedBalanceBaseUnitsDesc = 'FOCUS_TOKEN_LOCKED_BALANCE_BASE_UNITS_DESC',
  FocusTokenLockedBalanceUsdCentsAsc = 'FOCUS_TOKEN_LOCKED_BALANCE_USD_CENTS_ASC',
  FocusTokenLockedBalanceUsdCentsDesc = 'FOCUS_TOKEN_LOCKED_BALANCE_USD_CENTS_DESC',
  FocusTokenTotalBalanceBaseUnitsAsc = 'FOCUS_TOKEN_TOTAL_BALANCE_BASE_UNITS_ASC',
  FocusTokenTotalBalanceBaseUnitsDesc = 'FOCUS_TOKEN_TOTAL_BALANCE_BASE_UNITS_DESC',
  FocusTokenTotalBalanceUsdCentsAsc = 'FOCUS_TOKEN_TOTAL_BALANCE_USD_CENTS_ASC',
  FocusTokenTotalBalanceUsdCentsDesc = 'FOCUS_TOKEN_TOTAL_BALANCE_USD_CENTS_DESC',
  LockedStakeDesoBalanceNanosAsc = 'LOCKED_STAKE_DESO_BALANCE_NANOS_ASC',
  LockedStakeDesoBalanceNanosDesc = 'LOCKED_STAKE_DESO_BALANCE_NANOS_DESC',
  Natural = 'NATURAL',
  PkidAsc = 'PKID_ASC',
  PkidDesc = 'PKID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  StakedDesoBalanceNanosAsc = 'STAKED_DESO_BALANCE_NANOS_ASC',
  StakedDesoBalanceNanosDesc = 'STAKED_DESO_BALANCE_NANOS_DESC',
  TotalBalanceUsdCentsAsc = 'TOTAL_BALANCE_USD_CENTS_ASC',
  TotalBalanceUsdCentsDesc = 'TOTAL_BALANCE_USD_CENTS_DESC',
  UnstakedDesoBalanceNanosAsc = 'UNSTAKED_DESO_BALANCE_NANOS_ASC',
  UnstakedDesoBalanceNanosDesc = 'UNSTAKED_DESO_BALANCE_NANOS_DESC',
  UsdBalanceBaseUnitsAsc = 'USD_BALANCE_BASE_UNITS_ASC',
  UsdBalanceBaseUnitsDesc = 'USD_BALANCE_BASE_UNITS_DESC',
  UsdBalanceUsdCentsAsc = 'USD_BALANCE_USD_CENTS_ASC',
  UsdBalanceUsdCentsDesc = 'USD_BALANCE_USD_CENTS_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
}

/** A connection to a list of `Account` values. */
export type AccountsConnection = {
  __typename?: 'AccountsConnection';
  /** A list of edges which contains the `Account` and cursor to aid in pagination. */
  edges: Array<AccountsEdge>;
  /** A list of `Account` objects. */
  nodes: Array<Maybe<Account>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Account` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Account` edge in the connection. */
export type AccountsEdge = {
  __typename?: 'AccountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Account` at the end of the edge. */
  node?: Maybe<Account>;
};

/** Methods to use when ordering `Account`. */
export enum AccountsOrderBy {
  CcCoinsInCirculationNanosAsc = 'CC_COINS_IN_CIRCULATION_NANOS_ASC',
  CcCoinsInCirculationNanosDesc = 'CC_COINS_IN_CIRCULATION_NANOS_DESC',
  CoinPriceDesoNanosAsc = 'COIN_PRICE_DESO_NANOS_ASC',
  CoinPriceDesoNanosDesc = 'COIN_PRICE_DESO_NANOS_DESC',
  CoinWatermarkNanosAsc = 'COIN_WATERMARK_NANOS_ASC',
  CoinWatermarkNanosDesc = 'COIN_WATERMARK_NANOS_DESC',
  CreatorBasisPointsAsc = 'CREATOR_BASIS_POINTS_ASC',
  CreatorBasisPointsDesc = 'CREATOR_BASIS_POINTS_DESC',
  DaoCoinsInCirculationNanosHexAsc = 'DAO_COINS_IN_CIRCULATION_NANOS_HEX_ASC',
  DaoCoinsInCirculationNanosHexDesc = 'DAO_COINS_IN_CIRCULATION_NANOS_HEX_DESC',
  DaoCoinMintingDisabledAsc = 'DAO_COIN_MINTING_DISABLED_ASC',
  DaoCoinMintingDisabledDesc = 'DAO_COIN_MINTING_DISABLED_DESC',
  DaoCoinTransferRestrictionStatusAsc = 'DAO_COIN_TRANSFER_RESTRICTION_STATUS_ASC',
  DaoCoinTransferRestrictionStatusDesc = 'DAO_COIN_TRANSFER_RESTRICTION_STATUS_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DesoBalanceNanosAsc = 'DESO_BALANCE_NANOS_ASC',
  DesoBalanceNanosDesc = 'DESO_BALANCE_NANOS_DESC',
  DesoBalanceUsdCentsAsc = 'DESO_BALANCE_USD_CENTS_ASC',
  DesoBalanceUsdCentsDesc = 'DESO_BALANCE_USD_CENTS_DESC',
  DesoLockedNanosAsc = 'DESO_LOCKED_NANOS_ASC',
  DesoLockedNanosDesc = 'DESO_LOCKED_NANOS_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  FocusTokenBalanceBaseUnitsAsc = 'FOCUS_TOKEN_BALANCE_BASE_UNITS_ASC',
  FocusTokenBalanceBaseUnitsDesc = 'FOCUS_TOKEN_BALANCE_BASE_UNITS_DESC',
  FocusTokenBalanceUsdCentsAsc = 'FOCUS_TOKEN_BALANCE_USD_CENTS_ASC',
  FocusTokenBalanceUsdCentsDesc = 'FOCUS_TOKEN_BALANCE_USD_CENTS_DESC',
  FocusTokenLockedBalanceBaseUnitsAsc = 'FOCUS_TOKEN_LOCKED_BALANCE_BASE_UNITS_ASC',
  FocusTokenLockedBalanceBaseUnitsDesc = 'FOCUS_TOKEN_LOCKED_BALANCE_BASE_UNITS_DESC',
  FocusTokenLockedBalanceUsdCentsAsc = 'FOCUS_TOKEN_LOCKED_BALANCE_USD_CENTS_ASC',
  FocusTokenLockedBalanceUsdCentsDesc = 'FOCUS_TOKEN_LOCKED_BALANCE_USD_CENTS_DESC',
  FocusTokenTotalBalanceBaseUnitsAsc = 'FOCUS_TOKEN_TOTAL_BALANCE_BASE_UNITS_ASC',
  FocusTokenTotalBalanceBaseUnitsDesc = 'FOCUS_TOKEN_TOTAL_BALANCE_BASE_UNITS_DESC',
  FocusTokenTotalBalanceUsdCentsAsc = 'FOCUS_TOKEN_TOTAL_BALANCE_USD_CENTS_ASC',
  FocusTokenTotalBalanceUsdCentsDesc = 'FOCUS_TOKEN_TOTAL_BALANCE_USD_CENTS_DESC',
  IsVerifiedAsc = 'IS_VERIFIED_ASC',
  IsVerifiedDesc = 'IS_VERIFIED_DESC',
  MintingDisabledAsc = 'MINTING_DISABLED_ASC',
  MintingDisabledDesc = 'MINTING_DISABLED_DESC',
  Natural = 'NATURAL',
  PkidAsc = 'PKID_ASC',
  PkidDesc = 'PKID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProfilePicAsc = 'PROFILE_PIC_ASC',
  ProfilePicDesc = 'PROFILE_PIC_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  TotalBalanceUsdCentsAsc = 'TOTAL_BALANCE_USD_CENTS_ASC',
  TotalBalanceUsdCentsDesc = 'TOTAL_BALANCE_USD_CENTS_DESC',
  UsdBalanceBaseUnitsAsc = 'USD_BALANCE_BASE_UNITS_ASC',
  UsdBalanceBaseUnitsDesc = 'USD_BALANCE_BASE_UNITS_DESC',
  UsdBalanceUsdCentsAsc = 'USD_BALANCE_USD_CENTS_ASC',
  UsdBalanceUsdCentsDesc = 'USD_BALANCE_USD_CENTS_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
}

export type AffectedPublicKey = Node & {
  __typename?: 'AffectedPublicKey';
  /** Reads a single `Account` that is related to this `AffectedPublicKey`. */
  account?: Maybe<Account>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  isDuplicate: Scalars['Boolean']['output'];
  metadata: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['Datetime']['output']>;
  /** Reads a single `Transaction` that is related to this `AffectedPublicKey`. */
  transaction?: Maybe<Transaction>;
  transactionHash: Scalars['String']['output'];
  txnType: Scalars['Int']['output'];
};

/**
 * A condition to be used against `AffectedPublicKey` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AffectedPublicKeyCondition = {
  /** Checks for equality with the object’s `isDuplicate` field. */
  isDuplicate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `transactionHash` field. */
  transactionHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `txnType` field. */
  txnType?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `AffectedPublicKey` object types. All fields are combined with a logical ‘and.’ */
export type AffectedPublicKeyFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AffectedPublicKeyFilter>>;
  /** Filter by the object’s `isDuplicate` field. */
  isDuplicate?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AffectedPublicKeyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AffectedPublicKeyFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `transaction` relation. */
  transaction?: InputMaybe<TransactionFilter>;
  /** Filter by the object’s `transactionHash` field. */
  transactionHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `txnType` field. */
  txnType?: InputMaybe<IntFilter>;
};

/** A connection to a list of `AffectedPublicKey` values. */
export type AffectedPublicKeysConnection = {
  __typename?: 'AffectedPublicKeysConnection';
  /** A list of edges which contains the `AffectedPublicKey` and cursor to aid in pagination. */
  edges: Array<AffectedPublicKeysEdge>;
  /** A list of `AffectedPublicKey` objects. */
  nodes: Array<Maybe<AffectedPublicKey>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AffectedPublicKey` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `AffectedPublicKey` edge in the connection. */
export type AffectedPublicKeysEdge = {
  __typename?: 'AffectedPublicKeysEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `AffectedPublicKey` at the end of the edge. */
  node?: Maybe<AffectedPublicKey>;
};

/** Methods to use when ordering `AffectedPublicKey`. */
export enum AffectedPublicKeysOrderBy {
  IsDuplicateAsc = 'IS_DUPLICATE_ASC',
  IsDuplicateDesc = 'IS_DUPLICATE_DESC',
  MetadataAsc = 'METADATA_ASC',
  MetadataDesc = 'METADATA_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
  TransactionHashAsc = 'TRANSACTION_HASH_ASC',
  TransactionHashDesc = 'TRANSACTION_HASH_DESC',
  TxnTypeAsc = 'TXN_TYPE_ASC',
  TxnTypeDesc = 'TXN_TYPE_DESC',
}

/** All input for the `aggregatePostFeedInteractionsAll` mutation. */
export type AggregatePostFeedInteractionsAllInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsAll` mutation. */
export type AggregatePostFeedInteractionsAllPayload = {
  __typename?: 'AggregatePostFeedInteractionsAllPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsAllV2` mutation. */
export type AggregatePostFeedInteractionsAllV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsAllV2` mutation. */
export type AggregatePostFeedInteractionsAllV2Payload = {
  __typename?: 'AggregatePostFeedInteractionsAllV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractions` mutation. */
export type AggregatePostFeedInteractionsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** All input for the `aggregatePostFeedInteractionsNegativeReaction` mutation. */
export type AggregatePostFeedInteractionsNegativeReactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsNegativeReaction` mutation. */
export type AggregatePostFeedInteractionsNegativeReactionPayload = {
  __typename?: 'AggregatePostFeedInteractionsNegativeReactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsNegativeReactionV2` mutation. */
export type AggregatePostFeedInteractionsNegativeReactionV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsNegativeReactionV2` mutation. */
export type AggregatePostFeedInteractionsNegativeReactionV2Payload = {
  __typename?: 'AggregatePostFeedInteractionsNegativeReactionV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsNeutralReaction` mutation. */
export type AggregatePostFeedInteractionsNeutralReactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsNeutralReaction` mutation. */
export type AggregatePostFeedInteractionsNeutralReactionPayload = {
  __typename?: 'AggregatePostFeedInteractionsNeutralReactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsNeutralReactionV2` mutation. */
export type AggregatePostFeedInteractionsNeutralReactionV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsNeutralReactionV2` mutation. */
export type AggregatePostFeedInteractionsNeutralReactionV2Payload = {
  __typename?: 'AggregatePostFeedInteractionsNeutralReactionV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsNewPostsV2` mutation. */
export type AggregatePostFeedInteractionsNewPostsV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsNewPostsV2` mutation. */
export type AggregatePostFeedInteractionsNewPostsV2Payload = {
  __typename?: 'AggregatePostFeedInteractionsNewPostsV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our `aggregatePostFeedInteractions` mutation. */
export type AggregatePostFeedInteractionsPayload = {
  __typename?: 'AggregatePostFeedInteractionsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsPositiveReaction` mutation. */
export type AggregatePostFeedInteractionsPositiveReactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsPositiveReaction` mutation. */
export type AggregatePostFeedInteractionsPositiveReactionPayload = {
  __typename?: 'AggregatePostFeedInteractionsPositiveReactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsPositiveReactionV2` mutation. */
export type AggregatePostFeedInteractionsPositiveReactionV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsPositiveReactionV2` mutation. */
export type AggregatePostFeedInteractionsPositiveReactionV2Payload = {
  __typename?: 'AggregatePostFeedInteractionsPositiveReactionV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsQuote` mutation. */
export type AggregatePostFeedInteractionsQuoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsQuote` mutation. */
export type AggregatePostFeedInteractionsQuotePayload = {
  __typename?: 'AggregatePostFeedInteractionsQuotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsQuoteV2` mutation. */
export type AggregatePostFeedInteractionsQuoteV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsQuoteV2` mutation. */
export type AggregatePostFeedInteractionsQuoteV2Payload = {
  __typename?: 'AggregatePostFeedInteractionsQuoteV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsReply` mutation. */
export type AggregatePostFeedInteractionsReplyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsReply` mutation. */
export type AggregatePostFeedInteractionsReplyPayload = {
  __typename?: 'AggregatePostFeedInteractionsReplyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsReplyV2` mutation. */
export type AggregatePostFeedInteractionsReplyV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsReplyV2` mutation. */
export type AggregatePostFeedInteractionsReplyV2Payload = {
  __typename?: 'AggregatePostFeedInteractionsReplyV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsReport` mutation. */
export type AggregatePostFeedInteractionsReportInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsReport` mutation. */
export type AggregatePostFeedInteractionsReportPayload = {
  __typename?: 'AggregatePostFeedInteractionsReportPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsReportV2` mutation. */
export type AggregatePostFeedInteractionsReportV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsReportV2` mutation. */
export type AggregatePostFeedInteractionsReportV2Payload = {
  __typename?: 'AggregatePostFeedInteractionsReportV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsRepost` mutation. */
export type AggregatePostFeedInteractionsRepostInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsRepost` mutation. */
export type AggregatePostFeedInteractionsRepostPayload = {
  __typename?: 'AggregatePostFeedInteractionsRepostPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsRepostV2` mutation. */
export type AggregatePostFeedInteractionsRepostV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsRepostV2` mutation. */
export type AggregatePostFeedInteractionsRepostV2Payload = {
  __typename?: 'AggregatePostFeedInteractionsRepostV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsShowLess` mutation. */
export type AggregatePostFeedInteractionsShowLessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsShowLess` mutation. */
export type AggregatePostFeedInteractionsShowLessPayload = {
  __typename?: 'AggregatePostFeedInteractionsShowLessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsShowLessV2` mutation. */
export type AggregatePostFeedInteractionsShowLessV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsShowLessV2` mutation. */
export type AggregatePostFeedInteractionsShowLessV2Payload = {
  __typename?: 'AggregatePostFeedInteractionsShowLessV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsTip` mutation. */
export type AggregatePostFeedInteractionsTipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsTip` mutation. */
export type AggregatePostFeedInteractionsTipPayload = {
  __typename?: 'AggregatePostFeedInteractionsTipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsTipV2` mutation. */
export type AggregatePostFeedInteractionsTipV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsTipV2` mutation. */
export type AggregatePostFeedInteractionsTipV2Payload = {
  __typename?: 'AggregatePostFeedInteractionsTipV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregatePostFeedInteractionsView` mutation. */
export type AggregatePostFeedInteractionsViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregatePostFeedInteractionsView` mutation. */
export type AggregatePostFeedInteractionsViewPayload = {
  __typename?: 'AggregatePostFeedInteractionsViewPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsAll` mutation. */
export type AggregateUserFeedInteractionsAllInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsAll` mutation. */
export type AggregateUserFeedInteractionsAllPayload = {
  __typename?: 'AggregateUserFeedInteractionsAllPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsAllV2` mutation. */
export type AggregateUserFeedInteractionsAllV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsAllV2` mutation. */
export type AggregateUserFeedInteractionsAllV2Payload = {
  __typename?: 'AggregateUserFeedInteractionsAllV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsBlock` mutation. */
export type AggregateUserFeedInteractionsBlockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsBlock` mutation. */
export type AggregateUserFeedInteractionsBlockPayload = {
  __typename?: 'AggregateUserFeedInteractionsBlockPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsBlockV2` mutation. */
export type AggregateUserFeedInteractionsBlockV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsBlockV2` mutation. */
export type AggregateUserFeedInteractionsBlockV2Payload = {
  __typename?: 'AggregateUserFeedInteractionsBlockV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsFocusFollow` mutation. */
export type AggregateUserFeedInteractionsFocusFollowInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsFocusFollow` mutation. */
export type AggregateUserFeedInteractionsFocusFollowPayload = {
  __typename?: 'AggregateUserFeedInteractionsFocusFollowPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsFollow` mutation. */
export type AggregateUserFeedInteractionsFollowInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsFollow` mutation. */
export type AggregateUserFeedInteractionsFollowPayload = {
  __typename?: 'AggregateUserFeedInteractionsFollowPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsFollowV2` mutation. */
export type AggregateUserFeedInteractionsFollowV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsFollowV2` mutation. */
export type AggregateUserFeedInteractionsFollowV2Payload = {
  __typename?: 'AggregateUserFeedInteractionsFollowV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractions` mutation. */
export type AggregateUserFeedInteractionsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** All input for the `aggregateUserFeedInteractionsNegativeReaction` mutation. */
export type AggregateUserFeedInteractionsNegativeReactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsNegativeReaction` mutation. */
export type AggregateUserFeedInteractionsNegativeReactionPayload = {
  __typename?: 'AggregateUserFeedInteractionsNegativeReactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsNegativeReactionV2` mutation. */
export type AggregateUserFeedInteractionsNegativeReactionV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsNegativeReactionV2` mutation. */
export type AggregateUserFeedInteractionsNegativeReactionV2Payload = {
  __typename?: 'AggregateUserFeedInteractionsNegativeReactionV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsNeutralReaction` mutation. */
export type AggregateUserFeedInteractionsNeutralReactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsNeutralReaction` mutation. */
export type AggregateUserFeedInteractionsNeutralReactionPayload = {
  __typename?: 'AggregateUserFeedInteractionsNeutralReactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsNeutralReactionV2` mutation. */
export type AggregateUserFeedInteractionsNeutralReactionV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsNeutralReactionV2` mutation. */
export type AggregateUserFeedInteractionsNeutralReactionV2Payload = {
  __typename?: 'AggregateUserFeedInteractionsNeutralReactionV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our `aggregateUserFeedInteractions` mutation. */
export type AggregateUserFeedInteractionsPayload = {
  __typename?: 'AggregateUserFeedInteractionsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsPositiveReaction` mutation. */
export type AggregateUserFeedInteractionsPositiveReactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsPositiveReaction` mutation. */
export type AggregateUserFeedInteractionsPositiveReactionPayload = {
  __typename?: 'AggregateUserFeedInteractionsPositiveReactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsPositiveReactionV2` mutation. */
export type AggregateUserFeedInteractionsPositiveReactionV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsPositiveReactionV2` mutation. */
export type AggregateUserFeedInteractionsPositiveReactionV2Payload = {
  __typename?: 'AggregateUserFeedInteractionsPositiveReactionV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsQuote` mutation. */
export type AggregateUserFeedInteractionsQuoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsQuote` mutation. */
export type AggregateUserFeedInteractionsQuotePayload = {
  __typename?: 'AggregateUserFeedInteractionsQuotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsQuoteV2` mutation. */
export type AggregateUserFeedInteractionsQuoteV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsQuoteV2` mutation. */
export type AggregateUserFeedInteractionsQuoteV2Payload = {
  __typename?: 'AggregateUserFeedInteractionsQuoteV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsReply` mutation. */
export type AggregateUserFeedInteractionsReplyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsReply` mutation. */
export type AggregateUserFeedInteractionsReplyPayload = {
  __typename?: 'AggregateUserFeedInteractionsReplyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsReplyV2` mutation. */
export type AggregateUserFeedInteractionsReplyV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsReplyV2` mutation. */
export type AggregateUserFeedInteractionsReplyV2Payload = {
  __typename?: 'AggregateUserFeedInteractionsReplyV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsReport` mutation. */
export type AggregateUserFeedInteractionsReportInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsReport` mutation. */
export type AggregateUserFeedInteractionsReportPayload = {
  __typename?: 'AggregateUserFeedInteractionsReportPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsReportV2` mutation. */
export type AggregateUserFeedInteractionsReportV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsReportV2` mutation. */
export type AggregateUserFeedInteractionsReportV2Payload = {
  __typename?: 'AggregateUserFeedInteractionsReportV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsRepost` mutation. */
export type AggregateUserFeedInteractionsRepostInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsRepost` mutation. */
export type AggregateUserFeedInteractionsRepostPayload = {
  __typename?: 'AggregateUserFeedInteractionsRepostPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsRepostV2` mutation. */
export type AggregateUserFeedInteractionsRepostV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsRepostV2` mutation. */
export type AggregateUserFeedInteractionsRepostV2Payload = {
  __typename?: 'AggregateUserFeedInteractionsRepostV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsShowLess` mutation. */
export type AggregateUserFeedInteractionsShowLessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsShowLess` mutation. */
export type AggregateUserFeedInteractionsShowLessPayload = {
  __typename?: 'AggregateUserFeedInteractionsShowLessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsShowLessV2` mutation. */
export type AggregateUserFeedInteractionsShowLessV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsShowLessV2` mutation. */
export type AggregateUserFeedInteractionsShowLessV2Payload = {
  __typename?: 'AggregateUserFeedInteractionsShowLessV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsTip` mutation. */
export type AggregateUserFeedInteractionsTipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsTip` mutation. */
export type AggregateUserFeedInteractionsTipPayload = {
  __typename?: 'AggregateUserFeedInteractionsTipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsTipV2` mutation. */
export type AggregateUserFeedInteractionsTipV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsTipV2` mutation. */
export type AggregateUserFeedInteractionsTipV2Payload = {
  __typename?: 'AggregateUserFeedInteractionsTipV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `aggregateUserFeedInteractionsV2` mutation. */
export type AggregateUserFeedInteractionsV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `aggregateUserFeedInteractionsV2` mutation. */
export type AggregateUserFeedInteractionsV2Payload = {
  __typename?: 'AggregateUserFeedInteractionsV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['BigFloat']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['BigFloat']['input']>>;
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['BigInt']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['BigInt']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['BigInt']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['BigInt']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['BigInt']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export type Block = Node & {
  __typename?: 'Block';
  blockHash: Scalars['String']['output'];
  blockVersion?: Maybe<Scalars['BigInt']['output']>;
  /** Reads a single `EpochDetailsForBlock` that is related to this `Block`. */
  epochDetailForBlock?: Maybe<EpochDetailsForBlock>;
  extraNonce?: Maybe<Scalars['BigInt']['output']>;
  /** Reads and enables pagination through a set of `FeedUserAssociation`. */
  feedAssociations: FeedUserAssociationsConnection;
  height: Scalars['BigInt']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `NftBid`. */
  nftBids: NftBidsConnection;
  nonce?: Maybe<Scalars['BigInt']['output']>;
  /** Reads and enables pagination through a set of `PostAssociation`. */
  postAssociations: PostAssociationsConnection;
  prevBlockHash?: Maybe<Scalars['String']['output']>;
  proposedInView?: Maybe<Scalars['BigInt']['output']>;
  proposerRandomSeedSignature?: Maybe<Scalars['String']['output']>;
  proposerVotePartialSignature?: Maybe<Scalars['String']['output']>;
  proposerVotingPublicKey?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `StakeReward`. */
  stakeRewardForBlock: StakeRewardsConnection;
  timestamp: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions: TransactionsConnection;
  txnMerkleRoot: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `UserAssociation`. */
  userAssociations: UserAssociationsConnection;
};

export type BlockFeedAssociationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedUserAssociationCondition>;
  filter?: InputMaybe<FeedUserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedUserAssociationsOrderBy>>;
};

export type BlockNftBidsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<NftBidCondition>;
  filter?: InputMaybe<NftBidFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<NftBidsOrderBy>>;
};

export type BlockPostAssociationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostAssociationCondition>;
  filter?: InputMaybe<PostAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostAssociationsOrderBy>>;
};

export type BlockStakeRewardForBlockArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StakeRewardCondition>;
  filter?: InputMaybe<StakeRewardFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StakeRewardsOrderBy>>;
};

export type BlockTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

export type BlockUserAssociationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

/** A condition to be used against `Block` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type BlockCondition = {
  /** Checks for equality with the object’s `blockHash` field. */
  blockHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `blockVersion` field. */
  blockVersion?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `extraNonce` field. */
  extraNonce?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `height` field. */
  height?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `nonce` field. */
  nonce?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `prevBlockHash` field. */
  prevBlockHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `proposedInView` field. */
  proposedInView?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `proposerRandomSeedSignature` field. */
  proposerRandomSeedSignature?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `proposerVotePartialSignature` field. */
  proposerVotePartialSignature?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `proposerVotingPublicKey` field. */
  proposerVotingPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `txnMerkleRoot` field. */
  txnMerkleRoot?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Block` object types. All fields are combined with a logical ‘and.’ */
export type BlockFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BlockFilter>>;
  /** Filter by the object’s `blockHash` field. */
  blockHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `blockVersion` field. */
  blockVersion?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `epochDetailForBlock` relation. */
  epochDetailForBlock?: InputMaybe<EpochDetailsForBlockFilter>;
  /** A related `epochDetailForBlock` exists. */
  epochDetailForBlockExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `extraNonce` field. */
  extraNonce?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `feedAssociations` relation. */
  feedAssociations?: InputMaybe<BlockToManyFeedUserAssociationFilter>;
  /** Some related `feedAssociations` exist. */
  feedAssociationsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `height` field. */
  height?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `nftBids` relation. */
  nftBids?: InputMaybe<BlockToManyNftBidFilter>;
  /** Some related `nftBids` exist. */
  nftBidsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `nonce` field. */
  nonce?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BlockFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BlockFilter>>;
  /** Filter by the object’s `postAssociations` relation. */
  postAssociations?: InputMaybe<BlockToManyPostAssociationFilter>;
  /** Some related `postAssociations` exist. */
  postAssociationsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `prevBlockHash` field. */
  prevBlockHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `proposedInView` field. */
  proposedInView?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `proposerRandomSeedSignature` field. */
  proposerRandomSeedSignature?: InputMaybe<StringFilter>;
  /** Filter by the object’s `proposerVotePartialSignature` field. */
  proposerVotePartialSignature?: InputMaybe<StringFilter>;
  /** Filter by the object’s `proposerVotingPublicKey` field. */
  proposerVotingPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stakeRewardForBlock` relation. */
  stakeRewardForBlock?: InputMaybe<BlockToManyStakeRewardFilter>;
  /** Some related `stakeRewardForBlock` exist. */
  stakeRewardForBlockExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `transactions` relation. */
  transactions?: InputMaybe<BlockToManyTransactionFilter>;
  /** Some related `transactions` exist. */
  transactionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `txnMerkleRoot` field. */
  txnMerkleRoot?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userAssociations` relation. */
  userAssociations?: InputMaybe<BlockToManyUserAssociationFilter>;
  /** Some related `userAssociations` exist. */
  userAssociationsExist?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BlockSigner = Node & {
  __typename?: 'BlockSigner';
  blockHash: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  signerIndex: Scalars['BigInt']['output'];
};

/**
 * A condition to be used against `BlockSigner` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type BlockSignerCondition = {
  /** Checks for equality with the object’s `blockHash` field. */
  blockHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `signerIndex` field. */
  signerIndex?: InputMaybe<Scalars['BigInt']['input']>;
};

/** A filter to be used against `BlockSigner` object types. All fields are combined with a logical ‘and.’ */
export type BlockSignerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BlockSignerFilter>>;
  /** Filter by the object’s `blockHash` field. */
  blockHash?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BlockSignerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BlockSignerFilter>>;
  /** Filter by the object’s `signerIndex` field. */
  signerIndex?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `BlockSigner` values. */
export type BlockSignersConnection = {
  __typename?: 'BlockSignersConnection';
  /** A list of edges which contains the `BlockSigner` and cursor to aid in pagination. */
  edges: Array<BlockSignersEdge>;
  /** A list of `BlockSigner` objects. */
  nodes: Array<Maybe<BlockSigner>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BlockSigner` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `BlockSigner` edge in the connection. */
export type BlockSignersEdge = {
  __typename?: 'BlockSignersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `BlockSigner` at the end of the edge. */
  node?: Maybe<BlockSigner>;
};

/** Methods to use when ordering `BlockSigner`. */
export enum BlockSignersOrderBy {
  BlockHashAsc = 'BLOCK_HASH_ASC',
  BlockHashDesc = 'BLOCK_HASH_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SignerIndexAsc = 'SIGNER_INDEX_ASC',
  SignerIndexDesc = 'SIGNER_INDEX_DESC',
}

/** A filter to be used against many `FeedUserAssociation` object types. All fields are combined with a logical ‘and.’ */
export type BlockToManyFeedUserAssociationFilter = {
  /** Every related `FeedUserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FeedUserAssociationFilter>;
  /** No related `FeedUserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FeedUserAssociationFilter>;
  /** Some related `FeedUserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FeedUserAssociationFilter>;
};

/** A filter to be used against many `NftBid` object types. All fields are combined with a logical ‘and.’ */
export type BlockToManyNftBidFilter = {
  /** Every related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NftBidFilter>;
  /** No related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NftBidFilter>;
  /** Some related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NftBidFilter>;
};

/** A filter to be used against many `PostAssociation` object types. All fields are combined with a logical ‘and.’ */
export type BlockToManyPostAssociationFilter = {
  /** Every related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostAssociationFilter>;
  /** No related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostAssociationFilter>;
  /** Some related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostAssociationFilter>;
};

/** A filter to be used against many `StakeReward` object types. All fields are combined with a logical ‘and.’ */
export type BlockToManyStakeRewardFilter = {
  /** Every related `StakeReward` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<StakeRewardFilter>;
  /** No related `StakeReward` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<StakeRewardFilter>;
  /** Some related `StakeReward` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<StakeRewardFilter>;
};

/** A filter to be used against many `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type BlockToManyTransactionFilter = {
  /** Every related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TransactionFilter>;
  /** No related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TransactionFilter>;
  /** Some related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TransactionFilter>;
};

/** A filter to be used against many `UserAssociation` object types. All fields are combined with a logical ‘and.’ */
export type BlockToManyUserAssociationFilter = {
  /** Every related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserAssociationFilter>;
  /** No related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserAssociationFilter>;
  /** Some related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserAssociationFilter>;
};

/** A connection to a list of `Block` values. */
export type BlocksConnection = {
  __typename?: 'BlocksConnection';
  /** A list of edges which contains the `Block` and cursor to aid in pagination. */
  edges: Array<BlocksEdge>;
  /** A list of `Block` objects. */
  nodes: Array<Maybe<Block>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Block` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Block` edge in the connection. */
export type BlocksEdge = {
  __typename?: 'BlocksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Block` at the end of the edge. */
  node?: Maybe<Block>;
};

/** Methods to use when ordering `Block`. */
export enum BlocksOrderBy {
  BlockHashAsc = 'BLOCK_HASH_ASC',
  BlockHashDesc = 'BLOCK_HASH_DESC',
  BlockVersionAsc = 'BLOCK_VERSION_ASC',
  BlockVersionDesc = 'BLOCK_VERSION_DESC',
  ExtraNonceAsc = 'EXTRA_NONCE_ASC',
  ExtraNonceDesc = 'EXTRA_NONCE_DESC',
  HeightAsc = 'HEIGHT_ASC',
  HeightDesc = 'HEIGHT_DESC',
  Natural = 'NATURAL',
  NonceAsc = 'NONCE_ASC',
  NonceDesc = 'NONCE_DESC',
  PrevBlockHashAsc = 'PREV_BLOCK_HASH_ASC',
  PrevBlockHashDesc = 'PREV_BLOCK_HASH_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProposedInViewAsc = 'PROPOSED_IN_VIEW_ASC',
  ProposedInViewDesc = 'PROPOSED_IN_VIEW_DESC',
  ProposerRandomSeedSignatureAsc = 'PROPOSER_RANDOM_SEED_SIGNATURE_ASC',
  ProposerRandomSeedSignatureDesc = 'PROPOSER_RANDOM_SEED_SIGNATURE_DESC',
  ProposerVotePartialSignatureAsc = 'PROPOSER_VOTE_PARTIAL_SIGNATURE_ASC',
  ProposerVotePartialSignatureDesc = 'PROPOSER_VOTE_PARTIAL_SIGNATURE_DESC',
  ProposerVotingPublicKeyAsc = 'PROPOSER_VOTING_PUBLIC_KEY_ASC',
  ProposerVotingPublicKeyDesc = 'PROPOSER_VOTING_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
  TxnMerkleRootAsc = 'TXN_MERKLE_ROOT_ASC',
  TxnMerkleRootDesc = 'TXN_MERKLE_ROOT_DESC',
}

/** A connection to a list of `BlsPublicKeyPkidPairEntry` values. */
export type BlsPublicKeyPkidPairEntriesConnection = {
  __typename?: 'BlsPublicKeyPkidPairEntriesConnection';
  /** A list of edges which contains the `BlsPublicKeyPkidPairEntry` and cursor to aid in pagination. */
  edges: Array<BlsPublicKeyPkidPairEntriesEdge>;
  /** A list of `BlsPublicKeyPkidPairEntry` objects. */
  nodes: Array<Maybe<BlsPublicKeyPkidPairEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BlsPublicKeyPkidPairEntry` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `BlsPublicKeyPkidPairEntry` edge in the connection. */
export type BlsPublicKeyPkidPairEntriesEdge = {
  __typename?: 'BlsPublicKeyPkidPairEntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `BlsPublicKeyPkidPairEntry` at the end of the edge. */
  node?: Maybe<BlsPublicKeyPkidPairEntry>;
};

/** Methods to use when ordering `BlsPublicKeyPkidPairEntry`. */
export enum BlsPublicKeyPkidPairEntriesOrderBy {
  BadgerKeyAsc = 'BADGER_KEY_ASC',
  BadgerKeyDesc = 'BADGER_KEY_DESC',
  BlsPublicKeyAsc = 'BLS_PUBLIC_KEY_ASC',
  BlsPublicKeyDesc = 'BLS_PUBLIC_KEY_DESC',
  Natural = 'NATURAL',
  PkidAsc = 'PKID_ASC',
  PkidDesc = 'PKID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type BlsPublicKeyPkidPairEntry = Node & {
  __typename?: 'BlsPublicKeyPkidPairEntry';
  badgerKey: Scalars['String']['output'];
  blsPublicKey: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  pkid: Scalars['String']['output'];
};

/**
 * A condition to be used against `BlsPublicKeyPkidPairEntry` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type BlsPublicKeyPkidPairEntryCondition = {
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `blsPublicKey` field. */
  blsPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `BlsPublicKeyPkidPairEntry` object types. All fields are combined with a logical ‘and.’ */
export type BlsPublicKeyPkidPairEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BlsPublicKeyPkidPairEntryFilter>>;
  /** Filter by the object’s `blsPublicKey` field. */
  blsPublicKey?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BlsPublicKeyPkidPairEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BlsPublicKeyPkidPairEntryFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
};

/** A connection to a list of `BlsPublicKeyPkidPairSnapshotEntry` values. */
export type BlsPublicKeyPkidPairSnapshotEntriesConnection = {
  __typename?: 'BlsPublicKeyPkidPairSnapshotEntriesConnection';
  /** A list of edges which contains the `BlsPublicKeyPkidPairSnapshotEntry` and cursor to aid in pagination. */
  edges: Array<BlsPublicKeyPkidPairSnapshotEntriesEdge>;
  /** A list of `BlsPublicKeyPkidPairSnapshotEntry` objects. */
  nodes: Array<Maybe<BlsPublicKeyPkidPairSnapshotEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BlsPublicKeyPkidPairSnapshotEntry` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `BlsPublicKeyPkidPairSnapshotEntry` edge in the connection. */
export type BlsPublicKeyPkidPairSnapshotEntriesEdge = {
  __typename?: 'BlsPublicKeyPkidPairSnapshotEntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `BlsPublicKeyPkidPairSnapshotEntry` at the end of the edge. */
  node?: Maybe<BlsPublicKeyPkidPairSnapshotEntry>;
};

/** Methods to use when ordering `BlsPublicKeyPkidPairSnapshotEntry`. */
export enum BlsPublicKeyPkidPairSnapshotEntriesOrderBy {
  BlsPublicKeyAsc = 'BLS_PUBLIC_KEY_ASC',
  BlsPublicKeyDesc = 'BLS_PUBLIC_KEY_DESC',
  Natural = 'NATURAL',
  PkidAsc = 'PKID_ASC',
  PkidDesc = 'PKID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SnapshotAtEpochNumberAsc = 'SNAPSHOT_AT_EPOCH_NUMBER_ASC',
  SnapshotAtEpochNumberDesc = 'SNAPSHOT_AT_EPOCH_NUMBER_DESC',
}

export type BlsPublicKeyPkidPairSnapshotEntry = Node & {
  __typename?: 'BlsPublicKeyPkidPairSnapshotEntry';
  /** Reads a single `Account` that is related to this `BlsPublicKeyPkidPairSnapshotEntry`. */
  account?: Maybe<Account>;
  blsPublicKey: Scalars['String']['output'];
  /** Reads a single `EpochEntry` that is related to this `BlsPublicKeyPkidPairSnapshotEntry`. */
  epochEntry?: Maybe<EpochEntry>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  pkid: Scalars['String']['output'];
  snapshotAtEpochNumber: Scalars['BigInt']['output'];
};

/**
 * A condition to be used against `BlsPublicKeyPkidPairSnapshotEntry` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type BlsPublicKeyPkidPairSnapshotEntryCondition = {
  /** Checks for equality with the object’s `blsPublicKey` field. */
  blsPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<Scalars['BigInt']['input']>;
};

/** A filter to be used against `BlsPublicKeyPkidPairSnapshotEntry` object types. All fields are combined with a logical ‘and.’ */
export type BlsPublicKeyPkidPairSnapshotEntryFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BlsPublicKeyPkidPairSnapshotEntryFilter>>;
  /** Filter by the object’s `blsPublicKey` field. */
  blsPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `epochEntry` relation. */
  epochEntry?: InputMaybe<EpochEntryFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BlsPublicKeyPkidPairSnapshotEntryFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<BigIntFilter>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** All input for the `calculateDecay` mutation. */
export type CalculateDecayInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  lambda?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** The output of our `calculateDecay` mutation. */
export type CalculateDecayPayload = {
  __typename?: 'CalculateDecayPayload';
  bigFloat?: Maybe<Scalars['BigFloat']['output']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CoinCategoryCount = {
  __typename?: 'CoinCategoryCount';
  coinCategory?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
};

/**
 * A condition to be used against `CoinCategoryCount` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CoinCategoryCountCondition = {
  /** Checks for equality with the object’s `coinCategory` field. */
  coinCategory?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `count` field. */
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `CoinCategoryCount` object types. All fields are combined with a logical ‘and.’ */
export type CoinCategoryCountFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CoinCategoryCountFilter>>;
  /** Filter by the object’s `coinCategory` field. */
  coinCategory?: InputMaybe<StringFilter>;
  /** Filter by the object’s `count` field. */
  count?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CoinCategoryCountFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CoinCategoryCountFilter>>;
};

/** A connection to a list of `CoinCategoryCount` values. */
export type CoinCategoryCountsConnection = {
  __typename?: 'CoinCategoryCountsConnection';
  /** A list of edges which contains the `CoinCategoryCount` and cursor to aid in pagination. */
  edges: Array<CoinCategoryCountsEdge>;
  /** A list of `CoinCategoryCount` objects. */
  nodes: Array<Maybe<CoinCategoryCount>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CoinCategoryCount` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `CoinCategoryCount` edge in the connection. */
export type CoinCategoryCountsEdge = {
  __typename?: 'CoinCategoryCountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `CoinCategoryCount` at the end of the edge. */
  node?: Maybe<CoinCategoryCount>;
};

/** Methods to use when ordering `CoinCategoryCount`. */
export enum CoinCategoryCountsOrderBy {
  CoinCategoryAsc = 'COIN_CATEGORY_ASC',
  CoinCategoryDesc = 'COIN_CATEGORY_DESC',
  CountAsc = 'COUNT_ASC',
  CountDesc = 'COUNT_DESC',
  Natural = 'NATURAL',
}

/** All input for the `computeDecayScore` mutation. */
export type ComputeDecayScoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  decayRateLambda?: InputMaybe<Scalars['Float']['input']>;
  newBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  newEventBlockHeights?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  oldBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  oldDecayScore?: InputMaybe<Scalars['Float']['input']>;
};

/** The output of our `computeDecayScore` mutation. */
export type ComputeDecayScorePayload = {
  __typename?: 'ComputeDecayScorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  float?: Maybe<Scalars['Float']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `computeDecayScoreWithWealth` mutation. */
export type ComputeDecayScoreWithWealthInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  decayRateLambda?: InputMaybe<Scalars['Float']['input']>;
  maxContributionUsdCents?: InputMaybe<Scalars['Float']['input']>;
  newBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  newEventBlockHeights?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  newEventWealthUsdCents?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  oldBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  oldDecayScore?: InputMaybe<Scalars['Float']['input']>;
};

/** The output of our `computeDecayScoreWithWealth` mutation. */
export type ComputeDecayScoreWithWealthPayload = {
  __typename?: 'ComputeDecayScoreWithWealthPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  float?: Maybe<Scalars['Float']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `computeUpdatedDecayScore` mutation. */
export type ComputeUpdatedDecayScoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  decayRateLambda?: InputMaybe<Scalars['Float']['input']>;
  newBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  oldBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  oldDecayScore?: InputMaybe<Scalars['Float']['input']>;
};

/** The output of our `computeUpdatedDecayScore` mutation. */
export type ComputeUpdatedDecayScorePayload = {
  __typename?: 'ComputeUpdatedDecayScorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  float?: Maybe<Scalars['Float']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `computeWeightedPostScores` mutation. */
export type ComputeWeightedPostScoresInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
};

/** The output of our `computeWeightedPostScores` mutation. */
export type ComputeWeightedPostScoresPayload = {
  __typename?: 'ComputeWeightedPostScoresPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `computeWeightedUserRelationshipScores` mutation. */
export type ComputeWeightedUserRelationshipScoresInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `computeWeightedUserRelationshipScores` mutation. */
export type ComputeWeightedUserRelationshipScoresPayload = {
  __typename?: 'ComputeWeightedUserRelationshipScoresPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CreatorCoinBalance = Node & {
  __typename?: 'CreatorCoinBalance';
  balanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  coinPriceDesoNanos?: Maybe<Scalars['BigFloat']['output']>;
  /** Reads a single `Account` that is related to this `CreatorCoinBalance`. */
  creator?: Maybe<Account>;
  creatorPkid: Scalars['String']['output'];
  hasPurchased?: Maybe<Scalars['Boolean']['output']>;
  hodlerPkid: Scalars['String']['output'];
  /** Reads a single `Account` that is related to this `CreatorCoinBalance`. */
  holder?: Maybe<Account>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  totalValueNanos?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `CreatorCoinBalance` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CreatorCoinBalanceCondition = {
  /** Checks for equality with the object’s `balanceNanos` field. */
  balanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `coinPriceDesoNanos` field. */
  coinPriceDesoNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `creatorPkid` field. */
  creatorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `hasPurchased` field. */
  hasPurchased?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `totalValueNanos` field. */
  totalValueNanos?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `CreatorCoinBalance` object types. All fields are combined with a logical ‘and.’ */
export type CreatorCoinBalanceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CreatorCoinBalanceFilter>>;
  /** Filter by the object’s `balanceNanos` field. */
  balanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coinPriceDesoNanos` field. */
  coinPriceDesoNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `creator` relation. */
  creator?: InputMaybe<AccountFilter>;
  /** A related `creator` exists. */
  creatorExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `creatorPkid` field. */
  creatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hasPurchased` field. */
  hasPurchased?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `holder` relation. */
  holder?: InputMaybe<AccountFilter>;
  /** A related `holder` exists. */
  holderExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not?: InputMaybe<CreatorCoinBalanceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CreatorCoinBalanceFilter>>;
  /** Filter by the object’s `totalValueNanos` field. */
  totalValueNanos?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `CreatorCoinBalance` values. */
export type CreatorCoinBalancesConnection = {
  __typename?: 'CreatorCoinBalancesConnection';
  /** A list of edges which contains the `CreatorCoinBalance` and cursor to aid in pagination. */
  edges: Array<CreatorCoinBalancesEdge>;
  /** A list of `CreatorCoinBalance` objects. */
  nodes: Array<Maybe<CreatorCoinBalance>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CreatorCoinBalance` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `CreatorCoinBalance` edge in the connection. */
export type CreatorCoinBalancesEdge = {
  __typename?: 'CreatorCoinBalancesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `CreatorCoinBalance` at the end of the edge. */
  node?: Maybe<CreatorCoinBalance>;
};

/** Methods to use when ordering `CreatorCoinBalance`. */
export enum CreatorCoinBalancesOrderBy {
  BalanceNanosAsc = 'BALANCE_NANOS_ASC',
  BalanceNanosDesc = 'BALANCE_NANOS_DESC',
  CoinPriceDesoNanosAsc = 'COIN_PRICE_DESO_NANOS_ASC',
  CoinPriceDesoNanosDesc = 'COIN_PRICE_DESO_NANOS_DESC',
  CreatorPkidAsc = 'CREATOR_PKID_ASC',
  CreatorPkidDesc = 'CREATOR_PKID_DESC',
  HasPurchasedAsc = 'HAS_PURCHASED_ASC',
  HasPurchasedDesc = 'HAS_PURCHASED_DESC',
  HodlerPkidAsc = 'HODLER_PKID_ASC',
  HodlerPkidDesc = 'HODLER_PKID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TotalValueNanosAsc = 'TOTAL_VALUE_NANOS_ASC',
  TotalValueNanosDesc = 'TOTAL_VALUE_NANOS_DESC',
}

export type CreatorStat = Node & {
  __typename?: 'CreatorStat';
  /** Reads a single `Account` that is related to this `CreatorStat`. */
  account?: Maybe<Account>;
  ammMarketOpenTimestampUtc?: Maybe<Scalars['Datetime']['output']>;
  coinApyBasisPoints?: Maybe<Scalars['BigInt']['output']>;
  coinCategory?: Maybe<Scalars['String']['output']>;
  coinCirculatingSupply?: Maybe<Scalars['BigFloat']['output']>;
  coinMarketCapUsd?: Maybe<Scalars['Float']['output']>;
  coinNumHolders?: Maybe<Scalars['Int']['output']>;
  coinPriceUsd?: Maybe<Scalars['Float']['output']>;
  coinPriceUsdPctChange1H?: Maybe<Scalars['Float']['output']>;
  coinPriceUsdPctChange7D?: Maybe<Scalars['Float']['output']>;
  coinPriceUsdPctChange24H?: Maybe<Scalars['Float']['output']>;
  coinPriceUsdPctChange30D?: Maybe<Scalars['Float']['output']>;
  coinTotalSupply?: Maybe<Scalars['BigFloat']['output']>;
  coinVolumeUsdPast1H?: Maybe<Scalars['Float']['output']>;
  coinVolumeUsdPast7D?: Maybe<Scalars['Float']['output']>;
  coinVolumeUsdPast24H?: Maybe<Scalars['Float']['output']>;
  coinVolumeUsdPast30D?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  desoBalanceNanos?: Maybe<Scalars['BigInt']['output']>;
  desoBalanceUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  focusBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  focusBalanceUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  joinedFocusDate?: Maybe<Scalars['Date']['output']>;
  largeProfilePicUrl?: Maybe<Scalars['String']['output']>;
  lockedFocusBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  lockedFocusBalanceUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  pkid?: Maybe<Scalars['String']['output']>;
  publicKeyBase58Check: Scalars['String']['output'];
  tokenAmmCreatedAt?: Maybe<Scalars['Datetime']['output']>;
  tokenAmmLaunchAt?: Maybe<Scalars['Datetime']['output']>;
  totalAssetsBalanceUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  totalDiamondsUsdCentsReceivedAllTime?: Maybe<Scalars['BigFloat']['output']>;
  totalDiamondsUsdCentsReceivedPast7D?: Maybe<Scalars['BigFloat']['output']>;
  totalDiamondsUsdCentsReceivedPast24H?: Maybe<Scalars['BigFloat']['output']>;
  totalDiamondsUsdCentsReceivedPast30D?: Maybe<Scalars['BigFloat']['output']>;
  totalDiamondsUsdCentsSentAllTime?: Maybe<Scalars['BigFloat']['output']>;
  totalDiamondsUsdCentsSentPast7D?: Maybe<Scalars['BigFloat']['output']>;
  totalDiamondsUsdCentsSentPast24H?: Maybe<Scalars['BigFloat']['output']>;
  totalDiamondsUsdCentsSentPast30D?: Maybe<Scalars['BigFloat']['output']>;
  totalFocusEarningsUsdCentsAllTime?: Maybe<Scalars['Int']['output']>;
  totalFocusEarningsUsdCentsPast7D?: Maybe<Scalars['Int']['output']>;
  totalFocusEarningsUsdCentsPast24H?: Maybe<Scalars['Int']['output']>;
  totalFocusEarningsUsdCentsPast30D?: Maybe<Scalars['Int']['output']>;
  totalFocusPostViewersAssetsUsdCentsAllTime?: Maybe<Scalars['BigFloat']['output']>;
  totalFocusPostViewersAssetsUsdCentsPast7D?: Maybe<Scalars['BigFloat']['output']>;
  totalFocusPostViewersAssetsUsdCentsPast24H?: Maybe<Scalars['BigFloat']['output']>;
  totalFocusPostViewersAssetsUsdCentsPast30D?: Maybe<Scalars['BigFloat']['output']>;
  totalFocusPostViewsAllTime?: Maybe<Scalars['BigInt']['output']>;
  totalFocusPostViewsPast7D?: Maybe<Scalars['BigInt']['output']>;
  totalFocusPostViewsPast24H?: Maybe<Scalars['BigInt']['output']>;
  totalFocusPostViewsPast30D?: Maybe<Scalars['BigInt']['output']>;
  totalFocusWhalePostViewsAllTime?: Maybe<Scalars['BigInt']['output']>;
  totalFocusWhalePostViewsPast7D?: Maybe<Scalars['BigInt']['output']>;
  totalFocusWhalePostViewsPast24H?: Maybe<Scalars['BigInt']['output']>;
  totalFocusWhalePostViewsPast30D?: Maybe<Scalars['BigInt']['output']>;
  totalFollowers?: Maybe<Scalars['BigInt']['output']>;
  totalFollowersAssetsUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  totalFollowing?: Maybe<Scalars['BigInt']['output']>;
  totalJoiningRewardUsdCentsAllTime?: Maybe<Scalars['Int']['output']>;
  totalJoiningRewardUsdCentsPast7D?: Maybe<Scalars['Int']['output']>;
  totalJoiningRewardUsdCentsPast24H?: Maybe<Scalars['Int']['output']>;
  totalJoiningRewardUsdCentsPast30D?: Maybe<Scalars['Int']['output']>;
  totalLiquidAssetsBalanceUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  totalNegativeReactionsGivenAllTime?: Maybe<Scalars['BigInt']['output']>;
  totalNegativeReactionsGivenPast7D?: Maybe<Scalars['BigFloat']['output']>;
  totalNegativeReactionsGivenPast24H?: Maybe<Scalars['BigFloat']['output']>;
  totalNegativeReactionsGivenPast30D?: Maybe<Scalars['BigFloat']['output']>;
  totalNegativeReactionsReceivedAllTime?: Maybe<Scalars['BigInt']['output']>;
  totalNegativeReactionsReceivedPast7D?: Maybe<Scalars['BigFloat']['output']>;
  totalNegativeReactionsReceivedPast24H?: Maybe<Scalars['BigFloat']['output']>;
  totalNegativeReactionsReceivedPast30D?: Maybe<Scalars['BigFloat']['output']>;
  totalPaidMessageEarningsUsdCentsAllTime?: Maybe<Scalars['Int']['output']>;
  totalPaidMessageEarningsUsdCentsPast7D?: Maybe<Scalars['Int']['output']>;
  totalPaidMessageEarningsUsdCentsPast24H?: Maybe<Scalars['Int']['output']>;
  totalPaidMessageEarningsUsdCentsPast30D?: Maybe<Scalars['Int']['output']>;
  totalPaidMessages?: Maybe<Scalars['Int']['output']>;
  totalPaidMessagesRead?: Maybe<Scalars['Int']['output']>;
  totalPaidMessagesReadPercent?: Maybe<Scalars['Float']['output']>;
  totalPaidMessagesRepliedTo?: Maybe<Scalars['Int']['output']>;
  totalPaidMessagesRepliedToPercent?: Maybe<Scalars['Float']['output']>;
  totalPaidMessagesUnread?: Maybe<Scalars['Int']['output']>;
  totalPositiveReactionsGivenAllTime?: Maybe<Scalars['BigInt']['output']>;
  totalPositiveReactionsGivenPast7D?: Maybe<Scalars['BigFloat']['output']>;
  totalPositiveReactionsGivenPast24H?: Maybe<Scalars['BigFloat']['output']>;
  totalPositiveReactionsGivenPast30D?: Maybe<Scalars['BigFloat']['output']>;
  totalPositiveReactionsReceivedAllTime?: Maybe<Scalars['BigInt']['output']>;
  totalPositiveReactionsReceivedPast7D?: Maybe<Scalars['BigFloat']['output']>;
  totalPositiveReactionsReceivedPast24H?: Maybe<Scalars['BigFloat']['output']>;
  totalPositiveReactionsReceivedPast30D?: Maybe<Scalars['BigFloat']['output']>;
  totalPostsAllTime?: Maybe<Scalars['BigInt']['output']>;
  totalPostsPast7D?: Maybe<Scalars['BigInt']['output']>;
  totalPostsPast24H?: Maybe<Scalars['BigInt']['output']>;
  totalPostsPast30D?: Maybe<Scalars['BigInt']['output']>;
  totalReactionsGivenAllTime?: Maybe<Scalars['BigInt']['output']>;
  totalReactionsGivenPast7D?: Maybe<Scalars['BigFloat']['output']>;
  totalReactionsGivenPast24H?: Maybe<Scalars['BigFloat']['output']>;
  totalReactionsGivenPast30D?: Maybe<Scalars['BigFloat']['output']>;
  totalReactionsReceivedAllTime?: Maybe<Scalars['BigInt']['output']>;
  totalReactionsReceivedPast7D?: Maybe<Scalars['BigFloat']['output']>;
  totalReactionsReceivedPast24H?: Maybe<Scalars['BigFloat']['output']>;
  totalReactionsReceivedPast30D?: Maybe<Scalars['BigFloat']['output']>;
  totalReferralRewardsUsdCentsAllTime?: Maybe<Scalars['Int']['output']>;
  totalReferralRewardsUsdCentsPast7D?: Maybe<Scalars['Int']['output']>;
  totalReferralRewardsUsdCentsPast24H?: Maybe<Scalars['Int']['output']>;
  totalReferralRewardsUsdCentsPast30D?: Maybe<Scalars['Int']['output']>;
  totalSubscriptionEarningsUsdCentsAllTime?: Maybe<Scalars['Int']['output']>;
  totalSubscriptionEarningsUsdCentsPast7D?: Maybe<Scalars['Int']['output']>;
  totalSubscriptionEarningsUsdCentsPast24H?: Maybe<Scalars['Int']['output']>;
  totalSubscriptionEarningsUsdCentsPast30D?: Maybe<Scalars['Int']['output']>;
  totalViewedFocusPostsAllTime?: Maybe<Scalars['BigInt']['output']>;
  totalViewedFocusPostsPast7D?: Maybe<Scalars['BigInt']['output']>;
  totalViewedFocusPostsPast24H?: Maybe<Scalars['BigInt']['output']>;
  totalViewedFocusPostsPast30D?: Maybe<Scalars['BigInt']['output']>;
  totalWhaleFollowers?: Maybe<Scalars['BigInt']['output']>;
  twitterIsManuallyVerified?: Maybe<Scalars['Boolean']['output']>;
  twitterIsVerified?: Maybe<Scalars['Boolean']['output']>;
  twitterTotalFollowers?: Maybe<Scalars['Int']['output']>;
  twitterTotalFollowing?: Maybe<Scalars['Int']['output']>;
  twitterTotalTweets?: Maybe<Scalars['Int']['output']>;
  twitterUsername?: Maybe<Scalars['String']['output']>;
  usdcBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  usdcBalanceUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  usdtBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  usdtBalanceUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `CreatorStat` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CreatorStatCondition = {
  /** Checks for equality with the object’s `ammMarketOpenTimestampUtc` field. */
  ammMarketOpenTimestampUtc?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `coinApyBasisPoints` field. */
  coinApyBasisPoints?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `coinCategory` field. */
  coinCategory?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `coinCirculatingSupply` field. */
  coinCirculatingSupply?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `coinMarketCapUsd` field. */
  coinMarketCapUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `coinNumHolders` field. */
  coinNumHolders?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `coinPriceUsd` field. */
  coinPriceUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `coinPriceUsdPctChange1H` field. */
  coinPriceUsdPctChange1H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `coinPriceUsdPctChange7D` field. */
  coinPriceUsdPctChange7D?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `coinPriceUsdPctChange24H` field. */
  coinPriceUsdPctChange24H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `coinPriceUsdPctChange30D` field. */
  coinPriceUsdPctChange30D?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `coinTotalSupply` field. */
  coinTotalSupply?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `coinVolumeUsdPast1H` field. */
  coinVolumeUsdPast1H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `coinVolumeUsdPast7D` field. */
  coinVolumeUsdPast7D?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `coinVolumeUsdPast24H` field. */
  coinVolumeUsdPast24H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `coinVolumeUsdPast30D` field. */
  coinVolumeUsdPast30D?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `desoBalanceNanos` field. */
  desoBalanceNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `desoBalanceUsdCents` field. */
  desoBalanceUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `focusBalanceNanos` field. */
  focusBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `focusBalanceUsdCents` field. */
  focusBalanceUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `joinedFocusDate` field. */
  joinedFocusDate?: InputMaybe<Scalars['Date']['input']>;
  /** Checks for equality with the object’s `largeProfilePicUrl` field. */
  largeProfilePicUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `lockedFocusBalanceNanos` field. */
  lockedFocusBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `lockedFocusBalanceUsdCents` field. */
  lockedFocusBalanceUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKeyBase58Check` field. */
  publicKeyBase58Check?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenAmmCreatedAt` field. */
  tokenAmmCreatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `tokenAmmLaunchAt` field. */
  tokenAmmLaunchAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `totalAssetsBalanceUsdCents` field. */
  totalAssetsBalanceUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalDiamondsUsdCentsReceivedAllTime` field. */
  totalDiamondsUsdCentsReceivedAllTime?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalDiamondsUsdCentsReceivedPast7D` field. */
  totalDiamondsUsdCentsReceivedPast7D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalDiamondsUsdCentsReceivedPast24H` field. */
  totalDiamondsUsdCentsReceivedPast24H?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalDiamondsUsdCentsReceivedPast30D` field. */
  totalDiamondsUsdCentsReceivedPast30D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalDiamondsUsdCentsSentAllTime` field. */
  totalDiamondsUsdCentsSentAllTime?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalDiamondsUsdCentsSentPast7D` field. */
  totalDiamondsUsdCentsSentPast7D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalDiamondsUsdCentsSentPast24H` field. */
  totalDiamondsUsdCentsSentPast24H?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalDiamondsUsdCentsSentPast30D` field. */
  totalDiamondsUsdCentsSentPast30D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalFocusEarningsUsdCentsAllTime` field. */
  totalFocusEarningsUsdCentsAllTime?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalFocusEarningsUsdCentsPast7D` field. */
  totalFocusEarningsUsdCentsPast7D?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalFocusEarningsUsdCentsPast24H` field. */
  totalFocusEarningsUsdCentsPast24H?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalFocusEarningsUsdCentsPast30D` field. */
  totalFocusEarningsUsdCentsPast30D?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalFocusPostViewersAssetsUsdCentsAllTime` field. */
  totalFocusPostViewersAssetsUsdCentsAllTime?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalFocusPostViewersAssetsUsdCentsPast7D` field. */
  totalFocusPostViewersAssetsUsdCentsPast7D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalFocusPostViewersAssetsUsdCentsPast24H` field. */
  totalFocusPostViewersAssetsUsdCentsPast24H?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalFocusPostViewersAssetsUsdCentsPast30D` field. */
  totalFocusPostViewersAssetsUsdCentsPast30D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalFocusPostViewsAllTime` field. */
  totalFocusPostViewsAllTime?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalFocusPostViewsPast7D` field. */
  totalFocusPostViewsPast7D?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalFocusPostViewsPast24H` field. */
  totalFocusPostViewsPast24H?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalFocusPostViewsPast30D` field. */
  totalFocusPostViewsPast30D?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalFocusWhalePostViewsAllTime` field. */
  totalFocusWhalePostViewsAllTime?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalFocusWhalePostViewsPast7D` field. */
  totalFocusWhalePostViewsPast7D?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalFocusWhalePostViewsPast24H` field. */
  totalFocusWhalePostViewsPast24H?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalFocusWhalePostViewsPast30D` field. */
  totalFocusWhalePostViewsPast30D?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalFollowers` field. */
  totalFollowers?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalFollowersAssetsUsdCents` field. */
  totalFollowersAssetsUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalFollowing` field. */
  totalFollowing?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalJoiningRewardUsdCentsAllTime` field. */
  totalJoiningRewardUsdCentsAllTime?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalJoiningRewardUsdCentsPast7D` field. */
  totalJoiningRewardUsdCentsPast7D?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalJoiningRewardUsdCentsPast24H` field. */
  totalJoiningRewardUsdCentsPast24H?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalJoiningRewardUsdCentsPast30D` field. */
  totalJoiningRewardUsdCentsPast30D?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalLiquidAssetsBalanceUsdCents` field. */
  totalLiquidAssetsBalanceUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalNegativeReactionsGivenAllTime` field. */
  totalNegativeReactionsGivenAllTime?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalNegativeReactionsGivenPast7D` field. */
  totalNegativeReactionsGivenPast7D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalNegativeReactionsGivenPast24H` field. */
  totalNegativeReactionsGivenPast24H?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalNegativeReactionsGivenPast30D` field. */
  totalNegativeReactionsGivenPast30D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalNegativeReactionsReceivedAllTime` field. */
  totalNegativeReactionsReceivedAllTime?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalNegativeReactionsReceivedPast7D` field. */
  totalNegativeReactionsReceivedPast7D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalNegativeReactionsReceivedPast24H` field. */
  totalNegativeReactionsReceivedPast24H?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalNegativeReactionsReceivedPast30D` field. */
  totalNegativeReactionsReceivedPast30D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalPaidMessageEarningsUsdCentsAllTime` field. */
  totalPaidMessageEarningsUsdCentsAllTime?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalPaidMessageEarningsUsdCentsPast7D` field. */
  totalPaidMessageEarningsUsdCentsPast7D?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalPaidMessageEarningsUsdCentsPast24H` field. */
  totalPaidMessageEarningsUsdCentsPast24H?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalPaidMessageEarningsUsdCentsPast30D` field. */
  totalPaidMessageEarningsUsdCentsPast30D?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalPaidMessages` field. */
  totalPaidMessages?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalPaidMessagesRead` field. */
  totalPaidMessagesRead?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalPaidMessagesReadPercent` field. */
  totalPaidMessagesReadPercent?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `totalPaidMessagesRepliedTo` field. */
  totalPaidMessagesRepliedTo?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalPaidMessagesRepliedToPercent` field. */
  totalPaidMessagesRepliedToPercent?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `totalPaidMessagesUnread` field. */
  totalPaidMessagesUnread?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalPositiveReactionsGivenAllTime` field. */
  totalPositiveReactionsGivenAllTime?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalPositiveReactionsGivenPast7D` field. */
  totalPositiveReactionsGivenPast7D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalPositiveReactionsGivenPast24H` field. */
  totalPositiveReactionsGivenPast24H?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalPositiveReactionsGivenPast30D` field. */
  totalPositiveReactionsGivenPast30D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalPositiveReactionsReceivedAllTime` field. */
  totalPositiveReactionsReceivedAllTime?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalPositiveReactionsReceivedPast7D` field. */
  totalPositiveReactionsReceivedPast7D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalPositiveReactionsReceivedPast24H` field. */
  totalPositiveReactionsReceivedPast24H?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalPositiveReactionsReceivedPast30D` field. */
  totalPositiveReactionsReceivedPast30D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalPostsAllTime` field. */
  totalPostsAllTime?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalPostsPast7D` field. */
  totalPostsPast7D?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalPostsPast24H` field. */
  totalPostsPast24H?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalPostsPast30D` field. */
  totalPostsPast30D?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalReactionsGivenAllTime` field. */
  totalReactionsGivenAllTime?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalReactionsGivenPast7D` field. */
  totalReactionsGivenPast7D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalReactionsGivenPast24H` field. */
  totalReactionsGivenPast24H?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalReactionsGivenPast30D` field. */
  totalReactionsGivenPast30D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalReactionsReceivedAllTime` field. */
  totalReactionsReceivedAllTime?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalReactionsReceivedPast7D` field. */
  totalReactionsReceivedPast7D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalReactionsReceivedPast24H` field. */
  totalReactionsReceivedPast24H?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalReactionsReceivedPast30D` field. */
  totalReactionsReceivedPast30D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalReferralRewardsUsdCentsAllTime` field. */
  totalReferralRewardsUsdCentsAllTime?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalReferralRewardsUsdCentsPast7D` field. */
  totalReferralRewardsUsdCentsPast7D?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalReferralRewardsUsdCentsPast24H` field. */
  totalReferralRewardsUsdCentsPast24H?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalReferralRewardsUsdCentsPast30D` field. */
  totalReferralRewardsUsdCentsPast30D?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalSubscriptionEarningsUsdCentsAllTime` field. */
  totalSubscriptionEarningsUsdCentsAllTime?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalSubscriptionEarningsUsdCentsPast7D` field. */
  totalSubscriptionEarningsUsdCentsPast7D?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalSubscriptionEarningsUsdCentsPast24H` field. */
  totalSubscriptionEarningsUsdCentsPast24H?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalSubscriptionEarningsUsdCentsPast30D` field. */
  totalSubscriptionEarningsUsdCentsPast30D?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalViewedFocusPostsAllTime` field. */
  totalViewedFocusPostsAllTime?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalViewedFocusPostsPast7D` field. */
  totalViewedFocusPostsPast7D?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalViewedFocusPostsPast24H` field. */
  totalViewedFocusPostsPast24H?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalViewedFocusPostsPast30D` field. */
  totalViewedFocusPostsPast30D?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalWhaleFollowers` field. */
  totalWhaleFollowers?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `twitterIsManuallyVerified` field. */
  twitterIsManuallyVerified?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `twitterIsVerified` field. */
  twitterIsVerified?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `twitterTotalFollowers` field. */
  twitterTotalFollowers?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `twitterTotalFollowing` field. */
  twitterTotalFollowing?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `twitterTotalTweets` field. */
  twitterTotalTweets?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `twitterUsername` field. */
  twitterUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `usdcBalanceNanos` field. */
  usdcBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `usdcBalanceUsdCents` field. */
  usdcBalanceUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `usdtBalanceNanos` field. */
  usdtBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `usdtBalanceUsdCents` field. */
  usdtBalanceUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `CreatorStat` object types. All fields are combined with a logical ‘and.’ */
export type CreatorStatFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `ammMarketOpenTimestampUtc` field. */
  ammMarketOpenTimestampUtc?: InputMaybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CreatorStatFilter>>;
  /** Filter by the object’s `coinApyBasisPoints` field. */
  coinApyBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `coinCategory` field. */
  coinCategory?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coinCirculatingSupply` field. */
  coinCirculatingSupply?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coinMarketCapUsd` field. */
  coinMarketCapUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `coinNumHolders` field. */
  coinNumHolders?: InputMaybe<IntFilter>;
  /** Filter by the object’s `coinPriceUsd` field. */
  coinPriceUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `coinPriceUsdPctChange1H` field. */
  coinPriceUsdPctChange1H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `coinPriceUsdPctChange7D` field. */
  coinPriceUsdPctChange7D?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `coinPriceUsdPctChange24H` field. */
  coinPriceUsdPctChange24H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `coinPriceUsdPctChange30D` field. */
  coinPriceUsdPctChange30D?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `coinTotalSupply` field. */
  coinTotalSupply?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coinVolumeUsdPast1H` field. */
  coinVolumeUsdPast1H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `coinVolumeUsdPast7D` field. */
  coinVolumeUsdPast7D?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `coinVolumeUsdPast24H` field. */
  coinVolumeUsdPast24H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `coinVolumeUsdPast30D` field. */
  coinVolumeUsdPast30D?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `desoBalanceNanos` field. */
  desoBalanceNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `desoBalanceUsdCents` field. */
  desoBalanceUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `focusBalanceNanos` field. */
  focusBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `focusBalanceUsdCents` field. */
  focusBalanceUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `joinedFocusDate` field. */
  joinedFocusDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `largeProfilePicUrl` field. */
  largeProfilePicUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lockedFocusBalanceNanos` field. */
  lockedFocusBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `lockedFocusBalanceUsdCents` field. */
  lockedFocusBalanceUsdCents?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CreatorStatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CreatorStatFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKeyBase58Check` field. */
  publicKeyBase58Check?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenAmmCreatedAt` field. */
  tokenAmmCreatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `tokenAmmLaunchAt` field. */
  tokenAmmLaunchAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `totalAssetsBalanceUsdCents` field. */
  totalAssetsBalanceUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalDiamondsUsdCentsReceivedAllTime` field. */
  totalDiamondsUsdCentsReceivedAllTime?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalDiamondsUsdCentsReceivedPast7D` field. */
  totalDiamondsUsdCentsReceivedPast7D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalDiamondsUsdCentsReceivedPast24H` field. */
  totalDiamondsUsdCentsReceivedPast24H?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalDiamondsUsdCentsReceivedPast30D` field. */
  totalDiamondsUsdCentsReceivedPast30D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalDiamondsUsdCentsSentAllTime` field. */
  totalDiamondsUsdCentsSentAllTime?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalDiamondsUsdCentsSentPast7D` field. */
  totalDiamondsUsdCentsSentPast7D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalDiamondsUsdCentsSentPast24H` field. */
  totalDiamondsUsdCentsSentPast24H?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalDiamondsUsdCentsSentPast30D` field. */
  totalDiamondsUsdCentsSentPast30D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalFocusEarningsUsdCentsAllTime` field. */
  totalFocusEarningsUsdCentsAllTime?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalFocusEarningsUsdCentsPast7D` field. */
  totalFocusEarningsUsdCentsPast7D?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalFocusEarningsUsdCentsPast24H` field. */
  totalFocusEarningsUsdCentsPast24H?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalFocusEarningsUsdCentsPast30D` field. */
  totalFocusEarningsUsdCentsPast30D?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalFocusPostViewersAssetsUsdCentsAllTime` field. */
  totalFocusPostViewersAssetsUsdCentsAllTime?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalFocusPostViewersAssetsUsdCentsPast7D` field. */
  totalFocusPostViewersAssetsUsdCentsPast7D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalFocusPostViewersAssetsUsdCentsPast24H` field. */
  totalFocusPostViewersAssetsUsdCentsPast24H?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalFocusPostViewersAssetsUsdCentsPast30D` field. */
  totalFocusPostViewersAssetsUsdCentsPast30D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalFocusPostViewsAllTime` field. */
  totalFocusPostViewsAllTime?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalFocusPostViewsPast7D` field. */
  totalFocusPostViewsPast7D?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalFocusPostViewsPast24H` field. */
  totalFocusPostViewsPast24H?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalFocusPostViewsPast30D` field. */
  totalFocusPostViewsPast30D?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalFocusWhalePostViewsAllTime` field. */
  totalFocusWhalePostViewsAllTime?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalFocusWhalePostViewsPast7D` field. */
  totalFocusWhalePostViewsPast7D?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalFocusWhalePostViewsPast24H` field. */
  totalFocusWhalePostViewsPast24H?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalFocusWhalePostViewsPast30D` field. */
  totalFocusWhalePostViewsPast30D?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalFollowers` field. */
  totalFollowers?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalFollowersAssetsUsdCents` field. */
  totalFollowersAssetsUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalFollowing` field. */
  totalFollowing?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalJoiningRewardUsdCentsAllTime` field. */
  totalJoiningRewardUsdCentsAllTime?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalJoiningRewardUsdCentsPast7D` field. */
  totalJoiningRewardUsdCentsPast7D?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalJoiningRewardUsdCentsPast24H` field. */
  totalJoiningRewardUsdCentsPast24H?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalJoiningRewardUsdCentsPast30D` field. */
  totalJoiningRewardUsdCentsPast30D?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalLiquidAssetsBalanceUsdCents` field. */
  totalLiquidAssetsBalanceUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalNegativeReactionsGivenAllTime` field. */
  totalNegativeReactionsGivenAllTime?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalNegativeReactionsGivenPast7D` field. */
  totalNegativeReactionsGivenPast7D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalNegativeReactionsGivenPast24H` field. */
  totalNegativeReactionsGivenPast24H?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalNegativeReactionsGivenPast30D` field. */
  totalNegativeReactionsGivenPast30D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalNegativeReactionsReceivedAllTime` field. */
  totalNegativeReactionsReceivedAllTime?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalNegativeReactionsReceivedPast7D` field. */
  totalNegativeReactionsReceivedPast7D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalNegativeReactionsReceivedPast24H` field. */
  totalNegativeReactionsReceivedPast24H?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalNegativeReactionsReceivedPast30D` field. */
  totalNegativeReactionsReceivedPast30D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalPaidMessageEarningsUsdCentsAllTime` field. */
  totalPaidMessageEarningsUsdCentsAllTime?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalPaidMessageEarningsUsdCentsPast7D` field. */
  totalPaidMessageEarningsUsdCentsPast7D?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalPaidMessageEarningsUsdCentsPast24H` field. */
  totalPaidMessageEarningsUsdCentsPast24H?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalPaidMessageEarningsUsdCentsPast30D` field. */
  totalPaidMessageEarningsUsdCentsPast30D?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalPaidMessages` field. */
  totalPaidMessages?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalPaidMessagesRead` field. */
  totalPaidMessagesRead?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalPaidMessagesReadPercent` field. */
  totalPaidMessagesReadPercent?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `totalPaidMessagesRepliedTo` field. */
  totalPaidMessagesRepliedTo?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalPaidMessagesRepliedToPercent` field. */
  totalPaidMessagesRepliedToPercent?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `totalPaidMessagesUnread` field. */
  totalPaidMessagesUnread?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalPositiveReactionsGivenAllTime` field. */
  totalPositiveReactionsGivenAllTime?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalPositiveReactionsGivenPast7D` field. */
  totalPositiveReactionsGivenPast7D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalPositiveReactionsGivenPast24H` field. */
  totalPositiveReactionsGivenPast24H?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalPositiveReactionsGivenPast30D` field. */
  totalPositiveReactionsGivenPast30D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalPositiveReactionsReceivedAllTime` field. */
  totalPositiveReactionsReceivedAllTime?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalPositiveReactionsReceivedPast7D` field. */
  totalPositiveReactionsReceivedPast7D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalPositiveReactionsReceivedPast24H` field. */
  totalPositiveReactionsReceivedPast24H?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalPositiveReactionsReceivedPast30D` field. */
  totalPositiveReactionsReceivedPast30D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalPostsAllTime` field. */
  totalPostsAllTime?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalPostsPast7D` field. */
  totalPostsPast7D?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalPostsPast24H` field. */
  totalPostsPast24H?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalPostsPast30D` field. */
  totalPostsPast30D?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalReactionsGivenAllTime` field. */
  totalReactionsGivenAllTime?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalReactionsGivenPast7D` field. */
  totalReactionsGivenPast7D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalReactionsGivenPast24H` field. */
  totalReactionsGivenPast24H?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalReactionsGivenPast30D` field. */
  totalReactionsGivenPast30D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalReactionsReceivedAllTime` field. */
  totalReactionsReceivedAllTime?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalReactionsReceivedPast7D` field. */
  totalReactionsReceivedPast7D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalReactionsReceivedPast24H` field. */
  totalReactionsReceivedPast24H?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalReactionsReceivedPast30D` field. */
  totalReactionsReceivedPast30D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalReferralRewardsUsdCentsAllTime` field. */
  totalReferralRewardsUsdCentsAllTime?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalReferralRewardsUsdCentsPast7D` field. */
  totalReferralRewardsUsdCentsPast7D?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalReferralRewardsUsdCentsPast24H` field. */
  totalReferralRewardsUsdCentsPast24H?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalReferralRewardsUsdCentsPast30D` field. */
  totalReferralRewardsUsdCentsPast30D?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalSubscriptionEarningsUsdCentsAllTime` field. */
  totalSubscriptionEarningsUsdCentsAllTime?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalSubscriptionEarningsUsdCentsPast7D` field. */
  totalSubscriptionEarningsUsdCentsPast7D?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalSubscriptionEarningsUsdCentsPast24H` field. */
  totalSubscriptionEarningsUsdCentsPast24H?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalSubscriptionEarningsUsdCentsPast30D` field. */
  totalSubscriptionEarningsUsdCentsPast30D?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalViewedFocusPostsAllTime` field. */
  totalViewedFocusPostsAllTime?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalViewedFocusPostsPast7D` field. */
  totalViewedFocusPostsPast7D?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalViewedFocusPostsPast24H` field. */
  totalViewedFocusPostsPast24H?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalViewedFocusPostsPast30D` field. */
  totalViewedFocusPostsPast30D?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalWhaleFollowers` field. */
  totalWhaleFollowers?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `twitterIsManuallyVerified` field. */
  twitterIsManuallyVerified?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `twitterIsVerified` field. */
  twitterIsVerified?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `twitterTotalFollowers` field. */
  twitterTotalFollowers?: InputMaybe<IntFilter>;
  /** Filter by the object’s `twitterTotalFollowing` field. */
  twitterTotalFollowing?: InputMaybe<IntFilter>;
  /** Filter by the object’s `twitterTotalTweets` field. */
  twitterTotalTweets?: InputMaybe<IntFilter>;
  /** Filter by the object’s `twitterUsername` field. */
  twitterUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `usdcBalanceNanos` field. */
  usdcBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `usdcBalanceUsdCents` field. */
  usdcBalanceUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `usdtBalanceNanos` field. */
  usdtBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `usdtBalanceUsdCents` field. */
  usdtBalanceUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** A connection to a list of `CreatorStat` values. */
export type CreatorStatsConnection = {
  __typename?: 'CreatorStatsConnection';
  /** A list of edges which contains the `CreatorStat` and cursor to aid in pagination. */
  edges: Array<CreatorStatsEdge>;
  /** A list of `CreatorStat` objects. */
  nodes: Array<Maybe<CreatorStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CreatorStat` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `CreatorStat` edge in the connection. */
export type CreatorStatsEdge = {
  __typename?: 'CreatorStatsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `CreatorStat` at the end of the edge. */
  node?: Maybe<CreatorStat>;
};

/** Methods to use when ordering `CreatorStat`. */
export enum CreatorStatsOrderBy {
  AmmMarketOpenTimestampUtcAsc = 'AMM_MARKET_OPEN_TIMESTAMP_UTC_ASC',
  AmmMarketOpenTimestampUtcDesc = 'AMM_MARKET_OPEN_TIMESTAMP_UTC_DESC',
  CoinApyBasisPointsAsc = 'COIN_APY_BASIS_POINTS_ASC',
  CoinApyBasisPointsDesc = 'COIN_APY_BASIS_POINTS_DESC',
  CoinCategoryAsc = 'COIN_CATEGORY_ASC',
  CoinCategoryDesc = 'COIN_CATEGORY_DESC',
  CoinCirculatingSupplyAsc = 'COIN_CIRCULATING_SUPPLY_ASC',
  CoinCirculatingSupplyDesc = 'COIN_CIRCULATING_SUPPLY_DESC',
  CoinMarketCapUsdAsc = 'COIN_MARKET_CAP_USD_ASC',
  CoinMarketCapUsdDesc = 'COIN_MARKET_CAP_USD_DESC',
  CoinNumHoldersAsc = 'COIN_NUM_HOLDERS_ASC',
  CoinNumHoldersDesc = 'COIN_NUM_HOLDERS_DESC',
  CoinPriceUsdAsc = 'COIN_PRICE_USD_ASC',
  CoinPriceUsdDesc = 'COIN_PRICE_USD_DESC',
  CoinPriceUsdPctChange_1HAsc = 'COIN_PRICE_USD_PCT_CHANGE_1H_ASC',
  CoinPriceUsdPctChange_1HDesc = 'COIN_PRICE_USD_PCT_CHANGE_1H_DESC',
  CoinPriceUsdPctChange_7DAsc = 'COIN_PRICE_USD_PCT_CHANGE_7D_ASC',
  CoinPriceUsdPctChange_7DDesc = 'COIN_PRICE_USD_PCT_CHANGE_7D_DESC',
  CoinPriceUsdPctChange_24HAsc = 'COIN_PRICE_USD_PCT_CHANGE_24H_ASC',
  CoinPriceUsdPctChange_24HDesc = 'COIN_PRICE_USD_PCT_CHANGE_24H_DESC',
  CoinPriceUsdPctChange_30DAsc = 'COIN_PRICE_USD_PCT_CHANGE_30D_ASC',
  CoinPriceUsdPctChange_30DDesc = 'COIN_PRICE_USD_PCT_CHANGE_30D_DESC',
  CoinTotalSupplyAsc = 'COIN_TOTAL_SUPPLY_ASC',
  CoinTotalSupplyDesc = 'COIN_TOTAL_SUPPLY_DESC',
  CoinVolumeUsdPast_1HAsc = 'COIN_VOLUME_USD_PAST_1H_ASC',
  CoinVolumeUsdPast_1HDesc = 'COIN_VOLUME_USD_PAST_1H_DESC',
  CoinVolumeUsdPast_7DAsc = 'COIN_VOLUME_USD_PAST_7D_ASC',
  CoinVolumeUsdPast_7DDesc = 'COIN_VOLUME_USD_PAST_7D_DESC',
  CoinVolumeUsdPast_24HAsc = 'COIN_VOLUME_USD_PAST_24H_ASC',
  CoinVolumeUsdPast_24HDesc = 'COIN_VOLUME_USD_PAST_24H_DESC',
  CoinVolumeUsdPast_30DAsc = 'COIN_VOLUME_USD_PAST_30D_ASC',
  CoinVolumeUsdPast_30DDesc = 'COIN_VOLUME_USD_PAST_30D_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DesoBalanceNanosAsc = 'DESO_BALANCE_NANOS_ASC',
  DesoBalanceNanosDesc = 'DESO_BALANCE_NANOS_DESC',
  DesoBalanceUsdCentsAsc = 'DESO_BALANCE_USD_CENTS_ASC',
  DesoBalanceUsdCentsDesc = 'DESO_BALANCE_USD_CENTS_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  FocusBalanceNanosAsc = 'FOCUS_BALANCE_NANOS_ASC',
  FocusBalanceNanosDesc = 'FOCUS_BALANCE_NANOS_DESC',
  FocusBalanceUsdCentsAsc = 'FOCUS_BALANCE_USD_CENTS_ASC',
  FocusBalanceUsdCentsDesc = 'FOCUS_BALANCE_USD_CENTS_DESC',
  JoinedFocusDateAsc = 'JOINED_FOCUS_DATE_ASC',
  JoinedFocusDateDesc = 'JOINED_FOCUS_DATE_DESC',
  LargeProfilePicUrlAsc = 'LARGE_PROFILE_PIC_URL_ASC',
  LargeProfilePicUrlDesc = 'LARGE_PROFILE_PIC_URL_DESC',
  LockedFocusBalanceNanosAsc = 'LOCKED_FOCUS_BALANCE_NANOS_ASC',
  LockedFocusBalanceNanosDesc = 'LOCKED_FOCUS_BALANCE_NANOS_DESC',
  LockedFocusBalanceUsdCentsAsc = 'LOCKED_FOCUS_BALANCE_USD_CENTS_ASC',
  LockedFocusBalanceUsdCentsDesc = 'LOCKED_FOCUS_BALANCE_USD_CENTS_DESC',
  Natural = 'NATURAL',
  PkidAsc = 'PKID_ASC',
  PkidDesc = 'PKID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublicKeyBase58CheckAsc = 'PUBLIC_KEY_BASE58_CHECK_ASC',
  PublicKeyBase58CheckDesc = 'PUBLIC_KEY_BASE58_CHECK_DESC',
  TokenAmmCreatedAtAsc = 'TOKEN_AMM_CREATED_AT_ASC',
  TokenAmmCreatedAtDesc = 'TOKEN_AMM_CREATED_AT_DESC',
  TokenAmmLaunchAtAsc = 'TOKEN_AMM_LAUNCH_AT_ASC',
  TokenAmmLaunchAtDesc = 'TOKEN_AMM_LAUNCH_AT_DESC',
  TotalAssetsBalanceUsdCentsAsc = 'TOTAL_ASSETS_BALANCE_USD_CENTS_ASC',
  TotalAssetsBalanceUsdCentsDesc = 'TOTAL_ASSETS_BALANCE_USD_CENTS_DESC',
  TotalDiamondsUsdCentsReceivedAllTimeAsc = 'TOTAL_DIAMONDS_USD_CENTS_RECEIVED_ALL_TIME_ASC',
  TotalDiamondsUsdCentsReceivedAllTimeDesc = 'TOTAL_DIAMONDS_USD_CENTS_RECEIVED_ALL_TIME_DESC',
  TotalDiamondsUsdCentsReceivedPast_7DAsc = 'TOTAL_DIAMONDS_USD_CENTS_RECEIVED_PAST_7D_ASC',
  TotalDiamondsUsdCentsReceivedPast_7DDesc = 'TOTAL_DIAMONDS_USD_CENTS_RECEIVED_PAST_7D_DESC',
  TotalDiamondsUsdCentsReceivedPast_24HAsc = 'TOTAL_DIAMONDS_USD_CENTS_RECEIVED_PAST_24H_ASC',
  TotalDiamondsUsdCentsReceivedPast_24HDesc = 'TOTAL_DIAMONDS_USD_CENTS_RECEIVED_PAST_24H_DESC',
  TotalDiamondsUsdCentsReceivedPast_30DAsc = 'TOTAL_DIAMONDS_USD_CENTS_RECEIVED_PAST_30D_ASC',
  TotalDiamondsUsdCentsReceivedPast_30DDesc = 'TOTAL_DIAMONDS_USD_CENTS_RECEIVED_PAST_30D_DESC',
  TotalDiamondsUsdCentsSentAllTimeAsc = 'TOTAL_DIAMONDS_USD_CENTS_SENT_ALL_TIME_ASC',
  TotalDiamondsUsdCentsSentAllTimeDesc = 'TOTAL_DIAMONDS_USD_CENTS_SENT_ALL_TIME_DESC',
  TotalDiamondsUsdCentsSentPast_7DAsc = 'TOTAL_DIAMONDS_USD_CENTS_SENT_PAST_7D_ASC',
  TotalDiamondsUsdCentsSentPast_7DDesc = 'TOTAL_DIAMONDS_USD_CENTS_SENT_PAST_7D_DESC',
  TotalDiamondsUsdCentsSentPast_24HAsc = 'TOTAL_DIAMONDS_USD_CENTS_SENT_PAST_24H_ASC',
  TotalDiamondsUsdCentsSentPast_24HDesc = 'TOTAL_DIAMONDS_USD_CENTS_SENT_PAST_24H_DESC',
  TotalDiamondsUsdCentsSentPast_30DAsc = 'TOTAL_DIAMONDS_USD_CENTS_SENT_PAST_30D_ASC',
  TotalDiamondsUsdCentsSentPast_30DDesc = 'TOTAL_DIAMONDS_USD_CENTS_SENT_PAST_30D_DESC',
  TotalFocusEarningsUsdCentsAllTimeAsc = 'TOTAL_FOCUS_EARNINGS_USD_CENTS_ALL_TIME_ASC',
  TotalFocusEarningsUsdCentsAllTimeDesc = 'TOTAL_FOCUS_EARNINGS_USD_CENTS_ALL_TIME_DESC',
  TotalFocusEarningsUsdCentsPast_7DAsc = 'TOTAL_FOCUS_EARNINGS_USD_CENTS_PAST_7D_ASC',
  TotalFocusEarningsUsdCentsPast_7DDesc = 'TOTAL_FOCUS_EARNINGS_USD_CENTS_PAST_7D_DESC',
  TotalFocusEarningsUsdCentsPast_24HAsc = 'TOTAL_FOCUS_EARNINGS_USD_CENTS_PAST_24H_ASC',
  TotalFocusEarningsUsdCentsPast_24HDesc = 'TOTAL_FOCUS_EARNINGS_USD_CENTS_PAST_24H_DESC',
  TotalFocusEarningsUsdCentsPast_30DAsc = 'TOTAL_FOCUS_EARNINGS_USD_CENTS_PAST_30D_ASC',
  TotalFocusEarningsUsdCentsPast_30DDesc = 'TOTAL_FOCUS_EARNINGS_USD_CENTS_PAST_30D_DESC',
  TotalFocusPostViewersAssetsUsdCentsAllTimeAsc = 'TOTAL_FOCUS_POST_VIEWERS_ASSETS_USD_CENTS_ALL_TIME_ASC',
  TotalFocusPostViewersAssetsUsdCentsAllTimeDesc = 'TOTAL_FOCUS_POST_VIEWERS_ASSETS_USD_CENTS_ALL_TIME_DESC',
  TotalFocusPostViewersAssetsUsdCentsPast_7DAsc = 'TOTAL_FOCUS_POST_VIEWERS_ASSETS_USD_CENTS_PAST_7D_ASC',
  TotalFocusPostViewersAssetsUsdCentsPast_7DDesc = 'TOTAL_FOCUS_POST_VIEWERS_ASSETS_USD_CENTS_PAST_7D_DESC',
  TotalFocusPostViewersAssetsUsdCentsPast_24HAsc = 'TOTAL_FOCUS_POST_VIEWERS_ASSETS_USD_CENTS_PAST_24H_ASC',
  TotalFocusPostViewersAssetsUsdCentsPast_24HDesc = 'TOTAL_FOCUS_POST_VIEWERS_ASSETS_USD_CENTS_PAST_24H_DESC',
  TotalFocusPostViewersAssetsUsdCentsPast_30DAsc = 'TOTAL_FOCUS_POST_VIEWERS_ASSETS_USD_CENTS_PAST_30D_ASC',
  TotalFocusPostViewersAssetsUsdCentsPast_30DDesc = 'TOTAL_FOCUS_POST_VIEWERS_ASSETS_USD_CENTS_PAST_30D_DESC',
  TotalFocusPostViewsAllTimeAsc = 'TOTAL_FOCUS_POST_VIEWS_ALL_TIME_ASC',
  TotalFocusPostViewsAllTimeDesc = 'TOTAL_FOCUS_POST_VIEWS_ALL_TIME_DESC',
  TotalFocusPostViewsPast_7DAsc = 'TOTAL_FOCUS_POST_VIEWS_PAST_7D_ASC',
  TotalFocusPostViewsPast_7DDesc = 'TOTAL_FOCUS_POST_VIEWS_PAST_7D_DESC',
  TotalFocusPostViewsPast_24HAsc = 'TOTAL_FOCUS_POST_VIEWS_PAST_24H_ASC',
  TotalFocusPostViewsPast_24HDesc = 'TOTAL_FOCUS_POST_VIEWS_PAST_24H_DESC',
  TotalFocusPostViewsPast_30DAsc = 'TOTAL_FOCUS_POST_VIEWS_PAST_30D_ASC',
  TotalFocusPostViewsPast_30DDesc = 'TOTAL_FOCUS_POST_VIEWS_PAST_30D_DESC',
  TotalFocusWhalePostViewsAllTimeAsc = 'TOTAL_FOCUS_WHALE_POST_VIEWS_ALL_TIME_ASC',
  TotalFocusWhalePostViewsAllTimeDesc = 'TOTAL_FOCUS_WHALE_POST_VIEWS_ALL_TIME_DESC',
  TotalFocusWhalePostViewsPast_7DAsc = 'TOTAL_FOCUS_WHALE_POST_VIEWS_PAST_7D_ASC',
  TotalFocusWhalePostViewsPast_7DDesc = 'TOTAL_FOCUS_WHALE_POST_VIEWS_PAST_7D_DESC',
  TotalFocusWhalePostViewsPast_24HAsc = 'TOTAL_FOCUS_WHALE_POST_VIEWS_PAST_24H_ASC',
  TotalFocusWhalePostViewsPast_24HDesc = 'TOTAL_FOCUS_WHALE_POST_VIEWS_PAST_24H_DESC',
  TotalFocusWhalePostViewsPast_30DAsc = 'TOTAL_FOCUS_WHALE_POST_VIEWS_PAST_30D_ASC',
  TotalFocusWhalePostViewsPast_30DDesc = 'TOTAL_FOCUS_WHALE_POST_VIEWS_PAST_30D_DESC',
  TotalFollowersAsc = 'TOTAL_FOLLOWERS_ASC',
  TotalFollowersAssetsUsdCentsAsc = 'TOTAL_FOLLOWERS_ASSETS_USD_CENTS_ASC',
  TotalFollowersAssetsUsdCentsDesc = 'TOTAL_FOLLOWERS_ASSETS_USD_CENTS_DESC',
  TotalFollowersDesc = 'TOTAL_FOLLOWERS_DESC',
  TotalFollowingAsc = 'TOTAL_FOLLOWING_ASC',
  TotalFollowingDesc = 'TOTAL_FOLLOWING_DESC',
  TotalJoiningRewardUsdCentsAllTimeAsc = 'TOTAL_JOINING_REWARD_USD_CENTS_ALL_TIME_ASC',
  TotalJoiningRewardUsdCentsAllTimeDesc = 'TOTAL_JOINING_REWARD_USD_CENTS_ALL_TIME_DESC',
  TotalJoiningRewardUsdCentsPast_7DAsc = 'TOTAL_JOINING_REWARD_USD_CENTS_PAST_7D_ASC',
  TotalJoiningRewardUsdCentsPast_7DDesc = 'TOTAL_JOINING_REWARD_USD_CENTS_PAST_7D_DESC',
  TotalJoiningRewardUsdCentsPast_24HAsc = 'TOTAL_JOINING_REWARD_USD_CENTS_PAST_24H_ASC',
  TotalJoiningRewardUsdCentsPast_24HDesc = 'TOTAL_JOINING_REWARD_USD_CENTS_PAST_24H_DESC',
  TotalJoiningRewardUsdCentsPast_30DAsc = 'TOTAL_JOINING_REWARD_USD_CENTS_PAST_30D_ASC',
  TotalJoiningRewardUsdCentsPast_30DDesc = 'TOTAL_JOINING_REWARD_USD_CENTS_PAST_30D_DESC',
  TotalLiquidAssetsBalanceUsdCentsAsc = 'TOTAL_LIQUID_ASSETS_BALANCE_USD_CENTS_ASC',
  TotalLiquidAssetsBalanceUsdCentsDesc = 'TOTAL_LIQUID_ASSETS_BALANCE_USD_CENTS_DESC',
  TotalNegativeReactionsGivenAllTimeAsc = 'TOTAL_NEGATIVE_REACTIONS_GIVEN_ALL_TIME_ASC',
  TotalNegativeReactionsGivenAllTimeDesc = 'TOTAL_NEGATIVE_REACTIONS_GIVEN_ALL_TIME_DESC',
  TotalNegativeReactionsGivenPast_7DAsc = 'TOTAL_NEGATIVE_REACTIONS_GIVEN_PAST_7D_ASC',
  TotalNegativeReactionsGivenPast_7DDesc = 'TOTAL_NEGATIVE_REACTIONS_GIVEN_PAST_7D_DESC',
  TotalNegativeReactionsGivenPast_24HAsc = 'TOTAL_NEGATIVE_REACTIONS_GIVEN_PAST_24H_ASC',
  TotalNegativeReactionsGivenPast_24HDesc = 'TOTAL_NEGATIVE_REACTIONS_GIVEN_PAST_24H_DESC',
  TotalNegativeReactionsGivenPast_30DAsc = 'TOTAL_NEGATIVE_REACTIONS_GIVEN_PAST_30D_ASC',
  TotalNegativeReactionsGivenPast_30DDesc = 'TOTAL_NEGATIVE_REACTIONS_GIVEN_PAST_30D_DESC',
  TotalNegativeReactionsReceivedAllTimeAsc = 'TOTAL_NEGATIVE_REACTIONS_RECEIVED_ALL_TIME_ASC',
  TotalNegativeReactionsReceivedAllTimeDesc = 'TOTAL_NEGATIVE_REACTIONS_RECEIVED_ALL_TIME_DESC',
  TotalNegativeReactionsReceivedPast_7DAsc = 'TOTAL_NEGATIVE_REACTIONS_RECEIVED_PAST_7D_ASC',
  TotalNegativeReactionsReceivedPast_7DDesc = 'TOTAL_NEGATIVE_REACTIONS_RECEIVED_PAST_7D_DESC',
  TotalNegativeReactionsReceivedPast_24HAsc = 'TOTAL_NEGATIVE_REACTIONS_RECEIVED_PAST_24H_ASC',
  TotalNegativeReactionsReceivedPast_24HDesc = 'TOTAL_NEGATIVE_REACTIONS_RECEIVED_PAST_24H_DESC',
  TotalNegativeReactionsReceivedPast_30DAsc = 'TOTAL_NEGATIVE_REACTIONS_RECEIVED_PAST_30D_ASC',
  TotalNegativeReactionsReceivedPast_30DDesc = 'TOTAL_NEGATIVE_REACTIONS_RECEIVED_PAST_30D_DESC',
  TotalPaidMessagesAsc = 'TOTAL_PAID_MESSAGES_ASC',
  TotalPaidMessagesDesc = 'TOTAL_PAID_MESSAGES_DESC',
  TotalPaidMessagesReadAsc = 'TOTAL_PAID_MESSAGES_READ_ASC',
  TotalPaidMessagesReadDesc = 'TOTAL_PAID_MESSAGES_READ_DESC',
  TotalPaidMessagesReadPercentAsc = 'TOTAL_PAID_MESSAGES_READ_PERCENT_ASC',
  TotalPaidMessagesReadPercentDesc = 'TOTAL_PAID_MESSAGES_READ_PERCENT_DESC',
  TotalPaidMessagesRepliedToAsc = 'TOTAL_PAID_MESSAGES_REPLIED_TO_ASC',
  TotalPaidMessagesRepliedToDesc = 'TOTAL_PAID_MESSAGES_REPLIED_TO_DESC',
  TotalPaidMessagesRepliedToPercentAsc = 'TOTAL_PAID_MESSAGES_REPLIED_TO_PERCENT_ASC',
  TotalPaidMessagesRepliedToPercentDesc = 'TOTAL_PAID_MESSAGES_REPLIED_TO_PERCENT_DESC',
  TotalPaidMessagesUnreadAsc = 'TOTAL_PAID_MESSAGES_UNREAD_ASC',
  TotalPaidMessagesUnreadDesc = 'TOTAL_PAID_MESSAGES_UNREAD_DESC',
  TotalPaidMessageEarningsUsdCentsAllTimeAsc = 'TOTAL_PAID_MESSAGE_EARNINGS_USD_CENTS_ALL_TIME_ASC',
  TotalPaidMessageEarningsUsdCentsAllTimeDesc = 'TOTAL_PAID_MESSAGE_EARNINGS_USD_CENTS_ALL_TIME_DESC',
  TotalPaidMessageEarningsUsdCentsPast_7DAsc = 'TOTAL_PAID_MESSAGE_EARNINGS_USD_CENTS_PAST_7D_ASC',
  TotalPaidMessageEarningsUsdCentsPast_7DDesc = 'TOTAL_PAID_MESSAGE_EARNINGS_USD_CENTS_PAST_7D_DESC',
  TotalPaidMessageEarningsUsdCentsPast_24HAsc = 'TOTAL_PAID_MESSAGE_EARNINGS_USD_CENTS_PAST_24H_ASC',
  TotalPaidMessageEarningsUsdCentsPast_24HDesc = 'TOTAL_PAID_MESSAGE_EARNINGS_USD_CENTS_PAST_24H_DESC',
  TotalPaidMessageEarningsUsdCentsPast_30DAsc = 'TOTAL_PAID_MESSAGE_EARNINGS_USD_CENTS_PAST_30D_ASC',
  TotalPaidMessageEarningsUsdCentsPast_30DDesc = 'TOTAL_PAID_MESSAGE_EARNINGS_USD_CENTS_PAST_30D_DESC',
  TotalPositiveReactionsGivenAllTimeAsc = 'TOTAL_POSITIVE_REACTIONS_GIVEN_ALL_TIME_ASC',
  TotalPositiveReactionsGivenAllTimeDesc = 'TOTAL_POSITIVE_REACTIONS_GIVEN_ALL_TIME_DESC',
  TotalPositiveReactionsGivenPast_7DAsc = 'TOTAL_POSITIVE_REACTIONS_GIVEN_PAST_7D_ASC',
  TotalPositiveReactionsGivenPast_7DDesc = 'TOTAL_POSITIVE_REACTIONS_GIVEN_PAST_7D_DESC',
  TotalPositiveReactionsGivenPast_24HAsc = 'TOTAL_POSITIVE_REACTIONS_GIVEN_PAST_24H_ASC',
  TotalPositiveReactionsGivenPast_24HDesc = 'TOTAL_POSITIVE_REACTIONS_GIVEN_PAST_24H_DESC',
  TotalPositiveReactionsGivenPast_30DAsc = 'TOTAL_POSITIVE_REACTIONS_GIVEN_PAST_30D_ASC',
  TotalPositiveReactionsGivenPast_30DDesc = 'TOTAL_POSITIVE_REACTIONS_GIVEN_PAST_30D_DESC',
  TotalPositiveReactionsReceivedAllTimeAsc = 'TOTAL_POSITIVE_REACTIONS_RECEIVED_ALL_TIME_ASC',
  TotalPositiveReactionsReceivedAllTimeDesc = 'TOTAL_POSITIVE_REACTIONS_RECEIVED_ALL_TIME_DESC',
  TotalPositiveReactionsReceivedPast_7DAsc = 'TOTAL_POSITIVE_REACTIONS_RECEIVED_PAST_7D_ASC',
  TotalPositiveReactionsReceivedPast_7DDesc = 'TOTAL_POSITIVE_REACTIONS_RECEIVED_PAST_7D_DESC',
  TotalPositiveReactionsReceivedPast_24HAsc = 'TOTAL_POSITIVE_REACTIONS_RECEIVED_PAST_24H_ASC',
  TotalPositiveReactionsReceivedPast_24HDesc = 'TOTAL_POSITIVE_REACTIONS_RECEIVED_PAST_24H_DESC',
  TotalPositiveReactionsReceivedPast_30DAsc = 'TOTAL_POSITIVE_REACTIONS_RECEIVED_PAST_30D_ASC',
  TotalPositiveReactionsReceivedPast_30DDesc = 'TOTAL_POSITIVE_REACTIONS_RECEIVED_PAST_30D_DESC',
  TotalPostsAllTimeAsc = 'TOTAL_POSTS_ALL_TIME_ASC',
  TotalPostsAllTimeDesc = 'TOTAL_POSTS_ALL_TIME_DESC',
  TotalPostsPast_7DAsc = 'TOTAL_POSTS_PAST_7D_ASC',
  TotalPostsPast_7DDesc = 'TOTAL_POSTS_PAST_7D_DESC',
  TotalPostsPast_24HAsc = 'TOTAL_POSTS_PAST_24H_ASC',
  TotalPostsPast_24HDesc = 'TOTAL_POSTS_PAST_24H_DESC',
  TotalPostsPast_30DAsc = 'TOTAL_POSTS_PAST_30D_ASC',
  TotalPostsPast_30DDesc = 'TOTAL_POSTS_PAST_30D_DESC',
  TotalReactionsGivenAllTimeAsc = 'TOTAL_REACTIONS_GIVEN_ALL_TIME_ASC',
  TotalReactionsGivenAllTimeDesc = 'TOTAL_REACTIONS_GIVEN_ALL_TIME_DESC',
  TotalReactionsGivenPast_7DAsc = 'TOTAL_REACTIONS_GIVEN_PAST_7D_ASC',
  TotalReactionsGivenPast_7DDesc = 'TOTAL_REACTIONS_GIVEN_PAST_7D_DESC',
  TotalReactionsGivenPast_24HAsc = 'TOTAL_REACTIONS_GIVEN_PAST_24H_ASC',
  TotalReactionsGivenPast_24HDesc = 'TOTAL_REACTIONS_GIVEN_PAST_24H_DESC',
  TotalReactionsGivenPast_30DAsc = 'TOTAL_REACTIONS_GIVEN_PAST_30D_ASC',
  TotalReactionsGivenPast_30DDesc = 'TOTAL_REACTIONS_GIVEN_PAST_30D_DESC',
  TotalReactionsReceivedAllTimeAsc = 'TOTAL_REACTIONS_RECEIVED_ALL_TIME_ASC',
  TotalReactionsReceivedAllTimeDesc = 'TOTAL_REACTIONS_RECEIVED_ALL_TIME_DESC',
  TotalReactionsReceivedPast_7DAsc = 'TOTAL_REACTIONS_RECEIVED_PAST_7D_ASC',
  TotalReactionsReceivedPast_7DDesc = 'TOTAL_REACTIONS_RECEIVED_PAST_7D_DESC',
  TotalReactionsReceivedPast_24HAsc = 'TOTAL_REACTIONS_RECEIVED_PAST_24H_ASC',
  TotalReactionsReceivedPast_24HDesc = 'TOTAL_REACTIONS_RECEIVED_PAST_24H_DESC',
  TotalReactionsReceivedPast_30DAsc = 'TOTAL_REACTIONS_RECEIVED_PAST_30D_ASC',
  TotalReactionsReceivedPast_30DDesc = 'TOTAL_REACTIONS_RECEIVED_PAST_30D_DESC',
  TotalReferralRewardsUsdCentsAllTimeAsc = 'TOTAL_REFERRAL_REWARDS_USD_CENTS_ALL_TIME_ASC',
  TotalReferralRewardsUsdCentsAllTimeDesc = 'TOTAL_REFERRAL_REWARDS_USD_CENTS_ALL_TIME_DESC',
  TotalReferralRewardsUsdCentsPast_7DAsc = 'TOTAL_REFERRAL_REWARDS_USD_CENTS_PAST_7D_ASC',
  TotalReferralRewardsUsdCentsPast_7DDesc = 'TOTAL_REFERRAL_REWARDS_USD_CENTS_PAST_7D_DESC',
  TotalReferralRewardsUsdCentsPast_24HAsc = 'TOTAL_REFERRAL_REWARDS_USD_CENTS_PAST_24H_ASC',
  TotalReferralRewardsUsdCentsPast_24HDesc = 'TOTAL_REFERRAL_REWARDS_USD_CENTS_PAST_24H_DESC',
  TotalReferralRewardsUsdCentsPast_30DAsc = 'TOTAL_REFERRAL_REWARDS_USD_CENTS_PAST_30D_ASC',
  TotalReferralRewardsUsdCentsPast_30DDesc = 'TOTAL_REFERRAL_REWARDS_USD_CENTS_PAST_30D_DESC',
  TotalSubscriptionEarningsUsdCentsAllTimeAsc = 'TOTAL_SUBSCRIPTION_EARNINGS_USD_CENTS_ALL_TIME_ASC',
  TotalSubscriptionEarningsUsdCentsAllTimeDesc = 'TOTAL_SUBSCRIPTION_EARNINGS_USD_CENTS_ALL_TIME_DESC',
  TotalSubscriptionEarningsUsdCentsPast_7DAsc = 'TOTAL_SUBSCRIPTION_EARNINGS_USD_CENTS_PAST_7D_ASC',
  TotalSubscriptionEarningsUsdCentsPast_7DDesc = 'TOTAL_SUBSCRIPTION_EARNINGS_USD_CENTS_PAST_7D_DESC',
  TotalSubscriptionEarningsUsdCentsPast_24HAsc = 'TOTAL_SUBSCRIPTION_EARNINGS_USD_CENTS_PAST_24H_ASC',
  TotalSubscriptionEarningsUsdCentsPast_24HDesc = 'TOTAL_SUBSCRIPTION_EARNINGS_USD_CENTS_PAST_24H_DESC',
  TotalSubscriptionEarningsUsdCentsPast_30DAsc = 'TOTAL_SUBSCRIPTION_EARNINGS_USD_CENTS_PAST_30D_ASC',
  TotalSubscriptionEarningsUsdCentsPast_30DDesc = 'TOTAL_SUBSCRIPTION_EARNINGS_USD_CENTS_PAST_30D_DESC',
  TotalViewedFocusPostsAllTimeAsc = 'TOTAL_VIEWED_FOCUS_POSTS_ALL_TIME_ASC',
  TotalViewedFocusPostsAllTimeDesc = 'TOTAL_VIEWED_FOCUS_POSTS_ALL_TIME_DESC',
  TotalViewedFocusPostsPast_7DAsc = 'TOTAL_VIEWED_FOCUS_POSTS_PAST_7D_ASC',
  TotalViewedFocusPostsPast_7DDesc = 'TOTAL_VIEWED_FOCUS_POSTS_PAST_7D_DESC',
  TotalViewedFocusPostsPast_24HAsc = 'TOTAL_VIEWED_FOCUS_POSTS_PAST_24H_ASC',
  TotalViewedFocusPostsPast_24HDesc = 'TOTAL_VIEWED_FOCUS_POSTS_PAST_24H_DESC',
  TotalViewedFocusPostsPast_30DAsc = 'TOTAL_VIEWED_FOCUS_POSTS_PAST_30D_ASC',
  TotalViewedFocusPostsPast_30DDesc = 'TOTAL_VIEWED_FOCUS_POSTS_PAST_30D_DESC',
  TotalWhaleFollowersAsc = 'TOTAL_WHALE_FOLLOWERS_ASC',
  TotalWhaleFollowersDesc = 'TOTAL_WHALE_FOLLOWERS_DESC',
  TwitterIsManuallyVerifiedAsc = 'TWITTER_IS_MANUALLY_VERIFIED_ASC',
  TwitterIsManuallyVerifiedDesc = 'TWITTER_IS_MANUALLY_VERIFIED_DESC',
  TwitterIsVerifiedAsc = 'TWITTER_IS_VERIFIED_ASC',
  TwitterIsVerifiedDesc = 'TWITTER_IS_VERIFIED_DESC',
  TwitterTotalFollowersAsc = 'TWITTER_TOTAL_FOLLOWERS_ASC',
  TwitterTotalFollowersDesc = 'TWITTER_TOTAL_FOLLOWERS_DESC',
  TwitterTotalFollowingAsc = 'TWITTER_TOTAL_FOLLOWING_ASC',
  TwitterTotalFollowingDesc = 'TWITTER_TOTAL_FOLLOWING_DESC',
  TwitterTotalTweetsAsc = 'TWITTER_TOTAL_TWEETS_ASC',
  TwitterTotalTweetsDesc = 'TWITTER_TOTAL_TWEETS_DESC',
  TwitterUsernameAsc = 'TWITTER_USERNAME_ASC',
  TwitterUsernameDesc = 'TWITTER_USERNAME_DESC',
  UsdcBalanceNanosAsc = 'USDC_BALANCE_NANOS_ASC',
  UsdcBalanceNanosDesc = 'USDC_BALANCE_NANOS_DESC',
  UsdcBalanceUsdCentsAsc = 'USDC_BALANCE_USD_CENTS_ASC',
  UsdcBalanceUsdCentsDesc = 'USDC_BALANCE_USD_CENTS_DESC',
  UsdtBalanceNanosAsc = 'USDT_BALANCE_NANOS_ASC',
  UsdtBalanceNanosDesc = 'USDT_BALANCE_NANOS_DESC',
  UsdtBalanceUsdCentsAsc = 'USDT_BALANCE_USD_CENTS_ASC',
  UsdtBalanceUsdCentsDesc = 'USDT_BALANCE_USD_CENTS_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
}

export type CreatorStatsTotalPaidMessageEarningsUsdCentsAllTime = {
  __typename?: 'CreatorStatsTotalPaidMessageEarningsUsdCentsAllTime';
  totalEarningsUsdCents?: Maybe<Scalars['Int']['output']>;
  userPublicKeyBase58Check?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against
 * `CreatorStatsTotalPaidMessageEarningsUsdCentsAllTime` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimeCondition = {
  /** Checks for equality with the object’s `totalEarningsUsdCents` field. */
  totalEarningsUsdCents?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `userPublicKeyBase58Check` field. */
  userPublicKeyBase58Check?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `CreatorStatsTotalPaidMessageEarningsUsdCentsAllTime` object types. All fields are combined with a logical ‘and.’ */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimeFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimeFilter>>;
  /** Filter by the object’s `totalEarningsUsdCents` field. */
  totalEarningsUsdCents?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userPublicKeyBase58Check` field. */
  userPublicKeyBase58Check?: InputMaybe<StringFilter>;
};

/** A connection to a list of `CreatorStatsTotalPaidMessageEarningsUsdCentsAllTime` values. */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimesConnection = {
  __typename?: 'CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimesConnection';
  /** A list of edges which contains the `CreatorStatsTotalPaidMessageEarningsUsdCentsAllTime` and cursor to aid in pagination. */
  edges: Array<CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimesEdge>;
  /** A list of `CreatorStatsTotalPaidMessageEarningsUsdCentsAllTime` objects. */
  nodes: Array<Maybe<CreatorStatsTotalPaidMessageEarningsUsdCentsAllTime>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CreatorStatsTotalPaidMessageEarningsUsdCentsAllTime` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `CreatorStatsTotalPaidMessageEarningsUsdCentsAllTime` edge in the connection. */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimesEdge = {
  __typename?: 'CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `CreatorStatsTotalPaidMessageEarningsUsdCentsAllTime` at the end of the edge. */
  node?: Maybe<CreatorStatsTotalPaidMessageEarningsUsdCentsAllTime>;
};

/** Methods to use when ordering `CreatorStatsTotalPaidMessageEarningsUsdCentsAllTime`. */
export enum CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimesOrderBy {
  Natural = 'NATURAL',
  TotalEarningsUsdCentsAsc = 'TOTAL_EARNINGS_USD_CENTS_ASC',
  TotalEarningsUsdCentsDesc = 'TOTAL_EARNINGS_USD_CENTS_DESC',
  UserPublicKeyBase58CheckAsc = 'USER_PUBLIC_KEY_BASE58_CHECK_ASC',
  UserPublicKeyBase58CheckDesc = 'USER_PUBLIC_KEY_BASE58_CHECK_DESC',
}

export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast7D = {
  __typename?: 'CreatorStatsTotalPaidMessageEarningsUsdCentsPast7D';
  totalEarningsUsdCents?: Maybe<Scalars['Int']['output']>;
  userPublicKeyBase58Check?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against
 * `CreatorStatsTotalPaidMessageEarningsUsdCentsPast7D` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DCondition = {
  /** Checks for equality with the object’s `totalEarningsUsdCents` field. */
  totalEarningsUsdCents?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `userPublicKeyBase58Check` field. */
  userPublicKeyBase58Check?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `CreatorStatsTotalPaidMessageEarningsUsdCentsPast7D` object types. All fields are combined with a logical ‘and.’ */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DFilter>>;
  /** Filter by the object’s `totalEarningsUsdCents` field. */
  totalEarningsUsdCents?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userPublicKeyBase58Check` field. */
  userPublicKeyBase58Check?: InputMaybe<StringFilter>;
};

/** A connection to a list of `CreatorStatsTotalPaidMessageEarningsUsdCentsPast7D` values. */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DsConnection = {
  __typename?: 'CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DsConnection';
  /** A list of edges which contains the `CreatorStatsTotalPaidMessageEarningsUsdCentsPast7D` and cursor to aid in pagination. */
  edges: Array<CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DsEdge>;
  /** A list of `CreatorStatsTotalPaidMessageEarningsUsdCentsPast7D` objects. */
  nodes: Array<Maybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast7D>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CreatorStatsTotalPaidMessageEarningsUsdCentsPast7D` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `CreatorStatsTotalPaidMessageEarningsUsdCentsPast7D` edge in the connection. */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DsEdge = {
  __typename?: 'CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `CreatorStatsTotalPaidMessageEarningsUsdCentsPast7D` at the end of the edge. */
  node?: Maybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast7D>;
};

/** Methods to use when ordering `CreatorStatsTotalPaidMessageEarningsUsdCentsPast7D`. */
export enum CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DsOrderBy {
  Natural = 'NATURAL',
  TotalEarningsUsdCentsAsc = 'TOTAL_EARNINGS_USD_CENTS_ASC',
  TotalEarningsUsdCentsDesc = 'TOTAL_EARNINGS_USD_CENTS_DESC',
  UserPublicKeyBase58CheckAsc = 'USER_PUBLIC_KEY_BASE58_CHECK_ASC',
  UserPublicKeyBase58CheckDesc = 'USER_PUBLIC_KEY_BASE58_CHECK_DESC',
}

export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast24H = {
  __typename?: 'CreatorStatsTotalPaidMessageEarningsUsdCentsPast24H';
  totalEarningsUsdCents?: Maybe<Scalars['Int']['output']>;
  userPublicKeyBase58Check?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against
 * `CreatorStatsTotalPaidMessageEarningsUsdCentsPast24H` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HCondition = {
  /** Checks for equality with the object’s `totalEarningsUsdCents` field. */
  totalEarningsUsdCents?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `userPublicKeyBase58Check` field. */
  userPublicKeyBase58Check?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `CreatorStatsTotalPaidMessageEarningsUsdCentsPast24H` object types. All fields are combined with a logical ‘and.’ */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HFilter>>;
  /** Filter by the object’s `totalEarningsUsdCents` field. */
  totalEarningsUsdCents?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userPublicKeyBase58Check` field. */
  userPublicKeyBase58Check?: InputMaybe<StringFilter>;
};

/** A connection to a list of `CreatorStatsTotalPaidMessageEarningsUsdCentsPast24H` values. */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HsConnection = {
  __typename?: 'CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HsConnection';
  /** A list of edges which contains the `CreatorStatsTotalPaidMessageEarningsUsdCentsPast24H` and cursor to aid in pagination. */
  edges: Array<CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HsEdge>;
  /** A list of `CreatorStatsTotalPaidMessageEarningsUsdCentsPast24H` objects. */
  nodes: Array<Maybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast24H>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CreatorStatsTotalPaidMessageEarningsUsdCentsPast24H` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `CreatorStatsTotalPaidMessageEarningsUsdCentsPast24H` edge in the connection. */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HsEdge = {
  __typename?: 'CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `CreatorStatsTotalPaidMessageEarningsUsdCentsPast24H` at the end of the edge. */
  node?: Maybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast24H>;
};

/** Methods to use when ordering `CreatorStatsTotalPaidMessageEarningsUsdCentsPast24H`. */
export enum CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HsOrderBy {
  Natural = 'NATURAL',
  TotalEarningsUsdCentsAsc = 'TOTAL_EARNINGS_USD_CENTS_ASC',
  TotalEarningsUsdCentsDesc = 'TOTAL_EARNINGS_USD_CENTS_DESC',
  UserPublicKeyBase58CheckAsc = 'USER_PUBLIC_KEY_BASE58_CHECK_ASC',
  UserPublicKeyBase58CheckDesc = 'USER_PUBLIC_KEY_BASE58_CHECK_DESC',
}

export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast30D = {
  __typename?: 'CreatorStatsTotalPaidMessageEarningsUsdCentsPast30D';
  totalEarningsUsdCents?: Maybe<Scalars['Int']['output']>;
  userPublicKeyBase58Check?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against
 * `CreatorStatsTotalPaidMessageEarningsUsdCentsPast30D` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DCondition = {
  /** Checks for equality with the object’s `totalEarningsUsdCents` field. */
  totalEarningsUsdCents?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `userPublicKeyBase58Check` field. */
  userPublicKeyBase58Check?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `CreatorStatsTotalPaidMessageEarningsUsdCentsPast30D` object types. All fields are combined with a logical ‘and.’ */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DFilter>>;
  /** Filter by the object’s `totalEarningsUsdCents` field. */
  totalEarningsUsdCents?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userPublicKeyBase58Check` field. */
  userPublicKeyBase58Check?: InputMaybe<StringFilter>;
};

/** A connection to a list of `CreatorStatsTotalPaidMessageEarningsUsdCentsPast30D` values. */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DsConnection = {
  __typename?: 'CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DsConnection';
  /** A list of edges which contains the `CreatorStatsTotalPaidMessageEarningsUsdCentsPast30D` and cursor to aid in pagination. */
  edges: Array<CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DsEdge>;
  /** A list of `CreatorStatsTotalPaidMessageEarningsUsdCentsPast30D` objects. */
  nodes: Array<Maybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast30D>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CreatorStatsTotalPaidMessageEarningsUsdCentsPast30D` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `CreatorStatsTotalPaidMessageEarningsUsdCentsPast30D` edge in the connection. */
export type CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DsEdge = {
  __typename?: 'CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `CreatorStatsTotalPaidMessageEarningsUsdCentsPast30D` at the end of the edge. */
  node?: Maybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast30D>;
};

/** Methods to use when ordering `CreatorStatsTotalPaidMessageEarningsUsdCentsPast30D`. */
export enum CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DsOrderBy {
  Natural = 'NATURAL',
  TotalEarningsUsdCentsAsc = 'TOTAL_EARNINGS_USD_CENTS_ASC',
  TotalEarningsUsdCentsDesc = 'TOTAL_EARNINGS_USD_CENTS_DESC',
  UserPublicKeyBase58CheckAsc = 'USER_PUBLIC_KEY_BASE58_CHECK_ASC',
  UserPublicKeyBase58CheckDesc = 'USER_PUBLIC_KEY_BASE58_CHECK_DESC',
}

export type DaoCoinCandlestick = {
  __typename?: 'DaoCoinCandlestick';
  close?: Maybe<Scalars['Float']['output']>;
  coinPublicKey?: Maybe<Scalars['String']['output']>;
  high?: Maybe<Scalars['Float']['output']>;
  low?: Maybe<Scalars['Float']['output']>;
  open?: Maybe<Scalars['Float']['output']>;
  quoteCoinPublicKey?: Maybe<Scalars['String']['output']>;
  resolution?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['BigFloat']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `DaoCoinCandlestick` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DaoCoinCandlestickCondition = {
  /** Checks for equality with the object’s `close` field. */
  close?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `coinPublicKey` field. */
  coinPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `high` field. */
  high?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `low` field. */
  low?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `open` field. */
  open?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `quoteCoinPublicKey` field. */
  quoteCoinPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `resolution` field. */
  resolution?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `time` field. */
  time?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `volume` field. */
  volume?: InputMaybe<Scalars['Float']['input']>;
};

/** A filter to be used against `DaoCoinCandlestick` object types. All fields are combined with a logical ‘and.’ */
export type DaoCoinCandlestickFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DaoCoinCandlestickFilter>>;
  /** Filter by the object’s `close` field. */
  close?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `coinPublicKey` field. */
  coinPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `high` field. */
  high?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `low` field. */
  low?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DaoCoinCandlestickFilter>;
  /** Filter by the object’s `open` field. */
  open?: InputMaybe<FloatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DaoCoinCandlestickFilter>>;
  /** Filter by the object’s `quoteCoinPublicKey` field. */
  quoteCoinPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `resolution` field. */
  resolution?: InputMaybe<StringFilter>;
  /** Filter by the object’s `time` field. */
  time?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `volume` field. */
  volume?: InputMaybe<FloatFilter>;
};

/** A connection to a list of `DaoCoinCandlestick` values. */
export type DaoCoinCandlesticksConnection = {
  __typename?: 'DaoCoinCandlesticksConnection';
  /** A list of edges which contains the `DaoCoinCandlestick` and cursor to aid in pagination. */
  edges: Array<DaoCoinCandlesticksEdge>;
  /** A list of `DaoCoinCandlestick` objects. */
  nodes: Array<Maybe<DaoCoinCandlestick>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DaoCoinCandlestick` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `DaoCoinCandlestick` edge in the connection. */
export type DaoCoinCandlesticksEdge = {
  __typename?: 'DaoCoinCandlesticksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `DaoCoinCandlestick` at the end of the edge. */
  node?: Maybe<DaoCoinCandlestick>;
};

/** Methods to use when ordering `DaoCoinCandlestick`. */
export enum DaoCoinCandlesticksOrderBy {
  CloseAsc = 'CLOSE_ASC',
  CloseDesc = 'CLOSE_DESC',
  CoinPublicKeyAsc = 'COIN_PUBLIC_KEY_ASC',
  CoinPublicKeyDesc = 'COIN_PUBLIC_KEY_DESC',
  HighAsc = 'HIGH_ASC',
  HighDesc = 'HIGH_DESC',
  LowAsc = 'LOW_ASC',
  LowDesc = 'LOW_DESC',
  Natural = 'NATURAL',
  OpenAsc = 'OPEN_ASC',
  OpenDesc = 'OPEN_DESC',
  QuoteCoinPublicKeyAsc = 'QUOTE_COIN_PUBLIC_KEY_ASC',
  QuoteCoinPublicKeyDesc = 'QUOTE_COIN_PUBLIC_KEY_DESC',
  ResolutionAsc = 'RESOLUTION_ASC',
  ResolutionDesc = 'RESOLUTION_DESC',
  TimeAsc = 'TIME_ASC',
  TimeDesc = 'TIME_DESC',
  VolumeAsc = 'VOLUME_ASC',
  VolumeDesc = 'VOLUME_DESC',
}

export type DaoCoinStat = Node & {
  __typename?: 'DaoCoinStat';
  apyBasisPoints?: Maybe<Scalars['BigInt']['output']>;
  circulatingSupply?: Maybe<Scalars['BigFloat']['output']>;
  coinCategory?: Maybe<Scalars['String']['output']>;
  coinPkid?: Maybe<Scalars['String']['output']>;
  coinProfilePicUrl?: Maybe<Scalars['String']['output']>;
  coinPublicKey: Scalars['String']['output'];
  coinUsername?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  isBlacklisted?: Maybe<Scalars['Boolean']['output']>;
  isGreylisted?: Maybe<Scalars['Boolean']['output']>;
  marketCapUsd?: Maybe<Scalars['Float']['output']>;
  numHolders?: Maybe<Scalars['Int']['output']>;
  priceUsd?: Maybe<Scalars['Float']['output']>;
  priceUsdPctChange1H?: Maybe<Scalars['Float']['output']>;
  priceUsdPctChange7D?: Maybe<Scalars['Float']['output']>;
  priceUsdPctChange24H?: Maybe<Scalars['Float']['output']>;
  priceUsdPctChange30D?: Maybe<Scalars['Float']['output']>;
  tokenAmmCreatedAt?: Maybe<Scalars['Datetime']['output']>;
  tokenAmmLaunchAt?: Maybe<Scalars['Datetime']['output']>;
  totalSupply?: Maybe<Scalars['BigFloat']['output']>;
  volumeUsdPast1H?: Maybe<Scalars['Float']['output']>;
  volumeUsdPast7D?: Maybe<Scalars['Float']['output']>;
  volumeUsdPast24H?: Maybe<Scalars['Float']['output']>;
  volumeUsdPast30D?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `DaoCoinStat` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DaoCoinStatCondition = {
  /** Checks for equality with the object’s `apyBasisPoints` field. */
  apyBasisPoints?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `circulatingSupply` field. */
  circulatingSupply?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `coinCategory` field. */
  coinCategory?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `coinPkid` field. */
  coinPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `coinProfilePicUrl` field. */
  coinProfilePicUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `coinPublicKey` field. */
  coinPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `coinUsername` field. */
  coinUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isBlacklisted` field. */
  isBlacklisted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isGreylisted` field. */
  isGreylisted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `marketCapUsd` field. */
  marketCapUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `numHolders` field. */
  numHolders?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `priceUsd` field. */
  priceUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `priceUsdPctChange1H` field. */
  priceUsdPctChange1H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `priceUsdPctChange7D` field. */
  priceUsdPctChange7D?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `priceUsdPctChange24H` field. */
  priceUsdPctChange24H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `priceUsdPctChange30D` field. */
  priceUsdPctChange30D?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tokenAmmCreatedAt` field. */
  tokenAmmCreatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `tokenAmmLaunchAt` field. */
  tokenAmmLaunchAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `totalSupply` field. */
  totalSupply?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `volumeUsdPast1H` field. */
  volumeUsdPast1H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `volumeUsdPast7D` field. */
  volumeUsdPast7D?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `volumeUsdPast24H` field. */
  volumeUsdPast24H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `volumeUsdPast30D` field. */
  volumeUsdPast30D?: InputMaybe<Scalars['Float']['input']>;
};

/** A filter to be used against `DaoCoinStat` object types. All fields are combined with a logical ‘and.’ */
export type DaoCoinStatFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DaoCoinStatFilter>>;
  /** Filter by the object’s `apyBasisPoints` field. */
  apyBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `circulatingSupply` field. */
  circulatingSupply?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coinCategory` field. */
  coinCategory?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coinPkid` field. */
  coinPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coinProfilePicUrl` field. */
  coinProfilePicUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coinPublicKey` field. */
  coinPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coinUsername` field. */
  coinUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isBlacklisted` field. */
  isBlacklisted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isGreylisted` field. */
  isGreylisted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `marketCapUsd` field. */
  marketCapUsd?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DaoCoinStatFilter>;
  /** Filter by the object’s `numHolders` field. */
  numHolders?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DaoCoinStatFilter>>;
  /** Filter by the object’s `priceUsd` field. */
  priceUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `priceUsdPctChange1H` field. */
  priceUsdPctChange1H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `priceUsdPctChange7D` field. */
  priceUsdPctChange7D?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `priceUsdPctChange24H` field. */
  priceUsdPctChange24H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `priceUsdPctChange30D` field. */
  priceUsdPctChange30D?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tokenAmmCreatedAt` field. */
  tokenAmmCreatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `tokenAmmLaunchAt` field. */
  tokenAmmLaunchAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `totalSupply` field. */
  totalSupply?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `volumeUsdPast1H` field. */
  volumeUsdPast1H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `volumeUsdPast7D` field. */
  volumeUsdPast7D?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `volumeUsdPast24H` field. */
  volumeUsdPast24H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `volumeUsdPast30D` field. */
  volumeUsdPast30D?: InputMaybe<FloatFilter>;
};

/** A connection to a list of `DaoCoinStat` values. */
export type DaoCoinStatsConnection = {
  __typename?: 'DaoCoinStatsConnection';
  /** A list of edges which contains the `DaoCoinStat` and cursor to aid in pagination. */
  edges: Array<DaoCoinStatsEdge>;
  /** A list of `DaoCoinStat` objects. */
  nodes: Array<Maybe<DaoCoinStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DaoCoinStat` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `DaoCoinStat` edge in the connection. */
export type DaoCoinStatsEdge = {
  __typename?: 'DaoCoinStatsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `DaoCoinStat` at the end of the edge. */
  node?: Maybe<DaoCoinStat>;
};

/** Methods to use when ordering `DaoCoinStat`. */
export enum DaoCoinStatsOrderBy {
  ApyBasisPointsAsc = 'APY_BASIS_POINTS_ASC',
  ApyBasisPointsDesc = 'APY_BASIS_POINTS_DESC',
  CirculatingSupplyAsc = 'CIRCULATING_SUPPLY_ASC',
  CirculatingSupplyDesc = 'CIRCULATING_SUPPLY_DESC',
  CoinCategoryAsc = 'COIN_CATEGORY_ASC',
  CoinCategoryDesc = 'COIN_CATEGORY_DESC',
  CoinPkidAsc = 'COIN_PKID_ASC',
  CoinPkidDesc = 'COIN_PKID_DESC',
  CoinProfilePicUrlAsc = 'COIN_PROFILE_PIC_URL_ASC',
  CoinProfilePicUrlDesc = 'COIN_PROFILE_PIC_URL_DESC',
  CoinPublicKeyAsc = 'COIN_PUBLIC_KEY_ASC',
  CoinPublicKeyDesc = 'COIN_PUBLIC_KEY_DESC',
  CoinUsernameAsc = 'COIN_USERNAME_ASC',
  CoinUsernameDesc = 'COIN_USERNAME_DESC',
  IsBlacklistedAsc = 'IS_BLACKLISTED_ASC',
  IsBlacklistedDesc = 'IS_BLACKLISTED_DESC',
  IsGreylistedAsc = 'IS_GREYLISTED_ASC',
  IsGreylistedDesc = 'IS_GREYLISTED_DESC',
  MarketCapUsdAsc = 'MARKET_CAP_USD_ASC',
  MarketCapUsdDesc = 'MARKET_CAP_USD_DESC',
  Natural = 'NATURAL',
  NumHoldersAsc = 'NUM_HOLDERS_ASC',
  NumHoldersDesc = 'NUM_HOLDERS_DESC',
  PriceUsdAsc = 'PRICE_USD_ASC',
  PriceUsdDesc = 'PRICE_USD_DESC',
  PriceUsdPctChange_1HAsc = 'PRICE_USD_PCT_CHANGE_1H_ASC',
  PriceUsdPctChange_1HDesc = 'PRICE_USD_PCT_CHANGE_1H_DESC',
  PriceUsdPctChange_7DAsc = 'PRICE_USD_PCT_CHANGE_7D_ASC',
  PriceUsdPctChange_7DDesc = 'PRICE_USD_PCT_CHANGE_7D_DESC',
  PriceUsdPctChange_24HAsc = 'PRICE_USD_PCT_CHANGE_24H_ASC',
  PriceUsdPctChange_24HDesc = 'PRICE_USD_PCT_CHANGE_24H_DESC',
  PriceUsdPctChange_30DAsc = 'PRICE_USD_PCT_CHANGE_30D_ASC',
  PriceUsdPctChange_30DDesc = 'PRICE_USD_PCT_CHANGE_30D_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TokenAmmCreatedAtAsc = 'TOKEN_AMM_CREATED_AT_ASC',
  TokenAmmCreatedAtDesc = 'TOKEN_AMM_CREATED_AT_DESC',
  TokenAmmLaunchAtAsc = 'TOKEN_AMM_LAUNCH_AT_ASC',
  TokenAmmLaunchAtDesc = 'TOKEN_AMM_LAUNCH_AT_DESC',
  TotalSupplyAsc = 'TOTAL_SUPPLY_ASC',
  TotalSupplyDesc = 'TOTAL_SUPPLY_DESC',
  VolumeUsdPast_1HAsc = 'VOLUME_USD_PAST_1H_ASC',
  VolumeUsdPast_1HDesc = 'VOLUME_USD_PAST_1H_DESC',
  VolumeUsdPast_7DAsc = 'VOLUME_USD_PAST_7D_ASC',
  VolumeUsdPast_7DDesc = 'VOLUME_USD_PAST_7D_DESC',
  VolumeUsdPast_24HAsc = 'VOLUME_USD_PAST_24H_ASC',
  VolumeUsdPast_24HDesc = 'VOLUME_USD_PAST_24H_DESC',
  VolumeUsdPast_30DAsc = 'VOLUME_USD_PAST_30D_ASC',
  VolumeUsdPast_30DDesc = 'VOLUME_USD_PAST_30D_DESC',
}

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Date']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Date']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Date']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Date']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Date']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Date']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Date']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Date']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Date']['input']>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']['input']>>;
};

/** All input for the `decayAllPostScores` mutation. */
export type DecayAllPostScoresInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `decayAllPostScores` mutation. */
export type DecayAllPostScoresPayload = {
  __typename?: 'DecayAllPostScoresPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type DerivedKey = Node & {
  __typename?: 'DerivedKey';
  derivedPublicKey: Scalars['String']['output'];
  expirationBlock: Scalars['BigInt']['output'];
  extraData?: Maybe<Scalars['JSON']['output']>;
  globalDesoLimit?: Maybe<Scalars['BigInt']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  isUnlimited?: Maybe<Scalars['Boolean']['output']>;
  isValid?: Maybe<Scalars['Boolean']['output']>;
  operationType: Scalars['Int']['output'];
  /** Reads a single `Account` that is related to this `DerivedKey`. */
  owner?: Maybe<Account>;
  ownerPublicKey: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `Message`. */
  paidMessages: MessagesConnection;
  transactionSpendingLimits?: Maybe<Scalars['JSON']['output']>;
};

export type DerivedKeyPaidMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/**
 * A condition to be used against `DerivedKey` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DerivedKeyCondition = {
  /** Checks for equality with the object’s `derivedPublicKey` field. */
  derivedPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `expirationBlock` field. */
  expirationBlock?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `globalDesoLimit` field. */
  globalDesoLimit?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `isUnlimited` field. */
  isUnlimited?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `operationType` field. */
  operationType?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `ownerPublicKey` field. */
  ownerPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transactionSpendingLimits` field. */
  transactionSpendingLimits?: InputMaybe<Scalars['JSON']['input']>;
};

/** A filter to be used against `DerivedKey` object types. All fields are combined with a logical ‘and.’ */
export type DerivedKeyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DerivedKeyFilter>>;
  /** Filter by the object’s `derivedPublicKey` field. */
  derivedPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `expirationBlock` field. */
  expirationBlock?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `globalDesoLimit` field. */
  globalDesoLimit?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `isUnlimited` field. */
  isUnlimited?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DerivedKeyFilter>;
  /** Filter by the object’s `operationType` field. */
  operationType?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DerivedKeyFilter>>;
  /** Filter by the object’s `owner` relation. */
  owner?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `ownerPublicKey` field. */
  ownerPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `paidMessages` relation. */
  paidMessages?: InputMaybe<DerivedKeyToManyMessageFilter>;
  /** Some related `paidMessages` exist. */
  paidMessagesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `transactionSpendingLimits` field. */
  transactionSpendingLimits?: InputMaybe<JsonFilter>;
};

/** A filter to be used against many `Message` object types. All fields are combined with a logical ‘and.’ */
export type DerivedKeyToManyMessageFilter = {
  /** Every related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MessageFilter>;
  /** No related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MessageFilter>;
  /** Some related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MessageFilter>;
};

/** A connection to a list of `DerivedKey` values. */
export type DerivedKeysConnection = {
  __typename?: 'DerivedKeysConnection';
  /** A list of edges which contains the `DerivedKey` and cursor to aid in pagination. */
  edges: Array<DerivedKeysEdge>;
  /** A list of `DerivedKey` objects. */
  nodes: Array<Maybe<DerivedKey>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DerivedKey` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `DerivedKey` edge in the connection. */
export type DerivedKeysEdge = {
  __typename?: 'DerivedKeysEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `DerivedKey` at the end of the edge. */
  node?: Maybe<DerivedKey>;
};

/** Methods to use when ordering `DerivedKey`. */
export enum DerivedKeysOrderBy {
  DerivedPublicKeyAsc = 'DERIVED_PUBLIC_KEY_ASC',
  DerivedPublicKeyDesc = 'DERIVED_PUBLIC_KEY_DESC',
  ExpirationBlockAsc = 'EXPIRATION_BLOCK_ASC',
  ExpirationBlockDesc = 'EXPIRATION_BLOCK_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  GlobalDesoLimitAsc = 'GLOBAL_DESO_LIMIT_ASC',
  GlobalDesoLimitDesc = 'GLOBAL_DESO_LIMIT_DESC',
  IsUnlimitedAsc = 'IS_UNLIMITED_ASC',
  IsUnlimitedDesc = 'IS_UNLIMITED_DESC',
  Natural = 'NATURAL',
  OperationTypeAsc = 'OPERATION_TYPE_ASC',
  OperationTypeDesc = 'OPERATION_TYPE_DESC',
  OwnerPublicKeyAsc = 'OWNER_PUBLIC_KEY_ASC',
  OwnerPublicKeyDesc = 'OWNER_PUBLIC_KEY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TransactionSpendingLimitsAsc = 'TRANSACTION_SPENDING_LIMITS_ASC',
  TransactionSpendingLimitsDesc = 'TRANSACTION_SPENDING_LIMITS_DESC',
}

export type DesoBalance = Node & {
  __typename?: 'DesoBalance';
  /** Reads a single `Account` that is related to this `DesoBalance`. */
  account?: Maybe<Account>;
  balanceNanos: Scalars['BigInt']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  publicKey: Scalars['String']['output'];
};

/**
 * A condition to be used against `DesoBalance` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DesoBalanceCondition = {
  /** Checks for equality with the object’s `balanceNanos` field. */
  balanceNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `DesoBalance` object types. All fields are combined with a logical ‘and.’ */
export type DesoBalanceFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DesoBalanceFilter>>;
  /** Filter by the object’s `balanceNanos` field. */
  balanceNanos?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DesoBalanceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DesoBalanceFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `DesoBalance` values. */
export type DesoBalancesConnection = {
  __typename?: 'DesoBalancesConnection';
  /** A list of edges which contains the `DesoBalance` and cursor to aid in pagination. */
  edges: Array<DesoBalancesEdge>;
  /** A list of `DesoBalance` objects. */
  nodes: Array<Maybe<DesoBalance>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DesoBalance` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `DesoBalance` edge in the connection. */
export type DesoBalancesEdge = {
  __typename?: 'DesoBalancesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `DesoBalance` at the end of the edge. */
  node?: Maybe<DesoBalance>;
};

/** Methods to use when ordering `DesoBalance`. */
export enum DesoBalancesOrderBy {
  BalanceNanosAsc = 'BALANCE_NANOS_ASC',
  BalanceNanosDesc = 'BALANCE_NANOS_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
}

export type DesoTokenLimitOrder = Node & {
  __typename?: 'DesoTokenLimitOrder';
  badgerKey: Scalars['String']['output'];
  blockHeight?: Maybe<Scalars['BigInt']['output']>;
  buyingDaoCoinCreatorPkid?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Account` that is related to this `DesoTokenLimitOrder`. */
  creatorBoughtAccount?: Maybe<Account>;
  /** Reads a single `Account` that is related to this `DesoTokenLimitOrder`. */
  creatorSoldAccount?: Maybe<Account>;
  fillType?: Maybe<Scalars['Int']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  isDaoCoinConst: Scalars['Boolean']['output'];
  operationType?: Maybe<Scalars['Int']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  quantityToFillInBaseUnitsHex?: Maybe<Scalars['String']['output']>;
  quantityToFillInBaseUnitsNumeric?: Maybe<Scalars['BigFloat']['output']>;
  scaledExchangeRateCoinsToSellPerCoinToBuyHex?: Maybe<Scalars['String']['output']>;
  scaledExchangeRateCoinsToSellPerCoinToBuyNumeric?: Maybe<Scalars['BigFloat']['output']>;
  sellingDaoCoinCreatorPkid?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Account` that is related to this `DesoTokenLimitOrder`. */
  transactorAccount?: Maybe<Account>;
  /** Reads a single `TokenBalance` that is related to this `DesoTokenLimitOrder`. */
  transactorBuyingTokenBalance?: Maybe<TokenBalance>;
  transactorPkid?: Maybe<Scalars['String']['output']>;
  /** Reads a single `TokenBalance` that is related to this `DesoTokenLimitOrder`. */
  transactorSellingTokenBalance?: Maybe<TokenBalance>;
};

export type DesoTokenLimitOrderBidAsk = Node & {
  __typename?: 'DesoTokenLimitOrderBidAsk';
  ask?: Maybe<Scalars['BigFloat']['output']>;
  askUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  bid?: Maybe<Scalars['BigFloat']['output']>;
  bidUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  /** Reads a single `Account` that is related to this `DesoTokenLimitOrderBidAsk`. */
  coin1Account?: Maybe<Account>;
  coin1CreatorPkid: Scalars['String']['output'];
  coin1OrderCount?: Maybe<Scalars['BigInt']['output']>;
  coin1SumQuantityToFillInBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
  coin1SumScaledExchangeRateCoinsToSellPerCoinToBuy?: Maybe<Scalars['BigFloat']['output']>;
  /** Reads a single `Account` that is related to this `DesoTokenLimitOrderBidAsk`. */
  coin2Account?: Maybe<Account>;
  coin2CreatorPkid: Scalars['String']['output'];
  coin2OrderCount?: Maybe<Scalars['BigInt']['output']>;
  coin2SumQuantityToFillInBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
  coin2SumScaledExchangeRateCoinsToSellPerCoinToBuy?: Maybe<Scalars['BigFloat']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  midOrFloorPrice?: Maybe<Scalars['BigFloat']['output']>;
  midOrFloorPriceUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  midPrice?: Maybe<Scalars['BigFloat']['output']>;
  midPriceUsdCents?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `DesoTokenLimitOrderBidAsk` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DesoTokenLimitOrderBidAskCondition = {
  /** Checks for equality with the object’s `ask` field. */
  ask?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `askUsdCents` field. */
  askUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `bid` field. */
  bid?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `bidUsdCents` field. */
  bidUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `coin1CreatorPkid` field. */
  coin1CreatorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `coin1OrderCount` field. */
  coin1OrderCount?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `coin1SumQuantityToFillInBaseUnits` field. */
  coin1SumQuantityToFillInBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `coin1SumScaledExchangeRateCoinsToSellPerCoinToBuy` field. */
  coin1SumScaledExchangeRateCoinsToSellPerCoinToBuy?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `coin2CreatorPkid` field. */
  coin2CreatorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `coin2OrderCount` field. */
  coin2OrderCount?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `coin2SumQuantityToFillInBaseUnits` field. */
  coin2SumQuantityToFillInBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `coin2SumScaledExchangeRateCoinsToSellPerCoinToBuy` field. */
  coin2SumScaledExchangeRateCoinsToSellPerCoinToBuy?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `midOrFloorPrice` field. */
  midOrFloorPrice?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `midOrFloorPriceUsdCents` field. */
  midOrFloorPriceUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `midPrice` field. */
  midPrice?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `midPriceUsdCents` field. */
  midPriceUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `DesoTokenLimitOrderBidAsk` object types. All fields are combined with a logical ‘and.’ */
export type DesoTokenLimitOrderBidAskFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DesoTokenLimitOrderBidAskFilter>>;
  /** Filter by the object’s `ask` field. */
  ask?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `askUsdCents` field. */
  askUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `bid` field. */
  bid?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `bidUsdCents` field. */
  bidUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coin1Account` relation. */
  coin1Account?: InputMaybe<AccountFilter>;
  /** A related `coin1Account` exists. */
  coin1AccountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `coin1CreatorPkid` field. */
  coin1CreatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coin1OrderCount` field. */
  coin1OrderCount?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `coin1SumQuantityToFillInBaseUnits` field. */
  coin1SumQuantityToFillInBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coin1SumScaledExchangeRateCoinsToSellPerCoinToBuy` field. */
  coin1SumScaledExchangeRateCoinsToSellPerCoinToBuy?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coin2Account` relation. */
  coin2Account?: InputMaybe<AccountFilter>;
  /** A related `coin2Account` exists. */
  coin2AccountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `coin2CreatorPkid` field. */
  coin2CreatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coin2OrderCount` field. */
  coin2OrderCount?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `coin2SumQuantityToFillInBaseUnits` field. */
  coin2SumQuantityToFillInBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coin2SumScaledExchangeRateCoinsToSellPerCoinToBuy` field. */
  coin2SumScaledExchangeRateCoinsToSellPerCoinToBuy?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `midOrFloorPrice` field. */
  midOrFloorPrice?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `midOrFloorPriceUsdCents` field. */
  midOrFloorPriceUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `midPrice` field. */
  midPrice?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `midPriceUsdCents` field. */
  midPriceUsdCents?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DesoTokenLimitOrderBidAskFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DesoTokenLimitOrderBidAskFilter>>;
};

/** A connection to a list of `DesoTokenLimitOrderBidAsk` values. */
export type DesoTokenLimitOrderBidAsksConnection = {
  __typename?: 'DesoTokenLimitOrderBidAsksConnection';
  /** A list of edges which contains the `DesoTokenLimitOrderBidAsk` and cursor to aid in pagination. */
  edges: Array<DesoTokenLimitOrderBidAsksEdge>;
  /** A list of `DesoTokenLimitOrderBidAsk` objects. */
  nodes: Array<Maybe<DesoTokenLimitOrderBidAsk>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DesoTokenLimitOrderBidAsk` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `DesoTokenLimitOrderBidAsk` edge in the connection. */
export type DesoTokenLimitOrderBidAsksEdge = {
  __typename?: 'DesoTokenLimitOrderBidAsksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `DesoTokenLimitOrderBidAsk` at the end of the edge. */
  node?: Maybe<DesoTokenLimitOrderBidAsk>;
};

/** Methods to use when ordering `DesoTokenLimitOrderBidAsk`. */
export enum DesoTokenLimitOrderBidAsksOrderBy {
  AskAsc = 'ASK_ASC',
  AskDesc = 'ASK_DESC',
  AskUsdCentsAsc = 'ASK_USD_CENTS_ASC',
  AskUsdCentsDesc = 'ASK_USD_CENTS_DESC',
  BidAsc = 'BID_ASC',
  BidDesc = 'BID_DESC',
  BidUsdCentsAsc = 'BID_USD_CENTS_ASC',
  BidUsdCentsDesc = 'BID_USD_CENTS_DESC',
  Coin_1CreatorPkidAsc = 'COIN_1_CREATOR_PKID_ASC',
  Coin_1CreatorPkidDesc = 'COIN_1_CREATOR_PKID_DESC',
  Coin_1OrderCountAsc = 'COIN_1_ORDER_COUNT_ASC',
  Coin_1OrderCountDesc = 'COIN_1_ORDER_COUNT_DESC',
  Coin_1SumQuantityToFillInBaseUnitsAsc = 'COIN_1_SUM_QUANTITY_TO_FILL_IN_BASE_UNITS_ASC',
  Coin_1SumQuantityToFillInBaseUnitsDesc = 'COIN_1_SUM_QUANTITY_TO_FILL_IN_BASE_UNITS_DESC',
  Coin_1SumScaledExchangeRateCoinsToSellPerCoinToBuyAsc = 'COIN_1_SUM_SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_ASC',
  Coin_1SumScaledExchangeRateCoinsToSellPerCoinToBuyDesc = 'COIN_1_SUM_SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_DESC',
  Coin_2CreatorPkidAsc = 'COIN_2_CREATOR_PKID_ASC',
  Coin_2CreatorPkidDesc = 'COIN_2_CREATOR_PKID_DESC',
  Coin_2OrderCountAsc = 'COIN_2_ORDER_COUNT_ASC',
  Coin_2OrderCountDesc = 'COIN_2_ORDER_COUNT_DESC',
  Coin_2SumQuantityToFillInBaseUnitsAsc = 'COIN_2_SUM_QUANTITY_TO_FILL_IN_BASE_UNITS_ASC',
  Coin_2SumQuantityToFillInBaseUnitsDesc = 'COIN_2_SUM_QUANTITY_TO_FILL_IN_BASE_UNITS_DESC',
  Coin_2SumScaledExchangeRateCoinsToSellPerCoinToBuyAsc = 'COIN_2_SUM_SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_ASC',
  Coin_2SumScaledExchangeRateCoinsToSellPerCoinToBuyDesc = 'COIN_2_SUM_SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_DESC',
  MidOrFloorPriceAsc = 'MID_OR_FLOOR_PRICE_ASC',
  MidOrFloorPriceDesc = 'MID_OR_FLOOR_PRICE_DESC',
  MidOrFloorPriceUsdCentsAsc = 'MID_OR_FLOOR_PRICE_USD_CENTS_ASC',
  MidOrFloorPriceUsdCentsDesc = 'MID_OR_FLOOR_PRICE_USD_CENTS_DESC',
  MidPriceAsc = 'MID_PRICE_ASC',
  MidPriceDesc = 'MID_PRICE_DESC',
  MidPriceUsdCentsAsc = 'MID_PRICE_USD_CENTS_ASC',
  MidPriceUsdCentsDesc = 'MID_PRICE_USD_CENTS_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/**
 * A condition to be used against `DesoTokenLimitOrder` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type DesoTokenLimitOrderCondition = {
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `buyingDaoCoinCreatorPkid` field. */
  buyingDaoCoinCreatorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `fillType` field. */
  fillType?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isDaoCoinConst` field. */
  isDaoCoinConst?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `operationType` field. */
  operationType?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `orderId` field. */
  orderId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `quantityToFillInBaseUnitsHex` field. */
  quantityToFillInBaseUnitsHex?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `quantityToFillInBaseUnitsNumeric` field. */
  quantityToFillInBaseUnitsNumeric?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `scaledExchangeRateCoinsToSellPerCoinToBuyHex` field. */
  scaledExchangeRateCoinsToSellPerCoinToBuyHex?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `scaledExchangeRateCoinsToSellPerCoinToBuyNumeric` field. */
  scaledExchangeRateCoinsToSellPerCoinToBuyNumeric?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `sellingDaoCoinCreatorPkid` field. */
  sellingDaoCoinCreatorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `DesoTokenLimitOrder` object types. All fields are combined with a logical ‘and.’ */
export type DesoTokenLimitOrderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DesoTokenLimitOrderFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `buyingDaoCoinCreatorPkid` field. */
  buyingDaoCoinCreatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `creatorBoughtAccount` relation. */
  creatorBoughtAccount?: InputMaybe<AccountFilter>;
  /** A related `creatorBoughtAccount` exists. */
  creatorBoughtAccountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `creatorSoldAccount` relation. */
  creatorSoldAccount?: InputMaybe<AccountFilter>;
  /** A related `creatorSoldAccount` exists. */
  creatorSoldAccountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `fillType` field. */
  fillType?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isDaoCoinConst` field. */
  isDaoCoinConst?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DesoTokenLimitOrderFilter>;
  /** Filter by the object’s `operationType` field. */
  operationType?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DesoTokenLimitOrderFilter>>;
  /** Filter by the object’s `orderId` field. */
  orderId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `quantityToFillInBaseUnitsHex` field. */
  quantityToFillInBaseUnitsHex?: InputMaybe<StringFilter>;
  /** Filter by the object’s `quantityToFillInBaseUnitsNumeric` field. */
  quantityToFillInBaseUnitsNumeric?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `scaledExchangeRateCoinsToSellPerCoinToBuyHex` field. */
  scaledExchangeRateCoinsToSellPerCoinToBuyHex?: InputMaybe<StringFilter>;
  /** Filter by the object’s `scaledExchangeRateCoinsToSellPerCoinToBuyNumeric` field. */
  scaledExchangeRateCoinsToSellPerCoinToBuyNumeric?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `sellingDaoCoinCreatorPkid` field. */
  sellingDaoCoinCreatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactorAccount` relation. */
  transactorAccount?: InputMaybe<AccountFilter>;
  /** A related `transactorAccount` exists. */
  transactorAccountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `transactorBuyingTokenBalance` relation. */
  transactorBuyingTokenBalance?: InputMaybe<TokenBalanceFilter>;
  /** A related `transactorBuyingTokenBalance` exists. */
  transactorBuyingTokenBalanceExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactorSellingTokenBalance` relation. */
  transactorSellingTokenBalance?: InputMaybe<TokenBalanceFilter>;
  /** A related `transactorSellingTokenBalance` exists. */
  transactorSellingTokenBalanceExists?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A connection to a list of `DesoTokenLimitOrder` values. */
export type DesoTokenLimitOrdersConnection = {
  __typename?: 'DesoTokenLimitOrdersConnection';
  /** A list of edges which contains the `DesoTokenLimitOrder` and cursor to aid in pagination. */
  edges: Array<DesoTokenLimitOrdersEdge>;
  /** A list of `DesoTokenLimitOrder` objects. */
  nodes: Array<Maybe<DesoTokenLimitOrder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DesoTokenLimitOrder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `DesoTokenLimitOrder` edge in the connection. */
export type DesoTokenLimitOrdersEdge = {
  __typename?: 'DesoTokenLimitOrdersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `DesoTokenLimitOrder` at the end of the edge. */
  node?: Maybe<DesoTokenLimitOrder>;
};

/** Methods to use when ordering `DesoTokenLimitOrder`. */
export enum DesoTokenLimitOrdersOrderBy {
  BadgerKeyAsc = 'BADGER_KEY_ASC',
  BadgerKeyDesc = 'BADGER_KEY_DESC',
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  BuyingDaoCoinCreatorPkidAsc = 'BUYING_DAO_COIN_CREATOR_PKID_ASC',
  BuyingDaoCoinCreatorPkidDesc = 'BUYING_DAO_COIN_CREATOR_PKID_DESC',
  FillTypeAsc = 'FILL_TYPE_ASC',
  FillTypeDesc = 'FILL_TYPE_DESC',
  IsDaoCoinConstAsc = 'IS_DAO_COIN_CONST_ASC',
  IsDaoCoinConstDesc = 'IS_DAO_COIN_CONST_DESC',
  Natural = 'NATURAL',
  OperationTypeAsc = 'OPERATION_TYPE_ASC',
  OperationTypeDesc = 'OPERATION_TYPE_DESC',
  OrderIdAsc = 'ORDER_ID_ASC',
  OrderIdDesc = 'ORDER_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  QuantityToFillInBaseUnitsHexAsc = 'QUANTITY_TO_FILL_IN_BASE_UNITS_HEX_ASC',
  QuantityToFillInBaseUnitsHexDesc = 'QUANTITY_TO_FILL_IN_BASE_UNITS_HEX_DESC',
  QuantityToFillInBaseUnitsNumericAsc = 'QUANTITY_TO_FILL_IN_BASE_UNITS_NUMERIC_ASC',
  QuantityToFillInBaseUnitsNumericDesc = 'QUANTITY_TO_FILL_IN_BASE_UNITS_NUMERIC_DESC',
  ScaledExchangeRateCoinsToSellPerCoinToBuyHexAsc = 'SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_HEX_ASC',
  ScaledExchangeRateCoinsToSellPerCoinToBuyHexDesc = 'SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_HEX_DESC',
  ScaledExchangeRateCoinsToSellPerCoinToBuyNumericAsc = 'SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_NUMERIC_ASC',
  ScaledExchangeRateCoinsToSellPerCoinToBuyNumericDesc = 'SCALED_EXCHANGE_RATE_COINS_TO_SELL_PER_COIN_TO_BUY_NUMERIC_DESC',
  SellingDaoCoinCreatorPkidAsc = 'SELLING_DAO_COIN_CREATOR_PKID_ASC',
  SellingDaoCoinCreatorPkidDesc = 'SELLING_DAO_COIN_CREATOR_PKID_DESC',
  TransactorPkidAsc = 'TRANSACTOR_PKID_ASC',
  TransactorPkidDesc = 'TRANSACTOR_PKID_DESC',
}

export type Diamond = Node & {
  __typename?: 'Diamond';
  diamondLevel: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  /** Reads a single `Post` that is related to this `Diamond`. */
  post?: Maybe<Post>;
  postHash: Scalars['String']['output'];
  receiverPkid: Scalars['String']['output'];
  /** Reads a single `Account` that is related to this `Diamond`. */
  reciever?: Maybe<Account>;
  /** Reads a single `Account` that is related to this `Diamond`. */
  sender?: Maybe<Account>;
  senderPkid: Scalars['String']['output'];
};

/** A condition to be used against `Diamond` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type DiamondCondition = {
  /** Checks for equality with the object’s `diamondLevel` field. */
  diamondLevel?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `receiverPkid` field. */
  receiverPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `senderPkid` field. */
  senderPkid?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Diamond` object types. All fields are combined with a logical ‘and.’ */
export type DiamondFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DiamondFilter>>;
  /** Filter by the object’s `diamondLevel` field. */
  diamondLevel?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DiamondFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DiamondFilter>>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `receiverPkid` field. */
  receiverPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `reciever` relation. */
  reciever?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `sender` relation. */
  sender?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `senderPkid` field. */
  senderPkid?: InputMaybe<StringFilter>;
};

/** All input for the `diamondLevelToDesoNanos` mutation. */
export type DiamondLevelToDesoNanosInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  diamondLevel?: InputMaybe<Scalars['Int']['input']>;
};

/** The output of our `diamondLevelToDesoNanos` mutation. */
export type DiamondLevelToDesoNanosPayload = {
  __typename?: 'DiamondLevelToDesoNanosPayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A connection to a list of `Diamond` values. */
export type DiamondsConnection = {
  __typename?: 'DiamondsConnection';
  /** A list of edges which contains the `Diamond` and cursor to aid in pagination. */
  edges: Array<DiamondsEdge>;
  /** A list of `Diamond` objects. */
  nodes: Array<Maybe<Diamond>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Diamond` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Diamond` edge in the connection. */
export type DiamondsEdge = {
  __typename?: 'DiamondsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Diamond` at the end of the edge. */
  node?: Maybe<Diamond>;
};

/** Methods to use when ordering `Diamond`. */
export enum DiamondsOrderBy {
  DiamondLevelAsc = 'DIAMOND_LEVEL_ASC',
  DiamondLevelDesc = 'DIAMOND_LEVEL_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReceiverPkidAsc = 'RECEIVER_PKID_ASC',
  ReceiverPkidDesc = 'RECEIVER_PKID_DESC',
  SenderPkidAsc = 'SENDER_PKID_ASC',
  SenderPkidDesc = 'SENDER_PKID_DESC',
}

export type EpochDetailsForBlock = {
  __typename?: 'EpochDetailsForBlock';
  /** Reads a single `Block` that is related to this `EpochDetailsForBlock`. */
  block?: Maybe<Block>;
  blockHash?: Maybe<Scalars['String']['output']>;
  /** Reads a single `EpochEntry` that is related to this `EpochDetailsForBlock`. */
  epochEntry?: Maybe<EpochEntry>;
  epochNumber?: Maybe<Scalars['BigInt']['output']>;
  /** Reads a single `Account` that is related to this `EpochDetailsForBlock`. */
  proposer?: Maybe<Account>;
  proposerPkid?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `EpochDetailsForBlock` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type EpochDetailsForBlockCondition = {
  /** Checks for equality with the object’s `blockHash` field. */
  blockHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `epochNumber` field. */
  epochNumber?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `proposerPkid` field. */
  proposerPkid?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `EpochDetailsForBlock` object types. All fields are combined with a logical ‘and.’ */
export type EpochDetailsForBlockFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EpochDetailsForBlockFilter>>;
  /** Filter by the object’s `block` relation. */
  block?: InputMaybe<BlockFilter>;
  /** A related `block` exists. */
  blockExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `blockHash` field. */
  blockHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `epochEntry` relation. */
  epochEntry?: InputMaybe<EpochEntryFilter>;
  /** A related `epochEntry` exists. */
  epochEntryExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `epochNumber` field. */
  epochNumber?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EpochDetailsForBlockFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EpochDetailsForBlockFilter>>;
  /** Filter by the object’s `proposer` relation. */
  proposer?: InputMaybe<AccountFilter>;
  /** A related `proposer` exists. */
  proposerExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `proposerPkid` field. */
  proposerPkid?: InputMaybe<StringFilter>;
};

/** A connection to a list of `EpochDetailsForBlock` values. */
export type EpochDetailsForBlocksConnection = {
  __typename?: 'EpochDetailsForBlocksConnection';
  /** A list of edges which contains the `EpochDetailsForBlock` and cursor to aid in pagination. */
  edges: Array<EpochDetailsForBlocksEdge>;
  /** A list of `EpochDetailsForBlock` objects. */
  nodes: Array<Maybe<EpochDetailsForBlock>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EpochDetailsForBlock` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `EpochDetailsForBlock` edge in the connection. */
export type EpochDetailsForBlocksEdge = {
  __typename?: 'EpochDetailsForBlocksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `EpochDetailsForBlock` at the end of the edge. */
  node?: Maybe<EpochDetailsForBlock>;
};

/** Methods to use when ordering `EpochDetailsForBlock`. */
export enum EpochDetailsForBlocksOrderBy {
  BlockHashAsc = 'BLOCK_HASH_ASC',
  BlockHashDesc = 'BLOCK_HASH_DESC',
  EpochNumberAsc = 'EPOCH_NUMBER_ASC',
  EpochNumberDesc = 'EPOCH_NUMBER_DESC',
  Natural = 'NATURAL',
  ProposerPkidAsc = 'PROPOSER_PKID_ASC',
  ProposerPkidDesc = 'PROPOSER_PKID_DESC',
}

/** A connection to a list of `EpochEntry` values. */
export type EpochEntriesConnection = {
  __typename?: 'EpochEntriesConnection';
  /** A list of edges which contains the `EpochEntry` and cursor to aid in pagination. */
  edges: Array<EpochEntriesEdge>;
  /** A list of `EpochEntry` objects. */
  nodes: Array<Maybe<EpochEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EpochEntry` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `EpochEntry` edge in the connection. */
export type EpochEntriesEdge = {
  __typename?: 'EpochEntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `EpochEntry` at the end of the edge. */
  node?: Maybe<EpochEntry>;
};

/** Methods to use when ordering `EpochEntry`. */
export enum EpochEntriesOrderBy {
  CreatedAtBlockTimestampNanoSecsAsc = 'CREATED_AT_BLOCK_TIMESTAMP_NANO_SECS_ASC',
  CreatedAtBlockTimestampNanoSecsDesc = 'CREATED_AT_BLOCK_TIMESTAMP_NANO_SECS_DESC',
  EpochNumberAsc = 'EPOCH_NUMBER_ASC',
  EpochNumberDesc = 'EPOCH_NUMBER_DESC',
  FinalBlockHeightAsc = 'FINAL_BLOCK_HEIGHT_ASC',
  FinalBlockHeightDesc = 'FINAL_BLOCK_HEIGHT_DESC',
  InitialBlockHeightAsc = 'INITIAL_BLOCK_HEIGHT_ASC',
  InitialBlockHeightDesc = 'INITIAL_BLOCK_HEIGHT_DESC',
  InitialLeaderIndexOffsetAsc = 'INITIAL_LEADER_INDEX_OFFSET_ASC',
  InitialLeaderIndexOffsetDesc = 'INITIAL_LEADER_INDEX_OFFSET_DESC',
  InitialViewAsc = 'INITIAL_VIEW_ASC',
  InitialViewDesc = 'INITIAL_VIEW_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SnapshotAtEpochNumberAsc = 'SNAPSHOT_AT_EPOCH_NUMBER_ASC',
  SnapshotAtEpochNumberDesc = 'SNAPSHOT_AT_EPOCH_NUMBER_DESC',
}

export type EpochEntry = Node & {
  __typename?: 'EpochEntry';
  /** Reads a single `EpochDetailsForBlock` that is related to this `EpochEntry`. */
  blockHashesInEpoch?: Maybe<EpochDetailsForBlock>;
  /** Reads and enables pagination through a set of `BlsPublicKeyPkidPairSnapshotEntry`. */
  blsPublicKeyPkidPairSnapshotEntries: BlsPublicKeyPkidPairSnapshotEntriesConnection;
  createdAtBlockTimestampNanoSecs: Scalars['BigInt']['output'];
  epochNumber: Scalars['BigInt']['output'];
  finalBlockHeight: Scalars['BigInt']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  initialBlockHeight: Scalars['BigInt']['output'];
  initialLeaderIndexOffset: Scalars['BigInt']['output'];
  initialView: Scalars['BigInt']['output'];
  /** Reads and enables pagination through a set of `LeaderScheduleEntry`. */
  leaderScheduleEntries: LeaderScheduleEntriesConnection;
  snapshotAtEpochNumber: Scalars['BigInt']['output'];
};

export type EpochEntryBlsPublicKeyPkidPairSnapshotEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryCondition>;
  filter?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlsPublicKeyPkidPairSnapshotEntriesOrderBy>>;
};

export type EpochEntryLeaderScheduleEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LeaderScheduleEntryCondition>;
  filter?: InputMaybe<LeaderScheduleEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LeaderScheduleEntriesOrderBy>>;
};

/**
 * A condition to be used against `EpochEntry` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EpochEntryCondition = {
  /** Checks for equality with the object’s `createdAtBlockTimestampNanoSecs` field. */
  createdAtBlockTimestampNanoSecs?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `epochNumber` field. */
  epochNumber?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `finalBlockHeight` field. */
  finalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `initialBlockHeight` field. */
  initialBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `initialLeaderIndexOffset` field. */
  initialLeaderIndexOffset?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `initialView` field. */
  initialView?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<Scalars['BigInt']['input']>;
};

/** A filter to be used against `EpochEntry` object types. All fields are combined with a logical ‘and.’ */
export type EpochEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EpochEntryFilter>>;
  /** Filter by the object’s `blockHashesInEpoch` relation. */
  blockHashesInEpoch?: InputMaybe<EpochDetailsForBlockFilter>;
  /** A related `blockHashesInEpoch` exists. */
  blockHashesInEpochExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `blsPublicKeyPkidPairSnapshotEntries` relation. */
  blsPublicKeyPkidPairSnapshotEntries?: InputMaybe<EpochEntryToManyBlsPublicKeyPkidPairSnapshotEntryFilter>;
  /** Some related `blsPublicKeyPkidPairSnapshotEntries` exist. */
  blsPublicKeyPkidPairSnapshotEntriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdAtBlockTimestampNanoSecs` field. */
  createdAtBlockTimestampNanoSecs?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `epochNumber` field. */
  epochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `finalBlockHeight` field. */
  finalBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `initialBlockHeight` field. */
  initialBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `initialLeaderIndexOffset` field. */
  initialLeaderIndexOffset?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `initialView` field. */
  initialView?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `leaderScheduleEntries` relation. */
  leaderScheduleEntries?: InputMaybe<EpochEntryToManyLeaderScheduleEntryFilter>;
  /** Some related `leaderScheduleEntries` exist. */
  leaderScheduleEntriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not?: InputMaybe<EpochEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EpochEntryFilter>>;
  /** Filter by the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<BigIntFilter>;
};

/** A filter to be used against many `BlsPublicKeyPkidPairSnapshotEntry` object types. All fields are combined with a logical ‘and.’ */
export type EpochEntryToManyBlsPublicKeyPkidPairSnapshotEntryFilter = {
  /** Every related `BlsPublicKeyPkidPairSnapshotEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  /** No related `BlsPublicKeyPkidPairSnapshotEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  /** Some related `BlsPublicKeyPkidPairSnapshotEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
};

/** A filter to be used against many `LeaderScheduleEntry` object types. All fields are combined with a logical ‘and.’ */
export type EpochEntryToManyLeaderScheduleEntryFilter = {
  /** Every related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LeaderScheduleEntryFilter>;
  /** No related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LeaderScheduleEntryFilter>;
  /** Some related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LeaderScheduleEntryFilter>;
};

export type Feed = {
  __typename?: 'Feed';
  postHash?: Maybe<Scalars['String']['output']>;
  postScore?: Maybe<Scalars['Float']['output']>;
  postScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
};

/** All input for the `feedCalculateUserRelationshipScore` mutation. */
export type FeedCalculateUserRelationshipScoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userInteractions?: InputMaybe<FeedInteractionsUserAggAllInput>;
  weights?: InputMaybe<FeedUserSettingInput>;
};

/** The output of our `feedCalculateUserRelationshipScore` mutation. */
export type FeedCalculateUserRelationshipScorePayload = {
  __typename?: 'FeedCalculateUserRelationshipScorePayload';
  bigFloat?: Maybe<Scalars['BigFloat']['output']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `feedCombineRelationshipPostScores` mutation. */
export type FeedCombineRelationshipPostScoresInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  postScore?: InputMaybe<Scalars['BigFloat']['input']>;
  relationshipScore?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** The output of our `feedCombineRelationshipPostScores` mutation. */
export type FeedCombineRelationshipPostScoresPayload = {
  __typename?: 'FeedCombineRelationshipPostScoresPayload';
  bigFloat?: Maybe<Scalars['BigFloat']['output']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `feedCombineScoresAndRelationships` mutation. */
export type FeedCombineScoresAndRelationshipsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `feedCombineScoresAndRelationships` mutation. */
export type FeedCombineScoresAndRelationshipsPayload = {
  __typename?: 'FeedCombineScoresAndRelationshipsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A condition to be used against `Feed` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FeedCondition = {
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postScore` field. */
  postScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postScoreWithWealth` field. */
  postScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
};

/** All input for the `feedCreateFinalUserPostScores` mutation. */
export type FeedCreateFinalUserPostScoresInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `feedCreateFinalUserPostScores` mutation. */
export type FeedCreateFinalUserPostScoresPayload = {
  __typename?: 'FeedCreateFinalUserPostScoresPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A filter to be used against `Feed` object types. All fields are combined with a logical ‘and.’ */
export type FeedFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<FeedFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postScore` field. */
  postScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `postScoreWithWealth` field. */
  postScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
};

/** All input for the `feedGetUnifiedRelationshipScore` mutation. */
export type FeedGetUnifiedRelationshipScoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedSettings?: InputMaybe<FeedSettingInput>;
  relationshipScore?: InputMaybe<FeedUserRelationshipScoreInput>;
};

/** The output of our `feedGetUnifiedRelationshipScore` mutation. */
export type FeedGetUnifiedRelationshipScorePayload = {
  __typename?: 'FeedGetUnifiedRelationshipScorePayload';
  bigFloat?: Maybe<Scalars['BigFloat']['output']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type FeedInteractionsBlock = {
  __typename?: 'FeedInteractionsBlock';
  blockHeight: Scalars['BigInt']['output'];
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsBlock` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsBlockCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `FeedInteractionsBlock` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsBlockFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsBlockFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsBlockFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsBlockFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `FeedInteractionsBlock` values. */
export type FeedInteractionsBlocksConnection = {
  __typename?: 'FeedInteractionsBlocksConnection';
  /** A list of edges which contains the `FeedInteractionsBlock` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsBlocksEdge>;
  /** A list of `FeedInteractionsBlock` objects. */
  nodes: Array<Maybe<FeedInteractionsBlock>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsBlock` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsBlock` edge in the connection. */
export type FeedInteractionsBlocksEdge = {
  __typename?: 'FeedInteractionsBlocksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsBlock` at the end of the edge. */
  node?: Maybe<FeedInteractionsBlock>;
};

/** Methods to use when ordering `FeedInteractionsBlock`. */
export enum FeedInteractionsBlocksOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
}

export type FeedInteractionsFocusFollow = {
  __typename?: 'FeedInteractionsFocusFollow';
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsFocusFollow` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsFocusFollowCondition = {
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `FeedInteractionsFocusFollow` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsFocusFollowFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsFocusFollowFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsFocusFollowFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsFocusFollowFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `FeedInteractionsFocusFollow` values. */
export type FeedInteractionsFocusFollowsConnection = {
  __typename?: 'FeedInteractionsFocusFollowsConnection';
  /** A list of edges which contains the `FeedInteractionsFocusFollow` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsFocusFollowsEdge>;
  /** A list of `FeedInteractionsFocusFollow` objects. */
  nodes: Array<Maybe<FeedInteractionsFocusFollow>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsFocusFollow` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsFocusFollow` edge in the connection. */
export type FeedInteractionsFocusFollowsEdge = {
  __typename?: 'FeedInteractionsFocusFollowsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsFocusFollow` at the end of the edge. */
  node?: Maybe<FeedInteractionsFocusFollow>;
};

/** Methods to use when ordering `FeedInteractionsFocusFollow`. */
export enum FeedInteractionsFocusFollowsOrderBy {
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
}

export type FeedInteractionsFollow = {
  __typename?: 'FeedInteractionsFollow';
  blockHeight: Scalars['BigInt']['output'];
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsFollow` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsFollowCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `FeedInteractionsFollow` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsFollowFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsFollowFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsFollowFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsFollowFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `FeedInteractionsFollow` values. */
export type FeedInteractionsFollowsConnection = {
  __typename?: 'FeedInteractionsFollowsConnection';
  /** A list of edges which contains the `FeedInteractionsFollow` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsFollowsEdge>;
  /** A list of `FeedInteractionsFollow` objects. */
  nodes: Array<Maybe<FeedInteractionsFollow>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsFollow` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsFollow` edge in the connection. */
export type FeedInteractionsFollowsEdge = {
  __typename?: 'FeedInteractionsFollowsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsFollow` at the end of the edge. */
  node?: Maybe<FeedInteractionsFollow>;
};

/** Methods to use when ordering `FeedInteractionsFollow`. */
export enum FeedInteractionsFollowsOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
}

export type FeedInteractionsNegativeReaction = {
  __typename?: 'FeedInteractionsNegativeReaction';
  blockHeight: Scalars['BigInt']['output'];
  postHash?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsNegativeReaction` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsNegativeReactionCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `FeedInteractionsNegativeReaction` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsNegativeReactionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsNegativeReactionFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsNegativeReactionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsNegativeReactionFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `FeedInteractionsNegativeReaction` values. */
export type FeedInteractionsNegativeReactionsConnection = {
  __typename?: 'FeedInteractionsNegativeReactionsConnection';
  /** A list of edges which contains the `FeedInteractionsNegativeReaction` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsNegativeReactionsEdge>;
  /** A list of `FeedInteractionsNegativeReaction` objects. */
  nodes: Array<Maybe<FeedInteractionsNegativeReaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsNegativeReaction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsNegativeReaction` edge in the connection. */
export type FeedInteractionsNegativeReactionsEdge = {
  __typename?: 'FeedInteractionsNegativeReactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsNegativeReaction` at the end of the edge. */
  node?: Maybe<FeedInteractionsNegativeReaction>;
};

/** Methods to use when ordering `FeedInteractionsNegativeReaction`. */
export enum FeedInteractionsNegativeReactionsOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
}

export type FeedInteractionsNeutralReaction = {
  __typename?: 'FeedInteractionsNeutralReaction';
  blockHeight: Scalars['BigInt']['output'];
  postHash?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsNeutralReaction` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsNeutralReactionCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `FeedInteractionsNeutralReaction` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsNeutralReactionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsNeutralReactionFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsNeutralReactionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsNeutralReactionFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `FeedInteractionsNeutralReaction` values. */
export type FeedInteractionsNeutralReactionsConnection = {
  __typename?: 'FeedInteractionsNeutralReactionsConnection';
  /** A list of edges which contains the `FeedInteractionsNeutralReaction` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsNeutralReactionsEdge>;
  /** A list of `FeedInteractionsNeutralReaction` objects. */
  nodes: Array<Maybe<FeedInteractionsNeutralReaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsNeutralReaction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsNeutralReaction` edge in the connection. */
export type FeedInteractionsNeutralReactionsEdge = {
  __typename?: 'FeedInteractionsNeutralReactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsNeutralReaction` at the end of the edge. */
  node?: Maybe<FeedInteractionsNeutralReaction>;
};

/** Methods to use when ordering `FeedInteractionsNeutralReaction`. */
export enum FeedInteractionsNeutralReactionsOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
}

export type FeedInteractionsNewPost = {
  __typename?: 'FeedInteractionsNewPost';
  blockHeight: Scalars['BigInt']['output'];
  postHash?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsNewPost` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsNewPostCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsNewPost` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsNewPostFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsNewPostFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsNewPostFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsNewPostFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsNewPost` values. */
export type FeedInteractionsNewPostsConnection = {
  __typename?: 'FeedInteractionsNewPostsConnection';
  /** A list of edges which contains the `FeedInteractionsNewPost` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsNewPostsEdge>;
  /** A list of `FeedInteractionsNewPost` objects. */
  nodes: Array<Maybe<FeedInteractionsNewPost>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsNewPost` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsNewPost` edge in the connection. */
export type FeedInteractionsNewPostsEdge = {
  __typename?: 'FeedInteractionsNewPostsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsNewPost` at the end of the edge. */
  node?: Maybe<FeedInteractionsNewPost>;
};

/** Methods to use when ordering `FeedInteractionsNewPost`. */
export enum FeedInteractionsNewPostsOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
}

export type FeedInteractionsPositiveReaction = {
  __typename?: 'FeedInteractionsPositiveReaction';
  blockHeight: Scalars['BigInt']['output'];
  postHash?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsPositiveReaction` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsPositiveReactionCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `FeedInteractionsPositiveReaction` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsPositiveReactionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsPositiveReactionFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsPositiveReactionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsPositiveReactionFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `FeedInteractionsPositiveReaction` values. */
export type FeedInteractionsPositiveReactionsConnection = {
  __typename?: 'FeedInteractionsPositiveReactionsConnection';
  /** A list of edges which contains the `FeedInteractionsPositiveReaction` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsPositiveReactionsEdge>;
  /** A list of `FeedInteractionsPositiveReaction` objects. */
  nodes: Array<Maybe<FeedInteractionsPositiveReaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsPositiveReaction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsPositiveReaction` edge in the connection. */
export type FeedInteractionsPositiveReactionsEdge = {
  __typename?: 'FeedInteractionsPositiveReactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsPositiveReaction` at the end of the edge. */
  node?: Maybe<FeedInteractionsPositiveReaction>;
};

/** Methods to use when ordering `FeedInteractionsPositiveReaction`. */
export enum FeedInteractionsPositiveReactionsOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
}

export type FeedInteractionsPostAggAll = {
  __typename?: 'FeedInteractionsPostAggAll';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  negativeReactionDecayScore?: Maybe<Scalars['Float']['output']>;
  negativeReactionDecayScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  neutralReactionDecayScore?: Maybe<Scalars['Float']['output']>;
  neutralReactionDecayScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  newPostDecayScore?: Maybe<Scalars['Float']['output']>;
  newPostDecayScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  positiveReactionDecayScore?: Maybe<Scalars['Float']['output']>;
  positiveReactionDecayScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  postHash?: Maybe<Scalars['String']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
  quoteDecayScore?: Maybe<Scalars['Float']['output']>;
  quoteDecayScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  replyDecayScore?: Maybe<Scalars['Float']['output']>;
  replyDecayScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  reportLessDecayScore?: Maybe<Scalars['Float']['output']>;
  reportLessDecayScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  repostDecayScore?: Maybe<Scalars['Float']['output']>;
  repostDecayScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  showLessDecayScore?: Maybe<Scalars['Float']['output']>;
  showLessDecayScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  tipDecayScore?: Maybe<Scalars['Float']['output']>;
  tipDecayScoreWithWealth?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsPostAggAll` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsPostAggAllCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `negativeReactionDecayScore` field. */
  negativeReactionDecayScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `negativeReactionDecayScoreWithWealth` field. */
  negativeReactionDecayScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `neutralReactionDecayScore` field. */
  neutralReactionDecayScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `neutralReactionDecayScoreWithWealth` field. */
  neutralReactionDecayScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `newPostDecayScore` field. */
  newPostDecayScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `newPostDecayScoreWithWealth` field. */
  newPostDecayScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `positiveReactionDecayScore` field. */
  positiveReactionDecayScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `positiveReactionDecayScoreWithWealth` field. */
  positiveReactionDecayScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `quoteDecayScore` field. */
  quoteDecayScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `quoteDecayScoreWithWealth` field. */
  quoteDecayScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `replyDecayScore` field. */
  replyDecayScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `replyDecayScoreWithWealth` field. */
  replyDecayScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `reportLessDecayScore` field. */
  reportLessDecayScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `reportLessDecayScoreWithWealth` field. */
  reportLessDecayScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `repostDecayScore` field. */
  repostDecayScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `repostDecayScoreWithWealth` field. */
  repostDecayScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `showLessDecayScore` field. */
  showLessDecayScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `showLessDecayScoreWithWealth` field. */
  showLessDecayScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tipDecayScore` field. */
  tipDecayScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tipDecayScoreWithWealth` field. */
  tipDecayScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
};

/** A filter to be used against `FeedInteractionsPostAggAll` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsPostAggAllFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsPostAggAllFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `negativeReactionDecayScore` field. */
  negativeReactionDecayScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `negativeReactionDecayScoreWithWealth` field. */
  negativeReactionDecayScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `neutralReactionDecayScore` field. */
  neutralReactionDecayScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `neutralReactionDecayScoreWithWealth` field. */
  neutralReactionDecayScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `newPostDecayScore` field. */
  newPostDecayScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `newPostDecayScoreWithWealth` field. */
  newPostDecayScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsPostAggAllFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsPostAggAllFilter>>;
  /** Filter by the object’s `positiveReactionDecayScore` field. */
  positiveReactionDecayScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `positiveReactionDecayScoreWithWealth` field. */
  positiveReactionDecayScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `quoteDecayScore` field. */
  quoteDecayScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `quoteDecayScoreWithWealth` field. */
  quoteDecayScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `replyDecayScore` field. */
  replyDecayScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `replyDecayScoreWithWealth` field. */
  replyDecayScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `reportLessDecayScore` field. */
  reportLessDecayScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `reportLessDecayScoreWithWealth` field. */
  reportLessDecayScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `repostDecayScore` field. */
  repostDecayScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `repostDecayScoreWithWealth` field. */
  repostDecayScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `showLessDecayScore` field. */
  showLessDecayScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `showLessDecayScoreWithWealth` field. */
  showLessDecayScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tipDecayScore` field. */
  tipDecayScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tipDecayScoreWithWealth` field. */
  tipDecayScoreWithWealth?: InputMaybe<FloatFilter>;
};

/** A connection to a list of `FeedInteractionsPostAggAll` values. */
export type FeedInteractionsPostAggAllsConnection = {
  __typename?: 'FeedInteractionsPostAggAllsConnection';
  /** A list of edges which contains the `FeedInteractionsPostAggAll` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsPostAggAllsEdge>;
  /** A list of `FeedInteractionsPostAggAll` objects. */
  nodes: Array<Maybe<FeedInteractionsPostAggAll>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsPostAggAll` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsPostAggAll` edge in the connection. */
export type FeedInteractionsPostAggAllsEdge = {
  __typename?: 'FeedInteractionsPostAggAllsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsPostAggAll` at the end of the edge. */
  node?: Maybe<FeedInteractionsPostAggAll>;
};

/** Methods to use when ordering `FeedInteractionsPostAggAll`. */
export enum FeedInteractionsPostAggAllsOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  Natural = 'NATURAL',
  NegativeReactionDecayScoreAsc = 'NEGATIVE_REACTION_DECAY_SCORE_ASC',
  NegativeReactionDecayScoreDesc = 'NEGATIVE_REACTION_DECAY_SCORE_DESC',
  NegativeReactionDecayScoreWithWealthAsc = 'NEGATIVE_REACTION_DECAY_SCORE_WITH_WEALTH_ASC',
  NegativeReactionDecayScoreWithWealthDesc = 'NEGATIVE_REACTION_DECAY_SCORE_WITH_WEALTH_DESC',
  NeutralReactionDecayScoreAsc = 'NEUTRAL_REACTION_DECAY_SCORE_ASC',
  NeutralReactionDecayScoreDesc = 'NEUTRAL_REACTION_DECAY_SCORE_DESC',
  NeutralReactionDecayScoreWithWealthAsc = 'NEUTRAL_REACTION_DECAY_SCORE_WITH_WEALTH_ASC',
  NeutralReactionDecayScoreWithWealthDesc = 'NEUTRAL_REACTION_DECAY_SCORE_WITH_WEALTH_DESC',
  NewPostDecayScoreAsc = 'NEW_POST_DECAY_SCORE_ASC',
  NewPostDecayScoreDesc = 'NEW_POST_DECAY_SCORE_DESC',
  NewPostDecayScoreWithWealthAsc = 'NEW_POST_DECAY_SCORE_WITH_WEALTH_ASC',
  NewPostDecayScoreWithWealthDesc = 'NEW_POST_DECAY_SCORE_WITH_WEALTH_DESC',
  PositiveReactionDecayScoreAsc = 'POSITIVE_REACTION_DECAY_SCORE_ASC',
  PositiveReactionDecayScoreDesc = 'POSITIVE_REACTION_DECAY_SCORE_DESC',
  PositiveReactionDecayScoreWithWealthAsc = 'POSITIVE_REACTION_DECAY_SCORE_WITH_WEALTH_ASC',
  PositiveReactionDecayScoreWithWealthDesc = 'POSITIVE_REACTION_DECAY_SCORE_WITH_WEALTH_DESC',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  QuoteDecayScoreAsc = 'QUOTE_DECAY_SCORE_ASC',
  QuoteDecayScoreDesc = 'QUOTE_DECAY_SCORE_DESC',
  QuoteDecayScoreWithWealthAsc = 'QUOTE_DECAY_SCORE_WITH_WEALTH_ASC',
  QuoteDecayScoreWithWealthDesc = 'QUOTE_DECAY_SCORE_WITH_WEALTH_DESC',
  ReplyDecayScoreAsc = 'REPLY_DECAY_SCORE_ASC',
  ReplyDecayScoreDesc = 'REPLY_DECAY_SCORE_DESC',
  ReplyDecayScoreWithWealthAsc = 'REPLY_DECAY_SCORE_WITH_WEALTH_ASC',
  ReplyDecayScoreWithWealthDesc = 'REPLY_DECAY_SCORE_WITH_WEALTH_DESC',
  ReportLessDecayScoreAsc = 'REPORT_LESS_DECAY_SCORE_ASC',
  ReportLessDecayScoreDesc = 'REPORT_LESS_DECAY_SCORE_DESC',
  ReportLessDecayScoreWithWealthAsc = 'REPORT_LESS_DECAY_SCORE_WITH_WEALTH_ASC',
  ReportLessDecayScoreWithWealthDesc = 'REPORT_LESS_DECAY_SCORE_WITH_WEALTH_DESC',
  RepostDecayScoreAsc = 'REPOST_DECAY_SCORE_ASC',
  RepostDecayScoreDesc = 'REPOST_DECAY_SCORE_DESC',
  RepostDecayScoreWithWealthAsc = 'REPOST_DECAY_SCORE_WITH_WEALTH_ASC',
  RepostDecayScoreWithWealthDesc = 'REPOST_DECAY_SCORE_WITH_WEALTH_DESC',
  ShowLessDecayScoreAsc = 'SHOW_LESS_DECAY_SCORE_ASC',
  ShowLessDecayScoreDesc = 'SHOW_LESS_DECAY_SCORE_DESC',
  ShowLessDecayScoreWithWealthAsc = 'SHOW_LESS_DECAY_SCORE_WITH_WEALTH_ASC',
  ShowLessDecayScoreWithWealthDesc = 'SHOW_LESS_DECAY_SCORE_WITH_WEALTH_DESC',
  TipDecayScoreAsc = 'TIP_DECAY_SCORE_ASC',
  TipDecayScoreDesc = 'TIP_DECAY_SCORE_DESC',
  TipDecayScoreWithWealthAsc = 'TIP_DECAY_SCORE_WITH_WEALTH_ASC',
  TipDecayScoreWithWealthDesc = 'TIP_DECAY_SCORE_WITH_WEALTH_DESC',
}

export type FeedInteractionsPostAggNegativeReaction = {
  __typename?: 'FeedInteractionsPostAggNegativeReaction';
  balanceWeightedCount?: Maybe<Scalars['BigFloat']['output']>;
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  decayedInteractionScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  postHash?: Maybe<Scalars['String']['output']>;
  postTimestamp?: Maybe<Scalars['Datetime']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsPostAggNegativeReaction` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsPostAggNegativeReactionCondition = {
  /** Checks for equality with the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsPostAggNegativeReaction` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsPostAggNegativeReactionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsPostAggNegativeReactionFilter>>;
  /** Filter by the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsPostAggNegativeReactionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsPostAggNegativeReactionFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsPostAggNegativeReaction` values. */
export type FeedInteractionsPostAggNegativeReactionsConnection = {
  __typename?: 'FeedInteractionsPostAggNegativeReactionsConnection';
  /** A list of edges which contains the `FeedInteractionsPostAggNegativeReaction` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsPostAggNegativeReactionsEdge>;
  /** A list of `FeedInteractionsPostAggNegativeReaction` objects. */
  nodes: Array<Maybe<FeedInteractionsPostAggNegativeReaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsPostAggNegativeReaction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsPostAggNegativeReaction` edge in the connection. */
export type FeedInteractionsPostAggNegativeReactionsEdge = {
  __typename?: 'FeedInteractionsPostAggNegativeReactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsPostAggNegativeReaction` at the end of the edge. */
  node?: Maybe<FeedInteractionsPostAggNegativeReaction>;
};

/** Methods to use when ordering `FeedInteractionsPostAggNegativeReaction`. */
export enum FeedInteractionsPostAggNegativeReactionsOrderBy {
  BalanceWeightedCountAsc = 'BALANCE_WEIGHTED_COUNT_ASC',
  BalanceWeightedCountDesc = 'BALANCE_WEIGHTED_COUNT_DESC',
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  DecayedInteractionScoreWithWealthAsc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_ASC',
  DecayedInteractionScoreWithWealthDesc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_DESC',
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PostTimestampAsc = 'POST_TIMESTAMP_ASC',
  PostTimestampDesc = 'POST_TIMESTAMP_DESC',
}

export type FeedInteractionsPostAggNeutralReaction = {
  __typename?: 'FeedInteractionsPostAggNeutralReaction';
  balanceWeightedCount?: Maybe<Scalars['BigFloat']['output']>;
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  decayedInteractionScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  postHash?: Maybe<Scalars['String']['output']>;
  postTimestamp?: Maybe<Scalars['Datetime']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsPostAggNeutralReaction` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsPostAggNeutralReactionCondition = {
  /** Checks for equality with the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsPostAggNeutralReaction` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsPostAggNeutralReactionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsPostAggNeutralReactionFilter>>;
  /** Filter by the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsPostAggNeutralReactionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsPostAggNeutralReactionFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsPostAggNeutralReaction` values. */
export type FeedInteractionsPostAggNeutralReactionsConnection = {
  __typename?: 'FeedInteractionsPostAggNeutralReactionsConnection';
  /** A list of edges which contains the `FeedInteractionsPostAggNeutralReaction` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsPostAggNeutralReactionsEdge>;
  /** A list of `FeedInteractionsPostAggNeutralReaction` objects. */
  nodes: Array<Maybe<FeedInteractionsPostAggNeutralReaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsPostAggNeutralReaction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsPostAggNeutralReaction` edge in the connection. */
export type FeedInteractionsPostAggNeutralReactionsEdge = {
  __typename?: 'FeedInteractionsPostAggNeutralReactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsPostAggNeutralReaction` at the end of the edge. */
  node?: Maybe<FeedInteractionsPostAggNeutralReaction>;
};

/** Methods to use when ordering `FeedInteractionsPostAggNeutralReaction`. */
export enum FeedInteractionsPostAggNeutralReactionsOrderBy {
  BalanceWeightedCountAsc = 'BALANCE_WEIGHTED_COUNT_ASC',
  BalanceWeightedCountDesc = 'BALANCE_WEIGHTED_COUNT_DESC',
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  DecayedInteractionScoreWithWealthAsc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_ASC',
  DecayedInteractionScoreWithWealthDesc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_DESC',
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PostTimestampAsc = 'POST_TIMESTAMP_ASC',
  PostTimestampDesc = 'POST_TIMESTAMP_DESC',
}

export type FeedInteractionsPostAggPositiveReaction = {
  __typename?: 'FeedInteractionsPostAggPositiveReaction';
  balanceWeightedCount?: Maybe<Scalars['BigFloat']['output']>;
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  decayedInteractionScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  postHash?: Maybe<Scalars['String']['output']>;
  postTimestamp?: Maybe<Scalars['Datetime']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsPostAggPositiveReaction` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsPostAggPositiveReactionCondition = {
  /** Checks for equality with the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsPostAggPositiveReaction` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsPostAggPositiveReactionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsPostAggPositiveReactionFilter>>;
  /** Filter by the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsPostAggPositiveReactionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsPostAggPositiveReactionFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsPostAggPositiveReaction` values. */
export type FeedInteractionsPostAggPositiveReactionsConnection = {
  __typename?: 'FeedInteractionsPostAggPositiveReactionsConnection';
  /** A list of edges which contains the `FeedInteractionsPostAggPositiveReaction` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsPostAggPositiveReactionsEdge>;
  /** A list of `FeedInteractionsPostAggPositiveReaction` objects. */
  nodes: Array<Maybe<FeedInteractionsPostAggPositiveReaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsPostAggPositiveReaction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsPostAggPositiveReaction` edge in the connection. */
export type FeedInteractionsPostAggPositiveReactionsEdge = {
  __typename?: 'FeedInteractionsPostAggPositiveReactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsPostAggPositiveReaction` at the end of the edge. */
  node?: Maybe<FeedInteractionsPostAggPositiveReaction>;
};

/** Methods to use when ordering `FeedInteractionsPostAggPositiveReaction`. */
export enum FeedInteractionsPostAggPositiveReactionsOrderBy {
  BalanceWeightedCountAsc = 'BALANCE_WEIGHTED_COUNT_ASC',
  BalanceWeightedCountDesc = 'BALANCE_WEIGHTED_COUNT_DESC',
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  DecayedInteractionScoreWithWealthAsc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_ASC',
  DecayedInteractionScoreWithWealthDesc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_DESC',
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PostTimestampAsc = 'POST_TIMESTAMP_ASC',
  PostTimestampDesc = 'POST_TIMESTAMP_DESC',
}

export type FeedInteractionsPostAggQuote = {
  __typename?: 'FeedInteractionsPostAggQuote';
  balanceWeightedCount?: Maybe<Scalars['BigFloat']['output']>;
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  decayedInteractionScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  postHash?: Maybe<Scalars['String']['output']>;
  postTimestamp?: Maybe<Scalars['Datetime']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsPostAggQuote` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsPostAggQuoteCondition = {
  /** Checks for equality with the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsPostAggQuote` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsPostAggQuoteFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsPostAggQuoteFilter>>;
  /** Filter by the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsPostAggQuoteFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsPostAggQuoteFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsPostAggQuote` values. */
export type FeedInteractionsPostAggQuotesConnection = {
  __typename?: 'FeedInteractionsPostAggQuotesConnection';
  /** A list of edges which contains the `FeedInteractionsPostAggQuote` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsPostAggQuotesEdge>;
  /** A list of `FeedInteractionsPostAggQuote` objects. */
  nodes: Array<Maybe<FeedInteractionsPostAggQuote>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsPostAggQuote` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsPostAggQuote` edge in the connection. */
export type FeedInteractionsPostAggQuotesEdge = {
  __typename?: 'FeedInteractionsPostAggQuotesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsPostAggQuote` at the end of the edge. */
  node?: Maybe<FeedInteractionsPostAggQuote>;
};

/** Methods to use when ordering `FeedInteractionsPostAggQuote`. */
export enum FeedInteractionsPostAggQuotesOrderBy {
  BalanceWeightedCountAsc = 'BALANCE_WEIGHTED_COUNT_ASC',
  BalanceWeightedCountDesc = 'BALANCE_WEIGHTED_COUNT_DESC',
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  DecayedInteractionScoreWithWealthAsc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_ASC',
  DecayedInteractionScoreWithWealthDesc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_DESC',
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PostTimestampAsc = 'POST_TIMESTAMP_ASC',
  PostTimestampDesc = 'POST_TIMESTAMP_DESC',
}

/** A connection to a list of `FeedInteractionsPostAggReply` values. */
export type FeedInteractionsPostAggRepliesConnection = {
  __typename?: 'FeedInteractionsPostAggRepliesConnection';
  /** A list of edges which contains the `FeedInteractionsPostAggReply` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsPostAggRepliesEdge>;
  /** A list of `FeedInteractionsPostAggReply` objects. */
  nodes: Array<Maybe<FeedInteractionsPostAggReply>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsPostAggReply` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsPostAggReply` edge in the connection. */
export type FeedInteractionsPostAggRepliesEdge = {
  __typename?: 'FeedInteractionsPostAggRepliesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsPostAggReply` at the end of the edge. */
  node?: Maybe<FeedInteractionsPostAggReply>;
};

/** Methods to use when ordering `FeedInteractionsPostAggReply`. */
export enum FeedInteractionsPostAggRepliesOrderBy {
  BalanceWeightedCountAsc = 'BALANCE_WEIGHTED_COUNT_ASC',
  BalanceWeightedCountDesc = 'BALANCE_WEIGHTED_COUNT_DESC',
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  DecayedInteractionScoreWithWealthAsc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_ASC',
  DecayedInteractionScoreWithWealthDesc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_DESC',
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PostTimestampAsc = 'POST_TIMESTAMP_ASC',
  PostTimestampDesc = 'POST_TIMESTAMP_DESC',
}

export type FeedInteractionsPostAggReply = {
  __typename?: 'FeedInteractionsPostAggReply';
  balanceWeightedCount?: Maybe<Scalars['BigFloat']['output']>;
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  decayedInteractionScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  postHash?: Maybe<Scalars['String']['output']>;
  postTimestamp?: Maybe<Scalars['Datetime']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsPostAggReply` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsPostAggReplyCondition = {
  /** Checks for equality with the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsPostAggReply` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsPostAggReplyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsPostAggReplyFilter>>;
  /** Filter by the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsPostAggReplyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsPostAggReplyFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
};

export type FeedInteractionsPostAggReport = {
  __typename?: 'FeedInteractionsPostAggReport';
  balanceWeightedCount?: Maybe<Scalars['BigFloat']['output']>;
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  decayedInteractionScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  postHash?: Maybe<Scalars['String']['output']>;
  postTimestamp?: Maybe<Scalars['Datetime']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsPostAggReport` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsPostAggReportCondition = {
  /** Checks for equality with the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsPostAggReport` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsPostAggReportFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsPostAggReportFilter>>;
  /** Filter by the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsPostAggReportFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsPostAggReportFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsPostAggReport` values. */
export type FeedInteractionsPostAggReportsConnection = {
  __typename?: 'FeedInteractionsPostAggReportsConnection';
  /** A list of edges which contains the `FeedInteractionsPostAggReport` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsPostAggReportsEdge>;
  /** A list of `FeedInteractionsPostAggReport` objects. */
  nodes: Array<Maybe<FeedInteractionsPostAggReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsPostAggReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsPostAggReport` edge in the connection. */
export type FeedInteractionsPostAggReportsEdge = {
  __typename?: 'FeedInteractionsPostAggReportsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsPostAggReport` at the end of the edge. */
  node?: Maybe<FeedInteractionsPostAggReport>;
};

/** Methods to use when ordering `FeedInteractionsPostAggReport`. */
export enum FeedInteractionsPostAggReportsOrderBy {
  BalanceWeightedCountAsc = 'BALANCE_WEIGHTED_COUNT_ASC',
  BalanceWeightedCountDesc = 'BALANCE_WEIGHTED_COUNT_DESC',
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  DecayedInteractionScoreWithWealthAsc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_ASC',
  DecayedInteractionScoreWithWealthDesc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_DESC',
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PostTimestampAsc = 'POST_TIMESTAMP_ASC',
  PostTimestampDesc = 'POST_TIMESTAMP_DESC',
}

export type FeedInteractionsPostAggRepost = {
  __typename?: 'FeedInteractionsPostAggRepost';
  balanceWeightedCount?: Maybe<Scalars['BigFloat']['output']>;
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  decayedInteractionScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  postHash?: Maybe<Scalars['String']['output']>;
  postTimestamp?: Maybe<Scalars['Datetime']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsPostAggRepost` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsPostAggRepostCondition = {
  /** Checks for equality with the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsPostAggRepost` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsPostAggRepostFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsPostAggRepostFilter>>;
  /** Filter by the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsPostAggRepostFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsPostAggRepostFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsPostAggRepost` values. */
export type FeedInteractionsPostAggRepostsConnection = {
  __typename?: 'FeedInteractionsPostAggRepostsConnection';
  /** A list of edges which contains the `FeedInteractionsPostAggRepost` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsPostAggRepostsEdge>;
  /** A list of `FeedInteractionsPostAggRepost` objects. */
  nodes: Array<Maybe<FeedInteractionsPostAggRepost>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsPostAggRepost` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsPostAggRepost` edge in the connection. */
export type FeedInteractionsPostAggRepostsEdge = {
  __typename?: 'FeedInteractionsPostAggRepostsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsPostAggRepost` at the end of the edge. */
  node?: Maybe<FeedInteractionsPostAggRepost>;
};

/** Methods to use when ordering `FeedInteractionsPostAggRepost`. */
export enum FeedInteractionsPostAggRepostsOrderBy {
  BalanceWeightedCountAsc = 'BALANCE_WEIGHTED_COUNT_ASC',
  BalanceWeightedCountDesc = 'BALANCE_WEIGHTED_COUNT_DESC',
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  DecayedInteractionScoreWithWealthAsc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_ASC',
  DecayedInteractionScoreWithWealthDesc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_DESC',
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PostTimestampAsc = 'POST_TIMESTAMP_ASC',
  PostTimestampDesc = 'POST_TIMESTAMP_DESC',
}

export type FeedInteractionsPostAggShowLess = {
  __typename?: 'FeedInteractionsPostAggShowLess';
  balanceWeightedCount?: Maybe<Scalars['BigFloat']['output']>;
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  decayedInteractionScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  postHash?: Maybe<Scalars['String']['output']>;
  postTimestamp?: Maybe<Scalars['Datetime']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsPostAggShowLess` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsPostAggShowLessCondition = {
  /** Checks for equality with the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsPostAggShowLess` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsPostAggShowLessFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsPostAggShowLessFilter>>;
  /** Filter by the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsPostAggShowLessFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsPostAggShowLessFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsPostAggShowLess` values. */
export type FeedInteractionsPostAggShowLessesConnection = {
  __typename?: 'FeedInteractionsPostAggShowLessesConnection';
  /** A list of edges which contains the `FeedInteractionsPostAggShowLess` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsPostAggShowLessesEdge>;
  /** A list of `FeedInteractionsPostAggShowLess` objects. */
  nodes: Array<Maybe<FeedInteractionsPostAggShowLess>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsPostAggShowLess` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsPostAggShowLess` edge in the connection. */
export type FeedInteractionsPostAggShowLessesEdge = {
  __typename?: 'FeedInteractionsPostAggShowLessesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsPostAggShowLess` at the end of the edge. */
  node?: Maybe<FeedInteractionsPostAggShowLess>;
};

/** Methods to use when ordering `FeedInteractionsPostAggShowLess`. */
export enum FeedInteractionsPostAggShowLessesOrderBy {
  BalanceWeightedCountAsc = 'BALANCE_WEIGHTED_COUNT_ASC',
  BalanceWeightedCountDesc = 'BALANCE_WEIGHTED_COUNT_DESC',
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  DecayedInteractionScoreWithWealthAsc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_ASC',
  DecayedInteractionScoreWithWealthDesc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_DESC',
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PostTimestampAsc = 'POST_TIMESTAMP_ASC',
  PostTimestampDesc = 'POST_TIMESTAMP_DESC',
}

export type FeedInteractionsPostAggTimeDecay = {
  __typename?: 'FeedInteractionsPostAggTimeDecay';
  interactionType?: Maybe<InteractionType>;
  publicKey?: Maybe<Scalars['String']['output']>;
  timeDecay?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsPostAggTimeDecay` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsPostAggTimeDecayCondition = {
  /** Checks for equality with the object’s `interactionType` field. */
  interactionType?: InputMaybe<InteractionType>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timeDecay` field. */
  timeDecay?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FeedInteractionsPostAggTimeDecay` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsPostAggTimeDecayFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsPostAggTimeDecayFilter>>;
  /** Filter by the object’s `interactionType` field. */
  interactionType?: InputMaybe<InteractionTypeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsPostAggTimeDecayFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsPostAggTimeDecayFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timeDecay` field. */
  timeDecay?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `FeedInteractionsPostAggTimeDecay` values. */
export type FeedInteractionsPostAggTimeDecaysConnection = {
  __typename?: 'FeedInteractionsPostAggTimeDecaysConnection';
  /** A list of edges which contains the `FeedInteractionsPostAggTimeDecay` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsPostAggTimeDecaysEdge>;
  /** A list of `FeedInteractionsPostAggTimeDecay` objects. */
  nodes: Array<Maybe<FeedInteractionsPostAggTimeDecay>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsPostAggTimeDecay` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsPostAggTimeDecay` edge in the connection. */
export type FeedInteractionsPostAggTimeDecaysEdge = {
  __typename?: 'FeedInteractionsPostAggTimeDecaysEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsPostAggTimeDecay` at the end of the edge. */
  node?: Maybe<FeedInteractionsPostAggTimeDecay>;
};

/** Methods to use when ordering `FeedInteractionsPostAggTimeDecay`. */
export enum FeedInteractionsPostAggTimeDecaysOrderBy {
  InteractionTypeAsc = 'INTERACTION_TYPE_ASC',
  InteractionTypeDesc = 'INTERACTION_TYPE_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  TimeDecayAsc = 'TIME_DECAY_ASC',
  TimeDecayDesc = 'TIME_DECAY_DESC',
}

export type FeedInteractionsPostAggTip = {
  __typename?: 'FeedInteractionsPostAggTip';
  balanceWeightedCount?: Maybe<Scalars['BigFloat']['output']>;
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  decayedInteractionScoreWithWealth?: Maybe<Scalars['Float']['output']>;
  postHash?: Maybe<Scalars['String']['output']>;
  postTimestamp?: Maybe<Scalars['Datetime']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
  timeDecayedDesoNanosGiven?: Maybe<Scalars['BigFloat']['output']>;
  timeDecayedDiamondCount?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsPostAggTip` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsPostAggTipCondition = {
  /** Checks for equality with the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timeDecayedDesoNanosGiven` field. */
  timeDecayedDesoNanosGiven?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `timeDecayedDiamondCount` field. */
  timeDecayedDiamondCount?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FeedInteractionsPostAggTip` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsPostAggTipFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsPostAggTipFilter>>;
  /** Filter by the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsPostAggTipFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsPostAggTipFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timeDecayedDesoNanosGiven` field. */
  timeDecayedDesoNanosGiven?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `timeDecayedDiamondCount` field. */
  timeDecayedDiamondCount?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `FeedInteractionsPostAggTip` values. */
export type FeedInteractionsPostAggTipsConnection = {
  __typename?: 'FeedInteractionsPostAggTipsConnection';
  /** A list of edges which contains the `FeedInteractionsPostAggTip` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsPostAggTipsEdge>;
  /** A list of `FeedInteractionsPostAggTip` objects. */
  nodes: Array<Maybe<FeedInteractionsPostAggTip>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsPostAggTip` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsPostAggTip` edge in the connection. */
export type FeedInteractionsPostAggTipsEdge = {
  __typename?: 'FeedInteractionsPostAggTipsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsPostAggTip` at the end of the edge. */
  node?: Maybe<FeedInteractionsPostAggTip>;
};

/** Methods to use when ordering `FeedInteractionsPostAggTip`. */
export enum FeedInteractionsPostAggTipsOrderBy {
  BalanceWeightedCountAsc = 'BALANCE_WEIGHTED_COUNT_ASC',
  BalanceWeightedCountDesc = 'BALANCE_WEIGHTED_COUNT_DESC',
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  DecayedInteractionScoreWithWealthAsc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_ASC',
  DecayedInteractionScoreWithWealthDesc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_DESC',
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PostTimestampAsc = 'POST_TIMESTAMP_ASC',
  PostTimestampDesc = 'POST_TIMESTAMP_DESC',
  TimeDecayedDesoNanosGivenAsc = 'TIME_DECAYED_DESO_NANOS_GIVEN_ASC',
  TimeDecayedDesoNanosGivenDesc = 'TIME_DECAYED_DESO_NANOS_GIVEN_DESC',
  TimeDecayedDiamondCountAsc = 'TIME_DECAYED_DIAMOND_COUNT_ASC',
  TimeDecayedDiamondCountDesc = 'TIME_DECAYED_DIAMOND_COUNT_DESC',
}

export type FeedInteractionsPostAggView = {
  __typename?: 'FeedInteractionsPostAggView';
  balanceWeightedCount?: Maybe<Scalars['BigFloat']['output']>;
  latestTimestamp?: Maybe<Scalars['Datetime']['output']>;
  postHash?: Maybe<Scalars['String']['output']>;
  postTimestamp?: Maybe<Scalars['Datetime']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
  timeDecayedCount?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsPostAggView` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsPostAggViewCondition = {
  /** Checks for equality with the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `latestTimestamp` field. */
  latestTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timeDecayedCount` field. */
  timeDecayedCount?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FeedInteractionsPostAggView` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsPostAggViewFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsPostAggViewFilter>>;
  /** Filter by the object’s `balanceWeightedCount` field. */
  balanceWeightedCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `latestTimestamp` field. */
  latestTimestamp?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsPostAggViewFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsPostAggViewFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timeDecayedCount` field. */
  timeDecayedCount?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `FeedInteractionsPostAggView` values. */
export type FeedInteractionsPostAggViewsConnection = {
  __typename?: 'FeedInteractionsPostAggViewsConnection';
  /** A list of edges which contains the `FeedInteractionsPostAggView` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsPostAggViewsEdge>;
  /** A list of `FeedInteractionsPostAggView` objects. */
  nodes: Array<Maybe<FeedInteractionsPostAggView>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsPostAggView` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsPostAggView` edge in the connection. */
export type FeedInteractionsPostAggViewsEdge = {
  __typename?: 'FeedInteractionsPostAggViewsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsPostAggView` at the end of the edge. */
  node?: Maybe<FeedInteractionsPostAggView>;
};

/** Methods to use when ordering `FeedInteractionsPostAggView`. */
export enum FeedInteractionsPostAggViewsOrderBy {
  BalanceWeightedCountAsc = 'BALANCE_WEIGHTED_COUNT_ASC',
  BalanceWeightedCountDesc = 'BALANCE_WEIGHTED_COUNT_DESC',
  LatestTimestampAsc = 'LATEST_TIMESTAMP_ASC',
  LatestTimestampDesc = 'LATEST_TIMESTAMP_DESC',
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PostTimestampAsc = 'POST_TIMESTAMP_ASC',
  PostTimestampDesc = 'POST_TIMESTAMP_DESC',
  TimeDecayedCountAsc = 'TIME_DECAYED_COUNT_ASC',
  TimeDecayedCountDesc = 'TIME_DECAYED_COUNT_DESC',
}

export type FeedInteractionsPostNewPost = {
  __typename?: 'FeedInteractionsPostNewPost';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore: Scalars['Float']['output'];
  decayedInteractionScoreWithWealth: Scalars['Float']['output'];
  postHash?: Maybe<Scalars['String']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsPostNewPost` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsPostNewPostCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsPostNewPost` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsPostNewPostFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsPostNewPostFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `decayedInteractionScoreWithWealth` field. */
  decayedInteractionScoreWithWealth?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsPostNewPostFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsPostNewPostFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsPostNewPost` values. */
export type FeedInteractionsPostNewPostsConnection = {
  __typename?: 'FeedInteractionsPostNewPostsConnection';
  /** A list of edges which contains the `FeedInteractionsPostNewPost` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsPostNewPostsEdge>;
  /** A list of `FeedInteractionsPostNewPost` objects. */
  nodes: Array<Maybe<FeedInteractionsPostNewPost>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsPostNewPost` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsPostNewPost` edge in the connection. */
export type FeedInteractionsPostNewPostsEdge = {
  __typename?: 'FeedInteractionsPostNewPostsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsPostNewPost` at the end of the edge. */
  node?: Maybe<FeedInteractionsPostNewPost>;
};

/** Methods to use when ordering `FeedInteractionsPostNewPost`. */
export enum FeedInteractionsPostNewPostsOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  DecayedInteractionScoreWithWealthAsc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_ASC',
  DecayedInteractionScoreWithWealthDesc = 'DECAYED_INTERACTION_SCORE_WITH_WEALTH_DESC',
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
}

export type FeedInteractionsQuote = {
  __typename?: 'FeedInteractionsQuote';
  blockHeight: Scalars['BigInt']['output'];
  postHash?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsQuote` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsQuoteCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `FeedInteractionsQuote` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsQuoteFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsQuoteFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsQuoteFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsQuoteFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `FeedInteractionsQuote` values. */
export type FeedInteractionsQuotesConnection = {
  __typename?: 'FeedInteractionsQuotesConnection';
  /** A list of edges which contains the `FeedInteractionsQuote` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsQuotesEdge>;
  /** A list of `FeedInteractionsQuote` objects. */
  nodes: Array<Maybe<FeedInteractionsQuote>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsQuote` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsQuote` edge in the connection. */
export type FeedInteractionsQuotesEdge = {
  __typename?: 'FeedInteractionsQuotesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsQuote` at the end of the edge. */
  node?: Maybe<FeedInteractionsQuote>;
};

/** Methods to use when ordering `FeedInteractionsQuote`. */
export enum FeedInteractionsQuotesOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
}

/** A connection to a list of `FeedInteractionsReply` values. */
export type FeedInteractionsRepliesConnection = {
  __typename?: 'FeedInteractionsRepliesConnection';
  /** A list of edges which contains the `FeedInteractionsReply` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsRepliesEdge>;
  /** A list of `FeedInteractionsReply` objects. */
  nodes: Array<Maybe<FeedInteractionsReply>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsReply` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsReply` edge in the connection. */
export type FeedInteractionsRepliesEdge = {
  __typename?: 'FeedInteractionsRepliesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsReply` at the end of the edge. */
  node?: Maybe<FeedInteractionsReply>;
};

/** Methods to use when ordering `FeedInteractionsReply`. */
export enum FeedInteractionsRepliesOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
}

export type FeedInteractionsReply = {
  __typename?: 'FeedInteractionsReply';
  blockHeight: Scalars['BigInt']['output'];
  postHash?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsReply` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsReplyCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `FeedInteractionsReply` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsReplyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsReplyFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsReplyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsReplyFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

export type FeedInteractionsReport = {
  __typename?: 'FeedInteractionsReport';
  blockHeight: Scalars['BigInt']['output'];
  postHash?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsReport` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsReportCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `FeedInteractionsReport` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsReportFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsReportFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsReportFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsReportFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `FeedInteractionsReport` values. */
export type FeedInteractionsReportsConnection = {
  __typename?: 'FeedInteractionsReportsConnection';
  /** A list of edges which contains the `FeedInteractionsReport` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsReportsEdge>;
  /** A list of `FeedInteractionsReport` objects. */
  nodes: Array<Maybe<FeedInteractionsReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsReport` edge in the connection. */
export type FeedInteractionsReportsEdge = {
  __typename?: 'FeedInteractionsReportsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsReport` at the end of the edge. */
  node?: Maybe<FeedInteractionsReport>;
};

/** Methods to use when ordering `FeedInteractionsReport`. */
export enum FeedInteractionsReportsOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
}

export type FeedInteractionsRepost = {
  __typename?: 'FeedInteractionsRepost';
  blockHeight: Scalars['BigInt']['output'];
  postHash?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsRepost` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsRepostCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `FeedInteractionsRepost` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsRepostFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsRepostFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsRepostFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsRepostFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `FeedInteractionsRepost` values. */
export type FeedInteractionsRepostsConnection = {
  __typename?: 'FeedInteractionsRepostsConnection';
  /** A list of edges which contains the `FeedInteractionsRepost` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsRepostsEdge>;
  /** A list of `FeedInteractionsRepost` objects. */
  nodes: Array<Maybe<FeedInteractionsRepost>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsRepost` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsRepost` edge in the connection. */
export type FeedInteractionsRepostsEdge = {
  __typename?: 'FeedInteractionsRepostsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsRepost` at the end of the edge. */
  node?: Maybe<FeedInteractionsRepost>;
};

/** Methods to use when ordering `FeedInteractionsRepost`. */
export enum FeedInteractionsRepostsOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
}

export type FeedInteractionsShowLess = {
  __typename?: 'FeedInteractionsShowLess';
  blockHeight: Scalars['BigInt']['output'];
  postHash?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsShowLess` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsShowLessCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `FeedInteractionsShowLess` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsShowLessFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsShowLessFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsShowLessFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsShowLessFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `FeedInteractionsShowLess` values. */
export type FeedInteractionsShowLessesConnection = {
  __typename?: 'FeedInteractionsShowLessesConnection';
  /** A list of edges which contains the `FeedInteractionsShowLess` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsShowLessesEdge>;
  /** A list of `FeedInteractionsShowLess` objects. */
  nodes: Array<Maybe<FeedInteractionsShowLess>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsShowLess` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsShowLess` edge in the connection. */
export type FeedInteractionsShowLessesEdge = {
  __typename?: 'FeedInteractionsShowLessesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsShowLess` at the end of the edge. */
  node?: Maybe<FeedInteractionsShowLess>;
};

/** Methods to use when ordering `FeedInteractionsShowLess`. */
export enum FeedInteractionsShowLessesOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
}

export type FeedInteractionsTip = {
  __typename?: 'FeedInteractionsTip';
  blockHeight: Scalars['BigInt']['output'];
  diamondLevel?: Maybe<Scalars['Int']['output']>;
  postHash?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsTip` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsTipCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `diamondLevel` field. */
  diamondLevel?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `FeedInteractionsTip` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsTipFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsTipFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `diamondLevel` field. */
  diamondLevel?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsTipFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsTipFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `FeedInteractionsTip` values. */
export type FeedInteractionsTipsConnection = {
  __typename?: 'FeedInteractionsTipsConnection';
  /** A list of edges which contains the `FeedInteractionsTip` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsTipsEdge>;
  /** A list of `FeedInteractionsTip` objects. */
  nodes: Array<Maybe<FeedInteractionsTip>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsTip` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsTip` edge in the connection. */
export type FeedInteractionsTipsEdge = {
  __typename?: 'FeedInteractionsTipsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsTip` at the end of the edge. */
  node?: Maybe<FeedInteractionsTip>;
};

/** Methods to use when ordering `FeedInteractionsTip`. */
export enum FeedInteractionsTipsOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  DiamondLevelAsc = 'DIAMOND_LEVEL_ASC',
  DiamondLevelDesc = 'DIAMOND_LEVEL_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
}

export type FeedInteractionsUserAggAll = {
  __typename?: 'FeedInteractionsUserAggAll';
  blockCount?: Maybe<Scalars['BigFloat']['output']>;
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  followCount?: Maybe<Scalars['BigFloat']['output']>;
  negativeReactionCount?: Maybe<Scalars['BigFloat']['output']>;
  neutralReactionCount?: Maybe<Scalars['BigFloat']['output']>;
  positiveReactionCount?: Maybe<Scalars['BigFloat']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  quoteCount?: Maybe<Scalars['BigFloat']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  replyCount?: Maybe<Scalars['BigFloat']['output']>;
  reportCount?: Maybe<Scalars['BigFloat']['output']>;
  repostCount?: Maybe<Scalars['BigFloat']['output']>;
  showLessCount?: Maybe<Scalars['BigFloat']['output']>;
  tipCount?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsUserAggAll` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsUserAggAllCondition = {
  /** Checks for equality with the object’s `blockCount` field. */
  blockCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `followCount` field. */
  followCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `negativeReactionCount` field. */
  negativeReactionCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `neutralReactionCount` field. */
  neutralReactionCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `positiveReactionCount` field. */
  positiveReactionCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `quoteCount` field. */
  quoteCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `replyCount` field. */
  replyCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `reportCount` field. */
  reportCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `repostCount` field. */
  repostCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `showLessCount` field. */
  showLessCount?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `tipCount` field. */
  tipCount?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FeedInteractionsUserAggAll` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsUserAggAllFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsUserAggAllFilter>>;
  /** Filter by the object’s `blockCount` field. */
  blockCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `followCount` field. */
  followCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `negativeReactionCount` field. */
  negativeReactionCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `neutralReactionCount` field. */
  neutralReactionCount?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsUserAggAllFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsUserAggAllFilter>>;
  /** Filter by the object’s `positiveReactionCount` field. */
  positiveReactionCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `quoteCount` field. */
  quoteCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `replyCount` field. */
  replyCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `reportCount` field. */
  reportCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `repostCount` field. */
  repostCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `showLessCount` field. */
  showLessCount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `tipCount` field. */
  tipCount?: InputMaybe<BigFloatFilter>;
};

/** An input for mutations affecting `FeedInteractionsUserAggAll` */
export type FeedInteractionsUserAggAllInput = {
  blockCount?: InputMaybe<Scalars['BigFloat']['input']>;
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  followCount?: InputMaybe<Scalars['BigFloat']['input']>;
  negativeReactionCount?: InputMaybe<Scalars['BigFloat']['input']>;
  neutralReactionCount?: InputMaybe<Scalars['BigFloat']['input']>;
  positiveReactionCount?: InputMaybe<Scalars['BigFloat']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  quoteCount?: InputMaybe<Scalars['BigFloat']['input']>;
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  replyCount?: InputMaybe<Scalars['BigFloat']['input']>;
  reportCount?: InputMaybe<Scalars['BigFloat']['input']>;
  repostCount?: InputMaybe<Scalars['BigFloat']['input']>;
  showLessCount?: InputMaybe<Scalars['BigFloat']['input']>;
  tipCount?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A connection to a list of `FeedInteractionsUserAggAll` values. */
export type FeedInteractionsUserAggAllsConnection = {
  __typename?: 'FeedInteractionsUserAggAllsConnection';
  /** A list of edges which contains the `FeedInteractionsUserAggAll` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsUserAggAllsEdge>;
  /** A list of `FeedInteractionsUserAggAll` objects. */
  nodes: Array<Maybe<FeedInteractionsUserAggAll>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsUserAggAll` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsUserAggAll` edge in the connection. */
export type FeedInteractionsUserAggAllsEdge = {
  __typename?: 'FeedInteractionsUserAggAllsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsUserAggAll` at the end of the edge. */
  node?: Maybe<FeedInteractionsUserAggAll>;
};

/** Methods to use when ordering `FeedInteractionsUserAggAll`. */
export enum FeedInteractionsUserAggAllsOrderBy {
  BlockCountAsc = 'BLOCK_COUNT_ASC',
  BlockCountDesc = 'BLOCK_COUNT_DESC',
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  FollowCountAsc = 'FOLLOW_COUNT_ASC',
  FollowCountDesc = 'FOLLOW_COUNT_DESC',
  Natural = 'NATURAL',
  NegativeReactionCountAsc = 'NEGATIVE_REACTION_COUNT_ASC',
  NegativeReactionCountDesc = 'NEGATIVE_REACTION_COUNT_DESC',
  NeutralReactionCountAsc = 'NEUTRAL_REACTION_COUNT_ASC',
  NeutralReactionCountDesc = 'NEUTRAL_REACTION_COUNT_DESC',
  PositiveReactionCountAsc = 'POSITIVE_REACTION_COUNT_ASC',
  PositiveReactionCountDesc = 'POSITIVE_REACTION_COUNT_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  QuoteCountAsc = 'QUOTE_COUNT_ASC',
  QuoteCountDesc = 'QUOTE_COUNT_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  ReplyCountAsc = 'REPLY_COUNT_ASC',
  ReplyCountDesc = 'REPLY_COUNT_DESC',
  ReportCountAsc = 'REPORT_COUNT_ASC',
  ReportCountDesc = 'REPORT_COUNT_DESC',
  RepostCountAsc = 'REPOST_COUNT_ASC',
  RepostCountDesc = 'REPOST_COUNT_DESC',
  ShowLessCountAsc = 'SHOW_LESS_COUNT_ASC',
  ShowLessCountDesc = 'SHOW_LESS_COUNT_DESC',
  TipCountAsc = 'TIP_COUNT_ASC',
  TipCountDesc = 'TIP_COUNT_DESC',
}

export type FeedInteractionsUserAggBlock = {
  __typename?: 'FeedInteractionsUserAggBlock';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsUserAggBlock` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsUserAggBlockCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsUserAggBlock` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsUserAggBlockFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsUserAggBlockFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsUserAggBlockFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsUserAggBlockFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsUserAggBlock` values. */
export type FeedInteractionsUserAggBlocksConnection = {
  __typename?: 'FeedInteractionsUserAggBlocksConnection';
  /** A list of edges which contains the `FeedInteractionsUserAggBlock` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsUserAggBlocksEdge>;
  /** A list of `FeedInteractionsUserAggBlock` objects. */
  nodes: Array<Maybe<FeedInteractionsUserAggBlock>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsUserAggBlock` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsUserAggBlock` edge in the connection. */
export type FeedInteractionsUserAggBlocksEdge = {
  __typename?: 'FeedInteractionsUserAggBlocksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsUserAggBlock` at the end of the edge. */
  node?: Maybe<FeedInteractionsUserAggBlock>;
};

/** Methods to use when ordering `FeedInteractionsUserAggBlock`. */
export enum FeedInteractionsUserAggBlocksOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
}

export type FeedInteractionsUserAggFocusFollow = {
  __typename?: 'FeedInteractionsUserAggFocusFollow';
  latestTimestamp?: Maybe<Scalars['Datetime']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timeDecayedCount?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsUserAggFocusFollow` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsUserAggFocusFollowCondition = {
  /** Checks for equality with the object’s `latestTimestamp` field. */
  latestTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timeDecayedCount` field. */
  timeDecayedCount?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FeedInteractionsUserAggFocusFollow` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsUserAggFocusFollowFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsUserAggFocusFollowFilter>>;
  /** Filter by the object’s `latestTimestamp` field. */
  latestTimestamp?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsUserAggFocusFollowFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsUserAggFocusFollowFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timeDecayedCount` field. */
  timeDecayedCount?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `FeedInteractionsUserAggFocusFollow` values. */
export type FeedInteractionsUserAggFocusFollowsConnection = {
  __typename?: 'FeedInteractionsUserAggFocusFollowsConnection';
  /** A list of edges which contains the `FeedInteractionsUserAggFocusFollow` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsUserAggFocusFollowsEdge>;
  /** A list of `FeedInteractionsUserAggFocusFollow` objects. */
  nodes: Array<Maybe<FeedInteractionsUserAggFocusFollow>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsUserAggFocusFollow` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsUserAggFocusFollow` edge in the connection. */
export type FeedInteractionsUserAggFocusFollowsEdge = {
  __typename?: 'FeedInteractionsUserAggFocusFollowsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsUserAggFocusFollow` at the end of the edge. */
  node?: Maybe<FeedInteractionsUserAggFocusFollow>;
};

/** Methods to use when ordering `FeedInteractionsUserAggFocusFollow`. */
export enum FeedInteractionsUserAggFocusFollowsOrderBy {
  LatestTimestampAsc = 'LATEST_TIMESTAMP_ASC',
  LatestTimestampDesc = 'LATEST_TIMESTAMP_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimeDecayedCountAsc = 'TIME_DECAYED_COUNT_ASC',
  TimeDecayedCountDesc = 'TIME_DECAYED_COUNT_DESC',
}

export type FeedInteractionsUserAggFollow = {
  __typename?: 'FeedInteractionsUserAggFollow';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsUserAggFollow` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsUserAggFollowCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsUserAggFollow` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsUserAggFollowFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsUserAggFollowFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsUserAggFollowFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsUserAggFollowFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsUserAggFollow` values. */
export type FeedInteractionsUserAggFollowsConnection = {
  __typename?: 'FeedInteractionsUserAggFollowsConnection';
  /** A list of edges which contains the `FeedInteractionsUserAggFollow` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsUserAggFollowsEdge>;
  /** A list of `FeedInteractionsUserAggFollow` objects. */
  nodes: Array<Maybe<FeedInteractionsUserAggFollow>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsUserAggFollow` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsUserAggFollow` edge in the connection. */
export type FeedInteractionsUserAggFollowsEdge = {
  __typename?: 'FeedInteractionsUserAggFollowsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsUserAggFollow` at the end of the edge. */
  node?: Maybe<FeedInteractionsUserAggFollow>;
};

/** Methods to use when ordering `FeedInteractionsUserAggFollow`. */
export enum FeedInteractionsUserAggFollowsOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
}

export type FeedInteractionsUserAggNegativeReaction = {
  __typename?: 'FeedInteractionsUserAggNegativeReaction';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsUserAggNegativeReaction` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsUserAggNegativeReactionCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsUserAggNegativeReaction` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsUserAggNegativeReactionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsUserAggNegativeReactionFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsUserAggNegativeReactionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsUserAggNegativeReactionFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsUserAggNegativeReaction` values. */
export type FeedInteractionsUserAggNegativeReactionsConnection = {
  __typename?: 'FeedInteractionsUserAggNegativeReactionsConnection';
  /** A list of edges which contains the `FeedInteractionsUserAggNegativeReaction` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsUserAggNegativeReactionsEdge>;
  /** A list of `FeedInteractionsUserAggNegativeReaction` objects. */
  nodes: Array<Maybe<FeedInteractionsUserAggNegativeReaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsUserAggNegativeReaction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsUserAggNegativeReaction` edge in the connection. */
export type FeedInteractionsUserAggNegativeReactionsEdge = {
  __typename?: 'FeedInteractionsUserAggNegativeReactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsUserAggNegativeReaction` at the end of the edge. */
  node?: Maybe<FeedInteractionsUserAggNegativeReaction>;
};

/** Methods to use when ordering `FeedInteractionsUserAggNegativeReaction`. */
export enum FeedInteractionsUserAggNegativeReactionsOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
}

export type FeedInteractionsUserAggNeutralReaction = {
  __typename?: 'FeedInteractionsUserAggNeutralReaction';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsUserAggNeutralReaction` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsUserAggNeutralReactionCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsUserAggNeutralReaction` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsUserAggNeutralReactionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsUserAggNeutralReactionFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsUserAggNeutralReactionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsUserAggNeutralReactionFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsUserAggNeutralReaction` values. */
export type FeedInteractionsUserAggNeutralReactionsConnection = {
  __typename?: 'FeedInteractionsUserAggNeutralReactionsConnection';
  /** A list of edges which contains the `FeedInteractionsUserAggNeutralReaction` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsUserAggNeutralReactionsEdge>;
  /** A list of `FeedInteractionsUserAggNeutralReaction` objects. */
  nodes: Array<Maybe<FeedInteractionsUserAggNeutralReaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsUserAggNeutralReaction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsUserAggNeutralReaction` edge in the connection. */
export type FeedInteractionsUserAggNeutralReactionsEdge = {
  __typename?: 'FeedInteractionsUserAggNeutralReactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsUserAggNeutralReaction` at the end of the edge. */
  node?: Maybe<FeedInteractionsUserAggNeutralReaction>;
};

/** Methods to use when ordering `FeedInteractionsUserAggNeutralReaction`. */
export enum FeedInteractionsUserAggNeutralReactionsOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
}

export type FeedInteractionsUserAggPositiveReaction = {
  __typename?: 'FeedInteractionsUserAggPositiveReaction';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsUserAggPositiveReaction` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsUserAggPositiveReactionCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsUserAggPositiveReaction` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsUserAggPositiveReactionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsUserAggPositiveReactionFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsUserAggPositiveReactionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsUserAggPositiveReactionFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsUserAggPositiveReaction` values. */
export type FeedInteractionsUserAggPositiveReactionsConnection = {
  __typename?: 'FeedInteractionsUserAggPositiveReactionsConnection';
  /** A list of edges which contains the `FeedInteractionsUserAggPositiveReaction` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsUserAggPositiveReactionsEdge>;
  /** A list of `FeedInteractionsUserAggPositiveReaction` objects. */
  nodes: Array<Maybe<FeedInteractionsUserAggPositiveReaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsUserAggPositiveReaction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsUserAggPositiveReaction` edge in the connection. */
export type FeedInteractionsUserAggPositiveReactionsEdge = {
  __typename?: 'FeedInteractionsUserAggPositiveReactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsUserAggPositiveReaction` at the end of the edge. */
  node?: Maybe<FeedInteractionsUserAggPositiveReaction>;
};

/** Methods to use when ordering `FeedInteractionsUserAggPositiveReaction`. */
export enum FeedInteractionsUserAggPositiveReactionsOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
}

export type FeedInteractionsUserAggQuote = {
  __typename?: 'FeedInteractionsUserAggQuote';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsUserAggQuote` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsUserAggQuoteCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsUserAggQuote` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsUserAggQuoteFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsUserAggQuoteFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsUserAggQuoteFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsUserAggQuoteFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsUserAggQuote` values. */
export type FeedInteractionsUserAggQuotesConnection = {
  __typename?: 'FeedInteractionsUserAggQuotesConnection';
  /** A list of edges which contains the `FeedInteractionsUserAggQuote` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsUserAggQuotesEdge>;
  /** A list of `FeedInteractionsUserAggQuote` objects. */
  nodes: Array<Maybe<FeedInteractionsUserAggQuote>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsUserAggQuote` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsUserAggQuote` edge in the connection. */
export type FeedInteractionsUserAggQuotesEdge = {
  __typename?: 'FeedInteractionsUserAggQuotesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsUserAggQuote` at the end of the edge. */
  node?: Maybe<FeedInteractionsUserAggQuote>;
};

/** Methods to use when ordering `FeedInteractionsUserAggQuote`. */
export enum FeedInteractionsUserAggQuotesOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
}

/** A connection to a list of `FeedInteractionsUserAggReply` values. */
export type FeedInteractionsUserAggRepliesConnection = {
  __typename?: 'FeedInteractionsUserAggRepliesConnection';
  /** A list of edges which contains the `FeedInteractionsUserAggReply` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsUserAggRepliesEdge>;
  /** A list of `FeedInteractionsUserAggReply` objects. */
  nodes: Array<Maybe<FeedInteractionsUserAggReply>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsUserAggReply` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsUserAggReply` edge in the connection. */
export type FeedInteractionsUserAggRepliesEdge = {
  __typename?: 'FeedInteractionsUserAggRepliesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsUserAggReply` at the end of the edge. */
  node?: Maybe<FeedInteractionsUserAggReply>;
};

/** Methods to use when ordering `FeedInteractionsUserAggReply`. */
export enum FeedInteractionsUserAggRepliesOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
}

export type FeedInteractionsUserAggReply = {
  __typename?: 'FeedInteractionsUserAggReply';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsUserAggReply` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsUserAggReplyCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsUserAggReply` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsUserAggReplyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsUserAggReplyFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsUserAggReplyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsUserAggReplyFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
};

export type FeedInteractionsUserAggReport = {
  __typename?: 'FeedInteractionsUserAggReport';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsUserAggReport` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsUserAggReportCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsUserAggReport` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsUserAggReportFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsUserAggReportFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsUserAggReportFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsUserAggReportFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsUserAggReport` values. */
export type FeedInteractionsUserAggReportsConnection = {
  __typename?: 'FeedInteractionsUserAggReportsConnection';
  /** A list of edges which contains the `FeedInteractionsUserAggReport` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsUserAggReportsEdge>;
  /** A list of `FeedInteractionsUserAggReport` objects. */
  nodes: Array<Maybe<FeedInteractionsUserAggReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsUserAggReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsUserAggReport` edge in the connection. */
export type FeedInteractionsUserAggReportsEdge = {
  __typename?: 'FeedInteractionsUserAggReportsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsUserAggReport` at the end of the edge. */
  node?: Maybe<FeedInteractionsUserAggReport>;
};

/** Methods to use when ordering `FeedInteractionsUserAggReport`. */
export enum FeedInteractionsUserAggReportsOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
}

export type FeedInteractionsUserAggRepost = {
  __typename?: 'FeedInteractionsUserAggRepost';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsUserAggRepost` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsUserAggRepostCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsUserAggRepost` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsUserAggRepostFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsUserAggRepostFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsUserAggRepostFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsUserAggRepostFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsUserAggRepost` values. */
export type FeedInteractionsUserAggRepostsConnection = {
  __typename?: 'FeedInteractionsUserAggRepostsConnection';
  /** A list of edges which contains the `FeedInteractionsUserAggRepost` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsUserAggRepostsEdge>;
  /** A list of `FeedInteractionsUserAggRepost` objects. */
  nodes: Array<Maybe<FeedInteractionsUserAggRepost>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsUserAggRepost` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsUserAggRepost` edge in the connection. */
export type FeedInteractionsUserAggRepostsEdge = {
  __typename?: 'FeedInteractionsUserAggRepostsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsUserAggRepost` at the end of the edge. */
  node?: Maybe<FeedInteractionsUserAggRepost>;
};

/** Methods to use when ordering `FeedInteractionsUserAggRepost`. */
export enum FeedInteractionsUserAggRepostsOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
}

export type FeedInteractionsUserAggShowLess = {
  __typename?: 'FeedInteractionsUserAggShowLess';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsUserAggShowLess` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsUserAggShowLessCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedInteractionsUserAggShowLess` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsUserAggShowLessFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsUserAggShowLessFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsUserAggShowLessFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsUserAggShowLessFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedInteractionsUserAggShowLess` values. */
export type FeedInteractionsUserAggShowLessesConnection = {
  __typename?: 'FeedInteractionsUserAggShowLessesConnection';
  /** A list of edges which contains the `FeedInteractionsUserAggShowLess` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsUserAggShowLessesEdge>;
  /** A list of `FeedInteractionsUserAggShowLess` objects. */
  nodes: Array<Maybe<FeedInteractionsUserAggShowLess>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsUserAggShowLess` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsUserAggShowLess` edge in the connection. */
export type FeedInteractionsUserAggShowLessesEdge = {
  __typename?: 'FeedInteractionsUserAggShowLessesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsUserAggShowLess` at the end of the edge. */
  node?: Maybe<FeedInteractionsUserAggShowLess>;
};

/** Methods to use when ordering `FeedInteractionsUserAggShowLess`. */
export enum FeedInteractionsUserAggShowLessesOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
}

export type FeedInteractionsUserAggTimeDecay = {
  __typename?: 'FeedInteractionsUserAggTimeDecay';
  interactionType?: Maybe<InteractionType>;
  publicKey?: Maybe<Scalars['String']['output']>;
  timeDecay?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsUserAggTimeDecay` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsUserAggTimeDecayCondition = {
  /** Checks for equality with the object’s `interactionType` field. */
  interactionType?: InputMaybe<InteractionType>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timeDecay` field. */
  timeDecay?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FeedInteractionsUserAggTimeDecay` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsUserAggTimeDecayFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsUserAggTimeDecayFilter>>;
  /** Filter by the object’s `interactionType` field. */
  interactionType?: InputMaybe<InteractionTypeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsUserAggTimeDecayFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsUserAggTimeDecayFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timeDecay` field. */
  timeDecay?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `FeedInteractionsUserAggTimeDecay` values. */
export type FeedInteractionsUserAggTimeDecaysConnection = {
  __typename?: 'FeedInteractionsUserAggTimeDecaysConnection';
  /** A list of edges which contains the `FeedInteractionsUserAggTimeDecay` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsUserAggTimeDecaysEdge>;
  /** A list of `FeedInteractionsUserAggTimeDecay` objects. */
  nodes: Array<Maybe<FeedInteractionsUserAggTimeDecay>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsUserAggTimeDecay` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsUserAggTimeDecay` edge in the connection. */
export type FeedInteractionsUserAggTimeDecaysEdge = {
  __typename?: 'FeedInteractionsUserAggTimeDecaysEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsUserAggTimeDecay` at the end of the edge. */
  node?: Maybe<FeedInteractionsUserAggTimeDecay>;
};

/** Methods to use when ordering `FeedInteractionsUserAggTimeDecay`. */
export enum FeedInteractionsUserAggTimeDecaysOrderBy {
  InteractionTypeAsc = 'INTERACTION_TYPE_ASC',
  InteractionTypeDesc = 'INTERACTION_TYPE_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  TimeDecayAsc = 'TIME_DECAY_ASC',
  TimeDecayDesc = 'TIME_DECAY_DESC',
}

export type FeedInteractionsUserAggTip = {
  __typename?: 'FeedInteractionsUserAggTip';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  decayedInteractionScore?: Maybe<Scalars['Float']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timeDecayedDesoNanos?: Maybe<Scalars['BigFloat']['output']>;
  timeDecayedDiamondCount?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsUserAggTip` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsUserAggTipCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timeDecayedDesoNanos` field. */
  timeDecayedDesoNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `timeDecayedDiamondCount` field. */
  timeDecayedDiamondCount?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FeedInteractionsUserAggTip` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsUserAggTipFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsUserAggTipFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `decayedInteractionScore` field. */
  decayedInteractionScore?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsUserAggTipFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsUserAggTipFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timeDecayedDesoNanos` field. */
  timeDecayedDesoNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `timeDecayedDiamondCount` field. */
  timeDecayedDiamondCount?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `FeedInteractionsUserAggTip` values. */
export type FeedInteractionsUserAggTipsConnection = {
  __typename?: 'FeedInteractionsUserAggTipsConnection';
  /** A list of edges which contains the `FeedInteractionsUserAggTip` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsUserAggTipsEdge>;
  /** A list of `FeedInteractionsUserAggTip` objects. */
  nodes: Array<Maybe<FeedInteractionsUserAggTip>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsUserAggTip` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsUserAggTip` edge in the connection. */
export type FeedInteractionsUserAggTipsEdge = {
  __typename?: 'FeedInteractionsUserAggTipsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsUserAggTip` at the end of the edge. */
  node?: Maybe<FeedInteractionsUserAggTip>;
};

/** Methods to use when ordering `FeedInteractionsUserAggTip`. */
export enum FeedInteractionsUserAggTipsOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  DecayedInteractionScoreAsc = 'DECAYED_INTERACTION_SCORE_ASC',
  DecayedInteractionScoreDesc = 'DECAYED_INTERACTION_SCORE_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimeDecayedDesoNanosAsc = 'TIME_DECAYED_DESO_NANOS_ASC',
  TimeDecayedDesoNanosDesc = 'TIME_DECAYED_DESO_NANOS_DESC',
  TimeDecayedDiamondCountAsc = 'TIME_DECAYED_DIAMOND_COUNT_ASC',
  TimeDecayedDiamondCountDesc = 'TIME_DECAYED_DIAMOND_COUNT_DESC',
}

export type FeedInteractionsView = {
  __typename?: 'FeedInteractionsView';
  postHash?: Maybe<Scalars['String']['output']>;
  postTimestamp?: Maybe<Scalars['Datetime']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `FeedInteractionsView` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FeedInteractionsViewCondition = {
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `FeedInteractionsView` object types. All fields are combined with a logical ‘and.’ */
export type FeedInteractionsViewFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedInteractionsViewFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<FeedInteractionsViewFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedInteractionsViewFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `FeedInteractionsView` values. */
export type FeedInteractionsViewsConnection = {
  __typename?: 'FeedInteractionsViewsConnection';
  /** A list of edges which contains the `FeedInteractionsView` and cursor to aid in pagination. */
  edges: Array<FeedInteractionsViewsEdge>;
  /** A list of `FeedInteractionsView` objects. */
  nodes: Array<Maybe<FeedInteractionsView>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInteractionsView` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedInteractionsView` edge in the connection. */
export type FeedInteractionsViewsEdge = {
  __typename?: 'FeedInteractionsViewsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInteractionsView` at the end of the edge. */
  node?: Maybe<FeedInteractionsView>;
};

/** Methods to use when ordering `FeedInteractionsView`. */
export enum FeedInteractionsViewsOrderBy {
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PostTimestampAsc = 'POST_TIMESTAMP_ASC',
  PostTimestampDesc = 'POST_TIMESTAMP_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
}

export type FeedPostScore = {
  __typename?: 'FeedPostScore';
  postHash?: Maybe<Scalars['String']['output']>;
  postTimestamp?: Maybe<Scalars['Datetime']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `FeedPostScore` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FeedPostScoreCondition = {
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `score` field. */
  score?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FeedPostScore` object types. All fields are combined with a logical ‘and.’ */
export type FeedPostScoreFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedPostScoreFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<FeedPostScoreFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedPostScoreFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `score` field. */
  score?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `FeedPostScore` values. */
export type FeedPostScoresConnection = {
  __typename?: 'FeedPostScoresConnection';
  /** A list of edges which contains the `FeedPostScore` and cursor to aid in pagination. */
  edges: Array<FeedPostScoresEdge>;
  /** A list of `FeedPostScore` objects. */
  nodes: Array<Maybe<FeedPostScore>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedPostScore` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedPostScore` edge in the connection. */
export type FeedPostScoresEdge = {
  __typename?: 'FeedPostScoresEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedPostScore` at the end of the edge. */
  node?: Maybe<FeedPostScore>;
};

/** Methods to use when ordering `FeedPostScore`. */
export enum FeedPostScoresOrderBy {
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PostTimestampAsc = 'POST_TIMESTAMP_ASC',
  PostTimestampDesc = 'POST_TIMESTAMP_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  ScoreAsc = 'SCORE_ASC',
  ScoreDesc = 'SCORE_DESC',
}

export type FeedPostTimeDecay = {
  __typename?: 'FeedPostTimeDecay';
  publicKey?: Maybe<Scalars['String']['output']>;
  timeDecay?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `FeedPostTimeDecay` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FeedPostTimeDecayCondition = {
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timeDecay` field. */
  timeDecay?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FeedPostTimeDecay` object types. All fields are combined with a logical ‘and.’ */
export type FeedPostTimeDecayFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedPostTimeDecayFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<FeedPostTimeDecayFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedPostTimeDecayFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timeDecay` field. */
  timeDecay?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `FeedPostTimeDecay` values. */
export type FeedPostTimeDecaysConnection = {
  __typename?: 'FeedPostTimeDecaysConnection';
  /** A list of edges which contains the `FeedPostTimeDecay` and cursor to aid in pagination. */
  edges: Array<FeedPostTimeDecaysEdge>;
  /** A list of `FeedPostTimeDecay` objects. */
  nodes: Array<Maybe<FeedPostTimeDecay>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedPostTimeDecay` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedPostTimeDecay` edge in the connection. */
export type FeedPostTimeDecaysEdge = {
  __typename?: 'FeedPostTimeDecaysEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedPostTimeDecay` at the end of the edge. */
  node?: Maybe<FeedPostTimeDecay>;
};

/** Methods to use when ordering `FeedPostTimeDecay`. */
export enum FeedPostTimeDecaysOrderBy {
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  TimeDecayAsc = 'TIME_DECAY_ASC',
  TimeDecayDesc = 'TIME_DECAY_DESC',
}

/** All input for the `feedRefreshAll` mutation. */
export type FeedRefreshAllInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `feedRefreshAll` mutation. */
export type FeedRefreshAllPayload = {
  __typename?: 'FeedRefreshAllPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `feedRefreshPosts` mutation. */
export type FeedRefreshPostsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `feedRefreshPosts` mutation. */
export type FeedRefreshPostsPayload = {
  __typename?: 'FeedRefreshPostsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `feedRefreshUsers` mutation. */
export type FeedRefreshUsersInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `feedRefreshUsers` mutation. */
export type FeedRefreshUsersPayload = {
  __typename?: 'FeedRefreshUsersPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type FeedSetting = Node & {
  __typename?: 'FeedSetting';
  blockInteractionDecayRate?: Maybe<Scalars['Float']['output']>;
  blockWeight?: Maybe<Scalars['Float']['output']>;
  finalPostScoreDecayRate?: Maybe<Scalars['Float']['output']>;
  followCountWeight?: Maybe<Scalars['Float']['output']>;
  followInteractionDecayRate?: Maybe<Scalars['Float']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  negativeReactionInteractionDecayRate?: Maybe<Scalars['Float']['output']>;
  negativeReactionWeight?: Maybe<Scalars['Float']['output']>;
  neutralReactionInteractionDecayRate?: Maybe<Scalars['Float']['output']>;
  neutralReactionWeight?: Maybe<Scalars['Float']['output']>;
  newPostDecayRate?: Maybe<Scalars['Float']['output']>;
  newPostWeight?: Maybe<Scalars['Float']['output']>;
  positiveReactionInteractionDecayRate?: Maybe<Scalars['Float']['output']>;
  positiveReactionWeight?: Maybe<Scalars['Float']['output']>;
  postMaxWealthContributionUsdCents?: Maybe<Scalars['Float']['output']>;
  postScoreDecayRate?: Maybe<Scalars['Float']['output']>;
  postScoreSigmoidScaling?: Maybe<Scalars['Float']['output']>;
  quoteInteractionDecayRate?: Maybe<Scalars['Float']['output']>;
  quoteWeight?: Maybe<Scalars['Float']['output']>;
  replyInteractionDecayRate?: Maybe<Scalars['Float']['output']>;
  replyWeight?: Maybe<Scalars['Float']['output']>;
  reportInteractionDecayRate?: Maybe<Scalars['Float']['output']>;
  reportWeight?: Maybe<Scalars['Float']['output']>;
  repostInteractionDecayRate?: Maybe<Scalars['Float']['output']>;
  repostWeight?: Maybe<Scalars['Float']['output']>;
  rowId: Scalars['Int']['output'];
  showLessInteractionDecayRate?: Maybe<Scalars['Float']['output']>;
  showLessWeight?: Maybe<Scalars['Float']['output']>;
  tipInteractionDecayRate?: Maybe<Scalars['Float']['output']>;
  tipWeight?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `FeedSetting` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FeedSettingCondition = {
  /** Checks for equality with the object’s `blockInteractionDecayRate` field. */
  blockInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `blockWeight` field. */
  blockWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `finalPostScoreDecayRate` field. */
  finalPostScoreDecayRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `followCountWeight` field. */
  followCountWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `followInteractionDecayRate` field. */
  followInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `negativeReactionInteractionDecayRate` field. */
  negativeReactionInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `negativeReactionWeight` field. */
  negativeReactionWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `neutralReactionInteractionDecayRate` field. */
  neutralReactionInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `neutralReactionWeight` field. */
  neutralReactionWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `newPostDecayRate` field. */
  newPostDecayRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `newPostWeight` field. */
  newPostWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `positiveReactionInteractionDecayRate` field. */
  positiveReactionInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `positiveReactionWeight` field. */
  positiveReactionWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postMaxWealthContributionUsdCents` field. */
  postMaxWealthContributionUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postScoreDecayRate` field. */
  postScoreDecayRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postScoreSigmoidScaling` field. */
  postScoreSigmoidScaling?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `quoteInteractionDecayRate` field. */
  quoteInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `quoteWeight` field. */
  quoteWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `replyInteractionDecayRate` field. */
  replyInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `replyWeight` field. */
  replyWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `reportInteractionDecayRate` field. */
  reportInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `reportWeight` field. */
  reportWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `repostInteractionDecayRate` field. */
  repostInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `repostWeight` field. */
  repostWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `showLessInteractionDecayRate` field. */
  showLessInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `showLessWeight` field. */
  showLessWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tipInteractionDecayRate` field. */
  tipInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tipWeight` field. */
  tipWeight?: InputMaybe<Scalars['Float']['input']>;
};

/** A filter to be used against `FeedSetting` object types. All fields are combined with a logical ‘and.’ */
export type FeedSettingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedSettingFilter>>;
  /** Filter by the object’s `blockInteractionDecayRate` field. */
  blockInteractionDecayRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `blockWeight` field. */
  blockWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `finalPostScoreDecayRate` field. */
  finalPostScoreDecayRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `followCountWeight` field. */
  followCountWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `followInteractionDecayRate` field. */
  followInteractionDecayRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `negativeReactionInteractionDecayRate` field. */
  negativeReactionInteractionDecayRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `negativeReactionWeight` field. */
  negativeReactionWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `neutralReactionInteractionDecayRate` field. */
  neutralReactionInteractionDecayRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `neutralReactionWeight` field. */
  neutralReactionWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `newPostDecayRate` field. */
  newPostDecayRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `newPostWeight` field. */
  newPostWeight?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedSettingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedSettingFilter>>;
  /** Filter by the object’s `positiveReactionInteractionDecayRate` field. */
  positiveReactionInteractionDecayRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `positiveReactionWeight` field. */
  positiveReactionWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `postMaxWealthContributionUsdCents` field. */
  postMaxWealthContributionUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `postScoreDecayRate` field. */
  postScoreDecayRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `postScoreSigmoidScaling` field. */
  postScoreSigmoidScaling?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `quoteInteractionDecayRate` field. */
  quoteInteractionDecayRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `quoteWeight` field. */
  quoteWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `replyInteractionDecayRate` field. */
  replyInteractionDecayRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `replyWeight` field. */
  replyWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `reportInteractionDecayRate` field. */
  reportInteractionDecayRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `reportWeight` field. */
  reportWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `repostInteractionDecayRate` field. */
  repostInteractionDecayRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `repostWeight` field. */
  repostWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `showLessInteractionDecayRate` field. */
  showLessInteractionDecayRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `showLessWeight` field. */
  showLessWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tipInteractionDecayRate` field. */
  tipInteractionDecayRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tipWeight` field. */
  tipWeight?: InputMaybe<FloatFilter>;
};

/** An input for mutations affecting `FeedSetting` */
export type FeedSettingInput = {
  blockInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  blockWeight?: InputMaybe<Scalars['Float']['input']>;
  finalPostScoreDecayRate?: InputMaybe<Scalars['Float']['input']>;
  followCountWeight?: InputMaybe<Scalars['Float']['input']>;
  followInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  negativeReactionInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  negativeReactionWeight?: InputMaybe<Scalars['Float']['input']>;
  neutralReactionInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  neutralReactionWeight?: InputMaybe<Scalars['Float']['input']>;
  newPostDecayRate?: InputMaybe<Scalars['Float']['input']>;
  newPostWeight?: InputMaybe<Scalars['Float']['input']>;
  positiveReactionInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  positiveReactionWeight?: InputMaybe<Scalars['Float']['input']>;
  postMaxWealthContributionUsdCents?: InputMaybe<Scalars['Float']['input']>;
  postScoreDecayRate?: InputMaybe<Scalars['Float']['input']>;
  postScoreSigmoidScaling?: InputMaybe<Scalars['Float']['input']>;
  quoteInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  quoteWeight?: InputMaybe<Scalars['Float']['input']>;
  replyInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  replyWeight?: InputMaybe<Scalars['Float']['input']>;
  reportInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  reportWeight?: InputMaybe<Scalars['Float']['input']>;
  repostInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  repostWeight?: InputMaybe<Scalars['Float']['input']>;
  rowId: Scalars['Int']['input'];
  showLessInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  showLessWeight?: InputMaybe<Scalars['Float']['input']>;
  tipInteractionDecayRate?: InputMaybe<Scalars['Float']['input']>;
  tipWeight?: InputMaybe<Scalars['Float']['input']>;
};

/** A connection to a list of `FeedSetting` values. */
export type FeedSettingsConnection = {
  __typename?: 'FeedSettingsConnection';
  /** A list of edges which contains the `FeedSetting` and cursor to aid in pagination. */
  edges: Array<FeedSettingsEdge>;
  /** A list of `FeedSetting` objects. */
  nodes: Array<Maybe<FeedSetting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedSetting` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedSetting` edge in the connection. */
export type FeedSettingsEdge = {
  __typename?: 'FeedSettingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedSetting` at the end of the edge. */
  node?: Maybe<FeedSetting>;
};

/** Methods to use when ordering `FeedSetting`. */
export enum FeedSettingsOrderBy {
  BlockInteractionDecayRateAsc = 'BLOCK_INTERACTION_DECAY_RATE_ASC',
  BlockInteractionDecayRateDesc = 'BLOCK_INTERACTION_DECAY_RATE_DESC',
  BlockWeightAsc = 'BLOCK_WEIGHT_ASC',
  BlockWeightDesc = 'BLOCK_WEIGHT_DESC',
  FinalPostScoreDecayRateAsc = 'FINAL_POST_SCORE_DECAY_RATE_ASC',
  FinalPostScoreDecayRateDesc = 'FINAL_POST_SCORE_DECAY_RATE_DESC',
  FollowCountWeightAsc = 'FOLLOW_COUNT_WEIGHT_ASC',
  FollowCountWeightDesc = 'FOLLOW_COUNT_WEIGHT_DESC',
  FollowInteractionDecayRateAsc = 'FOLLOW_INTERACTION_DECAY_RATE_ASC',
  FollowInteractionDecayRateDesc = 'FOLLOW_INTERACTION_DECAY_RATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  NegativeReactionInteractionDecayRateAsc = 'NEGATIVE_REACTION_INTERACTION_DECAY_RATE_ASC',
  NegativeReactionInteractionDecayRateDesc = 'NEGATIVE_REACTION_INTERACTION_DECAY_RATE_DESC',
  NegativeReactionWeightAsc = 'NEGATIVE_REACTION_WEIGHT_ASC',
  NegativeReactionWeightDesc = 'NEGATIVE_REACTION_WEIGHT_DESC',
  NeutralReactionInteractionDecayRateAsc = 'NEUTRAL_REACTION_INTERACTION_DECAY_RATE_ASC',
  NeutralReactionInteractionDecayRateDesc = 'NEUTRAL_REACTION_INTERACTION_DECAY_RATE_DESC',
  NeutralReactionWeightAsc = 'NEUTRAL_REACTION_WEIGHT_ASC',
  NeutralReactionWeightDesc = 'NEUTRAL_REACTION_WEIGHT_DESC',
  NewPostDecayRateAsc = 'NEW_POST_DECAY_RATE_ASC',
  NewPostDecayRateDesc = 'NEW_POST_DECAY_RATE_DESC',
  NewPostWeightAsc = 'NEW_POST_WEIGHT_ASC',
  NewPostWeightDesc = 'NEW_POST_WEIGHT_DESC',
  PositiveReactionInteractionDecayRateAsc = 'POSITIVE_REACTION_INTERACTION_DECAY_RATE_ASC',
  PositiveReactionInteractionDecayRateDesc = 'POSITIVE_REACTION_INTERACTION_DECAY_RATE_DESC',
  PositiveReactionWeightAsc = 'POSITIVE_REACTION_WEIGHT_ASC',
  PositiveReactionWeightDesc = 'POSITIVE_REACTION_WEIGHT_DESC',
  PostMaxWealthContributionUsdCentsAsc = 'POST_MAX_WEALTH_CONTRIBUTION_USD_CENTS_ASC',
  PostMaxWealthContributionUsdCentsDesc = 'POST_MAX_WEALTH_CONTRIBUTION_USD_CENTS_DESC',
  PostScoreDecayRateAsc = 'POST_SCORE_DECAY_RATE_ASC',
  PostScoreDecayRateDesc = 'POST_SCORE_DECAY_RATE_DESC',
  PostScoreSigmoidScalingAsc = 'POST_SCORE_SIGMOID_SCALING_ASC',
  PostScoreSigmoidScalingDesc = 'POST_SCORE_SIGMOID_SCALING_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  QuoteInteractionDecayRateAsc = 'QUOTE_INTERACTION_DECAY_RATE_ASC',
  QuoteInteractionDecayRateDesc = 'QUOTE_INTERACTION_DECAY_RATE_DESC',
  QuoteWeightAsc = 'QUOTE_WEIGHT_ASC',
  QuoteWeightDesc = 'QUOTE_WEIGHT_DESC',
  ReplyInteractionDecayRateAsc = 'REPLY_INTERACTION_DECAY_RATE_ASC',
  ReplyInteractionDecayRateDesc = 'REPLY_INTERACTION_DECAY_RATE_DESC',
  ReplyWeightAsc = 'REPLY_WEIGHT_ASC',
  ReplyWeightDesc = 'REPLY_WEIGHT_DESC',
  ReportInteractionDecayRateAsc = 'REPORT_INTERACTION_DECAY_RATE_ASC',
  ReportInteractionDecayRateDesc = 'REPORT_INTERACTION_DECAY_RATE_DESC',
  ReportWeightAsc = 'REPORT_WEIGHT_ASC',
  ReportWeightDesc = 'REPORT_WEIGHT_DESC',
  RepostInteractionDecayRateAsc = 'REPOST_INTERACTION_DECAY_RATE_ASC',
  RepostInteractionDecayRateDesc = 'REPOST_INTERACTION_DECAY_RATE_DESC',
  RepostWeightAsc = 'REPOST_WEIGHT_ASC',
  RepostWeightDesc = 'REPOST_WEIGHT_DESC',
  ShowLessInteractionDecayRateAsc = 'SHOW_LESS_INTERACTION_DECAY_RATE_ASC',
  ShowLessInteractionDecayRateDesc = 'SHOW_LESS_INTERACTION_DECAY_RATE_DESC',
  ShowLessWeightAsc = 'SHOW_LESS_WEIGHT_ASC',
  ShowLessWeightDesc = 'SHOW_LESS_WEIGHT_DESC',
  TipInteractionDecayRateAsc = 'TIP_INTERACTION_DECAY_RATE_ASC',
  TipInteractionDecayRateDesc = 'TIP_INTERACTION_DECAY_RATE_DESC',
  TipWeightAsc = 'TIP_WEIGHT_ASC',
  TipWeightDesc = 'TIP_WEIGHT_DESC',
}

/** All input for the `feedUpdatePostScoresForUsers` mutation. */
export type FeedUpdatePostScoresForUsersInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `feedUpdatePostScoresForUsers` mutation. */
export type FeedUpdatePostScoresForUsersPayload = {
  __typename?: 'FeedUpdatePostScoresForUsersPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `feedUpdatePostScores` mutation. */
export type FeedUpdatePostScoresInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `feedUpdatePostScores` mutation. */
export type FeedUpdatePostScoresPayload = {
  __typename?: 'FeedUpdatePostScoresPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `feedUpdateUserRelationshipScores1D` mutation. */
export type FeedUpdateUserRelationshipScores1DInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `feedUpdateUserRelationshipScores1D` mutation. */
export type FeedUpdateUserRelationshipScores1DPayload = {
  __typename?: 'FeedUpdateUserRelationshipScores1DPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `feedUpdateUserRelationshipScores2D` mutation. */
export type FeedUpdateUserRelationshipScores2DInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `feedUpdateUserRelationshipScores2D` mutation. */
export type FeedUpdateUserRelationshipScores2DPayload = {
  __typename?: 'FeedUpdateUserRelationshipScores2DPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `feedUpdateUserRelationshipScores` mutation. */
export type FeedUpdateUserRelationshipScoresInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `feedUpdateUserRelationshipScores` mutation. */
export type FeedUpdateUserRelationshipScoresPayload = {
  __typename?: 'FeedUpdateUserRelationshipScoresPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type FeedUser = {
  __typename?: 'FeedUser';
  pkid?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type FeedUserAssociation = {
  __typename?: 'FeedUserAssociation';
  /** Reads a single `AccessGroup` that is related to this `FeedUserAssociation`. */
  accessGroup?: Maybe<AccessGroup>;
  accessGroupKeyName?: Maybe<Scalars['String']['output']>;
  accessGroupOwnerPublicKey?: Maybe<Scalars['String']['output']>;
  activeFeedForeignKey?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `UserAssociation`. */
  activeFeedSubscriptions: UserAssociationsConnection;
  appPkid?: Maybe<Scalars['String']['output']>;
  associationId?: Maybe<Scalars['String']['output']>;
  associationType?: Maybe<Scalars['String']['output']>;
  associationValue?: Maybe<Scalars['String']['output']>;
  badgerKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Block` that is related to this `FeedUserAssociation`. */
  block?: Maybe<Block>;
  blockHeight?: Maybe<Scalars['Int']['output']>;
  extraData?: Maybe<Scalars['JSON']['output']>;
  feedForeignKey?: Maybe<Scalars['String']['output']>;
  feedId?: Maybe<Scalars['String']['output']>;
  feedPublicKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `UserAssociation` that is related to this `FeedUserAssociation`. */
  feedSettings?: Maybe<UserAssociation>;
  feedSettingsForeignKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `UserAssociation` that is related to this `FeedUserAssociation`. */
  feedSubscriptionTier?: Maybe<UserAssociation>;
  paymentCadenceDays?: Maybe<Scalars['Int']['output']>;
  paymentRecipientPublicKey?: Maybe<Scalars['String']['output']>;
  paymentSenderPublicKey?: Maybe<Scalars['String']['output']>;
  paymentUuid?: Maybe<Scalars['String']['output']>;
  subscriberPublicKey?: Maybe<Scalars['String']['output']>;
  subscriptionPaymentTimestamp?: Maybe<Scalars['Datetime']['output']>;
  subscriptionStartTimestamp?: Maybe<Scalars['Datetime']['output']>;
  subscriptionTierForeignKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Account` that is related to this `FeedUserAssociation`. */
  target?: Maybe<Account>;
  targetFocusTokenBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  targetTotalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  targetUsdBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  targetUserPkid?: Maybe<Scalars['String']['output']>;
  transactorFocusTokenBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  transactorPkid?: Maybe<Scalars['String']['output']>;
  transactorTotalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  transactorUsdBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  userInstallCount?: Maybe<Scalars['BigInt']['output']>;
};

export type FeedUserAssociationActiveFeedSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

/**
 * A condition to be used against `FeedUserAssociation` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FeedUserAssociationCondition = {
  /** Checks for equality with the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `activeFeedForeignKey` field. */
  activeFeedForeignKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `appPkid` field. */
  appPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationId` field. */
  associationId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationType` field. */
  associationType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationValue` field. */
  associationValue?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `feedForeignKey` field. */
  feedForeignKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `feedPublicKey` field. */
  feedPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `feedSettingsForeignKey` field. */
  feedSettingsForeignKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `paymentCadenceDays` field. */
  paymentCadenceDays?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `paymentRecipientPublicKey` field. */
  paymentRecipientPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `paymentSenderPublicKey` field. */
  paymentSenderPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `paymentUuid` field. */
  paymentUuid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `subscriberPublicKey` field. */
  subscriberPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `subscriptionPaymentTimestamp` field. */
  subscriptionPaymentTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `subscriptionStartTimestamp` field. */
  subscriptionStartTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `subscriptionTierForeignKey` field. */
  subscriptionTierForeignKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `targetFocusTokenBalanceUsdCents` field. */
  targetFocusTokenBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `targetTotalBalanceUsdCents` field. */
  targetTotalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `targetUsdBalanceUsdCents` field. */
  targetUsdBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `targetUserPkid` field. */
  targetUserPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transactorFocusTokenBalanceUsdCents` field. */
  transactorFocusTokenBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transactorTotalBalanceUsdCents` field. */
  transactorTotalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `transactorUsdBalanceUsdCents` field. */
  transactorUsdBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `userInstallCount` field. */
  userInstallCount?: InputMaybe<Scalars['BigInt']['input']>;
};

/** A filter to be used against `FeedUserAssociation` object types. All fields are combined with a logical ‘and.’ */
export type FeedUserAssociationFilter = {
  /** Filter by the object’s `accessGroup` relation. */
  accessGroup?: InputMaybe<AccessGroupFilter>;
  /** A related `accessGroup` exists. */
  accessGroupExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `activeFeedForeignKey` field. */
  activeFeedForeignKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `activeFeedSubscriptions` relation. */
  activeFeedSubscriptions?: InputMaybe<FeedUserAssociationToManyUserAssociationFilter>;
  /** Some related `activeFeedSubscriptions` exist. */
  activeFeedSubscriptionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedUserAssociationFilter>>;
  /** Filter by the object’s `appPkid` field. */
  appPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationId` field. */
  associationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationType` field. */
  associationType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationValue` field. */
  associationValue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `block` relation. */
  block?: InputMaybe<BlockFilter>;
  /** A related `block` exists. */
  blockExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<IntFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `feedForeignKey` field. */
  feedForeignKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedPublicKey` field. */
  feedPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedSettings` relation. */
  feedSettings?: InputMaybe<UserAssociationFilter>;
  /** A related `feedSettings` exists. */
  feedSettingsExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feedSettingsForeignKey` field. */
  feedSettingsForeignKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedSubscriptionTier` relation. */
  feedSubscriptionTier?: InputMaybe<UserAssociationFilter>;
  /** A related `feedSubscriptionTier` exists. */
  feedSubscriptionTierExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not?: InputMaybe<FeedUserAssociationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedUserAssociationFilter>>;
  /** Filter by the object’s `paymentCadenceDays` field. */
  paymentCadenceDays?: InputMaybe<IntFilter>;
  /** Filter by the object’s `paymentRecipientPublicKey` field. */
  paymentRecipientPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `paymentSenderPublicKey` field. */
  paymentSenderPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `paymentUuid` field. */
  paymentUuid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subscriberPublicKey` field. */
  subscriberPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subscriptionPaymentTimestamp` field. */
  subscriptionPaymentTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `subscriptionStartTimestamp` field. */
  subscriptionStartTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `subscriptionTierForeignKey` field. */
  subscriptionTierForeignKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `target` relation. */
  target?: InputMaybe<AccountFilter>;
  /** A related `target` exists. */
  targetExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `targetFocusTokenBalanceUsdCents` field. */
  targetFocusTokenBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `targetTotalBalanceUsdCents` field. */
  targetTotalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `targetUsdBalanceUsdCents` field. */
  targetUsdBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `targetUserPkid` field. */
  targetUserPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactorFocusTokenBalanceUsdCents` field. */
  transactorFocusTokenBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactorTotalBalanceUsdCents` field. */
  transactorTotalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `transactorUsdBalanceUsdCents` field. */
  transactorUsdBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `userInstallCount` field. */
  userInstallCount?: InputMaybe<BigIntFilter>;
};

/** A filter to be used against many `UserAssociation` object types. All fields are combined with a logical ‘and.’ */
export type FeedUserAssociationToManyUserAssociationFilter = {
  /** Every related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserAssociationFilter>;
  /** No related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserAssociationFilter>;
  /** Some related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserAssociationFilter>;
};

/** A connection to a list of `FeedUserAssociation` values. */
export type FeedUserAssociationsConnection = {
  __typename?: 'FeedUserAssociationsConnection';
  /** A list of edges which contains the `FeedUserAssociation` and cursor to aid in pagination. */
  edges: Array<FeedUserAssociationsEdge>;
  /** A list of `FeedUserAssociation` objects. */
  nodes: Array<Maybe<FeedUserAssociation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedUserAssociation` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedUserAssociation` edge in the connection. */
export type FeedUserAssociationsEdge = {
  __typename?: 'FeedUserAssociationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedUserAssociation` at the end of the edge. */
  node?: Maybe<FeedUserAssociation>;
};

/** Methods to use when ordering `FeedUserAssociation`. */
export enum FeedUserAssociationsOrderBy {
  AccessGroupKeyNameAsc = 'ACCESS_GROUP_KEY_NAME_ASC',
  AccessGroupKeyNameDesc = 'ACCESS_GROUP_KEY_NAME_DESC',
  AccessGroupOwnerPublicKeyAsc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC',
  AccessGroupOwnerPublicKeyDesc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC',
  ActiveFeedForeignKeyAsc = 'ACTIVE_FEED_FOREIGN_KEY_ASC',
  ActiveFeedForeignKeyDesc = 'ACTIVE_FEED_FOREIGN_KEY_DESC',
  AppPkidAsc = 'APP_PKID_ASC',
  AppPkidDesc = 'APP_PKID_DESC',
  AssociationIdAsc = 'ASSOCIATION_ID_ASC',
  AssociationIdDesc = 'ASSOCIATION_ID_DESC',
  AssociationTypeAsc = 'ASSOCIATION_TYPE_ASC',
  AssociationTypeDesc = 'ASSOCIATION_TYPE_DESC',
  AssociationValueAsc = 'ASSOCIATION_VALUE_ASC',
  AssociationValueDesc = 'ASSOCIATION_VALUE_DESC',
  BadgerKeyAsc = 'BADGER_KEY_ASC',
  BadgerKeyDesc = 'BADGER_KEY_DESC',
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  FeedForeignKeyAsc = 'FEED_FOREIGN_KEY_ASC',
  FeedForeignKeyDesc = 'FEED_FOREIGN_KEY_DESC',
  FeedIdAsc = 'FEED_ID_ASC',
  FeedIdDesc = 'FEED_ID_DESC',
  FeedPublicKeyAsc = 'FEED_PUBLIC_KEY_ASC',
  FeedPublicKeyDesc = 'FEED_PUBLIC_KEY_DESC',
  FeedSettingsForeignKeyAsc = 'FEED_SETTINGS_FOREIGN_KEY_ASC',
  FeedSettingsForeignKeyDesc = 'FEED_SETTINGS_FOREIGN_KEY_DESC',
  Natural = 'NATURAL',
  PaymentCadenceDaysAsc = 'PAYMENT_CADENCE_DAYS_ASC',
  PaymentCadenceDaysDesc = 'PAYMENT_CADENCE_DAYS_DESC',
  PaymentRecipientPublicKeyAsc = 'PAYMENT_RECIPIENT_PUBLIC_KEY_ASC',
  PaymentRecipientPublicKeyDesc = 'PAYMENT_RECIPIENT_PUBLIC_KEY_DESC',
  PaymentSenderPublicKeyAsc = 'PAYMENT_SENDER_PUBLIC_KEY_ASC',
  PaymentSenderPublicKeyDesc = 'PAYMENT_SENDER_PUBLIC_KEY_DESC',
  PaymentUuidAsc = 'PAYMENT_UUID_ASC',
  PaymentUuidDesc = 'PAYMENT_UUID_DESC',
  SubscriberPublicKeyAsc = 'SUBSCRIBER_PUBLIC_KEY_ASC',
  SubscriberPublicKeyDesc = 'SUBSCRIBER_PUBLIC_KEY_DESC',
  SubscriptionPaymentTimestampAsc = 'SUBSCRIPTION_PAYMENT_TIMESTAMP_ASC',
  SubscriptionPaymentTimestampDesc = 'SUBSCRIPTION_PAYMENT_TIMESTAMP_DESC',
  SubscriptionStartTimestampAsc = 'SUBSCRIPTION_START_TIMESTAMP_ASC',
  SubscriptionStartTimestampDesc = 'SUBSCRIPTION_START_TIMESTAMP_DESC',
  SubscriptionTierForeignKeyAsc = 'SUBSCRIPTION_TIER_FOREIGN_KEY_ASC',
  SubscriptionTierForeignKeyDesc = 'SUBSCRIPTION_TIER_FOREIGN_KEY_DESC',
  TargetFocusTokenBalanceUsdCentsAsc = 'TARGET_FOCUS_TOKEN_BALANCE_USD_CENTS_ASC',
  TargetFocusTokenBalanceUsdCentsDesc = 'TARGET_FOCUS_TOKEN_BALANCE_USD_CENTS_DESC',
  TargetTotalBalanceUsdCentsAsc = 'TARGET_TOTAL_BALANCE_USD_CENTS_ASC',
  TargetTotalBalanceUsdCentsDesc = 'TARGET_TOTAL_BALANCE_USD_CENTS_DESC',
  TargetUsdBalanceUsdCentsAsc = 'TARGET_USD_BALANCE_USD_CENTS_ASC',
  TargetUsdBalanceUsdCentsDesc = 'TARGET_USD_BALANCE_USD_CENTS_DESC',
  TargetUserPkidAsc = 'TARGET_USER_PKID_ASC',
  TargetUserPkidDesc = 'TARGET_USER_PKID_DESC',
  TransactorFocusTokenBalanceUsdCentsAsc = 'TRANSACTOR_FOCUS_TOKEN_BALANCE_USD_CENTS_ASC',
  TransactorFocusTokenBalanceUsdCentsDesc = 'TRANSACTOR_FOCUS_TOKEN_BALANCE_USD_CENTS_DESC',
  TransactorPkidAsc = 'TRANSACTOR_PKID_ASC',
  TransactorPkidDesc = 'TRANSACTOR_PKID_DESC',
  TransactorTotalBalanceUsdCentsAsc = 'TRANSACTOR_TOTAL_BALANCE_USD_CENTS_ASC',
  TransactorTotalBalanceUsdCentsDesc = 'TRANSACTOR_TOTAL_BALANCE_USD_CENTS_DESC',
  TransactorUsdBalanceUsdCentsAsc = 'TRANSACTOR_USD_BALANCE_USD_CENTS_ASC',
  TransactorUsdBalanceUsdCentsDesc = 'TRANSACTOR_USD_BALANCE_USD_CENTS_DESC',
  UserInstallCountAsc = 'USER_INSTALL_COUNT_ASC',
  UserInstallCountDesc = 'USER_INSTALL_COUNT_DESC',
}

/**
 * A condition to be used against `FeedUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FeedUserCondition = {
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedUser` object types. All fields are combined with a logical ‘and.’ */
export type FeedUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedUserFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<FeedUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedUserFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

export type FeedUserPostAndRelationshipScore = {
  __typename?: 'FeedUserPostAndRelationshipScore';
  postHash?: Maybe<Scalars['String']['output']>;
  postScore?: Maybe<Scalars['BigFloat']['output']>;
  postTimestamp?: Maybe<Scalars['Datetime']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relationshipScore?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `FeedUserPostAndRelationshipScore` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedUserPostAndRelationshipScoreCondition = {
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postScore` field. */
  postScore?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relationshipScore` field. */
  relationshipScore?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FeedUserPostAndRelationshipScore` object types. All fields are combined with a logical ‘and.’ */
export type FeedUserPostAndRelationshipScoreFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedUserPostAndRelationshipScoreFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<FeedUserPostAndRelationshipScoreFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedUserPostAndRelationshipScoreFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postScore` field. */
  postScore?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `postTimestamp` field. */
  postTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relationshipScore` field. */
  relationshipScore?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `FeedUserPostAndRelationshipScore` values. */
export type FeedUserPostAndRelationshipScoresConnection = {
  __typename?: 'FeedUserPostAndRelationshipScoresConnection';
  /** A list of edges which contains the `FeedUserPostAndRelationshipScore` and cursor to aid in pagination. */
  edges: Array<FeedUserPostAndRelationshipScoresEdge>;
  /** A list of `FeedUserPostAndRelationshipScore` objects. */
  nodes: Array<Maybe<FeedUserPostAndRelationshipScore>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedUserPostAndRelationshipScore` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedUserPostAndRelationshipScore` edge in the connection. */
export type FeedUserPostAndRelationshipScoresEdge = {
  __typename?: 'FeedUserPostAndRelationshipScoresEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedUserPostAndRelationshipScore` at the end of the edge. */
  node?: Maybe<FeedUserPostAndRelationshipScore>;
};

/** Methods to use when ordering `FeedUserPostAndRelationshipScore`. */
export enum FeedUserPostAndRelationshipScoresOrderBy {
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PostScoreAsc = 'POST_SCORE_ASC',
  PostScoreDesc = 'POST_SCORE_DESC',
  PostTimestampAsc = 'POST_TIMESTAMP_ASC',
  PostTimestampDesc = 'POST_TIMESTAMP_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelationshipScoreAsc = 'RELATIONSHIP_SCORE_ASC',
  RelationshipScoreDesc = 'RELATIONSHIP_SCORE_DESC',
}

export type FeedUserRelationshipScore = {
  __typename?: 'FeedUserRelationshipScore';
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedPublicKey?: Maybe<Scalars['String']['output']>;
  score1D?: Maybe<Scalars['BigFloat']['output']>;
  score2D?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `FeedUserRelationshipScore` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedUserRelationshipScoreCondition = {
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedPublicKey` field. */
  relatedPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `score1D` field. */
  score1D?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `score2D` field. */
  score2D?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FeedUserRelationshipScore` object types. All fields are combined with a logical ‘and.’ */
export type FeedUserRelationshipScoreFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedUserRelationshipScoreFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<FeedUserRelationshipScoreFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedUserRelationshipScoreFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedPublicKey` field. */
  relatedPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `score1D` field. */
  score1D?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `score2D` field. */
  score2D?: InputMaybe<BigFloatFilter>;
};

/** An input for mutations affecting `FeedUserRelationshipScore` */
export type FeedUserRelationshipScoreInput = {
  publicKey?: InputMaybe<Scalars['String']['input']>;
  relatedPublicKey?: InputMaybe<Scalars['String']['input']>;
  score1D?: InputMaybe<Scalars['BigFloat']['input']>;
  score2D?: InputMaybe<Scalars['BigFloat']['input']>;
};

export type FeedUserRelationshipScores1D = {
  __typename?: 'FeedUserRelationshipScores1D';
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedPublicKey?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `FeedUserRelationshipScores1D` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedUserRelationshipScores1DCondition = {
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedPublicKey` field. */
  relatedPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `score` field. */
  score?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FeedUserRelationshipScores1D` object types. All fields are combined with a logical ‘and.’ */
export type FeedUserRelationshipScores1DFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedUserRelationshipScores1DFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<FeedUserRelationshipScores1DFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedUserRelationshipScores1DFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedPublicKey` field. */
  relatedPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `score` field. */
  score?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `FeedUserRelationshipScores1D` values. */
export type FeedUserRelationshipScores1DsConnection = {
  __typename?: 'FeedUserRelationshipScores1DsConnection';
  /** A list of edges which contains the `FeedUserRelationshipScores1D` and cursor to aid in pagination. */
  edges: Array<FeedUserRelationshipScores1DsEdge>;
  /** A list of `FeedUserRelationshipScores1D` objects. */
  nodes: Array<Maybe<FeedUserRelationshipScores1D>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedUserRelationshipScores1D` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedUserRelationshipScores1D` edge in the connection. */
export type FeedUserRelationshipScores1DsEdge = {
  __typename?: 'FeedUserRelationshipScores1DsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedUserRelationshipScores1D` at the end of the edge. */
  node?: Maybe<FeedUserRelationshipScores1D>;
};

/** Methods to use when ordering `FeedUserRelationshipScores1D`. */
export enum FeedUserRelationshipScores1DsOrderBy {
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedPublicKeyAsc = 'RELATED_PUBLIC_KEY_ASC',
  RelatedPublicKeyDesc = 'RELATED_PUBLIC_KEY_DESC',
  ScoreAsc = 'SCORE_ASC',
  ScoreDesc = 'SCORE_DESC',
}

export type FeedUserRelationshipScores2D = {
  __typename?: 'FeedUserRelationshipScores2D';
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedPublicKey?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `FeedUserRelationshipScores2D` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedUserRelationshipScores2DCondition = {
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedPublicKey` field. */
  relatedPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `score` field. */
  score?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FeedUserRelationshipScores2D` object types. All fields are combined with a logical ‘and.’ */
export type FeedUserRelationshipScores2DFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedUserRelationshipScores2DFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<FeedUserRelationshipScores2DFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedUserRelationshipScores2DFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedPublicKey` field. */
  relatedPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `score` field. */
  score?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `FeedUserRelationshipScores2D` values. */
export type FeedUserRelationshipScores2DsConnection = {
  __typename?: 'FeedUserRelationshipScores2DsConnection';
  /** A list of edges which contains the `FeedUserRelationshipScores2D` and cursor to aid in pagination. */
  edges: Array<FeedUserRelationshipScores2DsEdge>;
  /** A list of `FeedUserRelationshipScores2D` objects. */
  nodes: Array<Maybe<FeedUserRelationshipScores2D>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedUserRelationshipScores2D` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedUserRelationshipScores2D` edge in the connection. */
export type FeedUserRelationshipScores2DsEdge = {
  __typename?: 'FeedUserRelationshipScores2DsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedUserRelationshipScores2D` at the end of the edge. */
  node?: Maybe<FeedUserRelationshipScores2D>;
};

/** Methods to use when ordering `FeedUserRelationshipScores2D`. */
export enum FeedUserRelationshipScores2DsOrderBy {
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedPublicKeyAsc = 'RELATED_PUBLIC_KEY_ASC',
  RelatedPublicKeyDesc = 'RELATED_PUBLIC_KEY_DESC',
  ScoreAsc = 'SCORE_ASC',
  ScoreDesc = 'SCORE_DESC',
}

/** A connection to a list of `FeedUserRelationshipScore` values. */
export type FeedUserRelationshipScoresConnection = {
  __typename?: 'FeedUserRelationshipScoresConnection';
  /** A list of edges which contains the `FeedUserRelationshipScore` and cursor to aid in pagination. */
  edges: Array<FeedUserRelationshipScoresEdge>;
  /** A list of `FeedUserRelationshipScore` objects. */
  nodes: Array<Maybe<FeedUserRelationshipScore>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedUserRelationshipScore` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedUserRelationshipScore` edge in the connection. */
export type FeedUserRelationshipScoresEdge = {
  __typename?: 'FeedUserRelationshipScoresEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedUserRelationshipScore` at the end of the edge. */
  node?: Maybe<FeedUserRelationshipScore>;
};

/** Methods to use when ordering `FeedUserRelationshipScore`. */
export enum FeedUserRelationshipScoresOrderBy {
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedPublicKeyAsc = 'RELATED_PUBLIC_KEY_ASC',
  RelatedPublicKeyDesc = 'RELATED_PUBLIC_KEY_DESC',
  Score_1DAsc = 'SCORE_1D_ASC',
  Score_1DDesc = 'SCORE_1D_DESC',
  Score_2DAsc = 'SCORE_2D_ASC',
  Score_2DDesc = 'SCORE_2D_DESC',
}

export type FeedUserScoredPost = {
  __typename?: 'FeedUserScoredPost';
  postHash?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `FeedUserScoredPost` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FeedUserScoredPostCondition = {
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `score` field. */
  score?: InputMaybe<Scalars['Float']['input']>;
};

/** A filter to be used against `FeedUserScoredPost` object types. All fields are combined with a logical ‘and.’ */
export type FeedUserScoredPostFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedUserScoredPostFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<FeedUserScoredPostFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedUserScoredPostFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `score` field. */
  score?: InputMaybe<FloatFilter>;
};

/** A connection to a list of `FeedUserScoredPost` values. */
export type FeedUserScoredPostsConnection = {
  __typename?: 'FeedUserScoredPostsConnection';
  /** A list of edges which contains the `FeedUserScoredPost` and cursor to aid in pagination. */
  edges: Array<FeedUserScoredPostsEdge>;
  /** A list of `FeedUserScoredPost` objects. */
  nodes: Array<Maybe<FeedUserScoredPost>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedUserScoredPost` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedUserScoredPost` edge in the connection. */
export type FeedUserScoredPostsEdge = {
  __typename?: 'FeedUserScoredPostsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedUserScoredPost` at the end of the edge. */
  node?: Maybe<FeedUserScoredPost>;
};

/** Methods to use when ordering `FeedUserScoredPost`. */
export enum FeedUserScoredPostsOrderBy {
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  ScoreAsc = 'SCORE_ASC',
  ScoreDesc = 'SCORE_DESC',
}

export type FeedUserSetting = {
  __typename?: 'FeedUserSetting';
  blockCountRelWeight?: Maybe<Scalars['BigFloat']['output']>;
  focusFollowCountRelWeight?: Maybe<Scalars['BigFloat']['output']>;
  followCountRelWeight?: Maybe<Scalars['BigFloat']['output']>;
  negativeReactionCountPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  negativeReactionCountRelWeight?: Maybe<Scalars['BigFloat']['output']>;
  negativeReactionNanosPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  neutralReactionCountPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  neutralReactionCountRelWeight?: Maybe<Scalars['BigFloat']['output']>;
  neutralReactionNanosPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  positiveReactionCountPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  positiveReactionCountRelWeight?: Maybe<Scalars['BigFloat']['output']>;
  positiveReactionNanosPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  quoteCountPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  quoteCountRelWeight?: Maybe<Scalars['BigFloat']['output']>;
  quoteNanosPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  replyCountPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  replyCountRelWeight?: Maybe<Scalars['BigFloat']['output']>;
  replyNanosPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  reportCountPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  reportCountRelWeight?: Maybe<Scalars['BigFloat']['output']>;
  reportNanosPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  repostCountPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  repostCountRelWeight?: Maybe<Scalars['BigFloat']['output']>;
  repostNanosPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  showLessCountPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  showLessCountRelWeight?: Maybe<Scalars['BigFloat']['output']>;
  showLessNanosPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  timeDecay?: Maybe<Scalars['BigFloat']['output']>;
  tipCountNanosPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  tipCountPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  tipCountRelWeight?: Maybe<Scalars['BigFloat']['output']>;
  tipDesoNanosRelWeight?: Maybe<Scalars['BigFloat']['output']>;
  tipDiamondCountPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  tipDiamondCountRelWeight?: Maybe<Scalars['BigFloat']['output']>;
  tipNanosGivenPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  viewCountPostWeight?: Maybe<Scalars['BigFloat']['output']>;
  viewNanosPostWeight?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `FeedUserSetting` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FeedUserSettingCondition = {
  /** Checks for equality with the object’s `blockCountRelWeight` field. */
  blockCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `focusFollowCountRelWeight` field. */
  focusFollowCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `followCountRelWeight` field. */
  followCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `negativeReactionCountPostWeight` field. */
  negativeReactionCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `negativeReactionCountRelWeight` field. */
  negativeReactionCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `negativeReactionNanosPostWeight` field. */
  negativeReactionNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `neutralReactionCountPostWeight` field. */
  neutralReactionCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `neutralReactionCountRelWeight` field. */
  neutralReactionCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `neutralReactionNanosPostWeight` field. */
  neutralReactionNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `positiveReactionCountPostWeight` field. */
  positiveReactionCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `positiveReactionCountRelWeight` field. */
  positiveReactionCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `positiveReactionNanosPostWeight` field. */
  positiveReactionNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `quoteCountPostWeight` field. */
  quoteCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `quoteCountRelWeight` field. */
  quoteCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `quoteNanosPostWeight` field. */
  quoteNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `replyCountPostWeight` field. */
  replyCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `replyCountRelWeight` field. */
  replyCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `replyNanosPostWeight` field. */
  replyNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `reportCountPostWeight` field. */
  reportCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `reportCountRelWeight` field. */
  reportCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `reportNanosPostWeight` field. */
  reportNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `repostCountPostWeight` field. */
  repostCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `repostCountRelWeight` field. */
  repostCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `repostNanosPostWeight` field. */
  repostNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `showLessCountPostWeight` field. */
  showLessCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `showLessCountRelWeight` field. */
  showLessCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `showLessNanosPostWeight` field. */
  showLessNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `timeDecay` field. */
  timeDecay?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `tipCountNanosPostWeight` field. */
  tipCountNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `tipCountPostWeight` field. */
  tipCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `tipCountRelWeight` field. */
  tipCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `tipDesoNanosRelWeight` field. */
  tipDesoNanosRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `tipDiamondCountPostWeight` field. */
  tipDiamondCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `tipDiamondCountRelWeight` field. */
  tipDiamondCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `tipNanosGivenPostWeight` field. */
  tipNanosGivenPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `viewCountPostWeight` field. */
  viewCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `viewNanosPostWeight` field. */
  viewNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FeedUserSetting` object types. All fields are combined with a logical ‘and.’ */
export type FeedUserSettingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedUserSettingFilter>>;
  /** Filter by the object’s `blockCountRelWeight` field. */
  blockCountRelWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `focusFollowCountRelWeight` field. */
  focusFollowCountRelWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `followCountRelWeight` field. */
  followCountRelWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `negativeReactionCountPostWeight` field. */
  negativeReactionCountPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `negativeReactionCountRelWeight` field. */
  negativeReactionCountRelWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `negativeReactionNanosPostWeight` field. */
  negativeReactionNanosPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `neutralReactionCountPostWeight` field. */
  neutralReactionCountPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `neutralReactionCountRelWeight` field. */
  neutralReactionCountRelWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `neutralReactionNanosPostWeight` field. */
  neutralReactionNanosPostWeight?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedUserSettingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedUserSettingFilter>>;
  /** Filter by the object’s `positiveReactionCountPostWeight` field. */
  positiveReactionCountPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `positiveReactionCountRelWeight` field. */
  positiveReactionCountRelWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `positiveReactionNanosPostWeight` field. */
  positiveReactionNanosPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `quoteCountPostWeight` field. */
  quoteCountPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `quoteCountRelWeight` field. */
  quoteCountRelWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `quoteNanosPostWeight` field. */
  quoteNanosPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `replyCountPostWeight` field. */
  replyCountPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `replyCountRelWeight` field. */
  replyCountRelWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `replyNanosPostWeight` field. */
  replyNanosPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `reportCountPostWeight` field. */
  reportCountPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `reportCountRelWeight` field. */
  reportCountRelWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `reportNanosPostWeight` field. */
  reportNanosPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `repostCountPostWeight` field. */
  repostCountPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `repostCountRelWeight` field. */
  repostCountRelWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `repostNanosPostWeight` field. */
  repostNanosPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `showLessCountPostWeight` field. */
  showLessCountPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `showLessCountRelWeight` field. */
  showLessCountRelWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `showLessNanosPostWeight` field. */
  showLessNanosPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `timeDecay` field. */
  timeDecay?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `tipCountNanosPostWeight` field. */
  tipCountNanosPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `tipCountPostWeight` field. */
  tipCountPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `tipCountRelWeight` field. */
  tipCountRelWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `tipDesoNanosRelWeight` field. */
  tipDesoNanosRelWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `tipDiamondCountPostWeight` field. */
  tipDiamondCountPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `tipDiamondCountRelWeight` field. */
  tipDiamondCountRelWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `tipNanosGivenPostWeight` field. */
  tipNanosGivenPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `viewCountPostWeight` field. */
  viewCountPostWeight?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `viewNanosPostWeight` field. */
  viewNanosPostWeight?: InputMaybe<BigFloatFilter>;
};

/** An input for mutations affecting `FeedUserSetting` */
export type FeedUserSettingInput = {
  blockCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  focusFollowCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  followCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  negativeReactionCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  negativeReactionCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  negativeReactionNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  neutralReactionCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  neutralReactionCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  neutralReactionNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  positiveReactionCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  positiveReactionCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  positiveReactionNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  quoteCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  quoteCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  quoteNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  replyCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  replyCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  replyNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  reportCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  reportCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  reportNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  repostCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  repostCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  repostNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  showLessCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  showLessCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  showLessNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  timeDecay?: InputMaybe<Scalars['BigFloat']['input']>;
  tipCountNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  tipCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  tipCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  tipDesoNanosRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  tipDiamondCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  tipDiamondCountRelWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  tipNanosGivenPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  viewCountPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
  viewNanosPostWeight?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A connection to a list of `FeedUserSetting` values. */
export type FeedUserSettingsConnection = {
  __typename?: 'FeedUserSettingsConnection';
  /** A list of edges which contains the `FeedUserSetting` and cursor to aid in pagination. */
  edges: Array<FeedUserSettingsEdge>;
  /** A list of `FeedUserSetting` objects. */
  nodes: Array<Maybe<FeedUserSetting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedUserSetting` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedUserSetting` edge in the connection. */
export type FeedUserSettingsEdge = {
  __typename?: 'FeedUserSettingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedUserSetting` at the end of the edge. */
  node?: Maybe<FeedUserSetting>;
};

/** Methods to use when ordering `FeedUserSetting`. */
export enum FeedUserSettingsOrderBy {
  BlockCountRelWeightAsc = 'BLOCK_COUNT_REL_WEIGHT_ASC',
  BlockCountRelWeightDesc = 'BLOCK_COUNT_REL_WEIGHT_DESC',
  FocusFollowCountRelWeightAsc = 'FOCUS_FOLLOW_COUNT_REL_WEIGHT_ASC',
  FocusFollowCountRelWeightDesc = 'FOCUS_FOLLOW_COUNT_REL_WEIGHT_DESC',
  FollowCountRelWeightAsc = 'FOLLOW_COUNT_REL_WEIGHT_ASC',
  FollowCountRelWeightDesc = 'FOLLOW_COUNT_REL_WEIGHT_DESC',
  Natural = 'NATURAL',
  NegativeReactionCountPostWeightAsc = 'NEGATIVE_REACTION_COUNT_POST_WEIGHT_ASC',
  NegativeReactionCountPostWeightDesc = 'NEGATIVE_REACTION_COUNT_POST_WEIGHT_DESC',
  NegativeReactionCountRelWeightAsc = 'NEGATIVE_REACTION_COUNT_REL_WEIGHT_ASC',
  NegativeReactionCountRelWeightDesc = 'NEGATIVE_REACTION_COUNT_REL_WEIGHT_DESC',
  NegativeReactionNanosPostWeightAsc = 'NEGATIVE_REACTION_NANOS_POST_WEIGHT_ASC',
  NegativeReactionNanosPostWeightDesc = 'NEGATIVE_REACTION_NANOS_POST_WEIGHT_DESC',
  NeutralReactionCountPostWeightAsc = 'NEUTRAL_REACTION_COUNT_POST_WEIGHT_ASC',
  NeutralReactionCountPostWeightDesc = 'NEUTRAL_REACTION_COUNT_POST_WEIGHT_DESC',
  NeutralReactionCountRelWeightAsc = 'NEUTRAL_REACTION_COUNT_REL_WEIGHT_ASC',
  NeutralReactionCountRelWeightDesc = 'NEUTRAL_REACTION_COUNT_REL_WEIGHT_DESC',
  NeutralReactionNanosPostWeightAsc = 'NEUTRAL_REACTION_NANOS_POST_WEIGHT_ASC',
  NeutralReactionNanosPostWeightDesc = 'NEUTRAL_REACTION_NANOS_POST_WEIGHT_DESC',
  PositiveReactionCountPostWeightAsc = 'POSITIVE_REACTION_COUNT_POST_WEIGHT_ASC',
  PositiveReactionCountPostWeightDesc = 'POSITIVE_REACTION_COUNT_POST_WEIGHT_DESC',
  PositiveReactionCountRelWeightAsc = 'POSITIVE_REACTION_COUNT_REL_WEIGHT_ASC',
  PositiveReactionCountRelWeightDesc = 'POSITIVE_REACTION_COUNT_REL_WEIGHT_DESC',
  PositiveReactionNanosPostWeightAsc = 'POSITIVE_REACTION_NANOS_POST_WEIGHT_ASC',
  PositiveReactionNanosPostWeightDesc = 'POSITIVE_REACTION_NANOS_POST_WEIGHT_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  QuoteCountPostWeightAsc = 'QUOTE_COUNT_POST_WEIGHT_ASC',
  QuoteCountPostWeightDesc = 'QUOTE_COUNT_POST_WEIGHT_DESC',
  QuoteCountRelWeightAsc = 'QUOTE_COUNT_REL_WEIGHT_ASC',
  QuoteCountRelWeightDesc = 'QUOTE_COUNT_REL_WEIGHT_DESC',
  QuoteNanosPostWeightAsc = 'QUOTE_NANOS_POST_WEIGHT_ASC',
  QuoteNanosPostWeightDesc = 'QUOTE_NANOS_POST_WEIGHT_DESC',
  ReplyCountPostWeightAsc = 'REPLY_COUNT_POST_WEIGHT_ASC',
  ReplyCountPostWeightDesc = 'REPLY_COUNT_POST_WEIGHT_DESC',
  ReplyCountRelWeightAsc = 'REPLY_COUNT_REL_WEIGHT_ASC',
  ReplyCountRelWeightDesc = 'REPLY_COUNT_REL_WEIGHT_DESC',
  ReplyNanosPostWeightAsc = 'REPLY_NANOS_POST_WEIGHT_ASC',
  ReplyNanosPostWeightDesc = 'REPLY_NANOS_POST_WEIGHT_DESC',
  ReportCountPostWeightAsc = 'REPORT_COUNT_POST_WEIGHT_ASC',
  ReportCountPostWeightDesc = 'REPORT_COUNT_POST_WEIGHT_DESC',
  ReportCountRelWeightAsc = 'REPORT_COUNT_REL_WEIGHT_ASC',
  ReportCountRelWeightDesc = 'REPORT_COUNT_REL_WEIGHT_DESC',
  ReportNanosPostWeightAsc = 'REPORT_NANOS_POST_WEIGHT_ASC',
  ReportNanosPostWeightDesc = 'REPORT_NANOS_POST_WEIGHT_DESC',
  RepostCountPostWeightAsc = 'REPOST_COUNT_POST_WEIGHT_ASC',
  RepostCountPostWeightDesc = 'REPOST_COUNT_POST_WEIGHT_DESC',
  RepostCountRelWeightAsc = 'REPOST_COUNT_REL_WEIGHT_ASC',
  RepostCountRelWeightDesc = 'REPOST_COUNT_REL_WEIGHT_DESC',
  RepostNanosPostWeightAsc = 'REPOST_NANOS_POST_WEIGHT_ASC',
  RepostNanosPostWeightDesc = 'REPOST_NANOS_POST_WEIGHT_DESC',
  ShowLessCountPostWeightAsc = 'SHOW_LESS_COUNT_POST_WEIGHT_ASC',
  ShowLessCountPostWeightDesc = 'SHOW_LESS_COUNT_POST_WEIGHT_DESC',
  ShowLessCountRelWeightAsc = 'SHOW_LESS_COUNT_REL_WEIGHT_ASC',
  ShowLessCountRelWeightDesc = 'SHOW_LESS_COUNT_REL_WEIGHT_DESC',
  ShowLessNanosPostWeightAsc = 'SHOW_LESS_NANOS_POST_WEIGHT_ASC',
  ShowLessNanosPostWeightDesc = 'SHOW_LESS_NANOS_POST_WEIGHT_DESC',
  TimeDecayAsc = 'TIME_DECAY_ASC',
  TimeDecayDesc = 'TIME_DECAY_DESC',
  TipCountNanosPostWeightAsc = 'TIP_COUNT_NANOS_POST_WEIGHT_ASC',
  TipCountNanosPostWeightDesc = 'TIP_COUNT_NANOS_POST_WEIGHT_DESC',
  TipCountPostWeightAsc = 'TIP_COUNT_POST_WEIGHT_ASC',
  TipCountPostWeightDesc = 'TIP_COUNT_POST_WEIGHT_DESC',
  TipCountRelWeightAsc = 'TIP_COUNT_REL_WEIGHT_ASC',
  TipCountRelWeightDesc = 'TIP_COUNT_REL_WEIGHT_DESC',
  TipDesoNanosRelWeightAsc = 'TIP_DESO_NANOS_REL_WEIGHT_ASC',
  TipDesoNanosRelWeightDesc = 'TIP_DESO_NANOS_REL_WEIGHT_DESC',
  TipDiamondCountPostWeightAsc = 'TIP_DIAMOND_COUNT_POST_WEIGHT_ASC',
  TipDiamondCountPostWeightDesc = 'TIP_DIAMOND_COUNT_POST_WEIGHT_DESC',
  TipDiamondCountRelWeightAsc = 'TIP_DIAMOND_COUNT_REL_WEIGHT_ASC',
  TipDiamondCountRelWeightDesc = 'TIP_DIAMOND_COUNT_REL_WEIGHT_DESC',
  TipNanosGivenPostWeightAsc = 'TIP_NANOS_GIVEN_POST_WEIGHT_ASC',
  TipNanosGivenPostWeightDesc = 'TIP_NANOS_GIVEN_POST_WEIGHT_DESC',
  ViewCountPostWeightAsc = 'VIEW_COUNT_POST_WEIGHT_ASC',
  ViewCountPostWeightDesc = 'VIEW_COUNT_POST_WEIGHT_DESC',
  ViewNanosPostWeightAsc = 'VIEW_NANOS_POST_WEIGHT_ASC',
  ViewNanosPostWeightDesc = 'VIEW_NANOS_POST_WEIGHT_DESC',
}

/** A connection to a list of `FeedUser` values. */
export type FeedUsersConnection = {
  __typename?: 'FeedUsersConnection';
  /** A list of edges which contains the `FeedUser` and cursor to aid in pagination. */
  edges: Array<FeedUsersEdge>;
  /** A list of `FeedUser` objects. */
  nodes: Array<Maybe<FeedUser>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedUser` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedUser` edge in the connection. */
export type FeedUsersEdge = {
  __typename?: 'FeedUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedUser` at the end of the edge. */
  node?: Maybe<FeedUser>;
};

export type FeedUsersMv = {
  __typename?: 'FeedUsersMv';
  pkid?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FeedUsersMv` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FeedUsersMvCondition = {
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FeedUsersMv` object types. All fields are combined with a logical ‘and.’ */
export type FeedUsersMvFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedUsersMvFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<FeedUsersMvFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedUsersMvFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FeedUsersMv` values. */
export type FeedUsersMvsConnection = {
  __typename?: 'FeedUsersMvsConnection';
  /** A list of edges which contains the `FeedUsersMv` and cursor to aid in pagination. */
  edges: Array<FeedUsersMvsEdge>;
  /** A list of `FeedUsersMv` objects. */
  nodes: Array<Maybe<FeedUsersMv>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedUsersMv` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedUsersMv` edge in the connection. */
export type FeedUsersMvsEdge = {
  __typename?: 'FeedUsersMvsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedUsersMv` at the end of the edge. */
  node?: Maybe<FeedUsersMv>;
};

/** Methods to use when ordering `FeedUsersMv`. */
export enum FeedUsersMvsOrderBy {
  Natural = 'NATURAL',
  PkidAsc = 'PKID_ASC',
  PkidDesc = 'PKID_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
}

/** Methods to use when ordering `FeedUser`. */
export enum FeedUsersOrderBy {
  Natural = 'NATURAL',
  PkidAsc = 'PKID_ASC',
  PkidDesc = 'PKID_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
}

export type FeedWeightedPostScore = {
  __typename?: 'FeedWeightedPostScore';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  isComment: Scalars['Boolean']['output'];
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  postHash?: Maybe<Scalars['String']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
  weightedScore?: Maybe<Scalars['Float']['output']>;
  weightedScoreWithWealth?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `FeedWeightedPostScore` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FeedWeightedPostScoreCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `isComment` field. */
  isComment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isHidden` field. */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `weightedScore` field. */
  weightedScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `weightedScoreWithWealth` field. */
  weightedScoreWithWealth?: InputMaybe<Scalars['Float']['input']>;
};

/** A filter to be used against `FeedWeightedPostScore` object types. All fields are combined with a logical ‘and.’ */
export type FeedWeightedPostScoreFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedWeightedPostScoreFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `isComment` field. */
  isComment?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isHidden` field. */
  isHidden?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedWeightedPostScoreFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedWeightedPostScoreFilter>>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `weightedScore` field. */
  weightedScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `weightedScoreWithWealth` field. */
  weightedScoreWithWealth?: InputMaybe<FloatFilter>;
};

/** A connection to a list of `FeedWeightedPostScore` values. */
export type FeedWeightedPostScoresConnection = {
  __typename?: 'FeedWeightedPostScoresConnection';
  /** A list of edges which contains the `FeedWeightedPostScore` and cursor to aid in pagination. */
  edges: Array<FeedWeightedPostScoresEdge>;
  /** A list of `FeedWeightedPostScore` objects. */
  nodes: Array<Maybe<FeedWeightedPostScore>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedWeightedPostScore` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedWeightedPostScore` edge in the connection. */
export type FeedWeightedPostScoresEdge = {
  __typename?: 'FeedWeightedPostScoresEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedWeightedPostScore` at the end of the edge. */
  node?: Maybe<FeedWeightedPostScore>;
};

/** Methods to use when ordering `FeedWeightedPostScore`. */
export enum FeedWeightedPostScoresOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  IsCommentAsc = 'IS_COMMENT_ASC',
  IsCommentDesc = 'IS_COMMENT_DESC',
  IsHiddenAsc = 'IS_HIDDEN_ASC',
  IsHiddenDesc = 'IS_HIDDEN_DESC',
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  WeightedScoreAsc = 'WEIGHTED_SCORE_ASC',
  WeightedScoreDesc = 'WEIGHTED_SCORE_DESC',
  WeightedScoreWithWealthAsc = 'WEIGHTED_SCORE_WITH_WEALTH_ASC',
  WeightedScoreWithWealthDesc = 'WEIGHTED_SCORE_WITH_WEALTH_DESC',
}

export type FeedWeightedUserRelationshipScore = {
  __typename?: 'FeedWeightedUserRelationshipScore';
  computedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  relatedUserPublicKey?: Maybe<Scalars['String']['output']>;
  weightedInteractionScore?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `FeedWeightedUserRelationshipScore` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type FeedWeightedUserRelationshipScoreCondition = {
  /** Checks for equality with the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `weightedInteractionScore` field. */
  weightedInteractionScore?: InputMaybe<Scalars['Float']['input']>;
};

/** A filter to be used against `FeedWeightedUserRelationshipScore` object types. All fields are combined with a logical ‘and.’ */
export type FeedWeightedUserRelationshipScoreFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedWeightedUserRelationshipScoreFilter>>;
  /** Filter by the object’s `computedBlockHeight` field. */
  computedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedWeightedUserRelationshipScoreFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedWeightedUserRelationshipScoreFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `relatedUserPublicKey` field. */
  relatedUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `weightedInteractionScore` field. */
  weightedInteractionScore?: InputMaybe<FloatFilter>;
};

/** A connection to a list of `FeedWeightedUserRelationshipScore` values. */
export type FeedWeightedUserRelationshipScoresConnection = {
  __typename?: 'FeedWeightedUserRelationshipScoresConnection';
  /** A list of edges which contains the `FeedWeightedUserRelationshipScore` and cursor to aid in pagination. */
  edges: Array<FeedWeightedUserRelationshipScoresEdge>;
  /** A list of `FeedWeightedUserRelationshipScore` objects. */
  nodes: Array<Maybe<FeedWeightedUserRelationshipScore>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedWeightedUserRelationshipScore` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FeedWeightedUserRelationshipScore` edge in the connection. */
export type FeedWeightedUserRelationshipScoresEdge = {
  __typename?: 'FeedWeightedUserRelationshipScoresEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedWeightedUserRelationshipScore` at the end of the edge. */
  node?: Maybe<FeedWeightedUserRelationshipScore>;
};

/** Methods to use when ordering `FeedWeightedUserRelationshipScore`. */
export enum FeedWeightedUserRelationshipScoresOrderBy {
  ComputedBlockHeightAsc = 'COMPUTED_BLOCK_HEIGHT_ASC',
  ComputedBlockHeightDesc = 'COMPUTED_BLOCK_HEIGHT_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  RelatedUserPublicKeyAsc = 'RELATED_USER_PUBLIC_KEY_ASC',
  RelatedUserPublicKeyDesc = 'RELATED_USER_PUBLIC_KEY_DESC',
  WeightedInteractionScoreAsc = 'WEIGHTED_INTERACTION_SCORE_ASC',
  WeightedInteractionScoreDesc = 'WEIGHTED_INTERACTION_SCORE_DESC',
}

/** A connection to a list of `Feed` values. */
export type FeedsConnection = {
  __typename?: 'FeedsConnection';
  /** A list of edges which contains the `Feed` and cursor to aid in pagination. */
  edges: Array<FeedsEdge>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Feed` edge in the connection. */
export type FeedsEdge = {
  __typename?: 'FeedsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node?: Maybe<Feed>;
};

/** Methods to use when ordering `Feed`. */
export enum FeedsOrderBy {
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PostScoreAsc = 'POST_SCORE_ASC',
  PostScoreDesc = 'POST_SCORE_DESC',
  PostScoreWithWealthAsc = 'POST_SCORE_WITH_WEALTH_ASC',
  PostScoreWithWealthDesc = 'POST_SCORE_WITH_WEALTH_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
}

export type FilledDaoCoinOrdersWithPrice = Node & {
  __typename?: 'FilledDaoCoinOrdersWithPrice';
  blockHeight: Scalars['Int']['output'];
  buyingCoinPublicKey: Scalars['String']['output'];
  coinQuantityBought: Scalars['Float']['output'];
  coinQuantitySold: Scalars['Float']['output'];
  denominatedCoinPublicKey: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  indexInBlock: Scalars['Int']['output'];
  indexInTransaction: Scalars['Int']['output'];
  indexInWrapperTransaction: Scalars['Int']['output'];
  isFulfilled: Scalars['Boolean']['output'];
  isMatchedOrder: Scalars['Boolean']['output'];
  rowId: Scalars['UUID']['output'];
  sellingCoinPublicKey: Scalars['String']['output'];
  targetCoinAmount: Scalars['Float']['output'];
  targetCoinPriceUsd: Scalars['Float']['output'];
  targetCoinPublicKey: Scalars['String']['output'];
  timestamp: Scalars['Datetime']['output'];
  transactorPublicKey: Scalars['String']['output'];
  txnHashHex: Scalars['String']['output'];
};

/**
 * A condition to be used against `FilledDaoCoinOrdersWithPrice` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FilledDaoCoinOrdersWithPriceCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `buyingCoinPublicKey` field. */
  buyingCoinPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `coinQuantityBought` field. */
  coinQuantityBought?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `coinQuantitySold` field. */
  coinQuantitySold?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `denominatedCoinPublicKey` field. */
  denominatedCoinPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `indexInBlock` field. */
  indexInBlock?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `indexInTransaction` field. */
  indexInTransaction?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `indexInWrapperTransaction` field. */
  indexInWrapperTransaction?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isFulfilled` field. */
  isFulfilled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isMatchedOrder` field. */
  isMatchedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `sellingCoinPublicKey` field. */
  sellingCoinPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `targetCoinAmount` field. */
  targetCoinAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `targetCoinPriceUsd` field. */
  targetCoinPriceUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `targetCoinPublicKey` field. */
  targetCoinPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `transactorPublicKey` field. */
  transactorPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `txnHashHex` field. */
  txnHashHex?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FilledDaoCoinOrdersWithPrice` object types. All fields are combined with a logical ‘and.’ */
export type FilledDaoCoinOrdersWithPriceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FilledDaoCoinOrdersWithPriceFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<IntFilter>;
  /** Filter by the object’s `buyingCoinPublicKey` field. */
  buyingCoinPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coinQuantityBought` field. */
  coinQuantityBought?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `coinQuantitySold` field. */
  coinQuantitySold?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `denominatedCoinPublicKey` field. */
  denominatedCoinPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `indexInBlock` field. */
  indexInBlock?: InputMaybe<IntFilter>;
  /** Filter by the object’s `indexInTransaction` field. */
  indexInTransaction?: InputMaybe<IntFilter>;
  /** Filter by the object’s `indexInWrapperTransaction` field. */
  indexInWrapperTransaction?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isFulfilled` field. */
  isFulfilled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isMatchedOrder` field. */
  isMatchedOrder?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FilledDaoCoinOrdersWithPriceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FilledDaoCoinOrdersWithPriceFilter>>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `sellingCoinPublicKey` field. */
  sellingCoinPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `targetCoinAmount` field. */
  targetCoinAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `targetCoinPriceUsd` field. */
  targetCoinPriceUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `targetCoinPublicKey` field. */
  targetCoinPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `transactorPublicKey` field. */
  transactorPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `txnHashHex` field. */
  txnHashHex?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FilledDaoCoinOrdersWithPrice` values. */
export type FilledDaoCoinOrdersWithPricesConnection = {
  __typename?: 'FilledDaoCoinOrdersWithPricesConnection';
  /** A list of edges which contains the `FilledDaoCoinOrdersWithPrice` and cursor to aid in pagination. */
  edges: Array<FilledDaoCoinOrdersWithPricesEdge>;
  /** A list of `FilledDaoCoinOrdersWithPrice` objects. */
  nodes: Array<Maybe<FilledDaoCoinOrdersWithPrice>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FilledDaoCoinOrdersWithPrice` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FilledDaoCoinOrdersWithPrice` edge in the connection. */
export type FilledDaoCoinOrdersWithPricesEdge = {
  __typename?: 'FilledDaoCoinOrdersWithPricesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FilledDaoCoinOrdersWithPrice` at the end of the edge. */
  node?: Maybe<FilledDaoCoinOrdersWithPrice>;
};

/** Methods to use when ordering `FilledDaoCoinOrdersWithPrice`. */
export enum FilledDaoCoinOrdersWithPricesOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  BuyingCoinPublicKeyAsc = 'BUYING_COIN_PUBLIC_KEY_ASC',
  BuyingCoinPublicKeyDesc = 'BUYING_COIN_PUBLIC_KEY_DESC',
  CoinQuantityBoughtAsc = 'COIN_QUANTITY_BOUGHT_ASC',
  CoinQuantityBoughtDesc = 'COIN_QUANTITY_BOUGHT_DESC',
  CoinQuantitySoldAsc = 'COIN_QUANTITY_SOLD_ASC',
  CoinQuantitySoldDesc = 'COIN_QUANTITY_SOLD_DESC',
  DenominatedCoinPublicKeyAsc = 'DENOMINATED_COIN_PUBLIC_KEY_ASC',
  DenominatedCoinPublicKeyDesc = 'DENOMINATED_COIN_PUBLIC_KEY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IndexInBlockAsc = 'INDEX_IN_BLOCK_ASC',
  IndexInBlockDesc = 'INDEX_IN_BLOCK_DESC',
  IndexInTransactionAsc = 'INDEX_IN_TRANSACTION_ASC',
  IndexInTransactionDesc = 'INDEX_IN_TRANSACTION_DESC',
  IndexInWrapperTransactionAsc = 'INDEX_IN_WRAPPER_TRANSACTION_ASC',
  IndexInWrapperTransactionDesc = 'INDEX_IN_WRAPPER_TRANSACTION_DESC',
  IsFulfilledAsc = 'IS_FULFILLED_ASC',
  IsFulfilledDesc = 'IS_FULFILLED_DESC',
  IsMatchedOrderAsc = 'IS_MATCHED_ORDER_ASC',
  IsMatchedOrderDesc = 'IS_MATCHED_ORDER_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SellingCoinPublicKeyAsc = 'SELLING_COIN_PUBLIC_KEY_ASC',
  SellingCoinPublicKeyDesc = 'SELLING_COIN_PUBLIC_KEY_DESC',
  TargetCoinAmountAsc = 'TARGET_COIN_AMOUNT_ASC',
  TargetCoinAmountDesc = 'TARGET_COIN_AMOUNT_DESC',
  TargetCoinPriceUsdAsc = 'TARGET_COIN_PRICE_USD_ASC',
  TargetCoinPriceUsdDesc = 'TARGET_COIN_PRICE_USD_DESC',
  TargetCoinPublicKeyAsc = 'TARGET_COIN_PUBLIC_KEY_ASC',
  TargetCoinPublicKeyDesc = 'TARGET_COIN_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
  TransactorPublicKeyAsc = 'TRANSACTOR_PUBLIC_KEY_ASC',
  TransactorPublicKeyDesc = 'TRANSACTOR_PUBLIC_KEY_DESC',
  TxnHashHexAsc = 'TXN_HASH_HEX_ASC',
  TxnHashHexDesc = 'TXN_HASH_HEX_DESC',
}

export type FilterableMessageThread = {
  __typename?: 'FilterableMessageThread';
  /** Reads a single `Account` that is related to this `FilterableMessageThread`. */
  initiator?: Maybe<Account>;
  initiatorPublicKey?: Maybe<Scalars['String']['output']>;
  isSpam?: Maybe<Scalars['Boolean']['output']>;
  latestClaimedPaymentTimestamp?: Maybe<Scalars['Datetime']['output']>;
  latestClaimedSentPaymentTimestamp?: Maybe<Scalars['Datetime']['output']>;
  latestMessageTimestamp?: Maybe<Scalars['Datetime']['output']>;
  latestReceivedMessageTimestamp?: Maybe<Scalars['Datetime']['output']>;
  latestReceivedPaymentTimestamp?: Maybe<Scalars['Datetime']['output']>;
  latestSentMessageTimestamp?: Maybe<Scalars['Datetime']['output']>;
  latestSentPaymentTimestamp?: Maybe<Scalars['Datetime']['output']>;
  latestUnclaimedPaymentTimestamp?: Maybe<Scalars['Datetime']['output']>;
  latestUnclaimedSentPaymentTimestamp?: Maybe<Scalars['Datetime']['output']>;
  maxPaidMessageAmtUsdCents?: Maybe<Scalars['BigInt']['output']>;
  /** Reads a single `Account` that is related to this `FilterableMessageThread`. */
  participant?: Maybe<Account>;
  participantDefaultFeePerMessageUsdCents?: Maybe<Scalars['BigInt']['output']>;
  participantFollowingFeePerMessageUsdCents?: Maybe<Scalars['BigInt']['output']>;
  participantPublicKey?: Maybe<Scalars['String']['output']>;
  participantThreadFeePerMessageUsdCents?: Maybe<Scalars['BigInt']['output']>;
  participantUserFeePerMessageUsdCents?: Maybe<Scalars['BigInt']['output']>;
  requiredPaymentAmountUsdCents?: Maybe<Scalars['BigInt']['output']>;
  /** Reads a single `MessageThread` that is related to this `FilterableMessageThread`. */
  thread?: Maybe<MessageThread>;
  threadIdentifier?: Maybe<Scalars['String']['output']>;
  totalClaimedMessageTipsUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  totalClaimedSentMessageTipsUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  totalRefundedMessageTipsUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  totalRefundedSentMessageTipsUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  totalUnclaimedMessageTipsUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  totalUnclaimedSentMessageTipsUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  /** Reads and enables pagination through a set of `UnreadMessagesCount`. */
  unreadMessagesCount: UnreadMessagesCountsConnection;
};

export type FilterableMessageThreadUnreadMessagesCountArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UnreadMessagesCountCondition>;
  filter?: InputMaybe<UnreadMessagesCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UnreadMessagesCountsOrderBy>>;
};

/**
 * A condition to be used against `FilterableMessageThread` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type FilterableMessageThreadCondition = {
  /** Checks for equality with the object’s `initiatorPublicKey` field. */
  initiatorPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isSpam` field. */
  isSpam?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `latestClaimedPaymentTimestamp` field. */
  latestClaimedPaymentTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `latestClaimedSentPaymentTimestamp` field. */
  latestClaimedSentPaymentTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `latestMessageTimestamp` field. */
  latestMessageTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `latestReceivedMessageTimestamp` field. */
  latestReceivedMessageTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `latestReceivedPaymentTimestamp` field. */
  latestReceivedPaymentTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `latestSentMessageTimestamp` field. */
  latestSentMessageTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `latestSentPaymentTimestamp` field. */
  latestSentPaymentTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `latestUnclaimedPaymentTimestamp` field. */
  latestUnclaimedPaymentTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `latestUnclaimedSentPaymentTimestamp` field. */
  latestUnclaimedSentPaymentTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `maxPaidMessageAmtUsdCents` field. */
  maxPaidMessageAmtUsdCents?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `participantDefaultFeePerMessageUsdCents` field. */
  participantDefaultFeePerMessageUsdCents?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `participantFollowingFeePerMessageUsdCents` field. */
  participantFollowingFeePerMessageUsdCents?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `participantPublicKey` field. */
  participantPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `participantThreadFeePerMessageUsdCents` field. */
  participantThreadFeePerMessageUsdCents?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `participantUserFeePerMessageUsdCents` field. */
  participantUserFeePerMessageUsdCents?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `requiredPaymentAmountUsdCents` field. */
  requiredPaymentAmountUsdCents?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `totalClaimedMessageTipsUsdCents` field. */
  totalClaimedMessageTipsUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalClaimedSentMessageTipsUsdCents` field. */
  totalClaimedSentMessageTipsUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalRefundedMessageTipsUsdCents` field. */
  totalRefundedMessageTipsUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalRefundedSentMessageTipsUsdCents` field. */
  totalRefundedSentMessageTipsUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalUnclaimedMessageTipsUsdCents` field. */
  totalUnclaimedMessageTipsUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalUnclaimedSentMessageTipsUsdCents` field. */
  totalUnclaimedSentMessageTipsUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `FilterableMessageThread` object types. All fields are combined with a logical ‘and.’ */
export type FilterableMessageThreadFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FilterableMessageThreadFilter>>;
  /** Filter by the object’s `initiator` relation. */
  initiator?: InputMaybe<AccountFilter>;
  /** A related `initiator` exists. */
  initiatorExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `initiatorPublicKey` field. */
  initiatorPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isSpam` field. */
  isSpam?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `latestClaimedPaymentTimestamp` field. */
  latestClaimedPaymentTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `latestClaimedSentPaymentTimestamp` field. */
  latestClaimedSentPaymentTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `latestMessageTimestamp` field. */
  latestMessageTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `latestReceivedMessageTimestamp` field. */
  latestReceivedMessageTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `latestReceivedPaymentTimestamp` field. */
  latestReceivedPaymentTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `latestSentMessageTimestamp` field. */
  latestSentMessageTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `latestSentPaymentTimestamp` field. */
  latestSentPaymentTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `latestUnclaimedPaymentTimestamp` field. */
  latestUnclaimedPaymentTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `latestUnclaimedSentPaymentTimestamp` field. */
  latestUnclaimedSentPaymentTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `maxPaidMessageAmtUsdCents` field. */
  maxPaidMessageAmtUsdCents?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FilterableMessageThreadFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FilterableMessageThreadFilter>>;
  /** Filter by the object’s `participant` relation. */
  participant?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `participantDefaultFeePerMessageUsdCents` field. */
  participantDefaultFeePerMessageUsdCents?: InputMaybe<BigIntFilter>;
  /** A related `participant` exists. */
  participantExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `participantFollowingFeePerMessageUsdCents` field. */
  participantFollowingFeePerMessageUsdCents?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `participantPublicKey` field. */
  participantPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `participantThreadFeePerMessageUsdCents` field. */
  participantThreadFeePerMessageUsdCents?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `participantUserFeePerMessageUsdCents` field. */
  participantUserFeePerMessageUsdCents?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `requiredPaymentAmountUsdCents` field. */
  requiredPaymentAmountUsdCents?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `thread` relation. */
  thread?: InputMaybe<MessageThreadFilter>;
  /** A related `thread` exists. */
  threadExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalClaimedMessageTipsUsdCents` field. */
  totalClaimedMessageTipsUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalClaimedSentMessageTipsUsdCents` field. */
  totalClaimedSentMessageTipsUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalRefundedMessageTipsUsdCents` field. */
  totalRefundedMessageTipsUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalRefundedSentMessageTipsUsdCents` field. */
  totalRefundedSentMessageTipsUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalUnclaimedMessageTipsUsdCents` field. */
  totalUnclaimedMessageTipsUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalUnclaimedSentMessageTipsUsdCents` field. */
  totalUnclaimedSentMessageTipsUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `unreadMessagesCount` relation. */
  unreadMessagesCount?: InputMaybe<FilterableMessageThreadToManyUnreadMessagesCountFilter>;
  /** Some related `unreadMessagesCount` exist. */
  unreadMessagesCountExist?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A filter to be used against many `UnreadMessagesCount` object types. All fields are combined with a logical ‘and.’ */
export type FilterableMessageThreadToManyUnreadMessagesCountFilter = {
  /** Every related `UnreadMessagesCount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UnreadMessagesCountFilter>;
  /** No related `UnreadMessagesCount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UnreadMessagesCountFilter>;
  /** Some related `UnreadMessagesCount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UnreadMessagesCountFilter>;
};

/** A connection to a list of `FilterableMessageThread` values. */
export type FilterableMessageThreadsConnection = {
  __typename?: 'FilterableMessageThreadsConnection';
  /** A list of edges which contains the `FilterableMessageThread` and cursor to aid in pagination. */
  edges: Array<FilterableMessageThreadsEdge>;
  /** A list of `FilterableMessageThread` objects. */
  nodes: Array<Maybe<FilterableMessageThread>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FilterableMessageThread` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FilterableMessageThread` edge in the connection. */
export type FilterableMessageThreadsEdge = {
  __typename?: 'FilterableMessageThreadsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FilterableMessageThread` at the end of the edge. */
  node?: Maybe<FilterableMessageThread>;
};

/** Methods to use when ordering `FilterableMessageThread`. */
export enum FilterableMessageThreadsOrderBy {
  InitiatorPublicKeyAsc = 'INITIATOR_PUBLIC_KEY_ASC',
  InitiatorPublicKeyDesc = 'INITIATOR_PUBLIC_KEY_DESC',
  IsSpamAsc = 'IS_SPAM_ASC',
  IsSpamDesc = 'IS_SPAM_DESC',
  LatestClaimedPaymentTimestampAsc = 'LATEST_CLAIMED_PAYMENT_TIMESTAMP_ASC',
  LatestClaimedPaymentTimestampDesc = 'LATEST_CLAIMED_PAYMENT_TIMESTAMP_DESC',
  LatestClaimedSentPaymentTimestampAsc = 'LATEST_CLAIMED_SENT_PAYMENT_TIMESTAMP_ASC',
  LatestClaimedSentPaymentTimestampDesc = 'LATEST_CLAIMED_SENT_PAYMENT_TIMESTAMP_DESC',
  LatestMessageTimestampAsc = 'LATEST_MESSAGE_TIMESTAMP_ASC',
  LatestMessageTimestampDesc = 'LATEST_MESSAGE_TIMESTAMP_DESC',
  LatestReceivedMessageTimestampAsc = 'LATEST_RECEIVED_MESSAGE_TIMESTAMP_ASC',
  LatestReceivedMessageTimestampDesc = 'LATEST_RECEIVED_MESSAGE_TIMESTAMP_DESC',
  LatestReceivedPaymentTimestampAsc = 'LATEST_RECEIVED_PAYMENT_TIMESTAMP_ASC',
  LatestReceivedPaymentTimestampDesc = 'LATEST_RECEIVED_PAYMENT_TIMESTAMP_DESC',
  LatestSentMessageTimestampAsc = 'LATEST_SENT_MESSAGE_TIMESTAMP_ASC',
  LatestSentMessageTimestampDesc = 'LATEST_SENT_MESSAGE_TIMESTAMP_DESC',
  LatestSentPaymentTimestampAsc = 'LATEST_SENT_PAYMENT_TIMESTAMP_ASC',
  LatestSentPaymentTimestampDesc = 'LATEST_SENT_PAYMENT_TIMESTAMP_DESC',
  LatestUnclaimedPaymentTimestampAsc = 'LATEST_UNCLAIMED_PAYMENT_TIMESTAMP_ASC',
  LatestUnclaimedPaymentTimestampDesc = 'LATEST_UNCLAIMED_PAYMENT_TIMESTAMP_DESC',
  LatestUnclaimedSentPaymentTimestampAsc = 'LATEST_UNCLAIMED_SENT_PAYMENT_TIMESTAMP_ASC',
  LatestUnclaimedSentPaymentTimestampDesc = 'LATEST_UNCLAIMED_SENT_PAYMENT_TIMESTAMP_DESC',
  MaxPaidMessageAmtUsdCentsAsc = 'MAX_PAID_MESSAGE_AMT_USD_CENTS_ASC',
  MaxPaidMessageAmtUsdCentsDesc = 'MAX_PAID_MESSAGE_AMT_USD_CENTS_DESC',
  Natural = 'NATURAL',
  ParticipantDefaultFeePerMessageUsdCentsAsc = 'PARTICIPANT_DEFAULT_FEE_PER_MESSAGE_USD_CENTS_ASC',
  ParticipantDefaultFeePerMessageUsdCentsDesc = 'PARTICIPANT_DEFAULT_FEE_PER_MESSAGE_USD_CENTS_DESC',
  ParticipantFollowingFeePerMessageUsdCentsAsc = 'PARTICIPANT_FOLLOWING_FEE_PER_MESSAGE_USD_CENTS_ASC',
  ParticipantFollowingFeePerMessageUsdCentsDesc = 'PARTICIPANT_FOLLOWING_FEE_PER_MESSAGE_USD_CENTS_DESC',
  ParticipantPublicKeyAsc = 'PARTICIPANT_PUBLIC_KEY_ASC',
  ParticipantPublicKeyDesc = 'PARTICIPANT_PUBLIC_KEY_DESC',
  ParticipantThreadFeePerMessageUsdCentsAsc = 'PARTICIPANT_THREAD_FEE_PER_MESSAGE_USD_CENTS_ASC',
  ParticipantThreadFeePerMessageUsdCentsDesc = 'PARTICIPANT_THREAD_FEE_PER_MESSAGE_USD_CENTS_DESC',
  ParticipantUserFeePerMessageUsdCentsAsc = 'PARTICIPANT_USER_FEE_PER_MESSAGE_USD_CENTS_ASC',
  ParticipantUserFeePerMessageUsdCentsDesc = 'PARTICIPANT_USER_FEE_PER_MESSAGE_USD_CENTS_DESC',
  RequiredPaymentAmountUsdCentsAsc = 'REQUIRED_PAYMENT_AMOUNT_USD_CENTS_ASC',
  RequiredPaymentAmountUsdCentsDesc = 'REQUIRED_PAYMENT_AMOUNT_USD_CENTS_DESC',
  ThreadIdentifierAsc = 'THREAD_IDENTIFIER_ASC',
  ThreadIdentifierDesc = 'THREAD_IDENTIFIER_DESC',
  TotalClaimedMessageTipsUsdCentsAsc = 'TOTAL_CLAIMED_MESSAGE_TIPS_USD_CENTS_ASC',
  TotalClaimedMessageTipsUsdCentsDesc = 'TOTAL_CLAIMED_MESSAGE_TIPS_USD_CENTS_DESC',
  TotalClaimedSentMessageTipsUsdCentsAsc = 'TOTAL_CLAIMED_SENT_MESSAGE_TIPS_USD_CENTS_ASC',
  TotalClaimedSentMessageTipsUsdCentsDesc = 'TOTAL_CLAIMED_SENT_MESSAGE_TIPS_USD_CENTS_DESC',
  TotalRefundedMessageTipsUsdCentsAsc = 'TOTAL_REFUNDED_MESSAGE_TIPS_USD_CENTS_ASC',
  TotalRefundedMessageTipsUsdCentsDesc = 'TOTAL_REFUNDED_MESSAGE_TIPS_USD_CENTS_DESC',
  TotalRefundedSentMessageTipsUsdCentsAsc = 'TOTAL_REFUNDED_SENT_MESSAGE_TIPS_USD_CENTS_ASC',
  TotalRefundedSentMessageTipsUsdCentsDesc = 'TOTAL_REFUNDED_SENT_MESSAGE_TIPS_USD_CENTS_DESC',
  TotalUnclaimedMessageTipsUsdCentsAsc = 'TOTAL_UNCLAIMED_MESSAGE_TIPS_USD_CENTS_ASC',
  TotalUnclaimedMessageTipsUsdCentsDesc = 'TOTAL_UNCLAIMED_MESSAGE_TIPS_USD_CENTS_DESC',
  TotalUnclaimedSentMessageTipsUsdCentsAsc = 'TOTAL_UNCLAIMED_SENT_MESSAGE_TIPS_USD_CENTS_ASC',
  TotalUnclaimedSentMessageTipsUsdCentsDesc = 'TOTAL_UNCLAIMED_SENT_MESSAGE_TIPS_USD_CENTS_DESC',
}

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Float']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Float']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Float']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Float']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Float']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Float']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Float']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Float']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FocusList = Node & {
  __typename?: 'FocusList';
  /** Reads a single `Account` that is related to this `FocusList`. */
  account?: Maybe<Account>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  listDescription?: Maybe<Scalars['String']['output']>;
  listId?: Maybe<Scalars['UUID']['output']>;
  /** Reads and enables pagination through a set of `FocusListMember`. */
  listMembers: FocusListMembersConnection;
  listName: Scalars['String']['output'];
  membersLimit?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
  userPublicKeyBase58Check: Scalars['String']['output'];
};

export type FocusListListMembersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FocusListMemberCondition>;
  filter?: InputMaybe<FocusListMemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FocusListMembersOrderBy>>;
};

/**
 * A condition to be used against `FocusList` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FocusListCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `listDescription` field. */
  listDescription?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `listId` field. */
  listId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `listName` field. */
  listName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `membersLimit` field. */
  membersLimit?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userPublicKeyBase58Check` field. */
  userPublicKeyBase58Check?: InputMaybe<Scalars['String']['input']>;
};

export type FocusListFdw = {
  __typename?: 'FocusListFdw';
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  listDescription?: Maybe<Scalars['String']['output']>;
  listId?: Maybe<Scalars['UUID']['output']>;
  listName?: Maybe<Scalars['String']['output']>;
  membersLimit?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
  userPublicKeyBase58Check?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FocusListFdw` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FocusListFdwCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `listDescription` field. */
  listDescription?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `listId` field. */
  listId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `listName` field. */
  listName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `membersLimit` field. */
  membersLimit?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userPublicKeyBase58Check` field. */
  userPublicKeyBase58Check?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FocusListFdw` object types. All fields are combined with a logical ‘and.’ */
export type FocusListFdwFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FocusListFdwFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `listDescription` field. */
  listDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `listId` field. */
  listId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `listName` field. */
  listName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `membersLimit` field. */
  membersLimit?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FocusListFdwFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FocusListFdwFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userPublicKeyBase58Check` field. */
  userPublicKeyBase58Check?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FocusListFdw` values. */
export type FocusListFdwsConnection = {
  __typename?: 'FocusListFdwsConnection';
  /** A list of edges which contains the `FocusListFdw` and cursor to aid in pagination. */
  edges: Array<FocusListFdwsEdge>;
  /** A list of `FocusListFdw` objects. */
  nodes: Array<Maybe<FocusListFdw>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FocusListFdw` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FocusListFdw` edge in the connection. */
export type FocusListFdwsEdge = {
  __typename?: 'FocusListFdwsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FocusListFdw` at the end of the edge. */
  node?: Maybe<FocusListFdw>;
};

/** Methods to use when ordering `FocusListFdw`. */
export enum FocusListFdwsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ListDescriptionAsc = 'LIST_DESCRIPTION_ASC',
  ListDescriptionDesc = 'LIST_DESCRIPTION_DESC',
  ListIdAsc = 'LIST_ID_ASC',
  ListIdDesc = 'LIST_ID_DESC',
  ListNameAsc = 'LIST_NAME_ASC',
  ListNameDesc = 'LIST_NAME_DESC',
  MembersLimitAsc = 'MEMBERS_LIMIT_ASC',
  MembersLimitDesc = 'MEMBERS_LIMIT_DESC',
  Natural = 'NATURAL',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserPublicKeyBase58CheckAsc = 'USER_PUBLIC_KEY_BASE58_CHECK_ASC',
  UserPublicKeyBase58CheckDesc = 'USER_PUBLIC_KEY_BASE58_CHECK_DESC',
}

/** A filter to be used against `FocusList` object types. All fields are combined with a logical ‘and.’ */
export type FocusListFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FocusListFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `listDescription` field. */
  listDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `listId` field. */
  listId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `listMembers` relation. */
  listMembers?: InputMaybe<FocusListToManyFocusListMemberFilter>;
  /** Some related `listMembers` exist. */
  listMembersExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `listName` field. */
  listName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `membersLimit` field. */
  membersLimit?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FocusListFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FocusListFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userPublicKeyBase58Check` field. */
  userPublicKeyBase58Check?: InputMaybe<StringFilter>;
};

export type FocusListMember = Node & {
  __typename?: 'FocusListMember';
  /** Reads a single `Account` that is related to this `FocusListMember`. */
  account?: Maybe<Account>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  /** Reads a single `FocusList` that is related to this `FocusListMember`. */
  list?: Maybe<FocusList>;
  listId: Scalars['UUID']['output'];
  listMemberPublicKeyBase58Check: Scalars['String']['output'];
};

/**
 * A condition to be used against `FocusListMember` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FocusListMemberCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `listId` field. */
  listId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `listMemberPublicKeyBase58Check` field. */
  listMemberPublicKeyBase58Check?: InputMaybe<Scalars['String']['input']>;
};

export type FocusListMemberFdw = {
  __typename?: 'FocusListMemberFdw';
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  listId?: Maybe<Scalars['UUID']['output']>;
  listMemberPublicKeyBase58Check?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FocusListMemberFdw` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FocusListMemberFdwCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `listId` field. */
  listId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `listMemberPublicKeyBase58Check` field. */
  listMemberPublicKeyBase58Check?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FocusListMemberFdw` object types. All fields are combined with a logical ‘and.’ */
export type FocusListMemberFdwFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FocusListMemberFdwFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `listId` field. */
  listId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `listMemberPublicKeyBase58Check` field. */
  listMemberPublicKeyBase58Check?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FocusListMemberFdwFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FocusListMemberFdwFilter>>;
};

/** A connection to a list of `FocusListMemberFdw` values. */
export type FocusListMemberFdwsConnection = {
  __typename?: 'FocusListMemberFdwsConnection';
  /** A list of edges which contains the `FocusListMemberFdw` and cursor to aid in pagination. */
  edges: Array<FocusListMemberFdwsEdge>;
  /** A list of `FocusListMemberFdw` objects. */
  nodes: Array<Maybe<FocusListMemberFdw>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FocusListMemberFdw` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FocusListMemberFdw` edge in the connection. */
export type FocusListMemberFdwsEdge = {
  __typename?: 'FocusListMemberFdwsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FocusListMemberFdw` at the end of the edge. */
  node?: Maybe<FocusListMemberFdw>;
};

/** Methods to use when ordering `FocusListMemberFdw`. */
export enum FocusListMemberFdwsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ListIdAsc = 'LIST_ID_ASC',
  ListIdDesc = 'LIST_ID_DESC',
  ListMemberPublicKeyBase58CheckAsc = 'LIST_MEMBER_PUBLIC_KEY_BASE58_CHECK_ASC',
  ListMemberPublicKeyBase58CheckDesc = 'LIST_MEMBER_PUBLIC_KEY_BASE58_CHECK_DESC',
  Natural = 'NATURAL',
}

/** A filter to be used against `FocusListMember` object types. All fields are combined with a logical ‘and.’ */
export type FocusListMemberFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FocusListMemberFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `list` relation. */
  list?: InputMaybe<FocusListFilter>;
  /** A related `list` exists. */
  listExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `listId` field. */
  listId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `listMemberPublicKeyBase58Check` field. */
  listMemberPublicKeyBase58Check?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FocusListMemberFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FocusListMemberFilter>>;
};

/** A connection to a list of `FocusListMember` values. */
export type FocusListMembersConnection = {
  __typename?: 'FocusListMembersConnection';
  /** A list of edges which contains the `FocusListMember` and cursor to aid in pagination. */
  edges: Array<FocusListMembersEdge>;
  /** A list of `FocusListMember` objects. */
  nodes: Array<Maybe<FocusListMember>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FocusListMember` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FocusListMember` edge in the connection. */
export type FocusListMembersEdge = {
  __typename?: 'FocusListMembersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FocusListMember` at the end of the edge. */
  node?: Maybe<FocusListMember>;
};

/** Methods to use when ordering `FocusListMember`. */
export enum FocusListMembersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ListIdAsc = 'LIST_ID_ASC',
  ListIdDesc = 'LIST_ID_DESC',
  ListMemberPublicKeyBase58CheckAsc = 'LIST_MEMBER_PUBLIC_KEY_BASE58_CHECK_ASC',
  ListMemberPublicKeyBase58CheckDesc = 'LIST_MEMBER_PUBLIC_KEY_BASE58_CHECK_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** A filter to be used against many `FocusListMember` object types. All fields are combined with a logical ‘and.’ */
export type FocusListToManyFocusListMemberFilter = {
  /** Every related `FocusListMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FocusListMemberFilter>;
  /** No related `FocusListMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FocusListMemberFilter>;
  /** Some related `FocusListMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FocusListMemberFilter>;
};

/** A connection to a list of `FocusList` values. */
export type FocusListsConnection = {
  __typename?: 'FocusListsConnection';
  /** A list of edges which contains the `FocusList` and cursor to aid in pagination. */
  edges: Array<FocusListsEdge>;
  /** A list of `FocusList` objects. */
  nodes: Array<Maybe<FocusList>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FocusList` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FocusList` edge in the connection. */
export type FocusListsEdge = {
  __typename?: 'FocusListsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FocusList` at the end of the edge. */
  node?: Maybe<FocusList>;
};

/** Methods to use when ordering `FocusList`. */
export enum FocusListsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ListDescriptionAsc = 'LIST_DESCRIPTION_ASC',
  ListDescriptionDesc = 'LIST_DESCRIPTION_DESC',
  ListIdAsc = 'LIST_ID_ASC',
  ListIdDesc = 'LIST_ID_DESC',
  ListNameAsc = 'LIST_NAME_ASC',
  ListNameDesc = 'LIST_NAME_DESC',
  MembersLimitAsc = 'MEMBERS_LIMIT_ASC',
  MembersLimitDesc = 'MEMBERS_LIMIT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserPublicKeyBase58CheckAsc = 'USER_PUBLIC_KEY_BASE58_CHECK_ASC',
  UserPublicKeyBase58CheckDesc = 'USER_PUBLIC_KEY_BASE58_CHECK_DESC',
}

export type FocusPrice = {
  __typename?: 'FocusPrice';
  bidDesoNanos?: Maybe<Scalars['BigFloat']['output']>;
  buyingDaoCoinCreatorPkid?: Maybe<Scalars['String']['output']>;
  rowId?: Maybe<Scalars['Int']['output']>;
};

/**
 * A condition to be used against `FocusPrice` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FocusPriceCondition = {
  /** Checks for equality with the object’s `bidDesoNanos` field. */
  bidDesoNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `buyingDaoCoinCreatorPkid` field. */
  buyingDaoCoinCreatorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `FocusPrice` object types. All fields are combined with a logical ‘and.’ */
export type FocusPriceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FocusPriceFilter>>;
  /** Filter by the object’s `bidDesoNanos` field. */
  bidDesoNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `buyingDaoCoinCreatorPkid` field. */
  buyingDaoCoinCreatorPkid?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FocusPriceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FocusPriceFilter>>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<IntFilter>;
};

/** A connection to a list of `FocusPrice` values. */
export type FocusPricesConnection = {
  __typename?: 'FocusPricesConnection';
  /** A list of edges which contains the `FocusPrice` and cursor to aid in pagination. */
  edges: Array<FocusPricesEdge>;
  /** A list of `FocusPrice` objects. */
  nodes: Array<Maybe<FocusPrice>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FocusPrice` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FocusPrice` edge in the connection. */
export type FocusPricesEdge = {
  __typename?: 'FocusPricesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FocusPrice` at the end of the edge. */
  node?: Maybe<FocusPrice>;
};

/** Methods to use when ordering `FocusPrice`. */
export enum FocusPricesOrderBy {
  BidDesoNanosAsc = 'BID_DESO_NANOS_ASC',
  BidDesoNanosDesc = 'BID_DESO_NANOS_DESC',
  BuyingDaoCoinCreatorPkidAsc = 'BUYING_DAO_COIN_CREATOR_PKID_ASC',
  BuyingDaoCoinCreatorPkidDesc = 'BUYING_DAO_COIN_CREATOR_PKID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
}

export type FocusUser = {
  __typename?: 'FocusUser';
  description?: Maybe<Scalars['String']['output']>;
  joinedFocusDate?: Maybe<Scalars['Date']['output']>;
  userPkid?: Maybe<Scalars['String']['output']>;
  userPublicKeyBase58Check?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `FocusUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FocusUserCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `joinedFocusDate` field. */
  joinedFocusDate?: InputMaybe<Scalars['Date']['input']>;
  /** Checks for equality with the object’s `userPkid` field. */
  userPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `userPublicKeyBase58Check` field. */
  userPublicKeyBase58Check?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FocusUser` object types. All fields are combined with a logical ‘and.’ */
export type FocusUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FocusUserFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `joinedFocusDate` field. */
  joinedFocusDate?: InputMaybe<DateFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FocusUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FocusUserFilter>>;
  /** Filter by the object’s `userPkid` field. */
  userPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userPublicKeyBase58Check` field. */
  userPublicKeyBase58Check?: InputMaybe<StringFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** A connection to a list of `FocusUser` values. */
export type FocusUsersConnection = {
  __typename?: 'FocusUsersConnection';
  /** A list of edges which contains the `FocusUser` and cursor to aid in pagination. */
  edges: Array<FocusUsersEdge>;
  /** A list of `FocusUser` objects. */
  nodes: Array<Maybe<FocusUser>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FocusUser` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FocusUser` edge in the connection. */
export type FocusUsersEdge = {
  __typename?: 'FocusUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FocusUser` at the end of the edge. */
  node?: Maybe<FocusUser>;
};

/** Methods to use when ordering `FocusUser`. */
export enum FocusUsersOrderBy {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  JoinedFocusDateAsc = 'JOINED_FOCUS_DATE_ASC',
  JoinedFocusDateDesc = 'JOINED_FOCUS_DATE_DESC',
  Natural = 'NATURAL',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  UserPkidAsc = 'USER_PKID_ASC',
  UserPkidDesc = 'USER_PKID_DESC',
  UserPublicKeyBase58CheckAsc = 'USER_PUBLIC_KEY_BASE58_CHECK_ASC',
  UserPublicKeyBase58CheckDesc = 'USER_PUBLIC_KEY_BASE58_CHECK_DESC',
}

export type Follow = {
  __typename?: 'Follow';
  badgerKey?: Maybe<Scalars['String']['output']>;
  followedDesoBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  followedFocusTokenBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  followedPkid?: Maybe<Scalars['String']['output']>;
  followedTotalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  followedUsdBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  /** Reads a single `Account` that is related to this `Follow`. */
  followee?: Maybe<Account>;
  /** Reads a single `Account` that is related to this `Follow`. */
  follower?: Maybe<Account>;
  followerDesoBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  followerFocusTokenBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  followerPkid?: Maybe<Scalars['String']['output']>;
  followerTotalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  followerUsdBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
};

/** A condition to be used against `Follow` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FollowCondition = {
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `followedDesoBalanceUsdCents` field. */
  followedDesoBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `followedFocusTokenBalanceUsdCents` field. */
  followedFocusTokenBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `followedPkid` field. */
  followedPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `followedTotalBalanceUsdCents` field. */
  followedTotalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `followedUsdBalanceUsdCents` field. */
  followedUsdBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `followerDesoBalanceUsdCents` field. */
  followerDesoBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `followerFocusTokenBalanceUsdCents` field. */
  followerFocusTokenBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `followerPkid` field. */
  followerPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `followerTotalBalanceUsdCents` field. */
  followerTotalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `followerUsdBalanceUsdCents` field. */
  followerUsdBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
};

export type FollowFast = Node & {
  __typename?: 'FollowFast';
  followedPkid: Scalars['String']['output'];
  /** Reads a single `Account` that is related to this `FollowFast`. */
  followeeFast?: Maybe<Account>;
  /** Reads a single `Account` that is related to this `FollowFast`. */
  followerFast?: Maybe<Account>;
  followerPkid: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
};

/**
 * A condition to be used against `FollowFast` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FollowFastCondition = {
  /** Checks for equality with the object’s `followedPkid` field. */
  followedPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `followerPkid` field. */
  followerPkid?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `FollowFast` object types. All fields are combined with a logical ‘and.’ */
export type FollowFastFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FollowFastFilter>>;
  /** Filter by the object’s `followedPkid` field. */
  followedPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `followeeFast` relation. */
  followeeFast?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `followerFast` relation. */
  followerFast?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `followerPkid` field. */
  followerPkid?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FollowFastFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FollowFastFilter>>;
};

/** A connection to a list of `FollowFast` values. */
export type FollowFastsConnection = {
  __typename?: 'FollowFastsConnection';
  /** A list of edges which contains the `FollowFast` and cursor to aid in pagination. */
  edges: Array<FollowFastsEdge>;
  /** A list of `FollowFast` objects. */
  nodes: Array<Maybe<FollowFast>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FollowFast` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `FollowFast` edge in the connection. */
export type FollowFastsEdge = {
  __typename?: 'FollowFastsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `FollowFast` at the end of the edge. */
  node?: Maybe<FollowFast>;
};

/** Methods to use when ordering `FollowFast`. */
export enum FollowFastsOrderBy {
  FollowedPkidAsc = 'FOLLOWED_PKID_ASC',
  FollowedPkidDesc = 'FOLLOWED_PKID_DESC',
  FollowerPkidAsc = 'FOLLOWER_PKID_ASC',
  FollowerPkidDesc = 'FOLLOWER_PKID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** A filter to be used against `Follow` object types. All fields are combined with a logical ‘and.’ */
export type FollowFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FollowFilter>>;
  /** Filter by the object’s `followedDesoBalanceUsdCents` field. */
  followedDesoBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `followedFocusTokenBalanceUsdCents` field. */
  followedFocusTokenBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `followedPkid` field. */
  followedPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `followedTotalBalanceUsdCents` field. */
  followedTotalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `followedUsdBalanceUsdCents` field. */
  followedUsdBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `followee` relation. */
  followee?: InputMaybe<AccountFilter>;
  /** A related `followee` exists. */
  followeeExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `follower` relation. */
  follower?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `followerDesoBalanceUsdCents` field. */
  followerDesoBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** A related `follower` exists. */
  followerExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `followerFocusTokenBalanceUsdCents` field. */
  followerFocusTokenBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `followerPkid` field. */
  followerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `followerTotalBalanceUsdCents` field. */
  followerTotalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `followerUsdBalanceUsdCents` field. */
  followerUsdBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FollowFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FollowFilter>>;
};

/** A connection to a list of `Follow` values. */
export type FollowsConnection = {
  __typename?: 'FollowsConnection';
  /** A list of edges which contains the `Follow` and cursor to aid in pagination. */
  edges: Array<FollowsEdge>;
  /** A list of `Follow` objects. */
  nodes: Array<Maybe<Follow>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Follow` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Follow` edge in the connection. */
export type FollowsEdge = {
  __typename?: 'FollowsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Follow` at the end of the edge. */
  node?: Maybe<Follow>;
};

/** Methods to use when ordering `Follow`. */
export enum FollowsOrderBy {
  BadgerKeyAsc = 'BADGER_KEY_ASC',
  BadgerKeyDesc = 'BADGER_KEY_DESC',
  FollowedDesoBalanceUsdCentsAsc = 'FOLLOWED_DESO_BALANCE_USD_CENTS_ASC',
  FollowedDesoBalanceUsdCentsDesc = 'FOLLOWED_DESO_BALANCE_USD_CENTS_DESC',
  FollowedFocusTokenBalanceUsdCentsAsc = 'FOLLOWED_FOCUS_TOKEN_BALANCE_USD_CENTS_ASC',
  FollowedFocusTokenBalanceUsdCentsDesc = 'FOLLOWED_FOCUS_TOKEN_BALANCE_USD_CENTS_DESC',
  FollowedPkidAsc = 'FOLLOWED_PKID_ASC',
  FollowedPkidDesc = 'FOLLOWED_PKID_DESC',
  FollowedTotalBalanceUsdCentsAsc = 'FOLLOWED_TOTAL_BALANCE_USD_CENTS_ASC',
  FollowedTotalBalanceUsdCentsDesc = 'FOLLOWED_TOTAL_BALANCE_USD_CENTS_DESC',
  FollowedUsdBalanceUsdCentsAsc = 'FOLLOWED_USD_BALANCE_USD_CENTS_ASC',
  FollowedUsdBalanceUsdCentsDesc = 'FOLLOWED_USD_BALANCE_USD_CENTS_DESC',
  FollowerDesoBalanceUsdCentsAsc = 'FOLLOWER_DESO_BALANCE_USD_CENTS_ASC',
  FollowerDesoBalanceUsdCentsDesc = 'FOLLOWER_DESO_BALANCE_USD_CENTS_DESC',
  FollowerFocusTokenBalanceUsdCentsAsc = 'FOLLOWER_FOCUS_TOKEN_BALANCE_USD_CENTS_ASC',
  FollowerFocusTokenBalanceUsdCentsDesc = 'FOLLOWER_FOCUS_TOKEN_BALANCE_USD_CENTS_DESC',
  FollowerPkidAsc = 'FOLLOWER_PKID_ASC',
  FollowerPkidDesc = 'FOLLOWER_PKID_DESC',
  FollowerTotalBalanceUsdCentsAsc = 'FOLLOWER_TOTAL_BALANCE_USD_CENTS_ASC',
  FollowerTotalBalanceUsdCentsDesc = 'FOLLOWER_TOTAL_BALANCE_USD_CENTS_DESC',
  FollowerUsdBalanceUsdCentsAsc = 'FOLLOWER_USD_BALANCE_USD_CENTS_ASC',
  FollowerUsdBalanceUsdCentsDesc = 'FOLLOWER_USD_BALANCE_USD_CENTS_DESC',
  Natural = 'NATURAL',
}

/** All input for the `generateAllFeeds` mutation. */
export type GenerateAllFeedsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `generateAllFeeds` mutation. */
export type GenerateAllFeedsPayload = {
  __typename?: 'GenerateAllFeedsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `generateFeed` mutation. */
export type GenerateFeedInput = {
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  viewerPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `generateFeed` mutation. */
export type GenerateFeedPayload = {
  __typename?: 'GenerateFeedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `getCoinValueUsdCentsV2` mutation. */
export type GetCoinValueUsdCentsV2Input = {
  baseCurrencyPkid?: InputMaybe<Scalars['String']['input']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  desoPkid?: InputMaybe<Scalars['String']['input']>;
  dusdPkid?: InputMaybe<Scalars['String']['input']>;
  focusPkid?: InputMaybe<Scalars['String']['input']>;
  quoteCurrencyPkid?: InputMaybe<Scalars['String']['input']>;
  usdCentsPerDeso?: InputMaybe<Scalars['BigFloat']['input']>;
  usdCentsPerFocus?: InputMaybe<Scalars['BigFloat']['input']>;
  value?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** The output of our `getCoinValueUsdCentsV2` mutation. */
export type GetCoinValueUsdCentsV2Payload = {
  __typename?: 'GetCoinValueUsdCentsV2Payload';
  bigFloat?: Maybe<Scalars['BigFloat']['output']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `getFeedSettings` mutation. */
export type GetFeedSettingsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `getFeedSettings` mutation. */
export type GetFeedSettingsPayload = {
  __typename?: 'GetFeedSettingsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  feedSettings?: Maybe<Array<Maybe<FeedSetting>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `getTransactionCount` mutation. */
export type GetTransactionCountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  transactionType?: InputMaybe<Scalars['Int']['input']>;
};

/** The output of our `getTransactionCount` mutation. */
export type GetTransactionCountPayload = {
  __typename?: 'GetTransactionCountPayload';
  bigInt?: Maybe<Scalars['BigInt']['output']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A connection to a list of `GlobalParamsEntry` values. */
export type GlobalParamsEntriesConnection = {
  __typename?: 'GlobalParamsEntriesConnection';
  /** A list of edges which contains the `GlobalParamsEntry` and cursor to aid in pagination. */
  edges: Array<GlobalParamsEntriesEdge>;
  /** A list of `GlobalParamsEntry` objects. */
  nodes: Array<Maybe<GlobalParamsEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GlobalParamsEntry` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `GlobalParamsEntry` edge in the connection. */
export type GlobalParamsEntriesEdge = {
  __typename?: 'GlobalParamsEntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `GlobalParamsEntry` at the end of the edge. */
  node?: Maybe<GlobalParamsEntry>;
};

/** Methods to use when ordering `GlobalParamsEntry`. */
export enum GlobalParamsEntriesOrderBy {
  BlockProductionIntervalMillisecondsPosAsc = 'BLOCK_PRODUCTION_INTERVAL_MILLISECONDS_POS_ASC',
  BlockProductionIntervalMillisecondsPosDesc = 'BLOCK_PRODUCTION_INTERVAL_MILLISECONDS_POS_DESC',
  BlockTimestampDriftNanoSecsAsc = 'BLOCK_TIMESTAMP_DRIFT_NANO_SECS_ASC',
  BlockTimestampDriftNanoSecsDesc = 'BLOCK_TIMESTAMP_DRIFT_NANO_SECS_DESC',
  CreateNftFeeNanosAsc = 'CREATE_NFT_FEE_NANOS_ASC',
  CreateNftFeeNanosDesc = 'CREATE_NFT_FEE_NANOS_DESC',
  CreateProfileFeeNanosAsc = 'CREATE_PROFILE_FEE_NANOS_ASC',
  CreateProfileFeeNanosDesc = 'CREATE_PROFILE_FEE_NANOS_DESC',
  EpochDurationNumBlocksAsc = 'EPOCH_DURATION_NUM_BLOCKS_ASC',
  EpochDurationNumBlocksDesc = 'EPOCH_DURATION_NUM_BLOCKS_DESC',
  FeeBucketGrowthRateBasisPointsAsc = 'FEE_BUCKET_GROWTH_RATE_BASIS_POINTS_ASC',
  FeeBucketGrowthRateBasisPointsDesc = 'FEE_BUCKET_GROWTH_RATE_BASIS_POINTS_DESC',
  JailInactiveValidatorGracePeriodEpochsAsc = 'JAIL_INACTIVE_VALIDATOR_GRACE_PERIOD_EPOCHS_ASC',
  JailInactiveValidatorGracePeriodEpochsDesc = 'JAIL_INACTIVE_VALIDATOR_GRACE_PERIOD_EPOCHS_DESC',
  LeaderScheduleMaxNumValidatorsAsc = 'LEADER_SCHEDULE_MAX_NUM_VALIDATORS_ASC',
  LeaderScheduleMaxNumValidatorsDesc = 'LEADER_SCHEDULE_MAX_NUM_VALIDATORS_DESC',
  MaximumVestedIntersectionsPerLockupTransactionAsc = 'MAXIMUM_VESTED_INTERSECTIONS_PER_LOCKUP_TRANSACTION_ASC',
  MaximumVestedIntersectionsPerLockupTransactionDesc = 'MAXIMUM_VESTED_INTERSECTIONS_PER_LOCKUP_TRANSACTION_DESC',
  MaxBlockSizeBytesPosAsc = 'MAX_BLOCK_SIZE_BYTES_POS_ASC',
  MaxBlockSizeBytesPosDesc = 'MAX_BLOCK_SIZE_BYTES_POS_DESC',
  MaxCopiesPerNftAsc = 'MAX_COPIES_PER_NFT_ASC',
  MaxCopiesPerNftDesc = 'MAX_COPIES_PER_NFT_DESC',
  MaxNonceExpirationBlockHeightOffsetAsc = 'MAX_NONCE_EXPIRATION_BLOCK_HEIGHT_OFFSET_ASC',
  MaxNonceExpirationBlockHeightOffsetDesc = 'MAX_NONCE_EXPIRATION_BLOCK_HEIGHT_OFFSET_DESC',
  MaxTxnSizeBytesPosAsc = 'MAX_TXN_SIZE_BYTES_POS_ASC',
  MaxTxnSizeBytesPosDesc = 'MAX_TXN_SIZE_BYTES_POS_DESC',
  MempoolFeeEstimatorNumMempoolBlocksAsc = 'MEMPOOL_FEE_ESTIMATOR_NUM_MEMPOOL_BLOCKS_ASC',
  MempoolFeeEstimatorNumMempoolBlocksDesc = 'MEMPOOL_FEE_ESTIMATOR_NUM_MEMPOOL_BLOCKS_DESC',
  MempoolFeeEstimatorNumPastBlocksAsc = 'MEMPOOL_FEE_ESTIMATOR_NUM_PAST_BLOCKS_ASC',
  MempoolFeeEstimatorNumPastBlocksDesc = 'MEMPOOL_FEE_ESTIMATOR_NUM_PAST_BLOCKS_DESC',
  MempoolMaxSizeBytesAsc = 'MEMPOOL_MAX_SIZE_BYTES_ASC',
  MempoolMaxSizeBytesDesc = 'MEMPOOL_MAX_SIZE_BYTES_DESC',
  MinimumNetworkFeeNanosPerKbAsc = 'MINIMUM_NETWORK_FEE_NANOS_PER_KB_ASC',
  MinimumNetworkFeeNanosPerKbDesc = 'MINIMUM_NETWORK_FEE_NANOS_PER_KB_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SoftMaxBlockSizeBytesPosAsc = 'SOFT_MAX_BLOCK_SIZE_BYTES_POS_ASC',
  SoftMaxBlockSizeBytesPosDesc = 'SOFT_MAX_BLOCK_SIZE_BYTES_POS_DESC',
  StakeLockupEpochDurationAsc = 'STAKE_LOCKUP_EPOCH_DURATION_ASC',
  StakeLockupEpochDurationDesc = 'STAKE_LOCKUP_EPOCH_DURATION_DESC',
  StakingRewardsApyBasisPointsAsc = 'STAKING_REWARDS_APY_BASIS_POINTS_ASC',
  StakingRewardsApyBasisPointsDesc = 'STAKING_REWARDS_APY_BASIS_POINTS_DESC',
  StakingRewardsMaxNumStakesAsc = 'STAKING_REWARDS_MAX_NUM_STAKES_ASC',
  StakingRewardsMaxNumStakesDesc = 'STAKING_REWARDS_MAX_NUM_STAKES_DESC',
  TimeoutIntervalMillisecondsPosAsc = 'TIMEOUT_INTERVAL_MILLISECONDS_POS_ASC',
  TimeoutIntervalMillisecondsPosDesc = 'TIMEOUT_INTERVAL_MILLISECONDS_POS_DESC',
  UsdCentsPerBitcoinAsc = 'USD_CENTS_PER_BITCOIN_ASC',
  UsdCentsPerBitcoinDesc = 'USD_CENTS_PER_BITCOIN_DESC',
  ValidatorJailEpochDurationAsc = 'VALIDATOR_JAIL_EPOCH_DURATION_ASC',
  ValidatorJailEpochDurationDesc = 'VALIDATOR_JAIL_EPOCH_DURATION_DESC',
  ValidatorSetMaxNumValidatorsAsc = 'VALIDATOR_SET_MAX_NUM_VALIDATORS_ASC',
  ValidatorSetMaxNumValidatorsDesc = 'VALIDATOR_SET_MAX_NUM_VALIDATORS_DESC',
}

export type GlobalParamsEntry = Node & {
  __typename?: 'GlobalParamsEntry';
  blockProductionIntervalMillisecondsPos: Scalars['BigInt']['output'];
  blockTimestampDriftNanoSecs: Scalars['BigInt']['output'];
  createNftFeeNanos: Scalars['BigInt']['output'];
  createProfileFeeNanos: Scalars['BigInt']['output'];
  epochDurationNumBlocks: Scalars['BigInt']['output'];
  feeBucketGrowthRateBasisPoints: Scalars['BigInt']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  jailInactiveValidatorGracePeriodEpochs: Scalars['BigInt']['output'];
  leaderScheduleMaxNumValidators: Scalars['BigInt']['output'];
  maxBlockSizeBytesPos: Scalars['BigInt']['output'];
  maxCopiesPerNft: Scalars['BigInt']['output'];
  maxNonceExpirationBlockHeightOffset: Scalars['BigInt']['output'];
  maxTxnSizeBytesPos: Scalars['BigInt']['output'];
  maximumVestedIntersectionsPerLockupTransaction: Scalars['Int']['output'];
  mempoolFeeEstimatorNumMempoolBlocks: Scalars['BigInt']['output'];
  mempoolFeeEstimatorNumPastBlocks: Scalars['BigInt']['output'];
  mempoolMaxSizeBytes: Scalars['BigInt']['output'];
  minimumNetworkFeeNanosPerKb: Scalars['BigInt']['output'];
  softMaxBlockSizeBytesPos: Scalars['BigInt']['output'];
  stakeLockupEpochDuration: Scalars['BigInt']['output'];
  stakingRewardsApyBasisPoints: Scalars['BigInt']['output'];
  stakingRewardsMaxNumStakes: Scalars['BigInt']['output'];
  timeoutIntervalMillisecondsPos: Scalars['BigInt']['output'];
  usdCentsPerBitcoin: Scalars['BigInt']['output'];
  validatorJailEpochDuration: Scalars['BigInt']['output'];
  validatorSetMaxNumValidators: Scalars['BigInt']['output'];
};

/**
 * A condition to be used against `GlobalParamsEntry` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GlobalParamsEntryCondition = {
  /** Checks for equality with the object’s `blockProductionIntervalMillisecondsPos` field. */
  blockProductionIntervalMillisecondsPos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `blockTimestampDriftNanoSecs` field. */
  blockTimestampDriftNanoSecs?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `createNftFeeNanos` field. */
  createNftFeeNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `createProfileFeeNanos` field. */
  createProfileFeeNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `epochDurationNumBlocks` field. */
  epochDurationNumBlocks?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `feeBucketGrowthRateBasisPoints` field. */
  feeBucketGrowthRateBasisPoints?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `jailInactiveValidatorGracePeriodEpochs` field. */
  jailInactiveValidatorGracePeriodEpochs?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `leaderScheduleMaxNumValidators` field. */
  leaderScheduleMaxNumValidators?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `maxBlockSizeBytesPos` field. */
  maxBlockSizeBytesPos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `maxCopiesPerNft` field. */
  maxCopiesPerNft?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `maxNonceExpirationBlockHeightOffset` field. */
  maxNonceExpirationBlockHeightOffset?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `maxTxnSizeBytesPos` field. */
  maxTxnSizeBytesPos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `maximumVestedIntersectionsPerLockupTransaction` field. */
  maximumVestedIntersectionsPerLockupTransaction?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `mempoolFeeEstimatorNumMempoolBlocks` field. */
  mempoolFeeEstimatorNumMempoolBlocks?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `mempoolFeeEstimatorNumPastBlocks` field. */
  mempoolFeeEstimatorNumPastBlocks?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `mempoolMaxSizeBytes` field. */
  mempoolMaxSizeBytes?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `minimumNetworkFeeNanosPerKb` field. */
  minimumNetworkFeeNanosPerKb?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `softMaxBlockSizeBytesPos` field. */
  softMaxBlockSizeBytesPos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `stakeLockupEpochDuration` field. */
  stakeLockupEpochDuration?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `stakingRewardsApyBasisPoints` field. */
  stakingRewardsApyBasisPoints?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `stakingRewardsMaxNumStakes` field. */
  stakingRewardsMaxNumStakes?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `timeoutIntervalMillisecondsPos` field. */
  timeoutIntervalMillisecondsPos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `usdCentsPerBitcoin` field. */
  usdCentsPerBitcoin?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `validatorJailEpochDuration` field. */
  validatorJailEpochDuration?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `validatorSetMaxNumValidators` field. */
  validatorSetMaxNumValidators?: InputMaybe<Scalars['BigInt']['input']>;
};

/** A filter to be used against `GlobalParamsEntry` object types. All fields are combined with a logical ‘and.’ */
export type GlobalParamsEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GlobalParamsEntryFilter>>;
  /** Filter by the object’s `blockProductionIntervalMillisecondsPos` field. */
  blockProductionIntervalMillisecondsPos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `blockTimestampDriftNanoSecs` field. */
  blockTimestampDriftNanoSecs?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `createNftFeeNanos` field. */
  createNftFeeNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `createProfileFeeNanos` field. */
  createProfileFeeNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `epochDurationNumBlocks` field. */
  epochDurationNumBlocks?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `feeBucketGrowthRateBasisPoints` field. */
  feeBucketGrowthRateBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `jailInactiveValidatorGracePeriodEpochs` field. */
  jailInactiveValidatorGracePeriodEpochs?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `leaderScheduleMaxNumValidators` field. */
  leaderScheduleMaxNumValidators?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `maxBlockSizeBytesPos` field. */
  maxBlockSizeBytesPos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `maxCopiesPerNft` field. */
  maxCopiesPerNft?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `maxNonceExpirationBlockHeightOffset` field. */
  maxNonceExpirationBlockHeightOffset?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `maxTxnSizeBytesPos` field. */
  maxTxnSizeBytesPos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `maximumVestedIntersectionsPerLockupTransaction` field. */
  maximumVestedIntersectionsPerLockupTransaction?: InputMaybe<IntFilter>;
  /** Filter by the object’s `mempoolFeeEstimatorNumMempoolBlocks` field. */
  mempoolFeeEstimatorNumMempoolBlocks?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `mempoolFeeEstimatorNumPastBlocks` field. */
  mempoolFeeEstimatorNumPastBlocks?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `mempoolMaxSizeBytes` field. */
  mempoolMaxSizeBytes?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `minimumNetworkFeeNanosPerKb` field. */
  minimumNetworkFeeNanosPerKb?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GlobalParamsEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GlobalParamsEntryFilter>>;
  /** Filter by the object’s `softMaxBlockSizeBytesPos` field. */
  softMaxBlockSizeBytesPos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `stakeLockupEpochDuration` field. */
  stakeLockupEpochDuration?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `stakingRewardsApyBasisPoints` field. */
  stakingRewardsApyBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `stakingRewardsMaxNumStakes` field. */
  stakingRewardsMaxNumStakes?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `timeoutIntervalMillisecondsPos` field. */
  timeoutIntervalMillisecondsPos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `usdCentsPerBitcoin` field. */
  usdCentsPerBitcoin?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `validatorJailEpochDuration` field. */
  validatorJailEpochDuration?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `validatorSetMaxNumValidators` field. */
  validatorSetMaxNumValidators?: InputMaybe<BigIntFilter>;
};

export type GlobalVal = Node & {
  __typename?: 'GlobalVal';
  ammServicePkid: Scalars['String']['output'];
  /** Reads a single `Account` that is related to this `GlobalVal`. */
  desoToken?: Maybe<Account>;
  desoTokenPkid: Scalars['String']['output'];
  /** Reads a single `Account` that is related to this `GlobalVal`. */
  dusdToken?: Maybe<Account>;
  dusdTokenPkid: Scalars['String']['output'];
  focusFloorPriceDesoNanos?: Maybe<Scalars['BigFloat']['output']>;
  /** Reads a single `Account` that is related to this `GlobalVal`. */
  focusToken?: Maybe<Account>;
  focusTokenPkid: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  maxPostContributionWealthUsdCents: Scalars['Int']['output'];
  rowId: Scalars['Int']['output'];
  usdCentsPerDeso: Scalars['Int']['output'];
  usdCentsPerFocus?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `GlobalVal` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type GlobalValCondition = {
  /** Checks for equality with the object’s `ammServicePkid` field. */
  ammServicePkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `desoTokenPkid` field. */
  desoTokenPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `dusdTokenPkid` field. */
  dusdTokenPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `focusFloorPriceDesoNanos` field. */
  focusFloorPriceDesoNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `focusTokenPkid` field. */
  focusTokenPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `maxPostContributionWealthUsdCents` field. */
  maxPostContributionWealthUsdCents?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `usdCentsPerDeso` field. */
  usdCentsPerDeso?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `usdCentsPerFocus` field. */
  usdCentsPerFocus?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `GlobalVal` object types. All fields are combined with a logical ‘and.’ */
export type GlobalValFilter = {
  /** Filter by the object’s `ammServicePkid` field. */
  ammServicePkid?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GlobalValFilter>>;
  /** Filter by the object’s `desoToken` relation. */
  desoToken?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `desoTokenPkid` field. */
  desoTokenPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dusdToken` relation. */
  dusdToken?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `dusdTokenPkid` field. */
  dusdTokenPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `focusFloorPriceDesoNanos` field. */
  focusFloorPriceDesoNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `focusToken` relation. */
  focusToken?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `focusTokenPkid` field. */
  focusTokenPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `maxPostContributionWealthUsdCents` field. */
  maxPostContributionWealthUsdCents?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GlobalValFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GlobalValFilter>>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `usdCentsPerDeso` field. */
  usdCentsPerDeso?: InputMaybe<IntFilter>;
  /** Filter by the object’s `usdCentsPerFocus` field. */
  usdCentsPerFocus?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `GlobalVal` values. */
export type GlobalValsConnection = {
  __typename?: 'GlobalValsConnection';
  /** A list of edges which contains the `GlobalVal` and cursor to aid in pagination. */
  edges: Array<GlobalValsEdge>;
  /** A list of `GlobalVal` objects. */
  nodes: Array<Maybe<GlobalVal>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GlobalVal` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `GlobalVal` edge in the connection. */
export type GlobalValsEdge = {
  __typename?: 'GlobalValsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `GlobalVal` at the end of the edge. */
  node?: Maybe<GlobalVal>;
};

/** Methods to use when ordering `GlobalVal`. */
export enum GlobalValsOrderBy {
  AmmServicePkidAsc = 'AMM_SERVICE_PKID_ASC',
  AmmServicePkidDesc = 'AMM_SERVICE_PKID_DESC',
  DesoTokenPkidAsc = 'DESO_TOKEN_PKID_ASC',
  DesoTokenPkidDesc = 'DESO_TOKEN_PKID_DESC',
  DusdTokenPkidAsc = 'DUSD_TOKEN_PKID_ASC',
  DusdTokenPkidDesc = 'DUSD_TOKEN_PKID_DESC',
  FocusFloorPriceDesoNanosAsc = 'FOCUS_FLOOR_PRICE_DESO_NANOS_ASC',
  FocusFloorPriceDesoNanosDesc = 'FOCUS_FLOOR_PRICE_DESO_NANOS_DESC',
  FocusTokenPkidAsc = 'FOCUS_TOKEN_PKID_ASC',
  FocusTokenPkidDesc = 'FOCUS_TOKEN_PKID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MaxPostContributionWealthUsdCentsAsc = 'MAX_POST_CONTRIBUTION_WEALTH_USD_CENTS_ASC',
  MaxPostContributionWealthUsdCentsDesc = 'MAX_POST_CONTRIBUTION_WEALTH_USD_CENTS_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UsdCentsPerDesoAsc = 'USD_CENTS_PER_DESO_ASC',
  UsdCentsPerDesoDesc = 'USD_CENTS_PER_DESO_DESC',
  UsdCentsPerFocusAsc = 'USD_CENTS_PER_FOCUS_ASC',
  UsdCentsPerFocusDesc = 'USD_CENTS_PER_FOCUS_DESC',
}

/** All input for the `insertFeedInteractionsAll` mutation. */
export type InsertFeedInteractionsAllInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsAll` mutation. */
export type InsertFeedInteractionsAllPayload = {
  __typename?: 'InsertFeedInteractionsAllPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsAllV2` mutation. */
export type InsertFeedInteractionsAllV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsAllV2` mutation. */
export type InsertFeedInteractionsAllV2Payload = {
  __typename?: 'InsertFeedInteractionsAllV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsBlock` mutation. */
export type InsertFeedInteractionsBlockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsBlock` mutation. */
export type InsertFeedInteractionsBlockPayload = {
  __typename?: 'InsertFeedInteractionsBlockPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsBlockV2` mutation. */
export type InsertFeedInteractionsBlockV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsBlockV2` mutation. */
export type InsertFeedInteractionsBlockV2Payload = {
  __typename?: 'InsertFeedInteractionsBlockV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsFocusFollow` mutation. */
export type InsertFeedInteractionsFocusFollowInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsFocusFollow` mutation. */
export type InsertFeedInteractionsFocusFollowPayload = {
  __typename?: 'InsertFeedInteractionsFocusFollowPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsFollow` mutation. */
export type InsertFeedInteractionsFollowInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsFollow` mutation. */
export type InsertFeedInteractionsFollowPayload = {
  __typename?: 'InsertFeedInteractionsFollowPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsFollowV2` mutation. */
export type InsertFeedInteractionsFollowV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsFollowV2` mutation. */
export type InsertFeedInteractionsFollowV2Payload = {
  __typename?: 'InsertFeedInteractionsFollowV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsNegativeReaction` mutation. */
export type InsertFeedInteractionsNegativeReactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsNegativeReaction` mutation. */
export type InsertFeedInteractionsNegativeReactionPayload = {
  __typename?: 'InsertFeedInteractionsNegativeReactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsNegativeReactionV2` mutation. */
export type InsertFeedInteractionsNegativeReactionV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsNegativeReactionV2` mutation. */
export type InsertFeedInteractionsNegativeReactionV2Payload = {
  __typename?: 'InsertFeedInteractionsNegativeReactionV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsNeutralReaction` mutation. */
export type InsertFeedInteractionsNeutralReactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsNeutralReaction` mutation. */
export type InsertFeedInteractionsNeutralReactionPayload = {
  __typename?: 'InsertFeedInteractionsNeutralReactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsNeutralReactionV2` mutation. */
export type InsertFeedInteractionsNeutralReactionV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsNeutralReactionV2` mutation. */
export type InsertFeedInteractionsNeutralReactionV2Payload = {
  __typename?: 'InsertFeedInteractionsNeutralReactionV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsNewPostsV2` mutation. */
export type InsertFeedInteractionsNewPostsV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsNewPostsV2` mutation. */
export type InsertFeedInteractionsNewPostsV2Payload = {
  __typename?: 'InsertFeedInteractionsNewPostsV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsPositiveReaction` mutation. */
export type InsertFeedInteractionsPositiveReactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsPositiveReaction` mutation. */
export type InsertFeedInteractionsPositiveReactionPayload = {
  __typename?: 'InsertFeedInteractionsPositiveReactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsPositiveReactionV2` mutation. */
export type InsertFeedInteractionsPositiveReactionV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsPositiveReactionV2` mutation. */
export type InsertFeedInteractionsPositiveReactionV2Payload = {
  __typename?: 'InsertFeedInteractionsPositiveReactionV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsQuote` mutation. */
export type InsertFeedInteractionsQuoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsQuote` mutation. */
export type InsertFeedInteractionsQuotePayload = {
  __typename?: 'InsertFeedInteractionsQuotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsQuoteV2` mutation. */
export type InsertFeedInteractionsQuoteV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsQuoteV2` mutation. */
export type InsertFeedInteractionsQuoteV2Payload = {
  __typename?: 'InsertFeedInteractionsQuoteV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsReply` mutation. */
export type InsertFeedInteractionsReplyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsReply` mutation. */
export type InsertFeedInteractionsReplyPayload = {
  __typename?: 'InsertFeedInteractionsReplyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsReplyV2` mutation. */
export type InsertFeedInteractionsReplyV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsReplyV2` mutation. */
export type InsertFeedInteractionsReplyV2Payload = {
  __typename?: 'InsertFeedInteractionsReplyV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsReport` mutation. */
export type InsertFeedInteractionsReportInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsReport` mutation. */
export type InsertFeedInteractionsReportPayload = {
  __typename?: 'InsertFeedInteractionsReportPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsReportV2` mutation. */
export type InsertFeedInteractionsReportV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsReportV2` mutation. */
export type InsertFeedInteractionsReportV2Payload = {
  __typename?: 'InsertFeedInteractionsReportV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsRepost` mutation. */
export type InsertFeedInteractionsRepostInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsRepost` mutation. */
export type InsertFeedInteractionsRepostPayload = {
  __typename?: 'InsertFeedInteractionsRepostPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsRepostV2` mutation. */
export type InsertFeedInteractionsRepostV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsRepostV2` mutation. */
export type InsertFeedInteractionsRepostV2Payload = {
  __typename?: 'InsertFeedInteractionsRepostV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsShowLess` mutation. */
export type InsertFeedInteractionsShowLessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsShowLess` mutation. */
export type InsertFeedInteractionsShowLessPayload = {
  __typename?: 'InsertFeedInteractionsShowLessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsShowLessV2` mutation. */
export type InsertFeedInteractionsShowLessV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsShowLessV2` mutation. */
export type InsertFeedInteractionsShowLessV2Payload = {
  __typename?: 'InsertFeedInteractionsShowLessV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsTip` mutation. */
export type InsertFeedInteractionsTipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsTip` mutation. */
export type InsertFeedInteractionsTipPayload = {
  __typename?: 'InsertFeedInteractionsTipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertFeedInteractionsTipV2` mutation. */
export type InsertFeedInteractionsTipV2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `insertFeedInteractionsTipV2` mutation. */
export type InsertFeedInteractionsTipV2Payload = {
  __typename?: 'InsertFeedInteractionsTipV2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type InsertInteractionsUpdate = Node & {
  __typename?: 'InsertInteractionsUpdate';
  blockHeight?: Maybe<Scalars['BigInt']['output']>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  rowId: Scalars['Int']['output'];
};

/**
 * A condition to be used against `InsertInteractionsUpdate` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type InsertInteractionsUpdateCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `InsertInteractionsUpdate` object types. All fields are combined with a logical ‘and.’ */
export type InsertInteractionsUpdateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InsertInteractionsUpdateFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InsertInteractionsUpdateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InsertInteractionsUpdateFilter>>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<IntFilter>;
};

/** A connection to a list of `InsertInteractionsUpdate` values. */
export type InsertInteractionsUpdatesConnection = {
  __typename?: 'InsertInteractionsUpdatesConnection';
  /** A list of edges which contains the `InsertInteractionsUpdate` and cursor to aid in pagination. */
  edges: Array<InsertInteractionsUpdatesEdge>;
  /** A list of `InsertInteractionsUpdate` objects. */
  nodes: Array<Maybe<InsertInteractionsUpdate>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InsertInteractionsUpdate` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `InsertInteractionsUpdate` edge in the connection. */
export type InsertInteractionsUpdatesEdge = {
  __typename?: 'InsertInteractionsUpdatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `InsertInteractionsUpdate` at the end of the edge. */
  node?: Maybe<InsertInteractionsUpdate>;
};

/** Methods to use when ordering `InsertInteractionsUpdate`. */
export enum InsertInteractionsUpdatesOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** All input for the `intToBytea` mutation. */
export type IntToByteaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  i?: InputMaybe<Scalars['Int']['input']>;
};

/** The output of our `intToBytea` mutation. */
export type IntToByteaPayload = {
  __typename?: 'IntToByteaPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  string?: Maybe<Scalars['String']['output']>;
};

export enum InteractionType {
  Block = 'BLOCK',
  FocusFollow = 'FOCUS_FOLLOW',
  Follow = 'FOLLOW',
  NegativeReaction = 'NEGATIVE_REACTION',
  NeutralReaction = 'NEUTRAL_REACTION',
  PositiveReaction = 'POSITIVE_REACTION',
  Quote = 'QUOTE',
  Reply = 'REPLY',
  Report = 'REPORT',
  Repost = 'REPOST',
  ShowLess = 'SHOW_LESS',
  Tip = 'TIP',
}

/** A filter to be used against InteractionType fields. All fields are combined with a logical ‘and.’ */
export type InteractionTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<InteractionType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<InteractionType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<InteractionType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<InteractionType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<InteractionType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<InteractionType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<InteractionType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<InteractionType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<InteractionType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<InteractionType>>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars['JSON']['input']>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars['JSON']['input']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['JSON']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['JSON']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['JSON']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['JSON']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['JSON']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export type JailedHistoryEvent = Node & {
  __typename?: 'JailedHistoryEvent';
  /** Reads a single `Account` that is related to this `JailedHistoryEvent`. */
  account?: Maybe<Account>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  jailedAtEpochNumber: Scalars['BigInt']['output'];
  unjailedAtEpochNumber: Scalars['BigInt']['output'];
  /** Reads a single `ValidatorEntry` that is related to this `JailedHistoryEvent`. */
  validatorEntry?: Maybe<ValidatorEntry>;
  validatorPkid: Scalars['String']['output'];
};

/**
 * A condition to be used against `JailedHistoryEvent` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type JailedHistoryEventCondition = {
  /** Checks for equality with the object’s `jailedAtEpochNumber` field. */
  jailedAtEpochNumber?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `unjailedAtEpochNumber` field. */
  unjailedAtEpochNumber?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `JailedHistoryEvent` object types. All fields are combined with a logical ‘and.’ */
export type JailedHistoryEventFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<JailedHistoryEventFilter>>;
  /** Filter by the object’s `jailedAtEpochNumber` field. */
  jailedAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<JailedHistoryEventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<JailedHistoryEventFilter>>;
  /** Filter by the object’s `unjailedAtEpochNumber` field. */
  unjailedAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `validatorEntry` relation. */
  validatorEntry?: InputMaybe<ValidatorEntryFilter>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
};

/** A connection to a list of `JailedHistoryEvent` values. */
export type JailedHistoryEventsConnection = {
  __typename?: 'JailedHistoryEventsConnection';
  /** A list of edges which contains the `JailedHistoryEvent` and cursor to aid in pagination. */
  edges: Array<JailedHistoryEventsEdge>;
  /** A list of `JailedHistoryEvent` objects. */
  nodes: Array<Maybe<JailedHistoryEvent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `JailedHistoryEvent` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `JailedHistoryEvent` edge in the connection. */
export type JailedHistoryEventsEdge = {
  __typename?: 'JailedHistoryEventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `JailedHistoryEvent` at the end of the edge. */
  node?: Maybe<JailedHistoryEvent>;
};

/** Methods to use when ordering `JailedHistoryEvent`. */
export enum JailedHistoryEventsOrderBy {
  JailedAtEpochNumberAsc = 'JAILED_AT_EPOCH_NUMBER_ASC',
  JailedAtEpochNumberDesc = 'JAILED_AT_EPOCH_NUMBER_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UnjailedAtEpochNumberAsc = 'UNJAILED_AT_EPOCH_NUMBER_ASC',
  UnjailedAtEpochNumberDesc = 'UNJAILED_AT_EPOCH_NUMBER_DESC',
  ValidatorPkidAsc = 'VALIDATOR_PKID_ASC',
  ValidatorPkidDesc = 'VALIDATOR_PKID_DESC',
}

/** All input for the `jsonbToBytea` mutation. */
export type JsonbToByteaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  j?: InputMaybe<Scalars['JSON']['input']>;
};

/** The output of our `jsonbToBytea` mutation. */
export type JsonbToByteaPayload = {
  __typename?: 'JsonbToByteaPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  string?: Maybe<Scalars['String']['output']>;
};

export type LatestAffectedKey = Node & {
  __typename?: 'LatestAffectedKey';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  publicKey: Scalars['String']['output'];
};

/**
 * A condition to be used against `LatestAffectedKey` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LatestAffectedKeyCondition = {
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `LatestAffectedKey` object types. All fields are combined with a logical ‘and.’ */
export type LatestAffectedKeyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LatestAffectedKeyFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<LatestAffectedKeyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LatestAffectedKeyFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `LatestAffectedKey` values. */
export type LatestAffectedKeysConnection = {
  __typename?: 'LatestAffectedKeysConnection';
  /** A list of edges which contains the `LatestAffectedKey` and cursor to aid in pagination. */
  edges: Array<LatestAffectedKeysEdge>;
  /** A list of `LatestAffectedKey` objects. */
  nodes: Array<Maybe<LatestAffectedKey>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LatestAffectedKey` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `LatestAffectedKey` edge in the connection. */
export type LatestAffectedKeysEdge = {
  __typename?: 'LatestAffectedKeysEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `LatestAffectedKey` at the end of the edge. */
  node?: Maybe<LatestAffectedKey>;
};

/** Methods to use when ordering `LatestAffectedKey`. */
export enum LatestAffectedKeysOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
}

/** A connection to a list of `LeaderScheduleEntry` values. */
export type LeaderScheduleEntriesConnection = {
  __typename?: 'LeaderScheduleEntriesConnection';
  /** A list of edges which contains the `LeaderScheduleEntry` and cursor to aid in pagination. */
  edges: Array<LeaderScheduleEntriesEdge>;
  /** A list of `LeaderScheduleEntry` objects. */
  nodes: Array<Maybe<LeaderScheduleEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LeaderScheduleEntry` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `LeaderScheduleEntry` edge in the connection. */
export type LeaderScheduleEntriesEdge = {
  __typename?: 'LeaderScheduleEntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `LeaderScheduleEntry` at the end of the edge. */
  node?: Maybe<LeaderScheduleEntry>;
};

/** Methods to use when ordering `LeaderScheduleEntry`. */
export enum LeaderScheduleEntriesOrderBy {
  LeaderIndexAsc = 'LEADER_INDEX_ASC',
  LeaderIndexDesc = 'LEADER_INDEX_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SnapshotAtEpochNumberAsc = 'SNAPSHOT_AT_EPOCH_NUMBER_ASC',
  SnapshotAtEpochNumberDesc = 'SNAPSHOT_AT_EPOCH_NUMBER_DESC',
  ValidatorPkidAsc = 'VALIDATOR_PKID_ASC',
  ValidatorPkidDesc = 'VALIDATOR_PKID_DESC',
}

export type LeaderScheduleEntry = Node & {
  __typename?: 'LeaderScheduleEntry';
  /** Reads a single `EpochEntry` that is related to this `LeaderScheduleEntry`. */
  epochEntryBySnapshot?: Maybe<EpochEntry>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  /** Reads a single `Account` that is related to this `LeaderScheduleEntry`. */
  leaderAccount?: Maybe<Account>;
  leaderIndex: Scalars['Int']['output'];
  snapshotAtEpochNumber: Scalars['BigInt']['output'];
  /** Reads a single `ValidatorEntry` that is related to this `LeaderScheduleEntry`. */
  validatorEntry?: Maybe<ValidatorEntry>;
  validatorPkid: Scalars['String']['output'];
};

/**
 * A condition to be used against `LeaderScheduleEntry` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LeaderScheduleEntryCondition = {
  /** Checks for equality with the object’s `leaderIndex` field. */
  leaderIndex?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `LeaderScheduleEntry` object types. All fields are combined with a logical ‘and.’ */
export type LeaderScheduleEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LeaderScheduleEntryFilter>>;
  /** Filter by the object’s `epochEntryBySnapshot` relation. */
  epochEntryBySnapshot?: InputMaybe<EpochEntryFilter>;
  /** Filter by the object’s `leaderAccount` relation. */
  leaderAccount?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `leaderIndex` field. */
  leaderIndex?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LeaderScheduleEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LeaderScheduleEntryFilter>>;
  /** Filter by the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `validatorEntry` relation. */
  validatorEntry?: InputMaybe<ValidatorEntryFilter>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
};

export type LegacyMessage = Node & {
  __typename?: 'LegacyMessage';
  encryptedText: Scalars['String']['output'];
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  /** Reads a single `Account` that is related to this `LegacyMessage`. */
  receiver?: Maybe<Account>;
  recipientMessagingGroupKeyName?: Maybe<Scalars['String']['output']>;
  recipientMessagingPublicKey?: Maybe<Scalars['String']['output']>;
  recipientPublicKey: Scalars['String']['output'];
  /** Reads a single `Account` that is related to this `LegacyMessage`. */
  sender?: Maybe<Account>;
  senderMessagingGroupKeyName?: Maybe<Scalars['String']['output']>;
  senderMessagingPublicKey?: Maybe<Scalars['String']['output']>;
  senderPublicKey: Scalars['String']['output'];
  timestamp?: Maybe<Scalars['Datetime']['output']>;
  version: Scalars['Int']['output'];
};

/**
 * A condition to be used against `LegacyMessage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LegacyMessageCondition = {
  /** Checks for equality with the object’s `encryptedText` field. */
  encryptedText?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `recipientMessagingGroupKeyName` field. */
  recipientMessagingGroupKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `recipientMessagingPublicKey` field. */
  recipientMessagingPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `recipientPublicKey` field. */
  recipientPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `senderMessagingGroupKeyName` field. */
  senderMessagingGroupKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `senderMessagingPublicKey` field. */
  senderMessagingPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `senderPublicKey` field. */
  senderPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `LegacyMessage` object types. All fields are combined with a logical ‘and.’ */
export type LegacyMessageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LegacyMessageFilter>>;
  /** Filter by the object’s `encryptedText` field. */
  encryptedText?: InputMaybe<StringFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LegacyMessageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LegacyMessageFilter>>;
  /** Filter by the object’s `receiver` relation. */
  receiver?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `recipientMessagingGroupKeyName` field. */
  recipientMessagingGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `recipientMessagingPublicKey` field. */
  recipientMessagingPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `recipientPublicKey` field. */
  recipientPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sender` relation. */
  sender?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `senderMessagingGroupKeyName` field. */
  senderMessagingGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `senderMessagingPublicKey` field. */
  senderMessagingPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `senderPublicKey` field. */
  senderPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<IntFilter>;
};

/** A connection to a list of `LegacyMessage` values. */
export type LegacyMessagesConnection = {
  __typename?: 'LegacyMessagesConnection';
  /** A list of edges which contains the `LegacyMessage` and cursor to aid in pagination. */
  edges: Array<LegacyMessagesEdge>;
  /** A list of `LegacyMessage` objects. */
  nodes: Array<Maybe<LegacyMessage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LegacyMessage` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `LegacyMessage` edge in the connection. */
export type LegacyMessagesEdge = {
  __typename?: 'LegacyMessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `LegacyMessage` at the end of the edge. */
  node?: Maybe<LegacyMessage>;
};

/** Methods to use when ordering `LegacyMessage`. */
export enum LegacyMessagesOrderBy {
  EncryptedTextAsc = 'ENCRYPTED_TEXT_ASC',
  EncryptedTextDesc = 'ENCRYPTED_TEXT_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RecipientMessagingGroupKeyNameAsc = 'RECIPIENT_MESSAGING_GROUP_KEY_NAME_ASC',
  RecipientMessagingGroupKeyNameDesc = 'RECIPIENT_MESSAGING_GROUP_KEY_NAME_DESC',
  RecipientMessagingPublicKeyAsc = 'RECIPIENT_MESSAGING_PUBLIC_KEY_ASC',
  RecipientMessagingPublicKeyDesc = 'RECIPIENT_MESSAGING_PUBLIC_KEY_DESC',
  RecipientPublicKeyAsc = 'RECIPIENT_PUBLIC_KEY_ASC',
  RecipientPublicKeyDesc = 'RECIPIENT_PUBLIC_KEY_DESC',
  SenderMessagingGroupKeyNameAsc = 'SENDER_MESSAGING_GROUP_KEY_NAME_ASC',
  SenderMessagingGroupKeyNameDesc = 'SENDER_MESSAGING_GROUP_KEY_NAME_DESC',
  SenderMessagingPublicKeyAsc = 'SENDER_MESSAGING_PUBLIC_KEY_ASC',
  SenderMessagingPublicKeyDesc = 'SENDER_MESSAGING_PUBLIC_KEY_DESC',
  SenderPublicKeyAsc = 'SENDER_PUBLIC_KEY_ASC',
  SenderPublicKeyDesc = 'SENDER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC',
}

export type Like = Node & {
  __typename?: 'Like';
  /** Reads a single `Account` that is related to this `Like`. */
  account?: Maybe<Account>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  /** Reads a single `Post` that is related to this `Like`. */
  post?: Maybe<Post>;
  postHash: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
};

/** A condition to be used against `Like` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LikeCondition = {
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Like` object types. All fields are combined with a logical ‘and.’ */
export type LikeFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LikeFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<LikeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LikeFilter>>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `Like` values. */
export type LikesConnection = {
  __typename?: 'LikesConnection';
  /** A list of edges which contains the `Like` and cursor to aid in pagination. */
  edges: Array<LikesEdge>;
  /** A list of `Like` objects. */
  nodes: Array<Maybe<Like>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Like` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Like` edge in the connection. */
export type LikesEdge = {
  __typename?: 'LikesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Like` at the end of the edge. */
  node?: Maybe<Like>;
};

/** Methods to use when ordering `Like`. */
export enum LikesOrderBy {
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
}

export type LockedBalance = {
  __typename?: 'LockedBalance';
  /** Reads a single `Account` that is related to this `LockedBalance`. */
  hodlerAccount?: Maybe<Account>;
  hodlerPkid?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Account` that is related to this `LockedBalance`. */
  profileAccount?: Maybe<Account>;
  profilePkid?: Maybe<Scalars['String']['output']>;
  totalBalanceBaseUnits?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `LockedBalance` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LockedBalanceCondition = {
  /** Checks for equality with the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `profilePkid` field. */
  profilePkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `totalBalanceBaseUnits` field. */
  totalBalanceBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A connection to a list of `LockedBalanceEntry` values. */
export type LockedBalanceEntriesConnection = {
  __typename?: 'LockedBalanceEntriesConnection';
  /** A list of edges which contains the `LockedBalanceEntry` and cursor to aid in pagination. */
  edges: Array<LockedBalanceEntriesEdge>;
  /** A list of `LockedBalanceEntry` objects. */
  nodes: Array<Maybe<LockedBalanceEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LockedBalanceEntry` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `LockedBalanceEntry` edge in the connection. */
export type LockedBalanceEntriesEdge = {
  __typename?: 'LockedBalanceEntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `LockedBalanceEntry` at the end of the edge. */
  node?: Maybe<LockedBalanceEntry>;
};

/** Methods to use when ordering `LockedBalanceEntry`. */
export enum LockedBalanceEntriesOrderBy {
  BalanceBaseUnitsAsc = 'BALANCE_BASE_UNITS_ASC',
  BalanceBaseUnitsDesc = 'BALANCE_BASE_UNITS_DESC',
  HodlerPkidAsc = 'HODLER_PKID_ASC',
  HodlerPkidDesc = 'HODLER_PKID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProfilePkidAsc = 'PROFILE_PKID_ASC',
  ProfilePkidDesc = 'PROFILE_PKID_DESC',
  UnlockTimestampNanoSecsAsc = 'UNLOCK_TIMESTAMP_NANO_SECS_ASC',
  UnlockTimestampNanoSecsDesc = 'UNLOCK_TIMESTAMP_NANO_SECS_DESC',
  VestingEndTimestampNanoSecsAsc = 'VESTING_END_TIMESTAMP_NANO_SECS_ASC',
  VestingEndTimestampNanoSecsDesc = 'VESTING_END_TIMESTAMP_NANO_SECS_DESC',
}

export type LockedBalanceEntry = Node & {
  __typename?: 'LockedBalanceEntry';
  balanceBaseUnits: Scalars['BigFloat']['output'];
  /** Reads a single `Account` that is related to this `LockedBalanceEntry`. */
  hodlerAccount?: Maybe<Account>;
  hodlerPkid: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  /** Reads a single `Account` that is related to this `LockedBalanceEntry`. */
  profileAccount?: Maybe<Account>;
  profilePkid: Scalars['String']['output'];
  unlockTimestampNanoSecs: Scalars['BigInt']['output'];
  vestingEndTimestampNanoSecs: Scalars['BigInt']['output'];
};

/**
 * A condition to be used against `LockedBalanceEntry` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LockedBalanceEntryCondition = {
  /** Checks for equality with the object’s `balanceBaseUnits` field. */
  balanceBaseUnits?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `profilePkid` field. */
  profilePkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `unlockTimestampNanoSecs` field. */
  unlockTimestampNanoSecs?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `vestingEndTimestampNanoSecs` field. */
  vestingEndTimestampNanoSecs?: InputMaybe<Scalars['BigInt']['input']>;
};

/** A filter to be used against `LockedBalanceEntry` object types. All fields are combined with a logical ‘and.’ */
export type LockedBalanceEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LockedBalanceEntryFilter>>;
  /** Filter by the object’s `balanceBaseUnits` field. */
  balanceBaseUnits?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `hodlerAccount` relation. */
  hodlerAccount?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LockedBalanceEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LockedBalanceEntryFilter>>;
  /** Filter by the object’s `profileAccount` relation. */
  profileAccount?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `profilePkid` field. */
  profilePkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `unlockTimestampNanoSecs` field. */
  unlockTimestampNanoSecs?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `vestingEndTimestampNanoSecs` field. */
  vestingEndTimestampNanoSecs?: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `LockedBalance` object types. All fields are combined with a logical ‘and.’ */
export type LockedBalanceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LockedBalanceFilter>>;
  /** Filter by the object’s `hodlerAccount` relation. */
  hodlerAccount?: InputMaybe<AccountFilter>;
  /** A related `hodlerAccount` exists. */
  hodlerAccountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LockedBalanceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LockedBalanceFilter>>;
  /** Filter by the object’s `profileAccount` relation. */
  profileAccount?: InputMaybe<AccountFilter>;
  /** A related `profileAccount` exists. */
  profileAccountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `profilePkid` field. */
  profilePkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalBalanceBaseUnits` field. */
  totalBalanceBaseUnits?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `LockedBalance` values. */
export type LockedBalancesConnection = {
  __typename?: 'LockedBalancesConnection';
  /** A list of edges which contains the `LockedBalance` and cursor to aid in pagination. */
  edges: Array<LockedBalancesEdge>;
  /** A list of `LockedBalance` objects. */
  nodes: Array<Maybe<LockedBalance>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LockedBalance` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `LockedBalance` edge in the connection. */
export type LockedBalancesEdge = {
  __typename?: 'LockedBalancesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `LockedBalance` at the end of the edge. */
  node?: Maybe<LockedBalance>;
};

/** Methods to use when ordering `LockedBalance`. */
export enum LockedBalancesOrderBy {
  HodlerPkidAsc = 'HODLER_PKID_ASC',
  HodlerPkidDesc = 'HODLER_PKID_DESC',
  Natural = 'NATURAL',
  ProfilePkidAsc = 'PROFILE_PKID_ASC',
  ProfilePkidDesc = 'PROFILE_PKID_DESC',
  TotalBalanceBaseUnitsAsc = 'TOTAL_BALANCE_BASE_UNITS_ASC',
  TotalBalanceBaseUnitsDesc = 'TOTAL_BALANCE_BASE_UNITS_DESC',
}

/** A connection to a list of `LockedStakeEntry` values. */
export type LockedStakeEntriesConnection = {
  __typename?: 'LockedStakeEntriesConnection';
  /** A list of edges which contains the `LockedStakeEntry` and cursor to aid in pagination. */
  edges: Array<LockedStakeEntriesEdge>;
  /** A list of `LockedStakeEntry` objects. */
  nodes: Array<Maybe<LockedStakeEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LockedStakeEntry` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `LockedStakeEntry` edge in the connection. */
export type LockedStakeEntriesEdge = {
  __typename?: 'LockedStakeEntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `LockedStakeEntry` at the end of the edge. */
  node?: Maybe<LockedStakeEntry>;
};

/** Methods to use when ordering `LockedStakeEntry`. */
export enum LockedStakeEntriesOrderBy {
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  LockedAmountNanosAsc = 'LOCKED_AMOUNT_NANOS_ASC',
  LockedAmountNanosDesc = 'LOCKED_AMOUNT_NANOS_DESC',
  LockedAtEpochNumberAsc = 'LOCKED_AT_EPOCH_NUMBER_ASC',
  LockedAtEpochNumberDesc = 'LOCKED_AT_EPOCH_NUMBER_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StakerPkidAsc = 'STAKER_PKID_ASC',
  StakerPkidDesc = 'STAKER_PKID_DESC',
  ValidatorPkidAsc = 'VALIDATOR_PKID_ASC',
  ValidatorPkidDesc = 'VALIDATOR_PKID_DESC',
}

export type LockedStakeEntry = Node & {
  __typename?: 'LockedStakeEntry';
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  lockedAmountNanos: Scalars['BigFloat']['output'];
  lockedAtEpochNumber: Scalars['BigInt']['output'];
  /** Reads a single `Account` that is related to this `LockedStakeEntry`. */
  staker?: Maybe<Account>;
  stakerPkid: Scalars['String']['output'];
  /** Reads a single `Account` that is related to this `LockedStakeEntry`. */
  validatorAccount?: Maybe<Account>;
  /** Reads a single `ValidatorEntry` that is related to this `LockedStakeEntry`. */
  validatorEntry?: Maybe<ValidatorEntry>;
  validatorPkid: Scalars['String']['output'];
};

/**
 * A condition to be used against `LockedStakeEntry` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LockedStakeEntryCondition = {
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `lockedAmountNanos` field. */
  lockedAmountNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `lockedAtEpochNumber` field. */
  lockedAtEpochNumber?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `LockedStakeEntry` object types. All fields are combined with a logical ‘and.’ */
export type LockedStakeEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LockedStakeEntryFilter>>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `lockedAmountNanos` field. */
  lockedAmountNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `lockedAtEpochNumber` field. */
  lockedAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LockedStakeEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LockedStakeEntryFilter>>;
  /** Filter by the object’s `staker` relation. */
  staker?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `validatorAccount` relation. */
  validatorAccount?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `validatorEntry` relation. */
  validatorEntry?: InputMaybe<ValidatorEntryFilter>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
};

export type MaxPostScoreBlockHeight = {
  __typename?: 'MaxPostScoreBlockHeight';
  max?: Maybe<Scalars['BigInt']['output']>;
};

/**
 * A condition to be used against `MaxPostScoreBlockHeight` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type MaxPostScoreBlockHeightCondition = {
  /** Checks for equality with the object’s `max` field. */
  max?: InputMaybe<Scalars['BigInt']['input']>;
};

/** A filter to be used against `MaxPostScoreBlockHeight` object types. All fields are combined with a logical ‘and.’ */
export type MaxPostScoreBlockHeightFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MaxPostScoreBlockHeightFilter>>;
  /** Filter by the object’s `max` field. */
  max?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MaxPostScoreBlockHeightFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MaxPostScoreBlockHeightFilter>>;
};

/** A connection to a list of `MaxPostScoreBlockHeight` values. */
export type MaxPostScoreBlockHeightsConnection = {
  __typename?: 'MaxPostScoreBlockHeightsConnection';
  /** A list of edges which contains the `MaxPostScoreBlockHeight` and cursor to aid in pagination. */
  edges: Array<MaxPostScoreBlockHeightsEdge>;
  /** A list of `MaxPostScoreBlockHeight` objects. */
  nodes: Array<Maybe<MaxPostScoreBlockHeight>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MaxPostScoreBlockHeight` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `MaxPostScoreBlockHeight` edge in the connection. */
export type MaxPostScoreBlockHeightsEdge = {
  __typename?: 'MaxPostScoreBlockHeightsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `MaxPostScoreBlockHeight` at the end of the edge. */
  node?: Maybe<MaxPostScoreBlockHeight>;
};

/** Methods to use when ordering `MaxPostScoreBlockHeight`. */
export enum MaxPostScoreBlockHeightsOrderBy {
  MaxAsc = 'MAX_ASC',
  MaxDesc = 'MAX_DESC',
  Natural = 'NATURAL',
}

export type Message = Node & {
  __typename?: 'Message';
  associationId?: Maybe<Scalars['String']['output']>;
  associationType?: Maybe<Scalars['String']['output']>;
  associationValue?: Maybe<Scalars['String']['output']>;
  badgerKey: Scalars['String']['output'];
  /** Reads a single `DerivedKey` that is related to this `Message`. */
  derivedKey?: Maybe<DerivedKey>;
  encryptedText: Scalars['String']['output'];
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  isGroupChatMessage?: Maybe<Scalars['Boolean']['output']>;
  isPayment?: Maybe<Scalars['Boolean']['output']>;
  paidMessageDerivedPublicKey?: Maybe<Scalars['String']['output']>;
  paymentAmountUsdCents?: Maybe<Scalars['BigInt']['output']>;
  paymentExtraData?: Maybe<Scalars['JSON']['output']>;
  paymentStatus?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Account` that is related to this `Message`. */
  receiver?: Maybe<Account>;
  /** Reads a single `AccessGroup` that is related to this `Message`. */
  receiverAccessGroup?: Maybe<AccessGroup>;
  recipientAccessGroupKeyName?: Maybe<Scalars['String']['output']>;
  recipientAccessGroupOwnerPublicKey?: Maybe<Scalars['String']['output']>;
  recipientAccessGroupPublicKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Account` that is related to this `Message`. */
  sender?: Maybe<Account>;
  /** Reads a single `AccessGroup` that is related to this `Message`. */
  senderAccessGroup?: Maybe<AccessGroup>;
  senderAccessGroupKeyName?: Maybe<Scalars['String']['output']>;
  senderAccessGroupOwnerPublicKey?: Maybe<Scalars['String']['output']>;
  senderAccessGroupPublicKey?: Maybe<Scalars['String']['output']>;
  targetUserPkid?: Maybe<Scalars['String']['output']>;
  /** Reads a single `MessageThread` that is related to this `Message`. */
  thread?: Maybe<MessageThread>;
  threadIdentifier?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
  transactorPkid?: Maybe<Scalars['String']['output']>;
};

/** A condition to be used against `Message` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MessageCondition = {
  /** Checks for equality with the object’s `associationId` field. */
  associationId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationType` field. */
  associationType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationValue` field. */
  associationValue?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `encryptedText` field. */
  encryptedText?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `isGroupChatMessage` field. */
  isGroupChatMessage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isPayment` field. */
  isPayment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `paidMessageDerivedPublicKey` field. */
  paidMessageDerivedPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `paymentAmountUsdCents` field. */
  paymentAmountUsdCents?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `paymentExtraData` field. */
  paymentExtraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `paymentStatus` field. */
  paymentStatus?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `recipientAccessGroupKeyName` field. */
  recipientAccessGroupKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `recipientAccessGroupOwnerPublicKey` field. */
  recipientAccessGroupOwnerPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `recipientAccessGroupPublicKey` field. */
  recipientAccessGroupPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `senderAccessGroupKeyName` field. */
  senderAccessGroupKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `senderAccessGroupOwnerPublicKey` field. */
  senderAccessGroupOwnerPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `senderAccessGroupPublicKey` field. */
  senderAccessGroupPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `targetUserPkid` field. */
  targetUserPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Message` object types. All fields are combined with a logical ‘and.’ */
export type MessageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MessageFilter>>;
  /** Filter by the object’s `associationId` field. */
  associationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationType` field. */
  associationType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationValue` field. */
  associationValue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `derivedKey` relation. */
  derivedKey?: InputMaybe<DerivedKeyFilter>;
  /** A related `derivedKey` exists. */
  derivedKeyExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `encryptedText` field. */
  encryptedText?: InputMaybe<StringFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `isGroupChatMessage` field. */
  isGroupChatMessage?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPayment` field. */
  isPayment?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MessageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MessageFilter>>;
  /** Filter by the object’s `paidMessageDerivedPublicKey` field. */
  paidMessageDerivedPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `paymentAmountUsdCents` field. */
  paymentAmountUsdCents?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `paymentExtraData` field. */
  paymentExtraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `paymentStatus` field. */
  paymentStatus?: InputMaybe<StringFilter>;
  /** Filter by the object’s `receiver` relation. */
  receiver?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `receiverAccessGroup` relation. */
  receiverAccessGroup?: InputMaybe<AccessGroupFilter>;
  /** A related `receiverAccessGroup` exists. */
  receiverAccessGroupExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** A related `receiver` exists. */
  receiverExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `recipientAccessGroupKeyName` field. */
  recipientAccessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `recipientAccessGroupOwnerPublicKey` field. */
  recipientAccessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `recipientAccessGroupPublicKey` field. */
  recipientAccessGroupPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sender` relation. */
  sender?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `senderAccessGroup` relation. */
  senderAccessGroup?: InputMaybe<AccessGroupFilter>;
  /** A related `senderAccessGroup` exists. */
  senderAccessGroupExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `senderAccessGroupKeyName` field. */
  senderAccessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `senderAccessGroupOwnerPublicKey` field. */
  senderAccessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `senderAccessGroupPublicKey` field. */
  senderAccessGroupPublicKey?: InputMaybe<StringFilter>;
  /** A related `sender` exists. */
  senderExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `targetUserPkid` field. */
  targetUserPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `thread` relation. */
  thread?: InputMaybe<MessageThreadFilter>;
  /** A related `thread` exists. */
  threadExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<StringFilter>;
};

export type MessageSpamFilter = Node & {
  __typename?: 'MessageSpamFilter';
  badgerKey: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  isSpam?: Maybe<Scalars['Boolean']['output']>;
  paymentAmountUsdCents?: Maybe<Scalars['BigInt']['output']>;
};

/**
 * A condition to be used against `MessageSpamFilter` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MessageSpamFilterCondition = {
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isSpam` field. */
  isSpam?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `paymentAmountUsdCents` field. */
  paymentAmountUsdCents?: InputMaybe<Scalars['BigInt']['input']>;
};

/** A filter to be used against `MessageSpamFilter` object types. All fields are combined with a logical ‘and.’ */
export type MessageSpamFilterFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MessageSpamFilterFilter>>;
  /** Filter by the object’s `isSpam` field. */
  isSpam?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MessageSpamFilterFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MessageSpamFilterFilter>>;
  /** Filter by the object’s `paymentAmountUsdCents` field. */
  paymentAmountUsdCents?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `MessageSpamFilter` values. */
export type MessageSpamFiltersConnection = {
  __typename?: 'MessageSpamFiltersConnection';
  /** A list of edges which contains the `MessageSpamFilter` and cursor to aid in pagination. */
  edges: Array<MessageSpamFiltersEdge>;
  /** A list of `MessageSpamFilter` objects. */
  nodes: Array<Maybe<MessageSpamFilter>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MessageSpamFilter` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `MessageSpamFilter` edge in the connection. */
export type MessageSpamFiltersEdge = {
  __typename?: 'MessageSpamFiltersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `MessageSpamFilter` at the end of the edge. */
  node?: Maybe<MessageSpamFilter>;
};

/** Methods to use when ordering `MessageSpamFilter`. */
export enum MessageSpamFiltersOrderBy {
  BadgerKeyAsc = 'BADGER_KEY_ASC',
  BadgerKeyDesc = 'BADGER_KEY_DESC',
  IsSpamAsc = 'IS_SPAM_ASC',
  IsSpamDesc = 'IS_SPAM_DESC',
  Natural = 'NATURAL',
  PaymentAmountUsdCentsAsc = 'PAYMENT_AMOUNT_USD_CENTS_ASC',
  PaymentAmountUsdCentsDesc = 'PAYMENT_AMOUNT_USD_CENTS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type MessageThread = {
  __typename?: 'MessageThread';
  accessGroupKeyName?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `AccessGroupMember`. */
  accessGroupMembers: AccessGroupMembersConnection;
  accessGroupOwnerPublicKey?: Maybe<Scalars['String']['output']>;
  isGroupChatMessage?: Maybe<Scalars['Boolean']['output']>;
  latestTimestamp?: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `Message`. */
  messages: MessagesConnection;
  /** Reads and enables pagination through a set of `FilterableMessageThread`. */
  participants: FilterableMessageThreadsConnection;
  threadIdentifier?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `UnreadMessagesCount`. */
  unreadMessagesCount: UnreadMessagesCountsConnection;
};

export type MessageThreadAccessGroupMembersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AccessGroupMemberCondition>;
  filter?: InputMaybe<AccessGroupMemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGroupMembersOrderBy>>;
};

export type MessageThreadMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

export type MessageThreadParticipantsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FilterableMessageThreadCondition>;
  filter?: InputMaybe<FilterableMessageThreadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilterableMessageThreadsOrderBy>>;
};

export type MessageThreadUnreadMessagesCountArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UnreadMessagesCountCondition>;
  filter?: InputMaybe<UnreadMessagesCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UnreadMessagesCountsOrderBy>>;
};

/**
 * A condition to be used against `MessageThread` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MessageThreadCondition = {
  /** Checks for equality with the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isGroupChatMessage` field. */
  isGroupChatMessage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `latestTimestamp` field. */
  latestTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `MessageThread` object types. All fields are combined with a logical ‘and.’ */
export type MessageThreadFilter = {
  /** Filter by the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupMembers` relation. */
  accessGroupMembers?: InputMaybe<MessageThreadToManyAccessGroupMemberFilter>;
  /** Some related `accessGroupMembers` exist. */
  accessGroupMembersExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MessageThreadFilter>>;
  /** Filter by the object’s `isGroupChatMessage` field. */
  isGroupChatMessage?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `latestTimestamp` field. */
  latestTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `messages` relation. */
  messages?: InputMaybe<MessageThreadToManyMessageFilter>;
  /** Some related `messages` exist. */
  messagesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not?: InputMaybe<MessageThreadFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MessageThreadFilter>>;
  /** Filter by the object’s `participants` relation. */
  participants?: InputMaybe<MessageThreadToManyFilterableMessageThreadFilter>;
  /** Some related `participants` exist. */
  participantsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<StringFilter>;
  /** Filter by the object’s `unreadMessagesCount` relation. */
  unreadMessagesCount?: InputMaybe<MessageThreadToManyUnreadMessagesCountFilter>;
  /** Some related `unreadMessagesCount` exist. */
  unreadMessagesCountExist?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MessageThreadParticipant = {
  __typename?: 'MessageThreadParticipant';
  participantPublicKey?: Maybe<Scalars['String']['output']>;
  threadIdentifier?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `MessageThreadParticipant` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type MessageThreadParticipantCondition = {
  /** Checks for equality with the object’s `participantPublicKey` field. */
  participantPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `MessageThreadParticipant` object types. All fields are combined with a logical ‘and.’ */
export type MessageThreadParticipantFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MessageThreadParticipantFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<MessageThreadParticipantFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MessageThreadParticipantFilter>>;
  /** Filter by the object’s `participantPublicKey` field. */
  participantPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<StringFilter>;
};

export type MessageThreadParticipantTbl = Node & {
  __typename?: 'MessageThreadParticipantTbl';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  participantPublicKey: Scalars['String']['output'];
  threadIdentifier: Scalars['String']['output'];
};

/**
 * A condition to be used against `MessageThreadParticipantTbl` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type MessageThreadParticipantTblCondition = {
  /** Checks for equality with the object’s `participantPublicKey` field. */
  participantPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `MessageThreadParticipantTbl` object types. All fields are combined with a logical ‘and.’ */
export type MessageThreadParticipantTblFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MessageThreadParticipantTblFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<MessageThreadParticipantTblFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MessageThreadParticipantTblFilter>>;
  /** Filter by the object’s `participantPublicKey` field. */
  participantPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<StringFilter>;
};

/** A connection to a list of `MessageThreadParticipantTbl` values. */
export type MessageThreadParticipantTblsConnection = {
  __typename?: 'MessageThreadParticipantTblsConnection';
  /** A list of edges which contains the `MessageThreadParticipantTbl` and cursor to aid in pagination. */
  edges: Array<MessageThreadParticipantTblsEdge>;
  /** A list of `MessageThreadParticipantTbl` objects. */
  nodes: Array<Maybe<MessageThreadParticipantTbl>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MessageThreadParticipantTbl` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `MessageThreadParticipantTbl` edge in the connection. */
export type MessageThreadParticipantTblsEdge = {
  __typename?: 'MessageThreadParticipantTblsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `MessageThreadParticipantTbl` at the end of the edge. */
  node?: Maybe<MessageThreadParticipantTbl>;
};

/** Methods to use when ordering `MessageThreadParticipantTbl`. */
export enum MessageThreadParticipantTblsOrderBy {
  Natural = 'NATURAL',
  ParticipantPublicKeyAsc = 'PARTICIPANT_PUBLIC_KEY_ASC',
  ParticipantPublicKeyDesc = 'PARTICIPANT_PUBLIC_KEY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ThreadIdentifierAsc = 'THREAD_IDENTIFIER_ASC',
  ThreadIdentifierDesc = 'THREAD_IDENTIFIER_DESC',
}

export type MessageThreadParticipantView = {
  __typename?: 'MessageThreadParticipantView';
  participantPublicKey?: Maybe<Scalars['String']['output']>;
  threadIdentifier?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `MessageThreadParticipantView` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type MessageThreadParticipantViewCondition = {
  /** Checks for equality with the object’s `participantPublicKey` field. */
  participantPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `MessageThreadParticipantView` object types. All fields are combined with a logical ‘and.’ */
export type MessageThreadParticipantViewFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MessageThreadParticipantViewFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<MessageThreadParticipantViewFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MessageThreadParticipantViewFilter>>;
  /** Filter by the object’s `participantPublicKey` field. */
  participantPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<StringFilter>;
};

/** A connection to a list of `MessageThreadParticipantView` values. */
export type MessageThreadParticipantViewsConnection = {
  __typename?: 'MessageThreadParticipantViewsConnection';
  /** A list of edges which contains the `MessageThreadParticipantView` and cursor to aid in pagination. */
  edges: Array<MessageThreadParticipantViewsEdge>;
  /** A list of `MessageThreadParticipantView` objects. */
  nodes: Array<Maybe<MessageThreadParticipantView>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MessageThreadParticipantView` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `MessageThreadParticipantView` edge in the connection. */
export type MessageThreadParticipantViewsEdge = {
  __typename?: 'MessageThreadParticipantViewsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `MessageThreadParticipantView` at the end of the edge. */
  node?: Maybe<MessageThreadParticipantView>;
};

/** Methods to use when ordering `MessageThreadParticipantView`. */
export enum MessageThreadParticipantViewsOrderBy {
  Natural = 'NATURAL',
  ParticipantPublicKeyAsc = 'PARTICIPANT_PUBLIC_KEY_ASC',
  ParticipantPublicKeyDesc = 'PARTICIPANT_PUBLIC_KEY_DESC',
  ThreadIdentifierAsc = 'THREAD_IDENTIFIER_ASC',
  ThreadIdentifierDesc = 'THREAD_IDENTIFIER_DESC',
}

/** A connection to a list of `MessageThreadParticipant` values. */
export type MessageThreadParticipantsConnection = {
  __typename?: 'MessageThreadParticipantsConnection';
  /** A list of edges which contains the `MessageThreadParticipant` and cursor to aid in pagination. */
  edges: Array<MessageThreadParticipantsEdge>;
  /** A list of `MessageThreadParticipant` objects. */
  nodes: Array<Maybe<MessageThreadParticipant>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MessageThreadParticipant` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `MessageThreadParticipant` edge in the connection. */
export type MessageThreadParticipantsEdge = {
  __typename?: 'MessageThreadParticipantsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `MessageThreadParticipant` at the end of the edge. */
  node?: Maybe<MessageThreadParticipant>;
};

/** Methods to use when ordering `MessageThreadParticipant`. */
export enum MessageThreadParticipantsOrderBy {
  Natural = 'NATURAL',
  ParticipantPublicKeyAsc = 'PARTICIPANT_PUBLIC_KEY_ASC',
  ParticipantPublicKeyDesc = 'PARTICIPANT_PUBLIC_KEY_DESC',
  ThreadIdentifierAsc = 'THREAD_IDENTIFIER_ASC',
  ThreadIdentifierDesc = 'THREAD_IDENTIFIER_DESC',
}

/** A filter to be used against many `AccessGroupMember` object types. All fields are combined with a logical ‘and.’ */
export type MessageThreadToManyAccessGroupMemberFilter = {
  /** Every related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AccessGroupMemberFilter>;
  /** No related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AccessGroupMemberFilter>;
  /** Some related `AccessGroupMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AccessGroupMemberFilter>;
};

/** A filter to be used against many `FilterableMessageThread` object types. All fields are combined with a logical ‘and.’ */
export type MessageThreadToManyFilterableMessageThreadFilter = {
  /** Every related `FilterableMessageThread` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FilterableMessageThreadFilter>;
  /** No related `FilterableMessageThread` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FilterableMessageThreadFilter>;
  /** Some related `FilterableMessageThread` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FilterableMessageThreadFilter>;
};

/** A filter to be used against many `Message` object types. All fields are combined with a logical ‘and.’ */
export type MessageThreadToManyMessageFilter = {
  /** Every related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MessageFilter>;
  /** No related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MessageFilter>;
  /** Some related `Message` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MessageFilter>;
};

/** A filter to be used against many `UnreadMessagesCount` object types. All fields are combined with a logical ‘and.’ */
export type MessageThreadToManyUnreadMessagesCountFilter = {
  /** Every related `UnreadMessagesCount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UnreadMessagesCountFilter>;
  /** No related `UnreadMessagesCount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UnreadMessagesCountFilter>;
  /** Some related `UnreadMessagesCount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UnreadMessagesCountFilter>;
};

/** A connection to a list of `MessageThread` values. */
export type MessageThreadsConnection = {
  __typename?: 'MessageThreadsConnection';
  /** A list of edges which contains the `MessageThread` and cursor to aid in pagination. */
  edges: Array<MessageThreadsEdge>;
  /** A list of `MessageThread` objects. */
  nodes: Array<Maybe<MessageThread>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MessageThread` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `MessageThread` edge in the connection. */
export type MessageThreadsEdge = {
  __typename?: 'MessageThreadsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `MessageThread` at the end of the edge. */
  node?: Maybe<MessageThread>;
};

/** Methods to use when ordering `MessageThread`. */
export enum MessageThreadsOrderBy {
  AccessGroupKeyNameAsc = 'ACCESS_GROUP_KEY_NAME_ASC',
  AccessGroupKeyNameDesc = 'ACCESS_GROUP_KEY_NAME_DESC',
  AccessGroupOwnerPublicKeyAsc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC',
  AccessGroupOwnerPublicKeyDesc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC',
  IsGroupChatMessageAsc = 'IS_GROUP_CHAT_MESSAGE_ASC',
  IsGroupChatMessageDesc = 'IS_GROUP_CHAT_MESSAGE_DESC',
  LatestTimestampAsc = 'LATEST_TIMESTAMP_ASC',
  LatestTimestampDesc = 'LATEST_TIMESTAMP_DESC',
  Natural = 'NATURAL',
  ThreadIdentifierAsc = 'THREAD_IDENTIFIER_ASC',
  ThreadIdentifierDesc = 'THREAD_IDENTIFIER_DESC',
}

export type MessageView = Node & {
  __typename?: 'MessageView';
  createdAt: Scalars['Datetime']['output'];
  deletedAt?: Maybe<Scalars['Datetime']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  lastViewedTimestamp: Scalars['Datetime']['output'];
  publicKey: Scalars['String']['output'];
  rowId: Scalars['UUID']['output'];
  threadIdentifier: Scalars['String']['output'];
};

/**
 * A condition to be used against `MessageView` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MessageViewCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastViewedTimestamp` field. */
  lastViewedTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `MessageView` object types. All fields are combined with a logical ‘and.’ */
export type MessageViewFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MessageViewFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastViewedTimestamp` field. */
  lastViewedTimestamp?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MessageViewFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MessageViewFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<StringFilter>;
};

/** A connection to a list of `MessageView` values. */
export type MessageViewsConnection = {
  __typename?: 'MessageViewsConnection';
  /** A list of edges which contains the `MessageView` and cursor to aid in pagination. */
  edges: Array<MessageViewsEdge>;
  /** A list of `MessageView` objects. */
  nodes: Array<Maybe<MessageView>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MessageView` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `MessageView` edge in the connection. */
export type MessageViewsEdge = {
  __typename?: 'MessageViewsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `MessageView` at the end of the edge. */
  node?: Maybe<MessageView>;
};

/** Methods to use when ordering `MessageView`. */
export enum MessageViewsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LastViewedTimestampAsc = 'LAST_VIEWED_TIMESTAMP_ASC',
  LastViewedTimestampDesc = 'LAST_VIEWED_TIMESTAMP_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  ThreadIdentifierAsc = 'THREAD_IDENTIFIER_ASC',
  ThreadIdentifierDesc = 'THREAD_IDENTIFIER_DESC',
}

/** A connection to a list of `Message` values. */
export type MessagesConnection = {
  __typename?: 'MessagesConnection';
  /** A list of edges which contains the `Message` and cursor to aid in pagination. */
  edges: Array<MessagesEdge>;
  /** A list of `Message` objects. */
  nodes: Array<Maybe<Message>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Message` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Message` edge in the connection. */
export type MessagesEdge = {
  __typename?: 'MessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Message` at the end of the edge. */
  node?: Maybe<Message>;
};

/** Methods to use when ordering `Message`. */
export enum MessagesOrderBy {
  AssociationIdAsc = 'ASSOCIATION_ID_ASC',
  AssociationIdDesc = 'ASSOCIATION_ID_DESC',
  AssociationTypeAsc = 'ASSOCIATION_TYPE_ASC',
  AssociationTypeDesc = 'ASSOCIATION_TYPE_DESC',
  AssociationValueAsc = 'ASSOCIATION_VALUE_ASC',
  AssociationValueDesc = 'ASSOCIATION_VALUE_DESC',
  BadgerKeyAsc = 'BADGER_KEY_ASC',
  BadgerKeyDesc = 'BADGER_KEY_DESC',
  EncryptedTextAsc = 'ENCRYPTED_TEXT_ASC',
  EncryptedTextDesc = 'ENCRYPTED_TEXT_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  IsGroupChatMessageAsc = 'IS_GROUP_CHAT_MESSAGE_ASC',
  IsGroupChatMessageDesc = 'IS_GROUP_CHAT_MESSAGE_DESC',
  IsPaymentAsc = 'IS_PAYMENT_ASC',
  IsPaymentDesc = 'IS_PAYMENT_DESC',
  Natural = 'NATURAL',
  PaidMessageDerivedPublicKeyAsc = 'PAID_MESSAGE_DERIVED_PUBLIC_KEY_ASC',
  PaidMessageDerivedPublicKeyDesc = 'PAID_MESSAGE_DERIVED_PUBLIC_KEY_DESC',
  PaymentAmountUsdCentsAsc = 'PAYMENT_AMOUNT_USD_CENTS_ASC',
  PaymentAmountUsdCentsDesc = 'PAYMENT_AMOUNT_USD_CENTS_DESC',
  PaymentExtraDataAsc = 'PAYMENT_EXTRA_DATA_ASC',
  PaymentExtraDataDesc = 'PAYMENT_EXTRA_DATA_DESC',
  PaymentStatusAsc = 'PAYMENT_STATUS_ASC',
  PaymentStatusDesc = 'PAYMENT_STATUS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RecipientAccessGroupKeyNameAsc = 'RECIPIENT_ACCESS_GROUP_KEY_NAME_ASC',
  RecipientAccessGroupKeyNameDesc = 'RECIPIENT_ACCESS_GROUP_KEY_NAME_DESC',
  RecipientAccessGroupOwnerPublicKeyAsc = 'RECIPIENT_ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC',
  RecipientAccessGroupOwnerPublicKeyDesc = 'RECIPIENT_ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC',
  RecipientAccessGroupPublicKeyAsc = 'RECIPIENT_ACCESS_GROUP_PUBLIC_KEY_ASC',
  RecipientAccessGroupPublicKeyDesc = 'RECIPIENT_ACCESS_GROUP_PUBLIC_KEY_DESC',
  SenderAccessGroupKeyNameAsc = 'SENDER_ACCESS_GROUP_KEY_NAME_ASC',
  SenderAccessGroupKeyNameDesc = 'SENDER_ACCESS_GROUP_KEY_NAME_DESC',
  SenderAccessGroupOwnerPublicKeyAsc = 'SENDER_ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC',
  SenderAccessGroupOwnerPublicKeyDesc = 'SENDER_ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC',
  SenderAccessGroupPublicKeyAsc = 'SENDER_ACCESS_GROUP_PUBLIC_KEY_ASC',
  SenderAccessGroupPublicKeyDesc = 'SENDER_ACCESS_GROUP_PUBLIC_KEY_DESC',
  TargetUserPkidAsc = 'TARGET_USER_PKID_ASC',
  TargetUserPkidDesc = 'TARGET_USER_PKID_DESC',
  ThreadIdentifierAsc = 'THREAD_IDENTIFIER_ASC',
  ThreadIdentifierDesc = 'THREAD_IDENTIFIER_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
  TransactorPkidAsc = 'TRANSACTOR_PKID_ASC',
  TransactorPkidDesc = 'TRANSACTOR_PKID_DESC',
}

/** All input for the `migrateMessageView` mutation. */
export type MigrateMessageViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  connStr?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `migrateMessageView` mutation. */
export type MigrateMessageViewPayload = {
  __typename?: 'MigrateMessageViewPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `migratePageView` mutation. */
export type MigratePageViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  connStr?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `migratePageView` mutation. */
export type MigratePageViewPayload = {
  __typename?: 'MigratePageViewPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `migratePostView` mutation. */
export type MigratePostViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  connStr?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `migratePostView` mutation. */
export type MigratePostViewPayload = {
  __typename?: 'MigratePostViewPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  aggregatePostFeedInteractions?: Maybe<AggregatePostFeedInteractionsPayload>;
  aggregatePostFeedInteractionsAll?: Maybe<AggregatePostFeedInteractionsAllPayload>;
  aggregatePostFeedInteractionsAllV2?: Maybe<AggregatePostFeedInteractionsAllV2Payload>;
  aggregatePostFeedInteractionsNegativeReaction?: Maybe<AggregatePostFeedInteractionsNegativeReactionPayload>;
  aggregatePostFeedInteractionsNegativeReactionV2?: Maybe<AggregatePostFeedInteractionsNegativeReactionV2Payload>;
  aggregatePostFeedInteractionsNeutralReaction?: Maybe<AggregatePostFeedInteractionsNeutralReactionPayload>;
  aggregatePostFeedInteractionsNeutralReactionV2?: Maybe<AggregatePostFeedInteractionsNeutralReactionV2Payload>;
  aggregatePostFeedInteractionsNewPostsV2?: Maybe<AggregatePostFeedInteractionsNewPostsV2Payload>;
  aggregatePostFeedInteractionsPositiveReaction?: Maybe<AggregatePostFeedInteractionsPositiveReactionPayload>;
  aggregatePostFeedInteractionsPositiveReactionV2?: Maybe<AggregatePostFeedInteractionsPositiveReactionV2Payload>;
  aggregatePostFeedInteractionsQuote?: Maybe<AggregatePostFeedInteractionsQuotePayload>;
  aggregatePostFeedInteractionsQuoteV2?: Maybe<AggregatePostFeedInteractionsQuoteV2Payload>;
  aggregatePostFeedInteractionsReply?: Maybe<AggregatePostFeedInteractionsReplyPayload>;
  aggregatePostFeedInteractionsReplyV2?: Maybe<AggregatePostFeedInteractionsReplyV2Payload>;
  aggregatePostFeedInteractionsReport?: Maybe<AggregatePostFeedInteractionsReportPayload>;
  aggregatePostFeedInteractionsReportV2?: Maybe<AggregatePostFeedInteractionsReportV2Payload>;
  aggregatePostFeedInteractionsRepost?: Maybe<AggregatePostFeedInteractionsRepostPayload>;
  aggregatePostFeedInteractionsRepostV2?: Maybe<AggregatePostFeedInteractionsRepostV2Payload>;
  aggregatePostFeedInteractionsShowLess?: Maybe<AggregatePostFeedInteractionsShowLessPayload>;
  aggregatePostFeedInteractionsShowLessV2?: Maybe<AggregatePostFeedInteractionsShowLessV2Payload>;
  aggregatePostFeedInteractionsTip?: Maybe<AggregatePostFeedInteractionsTipPayload>;
  aggregatePostFeedInteractionsTipV2?: Maybe<AggregatePostFeedInteractionsTipV2Payload>;
  aggregatePostFeedInteractionsView?: Maybe<AggregatePostFeedInteractionsViewPayload>;
  aggregateUserFeedInteractions?: Maybe<AggregateUserFeedInteractionsPayload>;
  aggregateUserFeedInteractionsAll?: Maybe<AggregateUserFeedInteractionsAllPayload>;
  aggregateUserFeedInteractionsAllV2?: Maybe<AggregateUserFeedInteractionsAllV2Payload>;
  aggregateUserFeedInteractionsBlock?: Maybe<AggregateUserFeedInteractionsBlockPayload>;
  aggregateUserFeedInteractionsBlockV2?: Maybe<AggregateUserFeedInteractionsBlockV2Payload>;
  aggregateUserFeedInteractionsFocusFollow?: Maybe<AggregateUserFeedInteractionsFocusFollowPayload>;
  aggregateUserFeedInteractionsFollow?: Maybe<AggregateUserFeedInteractionsFollowPayload>;
  aggregateUserFeedInteractionsFollowV2?: Maybe<AggregateUserFeedInteractionsFollowV2Payload>;
  aggregateUserFeedInteractionsNegativeReaction?: Maybe<AggregateUserFeedInteractionsNegativeReactionPayload>;
  aggregateUserFeedInteractionsNegativeReactionV2?: Maybe<AggregateUserFeedInteractionsNegativeReactionV2Payload>;
  aggregateUserFeedInteractionsNeutralReaction?: Maybe<AggregateUserFeedInteractionsNeutralReactionPayload>;
  aggregateUserFeedInteractionsNeutralReactionV2?: Maybe<AggregateUserFeedInteractionsNeutralReactionV2Payload>;
  aggregateUserFeedInteractionsPositiveReaction?: Maybe<AggregateUserFeedInteractionsPositiveReactionPayload>;
  aggregateUserFeedInteractionsPositiveReactionV2?: Maybe<AggregateUserFeedInteractionsPositiveReactionV2Payload>;
  aggregateUserFeedInteractionsQuote?: Maybe<AggregateUserFeedInteractionsQuotePayload>;
  aggregateUserFeedInteractionsQuoteV2?: Maybe<AggregateUserFeedInteractionsQuoteV2Payload>;
  aggregateUserFeedInteractionsReply?: Maybe<AggregateUserFeedInteractionsReplyPayload>;
  aggregateUserFeedInteractionsReplyV2?: Maybe<AggregateUserFeedInteractionsReplyV2Payload>;
  aggregateUserFeedInteractionsReport?: Maybe<AggregateUserFeedInteractionsReportPayload>;
  aggregateUserFeedInteractionsReportV2?: Maybe<AggregateUserFeedInteractionsReportV2Payload>;
  aggregateUserFeedInteractionsRepost?: Maybe<AggregateUserFeedInteractionsRepostPayload>;
  aggregateUserFeedInteractionsRepostV2?: Maybe<AggregateUserFeedInteractionsRepostV2Payload>;
  aggregateUserFeedInteractionsShowLess?: Maybe<AggregateUserFeedInteractionsShowLessPayload>;
  aggregateUserFeedInteractionsShowLessV2?: Maybe<AggregateUserFeedInteractionsShowLessV2Payload>;
  aggregateUserFeedInteractionsTip?: Maybe<AggregateUserFeedInteractionsTipPayload>;
  aggregateUserFeedInteractionsTipV2?: Maybe<AggregateUserFeedInteractionsTipV2Payload>;
  aggregateUserFeedInteractionsV2?: Maybe<AggregateUserFeedInteractionsV2Payload>;
  calculateDecay?: Maybe<CalculateDecayPayload>;
  computeDecayScore?: Maybe<ComputeDecayScorePayload>;
  computeDecayScoreWithWealth?: Maybe<ComputeDecayScoreWithWealthPayload>;
  computeUpdatedDecayScore?: Maybe<ComputeUpdatedDecayScorePayload>;
  computeWeightedPostScores?: Maybe<ComputeWeightedPostScoresPayload>;
  computeWeightedUserRelationshipScores?: Maybe<ComputeWeightedUserRelationshipScoresPayload>;
  decayAllPostScores?: Maybe<DecayAllPostScoresPayload>;
  diamondLevelToDesoNanos?: Maybe<DiamondLevelToDesoNanosPayload>;
  feedCalculateUserRelationshipScore?: Maybe<FeedCalculateUserRelationshipScorePayload>;
  feedCombineRelationshipPostScores?: Maybe<FeedCombineRelationshipPostScoresPayload>;
  feedCombineScoresAndRelationships?: Maybe<FeedCombineScoresAndRelationshipsPayload>;
  feedCreateFinalUserPostScores?: Maybe<FeedCreateFinalUserPostScoresPayload>;
  feedGetUnifiedRelationshipScore?: Maybe<FeedGetUnifiedRelationshipScorePayload>;
  feedRefreshAll?: Maybe<FeedRefreshAllPayload>;
  feedRefreshPosts?: Maybe<FeedRefreshPostsPayload>;
  feedRefreshUsers?: Maybe<FeedRefreshUsersPayload>;
  feedUpdatePostScores?: Maybe<FeedUpdatePostScoresPayload>;
  feedUpdatePostScoresForUsers?: Maybe<FeedUpdatePostScoresForUsersPayload>;
  feedUpdateUserRelationshipScores?: Maybe<FeedUpdateUserRelationshipScoresPayload>;
  feedUpdateUserRelationshipScores1D?: Maybe<FeedUpdateUserRelationshipScores1DPayload>;
  feedUpdateUserRelationshipScores2D?: Maybe<FeedUpdateUserRelationshipScores2DPayload>;
  generateAllFeeds?: Maybe<GenerateAllFeedsPayload>;
  generateFeed?: Maybe<GenerateFeedPayload>;
  getCoinValueUsdCentsV2?: Maybe<GetCoinValueUsdCentsV2Payload>;
  getFeedSettings?: Maybe<GetFeedSettingsPayload>;
  getTransactionCount?: Maybe<GetTransactionCountPayload>;
  insertFeedInteractionsAll?: Maybe<InsertFeedInteractionsAllPayload>;
  insertFeedInteractionsAllV2?: Maybe<InsertFeedInteractionsAllV2Payload>;
  insertFeedInteractionsBlock?: Maybe<InsertFeedInteractionsBlockPayload>;
  insertFeedInteractionsBlockV2?: Maybe<InsertFeedInteractionsBlockV2Payload>;
  insertFeedInteractionsFocusFollow?: Maybe<InsertFeedInteractionsFocusFollowPayload>;
  insertFeedInteractionsFollow?: Maybe<InsertFeedInteractionsFollowPayload>;
  insertFeedInteractionsFollowV2?: Maybe<InsertFeedInteractionsFollowV2Payload>;
  insertFeedInteractionsNegativeReaction?: Maybe<InsertFeedInteractionsNegativeReactionPayload>;
  insertFeedInteractionsNegativeReactionV2?: Maybe<InsertFeedInteractionsNegativeReactionV2Payload>;
  insertFeedInteractionsNeutralReaction?: Maybe<InsertFeedInteractionsNeutralReactionPayload>;
  insertFeedInteractionsNeutralReactionV2?: Maybe<InsertFeedInteractionsNeutralReactionV2Payload>;
  insertFeedInteractionsNewPostsV2?: Maybe<InsertFeedInteractionsNewPostsV2Payload>;
  insertFeedInteractionsPositiveReaction?: Maybe<InsertFeedInteractionsPositiveReactionPayload>;
  insertFeedInteractionsPositiveReactionV2?: Maybe<InsertFeedInteractionsPositiveReactionV2Payload>;
  insertFeedInteractionsQuote?: Maybe<InsertFeedInteractionsQuotePayload>;
  insertFeedInteractionsQuoteV2?: Maybe<InsertFeedInteractionsQuoteV2Payload>;
  insertFeedInteractionsReply?: Maybe<InsertFeedInteractionsReplyPayload>;
  insertFeedInteractionsReplyV2?: Maybe<InsertFeedInteractionsReplyV2Payload>;
  insertFeedInteractionsReport?: Maybe<InsertFeedInteractionsReportPayload>;
  insertFeedInteractionsReportV2?: Maybe<InsertFeedInteractionsReportV2Payload>;
  insertFeedInteractionsRepost?: Maybe<InsertFeedInteractionsRepostPayload>;
  insertFeedInteractionsRepostV2?: Maybe<InsertFeedInteractionsRepostV2Payload>;
  insertFeedInteractionsShowLess?: Maybe<InsertFeedInteractionsShowLessPayload>;
  insertFeedInteractionsShowLessV2?: Maybe<InsertFeedInteractionsShowLessV2Payload>;
  insertFeedInteractionsTip?: Maybe<InsertFeedInteractionsTipPayload>;
  insertFeedInteractionsTipV2?: Maybe<InsertFeedInteractionsTipV2Payload>;
  intToBytea?: Maybe<IntToByteaPayload>;
  jsonbToBytea?: Maybe<JsonbToByteaPayload>;
  migrateMessageView?: Maybe<MigrateMessageViewPayload>;
  migratePageView?: Maybe<MigratePageViewPayload>;
  migratePostView?: Maybe<MigratePostViewPayload>;
  refreshAccountWealth?: Maybe<RefreshAccountWealthPayload>;
  refreshAccountWealthChainUsers?: Maybe<RefreshAccountWealthChainUsersPayload>;
  refreshAccountWealthCombined?: Maybe<RefreshAccountWealthCombinedPayload>;
  refreshActiveUserFeeds?: Maybe<RefreshActiveUserFeedsPayload>;
  refreshFocusPrice?: Maybe<RefreshFocusPricePayload>;
  refreshFocusUsers?: Maybe<RefreshFocusUsersPayload>;
  refreshMessageThread?: Maybe<RefreshMessageThreadPayload>;
  refreshMessageThreadUnreadCount?: Maybe<RefreshMessageThreadUnreadCountPayload>;
  refreshPostWealthMv?: Maybe<RefreshPostWealthMvPayload>;
  refreshTokenBalanceAgg?: Maybe<RefreshTokenBalanceAggPayload>;
  refreshWalletWealth?: Maybe<RefreshWalletWealthPayload>;
  refreshWalletWealthScoped?: Maybe<RefreshWalletWealthScopedPayload>;
  resetAllFeedTables?: Maybe<ResetAllFeedTablesPayload>;
  sigmoid?: Maybe<SigmoidPayload>;
  updateInteractionsWithNewBlocks?: Maybe<UpdateInteractionsWithNewBlocksPayload>;
  updatePostScoresWithNewBlocks?: Maybe<UpdatePostScoresWithNewBlocksPayload>;
  updateUserScoresWithNewBlocks?: Maybe<UpdateUserScoresWithNewBlocksPayload>;
  usdCentsToDiamondLevel?: Maybe<UsdCentsToDiamondLevelPayload>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsArgs = {
  input: AggregatePostFeedInteractionsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsAllArgs = {
  input: AggregatePostFeedInteractionsAllInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsAllV2Args = {
  input: AggregatePostFeedInteractionsAllV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsNegativeReactionArgs = {
  input: AggregatePostFeedInteractionsNegativeReactionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsNegativeReactionV2Args = {
  input: AggregatePostFeedInteractionsNegativeReactionV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsNeutralReactionArgs = {
  input: AggregatePostFeedInteractionsNeutralReactionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsNeutralReactionV2Args = {
  input: AggregatePostFeedInteractionsNeutralReactionV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsNewPostsV2Args = {
  input: AggregatePostFeedInteractionsNewPostsV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsPositiveReactionArgs = {
  input: AggregatePostFeedInteractionsPositiveReactionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsPositiveReactionV2Args = {
  input: AggregatePostFeedInteractionsPositiveReactionV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsQuoteArgs = {
  input: AggregatePostFeedInteractionsQuoteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsQuoteV2Args = {
  input: AggregatePostFeedInteractionsQuoteV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsReplyArgs = {
  input: AggregatePostFeedInteractionsReplyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsReplyV2Args = {
  input: AggregatePostFeedInteractionsReplyV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsReportArgs = {
  input: AggregatePostFeedInteractionsReportInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsReportV2Args = {
  input: AggregatePostFeedInteractionsReportV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsRepostArgs = {
  input: AggregatePostFeedInteractionsRepostInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsRepostV2Args = {
  input: AggregatePostFeedInteractionsRepostV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsShowLessArgs = {
  input: AggregatePostFeedInteractionsShowLessInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsShowLessV2Args = {
  input: AggregatePostFeedInteractionsShowLessV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsTipArgs = {
  input: AggregatePostFeedInteractionsTipInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsTipV2Args = {
  input: AggregatePostFeedInteractionsTipV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregatePostFeedInteractionsViewArgs = {
  input: AggregatePostFeedInteractionsViewInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsArgs = {
  input: AggregateUserFeedInteractionsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsAllArgs = {
  input: AggregateUserFeedInteractionsAllInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsAllV2Args = {
  input: AggregateUserFeedInteractionsAllV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsBlockArgs = {
  input: AggregateUserFeedInteractionsBlockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsBlockV2Args = {
  input: AggregateUserFeedInteractionsBlockV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsFocusFollowArgs = {
  input: AggregateUserFeedInteractionsFocusFollowInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsFollowArgs = {
  input: AggregateUserFeedInteractionsFollowInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsFollowV2Args = {
  input: AggregateUserFeedInteractionsFollowV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsNegativeReactionArgs = {
  input: AggregateUserFeedInteractionsNegativeReactionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsNegativeReactionV2Args = {
  input: AggregateUserFeedInteractionsNegativeReactionV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsNeutralReactionArgs = {
  input: AggregateUserFeedInteractionsNeutralReactionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsNeutralReactionV2Args = {
  input: AggregateUserFeedInteractionsNeutralReactionV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsPositiveReactionArgs = {
  input: AggregateUserFeedInteractionsPositiveReactionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsPositiveReactionV2Args = {
  input: AggregateUserFeedInteractionsPositiveReactionV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsQuoteArgs = {
  input: AggregateUserFeedInteractionsQuoteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsQuoteV2Args = {
  input: AggregateUserFeedInteractionsQuoteV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsReplyArgs = {
  input: AggregateUserFeedInteractionsReplyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsReplyV2Args = {
  input: AggregateUserFeedInteractionsReplyV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsReportArgs = {
  input: AggregateUserFeedInteractionsReportInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsReportV2Args = {
  input: AggregateUserFeedInteractionsReportV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsRepostArgs = {
  input: AggregateUserFeedInteractionsRepostInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsRepostV2Args = {
  input: AggregateUserFeedInteractionsRepostV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsShowLessArgs = {
  input: AggregateUserFeedInteractionsShowLessInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsShowLessV2Args = {
  input: AggregateUserFeedInteractionsShowLessV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsTipArgs = {
  input: AggregateUserFeedInteractionsTipInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsTipV2Args = {
  input: AggregateUserFeedInteractionsTipV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAggregateUserFeedInteractionsV2Args = {
  input: AggregateUserFeedInteractionsV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCalculateDecayArgs = {
  input: CalculateDecayInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationComputeDecayScoreArgs = {
  input: ComputeDecayScoreInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationComputeDecayScoreWithWealthArgs = {
  input: ComputeDecayScoreWithWealthInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationComputeUpdatedDecayScoreArgs = {
  input: ComputeUpdatedDecayScoreInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationComputeWeightedPostScoresArgs = {
  input: ComputeWeightedPostScoresInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationComputeWeightedUserRelationshipScoresArgs = {
  input: ComputeWeightedUserRelationshipScoresInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDecayAllPostScoresArgs = {
  input: DecayAllPostScoresInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDiamondLevelToDesoNanosArgs = {
  input: DiamondLevelToDesoNanosInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationFeedCalculateUserRelationshipScoreArgs = {
  input: FeedCalculateUserRelationshipScoreInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationFeedCombineRelationshipPostScoresArgs = {
  input: FeedCombineRelationshipPostScoresInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationFeedCombineScoresAndRelationshipsArgs = {
  input: FeedCombineScoresAndRelationshipsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationFeedCreateFinalUserPostScoresArgs = {
  input: FeedCreateFinalUserPostScoresInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationFeedGetUnifiedRelationshipScoreArgs = {
  input: FeedGetUnifiedRelationshipScoreInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationFeedRefreshAllArgs = {
  input: FeedRefreshAllInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationFeedRefreshPostsArgs = {
  input: FeedRefreshPostsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationFeedRefreshUsersArgs = {
  input: FeedRefreshUsersInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationFeedUpdatePostScoresArgs = {
  input: FeedUpdatePostScoresInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationFeedUpdatePostScoresForUsersArgs = {
  input: FeedUpdatePostScoresForUsersInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationFeedUpdateUserRelationshipScoresArgs = {
  input: FeedUpdateUserRelationshipScoresInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationFeedUpdateUserRelationshipScores1DArgs = {
  input: FeedUpdateUserRelationshipScores1DInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationFeedUpdateUserRelationshipScores2DArgs = {
  input: FeedUpdateUserRelationshipScores2DInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateAllFeedsArgs = {
  input: GenerateAllFeedsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateFeedArgs = {
  input: GenerateFeedInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetCoinValueUsdCentsV2Args = {
  input: GetCoinValueUsdCentsV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetFeedSettingsArgs = {
  input: GetFeedSettingsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetTransactionCountArgs = {
  input: GetTransactionCountInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsAllArgs = {
  input: InsertFeedInteractionsAllInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsAllV2Args = {
  input: InsertFeedInteractionsAllV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsBlockArgs = {
  input: InsertFeedInteractionsBlockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsBlockV2Args = {
  input: InsertFeedInteractionsBlockV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsFocusFollowArgs = {
  input: InsertFeedInteractionsFocusFollowInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsFollowArgs = {
  input: InsertFeedInteractionsFollowInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsFollowV2Args = {
  input: InsertFeedInteractionsFollowV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsNegativeReactionArgs = {
  input: InsertFeedInteractionsNegativeReactionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsNegativeReactionV2Args = {
  input: InsertFeedInteractionsNegativeReactionV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsNeutralReactionArgs = {
  input: InsertFeedInteractionsNeutralReactionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsNeutralReactionV2Args = {
  input: InsertFeedInteractionsNeutralReactionV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsNewPostsV2Args = {
  input: InsertFeedInteractionsNewPostsV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsPositiveReactionArgs = {
  input: InsertFeedInteractionsPositiveReactionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsPositiveReactionV2Args = {
  input: InsertFeedInteractionsPositiveReactionV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsQuoteArgs = {
  input: InsertFeedInteractionsQuoteInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsQuoteV2Args = {
  input: InsertFeedInteractionsQuoteV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsReplyArgs = {
  input: InsertFeedInteractionsReplyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsReplyV2Args = {
  input: InsertFeedInteractionsReplyV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsReportArgs = {
  input: InsertFeedInteractionsReportInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsReportV2Args = {
  input: InsertFeedInteractionsReportV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsRepostArgs = {
  input: InsertFeedInteractionsRepostInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsRepostV2Args = {
  input: InsertFeedInteractionsRepostV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsShowLessArgs = {
  input: InsertFeedInteractionsShowLessInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsShowLessV2Args = {
  input: InsertFeedInteractionsShowLessV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsTipArgs = {
  input: InsertFeedInteractionsTipInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertFeedInteractionsTipV2Args = {
  input: InsertFeedInteractionsTipV2Input;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationIntToByteaArgs = {
  input: IntToByteaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationJsonbToByteaArgs = {
  input: JsonbToByteaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMigrateMessageViewArgs = {
  input: MigrateMessageViewInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMigratePageViewArgs = {
  input: MigratePageViewInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMigratePostViewArgs = {
  input: MigratePostViewInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshAccountWealthArgs = {
  input: RefreshAccountWealthInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshAccountWealthChainUsersArgs = {
  input: RefreshAccountWealthChainUsersInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshAccountWealthCombinedArgs = {
  input: RefreshAccountWealthCombinedInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshActiveUserFeedsArgs = {
  input: RefreshActiveUserFeedsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshFocusPriceArgs = {
  input: RefreshFocusPriceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshFocusUsersArgs = {
  input: RefreshFocusUsersInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshMessageThreadArgs = {
  input: RefreshMessageThreadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshMessageThreadUnreadCountArgs = {
  input: RefreshMessageThreadUnreadCountInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshPostWealthMvArgs = {
  input: RefreshPostWealthMvInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshTokenBalanceAggArgs = {
  input: RefreshTokenBalanceAggInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshWalletWealthArgs = {
  input: RefreshWalletWealthInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshWalletWealthScopedArgs = {
  input: RefreshWalletWealthScopedInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetAllFeedTablesArgs = {
  input: ResetAllFeedTablesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSigmoidArgs = {
  input: SigmoidInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInteractionsWithNewBlocksArgs = {
  input: UpdateInteractionsWithNewBlocksInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostScoresWithNewBlocksArgs = {
  input: UpdatePostScoresWithNewBlocksInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserScoresWithNewBlocksArgs = {
  input: UpdateUserScoresWithNewBlocksInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUsdCentsToDiamondLevelArgs = {
  input: UsdCentsToDiamondLevelInput;
};

/** A connection to a list of `MyStakeSummary` values. */
export type MyStakeSummariesConnection = {
  __typename?: 'MyStakeSummariesConnection';
  /** A list of edges which contains the `MyStakeSummary` and cursor to aid in pagination. */
  edges: Array<MyStakeSummariesEdge>;
  /** A list of `MyStakeSummary` objects. */
  nodes: Array<Maybe<MyStakeSummary>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MyStakeSummary` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `MyStakeSummary` edge in the connection. */
export type MyStakeSummariesEdge = {
  __typename?: 'MyStakeSummariesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `MyStakeSummary` at the end of the edge. */
  node?: Maybe<MyStakeSummary>;
};

/** Methods to use when ordering `MyStakeSummary`. */
export enum MyStakeSummariesOrderBy {
  Natural = 'NATURAL',
  StakerPkidAsc = 'STAKER_PKID_ASC',
  StakerPkidDesc = 'STAKER_PKID_DESC',
  TotalLockedStakeAsc = 'TOTAL_LOCKED_STAKE_ASC',
  TotalLockedStakeDesc = 'TOTAL_LOCKED_STAKE_DESC',
  TotalStakeAsc = 'TOTAL_STAKE_ASC',
  TotalStakeDesc = 'TOTAL_STAKE_DESC',
  TotalStakeRewardsAsc = 'TOTAL_STAKE_REWARDS_ASC',
  TotalStakeRewardsDesc = 'TOTAL_STAKE_REWARDS_DESC',
}

export type MyStakeSummary = {
  __typename?: 'MyStakeSummary';
  /** Reads a single `Account` that is related to this `MyStakeSummary`. */
  staker?: Maybe<Account>;
  stakerPkid?: Maybe<Scalars['String']['output']>;
  totalLockedStake?: Maybe<Scalars['BigFloat']['output']>;
  totalStake?: Maybe<Scalars['BigFloat']['output']>;
  totalStakeRewards?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `MyStakeSummary` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MyStakeSummaryCondition = {
  /** Checks for equality with the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `totalLockedStake` field. */
  totalLockedStake?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalStake` field. */
  totalStake?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalStakeRewards` field. */
  totalStakeRewards?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `MyStakeSummary` object types. All fields are combined with a logical ‘and.’ */
export type MyStakeSummaryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MyStakeSummaryFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<MyStakeSummaryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MyStakeSummaryFilter>>;
  /** Filter by the object’s `staker` relation. */
  staker?: InputMaybe<AccountFilter>;
  /** A related `staker` exists. */
  stakerExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalLockedStake` field. */
  totalLockedStake?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalStake` field. */
  totalStake?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalStakeRewards` field. */
  totalStakeRewards?: InputMaybe<BigFloatFilter>;
};

export type Nft = Node & {
  __typename?: 'Nft';
  buyNowPriceNanos?: Maybe<Scalars['BigInt']['output']>;
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  isBuyNow: Scalars['Boolean']['output'];
  isForSale: Scalars['Boolean']['output'];
  isPending: Scalars['Boolean']['output'];
  lastAcceptedBidAmountNanos?: Maybe<Scalars['BigInt']['output']>;
  /** Reads a single `Account` that is related to this `Nft`. */
  lastOwner?: Maybe<Account>;
  lastOwnerPkid?: Maybe<Scalars['String']['output']>;
  minBidAmountNanos?: Maybe<Scalars['BigInt']['output']>;
  nftPostHash: Scalars['String']['output'];
  /** Reads a single `Account` that is related to this `Nft`. */
  owner?: Maybe<Account>;
  ownerPkid: Scalars['String']['output'];
  /** Reads a single `Post` that is related to this `Nft`. */
  post?: Maybe<Post>;
  serialNumber: Scalars['BigInt']['output'];
  unlockableText?: Maybe<Scalars['String']['output']>;
};

export type NftBid = Node & {
  __typename?: 'NftBid';
  acceptedBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  bidAmountNanos: Scalars['BigInt']['output'];
  /** Reads a single `Account` that is related to this `NftBid`. */
  bidder?: Maybe<Account>;
  bidderPkid: Scalars['String']['output'];
  /** Reads a single `Block` that is related to this `NftBid`. */
  block?: Maybe<Block>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  nftPostHash: Scalars['String']['output'];
  /** Reads a single `Post` that is related to this `NftBid`. */
  post?: Maybe<Post>;
  serialNumber: Scalars['BigInt']['output'];
};

/** A condition to be used against `NftBid` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type NftBidCondition = {
  /** Checks for equality with the object’s `acceptedBlockHeight` field. */
  acceptedBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `bidAmountNanos` field. */
  bidAmountNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `bidderPkid` field. */
  bidderPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `nftPostHash` field. */
  nftPostHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `serialNumber` field. */
  serialNumber?: InputMaybe<Scalars['BigInt']['input']>;
};

/** A filter to be used against `NftBid` object types. All fields are combined with a logical ‘and.’ */
export type NftBidFilter = {
  /** Filter by the object’s `acceptedBlockHeight` field. */
  acceptedBlockHeight?: InputMaybe<BigIntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NftBidFilter>>;
  /** Filter by the object’s `bidAmountNanos` field. */
  bidAmountNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `bidder` relation. */
  bidder?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `bidderPkid` field. */
  bidderPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `block` relation. */
  block?: InputMaybe<BlockFilter>;
  /** A related `block` exists. */
  blockExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `nftPostHash` field. */
  nftPostHash?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NftBidFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NftBidFilter>>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** Filter by the object’s `serialNumber` field. */
  serialNumber?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `NftBid` values. */
export type NftBidsConnection = {
  __typename?: 'NftBidsConnection';
  /** A list of edges which contains the `NftBid` and cursor to aid in pagination. */
  edges: Array<NftBidsEdge>;
  /** A list of `NftBid` objects. */
  nodes: Array<Maybe<NftBid>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NftBid` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `NftBid` edge in the connection. */
export type NftBidsEdge = {
  __typename?: 'NftBidsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `NftBid` at the end of the edge. */
  node?: Maybe<NftBid>;
};

/** Methods to use when ordering `NftBid`. */
export enum NftBidsOrderBy {
  AcceptedBlockHeightAsc = 'ACCEPTED_BLOCK_HEIGHT_ASC',
  AcceptedBlockHeightDesc = 'ACCEPTED_BLOCK_HEIGHT_DESC',
  BidderPkidAsc = 'BIDDER_PKID_ASC',
  BidderPkidDesc = 'BIDDER_PKID_DESC',
  BidAmountNanosAsc = 'BID_AMOUNT_NANOS_ASC',
  BidAmountNanosDesc = 'BID_AMOUNT_NANOS_DESC',
  Natural = 'NATURAL',
  NftPostHashAsc = 'NFT_POST_HASH_ASC',
  NftPostHashDesc = 'NFT_POST_HASH_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SerialNumberAsc = 'SERIAL_NUMBER_ASC',
  SerialNumberDesc = 'SERIAL_NUMBER_DESC',
}

/** A condition to be used against `Nft` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type NftCondition = {
  /** Checks for equality with the object’s `buyNowPriceNanos` field. */
  buyNowPriceNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `isBuyNow` field. */
  isBuyNow?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isForSale` field. */
  isForSale?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isPending` field. */
  isPending?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `lastAcceptedBidAmountNanos` field. */
  lastAcceptedBidAmountNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `lastOwnerPkid` field. */
  lastOwnerPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `minBidAmountNanos` field. */
  minBidAmountNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `nftPostHash` field. */
  nftPostHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `ownerPkid` field. */
  ownerPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `serialNumber` field. */
  serialNumber?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `unlockableText` field. */
  unlockableText?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Nft` object types. All fields are combined with a logical ‘and.’ */
export type NftFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NftFilter>>;
  /** Filter by the object’s `buyNowPriceNanos` field. */
  buyNowPriceNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `isBuyNow` field. */
  isBuyNow?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isForSale` field. */
  isForSale?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPending` field. */
  isPending?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastAcceptedBidAmountNanos` field. */
  lastAcceptedBidAmountNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `lastOwner` relation. */
  lastOwner?: InputMaybe<AccountFilter>;
  /** A related `lastOwner` exists. */
  lastOwnerExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `lastOwnerPkid` field. */
  lastOwnerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `minBidAmountNanos` field. */
  minBidAmountNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `nftPostHash` field. */
  nftPostHash?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NftFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NftFilter>>;
  /** Filter by the object’s `owner` relation. */
  owner?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `ownerPkid` field. */
  ownerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** Filter by the object’s `serialNumber` field. */
  serialNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `unlockableText` field. */
  unlockableText?: InputMaybe<StringFilter>;
};

/** A connection to a list of `Nft` values. */
export type NftsConnection = {
  __typename?: 'NftsConnection';
  /** A list of edges which contains the `Nft` and cursor to aid in pagination. */
  edges: Array<NftsEdge>;
  /** A list of `Nft` objects. */
  nodes: Array<Maybe<Nft>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Nft` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Nft` edge in the connection. */
export type NftsEdge = {
  __typename?: 'NftsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Nft` at the end of the edge. */
  node?: Maybe<Nft>;
};

/** Methods to use when ordering `Nft`. */
export enum NftsOrderBy {
  BuyNowPriceNanosAsc = 'BUY_NOW_PRICE_NANOS_ASC',
  BuyNowPriceNanosDesc = 'BUY_NOW_PRICE_NANOS_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  IsBuyNowAsc = 'IS_BUY_NOW_ASC',
  IsBuyNowDesc = 'IS_BUY_NOW_DESC',
  IsForSaleAsc = 'IS_FOR_SALE_ASC',
  IsForSaleDesc = 'IS_FOR_SALE_DESC',
  IsPendingAsc = 'IS_PENDING_ASC',
  IsPendingDesc = 'IS_PENDING_DESC',
  LastAcceptedBidAmountNanosAsc = 'LAST_ACCEPTED_BID_AMOUNT_NANOS_ASC',
  LastAcceptedBidAmountNanosDesc = 'LAST_ACCEPTED_BID_AMOUNT_NANOS_DESC',
  LastOwnerPkidAsc = 'LAST_OWNER_PKID_ASC',
  LastOwnerPkidDesc = 'LAST_OWNER_PKID_DESC',
  MinBidAmountNanosAsc = 'MIN_BID_AMOUNT_NANOS_ASC',
  MinBidAmountNanosDesc = 'MIN_BID_AMOUNT_NANOS_DESC',
  Natural = 'NATURAL',
  NftPostHashAsc = 'NFT_POST_HASH_ASC',
  NftPostHashDesc = 'NFT_POST_HASH_DESC',
  OwnerPkidAsc = 'OWNER_PKID_ASC',
  OwnerPkidDesc = 'OWNER_PKID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SerialNumberAsc = 'SERIAL_NUMBER_ASC',
  SerialNumberDesc = 'SERIAL_NUMBER_DESC',
  UnlockableTextAsc = 'UNLOCKABLE_TEXT_ASC',
  UnlockableTextDesc = 'UNLOCKABLE_TEXT_DESC',
}

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type PageView = Node & {
  __typename?: 'PageView';
  createdAt: Scalars['Datetime']['output'];
  deletedAt?: Maybe<Scalars['Datetime']['output']>;
  host: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  pathParam0?: Maybe<Scalars['String']['output']>;
  pathParam1?: Maybe<Scalars['String']['output']>;
  pathParam2?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  queryParam0?: Maybe<Scalars['String']['output']>;
  queryParam1?: Maybe<Scalars['String']['output']>;
  queryParam2?: Maybe<Scalars['String']['output']>;
  queryParam3?: Maybe<Scalars['String']['output']>;
  queryParam4?: Maybe<Scalars['String']['output']>;
  route: Scalars['String']['output'];
  rowId: Scalars['UUID']['output'];
  /** Reads a single `Account` that is related to this `PageView`. */
  viewer?: Maybe<Account>;
};

/**
 * A condition to be used against `PageView` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PageViewCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `host` field. */
  host?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `pathParam0` field. */
  pathParam0?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `pathParam1` field. */
  pathParam1?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `pathParam2` field. */
  pathParam2?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `queryParam0` field. */
  queryParam0?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `queryParam1` field. */
  queryParam1?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `queryParam2` field. */
  queryParam2?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `queryParam3` field. */
  queryParam3?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `queryParam4` field. */
  queryParam4?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `route` field. */
  route?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `PageView` object types. All fields are combined with a logical ‘and.’ */
export type PageViewFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PageViewFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `host` field. */
  host?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PageViewFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PageViewFilter>>;
  /** Filter by the object’s `pathParam0` field. */
  pathParam0?: InputMaybe<StringFilter>;
  /** Filter by the object’s `pathParam1` field. */
  pathParam1?: InputMaybe<StringFilter>;
  /** Filter by the object’s `pathParam2` field. */
  pathParam2?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `queryParam0` field. */
  queryParam0?: InputMaybe<StringFilter>;
  /** Filter by the object’s `queryParam1` field. */
  queryParam1?: InputMaybe<StringFilter>;
  /** Filter by the object’s `queryParam2` field. */
  queryParam2?: InputMaybe<StringFilter>;
  /** Filter by the object’s `queryParam3` field. */
  queryParam3?: InputMaybe<StringFilter>;
  /** Filter by the object’s `queryParam4` field. */
  queryParam4?: InputMaybe<StringFilter>;
  /** Filter by the object’s `route` field. */
  route?: InputMaybe<StringFilter>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `viewer` relation. */
  viewer?: InputMaybe<AccountFilter>;
  /** A related `viewer` exists. */
  viewerExists?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A connection to a list of `PageView` values. */
export type PageViewsConnection = {
  __typename?: 'PageViewsConnection';
  /** A list of edges which contains the `PageView` and cursor to aid in pagination. */
  edges: Array<PageViewsEdge>;
  /** A list of `PageView` objects. */
  nodes: Array<Maybe<PageView>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PageView` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `PageView` edge in the connection. */
export type PageViewsEdge = {
  __typename?: 'PageViewsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `PageView` at the end of the edge. */
  node?: Maybe<PageView>;
};

/** Methods to use when ordering `PageView`. */
export enum PageViewsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  HostAsc = 'HOST_ASC',
  HostDesc = 'HOST_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PathParam_0Asc = 'PATH_PARAM_0_ASC',
  PathParam_0Desc = 'PATH_PARAM_0_DESC',
  PathParam_1Asc = 'PATH_PARAM_1_ASC',
  PathParam_1Desc = 'PATH_PARAM_1_DESC',
  PathParam_2Asc = 'PATH_PARAM_2_ASC',
  PathParam_2Desc = 'PATH_PARAM_2_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  QueryParam_0Asc = 'QUERY_PARAM_0_ASC',
  QueryParam_0Desc = 'QUERY_PARAM_0_DESC',
  QueryParam_1Asc = 'QUERY_PARAM_1_ASC',
  QueryParam_1Desc = 'QUERY_PARAM_1_DESC',
  QueryParam_2Asc = 'QUERY_PARAM_2_ASC',
  QueryParam_2Desc = 'QUERY_PARAM_2_DESC',
  QueryParam_3Asc = 'QUERY_PARAM_3_ASC',
  QueryParam_3Desc = 'QUERY_PARAM_3_DESC',
  QueryParam_4Asc = 'QUERY_PARAM_4_ASC',
  QueryParam_4Desc = 'QUERY_PARAM_4_DESC',
  RouteAsc = 'ROUTE_ASC',
  RouteDesc = 'ROUTE_DESC',
}

export type Payment = Node & {
  __typename?: 'Payment';
  /** Reads a single `AccessGroup` that is related to this `Payment`. */
  accessGroup?: Maybe<AccessGroup>;
  accessGroupKeyName?: Maybe<Scalars['String']['output']>;
  accessGroupOwnerPublicKey?: Maybe<Scalars['String']['output']>;
  appPkid?: Maybe<Scalars['String']['output']>;
  associationId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  messageBadgerKey?: Maybe<Scalars['String']['output']>;
  paymentAmountUsdCents?: Maybe<Scalars['BigInt']['output']>;
  paymentUuid?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Account` that is related to this `Payment`. */
  recipient?: Maybe<Account>;
  recipientPublicKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Account` that is related to this `Payment`. */
  sender?: Maybe<Account>;
  senderPublicKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Subscription` that is related to this `Payment`. */
  subscription?: Maybe<Subscription>;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions: TransactionsConnection;
};

export type PaymentTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/** A condition to be used against `Payment` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PaymentCondition = {
  /** Checks for equality with the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `appPkid` field. */
  appPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationId` field. */
  associationId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `messageBadgerKey` field. */
  messageBadgerKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `paymentAmountUsdCents` field. */
  paymentAmountUsdCents?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `paymentUuid` field. */
  paymentUuid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `recipientPublicKey` field. */
  recipientPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `senderPublicKey` field. */
  senderPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Payment` object types. All fields are combined with a logical ‘and.’ */
export type PaymentFilter = {
  /** Filter by the object’s `accessGroup` relation. */
  accessGroup?: InputMaybe<AccessGroupFilter>;
  /** A related `accessGroup` exists. */
  accessGroupExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PaymentFilter>>;
  /** Filter by the object’s `appPkid` field. */
  appPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationId` field. */
  associationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PaymentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PaymentFilter>>;
  /** Filter by the object’s `paymentAmountUsdCents` field. */
  paymentAmountUsdCents?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `paymentUuid` field. */
  paymentUuid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `recipient` relation. */
  recipient?: InputMaybe<AccountFilter>;
  /** A related `recipient` exists. */
  recipientExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `recipientPublicKey` field. */
  recipientPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sender` relation. */
  sender?: InputMaybe<AccountFilter>;
  /** A related `sender` exists. */
  senderExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `senderPublicKey` field. */
  senderPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subscription` relation. */
  subscription?: InputMaybe<SubscriptionFilter>;
  /** A related `subscription` exists. */
  subscriptionExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `transactions` relation. */
  transactions?: InputMaybe<PaymentToManyTransactionFilter>;
  /** Some related `transactions` exist. */
  transactionsExist?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A filter to be used against many `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type PaymentToManyTransactionFilter = {
  /** Every related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TransactionFilter>;
  /** No related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TransactionFilter>;
  /** Some related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TransactionFilter>;
};

/** A connection to a list of `Payment` values. */
export type PaymentsConnection = {
  __typename?: 'PaymentsConnection';
  /** A list of edges which contains the `Payment` and cursor to aid in pagination. */
  edges: Array<PaymentsEdge>;
  /** A list of `Payment` objects. */
  nodes: Array<Maybe<Payment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Payment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Payment` edge in the connection. */
export type PaymentsEdge = {
  __typename?: 'PaymentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Payment` at the end of the edge. */
  node?: Maybe<Payment>;
};

/** Methods to use when ordering `Payment`. */
export enum PaymentsOrderBy {
  AccessGroupKeyNameAsc = 'ACCESS_GROUP_KEY_NAME_ASC',
  AccessGroupKeyNameDesc = 'ACCESS_GROUP_KEY_NAME_DESC',
  AccessGroupOwnerPublicKeyAsc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC',
  AccessGroupOwnerPublicKeyDesc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC',
  AppPkidAsc = 'APP_PKID_ASC',
  AppPkidDesc = 'APP_PKID_DESC',
  AssociationIdAsc = 'ASSOCIATION_ID_ASC',
  AssociationIdDesc = 'ASSOCIATION_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  MessageBadgerKeyAsc = 'MESSAGE_BADGER_KEY_ASC',
  MessageBadgerKeyDesc = 'MESSAGE_BADGER_KEY_DESC',
  Natural = 'NATURAL',
  PaymentAmountUsdCentsAsc = 'PAYMENT_AMOUNT_USD_CENTS_ASC',
  PaymentAmountUsdCentsDesc = 'PAYMENT_AMOUNT_USD_CENTS_DESC',
  PaymentUuidAsc = 'PAYMENT_UUID_ASC',
  PaymentUuidDesc = 'PAYMENT_UUID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RecipientPublicKeyAsc = 'RECIPIENT_PUBLIC_KEY_ASC',
  RecipientPublicKeyDesc = 'RECIPIENT_PUBLIC_KEY_DESC',
  SenderPublicKeyAsc = 'SENDER_PUBLIC_KEY_ASC',
  SenderPublicKeyDesc = 'SENDER_PUBLIC_KEY_DESC',
}

export type Post = {
  __typename?: 'Post';
  additionalNftRoyaltiesToCoinsBasisPoints?: Maybe<Scalars['JSON']['output']>;
  additionalNftRoyaltiesToCreatorsBasisPoints?: Maybe<Scalars['JSON']['output']>;
  badgerKey?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  diamondCount?: Maybe<Scalars['BigInt']['output']>;
  diamondLevelSum?: Maybe<Scalars['BigInt']['output']>;
  /** Reads a single `PostDiamondTotal` that is related to this `Post`. */
  diamondTotals?: Maybe<PostDiamondTotal>;
  /** Reads and enables pagination through a set of `Diamond`. */
  diamonds: DiamondsConnection;
  extraData?: Maybe<Scalars['JSON']['output']>;
  hasUnlockable?: Maybe<Scalars['Boolean']['output']>;
  imageUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isFrozen?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isNft?: Maybe<Scalars['Boolean']['output']>;
  isPinned?: Maybe<Scalars['Boolean']['output']>;
  isQuotedRepost?: Maybe<Scalars['Boolean']['output']>;
  /** Reads and enables pagination through a set of `Like`. */
  likes: LikesConnection;
  /** Reads and enables pagination through a set of `NftBid`. */
  nftBids: NftBidsConnection;
  nftRoyaltyToCoinBasisPoints?: Maybe<Scalars['BigInt']['output']>;
  nftRoyaltyToCreatorBasisPoints?: Maybe<Scalars['BigInt']['output']>;
  /** Reads and enables pagination through a set of `Nft`. */
  nfts: NftsConnection;
  numNftCopies?: Maybe<Scalars['BigInt']['output']>;
  numNftCopiesBurned?: Maybe<Scalars['BigInt']['output']>;
  numNftCopiesForSale?: Maybe<Scalars['BigInt']['output']>;
  /** Reads a single `Post` that is related to this `Post`. */
  parentPost?: Maybe<Post>;
  parentPostHash?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `PostAssociationCount`. */
  postAssociationCounts: PostAssociationCountsConnection;
  /** Reads and enables pagination through a set of `PostAssociation`. */
  postAssociationsByPostHash: PostAssociationsConnection;
  postHash: Scalars['String']['output'];
  /** Reads a single `Account` that is related to this `Post`. */
  poster?: Maybe<Account>;
  posterDesoBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  posterFocusTokenBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
  posterTotalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  posterUsdBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  /** Reads and enables pagination through a set of `Post`. */
  replies: PostsConnection;
  /** Reads a single `Post` that is related to this `Post`. */
  repostedPost?: Maybe<Post>;
  repostedPostHash?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Post`. */
  reposts: PostsConnection;
  /** Reads a single `AccessGroup` that is related to this `Post`. */
  subscriptionAccessGroup?: Maybe<AccessGroup>;
  subscriptionAccessGroupKeyName?: Maybe<Scalars['String']['output']>;
  subscriptionAccessGroupOwnerPublicKey?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `PostSubscriptionStat`. */
  subscriptionStats: PostSubscriptionStatsConnection;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
  totalDiamondValueUsdCents?: Maybe<Scalars['BigInt']['output']>;
  upvoteCappedWealthUsdCents?: Maybe<Scalars['Float']['output']>;
  /** Reads and enables pagination through a set of `PostWealth`. */
  upvoteWealth: PostWealthConnection;
  upvoteWealthUsdCents?: Maybe<Scalars['Float']['output']>;
  videoUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Reads and enables pagination through a set of `PostView`. */
  views: PostViewsConnection;
};

export type PostDiamondsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DiamondCondition>;
  filter?: InputMaybe<DiamondFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DiamondsOrderBy>>;
};

export type PostLikesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

export type PostNftBidsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<NftBidCondition>;
  filter?: InputMaybe<NftBidFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<NftBidsOrderBy>>;
};

export type PostNftsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<NftCondition>;
  filter?: InputMaybe<NftFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<NftsOrderBy>>;
};

export type PostPostAssociationCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostAssociationCountCondition>;
  filter?: InputMaybe<PostAssociationCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostAssociationCountsOrderBy>>;
};

export type PostPostAssociationsByPostHashArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostAssociationCondition>;
  filter?: InputMaybe<PostAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostAssociationsOrderBy>>;
};

export type PostRepliesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostCondition>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};

export type PostRepostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostCondition>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};

export type PostSubscriptionStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostSubscriptionStatCondition>;
  filter?: InputMaybe<PostSubscriptionStatFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostSubscriptionStatsOrderBy>>;
};

export type PostUpvoteWealthArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostWealthCondition>;
  filter?: InputMaybe<PostWealthFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostWealthOrderBy>>;
};

export type PostViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostViewCondition>;
  filter?: InputMaybe<PostViewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostViewsOrderBy>>;
};

export type PostAssociation = {
  __typename?: 'PostAssociation';
  amountUsdCents?: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Account` that is related to this `PostAssociation`. */
  app?: Maybe<Account>;
  appPkid?: Maybe<Scalars['String']['output']>;
  associationId?: Maybe<Scalars['String']['output']>;
  associationType?: Maybe<Scalars['String']['output']>;
  associationValue?: Maybe<Scalars['String']['output']>;
  badgerKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Block` that is related to this `PostAssociation`. */
  block?: Maybe<Block>;
  blockHeight?: Maybe<Scalars['Int']['output']>;
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** Reads a single `Post` that is related to this `PostAssociation`. */
  post?: Maybe<Post>;
  postHash?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Account` that is related to this `PostAssociation`. */
  sender?: Maybe<Account>;
  senderFocusTokenBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  senderPublicKey?: Maybe<Scalars['String']['output']>;
  senderTotalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  senderUsdBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  /** Reads a single `Account` that is related to this `PostAssociation`. */
  transactor?: Maybe<Account>;
  transactorFocusTokenBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  transactorPkid?: Maybe<Scalars['String']['output']>;
  transactorTotalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  transactorUsdBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `PostAssociation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PostAssociationCondition = {
  /** Checks for equality with the object’s `amountUsdCents` field. */
  amountUsdCents?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `appPkid` field. */
  appPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationId` field. */
  associationId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationType` field. */
  associationType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationValue` field. */
  associationValue?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `senderFocusTokenBalanceUsdCents` field. */
  senderFocusTokenBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `senderPublicKey` field. */
  senderPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `senderTotalBalanceUsdCents` field. */
  senderTotalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `senderUsdBalanceUsdCents` field. */
  senderUsdBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `transactorFocusTokenBalanceUsdCents` field. */
  transactorFocusTokenBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transactorTotalBalanceUsdCents` field. */
  transactorTotalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `transactorUsdBalanceUsdCents` field. */
  transactorUsdBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
};

export type PostAssociationCount = {
  __typename?: 'PostAssociationCount';
  associationType?: Maybe<Scalars['String']['output']>;
  associationValue?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['BigInt']['output']>;
  /** Reads a single `Post` that is related to this `PostAssociationCount`. */
  post?: Maybe<Post>;
  postHash?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `PostAssociationCount` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type PostAssociationCountCondition = {
  /** Checks for equality with the object’s `associationType` field. */
  associationType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationValue` field. */
  associationValue?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `count` field. */
  count?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `PostAssociationCount` object types. All fields are combined with a logical ‘and.’ */
export type PostAssociationCountFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostAssociationCountFilter>>;
  /** Filter by the object’s `associationType` field. */
  associationType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationValue` field. */
  associationValue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `count` field. */
  count?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostAssociationCountFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostAssociationCountFilter>>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** A related `post` exists. */
  postExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
};

/** A connection to a list of `PostAssociationCount` values. */
export type PostAssociationCountsConnection = {
  __typename?: 'PostAssociationCountsConnection';
  /** A list of edges which contains the `PostAssociationCount` and cursor to aid in pagination. */
  edges: Array<PostAssociationCountsEdge>;
  /** A list of `PostAssociationCount` objects. */
  nodes: Array<Maybe<PostAssociationCount>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PostAssociationCount` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `PostAssociationCount` edge in the connection. */
export type PostAssociationCountsEdge = {
  __typename?: 'PostAssociationCountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `PostAssociationCount` at the end of the edge. */
  node?: Maybe<PostAssociationCount>;
};

/** Methods to use when ordering `PostAssociationCount`. */
export enum PostAssociationCountsOrderBy {
  AssociationTypeAsc = 'ASSOCIATION_TYPE_ASC',
  AssociationTypeDesc = 'ASSOCIATION_TYPE_DESC',
  AssociationValueAsc = 'ASSOCIATION_VALUE_ASC',
  AssociationValueDesc = 'ASSOCIATION_VALUE_DESC',
  CountAsc = 'COUNT_ASC',
  CountDesc = 'COUNT_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
}

/** A filter to be used against `PostAssociation` object types. All fields are combined with a logical ‘and.’ */
export type PostAssociationFilter = {
  /** Filter by the object’s `amountUsdCents` field. */
  amountUsdCents?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostAssociationFilter>>;
  /** Filter by the object’s `app` relation. */
  app?: InputMaybe<AccountFilter>;
  /** A related `app` exists. */
  appExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `appPkid` field. */
  appPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationId` field. */
  associationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationType` field. */
  associationType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationValue` field. */
  associationValue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `block` relation. */
  block?: InputMaybe<BlockFilter>;
  /** A related `block` exists. */
  blockExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<IntFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostAssociationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostAssociationFilter>>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** A related `post` exists. */
  postExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sender` relation. */
  sender?: InputMaybe<AccountFilter>;
  /** A related `sender` exists. */
  senderExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `senderFocusTokenBalanceUsdCents` field. */
  senderFocusTokenBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `senderPublicKey` field. */
  senderPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `senderTotalBalanceUsdCents` field. */
  senderTotalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `senderUsdBalanceUsdCents` field. */
  senderUsdBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `transactor` relation. */
  transactor?: InputMaybe<AccountFilter>;
  /** A related `transactor` exists. */
  transactorExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `transactorFocusTokenBalanceUsdCents` field. */
  transactorFocusTokenBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactorTotalBalanceUsdCents` field. */
  transactorTotalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `transactorUsdBalanceUsdCents` field. */
  transactorUsdBalanceUsdCents?: InputMaybe<FloatFilter>;
};

/** A connection to a list of `PostAssociation` values. */
export type PostAssociationsConnection = {
  __typename?: 'PostAssociationsConnection';
  /** A list of edges which contains the `PostAssociation` and cursor to aid in pagination. */
  edges: Array<PostAssociationsEdge>;
  /** A list of `PostAssociation` objects. */
  nodes: Array<Maybe<PostAssociation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PostAssociation` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `PostAssociation` edge in the connection. */
export type PostAssociationsEdge = {
  __typename?: 'PostAssociationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `PostAssociation` at the end of the edge. */
  node?: Maybe<PostAssociation>;
};

/** Methods to use when ordering `PostAssociation`. */
export enum PostAssociationsOrderBy {
  AmountUsdCentsAsc = 'AMOUNT_USD_CENTS_ASC',
  AmountUsdCentsDesc = 'AMOUNT_USD_CENTS_DESC',
  AppPkidAsc = 'APP_PKID_ASC',
  AppPkidDesc = 'APP_PKID_DESC',
  AssociationIdAsc = 'ASSOCIATION_ID_ASC',
  AssociationIdDesc = 'ASSOCIATION_ID_DESC',
  AssociationTypeAsc = 'ASSOCIATION_TYPE_ASC',
  AssociationTypeDesc = 'ASSOCIATION_TYPE_DESC',
  AssociationValueAsc = 'ASSOCIATION_VALUE_ASC',
  AssociationValueDesc = 'ASSOCIATION_VALUE_DESC',
  BadgerKeyAsc = 'BADGER_KEY_ASC',
  BadgerKeyDesc = 'BADGER_KEY_DESC',
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  SenderFocusTokenBalanceUsdCentsAsc = 'SENDER_FOCUS_TOKEN_BALANCE_USD_CENTS_ASC',
  SenderFocusTokenBalanceUsdCentsDesc = 'SENDER_FOCUS_TOKEN_BALANCE_USD_CENTS_DESC',
  SenderPublicKeyAsc = 'SENDER_PUBLIC_KEY_ASC',
  SenderPublicKeyDesc = 'SENDER_PUBLIC_KEY_DESC',
  SenderTotalBalanceUsdCentsAsc = 'SENDER_TOTAL_BALANCE_USD_CENTS_ASC',
  SenderTotalBalanceUsdCentsDesc = 'SENDER_TOTAL_BALANCE_USD_CENTS_DESC',
  SenderUsdBalanceUsdCentsAsc = 'SENDER_USD_BALANCE_USD_CENTS_ASC',
  SenderUsdBalanceUsdCentsDesc = 'SENDER_USD_BALANCE_USD_CENTS_DESC',
  TransactorFocusTokenBalanceUsdCentsAsc = 'TRANSACTOR_FOCUS_TOKEN_BALANCE_USD_CENTS_ASC',
  TransactorFocusTokenBalanceUsdCentsDesc = 'TRANSACTOR_FOCUS_TOKEN_BALANCE_USD_CENTS_DESC',
  TransactorPkidAsc = 'TRANSACTOR_PKID_ASC',
  TransactorPkidDesc = 'TRANSACTOR_PKID_DESC',
  TransactorTotalBalanceUsdCentsAsc = 'TRANSACTOR_TOTAL_BALANCE_USD_CENTS_ASC',
  TransactorTotalBalanceUsdCentsDesc = 'TRANSACTOR_TOTAL_BALANCE_USD_CENTS_DESC',
  TransactorUsdBalanceUsdCentsAsc = 'TRANSACTOR_USD_BALANCE_USD_CENTS_ASC',
  TransactorUsdBalanceUsdCentsDesc = 'TRANSACTOR_USD_BALANCE_USD_CENTS_DESC',
}

/** A condition to be used against `Post` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PostCondition = {
  /** Checks for equality with the object’s `additionalNftRoyaltiesToCoinsBasisPoints` field. */
  additionalNftRoyaltiesToCoinsBasisPoints?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `additionalNftRoyaltiesToCreatorsBasisPoints` field. */
  additionalNftRoyaltiesToCreatorsBasisPoints?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `body` field. */
  body?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `diamondCount` field. */
  diamondCount?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `diamondLevelSum` field. */
  diamondLevelSum?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `hasUnlockable` field. */
  hasUnlockable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `imageUrls` field. */
  imageUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `isFrozen` field. */
  isFrozen?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isHidden` field. */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isNft` field. */
  isNft?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isPinned` field. */
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isQuotedRepost` field. */
  isQuotedRepost?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `nftRoyaltyToCoinBasisPoints` field. */
  nftRoyaltyToCoinBasisPoints?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `nftRoyaltyToCreatorBasisPoints` field. */
  nftRoyaltyToCreatorBasisPoints?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `numNftCopies` field. */
  numNftCopies?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `numNftCopiesBurned` field. */
  numNftCopiesBurned?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `numNftCopiesForSale` field. */
  numNftCopiesForSale?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `parentPostHash` field. */
  parentPostHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `posterDesoBalanceUsdCents` field. */
  posterDesoBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `posterFocusTokenBalanceUsdCents` field. */
  posterFocusTokenBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `posterTotalBalanceUsdCents` field. */
  posterTotalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `posterUsdBalanceUsdCents` field. */
  posterUsdBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `repostedPostHash` field. */
  repostedPostHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `subscriptionAccessGroupKeyName` field. */
  subscriptionAccessGroupKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `subscriptionAccessGroupOwnerPublicKey` field. */
  subscriptionAccessGroupOwnerPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `totalDiamondValueUsdCents` field. */
  totalDiamondValueUsdCents?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `upvoteCappedWealthUsdCents` field. */
  upvoteCappedWealthUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `upvoteWealthUsdCents` field. */
  upvoteWealthUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `videoUrls` field. */
  videoUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PostDiamondTotal = {
  __typename?: 'PostDiamondTotal';
  diamondCount?: Maybe<Scalars['BigInt']['output']>;
  diamondLevelSum?: Maybe<Scalars['BigInt']['output']>;
  /** Reads a single `Post` that is related to this `PostDiamondTotal`. */
  post?: Maybe<Post>;
  postHash?: Maybe<Scalars['String']['output']>;
  usdCentsSum?: Maybe<Scalars['BigInt']['output']>;
};

/**
 * A condition to be used against `PostDiamondTotal` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PostDiamondTotalCondition = {
  /** Checks for equality with the object’s `diamondCount` field. */
  diamondCount?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `diamondLevelSum` field. */
  diamondLevelSum?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `usdCentsSum` field. */
  usdCentsSum?: InputMaybe<Scalars['BigInt']['input']>;
};

/** A filter to be used against `PostDiamondTotal` object types. All fields are combined with a logical ‘and.’ */
export type PostDiamondTotalFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostDiamondTotalFilter>>;
  /** Filter by the object’s `diamondCount` field. */
  diamondCount?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `diamondLevelSum` field. */
  diamondLevelSum?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostDiamondTotalFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostDiamondTotalFilter>>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** A related `post` exists. */
  postExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `usdCentsSum` field. */
  usdCentsSum?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `PostDiamondTotal` values. */
export type PostDiamondTotalsConnection = {
  __typename?: 'PostDiamondTotalsConnection';
  /** A list of edges which contains the `PostDiamondTotal` and cursor to aid in pagination. */
  edges: Array<PostDiamondTotalsEdge>;
  /** A list of `PostDiamondTotal` objects. */
  nodes: Array<Maybe<PostDiamondTotal>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PostDiamondTotal` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `PostDiamondTotal` edge in the connection. */
export type PostDiamondTotalsEdge = {
  __typename?: 'PostDiamondTotalsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `PostDiamondTotal` at the end of the edge. */
  node?: Maybe<PostDiamondTotal>;
};

/** Methods to use when ordering `PostDiamondTotal`. */
export enum PostDiamondTotalsOrderBy {
  DiamondCountAsc = 'DIAMOND_COUNT_ASC',
  DiamondCountDesc = 'DIAMOND_COUNT_DESC',
  DiamondLevelSumAsc = 'DIAMOND_LEVEL_SUM_ASC',
  DiamondLevelSumDesc = 'DIAMOND_LEVEL_SUM_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  UsdCentsSumAsc = 'USD_CENTS_SUM_ASC',
  UsdCentsSumDesc = 'USD_CENTS_SUM_DESC',
}

/** A filter to be used against `Post` object types. All fields are combined with a logical ‘and.’ */
export type PostFilter = {
  /** Filter by the object’s `additionalNftRoyaltiesToCoinsBasisPoints` field. */
  additionalNftRoyaltiesToCoinsBasisPoints?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `additionalNftRoyaltiesToCreatorsBasisPoints` field. */
  additionalNftRoyaltiesToCreatorsBasisPoints?: InputMaybe<JsonFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostFilter>>;
  /** Filter by the object’s `body` field. */
  body?: InputMaybe<StringFilter>;
  /** Filter by the object’s `diamondCount` field. */
  diamondCount?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `diamondLevelSum` field. */
  diamondLevelSum?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `diamondTotals` relation. */
  diamondTotals?: InputMaybe<PostDiamondTotalFilter>;
  /** A related `diamondTotals` exists. */
  diamondTotalsExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `diamonds` relation. */
  diamonds?: InputMaybe<PostToManyDiamondFilter>;
  /** Some related `diamonds` exist. */
  diamondsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `hasUnlockable` field. */
  hasUnlockable?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `imageUrls` field. */
  imageUrls?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `isFrozen` field. */
  isFrozen?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isHidden` field. */
  isHidden?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isNft` field. */
  isNft?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPinned` field. */
  isPinned?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isQuotedRepost` field. */
  isQuotedRepost?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `likes` relation. */
  likes?: InputMaybe<PostToManyLikeFilter>;
  /** Some related `likes` exist. */
  likesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `nftBids` relation. */
  nftBids?: InputMaybe<PostToManyNftBidFilter>;
  /** Some related `nftBids` exist. */
  nftBidsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `nftRoyaltyToCoinBasisPoints` field. */
  nftRoyaltyToCoinBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `nftRoyaltyToCreatorBasisPoints` field. */
  nftRoyaltyToCreatorBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `nfts` relation. */
  nfts?: InputMaybe<PostToManyNftFilter>;
  /** Some related `nfts` exist. */
  nftsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not?: InputMaybe<PostFilter>;
  /** Filter by the object’s `numNftCopies` field. */
  numNftCopies?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `numNftCopiesBurned` field. */
  numNftCopiesBurned?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `numNftCopiesForSale` field. */
  numNftCopiesForSale?: InputMaybe<BigIntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostFilter>>;
  /** Filter by the object’s `parentPost` relation. */
  parentPost?: InputMaybe<PostFilter>;
  /** A related `parentPost` exists. */
  parentPostExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `parentPostHash` field. */
  parentPostHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `postAssociationCounts` relation. */
  postAssociationCounts?: InputMaybe<PostToManyPostAssociationCountFilter>;
  /** Some related `postAssociationCounts` exist. */
  postAssociationCountsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `postAssociationsByPostHash` relation. */
  postAssociationsByPostHash?: InputMaybe<PostToManyPostAssociationFilter>;
  /** Some related `postAssociationsByPostHash` exist. */
  postAssociationsByPostHashExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `poster` relation. */
  poster?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `posterDesoBalanceUsdCents` field. */
  posterDesoBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** A related `poster` exists. */
  posterExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `posterFocusTokenBalanceUsdCents` field. */
  posterFocusTokenBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `posterTotalBalanceUsdCents` field. */
  posterTotalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `posterUsdBalanceUsdCents` field. */
  posterUsdBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `replies` relation. */
  replies?: InputMaybe<PostToManyPostFilter>;
  /** Some related `replies` exist. */
  repliesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `repostedPost` relation. */
  repostedPost?: InputMaybe<PostFilter>;
  /** A related `repostedPost` exists. */
  repostedPostExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `repostedPostHash` field. */
  repostedPostHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `reposts` relation. */
  reposts?: InputMaybe<PostToManyPostFilter>;
  /** Some related `reposts` exist. */
  repostsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `subscriptionAccessGroup` relation. */
  subscriptionAccessGroup?: InputMaybe<AccessGroupFilter>;
  /** A related `subscriptionAccessGroup` exists. */
  subscriptionAccessGroupExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `subscriptionAccessGroupKeyName` field. */
  subscriptionAccessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subscriptionAccessGroupOwnerPublicKey` field. */
  subscriptionAccessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subscriptionStats` relation. */
  subscriptionStats?: InputMaybe<PostToManyPostSubscriptionStatFilter>;
  /** Some related `subscriptionStats` exist. */
  subscriptionStatsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `totalDiamondValueUsdCents` field. */
  totalDiamondValueUsdCents?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `upvoteCappedWealthUsdCents` field. */
  upvoteCappedWealthUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `upvoteWealth` relation. */
  upvoteWealth?: InputMaybe<PostToManyPostWealthFilter>;
  /** Some related `upvoteWealth` exist. */
  upvoteWealthExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `upvoteWealthUsdCents` field. */
  upvoteWealthUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `videoUrls` field. */
  videoUrls?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `views` relation. */
  views?: InputMaybe<PostToManyPostViewFilter>;
  /** Some related `views` exist. */
  viewsExist?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostModeration = {
  __typename?: 'PostModeration';
  associationId?: Maybe<Scalars['String']['output']>;
  blockHeight?: Maybe<Scalars['Int']['output']>;
  moderationReason?: Maybe<Scalars['String']['output']>;
  moderationTag?: Maybe<Scalars['String']['output']>;
  moderatorDisplayName?: Maybe<Scalars['String']['output']>;
  moderatorPublicKey?: Maybe<Scalars['String']['output']>;
  moderatorUsername?: Maybe<Scalars['String']['output']>;
  postHashHex?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `PostModeration` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PostModerationCondition = {
  /** Checks for equality with the object’s `associationId` field. */
  associationId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `moderationReason` field. */
  moderationReason?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `moderationTag` field. */
  moderationTag?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `moderatorDisplayName` field. */
  moderatorDisplayName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `moderatorPublicKey` field. */
  moderatorPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `moderatorUsername` field. */
  moderatorUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postHashHex` field. */
  postHashHex?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `PostModeration` object types. All fields are combined with a logical ‘and.’ */
export type PostModerationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostModerationFilter>>;
  /** Filter by the object’s `associationId` field. */
  associationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<IntFilter>;
  /** Filter by the object’s `moderationReason` field. */
  moderationReason?: InputMaybe<StringFilter>;
  /** Filter by the object’s `moderationTag` field. */
  moderationTag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `moderatorDisplayName` field. */
  moderatorDisplayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `moderatorPublicKey` field. */
  moderatorPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `moderatorUsername` field. */
  moderatorUsername?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostModerationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostModerationFilter>>;
  /** Filter by the object’s `postHashHex` field. */
  postHashHex?: InputMaybe<StringFilter>;
};

/** A connection to a list of `PostModeration` values. */
export type PostModerationsConnection = {
  __typename?: 'PostModerationsConnection';
  /** A list of edges which contains the `PostModeration` and cursor to aid in pagination. */
  edges: Array<PostModerationsEdge>;
  /** A list of `PostModeration` objects. */
  nodes: Array<Maybe<PostModeration>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PostModeration` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `PostModeration` edge in the connection. */
export type PostModerationsEdge = {
  __typename?: 'PostModerationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `PostModeration` at the end of the edge. */
  node?: Maybe<PostModeration>;
};

/** Methods to use when ordering `PostModeration`. */
export enum PostModerationsOrderBy {
  AssociationIdAsc = 'ASSOCIATION_ID_ASC',
  AssociationIdDesc = 'ASSOCIATION_ID_DESC',
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  ModerationReasonAsc = 'MODERATION_REASON_ASC',
  ModerationReasonDesc = 'MODERATION_REASON_DESC',
  ModerationTagAsc = 'MODERATION_TAG_ASC',
  ModerationTagDesc = 'MODERATION_TAG_DESC',
  ModeratorDisplayNameAsc = 'MODERATOR_DISPLAY_NAME_ASC',
  ModeratorDisplayNameDesc = 'MODERATOR_DISPLAY_NAME_DESC',
  ModeratorPublicKeyAsc = 'MODERATOR_PUBLIC_KEY_ASC',
  ModeratorPublicKeyDesc = 'MODERATOR_PUBLIC_KEY_DESC',
  ModeratorUsernameAsc = 'MODERATOR_USERNAME_ASC',
  ModeratorUsernameDesc = 'MODERATOR_USERNAME_DESC',
  Natural = 'NATURAL',
  PostHashHexAsc = 'POST_HASH_HEX_ASC',
  PostHashHexDesc = 'POST_HASH_HEX_DESC',
}

export type PostScoreUpdate = Node & {
  __typename?: 'PostScoreUpdate';
  blockHeight?: Maybe<Scalars['BigInt']['output']>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  rowId: Scalars['Int']['output'];
};

/**
 * A condition to be used against `PostScoreUpdate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PostScoreUpdateCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `PostScoreUpdate` object types. All fields are combined with a logical ‘and.’ */
export type PostScoreUpdateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostScoreUpdateFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostScoreUpdateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostScoreUpdateFilter>>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<IntFilter>;
};

/** A connection to a list of `PostScoreUpdate` values. */
export type PostScoreUpdatesConnection = {
  __typename?: 'PostScoreUpdatesConnection';
  /** A list of edges which contains the `PostScoreUpdate` and cursor to aid in pagination. */
  edges: Array<PostScoreUpdatesEdge>;
  /** A list of `PostScoreUpdate` objects. */
  nodes: Array<Maybe<PostScoreUpdate>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PostScoreUpdate` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `PostScoreUpdate` edge in the connection. */
export type PostScoreUpdatesEdge = {
  __typename?: 'PostScoreUpdatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `PostScoreUpdate` at the end of the edge. */
  node?: Maybe<PostScoreUpdate>;
};

/** Methods to use when ordering `PostScoreUpdate`. */
export enum PostScoreUpdatesOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type PostSubscriptionStat = {
  __typename?: 'PostSubscriptionStat';
  /** Reads a single `AccessGroup` that is related to this `PostSubscriptionStat`. */
  accessGroup?: Maybe<AccessGroup>;
  accessGroupKeyName?: Maybe<Scalars['String']['output']>;
  accessGroupOwnerPublicKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Account` that is related to this `PostSubscriptionStat`. */
  creatorAccount?: Maybe<Account>;
  /** Reads a single `Post` that is related to this `PostSubscriptionStat`. */
  post?: Maybe<Post>;
  postHash?: Maybe<Scalars['String']['output']>;
  posterPublicKey?: Maybe<Scalars['String']['output']>;
  subscriptionCount?: Maybe<Scalars['BigInt']['output']>;
  totalPaidUsdCents?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `PostSubscriptionStat` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type PostSubscriptionStatCondition = {
  /** Checks for equality with the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `subscriptionCount` field. */
  subscriptionCount?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalPaidUsdCents` field. */
  totalPaidUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `PostSubscriptionStat` object types. All fields are combined with a logical ‘and.’ */
export type PostSubscriptionStatFilter = {
  /** Filter by the object’s `accessGroup` relation. */
  accessGroup?: InputMaybe<AccessGroupFilter>;
  /** A related `accessGroup` exists. */
  accessGroupExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostSubscriptionStatFilter>>;
  /** Filter by the object’s `creatorAccount` relation. */
  creatorAccount?: InputMaybe<AccountFilter>;
  /** A related `creatorAccount` exists. */
  creatorAccountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not?: InputMaybe<PostSubscriptionStatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostSubscriptionStatFilter>>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** A related `post` exists. */
  postExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `posterPublicKey` field. */
  posterPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subscriptionCount` field. */
  subscriptionCount?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalPaidUsdCents` field. */
  totalPaidUsdCents?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `PostSubscriptionStat` values. */
export type PostSubscriptionStatsConnection = {
  __typename?: 'PostSubscriptionStatsConnection';
  /** A list of edges which contains the `PostSubscriptionStat` and cursor to aid in pagination. */
  edges: Array<PostSubscriptionStatsEdge>;
  /** A list of `PostSubscriptionStat` objects. */
  nodes: Array<Maybe<PostSubscriptionStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PostSubscriptionStat` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `PostSubscriptionStat` edge in the connection. */
export type PostSubscriptionStatsEdge = {
  __typename?: 'PostSubscriptionStatsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `PostSubscriptionStat` at the end of the edge. */
  node?: Maybe<PostSubscriptionStat>;
};

/** Methods to use when ordering `PostSubscriptionStat`. */
export enum PostSubscriptionStatsOrderBy {
  AccessGroupKeyNameAsc = 'ACCESS_GROUP_KEY_NAME_ASC',
  AccessGroupKeyNameDesc = 'ACCESS_GROUP_KEY_NAME_DESC',
  AccessGroupOwnerPublicKeyAsc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC',
  AccessGroupOwnerPublicKeyDesc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC',
  Natural = 'NATURAL',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  SubscriptionCountAsc = 'SUBSCRIPTION_COUNT_ASC',
  SubscriptionCountDesc = 'SUBSCRIPTION_COUNT_DESC',
  TotalPaidUsdCentsAsc = 'TOTAL_PAID_USD_CENTS_ASC',
  TotalPaidUsdCentsDesc = 'TOTAL_PAID_USD_CENTS_DESC',
}

/** A filter to be used against many `Diamond` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyDiamondFilter = {
  /** Every related `Diamond` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DiamondFilter>;
  /** No related `Diamond` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DiamondFilter>;
  /** Some related `Diamond` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DiamondFilter>;
};

/** A filter to be used against many `Like` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyLikeFilter = {
  /** Every related `Like` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LikeFilter>;
  /** No related `Like` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LikeFilter>;
  /** Some related `Like` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LikeFilter>;
};

/** A filter to be used against many `NftBid` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyNftBidFilter = {
  /** Every related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NftBidFilter>;
  /** No related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NftBidFilter>;
  /** Some related `NftBid` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NftBidFilter>;
};

/** A filter to be used against many `Nft` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyNftFilter = {
  /** Every related `Nft` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NftFilter>;
  /** No related `Nft` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NftFilter>;
  /** Some related `Nft` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NftFilter>;
};

/** A filter to be used against many `PostAssociationCount` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyPostAssociationCountFilter = {
  /** Every related `PostAssociationCount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostAssociationCountFilter>;
  /** No related `PostAssociationCount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostAssociationCountFilter>;
  /** Some related `PostAssociationCount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostAssociationCountFilter>;
};

/** A filter to be used against many `PostAssociation` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyPostAssociationFilter = {
  /** Every related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostAssociationFilter>;
  /** No related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostAssociationFilter>;
  /** Some related `PostAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostAssociationFilter>;
};

/** A filter to be used against many `Post` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyPostFilter = {
  /** Every related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostFilter>;
  /** No related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostFilter>;
  /** Some related `Post` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostFilter>;
};

/** A filter to be used against many `PostSubscriptionStat` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyPostSubscriptionStatFilter = {
  /** Every related `PostSubscriptionStat` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostSubscriptionStatFilter>;
  /** No related `PostSubscriptionStat` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostSubscriptionStatFilter>;
  /** Some related `PostSubscriptionStat` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostSubscriptionStatFilter>;
};

/** A filter to be used against many `PostView` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyPostViewFilter = {
  /** Every related `PostView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostViewFilter>;
  /** No related `PostView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostViewFilter>;
  /** Some related `PostView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostViewFilter>;
};

/** A filter to be used against many `PostWealth` object types. All fields are combined with a logical ‘and.’ */
export type PostToManyPostWealthFilter = {
  /** Every related `PostWealth` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostWealthFilter>;
  /** No related `PostWealth` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostWealthFilter>;
  /** Some related `PostWealth` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostWealthFilter>;
};

export type PostView = Node & {
  __typename?: 'PostView';
  createdAt: Scalars['Datetime']['output'];
  deletedAt?: Maybe<Scalars['Datetime']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  /** Reads a single `Post` that is related to this `PostView`. */
  post?: Maybe<Post>;
  postHash: Scalars['String']['output'];
  publicKey?: Maybe<Scalars['String']['output']>;
  rowId: Scalars['UUID']['output'];
  status: Scalars['String']['output'];
  timeViewedMilliseconds?: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Account` that is related to this `PostView`. */
  viewer?: Maybe<Account>;
};

/**
 * A condition to be used against `PostView` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PostViewCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timeViewedMilliseconds` field. */
  timeViewedMilliseconds?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `PostView` object types. All fields are combined with a logical ‘and.’ */
export type PostViewFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostViewFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostViewFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostViewFilter>>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timeViewedMilliseconds` field. */
  timeViewedMilliseconds?: InputMaybe<IntFilter>;
  /** Filter by the object’s `viewer` relation. */
  viewer?: InputMaybe<AccountFilter>;
  /** A related `viewer` exists. */
  viewerExists?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A connection to a list of `PostView` values. */
export type PostViewsConnection = {
  __typename?: 'PostViewsConnection';
  /** A list of edges which contains the `PostView` and cursor to aid in pagination. */
  edges: Array<PostViewsEdge>;
  /** A list of `PostView` objects. */
  nodes: Array<Maybe<PostView>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PostView` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `PostView` edge in the connection. */
export type PostViewsEdge = {
  __typename?: 'PostViewsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `PostView` at the end of the edge. */
  node?: Maybe<PostView>;
};

/** Methods to use when ordering `PostView`. */
export enum PostViewsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TimeViewedMillisecondsAsc = 'TIME_VIEWED_MILLISECONDS_ASC',
  TimeViewedMillisecondsDesc = 'TIME_VIEWED_MILLISECONDS_DESC',
}

export type PostWealth = {
  __typename?: 'PostWealth';
  cappedWealthUsdCents?: Maybe<Scalars['Float']['output']>;
  negativeCappedWealthUsdCents?: Maybe<Scalars['Float']['output']>;
  negativeWealthUsdCents?: Maybe<Scalars['Float']['output']>;
  positiveCappedWealthUsdCents?: Maybe<Scalars['Float']['output']>;
  positiveWealthUsdCents?: Maybe<Scalars['Float']['output']>;
  /** Reads a single `Post` that is related to this `PostWealth`. */
  post?: Maybe<Post>;
  postHash?: Maybe<Scalars['String']['output']>;
  wealthUsdCents?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `PostWealth` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PostWealthCondition = {
  /** Checks for equality with the object’s `cappedWealthUsdCents` field. */
  cappedWealthUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `negativeCappedWealthUsdCents` field. */
  negativeCappedWealthUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `negativeWealthUsdCents` field. */
  negativeWealthUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `positiveCappedWealthUsdCents` field. */
  positiveCappedWealthUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `positiveWealthUsdCents` field. */
  positiveWealthUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `wealthUsdCents` field. */
  wealthUsdCents?: InputMaybe<Scalars['Float']['input']>;
};

/** A connection to a list of `PostWealth` values. */
export type PostWealthConnection = {
  __typename?: 'PostWealthConnection';
  /** A list of edges which contains the `PostWealth` and cursor to aid in pagination. */
  edges: Array<PostWealthEdge>;
  /** A list of `PostWealth` objects. */
  nodes: Array<Maybe<PostWealth>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PostWealth` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `PostWealth` edge in the connection. */
export type PostWealthEdge = {
  __typename?: 'PostWealthEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `PostWealth` at the end of the edge. */
  node?: Maybe<PostWealth>;
};

/** A filter to be used against `PostWealth` object types. All fields are combined with a logical ‘and.’ */
export type PostWealthFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostWealthFilter>>;
  /** Filter by the object’s `cappedWealthUsdCents` field. */
  cappedWealthUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `negativeCappedWealthUsdCents` field. */
  negativeCappedWealthUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `negativeWealthUsdCents` field. */
  negativeWealthUsdCents?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostWealthFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostWealthFilter>>;
  /** Filter by the object’s `positiveCappedWealthUsdCents` field. */
  positiveCappedWealthUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `positiveWealthUsdCents` field. */
  positiveWealthUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `post` relation. */
  post?: InputMaybe<PostFilter>;
  /** A related `post` exists. */
  postExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `wealthUsdCents` field. */
  wealthUsdCents?: InputMaybe<FloatFilter>;
};

export type PostWealthMv = {
  __typename?: 'PostWealthMv';
  cappedWealthUsdCents?: Maybe<Scalars['Float']['output']>;
  negativeCappedWealthUsdCents?: Maybe<Scalars['Float']['output']>;
  negativeWealthUsdCents?: Maybe<Scalars['Float']['output']>;
  positiveCappedWealthUsdCents?: Maybe<Scalars['Float']['output']>;
  positiveWealthUsdCents?: Maybe<Scalars['Float']['output']>;
  postHash?: Maybe<Scalars['String']['output']>;
  wealthUsdCents?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `PostWealthMv` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PostWealthMvCondition = {
  /** Checks for equality with the object’s `cappedWealthUsdCents` field. */
  cappedWealthUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `negativeCappedWealthUsdCents` field. */
  negativeCappedWealthUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `negativeWealthUsdCents` field. */
  negativeWealthUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `positiveCappedWealthUsdCents` field. */
  positiveCappedWealthUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `positiveWealthUsdCents` field. */
  positiveWealthUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `postHash` field. */
  postHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `wealthUsdCents` field. */
  wealthUsdCents?: InputMaybe<Scalars['Float']['input']>;
};

/** A filter to be used against `PostWealthMv` object types. All fields are combined with a logical ‘and.’ */
export type PostWealthMvFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostWealthMvFilter>>;
  /** Filter by the object’s `cappedWealthUsdCents` field. */
  cappedWealthUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `negativeCappedWealthUsdCents` field. */
  negativeCappedWealthUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `negativeWealthUsdCents` field. */
  negativeWealthUsdCents?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostWealthMvFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostWealthMvFilter>>;
  /** Filter by the object’s `positiveCappedWealthUsdCents` field. */
  positiveCappedWealthUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `positiveWealthUsdCents` field. */
  positiveWealthUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `postHash` field. */
  postHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `wealthUsdCents` field. */
  wealthUsdCents?: InputMaybe<FloatFilter>;
};

/** A connection to a list of `PostWealthMv` values. */
export type PostWealthMvsConnection = {
  __typename?: 'PostWealthMvsConnection';
  /** A list of edges which contains the `PostWealthMv` and cursor to aid in pagination. */
  edges: Array<PostWealthMvsEdge>;
  /** A list of `PostWealthMv` objects. */
  nodes: Array<Maybe<PostWealthMv>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PostWealthMv` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `PostWealthMv` edge in the connection. */
export type PostWealthMvsEdge = {
  __typename?: 'PostWealthMvsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `PostWealthMv` at the end of the edge. */
  node?: Maybe<PostWealthMv>;
};

/** Methods to use when ordering `PostWealthMv`. */
export enum PostWealthMvsOrderBy {
  CappedWealthUsdCentsAsc = 'CAPPED_WEALTH_USD_CENTS_ASC',
  CappedWealthUsdCentsDesc = 'CAPPED_WEALTH_USD_CENTS_DESC',
  Natural = 'NATURAL',
  NegativeCappedWealthUsdCentsAsc = 'NEGATIVE_CAPPED_WEALTH_USD_CENTS_ASC',
  NegativeCappedWealthUsdCentsDesc = 'NEGATIVE_CAPPED_WEALTH_USD_CENTS_DESC',
  NegativeWealthUsdCentsAsc = 'NEGATIVE_WEALTH_USD_CENTS_ASC',
  NegativeWealthUsdCentsDesc = 'NEGATIVE_WEALTH_USD_CENTS_DESC',
  PositiveCappedWealthUsdCentsAsc = 'POSITIVE_CAPPED_WEALTH_USD_CENTS_ASC',
  PositiveCappedWealthUsdCentsDesc = 'POSITIVE_CAPPED_WEALTH_USD_CENTS_DESC',
  PositiveWealthUsdCentsAsc = 'POSITIVE_WEALTH_USD_CENTS_ASC',
  PositiveWealthUsdCentsDesc = 'POSITIVE_WEALTH_USD_CENTS_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  WealthUsdCentsAsc = 'WEALTH_USD_CENTS_ASC',
  WealthUsdCentsDesc = 'WEALTH_USD_CENTS_DESC',
}

/** Methods to use when ordering `PostWealth`. */
export enum PostWealthOrderBy {
  CappedWealthUsdCentsAsc = 'CAPPED_WEALTH_USD_CENTS_ASC',
  CappedWealthUsdCentsDesc = 'CAPPED_WEALTH_USD_CENTS_DESC',
  Natural = 'NATURAL',
  NegativeCappedWealthUsdCentsAsc = 'NEGATIVE_CAPPED_WEALTH_USD_CENTS_ASC',
  NegativeCappedWealthUsdCentsDesc = 'NEGATIVE_CAPPED_WEALTH_USD_CENTS_DESC',
  NegativeWealthUsdCentsAsc = 'NEGATIVE_WEALTH_USD_CENTS_ASC',
  NegativeWealthUsdCentsDesc = 'NEGATIVE_WEALTH_USD_CENTS_DESC',
  PositiveCappedWealthUsdCentsAsc = 'POSITIVE_CAPPED_WEALTH_USD_CENTS_ASC',
  PositiveCappedWealthUsdCentsDesc = 'POSITIVE_CAPPED_WEALTH_USD_CENTS_DESC',
  PositiveWealthUsdCentsAsc = 'POSITIVE_WEALTH_USD_CENTS_ASC',
  PositiveWealthUsdCentsDesc = 'POSITIVE_WEALTH_USD_CENTS_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  WealthUsdCentsAsc = 'WEALTH_USD_CENTS_ASC',
  WealthUsdCentsDesc = 'WEALTH_USD_CENTS_DESC',
}

/** A connection to a list of `Post` values. */
export type PostsConnection = {
  __typename?: 'PostsConnection';
  /** A list of edges which contains the `Post` and cursor to aid in pagination. */
  edges: Array<PostsEdge>;
  /** A list of `Post` objects. */
  nodes: Array<Maybe<Post>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Post` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Post` edge in the connection. */
export type PostsEdge = {
  __typename?: 'PostsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Post` at the end of the edge. */
  node?: Maybe<Post>;
};

/** Methods to use when ordering `Post`. */
export enum PostsOrderBy {
  AdditionalNftRoyaltiesToCoinsBasisPointsAsc = 'ADDITIONAL_NFT_ROYALTIES_TO_COINS_BASIS_POINTS_ASC',
  AdditionalNftRoyaltiesToCoinsBasisPointsDesc = 'ADDITIONAL_NFT_ROYALTIES_TO_COINS_BASIS_POINTS_DESC',
  AdditionalNftRoyaltiesToCreatorsBasisPointsAsc = 'ADDITIONAL_NFT_ROYALTIES_TO_CREATORS_BASIS_POINTS_ASC',
  AdditionalNftRoyaltiesToCreatorsBasisPointsDesc = 'ADDITIONAL_NFT_ROYALTIES_TO_CREATORS_BASIS_POINTS_DESC',
  BadgerKeyAsc = 'BADGER_KEY_ASC',
  BadgerKeyDesc = 'BADGER_KEY_DESC',
  BodyAsc = 'BODY_ASC',
  BodyDesc = 'BODY_DESC',
  DiamondCountAsc = 'DIAMOND_COUNT_ASC',
  DiamondCountDesc = 'DIAMOND_COUNT_DESC',
  DiamondLevelSumAsc = 'DIAMOND_LEVEL_SUM_ASC',
  DiamondLevelSumDesc = 'DIAMOND_LEVEL_SUM_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  HasUnlockableAsc = 'HAS_UNLOCKABLE_ASC',
  HasUnlockableDesc = 'HAS_UNLOCKABLE_DESC',
  ImageUrlsAsc = 'IMAGE_URLS_ASC',
  ImageUrlsDesc = 'IMAGE_URLS_DESC',
  IsFrozenAsc = 'IS_FROZEN_ASC',
  IsFrozenDesc = 'IS_FROZEN_DESC',
  IsHiddenAsc = 'IS_HIDDEN_ASC',
  IsHiddenDesc = 'IS_HIDDEN_DESC',
  IsNftAsc = 'IS_NFT_ASC',
  IsNftDesc = 'IS_NFT_DESC',
  IsPinnedAsc = 'IS_PINNED_ASC',
  IsPinnedDesc = 'IS_PINNED_DESC',
  IsQuotedRepostAsc = 'IS_QUOTED_REPOST_ASC',
  IsQuotedRepostDesc = 'IS_QUOTED_REPOST_DESC',
  Natural = 'NATURAL',
  NftRoyaltyToCoinBasisPointsAsc = 'NFT_ROYALTY_TO_COIN_BASIS_POINTS_ASC',
  NftRoyaltyToCoinBasisPointsDesc = 'NFT_ROYALTY_TO_COIN_BASIS_POINTS_DESC',
  NftRoyaltyToCreatorBasisPointsAsc = 'NFT_ROYALTY_TO_CREATOR_BASIS_POINTS_ASC',
  NftRoyaltyToCreatorBasisPointsDesc = 'NFT_ROYALTY_TO_CREATOR_BASIS_POINTS_DESC',
  NumNftCopiesAsc = 'NUM_NFT_COPIES_ASC',
  NumNftCopiesBurnedAsc = 'NUM_NFT_COPIES_BURNED_ASC',
  NumNftCopiesBurnedDesc = 'NUM_NFT_COPIES_BURNED_DESC',
  NumNftCopiesDesc = 'NUM_NFT_COPIES_DESC',
  NumNftCopiesForSaleAsc = 'NUM_NFT_COPIES_FOR_SALE_ASC',
  NumNftCopiesForSaleDesc = 'NUM_NFT_COPIES_FOR_SALE_DESC',
  ParentPostHashAsc = 'PARENT_POST_HASH_ASC',
  ParentPostHashDesc = 'PARENT_POST_HASH_DESC',
  PosterDesoBalanceUsdCentsAsc = 'POSTER_DESO_BALANCE_USD_CENTS_ASC',
  PosterDesoBalanceUsdCentsDesc = 'POSTER_DESO_BALANCE_USD_CENTS_DESC',
  PosterFocusTokenBalanceUsdCentsAsc = 'POSTER_FOCUS_TOKEN_BALANCE_USD_CENTS_ASC',
  PosterFocusTokenBalanceUsdCentsDesc = 'POSTER_FOCUS_TOKEN_BALANCE_USD_CENTS_DESC',
  PosterPublicKeyAsc = 'POSTER_PUBLIC_KEY_ASC',
  PosterPublicKeyDesc = 'POSTER_PUBLIC_KEY_DESC',
  PosterTotalBalanceUsdCentsAsc = 'POSTER_TOTAL_BALANCE_USD_CENTS_ASC',
  PosterTotalBalanceUsdCentsDesc = 'POSTER_TOTAL_BALANCE_USD_CENTS_DESC',
  PosterUsdBalanceUsdCentsAsc = 'POSTER_USD_BALANCE_USD_CENTS_ASC',
  PosterUsdBalanceUsdCentsDesc = 'POSTER_USD_BALANCE_USD_CENTS_DESC',
  PostHashAsc = 'POST_HASH_ASC',
  PostHashDesc = 'POST_HASH_DESC',
  RepostedPostHashAsc = 'REPOSTED_POST_HASH_ASC',
  RepostedPostHashDesc = 'REPOSTED_POST_HASH_DESC',
  SubscriptionAccessGroupKeyNameAsc = 'SUBSCRIPTION_ACCESS_GROUP_KEY_NAME_ASC',
  SubscriptionAccessGroupKeyNameDesc = 'SUBSCRIPTION_ACCESS_GROUP_KEY_NAME_DESC',
  SubscriptionAccessGroupOwnerPublicKeyAsc = 'SUBSCRIPTION_ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC',
  SubscriptionAccessGroupOwnerPublicKeyDesc = 'SUBSCRIPTION_ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
  TotalDiamondValueUsdCentsAsc = 'TOTAL_DIAMOND_VALUE_USD_CENTS_ASC',
  TotalDiamondValueUsdCentsDesc = 'TOTAL_DIAMOND_VALUE_USD_CENTS_DESC',
  UpvoteCappedWealthUsdCentsAsc = 'UPVOTE_CAPPED_WEALTH_USD_CENTS_ASC',
  UpvoteCappedWealthUsdCentsDesc = 'UPVOTE_CAPPED_WEALTH_USD_CENTS_DESC',
  UpvoteWealthUsdCentsAsc = 'UPVOTE_WEALTH_USD_CENTS_ASC',
  UpvoteWealthUsdCentsDesc = 'UPVOTE_WEALTH_USD_CENTS_DESC',
  VideoUrlsAsc = 'VIDEO_URLS_ASC',
  VideoUrlsDesc = 'VIDEO_URLS_DESC',
}

export type Profile = Node & {
  __typename?: 'Profile';
  /** Reads a single `Account` that is related to this `Profile`. */
  account?: Maybe<Account>;
  ccCoinsInCirculationNanos: Scalars['BigInt']['output'];
  coinPriceDesoNanos?: Maybe<Scalars['BigFloat']['output']>;
  coinWatermarkNanos: Scalars['BigInt']['output'];
  creatorBasisPoints: Scalars['BigInt']['output'];
  daoCoinMintingDisabled: Scalars['Boolean']['output'];
  daoCoinTransferRestrictionStatus: Scalars['Int']['output'];
  daoCoinsInCirculationNanosHex: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  desoLockedNanos: Scalars['BigInt']['output'];
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  mintingDisabled: Scalars['Boolean']['output'];
  pkid: Scalars['String']['output'];
  profilePic?: Maybe<Scalars['String']['output']>;
  publicKey: Scalars['String']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

/** A condition to be used against `Profile` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProfileCondition = {
  /** Checks for equality with the object’s `ccCoinsInCirculationNanos` field. */
  ccCoinsInCirculationNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `coinPriceDesoNanos` field. */
  coinPriceDesoNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `coinWatermarkNanos` field. */
  coinWatermarkNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `creatorBasisPoints` field. */
  creatorBasisPoints?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `daoCoinMintingDisabled` field. */
  daoCoinMintingDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `daoCoinTransferRestrictionStatus` field. */
  daoCoinTransferRestrictionStatus?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `daoCoinsInCirculationNanosHex` field. */
  daoCoinsInCirculationNanosHex?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `desoLockedNanos` field. */
  desoLockedNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `mintingDisabled` field. */
  mintingDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `profilePic` field. */
  profilePic?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Profile` object types. All fields are combined with a logical ‘and.’ */
export type ProfileFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProfileFilter>>;
  /** Filter by the object’s `ccCoinsInCirculationNanos` field. */
  ccCoinsInCirculationNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `coinPriceDesoNanos` field. */
  coinPriceDesoNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coinWatermarkNanos` field. */
  coinWatermarkNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `creatorBasisPoints` field. */
  creatorBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `daoCoinMintingDisabled` field. */
  daoCoinMintingDisabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `daoCoinTransferRestrictionStatus` field. */
  daoCoinTransferRestrictionStatus?: InputMaybe<IntFilter>;
  /** Filter by the object’s `daoCoinsInCirculationNanosHex` field. */
  daoCoinsInCirculationNanosHex?: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `desoLockedNanos` field. */
  desoLockedNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `mintingDisabled` field. */
  mintingDisabled?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProfileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProfileFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** A connection to a list of `Profile` values. */
export type ProfilesConnection = {
  __typename?: 'ProfilesConnection';
  /** A list of edges which contains the `Profile` and cursor to aid in pagination. */
  edges: Array<ProfilesEdge>;
  /** A list of `Profile` objects. */
  nodes: Array<Maybe<Profile>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Profile` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Profile` edge in the connection. */
export type ProfilesEdge = {
  __typename?: 'ProfilesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Profile` at the end of the edge. */
  node?: Maybe<Profile>;
};

/** Methods to use when ordering `Profile`. */
export enum ProfilesOrderBy {
  CcCoinsInCirculationNanosAsc = 'CC_COINS_IN_CIRCULATION_NANOS_ASC',
  CcCoinsInCirculationNanosDesc = 'CC_COINS_IN_CIRCULATION_NANOS_DESC',
  CoinPriceDesoNanosAsc = 'COIN_PRICE_DESO_NANOS_ASC',
  CoinPriceDesoNanosDesc = 'COIN_PRICE_DESO_NANOS_DESC',
  CoinWatermarkNanosAsc = 'COIN_WATERMARK_NANOS_ASC',
  CoinWatermarkNanosDesc = 'COIN_WATERMARK_NANOS_DESC',
  CreatorBasisPointsAsc = 'CREATOR_BASIS_POINTS_ASC',
  CreatorBasisPointsDesc = 'CREATOR_BASIS_POINTS_DESC',
  DaoCoinsInCirculationNanosHexAsc = 'DAO_COINS_IN_CIRCULATION_NANOS_HEX_ASC',
  DaoCoinsInCirculationNanosHexDesc = 'DAO_COINS_IN_CIRCULATION_NANOS_HEX_DESC',
  DaoCoinMintingDisabledAsc = 'DAO_COIN_MINTING_DISABLED_ASC',
  DaoCoinMintingDisabledDesc = 'DAO_COIN_MINTING_DISABLED_DESC',
  DaoCoinTransferRestrictionStatusAsc = 'DAO_COIN_TRANSFER_RESTRICTION_STATUS_ASC',
  DaoCoinTransferRestrictionStatusDesc = 'DAO_COIN_TRANSFER_RESTRICTION_STATUS_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DesoLockedNanosAsc = 'DESO_LOCKED_NANOS_ASC',
  DesoLockedNanosDesc = 'DESO_LOCKED_NANOS_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  MintingDisabledAsc = 'MINTING_DISABLED_ASC',
  MintingDisabledDesc = 'MINTING_DISABLED_DESC',
  Natural = 'NATURAL',
  PkidAsc = 'PKID_ASC',
  PkidDesc = 'PKID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProfilePicAsc = 'PROFILE_PIC_ASC',
  ProfilePicDesc = 'PROFILE_PIC_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
}

export type PublicKey = Node & {
  __typename?: 'PublicKey';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  publicKey: Scalars['String']['output'];
};

/**
 * A condition to be used against `PublicKey` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PublicKeyCondition = {
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `PublicKey` object types. All fields are combined with a logical ‘and.’ */
export type PublicKeyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PublicKeyFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<PublicKeyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PublicKeyFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `PublicKey` values. */
export type PublicKeysConnection = {
  __typename?: 'PublicKeysConnection';
  /** A list of edges which contains the `PublicKey` and cursor to aid in pagination. */
  edges: Array<PublicKeysEdge>;
  /** A list of `PublicKey` objects. */
  nodes: Array<Maybe<PublicKey>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PublicKey` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `PublicKey` edge in the connection. */
export type PublicKeysEdge = {
  __typename?: 'PublicKeysEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `PublicKey` at the end of the edge. */
  node?: Maybe<PublicKey>;
};

/** Methods to use when ordering `PublicKey`. */
export enum PublicKeysOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  accessGroupByAccessGroupOwnerPublicKeyAndAccessGroupKeyName?: Maybe<AccessGroup>;
  accessGroupByAccessGroupPublicKey?: Maybe<AccessGroup>;
  /** Reads a single `AccessGroup` using its globally unique `ID`. */
  accessGroupById?: Maybe<AccessGroup>;
  accessGroupMemberByAccessGroupOwnerPublicKeyAndAccessGroupMemberPublicKeyAndAccessGroupKeyNameAndAccessGroupMemberKeyName?: Maybe<AccessGroupMember>;
  /** Reads a single `AccessGroupMember` using its globally unique `ID`. */
  accessGroupMemberById?: Maybe<AccessGroupMember>;
  /** Reads and enables pagination through a set of `AccessGroupMember`. */
  accessGroupMembers?: Maybe<AccessGroupMembersConnection>;
  /** Reads and enables pagination through a set of `AccessGroup`. */
  accessGroups?: Maybe<AccessGroupsConnection>;
  account?: Maybe<Account>;
  /** Reads a single `Account` using its globally unique `ID`. */
  accountById?: Maybe<Account>;
  accountByPkid?: Maybe<Account>;
  accountByPublicKey?: Maybe<Account>;
  accountByUsername?: Maybe<Account>;
  accountWealth?: Maybe<AccountWealth>;
  /** Reads a single `AccountWealth` using its globally unique `ID`. */
  accountWealthById?: Maybe<AccountWealth>;
  accountWealthByPublicKey?: Maybe<AccountWealth>;
  accountWealthChainUser?: Maybe<AccountWealthChainUser>;
  /** Reads a single `AccountWealthChainUser` using its globally unique `ID`. */
  accountWealthChainUserById?: Maybe<AccountWealthChainUser>;
  accountWealthChainUserByPublicKey?: Maybe<AccountWealthChainUser>;
  /** Reads and enables pagination through a set of `AccountWealthChainUser`. */
  accountWealthChainUsers?: Maybe<AccountWealthChainUsersConnection>;
  /** Reads and enables pagination through a set of `AccountWealth`. */
  accountWealths?: Maybe<AccountWealthConnection>;
  /** Reads and enables pagination through a set of `Account`. */
  accounts?: Maybe<AccountsConnection>;
  affectedPublicKey?: Maybe<AffectedPublicKey>;
  /** Reads a single `AffectedPublicKey` using its globally unique `ID`. */
  affectedPublicKeyById?: Maybe<AffectedPublicKey>;
  /** Reads and enables pagination through a set of `AffectedPublicKey`. */
  affectedPublicKeys?: Maybe<AffectedPublicKeysConnection>;
  block?: Maybe<Block>;
  blockByBlockHash?: Maybe<Block>;
  blockByHeight?: Maybe<Block>;
  /** Reads a single `Block` using its globally unique `ID`. */
  blockById?: Maybe<Block>;
  blockSigner?: Maybe<BlockSigner>;
  /** Reads a single `BlockSigner` using its globally unique `ID`. */
  blockSignerById?: Maybe<BlockSigner>;
  /** Reads and enables pagination through a set of `BlockSigner`. */
  blockSigners?: Maybe<BlockSignersConnection>;
  /** Reads and enables pagination through a set of `Block`. */
  blocks?: Maybe<BlocksConnection>;
  /** Reads and enables pagination through a set of `BlsPublicKeyPkidPairEntry`. */
  blsPublicKeyPkidPairEntries?: Maybe<BlsPublicKeyPkidPairEntriesConnection>;
  blsPublicKeyPkidPairEntry?: Maybe<BlsPublicKeyPkidPairEntry>;
  /** Reads a single `BlsPublicKeyPkidPairEntry` using its globally unique `ID`. */
  blsPublicKeyPkidPairEntryById?: Maybe<BlsPublicKeyPkidPairEntry>;
  /** Reads and enables pagination through a set of `BlsPublicKeyPkidPairSnapshotEntry`. */
  blsPublicKeyPkidPairSnapshotEntries?: Maybe<BlsPublicKeyPkidPairSnapshotEntriesConnection>;
  /** Reads a single `BlsPublicKeyPkidPairSnapshotEntry` using its globally unique `ID`. */
  blsPublicKeyPkidPairSnapshotEntryById?: Maybe<BlsPublicKeyPkidPairSnapshotEntry>;
  /** Reads and enables pagination through a set of `CoinCategoryCount`. */
  coinCategoryCounts?: Maybe<CoinCategoryCountsConnection>;
  creatorCoinBalance?: Maybe<CreatorCoinBalance>;
  /** Reads a single `CreatorCoinBalance` using its globally unique `ID`. */
  creatorCoinBalanceById?: Maybe<CreatorCoinBalance>;
  /** Reads and enables pagination through a set of `CreatorCoinBalance`. */
  creatorCoinBalances?: Maybe<CreatorCoinBalancesConnection>;
  creatorStat?: Maybe<CreatorStat>;
  /** Reads a single `CreatorStat` using its globally unique `ID`. */
  creatorStatById?: Maybe<CreatorStat>;
  creatorStatByPkid?: Maybe<CreatorStat>;
  creatorStatByPublicKeyBase58Check?: Maybe<CreatorStat>;
  creatorStatByUsername?: Maybe<CreatorStat>;
  /** Reads and enables pagination through a set of `CreatorStat`. */
  creatorStats?: Maybe<CreatorStatsConnection>;
  /** Reads and enables pagination through a set of `CreatorStatsTotalPaidMessageEarningsUsdCentsAllTime`. */
  creatorStatsTotalPaidMessageEarningsUsdCentsAllTimes?: Maybe<CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimesConnection>;
  /** Reads and enables pagination through a set of `CreatorStatsTotalPaidMessageEarningsUsdCentsPast7D`. */
  creatorStatsTotalPaidMessageEarningsUsdCentsPast7Ds?: Maybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DsConnection>;
  /** Reads and enables pagination through a set of `CreatorStatsTotalPaidMessageEarningsUsdCentsPast24H`. */
  creatorStatsTotalPaidMessageEarningsUsdCentsPast24Hs?: Maybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HsConnection>;
  /** Reads and enables pagination through a set of `CreatorStatsTotalPaidMessageEarningsUsdCentsPast30D`. */
  creatorStatsTotalPaidMessageEarningsUsdCentsPast30Ds?: Maybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DsConnection>;
  /** Reads and enables pagination through a set of `DaoCoinCandlestick`. */
  daoCoinCandlesticks?: Maybe<DaoCoinCandlesticksConnection>;
  daoCoinStat?: Maybe<DaoCoinStat>;
  daoCoinStatByCoinPublicKey?: Maybe<DaoCoinStat>;
  /** Reads a single `DaoCoinStat` using its globally unique `ID`. */
  daoCoinStatById?: Maybe<DaoCoinStat>;
  /** Reads and enables pagination through a set of `DaoCoinStat`. */
  daoCoinStats?: Maybe<DaoCoinStatsConnection>;
  /** Reads a single `DerivedKey` using its globally unique `ID`. */
  derivedKeyById?: Maybe<DerivedKey>;
  /** Reads and enables pagination through a set of `DerivedKey`. */
  derivedKeys?: Maybe<DerivedKeysConnection>;
  desoBalance?: Maybe<DesoBalance>;
  /** Reads a single `DesoBalance` using its globally unique `ID`. */
  desoBalanceById?: Maybe<DesoBalance>;
  desoBalanceByPublicKey?: Maybe<DesoBalance>;
  /** Reads and enables pagination through a set of `DesoBalance`. */
  desoBalances?: Maybe<DesoBalancesConnection>;
  desoTokenLimitOrder?: Maybe<DesoTokenLimitOrder>;
  desoTokenLimitOrderBidAsk?: Maybe<DesoTokenLimitOrderBidAsk>;
  desoTokenLimitOrderBidAskByCoin1CreatorPkidAndCoin2CreatorPkid?: Maybe<DesoTokenLimitOrderBidAsk>;
  /** Reads a single `DesoTokenLimitOrderBidAsk` using its globally unique `ID`. */
  desoTokenLimitOrderBidAskById?: Maybe<DesoTokenLimitOrderBidAsk>;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrderBidAsk`. */
  desoTokenLimitOrderBidAsks?: Maybe<DesoTokenLimitOrderBidAsksConnection>;
  /** Reads a single `DesoTokenLimitOrder` using its globally unique `ID`. */
  desoTokenLimitOrderById?: Maybe<DesoTokenLimitOrder>;
  desoTokenLimitOrderByOrderId?: Maybe<DesoTokenLimitOrder>;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrder`. */
  desoTokenLimitOrders?: Maybe<DesoTokenLimitOrdersConnection>;
  /** Reads a single `Diamond` using its globally unique `ID`. */
  diamondById?: Maybe<Diamond>;
  /** Reads and enables pagination through a set of `Diamond`. */
  diamonds?: Maybe<DiamondsConnection>;
  epochDetailsForBlockByBlockHash?: Maybe<EpochDetailsForBlock>;
  epochDetailsForBlockByEpochNumber?: Maybe<EpochDetailsForBlock>;
  /** Reads and enables pagination through a set of `EpochDetailsForBlock`. */
  epochDetailsForBlocks?: Maybe<EpochDetailsForBlocksConnection>;
  /** Reads and enables pagination through a set of `EpochEntry`. */
  epochEntries?: Maybe<EpochEntriesConnection>;
  epochEntry?: Maybe<EpochEntry>;
  /** Reads a single `EpochEntry` using its globally unique `ID`. */
  epochEntryById?: Maybe<EpochEntry>;
  feedByPublicKeyAndPostHash?: Maybe<Feed>;
  feedInteractionsBlockByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsBlock>;
  /** Reads and enables pagination through a set of `FeedInteractionsBlock`. */
  feedInteractionsBlocks?: Maybe<FeedInteractionsBlocksConnection>;
  feedInteractionsFocusFollowByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsFocusFollow>;
  /** Reads and enables pagination through a set of `FeedInteractionsFocusFollow`. */
  feedInteractionsFocusFollows?: Maybe<FeedInteractionsFocusFollowsConnection>;
  feedInteractionsFollowByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsFollow>;
  /** Reads and enables pagination through a set of `FeedInteractionsFollow`. */
  feedInteractionsFollows?: Maybe<FeedInteractionsFollowsConnection>;
  feedInteractionsNegativeReactionByPublicKeyAndPostHashAndRelatedUserPublicKey?: Maybe<FeedInteractionsNegativeReaction>;
  /** Reads and enables pagination through a set of `FeedInteractionsNegativeReaction`. */
  feedInteractionsNegativeReactions?: Maybe<FeedInteractionsNegativeReactionsConnection>;
  feedInteractionsNeutralReactionByPublicKeyAndPostHashAndRelatedUserPublicKey?: Maybe<FeedInteractionsNeutralReaction>;
  /** Reads and enables pagination through a set of `FeedInteractionsNeutralReaction`. */
  feedInteractionsNeutralReactions?: Maybe<FeedInteractionsNeutralReactionsConnection>;
  feedInteractionsNewPostByPublicKeyAndPostHash?: Maybe<FeedInteractionsNewPost>;
  /** Reads and enables pagination through a set of `FeedInteractionsNewPost`. */
  feedInteractionsNewPosts?: Maybe<FeedInteractionsNewPostsConnection>;
  feedInteractionsPositiveReactionByPublicKeyAndPostHashAndRelatedUserPublicKey?: Maybe<FeedInteractionsPositiveReaction>;
  /** Reads and enables pagination through a set of `FeedInteractionsPositiveReaction`. */
  feedInteractionsPositiveReactions?: Maybe<FeedInteractionsPositiveReactionsConnection>;
  /** Reads and enables pagination through a set of `FeedInteractionsPostAggAll`. */
  feedInteractionsPostAggAlls?: Maybe<FeedInteractionsPostAggAllsConnection>;
  feedInteractionsPostAggNegativeReactionByPosterPublicKeyAndPostHash?: Maybe<FeedInteractionsPostAggNegativeReaction>;
  /** Reads and enables pagination through a set of `FeedInteractionsPostAggNegativeReaction`. */
  feedInteractionsPostAggNegativeReactions?: Maybe<FeedInteractionsPostAggNegativeReactionsConnection>;
  feedInteractionsPostAggNeutralReactionByPosterPublicKeyAndPostHash?: Maybe<FeedInteractionsPostAggNeutralReaction>;
  /** Reads and enables pagination through a set of `FeedInteractionsPostAggNeutralReaction`. */
  feedInteractionsPostAggNeutralReactions?: Maybe<FeedInteractionsPostAggNeutralReactionsConnection>;
  feedInteractionsPostAggPositiveReactionByPosterPublicKeyAndPostHash?: Maybe<FeedInteractionsPostAggPositiveReaction>;
  /** Reads and enables pagination through a set of `FeedInteractionsPostAggPositiveReaction`. */
  feedInteractionsPostAggPositiveReactions?: Maybe<FeedInteractionsPostAggPositiveReactionsConnection>;
  feedInteractionsPostAggQuoteByPosterPublicKeyAndPostHash?: Maybe<FeedInteractionsPostAggQuote>;
  /** Reads and enables pagination through a set of `FeedInteractionsPostAggQuote`. */
  feedInteractionsPostAggQuotes?: Maybe<FeedInteractionsPostAggQuotesConnection>;
  /** Reads and enables pagination through a set of `FeedInteractionsPostAggReply`. */
  feedInteractionsPostAggReplies?: Maybe<FeedInteractionsPostAggRepliesConnection>;
  feedInteractionsPostAggReplyByPosterPublicKeyAndPostHash?: Maybe<FeedInteractionsPostAggReply>;
  feedInteractionsPostAggReportByPosterPublicKeyAndPostHash?: Maybe<FeedInteractionsPostAggReport>;
  /** Reads and enables pagination through a set of `FeedInteractionsPostAggReport`. */
  feedInteractionsPostAggReports?: Maybe<FeedInteractionsPostAggReportsConnection>;
  feedInteractionsPostAggRepostByPosterPublicKeyAndPostHash?: Maybe<FeedInteractionsPostAggRepost>;
  /** Reads and enables pagination through a set of `FeedInteractionsPostAggRepost`. */
  feedInteractionsPostAggReposts?: Maybe<FeedInteractionsPostAggRepostsConnection>;
  feedInteractionsPostAggShowLessByPosterPublicKeyAndPostHash?: Maybe<FeedInteractionsPostAggShowLess>;
  /** Reads and enables pagination through a set of `FeedInteractionsPostAggShowLess`. */
  feedInteractionsPostAggShowLesses?: Maybe<FeedInteractionsPostAggShowLessesConnection>;
  /** Reads and enables pagination through a set of `FeedInteractionsPostAggTimeDecay`. */
  feedInteractionsPostAggTimeDecays?: Maybe<FeedInteractionsPostAggTimeDecaysConnection>;
  feedInteractionsPostAggTipByPosterPublicKeyAndPostHash?: Maybe<FeedInteractionsPostAggTip>;
  /** Reads and enables pagination through a set of `FeedInteractionsPostAggTip`. */
  feedInteractionsPostAggTips?: Maybe<FeedInteractionsPostAggTipsConnection>;
  feedInteractionsPostAggViewByPosterPublicKeyAndPostHash?: Maybe<FeedInteractionsPostAggView>;
  /** Reads and enables pagination through a set of `FeedInteractionsPostAggView`. */
  feedInteractionsPostAggViews?: Maybe<FeedInteractionsPostAggViewsConnection>;
  feedInteractionsPostNewPostByPosterPublicKeyAndPostHash?: Maybe<FeedInteractionsPostNewPost>;
  /** Reads and enables pagination through a set of `FeedInteractionsPostNewPost`. */
  feedInteractionsPostNewPosts?: Maybe<FeedInteractionsPostNewPostsConnection>;
  feedInteractionsQuoteByPublicKeyAndPostHashAndRelatedUserPublicKey?: Maybe<FeedInteractionsQuote>;
  /** Reads and enables pagination through a set of `FeedInteractionsQuote`. */
  feedInteractionsQuotes?: Maybe<FeedInteractionsQuotesConnection>;
  /** Reads and enables pagination through a set of `FeedInteractionsReply`. */
  feedInteractionsReplies?: Maybe<FeedInteractionsRepliesConnection>;
  feedInteractionsReplyByPublicKeyAndPostHashAndRelatedUserPublicKey?: Maybe<FeedInteractionsReply>;
  feedInteractionsReportByPublicKeyAndPostHashAndRelatedUserPublicKey?: Maybe<FeedInteractionsReport>;
  /** Reads and enables pagination through a set of `FeedInteractionsReport`. */
  feedInteractionsReports?: Maybe<FeedInteractionsReportsConnection>;
  feedInteractionsRepostByPublicKeyAndPostHashAndRelatedUserPublicKey?: Maybe<FeedInteractionsRepost>;
  /** Reads and enables pagination through a set of `FeedInteractionsRepost`. */
  feedInteractionsReposts?: Maybe<FeedInteractionsRepostsConnection>;
  feedInteractionsShowLessByPublicKeyAndPostHashAndRelatedUserPublicKey?: Maybe<FeedInteractionsShowLess>;
  /** Reads and enables pagination through a set of `FeedInteractionsShowLess`. */
  feedInteractionsShowLesses?: Maybe<FeedInteractionsShowLessesConnection>;
  feedInteractionsTipByPublicKeyAndPostHashAndRelatedUserPublicKeyAndDiamondLevel?: Maybe<FeedInteractionsTip>;
  /** Reads and enables pagination through a set of `FeedInteractionsTip`. */
  feedInteractionsTips?: Maybe<FeedInteractionsTipsConnection>;
  feedInteractionsUserAggAllByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsUserAggAll>;
  /** Reads and enables pagination through a set of `FeedInteractionsUserAggAll`. */
  feedInteractionsUserAggAlls?: Maybe<FeedInteractionsUserAggAllsConnection>;
  feedInteractionsUserAggBlockByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsUserAggBlock>;
  /** Reads and enables pagination through a set of `FeedInteractionsUserAggBlock`. */
  feedInteractionsUserAggBlocks?: Maybe<FeedInteractionsUserAggBlocksConnection>;
  feedInteractionsUserAggFocusFollowByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsUserAggFocusFollow>;
  /** Reads and enables pagination through a set of `FeedInteractionsUserAggFocusFollow`. */
  feedInteractionsUserAggFocusFollows?: Maybe<FeedInteractionsUserAggFocusFollowsConnection>;
  feedInteractionsUserAggFollowByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsUserAggFollow>;
  /** Reads and enables pagination through a set of `FeedInteractionsUserAggFollow`. */
  feedInteractionsUserAggFollows?: Maybe<FeedInteractionsUserAggFollowsConnection>;
  feedInteractionsUserAggNegativeReactionByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsUserAggNegativeReaction>;
  /** Reads and enables pagination through a set of `FeedInteractionsUserAggNegativeReaction`. */
  feedInteractionsUserAggNegativeReactions?: Maybe<FeedInteractionsUserAggNegativeReactionsConnection>;
  feedInteractionsUserAggNeutralReactionByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsUserAggNeutralReaction>;
  /** Reads and enables pagination through a set of `FeedInteractionsUserAggNeutralReaction`. */
  feedInteractionsUserAggNeutralReactions?: Maybe<FeedInteractionsUserAggNeutralReactionsConnection>;
  feedInteractionsUserAggPositiveReactionByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsUserAggPositiveReaction>;
  /** Reads and enables pagination through a set of `FeedInteractionsUserAggPositiveReaction`. */
  feedInteractionsUserAggPositiveReactions?: Maybe<FeedInteractionsUserAggPositiveReactionsConnection>;
  feedInteractionsUserAggQuoteByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsUserAggQuote>;
  /** Reads and enables pagination through a set of `FeedInteractionsUserAggQuote`. */
  feedInteractionsUserAggQuotes?: Maybe<FeedInteractionsUserAggQuotesConnection>;
  /** Reads and enables pagination through a set of `FeedInteractionsUserAggReply`. */
  feedInteractionsUserAggReplies?: Maybe<FeedInteractionsUserAggRepliesConnection>;
  feedInteractionsUserAggReplyByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsUserAggReply>;
  feedInteractionsUserAggReportByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsUserAggReport>;
  /** Reads and enables pagination through a set of `FeedInteractionsUserAggReport`. */
  feedInteractionsUserAggReports?: Maybe<FeedInteractionsUserAggReportsConnection>;
  feedInteractionsUserAggRepostByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsUserAggRepost>;
  /** Reads and enables pagination through a set of `FeedInteractionsUserAggRepost`. */
  feedInteractionsUserAggReposts?: Maybe<FeedInteractionsUserAggRepostsConnection>;
  feedInteractionsUserAggShowLessByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsUserAggShowLess>;
  /** Reads and enables pagination through a set of `FeedInteractionsUserAggShowLess`. */
  feedInteractionsUserAggShowLesses?: Maybe<FeedInteractionsUserAggShowLessesConnection>;
  /** Reads and enables pagination through a set of `FeedInteractionsUserAggTimeDecay`. */
  feedInteractionsUserAggTimeDecays?: Maybe<FeedInteractionsUserAggTimeDecaysConnection>;
  feedInteractionsUserAggTipByPublicKeyAndRelatedUserPublicKey?: Maybe<FeedInteractionsUserAggTip>;
  /** Reads and enables pagination through a set of `FeedInteractionsUserAggTip`. */
  feedInteractionsUserAggTips?: Maybe<FeedInteractionsUserAggTipsConnection>;
  feedInteractionsViewByPublicKeyAndPostHashAndRelatedUserPublicKey?: Maybe<FeedInteractionsView>;
  /** Reads and enables pagination through a set of `FeedInteractionsView`. */
  feedInteractionsViews?: Maybe<FeedInteractionsViewsConnection>;
  feedPostScoreByPublicKeyAndPostHashAndPosterPublicKey?: Maybe<FeedPostScore>;
  /** Reads and enables pagination through a set of `FeedPostScore`. */
  feedPostScores?: Maybe<FeedPostScoresConnection>;
  /** Reads and enables pagination through a set of `FeedPostTimeDecay`. */
  feedPostTimeDecays?: Maybe<FeedPostTimeDecaysConnection>;
  feedSetting?: Maybe<FeedSetting>;
  /** Reads a single `FeedSetting` using its globally unique `ID`. */
  feedSettingById?: Maybe<FeedSetting>;
  /** Reads and enables pagination through a set of `FeedSetting`. */
  feedSettings?: Maybe<FeedSettingsConnection>;
  feedUserAssociationByAssociationId?: Maybe<FeedUserAssociation>;
  feedUserAssociationByTransactorPkidAndTargetUserPkidAndAppPkidAndAssociationTypeAndAssociationValue?: Maybe<FeedUserAssociation>;
  /** Reads and enables pagination through a set of `FeedUserAssociation`. */
  feedUserAssociations?: Maybe<FeedUserAssociationsConnection>;
  feedUserPostAndRelationshipScoreByPublicKeyAndPostHashAndPosterPublicKey?: Maybe<FeedUserPostAndRelationshipScore>;
  /** Reads and enables pagination through a set of `FeedUserPostAndRelationshipScore`. */
  feedUserPostAndRelationshipScores?: Maybe<FeedUserPostAndRelationshipScoresConnection>;
  feedUserRelationshipScoreByPublicKeyAndRelatedPublicKey?: Maybe<FeedUserRelationshipScore>;
  /** Reads and enables pagination through a set of `FeedUserRelationshipScore`. */
  feedUserRelationshipScores?: Maybe<FeedUserRelationshipScoresConnection>;
  feedUserRelationshipScores1DByPublicKeyAndRelatedPublicKey?: Maybe<FeedUserRelationshipScores1D>;
  /** Reads and enables pagination through a set of `FeedUserRelationshipScores1D`. */
  feedUserRelationshipScores1Ds?: Maybe<FeedUserRelationshipScores1DsConnection>;
  feedUserRelationshipScores2DByPublicKeyAndRelatedPublicKey?: Maybe<FeedUserRelationshipScores2D>;
  /** Reads and enables pagination through a set of `FeedUserRelationshipScores2D`. */
  feedUserRelationshipScores2Ds?: Maybe<FeedUserRelationshipScores2DsConnection>;
  feedUserScoredPostByPublicKeyAndPostHash?: Maybe<FeedUserScoredPost>;
  /** Reads and enables pagination through a set of `FeedUserScoredPost`. */
  feedUserScoredPosts?: Maybe<FeedUserScoredPostsConnection>;
  /** Reads and enables pagination through a set of `FeedUserSetting`. */
  feedUserSettings?: Maybe<FeedUserSettingsConnection>;
  /** Reads and enables pagination through a set of `FeedUser`. */
  feedUsers?: Maybe<FeedUsersConnection>;
  /** Reads and enables pagination through a set of `FeedUsersMv`. */
  feedUsersMvs?: Maybe<FeedUsersMvsConnection>;
  /** Reads and enables pagination through a set of `FeedWeightedPostScore`. */
  feedWeightedPostScores?: Maybe<FeedWeightedPostScoresConnection>;
  /** Reads and enables pagination through a set of `FeedWeightedUserRelationshipScore`. */
  feedWeightedUserRelationshipScores?: Maybe<FeedWeightedUserRelationshipScoresConnection>;
  /** Reads and enables pagination through a set of `Feed`. */
  feeds?: Maybe<FeedsConnection>;
  filledDaoCoinOrdersWithPrice?: Maybe<FilledDaoCoinOrdersWithPrice>;
  /** Reads a single `FilledDaoCoinOrdersWithPrice` using its globally unique `ID`. */
  filledDaoCoinOrdersWithPriceById?: Maybe<FilledDaoCoinOrdersWithPrice>;
  /** Reads and enables pagination through a set of `FilledDaoCoinOrdersWithPrice`. */
  filledDaoCoinOrdersWithPrices?: Maybe<FilledDaoCoinOrdersWithPricesConnection>;
  filterableMessageThreadByThreadIdentifierAndParticipantPublicKey?: Maybe<FilterableMessageThread>;
  /** Reads and enables pagination through a set of `FilterableMessageThread`. */
  filterableMessageThreads?: Maybe<FilterableMessageThreadsConnection>;
  firstAgg?: Maybe<Scalars['String']['output']>;
  focusList?: Maybe<FocusList>;
  /** Reads a single `FocusList` using its globally unique `ID`. */
  focusListById?: Maybe<FocusList>;
  focusListByUserPublicKeyBase58CheckAndListName?: Maybe<FocusList>;
  /** Reads and enables pagination through a set of `FocusListFdw`. */
  focusListFdws?: Maybe<FocusListFdwsConnection>;
  focusListMember?: Maybe<FocusListMember>;
  /** Reads a single `FocusListMember` using its globally unique `ID`. */
  focusListMemberById?: Maybe<FocusListMember>;
  focusListMemberByListMemberPublicKeyBase58CheckAndListId?: Maybe<FocusListMember>;
  /** Reads and enables pagination through a set of `FocusListMemberFdw`. */
  focusListMemberFdws?: Maybe<FocusListMemberFdwsConnection>;
  /** Reads and enables pagination through a set of `FocusListMember`. */
  focusListMembers?: Maybe<FocusListMembersConnection>;
  /** Reads and enables pagination through a set of `FocusList`. */
  focusLists?: Maybe<FocusListsConnection>;
  /** Reads and enables pagination through a set of `FocusPrice`. */
  focusPrices?: Maybe<FocusPricesConnection>;
  /** Reads and enables pagination through a set of `FocusUser`. */
  focusUsers?: Maybe<FocusUsersConnection>;
  /** Reads a single `FollowFast` using its globally unique `ID`. */
  followFastById?: Maybe<FollowFast>;
  /** Reads and enables pagination through a set of `FollowFast`. */
  followFasts?: Maybe<FollowFastsConnection>;
  /** Reads and enables pagination through a set of `Follow`. */
  follows?: Maybe<FollowsConnection>;
  /** Reads and enables pagination through a set of `GlobalParamsEntry`. */
  globalParamsEntries?: Maybe<GlobalParamsEntriesConnection>;
  /** Reads a single `GlobalParamsEntry` using its globally unique `ID`. */
  globalParamsEntryById?: Maybe<GlobalParamsEntry>;
  globalVal?: Maybe<GlobalVal>;
  /** Reads a single `GlobalVal` using its globally unique `ID`. */
  globalValById?: Maybe<GlobalVal>;
  globalValByRowId?: Maybe<GlobalVal>;
  /** Reads and enables pagination through a set of `GlobalVal`. */
  globalVals?: Maybe<GlobalValsConnection>;
  hexToNumeric?: Maybe<Scalars['BigFloat']['output']>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  id: Scalars['ID']['output'];
  insertInteractionsUpdate?: Maybe<InsertInteractionsUpdate>;
  /** Reads a single `InsertInteractionsUpdate` using its globally unique `ID`. */
  insertInteractionsUpdateById?: Maybe<InsertInteractionsUpdate>;
  /** Reads and enables pagination through a set of `InsertInteractionsUpdate`. */
  insertInteractionsUpdates?: Maybe<InsertInteractionsUpdatesConnection>;
  jailedHistoryEvent?: Maybe<JailedHistoryEvent>;
  /** Reads a single `JailedHistoryEvent` using its globally unique `ID`. */
  jailedHistoryEventById?: Maybe<JailedHistoryEvent>;
  /** Reads and enables pagination through a set of `JailedHistoryEvent`. */
  jailedHistoryEvents?: Maybe<JailedHistoryEventsConnection>;
  lastAgg?: Maybe<Scalars['String']['output']>;
  latestAffectedKey?: Maybe<LatestAffectedKey>;
  /** Reads a single `LatestAffectedKey` using its globally unique `ID`. */
  latestAffectedKeyById?: Maybe<LatestAffectedKey>;
  /** Reads and enables pagination through a set of `LatestAffectedKey`. */
  latestAffectedKeys?: Maybe<LatestAffectedKeysConnection>;
  /** Reads and enables pagination through a set of `LeaderScheduleEntry`. */
  leaderScheduleEntries?: Maybe<LeaderScheduleEntriesConnection>;
  /** Reads a single `LeaderScheduleEntry` using its globally unique `ID`. */
  leaderScheduleEntryById?: Maybe<LeaderScheduleEntry>;
  /** Reads a single `LegacyMessage` using its globally unique `ID`. */
  legacyMessageById?: Maybe<LegacyMessage>;
  /** Reads and enables pagination through a set of `LegacyMessage`. */
  legacyMessages?: Maybe<LegacyMessagesConnection>;
  /** Reads a single `Like` using its globally unique `ID`. */
  likeById?: Maybe<Like>;
  /** Reads and enables pagination through a set of `Like`. */
  likes?: Maybe<LikesConnection>;
  lockedBalanceByHodlerPkidAndProfilePkid?: Maybe<LockedBalance>;
  /** Reads and enables pagination through a set of `LockedBalanceEntry`. */
  lockedBalanceEntries?: Maybe<LockedBalanceEntriesConnection>;
  /** Reads a single `LockedBalanceEntry` using its globally unique `ID`. */
  lockedBalanceEntryById?: Maybe<LockedBalanceEntry>;
  /** Reads and enables pagination through a set of `LockedBalance`. */
  lockedBalances?: Maybe<LockedBalancesConnection>;
  /** Reads and enables pagination through a set of `LockedStakeEntry`. */
  lockedStakeEntries?: Maybe<LockedStakeEntriesConnection>;
  /** Reads a single `LockedStakeEntry` using its globally unique `ID`. */
  lockedStakeEntryById?: Maybe<LockedStakeEntry>;
  /** Reads and enables pagination through a set of `MaxPostScoreBlockHeight`. */
  maxPostScoreBlockHeights?: Maybe<MaxPostScoreBlockHeightsConnection>;
  message?: Maybe<Message>;
  messageByBadgerKey?: Maybe<Message>;
  /** Reads a single `Message` using its globally unique `ID`. */
  messageById?: Maybe<Message>;
  messageSpamFilter?: Maybe<MessageSpamFilter>;
  messageSpamFilterByBadgerKey?: Maybe<MessageSpamFilter>;
  /** Reads a single `MessageSpamFilter` using its globally unique `ID`. */
  messageSpamFilterById?: Maybe<MessageSpamFilter>;
  /** Reads and enables pagination through a set of `MessageSpamFilter`. */
  messageSpamFilters?: Maybe<MessageSpamFiltersConnection>;
  messageThreadByThreadIdentifier?: Maybe<MessageThread>;
  messageThreadParticipantTbl?: Maybe<MessageThreadParticipantTbl>;
  /** Reads a single `MessageThreadParticipantTbl` using its globally unique `ID`. */
  messageThreadParticipantTblById?: Maybe<MessageThreadParticipantTbl>;
  /** Reads and enables pagination through a set of `MessageThreadParticipantTbl`. */
  messageThreadParticipantTbls?: Maybe<MessageThreadParticipantTblsConnection>;
  /** Reads and enables pagination through a set of `MessageThreadParticipantView`. */
  messageThreadParticipantViews?: Maybe<MessageThreadParticipantViewsConnection>;
  /** Reads and enables pagination through a set of `MessageThreadParticipant`. */
  messageThreadParticipants?: Maybe<MessageThreadParticipantsConnection>;
  /** Reads and enables pagination through a set of `MessageThread`. */
  messageThreads?: Maybe<MessageThreadsConnection>;
  messageView?: Maybe<MessageView>;
  /** Reads a single `MessageView` using its globally unique `ID`. */
  messageViewById?: Maybe<MessageView>;
  /** Reads and enables pagination through a set of `MessageView`. */
  messageViews?: Maybe<MessageViewsConnection>;
  /** Reads and enables pagination through a set of `Message`. */
  messages?: Maybe<MessagesConnection>;
  /** Reads and enables pagination through a set of `MyStakeSummary`. */
  myStakeSummaries?: Maybe<MyStakeSummariesConnection>;
  myStakeSummaryByStakerPkid?: Maybe<MyStakeSummary>;
  /** Reads a single `NftBid` using its globally unique `ID`. */
  nftBidById?: Maybe<NftBid>;
  /** Reads and enables pagination through a set of `NftBid`. */
  nftBids?: Maybe<NftBidsConnection>;
  /** Reads a single `Nft` using its globally unique `ID`. */
  nftById?: Maybe<Nft>;
  /** Reads and enables pagination through a set of `Nft`. */
  nfts?: Maybe<NftsConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  pageView?: Maybe<PageView>;
  /** Reads a single `PageView` using its globally unique `ID`. */
  pageViewById?: Maybe<PageView>;
  /** Reads and enables pagination through a set of `PageView`. */
  pageViews?: Maybe<PageViewsConnection>;
  payment?: Maybe<Payment>;
  paymentByAssociationId?: Maybe<Payment>;
  /** Reads a single `Payment` using its globally unique `ID`. */
  paymentById?: Maybe<Payment>;
  /** Reads and enables pagination through a set of `Payment`. */
  payments?: Maybe<PaymentsConnection>;
  postAssociationByAssociationId?: Maybe<PostAssociation>;
  postAssociationByTransactorPkidAndPostHashAndAppPkidAndAssociationTypeAndAssociationValue?: Maybe<PostAssociation>;
  /** Reads and enables pagination through a set of `PostAssociationCount`. */
  postAssociationCounts?: Maybe<PostAssociationCountsConnection>;
  /** Reads and enables pagination through a set of `PostAssociation`. */
  postAssociations?: Maybe<PostAssociationsConnection>;
  postByPostHash?: Maybe<Post>;
  postDiamondTotalByPostHash?: Maybe<PostDiamondTotal>;
  /** Reads and enables pagination through a set of `PostDiamondTotal`. */
  postDiamondTotals?: Maybe<PostDiamondTotalsConnection>;
  /** Reads and enables pagination through a set of `PostModeration`. */
  postModerations?: Maybe<PostModerationsConnection>;
  postScoreUpdate?: Maybe<PostScoreUpdate>;
  /** Reads a single `PostScoreUpdate` using its globally unique `ID`. */
  postScoreUpdateById?: Maybe<PostScoreUpdate>;
  /** Reads and enables pagination through a set of `PostScoreUpdate`. */
  postScoreUpdates?: Maybe<PostScoreUpdatesConnection>;
  /** Reads and enables pagination through a set of `PostSubscriptionStat`. */
  postSubscriptionStats?: Maybe<PostSubscriptionStatsConnection>;
  postView?: Maybe<PostView>;
  /** Reads a single `PostView` using its globally unique `ID`. */
  postViewById?: Maybe<PostView>;
  /** Reads and enables pagination through a set of `PostView`. */
  postViews?: Maybe<PostViewsConnection>;
  /** Reads and enables pagination through a set of `PostWealthMv`. */
  postWealthMvs?: Maybe<PostWealthMvsConnection>;
  /** Reads and enables pagination through a set of `PostWealth`. */
  postWealths?: Maybe<PostWealthConnection>;
  /** Reads and enables pagination through a set of `Post`. */
  posts?: Maybe<PostsConnection>;
  profile?: Maybe<Profile>;
  /** Reads a single `Profile` using its globally unique `ID`. */
  profileById?: Maybe<Profile>;
  profileByUsername?: Maybe<Profile>;
  /** Reads and enables pagination through a set of `Profile`. */
  profiles?: Maybe<ProfilesConnection>;
  publicKey?: Maybe<PublicKey>;
  /** Reads a single `PublicKey` using its globally unique `ID`. */
  publicKeyById?: Maybe<PublicKey>;
  /** Reads and enables pagination through a set of `PublicKey`. */
  publicKeys?: Maybe<PublicKeysConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads and enables pagination through a set of `SnapshotValidatorEntry`. */
  snapshotValidatorEntries?: Maybe<SnapshotValidatorEntriesConnection>;
  snapshotValidatorEntry?: Maybe<SnapshotValidatorEntry>;
  /** Reads a single `SnapshotValidatorEntry` using its globally unique `ID`. */
  snapshotValidatorEntryById?: Maybe<SnapshotValidatorEntry>;
  /** Reads and enables pagination through a set of `StakeEntry`. */
  stakeEntries?: Maybe<StakeEntriesConnection>;
  /** Reads a single `StakeEntry` using its globally unique `ID`. */
  stakeEntryById?: Maybe<StakeEntry>;
  stakeReward?: Maybe<StakeReward>;
  /** Reads a single `StakeReward` using its globally unique `ID`. */
  stakeRewardById?: Maybe<StakeReward>;
  /** Reads and enables pagination through a set of `StakeReward`. */
  stakeRewards?: Maybe<StakeRewardsConnection>;
  subscription?: Maybe<Subscription>;
  subscriptionByAssociationId?: Maybe<Subscription>;
  /** Reads a single `Subscription` using its globally unique `ID`. */
  subscriptionById?: Maybe<Subscription>;
  subscriptionBySubscriberPublicKeyAndAccessGroupKeyNameAndAccessGroupOwnerPublicKey?: Maybe<Subscription>;
  subscriptionTier?: Maybe<SubscriptionTier>;
  subscriptionTierByAccessGroupOwnerPublicKeyAndAccessGroupKeyName?: Maybe<SubscriptionTier>;
  subscriptionTierByAssociationId?: Maybe<SubscriptionTier>;
  /** Reads a single `SubscriptionTier` using its globally unique `ID`. */
  subscriptionTierById?: Maybe<SubscriptionTier>;
  /** Reads and enables pagination through a set of `SubscriptionTier`. */
  subscriptionTiers?: Maybe<SubscriptionTiersConnection>;
  /** Reads and enables pagination through a set of `Subscription`. */
  subscriptions?: Maybe<SubscriptionsConnection>;
  /** Reads and enables pagination through a set of `TokenAmmMetadatum`. */
  tokenAmmMetadata?: Maybe<TokenAmmMetadataConnection>;
  tokenAmmMetadatum?: Maybe<TokenAmmMetadatum>;
  /** Reads a single `TokenAmmMetadatum` using its globally unique `ID`. */
  tokenAmmMetadatumById?: Maybe<TokenAmmMetadatum>;
  tokenAmmMetadatumByTokenPublicKey?: Maybe<TokenAmmMetadatum>;
  tokenBalanceAgg?: Maybe<TokenBalanceAgg>;
  tokenBalanceAggByCreatorPkid?: Maybe<TokenBalanceAgg>;
  /** Reads a single `TokenBalanceAgg` using its globally unique `ID`. */
  tokenBalanceAggById?: Maybe<TokenBalanceAgg>;
  /** Reads and enables pagination through a set of `TokenBalanceAgg`. */
  tokenBalanceAggs?: Maybe<TokenBalanceAggsConnection>;
  /** Reads a single `TokenBalance` using its globally unique `ID`. */
  tokenBalanceById?: Maybe<TokenBalance>;
  /** Reads and enables pagination through a set of `TokenBalanceSummary`. */
  tokenBalanceSummaries?: Maybe<TokenBalanceSummariesConnection>;
  tokenBalanceSummaryByHodlerPkidAndCreatorPkid?: Maybe<TokenBalanceSummary>;
  /** Reads and enables pagination through a set of `TokenBalance`. */
  tokenBalances?: Maybe<TokenBalancesConnection>;
  /** Reads and enables pagination through a set of `TokenMarketVolumeSummary`. */
  tokenMarketVolumeSummaries?: Maybe<TokenMarketVolumeSummariesConnection>;
  tokenMarketVolumeSummary?: Maybe<TokenMarketVolumeSummary>;
  tokenMarketVolumeSummaryByCoin1CreatorPkidAndCoin2CreatorPkid?: Maybe<TokenMarketVolumeSummary>;
  /** Reads a single `TokenMarketVolumeSummary` using its globally unique `ID`. */
  tokenMarketVolumeSummaryById?: Maybe<TokenMarketVolumeSummary>;
  /** Reads and enables pagination through a set of `TokenMetadatum`. */
  tokenMetadata?: Maybe<TokenMetadataConnection>;
  tokenMetadatum?: Maybe<TokenMetadatum>;
  /** Reads a single `TokenMetadatum` using its globally unique `ID`. */
  tokenMetadatumById?: Maybe<TokenMetadatum>;
  /** Reads and enables pagination through a set of `TokensByTotalVolume`. */
  tokensByTotalVolumes?: Maybe<TokensByTotalVolumesConnection>;
  totalUnclaimedMessageTipByPublicKey?: Maybe<TotalUnclaimedMessageTip>;
  /** Reads and enables pagination through a set of `TotalUnclaimedMessageTip`. */
  totalUnclaimedMessageTips?: Maybe<TotalUnclaimedMessageTipsConnection>;
  /** Reads and enables pagination through a set of `TradingRecentTrade`. */
  tradingRecentTrades?: Maybe<TradingRecentTradesConnection>;
  /** Reads and enables pagination through a set of `TradingTopTradersByTokenByVolume`. */
  tradingTopTradersByTokenByVolumes?: Maybe<TradingTopTradersByTokenByVolumesConnection>;
  transactionByTransactionHash?: Maybe<Transaction>;
  transactionType?: Maybe<TransactionType>;
  /** Reads a single `TransactionType` using its globally unique `ID`. */
  transactionTypeById?: Maybe<TransactionType>;
  /** Reads and enables pagination through a set of `TransactionType`. */
  transactionTypes?: Maybe<TransactionTypesConnection>;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions?: Maybe<TransactionsConnection>;
  unreadMessagesCountByThreadIdentifierAndPublicKey?: Maybe<UnreadMessagesCount>;
  /** Reads and enables pagination through a set of `UnreadMessagesCount`. */
  unreadMessagesCounts?: Maybe<UnreadMessagesCountsConnection>;
  unreadMessagesTotalCountByPublicKey?: Maybe<UnreadMessagesTotalCount>;
  /** Reads and enables pagination through a set of `UnreadMessagesTotalCount`. */
  unreadMessagesTotalCounts?: Maybe<UnreadMessagesTotalCountsConnection>;
  unreadThreadsCountByPublicKey?: Maybe<UnreadThreadsCount>;
  /** Reads and enables pagination through a set of `UnreadThreadsCount`. */
  unreadThreadsCounts?: Maybe<UnreadThreadsCountsConnection>;
  userAssociationByAssociationId?: Maybe<UserAssociation>;
  userAssociationByTransactorPkidAndTargetUserPkidAndAppPkidAndAssociationTypeAndAssociationValue?: Maybe<UserAssociation>;
  /** Reads and enables pagination through a set of `UserAssociation`. */
  userAssociations?: Maybe<UserAssociationsConnection>;
  userDaoCoinStatByUserPublicKeyAndCoinPublicKey?: Maybe<UserDaoCoinStat>;
  /** Reads and enables pagination through a set of `UserDaoCoinStat`. */
  userDaoCoinStats?: Maybe<UserDaoCoinStatsConnection>;
  /** Reads and enables pagination through a set of `UserModeration`. */
  userModerations?: Maybe<UserModerationsConnection>;
  /** Reads and enables pagination through a set of `UserRelationshipCustomWeight`. */
  userRelationshipCustomWeights?: Maybe<UserRelationshipCustomWeightsConnection>;
  userScoreUpdate?: Maybe<UserScoreUpdate>;
  /** Reads a single `UserScoreUpdate` using its globally unique `ID`. */
  userScoreUpdateById?: Maybe<UserScoreUpdate>;
  /** Reads and enables pagination through a set of `UserScoreUpdate`. */
  userScoreUpdates?: Maybe<UserScoreUpdatesConnection>;
  /** Reads and enables pagination through a set of `UtxoOperation`. */
  utxoOperations?: Maybe<UtxoOperationsConnection>;
  /** Reads and enables pagination through a set of `ValidatorEntry`. */
  validatorEntries?: Maybe<ValidatorEntriesConnection>;
  /** Reads a single `ValidatorEntry` using its globally unique `ID`. */
  validatorEntryById?: Maybe<ValidatorEntry>;
  validatorEntryByValidatorPkid?: Maybe<ValidatorEntry>;
  /** Reads a single `YieldCurvePoint` using its globally unique `ID`. */
  yieldCurvePointById?: Maybe<YieldCurvePoint>;
  /** Reads and enables pagination through a set of `YieldCurvePoint`. */
  yieldCurvePoints?: Maybe<YieldCurvePointsConnection>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAccessGroupByAccessGroupOwnerPublicKeyAndAccessGroupKeyNameArgs = {
  accessGroupKeyName: Scalars['String']['input'];
  accessGroupOwnerPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccessGroupByAccessGroupPublicKeyArgs = {
  accessGroupPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccessGroupByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccessGroupMemberByAccessGroupOwnerPublicKeyAndAccessGroupMemberPublicKeyAndAccessGroupKeyNameAndAccessGroupMemberKeyNameArgs =
  {
    accessGroupKeyName: Scalars['String']['input'];
    accessGroupMemberKeyName: Scalars['String']['input'];
    accessGroupMemberPublicKey: Scalars['String']['input'];
    accessGroupOwnerPublicKey: Scalars['String']['input'];
  };

/** The root query type which gives access points into the data universe. */
export type QueryAccessGroupMemberByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccessGroupMembersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AccessGroupMemberCondition>;
  filter?: InputMaybe<AccessGroupMemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGroupMembersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAccessGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AccessGroupCondition>;
  filter?: InputMaybe<AccessGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessGroupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountArgs = {
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountByPkidArgs = {
  pkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountByPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountByUsernameArgs = {
  username: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountWealthArgs = {
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountWealthByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountWealthByPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountWealthChainUserArgs = {
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountWealthChainUserByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountWealthChainUserByPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountWealthChainUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AccountWealthChainUserCondition>;
  filter?: InputMaybe<AccountWealthChainUserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccountWealthChainUsersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountWealthsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AccountWealthCondition>;
  filter?: InputMaybe<AccountWealthFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccountWealthOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AccountCondition>;
  filter?: InputMaybe<AccountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAffectedPublicKeyArgs = {
  metadata: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  transactionHash: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAffectedPublicKeyByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAffectedPublicKeysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AffectedPublicKeyCondition>;
  filter?: InputMaybe<AffectedPublicKeyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AffectedPublicKeysOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryBlockArgs = {
  blockHash: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlockByBlockHashArgs = {
  blockHash: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlockByHeightArgs = {
  height: Scalars['BigInt']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlockByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlockSignerArgs = {
  blockHash: Scalars['String']['input'];
  signerIndex: Scalars['BigInt']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlockSignerByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlockSignersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BlockSignerCondition>;
  filter?: InputMaybe<BlockSignerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlockSignersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryBlocksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BlockCondition>;
  filter?: InputMaybe<BlockFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlocksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryBlsPublicKeyPkidPairEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BlsPublicKeyPkidPairEntryCondition>;
  filter?: InputMaybe<BlsPublicKeyPkidPairEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlsPublicKeyPkidPairEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryBlsPublicKeyPkidPairEntryArgs = {
  badgerKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlsPublicKeyPkidPairEntryByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBlsPublicKeyPkidPairSnapshotEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryCondition>;
  filter?: InputMaybe<BlsPublicKeyPkidPairSnapshotEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlsPublicKeyPkidPairSnapshotEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryBlsPublicKeyPkidPairSnapshotEntryByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCoinCategoryCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CoinCategoryCountCondition>;
  filter?: InputMaybe<CoinCategoryCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CoinCategoryCountsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorCoinBalanceArgs = {
  creatorPkid: Scalars['String']['input'];
  hodlerPkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorCoinBalanceByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorCoinBalancesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CreatorCoinBalanceCondition>;
  filter?: InputMaybe<CreatorCoinBalanceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorCoinBalancesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorStatArgs = {
  publicKeyBase58Check: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorStatByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorStatByPkidArgs = {
  pkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorStatByPublicKeyBase58CheckArgs = {
  publicKeyBase58Check: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorStatByUsernameArgs = {
  username: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CreatorStatCondition>;
  filter?: InputMaybe<CreatorStatFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorStatsTotalPaidMessageEarningsUsdCentsAllTimesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimeCondition>;
  filter?: InputMaybe<CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorStatsTotalPaidMessageEarningsUsdCentsAllTimesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorStatsTotalPaidMessageEarningsUsdCentsPast7DsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DCondition>;
  filter?: InputMaybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorStatsTotalPaidMessageEarningsUsdCentsPast7DsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorStatsTotalPaidMessageEarningsUsdCentsPast24HsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HCondition>;
  filter?: InputMaybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorStatsTotalPaidMessageEarningsUsdCentsPast24HsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCreatorStatsTotalPaidMessageEarningsUsdCentsPast30DsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DCondition>;
  filter?: InputMaybe<CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreatorStatsTotalPaidMessageEarningsUsdCentsPast30DsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDaoCoinCandlesticksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DaoCoinCandlestickCondition>;
  filter?: InputMaybe<DaoCoinCandlestickFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DaoCoinCandlesticksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDaoCoinStatArgs = {
  coinPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDaoCoinStatByCoinPublicKeyArgs = {
  coinPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDaoCoinStatByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDaoCoinStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DaoCoinStatCondition>;
  filter?: InputMaybe<DaoCoinStatFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DaoCoinStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDerivedKeyByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDerivedKeysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DerivedKeyCondition>;
  filter?: InputMaybe<DerivedKeyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DerivedKeysOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoBalanceArgs = {
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoBalanceByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoBalanceByPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoBalancesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DesoBalanceCondition>;
  filter?: InputMaybe<DesoBalanceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DesoBalancesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoTokenLimitOrderArgs = {
  badgerKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoTokenLimitOrderBidAskArgs = {
  coin1CreatorPkid: Scalars['String']['input'];
  coin2CreatorPkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoTokenLimitOrderBidAskByCoin1CreatorPkidAndCoin2CreatorPkidArgs = {
  coin1CreatorPkid: Scalars['String']['input'];
  coin2CreatorPkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoTokenLimitOrderBidAskByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoTokenLimitOrderBidAsksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DesoTokenLimitOrderBidAskCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderBidAskFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrderBidAsksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoTokenLimitOrderByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoTokenLimitOrderByOrderIdArgs = {
  orderId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDesoTokenLimitOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DesoTokenLimitOrderCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDiamondByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryDiamondsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DiamondCondition>;
  filter?: InputMaybe<DiamondFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DiamondsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEpochDetailsForBlockByBlockHashArgs = {
  blockHash: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEpochDetailsForBlockByEpochNumberArgs = {
  epochNumber: Scalars['BigInt']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEpochDetailsForBlocksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EpochDetailsForBlockCondition>;
  filter?: InputMaybe<EpochDetailsForBlockFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EpochDetailsForBlocksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEpochEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EpochEntryCondition>;
  filter?: InputMaybe<EpochEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EpochEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEpochEntryArgs = {
  epochNumber: Scalars['BigInt']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEpochEntryByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedByPublicKeyAndPostHashArgs = {
  postHash: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsBlockByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsBlocksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsBlockCondition>;
  filter?: InputMaybe<FeedInteractionsBlockFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsBlocksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsFocusFollowByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsFocusFollowsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsFocusFollowCondition>;
  filter?: InputMaybe<FeedInteractionsFocusFollowFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsFocusFollowsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsFollowByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsFollowsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsFollowCondition>;
  filter?: InputMaybe<FeedInteractionsFollowFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsFollowsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsNegativeReactionByPublicKeyAndPostHashAndRelatedUserPublicKeyArgs = {
  postHash: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsNegativeReactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsNegativeReactionCondition>;
  filter?: InputMaybe<FeedInteractionsNegativeReactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsNegativeReactionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsNeutralReactionByPublicKeyAndPostHashAndRelatedUserPublicKeyArgs = {
  postHash: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsNeutralReactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsNeutralReactionCondition>;
  filter?: InputMaybe<FeedInteractionsNeutralReactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsNeutralReactionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsNewPostByPublicKeyAndPostHashArgs = {
  postHash: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsNewPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsNewPostCondition>;
  filter?: InputMaybe<FeedInteractionsNewPostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsNewPostsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPositiveReactionByPublicKeyAndPostHashAndRelatedUserPublicKeyArgs = {
  postHash: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPositiveReactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsPositiveReactionCondition>;
  filter?: InputMaybe<FeedInteractionsPositiveReactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsPositiveReactionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggAllsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsPostAggAllCondition>;
  filter?: InputMaybe<FeedInteractionsPostAggAllFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsPostAggAllsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggNegativeReactionByPosterPublicKeyAndPostHashArgs = {
  postHash: Scalars['String']['input'];
  posterPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggNegativeReactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsPostAggNegativeReactionCondition>;
  filter?: InputMaybe<FeedInteractionsPostAggNegativeReactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsPostAggNegativeReactionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggNeutralReactionByPosterPublicKeyAndPostHashArgs = {
  postHash: Scalars['String']['input'];
  posterPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggNeutralReactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsPostAggNeutralReactionCondition>;
  filter?: InputMaybe<FeedInteractionsPostAggNeutralReactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsPostAggNeutralReactionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggPositiveReactionByPosterPublicKeyAndPostHashArgs = {
  postHash: Scalars['String']['input'];
  posterPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggPositiveReactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsPostAggPositiveReactionCondition>;
  filter?: InputMaybe<FeedInteractionsPostAggPositiveReactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsPostAggPositiveReactionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggQuoteByPosterPublicKeyAndPostHashArgs = {
  postHash: Scalars['String']['input'];
  posterPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggQuotesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsPostAggQuoteCondition>;
  filter?: InputMaybe<FeedInteractionsPostAggQuoteFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsPostAggQuotesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggRepliesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsPostAggReplyCondition>;
  filter?: InputMaybe<FeedInteractionsPostAggReplyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsPostAggRepliesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggReplyByPosterPublicKeyAndPostHashArgs = {
  postHash: Scalars['String']['input'];
  posterPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggReportByPosterPublicKeyAndPostHashArgs = {
  postHash: Scalars['String']['input'];
  posterPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggReportsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsPostAggReportCondition>;
  filter?: InputMaybe<FeedInteractionsPostAggReportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsPostAggReportsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggRepostByPosterPublicKeyAndPostHashArgs = {
  postHash: Scalars['String']['input'];
  posterPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggRepostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsPostAggRepostCondition>;
  filter?: InputMaybe<FeedInteractionsPostAggRepostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsPostAggRepostsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggShowLessByPosterPublicKeyAndPostHashArgs = {
  postHash: Scalars['String']['input'];
  posterPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggShowLessesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsPostAggShowLessCondition>;
  filter?: InputMaybe<FeedInteractionsPostAggShowLessFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsPostAggShowLessesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggTimeDecaysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsPostAggTimeDecayCondition>;
  filter?: InputMaybe<FeedInteractionsPostAggTimeDecayFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsPostAggTimeDecaysOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggTipByPosterPublicKeyAndPostHashArgs = {
  postHash: Scalars['String']['input'];
  posterPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggTipsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsPostAggTipCondition>;
  filter?: InputMaybe<FeedInteractionsPostAggTipFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsPostAggTipsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggViewByPosterPublicKeyAndPostHashArgs = {
  postHash: Scalars['String']['input'];
  posterPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostAggViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsPostAggViewCondition>;
  filter?: InputMaybe<FeedInteractionsPostAggViewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsPostAggViewsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostNewPostByPosterPublicKeyAndPostHashArgs = {
  postHash: Scalars['String']['input'];
  posterPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsPostNewPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsPostNewPostCondition>;
  filter?: InputMaybe<FeedInteractionsPostNewPostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsPostNewPostsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsQuoteByPublicKeyAndPostHashAndRelatedUserPublicKeyArgs = {
  postHash: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsQuotesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsQuoteCondition>;
  filter?: InputMaybe<FeedInteractionsQuoteFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsQuotesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsRepliesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsReplyCondition>;
  filter?: InputMaybe<FeedInteractionsReplyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsRepliesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsReplyByPublicKeyAndPostHashAndRelatedUserPublicKeyArgs = {
  postHash: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsReportByPublicKeyAndPostHashAndRelatedUserPublicKeyArgs = {
  postHash: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsReportsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsReportCondition>;
  filter?: InputMaybe<FeedInteractionsReportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsReportsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsRepostByPublicKeyAndPostHashAndRelatedUserPublicKeyArgs = {
  postHash: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsRepostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsRepostCondition>;
  filter?: InputMaybe<FeedInteractionsRepostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsRepostsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsShowLessByPublicKeyAndPostHashAndRelatedUserPublicKeyArgs = {
  postHash: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsShowLessesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsShowLessCondition>;
  filter?: InputMaybe<FeedInteractionsShowLessFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsShowLessesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsTipByPublicKeyAndPostHashAndRelatedUserPublicKeyAndDiamondLevelArgs = {
  diamondLevel: Scalars['Int']['input'];
  postHash: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsTipsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsTipCondition>;
  filter?: InputMaybe<FeedInteractionsTipFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsTipsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggAllByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggAllsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsUserAggAllCondition>;
  filter?: InputMaybe<FeedInteractionsUserAggAllFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsUserAggAllsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggBlockByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggBlocksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsUserAggBlockCondition>;
  filter?: InputMaybe<FeedInteractionsUserAggBlockFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsUserAggBlocksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggFocusFollowByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggFocusFollowsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsUserAggFocusFollowCondition>;
  filter?: InputMaybe<FeedInteractionsUserAggFocusFollowFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsUserAggFocusFollowsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggFollowByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggFollowsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsUserAggFollowCondition>;
  filter?: InputMaybe<FeedInteractionsUserAggFollowFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsUserAggFollowsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggNegativeReactionByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggNegativeReactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsUserAggNegativeReactionCondition>;
  filter?: InputMaybe<FeedInteractionsUserAggNegativeReactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsUserAggNegativeReactionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggNeutralReactionByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggNeutralReactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsUserAggNeutralReactionCondition>;
  filter?: InputMaybe<FeedInteractionsUserAggNeutralReactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsUserAggNeutralReactionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggPositiveReactionByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggPositiveReactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsUserAggPositiveReactionCondition>;
  filter?: InputMaybe<FeedInteractionsUserAggPositiveReactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsUserAggPositiveReactionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggQuoteByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggQuotesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsUserAggQuoteCondition>;
  filter?: InputMaybe<FeedInteractionsUserAggQuoteFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsUserAggQuotesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggRepliesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsUserAggReplyCondition>;
  filter?: InputMaybe<FeedInteractionsUserAggReplyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsUserAggRepliesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggReplyByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggReportByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggReportsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsUserAggReportCondition>;
  filter?: InputMaybe<FeedInteractionsUserAggReportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsUserAggReportsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggRepostByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggRepostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsUserAggRepostCondition>;
  filter?: InputMaybe<FeedInteractionsUserAggRepostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsUserAggRepostsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggShowLessByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggShowLessesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsUserAggShowLessCondition>;
  filter?: InputMaybe<FeedInteractionsUserAggShowLessFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsUserAggShowLessesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggTimeDecaysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsUserAggTimeDecayCondition>;
  filter?: InputMaybe<FeedInteractionsUserAggTimeDecayFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsUserAggTimeDecaysOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggTipByPublicKeyAndRelatedUserPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsUserAggTipsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsUserAggTipCondition>;
  filter?: InputMaybe<FeedInteractionsUserAggTipFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsUserAggTipsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsViewByPublicKeyAndPostHashAndRelatedUserPublicKeyArgs = {
  postHash: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  relatedUserPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedInteractionsViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedInteractionsViewCondition>;
  filter?: InputMaybe<FeedInteractionsViewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInteractionsViewsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedPostScoreByPublicKeyAndPostHashAndPosterPublicKeyArgs = {
  postHash: Scalars['String']['input'];
  posterPublicKey: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedPostScoresArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedPostScoreCondition>;
  filter?: InputMaybe<FeedPostScoreFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedPostScoresOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedPostTimeDecaysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedPostTimeDecayCondition>;
  filter?: InputMaybe<FeedPostTimeDecayFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedPostTimeDecaysOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedSettingArgs = {
  rowId: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedSettingByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedSettingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedSettingCondition>;
  filter?: InputMaybe<FeedSettingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedSettingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUserAssociationByAssociationIdArgs = {
  associationId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUserAssociationByTransactorPkidAndTargetUserPkidAndAppPkidAndAssociationTypeAndAssociationValueArgs =
  {
    appPkid: Scalars['String']['input'];
    associationType: Scalars['String']['input'];
    associationValue: Scalars['String']['input'];
    targetUserPkid: Scalars['String']['input'];
    transactorPkid: Scalars['String']['input'];
  };

/** The root query type which gives access points into the data universe. */
export type QueryFeedUserAssociationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedUserAssociationCondition>;
  filter?: InputMaybe<FeedUserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedUserAssociationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUserPostAndRelationshipScoreByPublicKeyAndPostHashAndPosterPublicKeyArgs = {
  postHash: Scalars['String']['input'];
  posterPublicKey: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUserPostAndRelationshipScoresArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedUserPostAndRelationshipScoreCondition>;
  filter?: InputMaybe<FeedUserPostAndRelationshipScoreFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedUserPostAndRelationshipScoresOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUserRelationshipScoreByPublicKeyAndRelatedPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUserRelationshipScoresArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedUserRelationshipScoreCondition>;
  filter?: InputMaybe<FeedUserRelationshipScoreFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedUserRelationshipScoresOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUserRelationshipScores1DByPublicKeyAndRelatedPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUserRelationshipScores1DsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedUserRelationshipScores1DCondition>;
  filter?: InputMaybe<FeedUserRelationshipScores1DFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedUserRelationshipScores1DsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUserRelationshipScores2DByPublicKeyAndRelatedPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  relatedPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUserRelationshipScores2DsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedUserRelationshipScores2DCondition>;
  filter?: InputMaybe<FeedUserRelationshipScores2DFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedUserRelationshipScores2DsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUserScoredPostByPublicKeyAndPostHashArgs = {
  postHash: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUserScoredPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedUserScoredPostCondition>;
  filter?: InputMaybe<FeedUserScoredPostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedUserScoredPostsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUserSettingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedUserSettingCondition>;
  filter?: InputMaybe<FeedUserSettingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedUserSettingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedUserCondition>;
  filter?: InputMaybe<FeedUserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedUsersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedUsersMvsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedUsersMvCondition>;
  filter?: InputMaybe<FeedUsersMvFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedUsersMvsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedWeightedPostScoresArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedWeightedPostScoreCondition>;
  filter?: InputMaybe<FeedWeightedPostScoreFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedWeightedPostScoresOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedWeightedUserRelationshipScoresArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedWeightedUserRelationshipScoreCondition>;
  filter?: InputMaybe<FeedWeightedUserRelationshipScoreFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedWeightedUserRelationshipScoresOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFeedsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedCondition>;
  filter?: InputMaybe<FeedFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFilledDaoCoinOrdersWithPriceArgs = {
  rowId: Scalars['UUID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFilledDaoCoinOrdersWithPriceByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFilledDaoCoinOrdersWithPricesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FilledDaoCoinOrdersWithPriceCondition>;
  filter?: InputMaybe<FilledDaoCoinOrdersWithPriceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilledDaoCoinOrdersWithPricesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFilterableMessageThreadByThreadIdentifierAndParticipantPublicKeyArgs = {
  participantPublicKey: Scalars['String']['input'];
  threadIdentifier: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFilterableMessageThreadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FilterableMessageThreadCondition>;
  filter?: InputMaybe<FilterableMessageThreadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilterableMessageThreadsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFirstAggArgs = {
  arg0: Scalars['String']['input'];
  arg1: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFocusListArgs = {
  listName: Scalars['String']['input'];
  userPublicKeyBase58Check: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFocusListByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFocusListByUserPublicKeyBase58CheckAndListNameArgs = {
  listName: Scalars['String']['input'];
  userPublicKeyBase58Check: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFocusListFdwsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FocusListFdwCondition>;
  filter?: InputMaybe<FocusListFdwFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FocusListFdwsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFocusListMemberArgs = {
  listId: Scalars['UUID']['input'];
  listMemberPublicKeyBase58Check: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFocusListMemberByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFocusListMemberByListMemberPublicKeyBase58CheckAndListIdArgs = {
  listId: Scalars['UUID']['input'];
  listMemberPublicKeyBase58Check: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFocusListMemberFdwsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FocusListMemberFdwCondition>;
  filter?: InputMaybe<FocusListMemberFdwFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FocusListMemberFdwsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFocusListMembersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FocusListMemberCondition>;
  filter?: InputMaybe<FocusListMemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FocusListMembersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFocusListsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FocusListCondition>;
  filter?: InputMaybe<FocusListFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FocusListsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFocusPricesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FocusPriceCondition>;
  filter?: InputMaybe<FocusPriceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FocusPricesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFocusUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FocusUserCondition>;
  filter?: InputMaybe<FocusUserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FocusUsersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFollowFastByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFollowFastsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FollowFastCondition>;
  filter?: InputMaybe<FollowFastFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowFastsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFollowsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FollowCondition>;
  filter?: InputMaybe<FollowFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGlobalParamsEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GlobalParamsEntryCondition>;
  filter?: InputMaybe<GlobalParamsEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GlobalParamsEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGlobalParamsEntryByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryGlobalValArgs = {
  rowId: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryGlobalValByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryGlobalValByRowIdArgs = {
  rowId: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryGlobalValsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GlobalValCondition>;
  filter?: InputMaybe<GlobalValFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GlobalValsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryHexToNumericArgs = {
  hexString?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryInsertInteractionsUpdateArgs = {
  rowId: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryInsertInteractionsUpdateByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryInsertInteractionsUpdatesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<InsertInteractionsUpdateCondition>;
  filter?: InputMaybe<InsertInteractionsUpdateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InsertInteractionsUpdatesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryJailedHistoryEventArgs = {
  jailedAtEpochNumber: Scalars['BigInt']['input'];
  unjailedAtEpochNumber: Scalars['BigInt']['input'];
  validatorPkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryJailedHistoryEventByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryJailedHistoryEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<JailedHistoryEventCondition>;
  filter?: InputMaybe<JailedHistoryEventFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JailedHistoryEventsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLastAggArgs = {
  arg0: Scalars['String']['input'];
  arg1: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLatestAffectedKeyArgs = {
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLatestAffectedKeyByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLatestAffectedKeysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LatestAffectedKeyCondition>;
  filter?: InputMaybe<LatestAffectedKeyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LatestAffectedKeysOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLeaderScheduleEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LeaderScheduleEntryCondition>;
  filter?: InputMaybe<LeaderScheduleEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LeaderScheduleEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLeaderScheduleEntryByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLegacyMessageByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLegacyMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LegacyMessageCondition>;
  filter?: InputMaybe<LegacyMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LegacyMessagesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLikeByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLikesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLockedBalanceByHodlerPkidAndProfilePkidArgs = {
  hodlerPkid: Scalars['String']['input'];
  profilePkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLockedBalanceEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockedBalanceEntryCondition>;
  filter?: InputMaybe<LockedBalanceEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockedBalanceEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLockedBalanceEntryByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLockedBalancesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockedBalanceCondition>;
  filter?: InputMaybe<LockedBalanceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockedBalancesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLockedStakeEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockedStakeEntryCondition>;
  filter?: InputMaybe<LockedStakeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockedStakeEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLockedStakeEntryByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMaxPostScoreBlockHeightsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MaxPostScoreBlockHeightCondition>;
  filter?: InputMaybe<MaxPostScoreBlockHeightFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MaxPostScoreBlockHeightsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageArgs = {
  badgerKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageByBadgerKeyArgs = {
  badgerKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageSpamFilterArgs = {
  badgerKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageSpamFilterByBadgerKeyArgs = {
  badgerKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageSpamFilterByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageSpamFiltersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageSpamFilterCondition>;
  filter?: InputMaybe<MessageSpamFilterFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessageSpamFiltersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageThreadByThreadIdentifierArgs = {
  threadIdentifier: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageThreadParticipantTblArgs = {
  participantPublicKey: Scalars['String']['input'];
  threadIdentifier: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageThreadParticipantTblByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageThreadParticipantTblsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageThreadParticipantTblCondition>;
  filter?: InputMaybe<MessageThreadParticipantTblFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessageThreadParticipantTblsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageThreadParticipantViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageThreadParticipantViewCondition>;
  filter?: InputMaybe<MessageThreadParticipantViewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessageThreadParticipantViewsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageThreadParticipantsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageThreadParticipantCondition>;
  filter?: InputMaybe<MessageThreadParticipantFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessageThreadParticipantsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageThreadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageThreadCondition>;
  filter?: InputMaybe<MessageThreadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessageThreadsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageViewArgs = {
  rowId: Scalars['UUID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageViewByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMessageViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageViewCondition>;
  filter?: InputMaybe<MessageViewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessageViewsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMyStakeSummariesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MyStakeSummaryCondition>;
  filter?: InputMaybe<MyStakeSummaryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MyStakeSummariesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMyStakeSummaryByStakerPkidArgs = {
  stakerPkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryNftBidByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryNftBidsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<NftBidCondition>;
  filter?: InputMaybe<NftBidFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<NftBidsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryNftByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryNftsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<NftCondition>;
  filter?: InputMaybe<NftFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<NftsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPageViewArgs = {
  rowId: Scalars['UUID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPageViewByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPageViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PageViewCondition>;
  filter?: InputMaybe<PageViewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PageViewsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPaymentArgs = {
  associationId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPaymentByAssociationIdArgs = {
  associationId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPaymentByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPaymentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PaymentCondition>;
  filter?: InputMaybe<PaymentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPostAssociationByAssociationIdArgs = {
  associationId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPostAssociationByTransactorPkidAndPostHashAndAppPkidAndAssociationTypeAndAssociationValueArgs = {
  appPkid: Scalars['String']['input'];
  associationType: Scalars['String']['input'];
  associationValue: Scalars['String']['input'];
  postHash: Scalars['String']['input'];
  transactorPkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPostAssociationCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostAssociationCountCondition>;
  filter?: InputMaybe<PostAssociationCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostAssociationCountsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPostAssociationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostAssociationCondition>;
  filter?: InputMaybe<PostAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostAssociationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPostByPostHashArgs = {
  postHash: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPostDiamondTotalByPostHashArgs = {
  postHash: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPostDiamondTotalsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostDiamondTotalCondition>;
  filter?: InputMaybe<PostDiamondTotalFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostDiamondTotalsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPostModerationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostModerationCondition>;
  filter?: InputMaybe<PostModerationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostModerationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPostScoreUpdateArgs = {
  rowId: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPostScoreUpdateByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPostScoreUpdatesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostScoreUpdateCondition>;
  filter?: InputMaybe<PostScoreUpdateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostScoreUpdatesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPostSubscriptionStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostSubscriptionStatCondition>;
  filter?: InputMaybe<PostSubscriptionStatFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostSubscriptionStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPostViewArgs = {
  rowId: Scalars['UUID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPostViewByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPostViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostViewCondition>;
  filter?: InputMaybe<PostViewFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostViewsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPostWealthMvsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostWealthMvCondition>;
  filter?: InputMaybe<PostWealthMvFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostWealthMvsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPostWealthsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostWealthCondition>;
  filter?: InputMaybe<PostWealthFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostWealthOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PostCondition>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryProfileArgs = {
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfileByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfileByUsernameArgs = {
  username: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProfilesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProfileCondition>;
  filter?: InputMaybe<ProfileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProfilesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPublicKeyByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPublicKeysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PublicKeyCondition>;
  filter?: InputMaybe<PublicKeyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PublicKeysOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySnapshotValidatorEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SnapshotValidatorEntryCondition>;
  filter?: InputMaybe<SnapshotValidatorEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SnapshotValidatorEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySnapshotValidatorEntryArgs = {
  badgerKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySnapshotValidatorEntryByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryStakeEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StakeEntryCondition>;
  filter?: InputMaybe<StakeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StakeEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryStakeEntryByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryStakeRewardArgs = {
  blockHash: Scalars['String']['input'];
  utxoOpIndex: Scalars['BigInt']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryStakeRewardByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryStakeRewardsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StakeRewardCondition>;
  filter?: InputMaybe<StakeRewardFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StakeRewardsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionArgs = {
  associationId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionByAssociationIdArgs = {
  associationId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionBySubscriberPublicKeyAndAccessGroupKeyNameAndAccessGroupOwnerPublicKeyArgs = {
  accessGroupKeyName: Scalars['String']['input'];
  accessGroupOwnerPublicKey: Scalars['String']['input'];
  subscriberPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionTierArgs = {
  associationId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionTierByAccessGroupOwnerPublicKeyAndAccessGroupKeyNameArgs = {
  accessGroupKeyName: Scalars['String']['input'];
  accessGroupOwnerPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionTierByAssociationIdArgs = {
  associationId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionTierByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionTiersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SubscriptionTierCondition>;
  filter?: InputMaybe<SubscriptionTierFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SubscriptionTiersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SubscriptionCondition>;
  filter?: InputMaybe<SubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenAmmMetadataArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TokenAmmMetadatumCondition>;
  filter?: InputMaybe<TokenAmmMetadatumFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TokenAmmMetadataOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenAmmMetadatumArgs = {
  tokenPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenAmmMetadatumByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenAmmMetadatumByTokenPublicKeyArgs = {
  tokenPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenBalanceAggArgs = {
  creatorPkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenBalanceAggByCreatorPkidArgs = {
  creatorPkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenBalanceAggByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenBalanceAggsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TokenBalanceAggCondition>;
  filter?: InputMaybe<TokenBalanceAggFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TokenBalanceAggsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenBalanceByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenBalanceSummariesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TokenBalanceSummaryCondition>;
  filter?: InputMaybe<TokenBalanceSummaryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TokenBalanceSummariesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenBalanceSummaryByHodlerPkidAndCreatorPkidArgs = {
  creatorPkid: Scalars['String']['input'];
  hodlerPkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenBalancesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TokenBalanceCondition>;
  filter?: InputMaybe<TokenBalanceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TokenBalancesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenMarketVolumeSummariesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TokenMarketVolumeSummaryCondition>;
  filter?: InputMaybe<TokenMarketVolumeSummaryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TokenMarketVolumeSummariesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenMarketVolumeSummaryArgs = {
  coin1CreatorPkid: Scalars['String']['input'];
  coin2CreatorPkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenMarketVolumeSummaryByCoin1CreatorPkidAndCoin2CreatorPkidArgs = {
  coin1CreatorPkid: Scalars['String']['input'];
  coin2CreatorPkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenMarketVolumeSummaryByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenMetadataArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TokenMetadatumCondition>;
  filter?: InputMaybe<TokenMetadatumFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TokenMetadataOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenMetadatumArgs = {
  publicKeyBase58Check: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokenMetadatumByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTokensByTotalVolumesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TokensByTotalVolumeCondition>;
  filter?: InputMaybe<TokensByTotalVolumeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TokensByTotalVolumesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTotalUnclaimedMessageTipByPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTotalUnclaimedMessageTipsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TotalUnclaimedMessageTipCondition>;
  filter?: InputMaybe<TotalUnclaimedMessageTipFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TotalUnclaimedMessageTipsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTradingRecentTradesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TradingRecentTradeCondition>;
  filter?: InputMaybe<TradingRecentTradeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TradingRecentTradesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTradingTopTradersByTokenByVolumesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TradingTopTradersByTokenByVolumeCondition>;
  filter?: InputMaybe<TradingTopTradersByTokenByVolumeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TradingTopTradersByTokenByVolumesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTransactionByTransactionHashArgs = {
  transactionHash: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTransactionTypeArgs = {
  type: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTransactionTypeByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTransactionTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TransactionTypeCondition>;
  filter?: InputMaybe<TransactionTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUnreadMessagesCountByThreadIdentifierAndPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
  threadIdentifier: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUnreadMessagesCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UnreadMessagesCountCondition>;
  filter?: InputMaybe<UnreadMessagesCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UnreadMessagesCountsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUnreadMessagesTotalCountByPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUnreadMessagesTotalCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UnreadMessagesTotalCountCondition>;
  filter?: InputMaybe<UnreadMessagesTotalCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UnreadMessagesTotalCountsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUnreadThreadsCountByPublicKeyArgs = {
  publicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUnreadThreadsCountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UnreadThreadsCountCondition>;
  filter?: InputMaybe<UnreadThreadsCountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UnreadThreadsCountsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserAssociationByAssociationIdArgs = {
  associationId: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserAssociationByTransactorPkidAndTargetUserPkidAndAppPkidAndAssociationTypeAndAssociationValueArgs = {
  appPkid: Scalars['String']['input'];
  associationType: Scalars['String']['input'];
  associationValue: Scalars['String']['input'];
  targetUserPkid: Scalars['String']['input'];
  transactorPkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserAssociationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserDaoCoinStatByUserPublicKeyAndCoinPublicKeyArgs = {
  coinPublicKey: Scalars['String']['input'];
  userPublicKey: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserDaoCoinStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserDaoCoinStatCondition>;
  filter?: InputMaybe<UserDaoCoinStatFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserDaoCoinStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserModerationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserModerationCondition>;
  filter?: InputMaybe<UserModerationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserModerationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserRelationshipCustomWeightsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserRelationshipCustomWeightCondition>;
  filter?: InputMaybe<UserRelationshipCustomWeightFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserRelationshipCustomWeightsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserScoreUpdateArgs = {
  rowId: Scalars['Int']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserScoreUpdateByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserScoreUpdatesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserScoreUpdateCondition>;
  filter?: InputMaybe<UserScoreUpdateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserScoreUpdatesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUtxoOperationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UtxoOperationCondition>;
  filter?: InputMaybe<UtxoOperationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UtxoOperationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryValidatorEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ValidatorEntryCondition>;
  filter?: InputMaybe<ValidatorEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ValidatorEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryValidatorEntryByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryValidatorEntryByValidatorPkidArgs = {
  validatorPkid: Scalars['String']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryYieldCurvePointByIdArgs = {
  id: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryYieldCurvePointsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<YieldCurvePointCondition>;
  filter?: InputMaybe<YieldCurvePointFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<YieldCurvePointsOrderBy>>;
};

/** All input for the `refreshAccountWealthChainUsers` mutation. */
export type RefreshAccountWealthChainUsersInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `refreshAccountWealthChainUsers` mutation. */
export type RefreshAccountWealthChainUsersPayload = {
  __typename?: 'RefreshAccountWealthChainUsersPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `refreshAccountWealthCombined` mutation. */
export type RefreshAccountWealthCombinedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `refreshAccountWealthCombined` mutation. */
export type RefreshAccountWealthCombinedPayload = {
  __typename?: 'RefreshAccountWealthCombinedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `refreshAccountWealth` mutation. */
export type RefreshAccountWealthInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `refreshAccountWealth` mutation. */
export type RefreshAccountWealthPayload = {
  __typename?: 'RefreshAccountWealthPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `refreshActiveUserFeeds` mutation. */
export type RefreshActiveUserFeedsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `refreshActiveUserFeeds` mutation. */
export type RefreshActiveUserFeedsPayload = {
  __typename?: 'RefreshActiveUserFeedsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `refreshFocusPrice` mutation. */
export type RefreshFocusPriceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `refreshFocusPrice` mutation. */
export type RefreshFocusPricePayload = {
  __typename?: 'RefreshFocusPricePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `refreshFocusUsers` mutation. */
export type RefreshFocusUsersInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  connStr?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `refreshFocusUsers` mutation. */
export type RefreshFocusUsersPayload = {
  __typename?: 'RefreshFocusUsersPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `refreshMessageThread` mutation. */
export type RefreshMessageThreadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `refreshMessageThread` mutation. */
export type RefreshMessageThreadPayload = {
  __typename?: 'RefreshMessageThreadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `refreshMessageThreadUnreadCount` mutation. */
export type RefreshMessageThreadUnreadCountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `refreshMessageThreadUnreadCount` mutation. */
export type RefreshMessageThreadUnreadCountPayload = {
  __typename?: 'RefreshMessageThreadUnreadCountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `refreshPostWealthMv` mutation. */
export type RefreshPostWealthMvInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `refreshPostWealthMv` mutation. */
export type RefreshPostWealthMvPayload = {
  __typename?: 'RefreshPostWealthMvPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `refreshTokenBalanceAgg` mutation. */
export type RefreshTokenBalanceAggInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `refreshTokenBalanceAgg` mutation. */
export type RefreshTokenBalanceAggPayload = {
  __typename?: 'RefreshTokenBalanceAggPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `refreshWalletWealth` mutation. */
export type RefreshWalletWealthInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `refreshWalletWealth` mutation. */
export type RefreshWalletWealthPayload = {
  __typename?: 'RefreshWalletWealthPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `refreshWalletWealthScoped` mutation. */
export type RefreshWalletWealthScopedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `refreshWalletWealthScoped` mutation. */
export type RefreshWalletWealthScopedPayload = {
  __typename?: 'RefreshWalletWealthScopedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `resetAllFeedTables` mutation. */
export type ResetAllFeedTablesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `resetAllFeedTables` mutation. */
export type ResetAllFeedTablesPayload = {
  __typename?: 'ResetAllFeedTablesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `sigmoid` mutation. */
export type SigmoidInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** The output of our `sigmoid` mutation. */
export type SigmoidPayload = {
  __typename?: 'SigmoidPayload';
  bigFloat?: Maybe<Scalars['BigFloat']['output']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A connection to a list of `SnapshotValidatorEntry` values. */
export type SnapshotValidatorEntriesConnection = {
  __typename?: 'SnapshotValidatorEntriesConnection';
  /** A list of edges which contains the `SnapshotValidatorEntry` and cursor to aid in pagination. */
  edges: Array<SnapshotValidatorEntriesEdge>;
  /** A list of `SnapshotValidatorEntry` objects. */
  nodes: Array<Maybe<SnapshotValidatorEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SnapshotValidatorEntry` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `SnapshotValidatorEntry` edge in the connection. */
export type SnapshotValidatorEntriesEdge = {
  __typename?: 'SnapshotValidatorEntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `SnapshotValidatorEntry` at the end of the edge. */
  node?: Maybe<SnapshotValidatorEntry>;
};

/** Methods to use when ordering `SnapshotValidatorEntry`. */
export enum SnapshotValidatorEntriesOrderBy {
  BadgerKeyAsc = 'BADGER_KEY_ASC',
  BadgerKeyDesc = 'BADGER_KEY_DESC',
  DelegatedStakeCommissionBasisPointsAsc = 'DELEGATED_STAKE_COMMISSION_BASIS_POINTS_ASC',
  DelegatedStakeCommissionBasisPointsDesc = 'DELEGATED_STAKE_COMMISSION_BASIS_POINTS_DESC',
  DisableDelegatedStakeAsc = 'DISABLE_DELEGATED_STAKE_ASC',
  DisableDelegatedStakeDesc = 'DISABLE_DELEGATED_STAKE_DESC',
  DomainsAsc = 'DOMAINS_ASC',
  DomainsDesc = 'DOMAINS_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  JailedAtEpochNumberAsc = 'JAILED_AT_EPOCH_NUMBER_ASC',
  JailedAtEpochNumberDesc = 'JAILED_AT_EPOCH_NUMBER_DESC',
  LastActiveAtEpochNumberAsc = 'LAST_ACTIVE_AT_EPOCH_NUMBER_ASC',
  LastActiveAtEpochNumberDesc = 'LAST_ACTIVE_AT_EPOCH_NUMBER_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SnapshotAtEpochNumberAsc = 'SNAPSHOT_AT_EPOCH_NUMBER_ASC',
  SnapshotAtEpochNumberDesc = 'SNAPSHOT_AT_EPOCH_NUMBER_DESC',
  TotalStakeAmountNanosAsc = 'TOTAL_STAKE_AMOUNT_NANOS_ASC',
  TotalStakeAmountNanosDesc = 'TOTAL_STAKE_AMOUNT_NANOS_DESC',
  ValidatorPkidAsc = 'VALIDATOR_PKID_ASC',
  ValidatorPkidDesc = 'VALIDATOR_PKID_DESC',
  VotingAuthorizationAsc = 'VOTING_AUTHORIZATION_ASC',
  VotingAuthorizationDesc = 'VOTING_AUTHORIZATION_DESC',
  VotingPublicKeyAsc = 'VOTING_PUBLIC_KEY_ASC',
  VotingPublicKeyDesc = 'VOTING_PUBLIC_KEY_DESC',
}

export type SnapshotValidatorEntry = Node & {
  __typename?: 'SnapshotValidatorEntry';
  badgerKey: Scalars['String']['output'];
  delegatedStakeCommissionBasisPoints?: Maybe<Scalars['BigInt']['output']>;
  disableDelegatedStake?: Maybe<Scalars['Boolean']['output']>;
  domains?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  jailedAtEpochNumber?: Maybe<Scalars['BigInt']['output']>;
  lastActiveAtEpochNumber?: Maybe<Scalars['BigInt']['output']>;
  snapshotAtEpochNumber: Scalars['BigInt']['output'];
  totalStakeAmountNanos: Scalars['BigFloat']['output'];
  validatorPkid: Scalars['String']['output'];
  votingAuthorization?: Maybe<Scalars['String']['output']>;
  votingPublicKey?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `SnapshotValidatorEntry` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type SnapshotValidatorEntryCondition = {
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `delegatedStakeCommissionBasisPoints` field. */
  delegatedStakeCommissionBasisPoints?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `disableDelegatedStake` field. */
  disableDelegatedStake?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `domains` field. */
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `jailedAtEpochNumber` field. */
  jailedAtEpochNumber?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `lastActiveAtEpochNumber` field. */
  lastActiveAtEpochNumber?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalStakeAmountNanos` field. */
  totalStakeAmountNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `votingAuthorization` field. */
  votingAuthorization?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `votingPublicKey` field. */
  votingPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `SnapshotValidatorEntry` object types. All fields are combined with a logical ‘and.’ */
export type SnapshotValidatorEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SnapshotValidatorEntryFilter>>;
  /** Filter by the object’s `delegatedStakeCommissionBasisPoints` field. */
  delegatedStakeCommissionBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `disableDelegatedStake` field. */
  disableDelegatedStake?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `domains` field. */
  domains?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `jailedAtEpochNumber` field. */
  jailedAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `lastActiveAtEpochNumber` field. */
  lastActiveAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SnapshotValidatorEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SnapshotValidatorEntryFilter>>;
  /** Filter by the object’s `snapshotAtEpochNumber` field. */
  snapshotAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `totalStakeAmountNanos` field. */
  totalStakeAmountNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `votingAuthorization` field. */
  votingAuthorization?: InputMaybe<StringFilter>;
  /** Filter by the object’s `votingPublicKey` field. */
  votingPublicKey?: InputMaybe<StringFilter>;
};

/** A connection to a list of `StakeEntry` values. */
export type StakeEntriesConnection = {
  __typename?: 'StakeEntriesConnection';
  /** A list of edges which contains the `StakeEntry` and cursor to aid in pagination. */
  edges: Array<StakeEntriesEdge>;
  /** A list of `StakeEntry` objects. */
  nodes: Array<Maybe<StakeEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `StakeEntry` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `StakeEntry` edge in the connection. */
export type StakeEntriesEdge = {
  __typename?: 'StakeEntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `StakeEntry` at the end of the edge. */
  node?: Maybe<StakeEntry>;
};

/** Methods to use when ordering `StakeEntry`. */
export enum StakeEntriesOrderBy {
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RewardMethodAsc = 'REWARD_METHOD_ASC',
  RewardMethodDesc = 'REWARD_METHOD_DESC',
  StakerPkidAsc = 'STAKER_PKID_ASC',
  StakerPkidDesc = 'STAKER_PKID_DESC',
  StakeAmountNanosAsc = 'STAKE_AMOUNT_NANOS_ASC',
  StakeAmountNanosDesc = 'STAKE_AMOUNT_NANOS_DESC',
  ValidatorPkidAsc = 'VALIDATOR_PKID_ASC',
  ValidatorPkidDesc = 'VALIDATOR_PKID_DESC',
}

export type StakeEntry = Node & {
  __typename?: 'StakeEntry';
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  rewardMethod: Scalars['String']['output'];
  stakeAmountNanos: Scalars['BigFloat']['output'];
  /** Reads a single `Account` that is related to this `StakeEntry`. */
  staker?: Maybe<Account>;
  stakerPkid: Scalars['String']['output'];
  /** Reads a single `Account` that is related to this `StakeEntry`. */
  validatorAccount?: Maybe<Account>;
  /** Reads a single `ValidatorEntry` that is related to this `StakeEntry`. */
  validatorEntry?: Maybe<ValidatorEntry>;
  validatorPkid: Scalars['String']['output'];
};

/**
 * A condition to be used against `StakeEntry` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type StakeEntryCondition = {
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `rewardMethod` field. */
  rewardMethod?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `stakeAmountNanos` field. */
  stakeAmountNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `StakeEntry` object types. All fields are combined with a logical ‘and.’ */
export type StakeEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StakeEntryFilter>>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StakeEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StakeEntryFilter>>;
  /** Filter by the object’s `rewardMethod` field. */
  rewardMethod?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stakeAmountNanos` field. */
  stakeAmountNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `staker` relation. */
  staker?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `validatorAccount` relation. */
  validatorAccount?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `validatorEntry` relation. */
  validatorEntry?: InputMaybe<ValidatorEntryFilter>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
};

export type StakeReward = Node & {
  __typename?: 'StakeReward';
  /** Reads a single `Block` that is related to this `StakeReward`. */
  block?: Maybe<Block>;
  blockHash: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  isValidatorCommission: Scalars['Boolean']['output'];
  rewardMethod: Scalars['Int']['output'];
  rewardNanos: Scalars['BigInt']['output'];
  /** Reads a single `Account` that is related to this `StakeReward`. */
  staker?: Maybe<Account>;
  stakerPkid: Scalars['String']['output'];
  utxoOpIndex: Scalars['BigInt']['output'];
  /** Reads a single `Account` that is related to this `StakeReward`. */
  validator?: Maybe<Account>;
  validatorPkid: Scalars['String']['output'];
};

/**
 * A condition to be used against `StakeReward` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type StakeRewardCondition = {
  /** Checks for equality with the object’s `blockHash` field. */
  blockHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isValidatorCommission` field. */
  isValidatorCommission?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `rewardMethod` field. */
  rewardMethod?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `rewardNanos` field. */
  rewardNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `utxoOpIndex` field. */
  utxoOpIndex?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `StakeReward` object types. All fields are combined with a logical ‘and.’ */
export type StakeRewardFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StakeRewardFilter>>;
  /** Filter by the object’s `block` relation. */
  block?: InputMaybe<BlockFilter>;
  /** Filter by the object’s `blockHash` field. */
  blockHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isValidatorCommission` field. */
  isValidatorCommission?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StakeRewardFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StakeRewardFilter>>;
  /** Filter by the object’s `rewardMethod` field. */
  rewardMethod?: InputMaybe<IntFilter>;
  /** Filter by the object’s `rewardNanos` field. */
  rewardNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `staker` relation. */
  staker?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `stakerPkid` field. */
  stakerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `utxoOpIndex` field. */
  utxoOpIndex?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `validator` relation. */
  validator?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
};

/** A connection to a list of `StakeReward` values. */
export type StakeRewardsConnection = {
  __typename?: 'StakeRewardsConnection';
  /** A list of edges which contains the `StakeReward` and cursor to aid in pagination. */
  edges: Array<StakeRewardsEdge>;
  /** A list of `StakeReward` objects. */
  nodes: Array<Maybe<StakeReward>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `StakeReward` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `StakeReward` edge in the connection. */
export type StakeRewardsEdge = {
  __typename?: 'StakeRewardsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `StakeReward` at the end of the edge. */
  node?: Maybe<StakeReward>;
};

/** Methods to use when ordering `StakeReward`. */
export enum StakeRewardsOrderBy {
  BlockHashAsc = 'BLOCK_HASH_ASC',
  BlockHashDesc = 'BLOCK_HASH_DESC',
  IsValidatorCommissionAsc = 'IS_VALIDATOR_COMMISSION_ASC',
  IsValidatorCommissionDesc = 'IS_VALIDATOR_COMMISSION_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RewardMethodAsc = 'REWARD_METHOD_ASC',
  RewardMethodDesc = 'REWARD_METHOD_DESC',
  RewardNanosAsc = 'REWARD_NANOS_ASC',
  RewardNanosDesc = 'REWARD_NANOS_DESC',
  StakerPkidAsc = 'STAKER_PKID_ASC',
  StakerPkidDesc = 'STAKER_PKID_DESC',
  UtxoOpIndexAsc = 'UTXO_OP_INDEX_ASC',
  UtxoOpIndexDesc = 'UTXO_OP_INDEX_DESC',
  ValidatorPkidAsc = 'VALIDATOR_PKID_ASC',
  ValidatorPkidDesc = 'VALIDATOR_PKID_DESC',
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']['input']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']['input']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']['input']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<Scalars['String']['input']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<Scalars['String']['input']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Subscription = Node & {
  __typename?: 'Subscription';
  /** Reads a single `AccessGroup` that is related to this `Subscription`. */
  accessGroup?: Maybe<AccessGroup>;
  accessGroupKeyName?: Maybe<Scalars['String']['output']>;
  accessGroupOwnerPublicKey?: Maybe<Scalars['String']['output']>;
  appPkid?: Maybe<Scalars['String']['output']>;
  associationId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `Payment`. */
  payments: PaymentsConnection;
  status?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Account` that is related to this `Subscription`. */
  subscriber?: Maybe<Account>;
  subscriberPublicKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `SubscriptionTier` that is related to this `Subscription`. */
  subscriptionTier?: Maybe<SubscriptionTier>;
};

export type SubscriptionPaymentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PaymentCondition>;
  filter?: InputMaybe<PaymentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};

/**
 * A condition to be used against `Subscription` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SubscriptionCondition = {
  /** Checks for equality with the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `appPkid` field. */
  appPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationId` field. */
  associationId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `subscriberPublicKey` field. */
  subscriberPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Subscription` object types. All fields are combined with a logical ‘and.’ */
export type SubscriptionFilter = {
  /** Filter by the object’s `accessGroup` relation. */
  accessGroup?: InputMaybe<AccessGroupFilter>;
  /** A related `accessGroup` exists. */
  accessGroupExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SubscriptionFilter>>;
  /** Filter by the object’s `appPkid` field. */
  appPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationId` field. */
  associationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SubscriptionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SubscriptionFilter>>;
  /** Filter by the object’s `payments` relation. */
  payments?: InputMaybe<SubscriptionToManyPaymentFilter>;
  /** Some related `payments` exist. */
  paymentsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subscriber` relation. */
  subscriber?: InputMaybe<AccountFilter>;
  /** A related `subscriber` exists. */
  subscriberExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `subscriberPublicKey` field. */
  subscriberPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subscriptionTier` relation. */
  subscriptionTier?: InputMaybe<SubscriptionTierFilter>;
  /** A related `subscriptionTier` exists. */
  subscriptionTierExists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubscriptionTier = Node & {
  __typename?: 'SubscriptionTier';
  /** Reads a single `AccessGroup` that is related to this `SubscriptionTier`. */
  accessGroup?: Maybe<AccessGroup>;
  accessGroupKeyName?: Maybe<Scalars['String']['output']>;
  accessGroupOwnerPublicKey?: Maybe<Scalars['String']['output']>;
  appPkid?: Maybe<Scalars['String']['output']>;
  associationId: Scalars['String']['output'];
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  /** Reads a single `Account` that is related to this `SubscriptionTier`. */
  owner?: Maybe<Account>;
  paymentCadenceDays?: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Subscription`. */
  subscriptions: SubscriptionsConnection;
};

export type SubscriptionTierSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SubscriptionCondition>;
  filter?: InputMaybe<SubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>;
};

/**
 * A condition to be used against `SubscriptionTier` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SubscriptionTierCondition = {
  /** Checks for equality with the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `appPkid` field. */
  appPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationId` field. */
  associationId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `paymentCadenceDays` field. */
  paymentCadenceDays?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `SubscriptionTier` object types. All fields are combined with a logical ‘and.’ */
export type SubscriptionTierFilter = {
  /** Filter by the object’s `accessGroup` relation. */
  accessGroup?: InputMaybe<AccessGroupFilter>;
  /** A related `accessGroup` exists. */
  accessGroupExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SubscriptionTierFilter>>;
  /** Filter by the object’s `appPkid` field. */
  appPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationId` field. */
  associationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SubscriptionTierFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SubscriptionTierFilter>>;
  /** Filter by the object’s `owner` relation. */
  owner?: InputMaybe<AccountFilter>;
  /** A related `owner` exists. */
  ownerExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `paymentCadenceDays` field. */
  paymentCadenceDays?: InputMaybe<IntFilter>;
  /** Filter by the object’s `subscriptions` relation. */
  subscriptions?: InputMaybe<SubscriptionTierToManySubscriptionFilter>;
  /** Some related `subscriptions` exist. */
  subscriptionsExist?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A filter to be used against many `Subscription` object types. All fields are combined with a logical ‘and.’ */
export type SubscriptionTierToManySubscriptionFilter = {
  /** Every related `Subscription` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SubscriptionFilter>;
  /** No related `Subscription` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SubscriptionFilter>;
  /** Some related `Subscription` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SubscriptionFilter>;
};

/** A connection to a list of `SubscriptionTier` values. */
export type SubscriptionTiersConnection = {
  __typename?: 'SubscriptionTiersConnection';
  /** A list of edges which contains the `SubscriptionTier` and cursor to aid in pagination. */
  edges: Array<SubscriptionTiersEdge>;
  /** A list of `SubscriptionTier` objects. */
  nodes: Array<Maybe<SubscriptionTier>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SubscriptionTier` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `SubscriptionTier` edge in the connection. */
export type SubscriptionTiersEdge = {
  __typename?: 'SubscriptionTiersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `SubscriptionTier` at the end of the edge. */
  node?: Maybe<SubscriptionTier>;
};

/** Methods to use when ordering `SubscriptionTier`. */
export enum SubscriptionTiersOrderBy {
  AccessGroupKeyNameAsc = 'ACCESS_GROUP_KEY_NAME_ASC',
  AccessGroupKeyNameDesc = 'ACCESS_GROUP_KEY_NAME_DESC',
  AccessGroupOwnerPublicKeyAsc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC',
  AccessGroupOwnerPublicKeyDesc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC',
  AppPkidAsc = 'APP_PKID_ASC',
  AppPkidDesc = 'APP_PKID_DESC',
  AssociationIdAsc = 'ASSOCIATION_ID_ASC',
  AssociationIdDesc = 'ASSOCIATION_ID_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  Natural = 'NATURAL',
  PaymentCadenceDaysAsc = 'PAYMENT_CADENCE_DAYS_ASC',
  PaymentCadenceDaysDesc = 'PAYMENT_CADENCE_DAYS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’ */
export type SubscriptionToManyPaymentFilter = {
  /** Every related `Payment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PaymentFilter>;
  /** No related `Payment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PaymentFilter>;
  /** Some related `Payment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PaymentFilter>;
};

/** A connection to a list of `Subscription` values. */
export type SubscriptionsConnection = {
  __typename?: 'SubscriptionsConnection';
  /** A list of edges which contains the `Subscription` and cursor to aid in pagination. */
  edges: Array<SubscriptionsEdge>;
  /** A list of `Subscription` objects. */
  nodes: Array<Maybe<Subscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Subscription` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Subscription` edge in the connection. */
export type SubscriptionsEdge = {
  __typename?: 'SubscriptionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Subscription` at the end of the edge. */
  node?: Maybe<Subscription>;
};

/** Methods to use when ordering `Subscription`. */
export enum SubscriptionsOrderBy {
  AccessGroupKeyNameAsc = 'ACCESS_GROUP_KEY_NAME_ASC',
  AccessGroupKeyNameDesc = 'ACCESS_GROUP_KEY_NAME_DESC',
  AccessGroupOwnerPublicKeyAsc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC',
  AccessGroupOwnerPublicKeyDesc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC',
  AppPkidAsc = 'APP_PKID_ASC',
  AppPkidDesc = 'APP_PKID_DESC',
  AssociationIdAsc = 'ASSOCIATION_ID_ASC',
  AssociationIdDesc = 'ASSOCIATION_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SubscriberPublicKeyAsc = 'SUBSCRIBER_PUBLIC_KEY_ASC',
  SubscriberPublicKeyDesc = 'SUBSCRIBER_PUBLIC_KEY_DESC',
}

/** A connection to a list of `TokenAmmMetadatum` values. */
export type TokenAmmMetadataConnection = {
  __typename?: 'TokenAmmMetadataConnection';
  /** A list of edges which contains the `TokenAmmMetadatum` and cursor to aid in pagination. */
  edges: Array<TokenAmmMetadataEdge>;
  /** A list of `TokenAmmMetadatum` objects. */
  nodes: Array<Maybe<TokenAmmMetadatum>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TokenAmmMetadatum` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TokenAmmMetadatum` edge in the connection. */
export type TokenAmmMetadataEdge = {
  __typename?: 'TokenAmmMetadataEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TokenAmmMetadatum` at the end of the edge. */
  node?: Maybe<TokenAmmMetadatum>;
};

/** Methods to use when ordering `TokenAmmMetadatum`. */
export enum TokenAmmMetadataOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TokenAmmCreatedAtAsc = 'TOKEN_AMM_CREATED_AT_ASC',
  TokenAmmCreatedAtDesc = 'TOKEN_AMM_CREATED_AT_DESC',
  TokenAmmLaunchAtAsc = 'TOKEN_AMM_LAUNCH_AT_ASC',
  TokenAmmLaunchAtDesc = 'TOKEN_AMM_LAUNCH_AT_DESC',
  TokenPublicKeyAsc = 'TOKEN_PUBLIC_KEY_ASC',
  TokenPublicKeyDesc = 'TOKEN_PUBLIC_KEY_DESC',
}

export type TokenAmmMetadatum = Node & {
  __typename?: 'TokenAmmMetadatum';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  tokenAmmCreatedAt?: Maybe<Scalars['Datetime']['output']>;
  tokenAmmLaunchAt?: Maybe<Scalars['Datetime']['output']>;
  tokenPublicKey: Scalars['String']['output'];
};

/**
 * A condition to be used against `TokenAmmMetadatum` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TokenAmmMetadatumCondition = {
  /** Checks for equality with the object’s `tokenAmmCreatedAt` field. */
  tokenAmmCreatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `tokenAmmLaunchAt` field. */
  tokenAmmLaunchAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `tokenPublicKey` field. */
  tokenPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `TokenAmmMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type TokenAmmMetadatumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TokenAmmMetadatumFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<TokenAmmMetadatumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TokenAmmMetadatumFilter>>;
  /** Filter by the object’s `tokenAmmCreatedAt` field. */
  tokenAmmCreatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `tokenAmmLaunchAt` field. */
  tokenAmmLaunchAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `tokenPublicKey` field. */
  tokenPublicKey?: InputMaybe<StringFilter>;
};

export type TokenBalance = Node & {
  __typename?: 'TokenBalance';
  balanceNanos: Scalars['BigFloat']['output'];
  /** Reads a single `Account` that is related to this `TokenBalance`. */
  creator?: Maybe<Account>;
  creatorPkid: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `DesoTokenLimitOrder`. */
  desoTokenBuyingLimitOrders: DesoTokenLimitOrdersConnection;
  /** Reads and enables pagination through a set of `DesoTokenLimitOrder`. */
  desoTokenSellingLimitOrders: DesoTokenLimitOrdersConnection;
  hasPurchased: Scalars['Boolean']['output'];
  hodlerPkid: Scalars['String']['output'];
  /** Reads a single `Account` that is related to this `TokenBalance`. */
  holder?: Maybe<Account>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  isDaoCoin: Scalars['Boolean']['output'];
};

export type TokenBalanceDesoTokenBuyingLimitOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DesoTokenLimitOrderCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy>>;
};

export type TokenBalanceDesoTokenSellingLimitOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DesoTokenLimitOrderCondition>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy>>;
};

export type TokenBalanceAgg = Node & {
  __typename?: 'TokenBalanceAgg';
  /** Reads a single `Account` that is related to this `TokenBalanceAgg`. */
  creatorAccount?: Maybe<Account>;
  creatorPkid: Scalars['String']['output'];
  hodlerCount?: Maybe<Scalars['BigInt']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  totalBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  totalLockedBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  totalUnlockedBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `TokenBalanceAgg` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TokenBalanceAggCondition = {
  /** Checks for equality with the object’s `creatorPkid` field. */
  creatorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `hodlerCount` field. */
  hodlerCount?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalBalanceNanos` field. */
  totalBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalLockedBalanceNanos` field. */
  totalLockedBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalUnlockedBalanceNanos` field. */
  totalUnlockedBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `TokenBalanceAgg` object types. All fields are combined with a logical ‘and.’ */
export type TokenBalanceAggFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TokenBalanceAggFilter>>;
  /** Filter by the object’s `creatorAccount` relation. */
  creatorAccount?: InputMaybe<AccountFilter>;
  /** A related `creatorAccount` exists. */
  creatorAccountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `creatorPkid` field. */
  creatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hodlerCount` field. */
  hodlerCount?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TokenBalanceAggFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TokenBalanceAggFilter>>;
  /** Filter by the object’s `totalBalanceNanos` field. */
  totalBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalLockedBalanceNanos` field. */
  totalLockedBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalUnlockedBalanceNanos` field. */
  totalUnlockedBalanceNanos?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `TokenBalanceAgg` values. */
export type TokenBalanceAggsConnection = {
  __typename?: 'TokenBalanceAggsConnection';
  /** A list of edges which contains the `TokenBalanceAgg` and cursor to aid in pagination. */
  edges: Array<TokenBalanceAggsEdge>;
  /** A list of `TokenBalanceAgg` objects. */
  nodes: Array<Maybe<TokenBalanceAgg>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TokenBalanceAgg` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TokenBalanceAgg` edge in the connection. */
export type TokenBalanceAggsEdge = {
  __typename?: 'TokenBalanceAggsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TokenBalanceAgg` at the end of the edge. */
  node?: Maybe<TokenBalanceAgg>;
};

/** Methods to use when ordering `TokenBalanceAgg`. */
export enum TokenBalanceAggsOrderBy {
  CreatorPkidAsc = 'CREATOR_PKID_ASC',
  CreatorPkidDesc = 'CREATOR_PKID_DESC',
  HodlerCountAsc = 'HODLER_COUNT_ASC',
  HodlerCountDesc = 'HODLER_COUNT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TotalBalanceNanosAsc = 'TOTAL_BALANCE_NANOS_ASC',
  TotalBalanceNanosDesc = 'TOTAL_BALANCE_NANOS_DESC',
  TotalLockedBalanceNanosAsc = 'TOTAL_LOCKED_BALANCE_NANOS_ASC',
  TotalLockedBalanceNanosDesc = 'TOTAL_LOCKED_BALANCE_NANOS_DESC',
  TotalUnlockedBalanceNanosAsc = 'TOTAL_UNLOCKED_BALANCE_NANOS_ASC',
  TotalUnlockedBalanceNanosDesc = 'TOTAL_UNLOCKED_BALANCE_NANOS_DESC',
}

/**
 * A condition to be used against `TokenBalance` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TokenBalanceCondition = {
  /** Checks for equality with the object’s `balanceNanos` field. */
  balanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `creatorPkid` field. */
  creatorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `hasPurchased` field. */
  hasPurchased?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isDaoCoin` field. */
  isDaoCoin?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A filter to be used against `TokenBalance` object types. All fields are combined with a logical ‘and.’ */
export type TokenBalanceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TokenBalanceFilter>>;
  /** Filter by the object’s `balanceNanos` field. */
  balanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `creator` relation. */
  creator?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `creatorPkid` field. */
  creatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `desoTokenBuyingLimitOrders` relation. */
  desoTokenBuyingLimitOrders?: InputMaybe<TokenBalanceToManyDesoTokenLimitOrderFilter>;
  /** Some related `desoTokenBuyingLimitOrders` exist. */
  desoTokenBuyingLimitOrdersExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `desoTokenSellingLimitOrders` relation. */
  desoTokenSellingLimitOrders?: InputMaybe<TokenBalanceToManyDesoTokenLimitOrderFilter>;
  /** Some related `desoTokenSellingLimitOrders` exist. */
  desoTokenSellingLimitOrdersExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `hasPurchased` field. */
  hasPurchased?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `holder` relation. */
  holder?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `isDaoCoin` field. */
  isDaoCoin?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TokenBalanceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TokenBalanceFilter>>;
};

/** A connection to a list of `TokenBalanceSummary` values. */
export type TokenBalanceSummariesConnection = {
  __typename?: 'TokenBalanceSummariesConnection';
  /** A list of edges which contains the `TokenBalanceSummary` and cursor to aid in pagination. */
  edges: Array<TokenBalanceSummariesEdge>;
  /** A list of `TokenBalanceSummary` objects. */
  nodes: Array<Maybe<TokenBalanceSummary>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TokenBalanceSummary` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TokenBalanceSummary` edge in the connection. */
export type TokenBalanceSummariesEdge = {
  __typename?: 'TokenBalanceSummariesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TokenBalanceSummary` at the end of the edge. */
  node?: Maybe<TokenBalanceSummary>;
};

/** Methods to use when ordering `TokenBalanceSummary`. */
export enum TokenBalanceSummariesOrderBy {
  CreatorPkidAsc = 'CREATOR_PKID_ASC',
  CreatorPkidDesc = 'CREATOR_PKID_DESC',
  HasPurchasedAsc = 'HAS_PURCHASED_ASC',
  HasPurchasedDesc = 'HAS_PURCHASED_DESC',
  HodlerPkidAsc = 'HODLER_PKID_ASC',
  HodlerPkidDesc = 'HODLER_PKID_DESC',
  LockedBalanceNanosAsc = 'LOCKED_BALANCE_NANOS_ASC',
  LockedBalanceNanosDesc = 'LOCKED_BALANCE_NANOS_DESC',
  Natural = 'NATURAL',
  TotalBalanceAsc = 'TOTAL_BALANCE_ASC',
  TotalBalanceDesc = 'TOTAL_BALANCE_DESC',
  UnlockedBalanceNanosAsc = 'UNLOCKED_BALANCE_NANOS_ASC',
  UnlockedBalanceNanosDesc = 'UNLOCKED_BALANCE_NANOS_DESC',
}

export type TokenBalanceSummary = {
  __typename?: 'TokenBalanceSummary';
  /** Reads a single `Account` that is related to this `TokenBalanceSummary`. */
  creator?: Maybe<Account>;
  creatorPkid?: Maybe<Scalars['String']['output']>;
  hasPurchased?: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `Account` that is related to this `TokenBalanceSummary`. */
  hodler?: Maybe<Account>;
  hodlerPkid?: Maybe<Scalars['String']['output']>;
  lockedBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  totalBalance?: Maybe<Scalars['BigFloat']['output']>;
  unlockedBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `TokenBalanceSummary` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type TokenBalanceSummaryCondition = {
  /** Checks for equality with the object’s `creatorPkid` field. */
  creatorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `hasPurchased` field. */
  hasPurchased?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `lockedBalanceNanos` field. */
  lockedBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalBalance` field. */
  totalBalance?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `unlockedBalanceNanos` field. */
  unlockedBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `TokenBalanceSummary` object types. All fields are combined with a logical ‘and.’ */
export type TokenBalanceSummaryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TokenBalanceSummaryFilter>>;
  /** Filter by the object’s `creator` relation. */
  creator?: InputMaybe<AccountFilter>;
  /** A related `creator` exists. */
  creatorExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `creatorPkid` field. */
  creatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hasPurchased` field. */
  hasPurchased?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hodler` relation. */
  hodler?: InputMaybe<AccountFilter>;
  /** A related `hodler` exists. */
  hodlerExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `hodlerPkid` field. */
  hodlerPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lockedBalanceNanos` field. */
  lockedBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TokenBalanceSummaryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TokenBalanceSummaryFilter>>;
  /** Filter by the object’s `totalBalance` field. */
  totalBalance?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `unlockedBalanceNanos` field. */
  unlockedBalanceNanos?: InputMaybe<BigFloatFilter>;
};

/** A filter to be used against many `DesoTokenLimitOrder` object types. All fields are combined with a logical ‘and.’ */
export type TokenBalanceToManyDesoTokenLimitOrderFilter = {
  /** Every related `DesoTokenLimitOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DesoTokenLimitOrderFilter>;
  /** No related `DesoTokenLimitOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DesoTokenLimitOrderFilter>;
  /** Some related `DesoTokenLimitOrder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DesoTokenLimitOrderFilter>;
};

/** A connection to a list of `TokenBalance` values. */
export type TokenBalancesConnection = {
  __typename?: 'TokenBalancesConnection';
  /** A list of edges which contains the `TokenBalance` and cursor to aid in pagination. */
  edges: Array<TokenBalancesEdge>;
  /** A list of `TokenBalance` objects. */
  nodes: Array<Maybe<TokenBalance>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TokenBalance` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TokenBalance` edge in the connection. */
export type TokenBalancesEdge = {
  __typename?: 'TokenBalancesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TokenBalance` at the end of the edge. */
  node?: Maybe<TokenBalance>;
};

/** Methods to use when ordering `TokenBalance`. */
export enum TokenBalancesOrderBy {
  BalanceNanosAsc = 'BALANCE_NANOS_ASC',
  BalanceNanosDesc = 'BALANCE_NANOS_DESC',
  CreatorPkidAsc = 'CREATOR_PKID_ASC',
  CreatorPkidDesc = 'CREATOR_PKID_DESC',
  HasPurchasedAsc = 'HAS_PURCHASED_ASC',
  HasPurchasedDesc = 'HAS_PURCHASED_DESC',
  HodlerPkidAsc = 'HODLER_PKID_ASC',
  HodlerPkidDesc = 'HODLER_PKID_DESC',
  IsDaoCoinAsc = 'IS_DAO_COIN_ASC',
  IsDaoCoinDesc = 'IS_DAO_COIN_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** A connection to a list of `TokenMarketVolumeSummary` values. */
export type TokenMarketVolumeSummariesConnection = {
  __typename?: 'TokenMarketVolumeSummariesConnection';
  /** A list of edges which contains the `TokenMarketVolumeSummary` and cursor to aid in pagination. */
  edges: Array<TokenMarketVolumeSummariesEdge>;
  /** A list of `TokenMarketVolumeSummary` objects. */
  nodes: Array<Maybe<TokenMarketVolumeSummary>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TokenMarketVolumeSummary` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TokenMarketVolumeSummary` edge in the connection. */
export type TokenMarketVolumeSummariesEdge = {
  __typename?: 'TokenMarketVolumeSummariesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TokenMarketVolumeSummary` at the end of the edge. */
  node?: Maybe<TokenMarketVolumeSummary>;
};

/** Methods to use when ordering `TokenMarketVolumeSummary`. */
export enum TokenMarketVolumeSummariesOrderBy {
  AskAsc = 'ASK_ASC',
  AskDesc = 'ASK_DESC',
  AskUsdCentsAsc = 'ASK_USD_CENTS_ASC',
  AskUsdCentsDesc = 'ASK_USD_CENTS_DESC',
  BidAsc = 'BID_ASC',
  BidDesc = 'BID_DESC',
  BidUsdCentsAsc = 'BID_USD_CENTS_ASC',
  BidUsdCentsDesc = 'BID_USD_CENTS_DESC',
  Coin_1CreatorPkidAsc = 'COIN_1_CREATOR_PKID_ASC',
  Coin_1CreatorPkidDesc = 'COIN_1_CREATOR_PKID_DESC',
  Coin_2CreatorPkidAsc = 'COIN_2_CREATOR_PKID_ASC',
  Coin_2CreatorPkidDesc = 'COIN_2_CREATOR_PKID_DESC',
  HodlerCountAsc = 'HODLER_COUNT_ASC',
  HodlerCountDesc = 'HODLER_COUNT_DESC',
  LockedMarketCapTokensAsc = 'LOCKED_MARKET_CAP_TOKENS_ASC',
  LockedMarketCapTokensDesc = 'LOCKED_MARKET_CAP_TOKENS_DESC',
  LockedMarketCapUsdCentsAsc = 'LOCKED_MARKET_CAP_USD_CENTS_ASC',
  LockedMarketCapUsdCentsDesc = 'LOCKED_MARKET_CAP_USD_CENTS_DESC',
  MarketCapTokensAsc = 'MARKET_CAP_TOKENS_ASC',
  MarketCapTokensDesc = 'MARKET_CAP_TOKENS_DESC',
  MarketCapUsdCentsAsc = 'MARKET_CAP_USD_CENTS_ASC',
  MarketCapUsdCentsDesc = 'MARKET_CAP_USD_CENTS_DESC',
  MidPriceAsc = 'MID_PRICE_ASC',
  MidPriceDesc = 'MID_PRICE_DESC',
  MidPriceUsdCentsAsc = 'MID_PRICE_USD_CENTS_ASC',
  MidPriceUsdCentsDesc = 'MID_PRICE_USD_CENTS_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TotalSupplyLockedNanosAsc = 'TOTAL_SUPPLY_LOCKED_NANOS_ASC',
  TotalSupplyLockedNanosDesc = 'TOTAL_SUPPLY_LOCKED_NANOS_DESC',
  TotalSupplyNanosAsc = 'TOTAL_SUPPLY_NANOS_ASC',
  TotalSupplyNanosDesc = 'TOTAL_SUPPLY_NANOS_DESC',
  TotalSupplyUnlockedNanosAsc = 'TOTAL_SUPPLY_UNLOCKED_NANOS_ASC',
  TotalSupplyUnlockedNanosDesc = 'TOTAL_SUPPLY_UNLOCKED_NANOS_DESC',
  UnlockedMarketCapTokensAsc = 'UNLOCKED_MARKET_CAP_TOKENS_ASC',
  UnlockedMarketCapTokensDesc = 'UNLOCKED_MARKET_CAP_TOKENS_DESC',
  UnlockedMarketCapUsdCentsAsc = 'UNLOCKED_MARKET_CAP_USD_CENTS_ASC',
  UnlockedMarketCapUsdCentsDesc = 'UNLOCKED_MARKET_CAP_USD_CENTS_DESC',
}

export type TokenMarketVolumeSummary = Node & {
  __typename?: 'TokenMarketVolumeSummary';
  ask?: Maybe<Scalars['BigFloat']['output']>;
  askUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  bid?: Maybe<Scalars['BigFloat']['output']>;
  bidUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  /** Reads a single `Account` that is related to this `TokenMarketVolumeSummary`. */
  coin1Account?: Maybe<Account>;
  coin1CreatorPkid: Scalars['String']['output'];
  /** Reads a single `Account` that is related to this `TokenMarketVolumeSummary`. */
  coin2Account?: Maybe<Account>;
  coin2CreatorPkid: Scalars['String']['output'];
  hodlerCount?: Maybe<Scalars['BigInt']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  lockedMarketCapTokens?: Maybe<Scalars['BigFloat']['output']>;
  lockedMarketCapUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  marketCapTokens?: Maybe<Scalars['BigFloat']['output']>;
  marketCapUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  midPrice?: Maybe<Scalars['BigFloat']['output']>;
  midPriceUsdCents?: Maybe<Scalars['BigFloat']['output']>;
  totalSupplyLockedNanos?: Maybe<Scalars['BigFloat']['output']>;
  totalSupplyNanos?: Maybe<Scalars['BigFloat']['output']>;
  totalSupplyUnlockedNanos?: Maybe<Scalars['BigFloat']['output']>;
  unlockedMarketCapTokens?: Maybe<Scalars['BigFloat']['output']>;
  unlockedMarketCapUsdCents?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `TokenMarketVolumeSummary` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type TokenMarketVolumeSummaryCondition = {
  /** Checks for equality with the object’s `ask` field. */
  ask?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `askUsdCents` field. */
  askUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `bid` field. */
  bid?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `bidUsdCents` field. */
  bidUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `coin1CreatorPkid` field. */
  coin1CreatorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `coin2CreatorPkid` field. */
  coin2CreatorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `hodlerCount` field. */
  hodlerCount?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `lockedMarketCapTokens` field. */
  lockedMarketCapTokens?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `lockedMarketCapUsdCents` field. */
  lockedMarketCapUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `marketCapTokens` field. */
  marketCapTokens?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `marketCapUsdCents` field. */
  marketCapUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `midPrice` field. */
  midPrice?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `midPriceUsdCents` field. */
  midPriceUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalSupplyLockedNanos` field. */
  totalSupplyLockedNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalSupplyNanos` field. */
  totalSupplyNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `totalSupplyUnlockedNanos` field. */
  totalSupplyUnlockedNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `unlockedMarketCapTokens` field. */
  unlockedMarketCapTokens?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `unlockedMarketCapUsdCents` field. */
  unlockedMarketCapUsdCents?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `TokenMarketVolumeSummary` object types. All fields are combined with a logical ‘and.’ */
export type TokenMarketVolumeSummaryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TokenMarketVolumeSummaryFilter>>;
  /** Filter by the object’s `ask` field. */
  ask?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `askUsdCents` field. */
  askUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `bid` field. */
  bid?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `bidUsdCents` field. */
  bidUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coin1Account` relation. */
  coin1Account?: InputMaybe<AccountFilter>;
  /** A related `coin1Account` exists. */
  coin1AccountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `coin1CreatorPkid` field. */
  coin1CreatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coin2Account` relation. */
  coin2Account?: InputMaybe<AccountFilter>;
  /** A related `coin2Account` exists. */
  coin2AccountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `coin2CreatorPkid` field. */
  coin2CreatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hodlerCount` field. */
  hodlerCount?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `lockedMarketCapTokens` field. */
  lockedMarketCapTokens?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `lockedMarketCapUsdCents` field. */
  lockedMarketCapUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `marketCapTokens` field. */
  marketCapTokens?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `marketCapUsdCents` field. */
  marketCapUsdCents?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `midPrice` field. */
  midPrice?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `midPriceUsdCents` field. */
  midPriceUsdCents?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TokenMarketVolumeSummaryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TokenMarketVolumeSummaryFilter>>;
  /** Filter by the object’s `totalSupplyLockedNanos` field. */
  totalSupplyLockedNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalSupplyNanos` field. */
  totalSupplyNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `totalSupplyUnlockedNanos` field. */
  totalSupplyUnlockedNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `unlockedMarketCapTokens` field. */
  unlockedMarketCapTokens?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `unlockedMarketCapUsdCents` field. */
  unlockedMarketCapUsdCents?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `TokenMetadatum` values. */
export type TokenMetadataConnection = {
  __typename?: 'TokenMetadataConnection';
  /** A list of edges which contains the `TokenMetadatum` and cursor to aid in pagination. */
  edges: Array<TokenMetadataEdge>;
  /** A list of `TokenMetadatum` objects. */
  nodes: Array<Maybe<TokenMetadatum>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TokenMetadatum` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TokenMetadatum` edge in the connection. */
export type TokenMetadataEdge = {
  __typename?: 'TokenMetadataEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TokenMetadatum` at the end of the edge. */
  node?: Maybe<TokenMetadatum>;
};

/** Methods to use when ordering `TokenMetadatum`. */
export enum TokenMetadataOrderBy {
  ApyBasisPointsAsc = 'APY_BASIS_POINTS_ASC',
  ApyBasisPointsDesc = 'APY_BASIS_POINTS_DESC',
  CirculatingSupplyAsc = 'CIRCULATING_SUPPLY_ASC',
  CirculatingSupplyDesc = 'CIRCULATING_SUPPLY_DESC',
  CoinCategoryAsc = 'COIN_CATEGORY_ASC',
  CoinCategoryDesc = 'COIN_CATEGORY_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  JoinedFocusDateAsc = 'JOINED_FOCUS_DATE_ASC',
  JoinedFocusDateDesc = 'JOINED_FOCUS_DATE_DESC',
  MarketCapUsdAsc = 'MARKET_CAP_USD_ASC',
  MarketCapUsdDesc = 'MARKET_CAP_USD_DESC',
  Natural = 'NATURAL',
  NumHoldersAsc = 'NUM_HOLDERS_ASC',
  NumHoldersDesc = 'NUM_HOLDERS_DESC',
  PkidAsc = 'PKID_ASC',
  PkidDesc = 'PKID_DESC',
  PriceUsdAsc = 'PRICE_USD_ASC',
  PriceUsdDesc = 'PRICE_USD_DESC',
  PriceUsdPctChange_1HAsc = 'PRICE_USD_PCT_CHANGE_1H_ASC',
  PriceUsdPctChange_1HDesc = 'PRICE_USD_PCT_CHANGE_1H_DESC',
  PriceUsdPctChange_7DAsc = 'PRICE_USD_PCT_CHANGE_7D_ASC',
  PriceUsdPctChange_7DDesc = 'PRICE_USD_PCT_CHANGE_7D_DESC',
  PriceUsdPctChange_24HAsc = 'PRICE_USD_PCT_CHANGE_24H_ASC',
  PriceUsdPctChange_24HDesc = 'PRICE_USD_PCT_CHANGE_24H_DESC',
  PriceUsdPctChange_30DAsc = 'PRICE_USD_PCT_CHANGE_30D_ASC',
  PriceUsdPctChange_30DDesc = 'PRICE_USD_PCT_CHANGE_30D_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublicKeyBase58CheckAsc = 'PUBLIC_KEY_BASE58_CHECK_ASC',
  PublicKeyBase58CheckDesc = 'PUBLIC_KEY_BASE58_CHECK_DESC',
  TokenAmmCreatedAtAsc = 'TOKEN_AMM_CREATED_AT_ASC',
  TokenAmmCreatedAtDesc = 'TOKEN_AMM_CREATED_AT_DESC',
  TokenAmmLaunchAtAsc = 'TOKEN_AMM_LAUNCH_AT_ASC',
  TokenAmmLaunchAtDesc = 'TOKEN_AMM_LAUNCH_AT_DESC',
  TotalAssetsBalanceUsdCentsAsc = 'TOTAL_ASSETS_BALANCE_USD_CENTS_ASC',
  TotalAssetsBalanceUsdCentsDesc = 'TOTAL_ASSETS_BALANCE_USD_CENTS_DESC',
  TotalFocusEarningsUsdCentsAllTimeAsc = 'TOTAL_FOCUS_EARNINGS_USD_CENTS_ALL_TIME_ASC',
  TotalFocusEarningsUsdCentsAllTimeDesc = 'TOTAL_FOCUS_EARNINGS_USD_CENTS_ALL_TIME_DESC',
  TotalFocusPostViewsAllTimeAsc = 'TOTAL_FOCUS_POST_VIEWS_ALL_TIME_ASC',
  TotalFocusPostViewsAllTimeDesc = 'TOTAL_FOCUS_POST_VIEWS_ALL_TIME_DESC',
  TotalFocusWhalePostViewsAllTimeAsc = 'TOTAL_FOCUS_WHALE_POST_VIEWS_ALL_TIME_ASC',
  TotalFocusWhalePostViewsAllTimeDesc = 'TOTAL_FOCUS_WHALE_POST_VIEWS_ALL_TIME_DESC',
  TotalFollowersAsc = 'TOTAL_FOLLOWERS_ASC',
  TotalFollowersDesc = 'TOTAL_FOLLOWERS_DESC',
  TotalFollowingAsc = 'TOTAL_FOLLOWING_ASC',
  TotalFollowingDesc = 'TOTAL_FOLLOWING_DESC',
  TotalSupplyAsc = 'TOTAL_SUPPLY_ASC',
  TotalSupplyDesc = 'TOTAL_SUPPLY_DESC',
  TotalWhaleFollowersAsc = 'TOTAL_WHALE_FOLLOWERS_ASC',
  TotalWhaleFollowersDesc = 'TOTAL_WHALE_FOLLOWERS_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  VolumeUsdPast_1HAsc = 'VOLUME_USD_PAST_1H_ASC',
  VolumeUsdPast_1HDesc = 'VOLUME_USD_PAST_1H_DESC',
  VolumeUsdPast_7DAsc = 'VOLUME_USD_PAST_7D_ASC',
  VolumeUsdPast_7DDesc = 'VOLUME_USD_PAST_7D_DESC',
  VolumeUsdPast_24HAsc = 'VOLUME_USD_PAST_24H_ASC',
  VolumeUsdPast_24HDesc = 'VOLUME_USD_PAST_24H_DESC',
  VolumeUsdPast_30DAsc = 'VOLUME_USD_PAST_30D_ASC',
  VolumeUsdPast_30DDesc = 'VOLUME_USD_PAST_30D_DESC',
}

export type TokenMetadatum = Node & {
  __typename?: 'TokenMetadatum';
  apyBasisPoints?: Maybe<Scalars['Int']['output']>;
  circulatingSupply?: Maybe<Scalars['Int']['output']>;
  coinCategory?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  joinedFocusDate?: Maybe<Scalars['Date']['output']>;
  marketCapUsd?: Maybe<Scalars['Float']['output']>;
  numHolders?: Maybe<Scalars['Int']['output']>;
  pkid?: Maybe<Scalars['String']['output']>;
  priceUsd?: Maybe<Scalars['Float']['output']>;
  priceUsdPctChange1H?: Maybe<Scalars['Float']['output']>;
  priceUsdPctChange7D?: Maybe<Scalars['Float']['output']>;
  priceUsdPctChange24H?: Maybe<Scalars['Float']['output']>;
  priceUsdPctChange30D?: Maybe<Scalars['Float']['output']>;
  publicKeyBase58Check: Scalars['String']['output'];
  tokenAmmCreatedAt?: Maybe<Scalars['Datetime']['output']>;
  tokenAmmLaunchAt?: Maybe<Scalars['Datetime']['output']>;
  totalAssetsBalanceUsdCents?: Maybe<Scalars['Int']['output']>;
  totalFocusEarningsUsdCentsAllTime?: Maybe<Scalars['Int']['output']>;
  totalFocusPostViewsAllTime?: Maybe<Scalars['Int']['output']>;
  totalFocusWhalePostViewsAllTime?: Maybe<Scalars['Int']['output']>;
  totalFollowers?: Maybe<Scalars['Int']['output']>;
  totalFollowing?: Maybe<Scalars['Int']['output']>;
  totalSupply?: Maybe<Scalars['Int']['output']>;
  totalWhaleFollowers?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  volumeUsdPast1H?: Maybe<Scalars['Float']['output']>;
  volumeUsdPast7D?: Maybe<Scalars['Float']['output']>;
  volumeUsdPast24H?: Maybe<Scalars['Float']['output']>;
  volumeUsdPast30D?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `TokenMetadatum` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TokenMetadatumCondition = {
  /** Checks for equality with the object’s `apyBasisPoints` field. */
  apyBasisPoints?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `circulatingSupply` field. */
  circulatingSupply?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `coinCategory` field. */
  coinCategory?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `joinedFocusDate` field. */
  joinedFocusDate?: InputMaybe<Scalars['Date']['input']>;
  /** Checks for equality with the object’s `marketCapUsd` field. */
  marketCapUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `numHolders` field. */
  numHolders?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `pkid` field. */
  pkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `priceUsd` field. */
  priceUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `priceUsdPctChange1H` field. */
  priceUsdPctChange1H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `priceUsdPctChange7D` field. */
  priceUsdPctChange7D?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `priceUsdPctChange24H` field. */
  priceUsdPctChange24H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `priceUsdPctChange30D` field. */
  priceUsdPctChange30D?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `publicKeyBase58Check` field. */
  publicKeyBase58Check?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenAmmCreatedAt` field. */
  tokenAmmCreatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `tokenAmmLaunchAt` field. */
  tokenAmmLaunchAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `totalAssetsBalanceUsdCents` field. */
  totalAssetsBalanceUsdCents?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalFocusEarningsUsdCentsAllTime` field. */
  totalFocusEarningsUsdCentsAllTime?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalFocusPostViewsAllTime` field. */
  totalFocusPostViewsAllTime?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalFocusWhalePostViewsAllTime` field. */
  totalFocusWhalePostViewsAllTime?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalFollowers` field. */
  totalFollowers?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalFollowing` field. */
  totalFollowing?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalSupply` field. */
  totalSupply?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalWhaleFollowers` field. */
  totalWhaleFollowers?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `volumeUsdPast1H` field. */
  volumeUsdPast1H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `volumeUsdPast7D` field. */
  volumeUsdPast7D?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `volumeUsdPast24H` field. */
  volumeUsdPast24H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `volumeUsdPast30D` field. */
  volumeUsdPast30D?: InputMaybe<Scalars['Float']['input']>;
};

/** A filter to be used against `TokenMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type TokenMetadatumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TokenMetadatumFilter>>;
  /** Filter by the object’s `apyBasisPoints` field. */
  apyBasisPoints?: InputMaybe<IntFilter>;
  /** Filter by the object’s `circulatingSupply` field. */
  circulatingSupply?: InputMaybe<IntFilter>;
  /** Filter by the object’s `coinCategory` field. */
  coinCategory?: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `joinedFocusDate` field. */
  joinedFocusDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `marketCapUsd` field. */
  marketCapUsd?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TokenMetadatumFilter>;
  /** Filter by the object’s `numHolders` field. */
  numHolders?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TokenMetadatumFilter>>;
  /** Filter by the object’s `pkid` field. */
  pkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `priceUsd` field. */
  priceUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `priceUsdPctChange1H` field. */
  priceUsdPctChange1H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `priceUsdPctChange7D` field. */
  priceUsdPctChange7D?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `priceUsdPctChange24H` field. */
  priceUsdPctChange24H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `priceUsdPctChange30D` field. */
  priceUsdPctChange30D?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `publicKeyBase58Check` field. */
  publicKeyBase58Check?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenAmmCreatedAt` field. */
  tokenAmmCreatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `tokenAmmLaunchAt` field. */
  tokenAmmLaunchAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `totalAssetsBalanceUsdCents` field. */
  totalAssetsBalanceUsdCents?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalFocusEarningsUsdCentsAllTime` field. */
  totalFocusEarningsUsdCentsAllTime?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalFocusPostViewsAllTime` field. */
  totalFocusPostViewsAllTime?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalFocusWhalePostViewsAllTime` field. */
  totalFocusWhalePostViewsAllTime?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalFollowers` field. */
  totalFollowers?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalFollowing` field. */
  totalFollowing?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalSupply` field. */
  totalSupply?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalWhaleFollowers` field. */
  totalWhaleFollowers?: InputMaybe<IntFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
  /** Filter by the object’s `volumeUsdPast1H` field. */
  volumeUsdPast1H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `volumeUsdPast7D` field. */
  volumeUsdPast7D?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `volumeUsdPast24H` field. */
  volumeUsdPast24H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `volumeUsdPast30D` field. */
  volumeUsdPast30D?: InputMaybe<FloatFilter>;
};

export type TokensByTotalVolume = {
  __typename?: 'TokensByTotalVolume';
  tokenDisplayName?: Maybe<Scalars['String']['output']>;
  tokenProfilePicUrl?: Maybe<Scalars['String']['output']>;
  tokenPublicKey?: Maybe<Scalars['String']['output']>;
  tokenUsername?: Maybe<Scalars['String']['output']>;
  totalVolumeUsd?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `TokensByTotalVolume` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type TokensByTotalVolumeCondition = {
  /** Checks for equality with the object’s `tokenDisplayName` field. */
  tokenDisplayName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenProfilePicUrl` field. */
  tokenProfilePicUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenPublicKey` field. */
  tokenPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenUsername` field. */
  tokenUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `totalVolumeUsd` field. */
  totalVolumeUsd?: InputMaybe<Scalars['Float']['input']>;
};

/** A filter to be used against `TokensByTotalVolume` object types. All fields are combined with a logical ‘and.’ */
export type TokensByTotalVolumeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TokensByTotalVolumeFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<TokensByTotalVolumeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TokensByTotalVolumeFilter>>;
  /** Filter by the object’s `tokenDisplayName` field. */
  tokenDisplayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenProfilePicUrl` field. */
  tokenProfilePicUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenPublicKey` field. */
  tokenPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenUsername` field. */
  tokenUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalVolumeUsd` field. */
  totalVolumeUsd?: InputMaybe<FloatFilter>;
};

/** A connection to a list of `TokensByTotalVolume` values. */
export type TokensByTotalVolumesConnection = {
  __typename?: 'TokensByTotalVolumesConnection';
  /** A list of edges which contains the `TokensByTotalVolume` and cursor to aid in pagination. */
  edges: Array<TokensByTotalVolumesEdge>;
  /** A list of `TokensByTotalVolume` objects. */
  nodes: Array<Maybe<TokensByTotalVolume>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TokensByTotalVolume` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TokensByTotalVolume` edge in the connection. */
export type TokensByTotalVolumesEdge = {
  __typename?: 'TokensByTotalVolumesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TokensByTotalVolume` at the end of the edge. */
  node?: Maybe<TokensByTotalVolume>;
};

/** Methods to use when ordering `TokensByTotalVolume`. */
export enum TokensByTotalVolumesOrderBy {
  Natural = 'NATURAL',
  TokenDisplayNameAsc = 'TOKEN_DISPLAY_NAME_ASC',
  TokenDisplayNameDesc = 'TOKEN_DISPLAY_NAME_DESC',
  TokenProfilePicUrlAsc = 'TOKEN_PROFILE_PIC_URL_ASC',
  TokenProfilePicUrlDesc = 'TOKEN_PROFILE_PIC_URL_DESC',
  TokenPublicKeyAsc = 'TOKEN_PUBLIC_KEY_ASC',
  TokenPublicKeyDesc = 'TOKEN_PUBLIC_KEY_DESC',
  TokenUsernameAsc = 'TOKEN_USERNAME_ASC',
  TokenUsernameDesc = 'TOKEN_USERNAME_DESC',
  TotalVolumeUsdAsc = 'TOTAL_VOLUME_USD_ASC',
  TotalVolumeUsdDesc = 'TOTAL_VOLUME_USD_DESC',
}

export type TotalUnclaimedMessageTip = {
  __typename?: 'TotalUnclaimedMessageTip';
  /** Reads a single `Account` that is related to this `TotalUnclaimedMessageTip`. */
  account?: Maybe<Account>;
  publicKey?: Maybe<Scalars['String']['output']>;
  totalUnclaimedMessageTipsUsdCents?: Maybe<Scalars['BigInt']['output']>;
};

/**
 * A condition to be used against `TotalUnclaimedMessageTip` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type TotalUnclaimedMessageTipCondition = {
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `totalUnclaimedMessageTipsUsdCents` field. */
  totalUnclaimedMessageTipsUsdCents?: InputMaybe<Scalars['BigInt']['input']>;
};

/** A filter to be used against `TotalUnclaimedMessageTip` object types. All fields are combined with a logical ‘and.’ */
export type TotalUnclaimedMessageTipFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TotalUnclaimedMessageTipFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<TotalUnclaimedMessageTipFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TotalUnclaimedMessageTipFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalUnclaimedMessageTipsUsdCents` field. */
  totalUnclaimedMessageTipsUsdCents?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `TotalUnclaimedMessageTip` values. */
export type TotalUnclaimedMessageTipsConnection = {
  __typename?: 'TotalUnclaimedMessageTipsConnection';
  /** A list of edges which contains the `TotalUnclaimedMessageTip` and cursor to aid in pagination. */
  edges: Array<TotalUnclaimedMessageTipsEdge>;
  /** A list of `TotalUnclaimedMessageTip` objects. */
  nodes: Array<Maybe<TotalUnclaimedMessageTip>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TotalUnclaimedMessageTip` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TotalUnclaimedMessageTip` edge in the connection. */
export type TotalUnclaimedMessageTipsEdge = {
  __typename?: 'TotalUnclaimedMessageTipsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TotalUnclaimedMessageTip` at the end of the edge. */
  node?: Maybe<TotalUnclaimedMessageTip>;
};

/** Methods to use when ordering `TotalUnclaimedMessageTip`. */
export enum TotalUnclaimedMessageTipsOrderBy {
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  TotalUnclaimedMessageTipsUsdCentsAsc = 'TOTAL_UNCLAIMED_MESSAGE_TIPS_USD_CENTS_ASC',
  TotalUnclaimedMessageTipsUsdCentsDesc = 'TOTAL_UNCLAIMED_MESSAGE_TIPS_USD_CENTS_DESC',
}

export type TradingRecentTrade = {
  __typename?: 'TradingRecentTrade';
  denominatedCoinPublicKey?: Maybe<Scalars['String']['output']>;
  isMatchedOrder?: Maybe<Scalars['Boolean']['output']>;
  tokenCategory?: Maybe<Scalars['String']['output']>;
  tokenMarketCapDeso?: Maybe<Scalars['Float']['output']>;
  tokenMarketCapFocus?: Maybe<Scalars['Float']['output']>;
  tokenMarketCapUsd?: Maybe<Scalars['Float']['output']>;
  tokenProfilePicUrl?: Maybe<Scalars['String']['output']>;
  tokenPublicKey?: Maybe<Scalars['String']['output']>;
  tokenUsername?: Maybe<Scalars['String']['output']>;
  tradeBuyCoinPublicKey?: Maybe<Scalars['String']['output']>;
  tradeBuyQuantity?: Maybe<Scalars['Float']['output']>;
  tradePriceDeso?: Maybe<Scalars['Float']['output']>;
  tradePriceFocus?: Maybe<Scalars['Float']['output']>;
  tradePriceUsd?: Maybe<Scalars['Float']['output']>;
  tradeQuantity?: Maybe<Scalars['Float']['output']>;
  tradeSellCoinPublicKey?: Maybe<Scalars['String']['output']>;
  tradeSellQuantity?: Maybe<Scalars['Float']['output']>;
  tradeTimestamp?: Maybe<Scalars['Datetime']['output']>;
  tradeType?: Maybe<Scalars['String']['output']>;
  tradeValueDeso?: Maybe<Scalars['Float']['output']>;
  tradeValueFocus?: Maybe<Scalars['Float']['output']>;
  tradeValueUsd?: Maybe<Scalars['Float']['output']>;
  traderAssetsUsd?: Maybe<Scalars['Float']['output']>;
  traderDisplayName?: Maybe<Scalars['String']['output']>;
  traderProfilePicUrl?: Maybe<Scalars['String']['output']>;
  traderPublicKey?: Maybe<Scalars['String']['output']>;
  traderUsername?: Maybe<Scalars['String']['output']>;
  txnHashHex?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `TradingRecentTrade` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TradingRecentTradeCondition = {
  /** Checks for equality with the object’s `denominatedCoinPublicKey` field. */
  denominatedCoinPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isMatchedOrder` field. */
  isMatchedOrder?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `tokenCategory` field. */
  tokenCategory?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenMarketCapDeso` field. */
  tokenMarketCapDeso?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tokenMarketCapFocus` field. */
  tokenMarketCapFocus?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tokenMarketCapUsd` field. */
  tokenMarketCapUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tokenProfilePicUrl` field. */
  tokenProfilePicUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenPublicKey` field. */
  tokenPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenUsername` field. */
  tokenUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tradeBuyCoinPublicKey` field. */
  tradeBuyCoinPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tradeBuyQuantity` field. */
  tradeBuyQuantity?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tradePriceDeso` field. */
  tradePriceDeso?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tradePriceFocus` field. */
  tradePriceFocus?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tradePriceUsd` field. */
  tradePriceUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tradeQuantity` field. */
  tradeQuantity?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tradeSellCoinPublicKey` field. */
  tradeSellCoinPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tradeSellQuantity` field. */
  tradeSellQuantity?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tradeTimestamp` field. */
  tradeTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `tradeType` field. */
  tradeType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tradeValueDeso` field. */
  tradeValueDeso?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tradeValueFocus` field. */
  tradeValueFocus?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tradeValueUsd` field. */
  tradeValueUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `traderAssetsUsd` field. */
  traderAssetsUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `traderDisplayName` field. */
  traderDisplayName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `traderProfilePicUrl` field. */
  traderProfilePicUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `traderPublicKey` field. */
  traderPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `traderUsername` field. */
  traderUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `txnHashHex` field. */
  txnHashHex?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `TradingRecentTrade` object types. All fields are combined with a logical ‘and.’ */
export type TradingRecentTradeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TradingRecentTradeFilter>>;
  /** Filter by the object’s `denominatedCoinPublicKey` field. */
  denominatedCoinPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isMatchedOrder` field. */
  isMatchedOrder?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TradingRecentTradeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TradingRecentTradeFilter>>;
  /** Filter by the object’s `tokenCategory` field. */
  tokenCategory?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenMarketCapDeso` field. */
  tokenMarketCapDeso?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tokenMarketCapFocus` field. */
  tokenMarketCapFocus?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tokenMarketCapUsd` field. */
  tokenMarketCapUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tokenProfilePicUrl` field. */
  tokenProfilePicUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenPublicKey` field. */
  tokenPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenUsername` field. */
  tokenUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tradeBuyCoinPublicKey` field. */
  tradeBuyCoinPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tradeBuyQuantity` field. */
  tradeBuyQuantity?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tradePriceDeso` field. */
  tradePriceDeso?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tradePriceFocus` field. */
  tradePriceFocus?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tradePriceUsd` field. */
  tradePriceUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tradeQuantity` field. */
  tradeQuantity?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tradeSellCoinPublicKey` field. */
  tradeSellCoinPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tradeSellQuantity` field. */
  tradeSellQuantity?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tradeTimestamp` field. */
  tradeTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `tradeType` field. */
  tradeType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tradeValueDeso` field. */
  tradeValueDeso?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tradeValueFocus` field. */
  tradeValueFocus?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tradeValueUsd` field. */
  tradeValueUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `traderAssetsUsd` field. */
  traderAssetsUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `traderDisplayName` field. */
  traderDisplayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `traderProfilePicUrl` field. */
  traderProfilePicUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `traderPublicKey` field. */
  traderPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `traderUsername` field. */
  traderUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `txnHashHex` field. */
  txnHashHex?: InputMaybe<StringFilter>;
};

/** A connection to a list of `TradingRecentTrade` values. */
export type TradingRecentTradesConnection = {
  __typename?: 'TradingRecentTradesConnection';
  /** A list of edges which contains the `TradingRecentTrade` and cursor to aid in pagination. */
  edges: Array<TradingRecentTradesEdge>;
  /** A list of `TradingRecentTrade` objects. */
  nodes: Array<Maybe<TradingRecentTrade>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TradingRecentTrade` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TradingRecentTrade` edge in the connection. */
export type TradingRecentTradesEdge = {
  __typename?: 'TradingRecentTradesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TradingRecentTrade` at the end of the edge. */
  node?: Maybe<TradingRecentTrade>;
};

/** Methods to use when ordering `TradingRecentTrade`. */
export enum TradingRecentTradesOrderBy {
  DenominatedCoinPublicKeyAsc = 'DENOMINATED_COIN_PUBLIC_KEY_ASC',
  DenominatedCoinPublicKeyDesc = 'DENOMINATED_COIN_PUBLIC_KEY_DESC',
  IsMatchedOrderAsc = 'IS_MATCHED_ORDER_ASC',
  IsMatchedOrderDesc = 'IS_MATCHED_ORDER_DESC',
  Natural = 'NATURAL',
  TokenCategoryAsc = 'TOKEN_CATEGORY_ASC',
  TokenCategoryDesc = 'TOKEN_CATEGORY_DESC',
  TokenMarketCapDesoAsc = 'TOKEN_MARKET_CAP_DESO_ASC',
  TokenMarketCapDesoDesc = 'TOKEN_MARKET_CAP_DESO_DESC',
  TokenMarketCapFocusAsc = 'TOKEN_MARKET_CAP_FOCUS_ASC',
  TokenMarketCapFocusDesc = 'TOKEN_MARKET_CAP_FOCUS_DESC',
  TokenMarketCapUsdAsc = 'TOKEN_MARKET_CAP_USD_ASC',
  TokenMarketCapUsdDesc = 'TOKEN_MARKET_CAP_USD_DESC',
  TokenProfilePicUrlAsc = 'TOKEN_PROFILE_PIC_URL_ASC',
  TokenProfilePicUrlDesc = 'TOKEN_PROFILE_PIC_URL_DESC',
  TokenPublicKeyAsc = 'TOKEN_PUBLIC_KEY_ASC',
  TokenPublicKeyDesc = 'TOKEN_PUBLIC_KEY_DESC',
  TokenUsernameAsc = 'TOKEN_USERNAME_ASC',
  TokenUsernameDesc = 'TOKEN_USERNAME_DESC',
  TraderAssetsUsdAsc = 'TRADER_ASSETS_USD_ASC',
  TraderAssetsUsdDesc = 'TRADER_ASSETS_USD_DESC',
  TraderDisplayNameAsc = 'TRADER_DISPLAY_NAME_ASC',
  TraderDisplayNameDesc = 'TRADER_DISPLAY_NAME_DESC',
  TraderProfilePicUrlAsc = 'TRADER_PROFILE_PIC_URL_ASC',
  TraderProfilePicUrlDesc = 'TRADER_PROFILE_PIC_URL_DESC',
  TraderPublicKeyAsc = 'TRADER_PUBLIC_KEY_ASC',
  TraderPublicKeyDesc = 'TRADER_PUBLIC_KEY_DESC',
  TraderUsernameAsc = 'TRADER_USERNAME_ASC',
  TraderUsernameDesc = 'TRADER_USERNAME_DESC',
  TradeBuyCoinPublicKeyAsc = 'TRADE_BUY_COIN_PUBLIC_KEY_ASC',
  TradeBuyCoinPublicKeyDesc = 'TRADE_BUY_COIN_PUBLIC_KEY_DESC',
  TradeBuyQuantityAsc = 'TRADE_BUY_QUANTITY_ASC',
  TradeBuyQuantityDesc = 'TRADE_BUY_QUANTITY_DESC',
  TradePriceDesoAsc = 'TRADE_PRICE_DESO_ASC',
  TradePriceDesoDesc = 'TRADE_PRICE_DESO_DESC',
  TradePriceFocusAsc = 'TRADE_PRICE_FOCUS_ASC',
  TradePriceFocusDesc = 'TRADE_PRICE_FOCUS_DESC',
  TradePriceUsdAsc = 'TRADE_PRICE_USD_ASC',
  TradePriceUsdDesc = 'TRADE_PRICE_USD_DESC',
  TradeQuantityAsc = 'TRADE_QUANTITY_ASC',
  TradeQuantityDesc = 'TRADE_QUANTITY_DESC',
  TradeSellCoinPublicKeyAsc = 'TRADE_SELL_COIN_PUBLIC_KEY_ASC',
  TradeSellCoinPublicKeyDesc = 'TRADE_SELL_COIN_PUBLIC_KEY_DESC',
  TradeSellQuantityAsc = 'TRADE_SELL_QUANTITY_ASC',
  TradeSellQuantityDesc = 'TRADE_SELL_QUANTITY_DESC',
  TradeTimestampAsc = 'TRADE_TIMESTAMP_ASC',
  TradeTimestampDesc = 'TRADE_TIMESTAMP_DESC',
  TradeTypeAsc = 'TRADE_TYPE_ASC',
  TradeTypeDesc = 'TRADE_TYPE_DESC',
  TradeValueDesoAsc = 'TRADE_VALUE_DESO_ASC',
  TradeValueDesoDesc = 'TRADE_VALUE_DESO_DESC',
  TradeValueFocusAsc = 'TRADE_VALUE_FOCUS_ASC',
  TradeValueFocusDesc = 'TRADE_VALUE_FOCUS_DESC',
  TradeValueUsdAsc = 'TRADE_VALUE_USD_ASC',
  TradeValueUsdDesc = 'TRADE_VALUE_USD_DESC',
  TxnHashHexAsc = 'TXN_HASH_HEX_ASC',
  TxnHashHexDesc = 'TXN_HASH_HEX_DESC',
}

export type TradingTopTradersByTokenByVolume = {
  __typename?: 'TradingTopTradersByTokenByVolume';
  timeWindow?: Maybe<Scalars['String']['output']>;
  tokenCategory?: Maybe<Scalars['String']['output']>;
  tokenDisplayName?: Maybe<Scalars['String']['output']>;
  tokenProfilePicUrl?: Maybe<Scalars['String']['output']>;
  tokenPublicKey?: Maybe<Scalars['String']['output']>;
  tokenUsername?: Maybe<Scalars['String']['output']>;
  totalVolumeFocus?: Maybe<Scalars['Float']['output']>;
  totalVolumeUsd?: Maybe<Scalars['Float']['output']>;
  traderDisplayName?: Maybe<Scalars['String']['output']>;
  traderLockedTokenBalance?: Maybe<Scalars['BigFloat']['output']>;
  traderLockedTokenBalanceUsd?: Maybe<Scalars['BigFloat']['output']>;
  traderProfilePicUrl?: Maybe<Scalars['String']['output']>;
  traderPublicKey?: Maybe<Scalars['String']['output']>;
  traderTokenBalance?: Maybe<Scalars['BigFloat']['output']>;
  traderTokenBalanceUsd?: Maybe<Scalars['BigFloat']['output']>;
  traderTotalBalanceFocus?: Maybe<Scalars['Float']['output']>;
  traderTotalBalanceUsd?: Maybe<Scalars['Float']['output']>;
  traderUsername?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `TradingTopTradersByTokenByVolume` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type TradingTopTradersByTokenByVolumeCondition = {
  /** Checks for equality with the object’s `timeWindow` field. */
  timeWindow?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenCategory` field. */
  tokenCategory?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenDisplayName` field. */
  tokenDisplayName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenProfilePicUrl` field. */
  tokenProfilePicUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenPublicKey` field. */
  tokenPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenUsername` field. */
  tokenUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `totalVolumeFocus` field. */
  totalVolumeFocus?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `totalVolumeUsd` field. */
  totalVolumeUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `traderDisplayName` field. */
  traderDisplayName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `traderLockedTokenBalance` field. */
  traderLockedTokenBalance?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `traderLockedTokenBalanceUsd` field. */
  traderLockedTokenBalanceUsd?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `traderProfilePicUrl` field. */
  traderProfilePicUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `traderPublicKey` field. */
  traderPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `traderTokenBalance` field. */
  traderTokenBalance?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `traderTokenBalanceUsd` field. */
  traderTokenBalanceUsd?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `traderTotalBalanceFocus` field. */
  traderTotalBalanceFocus?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `traderTotalBalanceUsd` field. */
  traderTotalBalanceUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `traderUsername` field. */
  traderUsername?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `TradingTopTradersByTokenByVolume` object types. All fields are combined with a logical ‘and.’ */
export type TradingTopTradersByTokenByVolumeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TradingTopTradersByTokenByVolumeFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<TradingTopTradersByTokenByVolumeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TradingTopTradersByTokenByVolumeFilter>>;
  /** Filter by the object’s `timeWindow` field. */
  timeWindow?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenCategory` field. */
  tokenCategory?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenDisplayName` field. */
  tokenDisplayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenProfilePicUrl` field. */
  tokenProfilePicUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenPublicKey` field. */
  tokenPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenUsername` field. */
  tokenUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalVolumeFocus` field. */
  totalVolumeFocus?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `totalVolumeUsd` field. */
  totalVolumeUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `traderDisplayName` field. */
  traderDisplayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `traderLockedTokenBalance` field. */
  traderLockedTokenBalance?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `traderLockedTokenBalanceUsd` field. */
  traderLockedTokenBalanceUsd?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `traderProfilePicUrl` field. */
  traderProfilePicUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `traderPublicKey` field. */
  traderPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `traderTokenBalance` field. */
  traderTokenBalance?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `traderTokenBalanceUsd` field. */
  traderTokenBalanceUsd?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `traderTotalBalanceFocus` field. */
  traderTotalBalanceFocus?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `traderTotalBalanceUsd` field. */
  traderTotalBalanceUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `traderUsername` field. */
  traderUsername?: InputMaybe<StringFilter>;
};

/** A connection to a list of `TradingTopTradersByTokenByVolume` values. */
export type TradingTopTradersByTokenByVolumesConnection = {
  __typename?: 'TradingTopTradersByTokenByVolumesConnection';
  /** A list of edges which contains the `TradingTopTradersByTokenByVolume` and cursor to aid in pagination. */
  edges: Array<TradingTopTradersByTokenByVolumesEdge>;
  /** A list of `TradingTopTradersByTokenByVolume` objects. */
  nodes: Array<Maybe<TradingTopTradersByTokenByVolume>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TradingTopTradersByTokenByVolume` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TradingTopTradersByTokenByVolume` edge in the connection. */
export type TradingTopTradersByTokenByVolumesEdge = {
  __typename?: 'TradingTopTradersByTokenByVolumesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TradingTopTradersByTokenByVolume` at the end of the edge. */
  node?: Maybe<TradingTopTradersByTokenByVolume>;
};

/** Methods to use when ordering `TradingTopTradersByTokenByVolume`. */
export enum TradingTopTradersByTokenByVolumesOrderBy {
  Natural = 'NATURAL',
  TimeWindowAsc = 'TIME_WINDOW_ASC',
  TimeWindowDesc = 'TIME_WINDOW_DESC',
  TokenCategoryAsc = 'TOKEN_CATEGORY_ASC',
  TokenCategoryDesc = 'TOKEN_CATEGORY_DESC',
  TokenDisplayNameAsc = 'TOKEN_DISPLAY_NAME_ASC',
  TokenDisplayNameDesc = 'TOKEN_DISPLAY_NAME_DESC',
  TokenProfilePicUrlAsc = 'TOKEN_PROFILE_PIC_URL_ASC',
  TokenProfilePicUrlDesc = 'TOKEN_PROFILE_PIC_URL_DESC',
  TokenPublicKeyAsc = 'TOKEN_PUBLIC_KEY_ASC',
  TokenPublicKeyDesc = 'TOKEN_PUBLIC_KEY_DESC',
  TokenUsernameAsc = 'TOKEN_USERNAME_ASC',
  TokenUsernameDesc = 'TOKEN_USERNAME_DESC',
  TotalVolumeFocusAsc = 'TOTAL_VOLUME_FOCUS_ASC',
  TotalVolumeFocusDesc = 'TOTAL_VOLUME_FOCUS_DESC',
  TotalVolumeUsdAsc = 'TOTAL_VOLUME_USD_ASC',
  TotalVolumeUsdDesc = 'TOTAL_VOLUME_USD_DESC',
  TraderDisplayNameAsc = 'TRADER_DISPLAY_NAME_ASC',
  TraderDisplayNameDesc = 'TRADER_DISPLAY_NAME_DESC',
  TraderLockedTokenBalanceAsc = 'TRADER_LOCKED_TOKEN_BALANCE_ASC',
  TraderLockedTokenBalanceDesc = 'TRADER_LOCKED_TOKEN_BALANCE_DESC',
  TraderLockedTokenBalanceUsdAsc = 'TRADER_LOCKED_TOKEN_BALANCE_USD_ASC',
  TraderLockedTokenBalanceUsdDesc = 'TRADER_LOCKED_TOKEN_BALANCE_USD_DESC',
  TraderProfilePicUrlAsc = 'TRADER_PROFILE_PIC_URL_ASC',
  TraderProfilePicUrlDesc = 'TRADER_PROFILE_PIC_URL_DESC',
  TraderPublicKeyAsc = 'TRADER_PUBLIC_KEY_ASC',
  TraderPublicKeyDesc = 'TRADER_PUBLIC_KEY_DESC',
  TraderTokenBalanceAsc = 'TRADER_TOKEN_BALANCE_ASC',
  TraderTokenBalanceDesc = 'TRADER_TOKEN_BALANCE_DESC',
  TraderTokenBalanceUsdAsc = 'TRADER_TOKEN_BALANCE_USD_ASC',
  TraderTokenBalanceUsdDesc = 'TRADER_TOKEN_BALANCE_USD_DESC',
  TraderTotalBalanceFocusAsc = 'TRADER_TOTAL_BALANCE_FOCUS_ASC',
  TraderTotalBalanceFocusDesc = 'TRADER_TOTAL_BALANCE_FOCUS_DESC',
  TraderTotalBalanceUsdAsc = 'TRADER_TOTAL_BALANCE_USD_ASC',
  TraderTotalBalanceUsdDesc = 'TRADER_TOTAL_BALANCE_USD_DESC',
  TraderUsernameAsc = 'TRADER_USERNAME_ASC',
  TraderUsernameDesc = 'TRADER_USERNAME_DESC',
}

export type Transaction = {
  __typename?: 'Transaction';
  /** Reads a single `Account` that is related to this `Transaction`. */
  account?: Maybe<Account>;
  /** Reads and enables pagination through a set of `AffectedPublicKey`. */
  affectedPublicKeys: AffectedPublicKeysConnection;
  /** Reads a single `Block` that is related to this `Transaction`. */
  block?: Maybe<Block>;
  blockHash?: Maybe<Scalars['String']['output']>;
  blockHeight?: Maybe<Scalars['BigInt']['output']>;
  extraData?: Maybe<Scalars['JSON']['output']>;
  feeNanos?: Maybe<Scalars['BigInt']['output']>;
  indexInBlock?: Maybe<Scalars['Int']['output']>;
  indexInWrapperTransaction?: Maybe<Scalars['BigInt']['output']>;
  /** Reads and enables pagination through a set of `Transaction`. */
  innerTransactions: TransactionsConnection;
  inputs?: Maybe<Scalars['JSON']['output']>;
  nonceExpirationBlockHeight?: Maybe<Scalars['BigInt']['output']>;
  noncePartialId?: Maybe<Scalars['BigInt']['output']>;
  outputs?: Maybe<Scalars['JSON']['output']>;
  /** Reads a single `Payment` that is related to this `Transaction`. */
  payment?: Maybe<Payment>;
  /** Reads a single `UserAssociation` that is related to this `Transaction`. */
  paymentAssociation?: Maybe<UserAssociation>;
  paymentUuid?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Datetime']['output']>;
  transactionHash?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `TransactionType` that is related to this `Transaction`. */
  transactionType?: Maybe<TransactionType>;
  txIndexBasicTransferMetadata?: Maybe<Scalars['JSON']['output']>;
  txIndexMetadata?: Maybe<Scalars['JSON']['output']>;
  txnBytes?: Maybe<Scalars['String']['output']>;
  txnMeta?: Maybe<Scalars['JSON']['output']>;
  txnMetaBytes?: Maybe<Scalars['String']['output']>;
  txnType?: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `UtxoOperation`. */
  utxoOperationsByBlockHashAndTransactionIndex: UtxoOperationsConnection;
  version?: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Transaction` that is related to this `Transaction`. */
  wrapperTransaction?: Maybe<Transaction>;
  wrapperTransactionHash?: Maybe<Scalars['String']['output']>;
};

export type TransactionAffectedPublicKeysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AffectedPublicKeyCondition>;
  filter?: InputMaybe<AffectedPublicKeyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AffectedPublicKeysOrderBy>>;
};

export type TransactionInnerTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

export type TransactionUtxoOperationsByBlockHashAndTransactionIndexArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UtxoOperationCondition>;
  filter?: InputMaybe<UtxoOperationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UtxoOperationsOrderBy>>;
};

/**
 * A condition to be used against `Transaction` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TransactionCondition = {
  /** Checks for equality with the object’s `blockHash` field. */
  blockHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `feeNanos` field. */
  feeNanos?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `indexInBlock` field. */
  indexInBlock?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `indexInWrapperTransaction` field. */
  indexInWrapperTransaction?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `inputs` field. */
  inputs?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `nonceExpirationBlockHeight` field. */
  nonceExpirationBlockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `noncePartialId` field. */
  noncePartialId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `outputs` field. */
  outputs?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `paymentUuid` field. */
  paymentUuid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `signature` field. */
  signature?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `transactionHash` field. */
  transactionHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transactionId` field. */
  transactionId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `txIndexBasicTransferMetadata` field. */
  txIndexBasicTransferMetadata?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `txIndexMetadata` field. */
  txIndexMetadata?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `txnBytes` field. */
  txnBytes?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `txnMeta` field. */
  txnMeta?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `txnMetaBytes` field. */
  txnMetaBytes?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `txnType` field. */
  txnType?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `wrapperTransactionHash` field. */
  wrapperTransactionHash?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type TransactionFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `affectedPublicKeys` relation. */
  affectedPublicKeys?: InputMaybe<TransactionToManyAffectedPublicKeyFilter>;
  /** Some related `affectedPublicKeys` exist. */
  affectedPublicKeysExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionFilter>>;
  /** Filter by the object’s `block` relation. */
  block?: InputMaybe<BlockFilter>;
  /** A related `block` exists. */
  blockExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `blockHash` field. */
  blockHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `feeNanos` field. */
  feeNanos?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `indexInBlock` field. */
  indexInBlock?: InputMaybe<IntFilter>;
  /** Filter by the object’s `indexInWrapperTransaction` field. */
  indexInWrapperTransaction?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `innerTransactions` relation. */
  innerTransactions?: InputMaybe<TransactionToManyTransactionFilter>;
  /** Some related `innerTransactions` exist. */
  innerTransactionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `inputs` field. */
  inputs?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `nonceExpirationBlockHeight` field. */
  nonceExpirationBlockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `noncePartialId` field. */
  noncePartialId?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionFilter>>;
  /** Filter by the object’s `outputs` field. */
  outputs?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `payment` relation. */
  payment?: InputMaybe<PaymentFilter>;
  /** Filter by the object’s `paymentAssociation` relation. */
  paymentAssociation?: InputMaybe<UserAssociationFilter>;
  /** A related `paymentAssociation` exists. */
  paymentAssociationExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** A related `payment` exists. */
  paymentExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `paymentUuid` field. */
  paymentUuid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `transactionHash` field. */
  transactionHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionId` field. */
  transactionId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionType` relation. */
  transactionType?: InputMaybe<TransactionTypeFilter>;
  /** A related `transactionType` exists. */
  transactionTypeExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `txIndexBasicTransferMetadata` field. */
  txIndexBasicTransferMetadata?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `txIndexMetadata` field. */
  txIndexMetadata?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `txnMeta` field. */
  txnMeta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `txnType` field. */
  txnType?: InputMaybe<IntFilter>;
  /** Filter by the object’s `utxoOperationsByBlockHashAndTransactionIndex` relation. */
  utxoOperationsByBlockHashAndTransactionIndex?: InputMaybe<TransactionToManyUtxoOperationFilter>;
  /** Some related `utxoOperationsByBlockHashAndTransactionIndex` exist. */
  utxoOperationsByBlockHashAndTransactionIndexExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<IntFilter>;
  /** Filter by the object’s `wrapperTransaction` relation. */
  wrapperTransaction?: InputMaybe<TransactionFilter>;
  /** A related `wrapperTransaction` exists. */
  wrapperTransactionExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `wrapperTransactionHash` field. */
  wrapperTransactionHash?: InputMaybe<StringFilter>;
};

/** A filter to be used against many `AffectedPublicKey` object types. All fields are combined with a logical ‘and.’ */
export type TransactionToManyAffectedPublicKeyFilter = {
  /** Every related `AffectedPublicKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AffectedPublicKeyFilter>;
  /** No related `AffectedPublicKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AffectedPublicKeyFilter>;
  /** Some related `AffectedPublicKey` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AffectedPublicKeyFilter>;
};

/** A filter to be used against many `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type TransactionToManyTransactionFilter = {
  /** Every related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TransactionFilter>;
  /** No related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TransactionFilter>;
  /** Some related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TransactionFilter>;
};

/** A filter to be used against many `UtxoOperation` object types. All fields are combined with a logical ‘and.’ */
export type TransactionToManyUtxoOperationFilter = {
  /** Every related `UtxoOperation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UtxoOperationFilter>;
  /** No related `UtxoOperation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UtxoOperationFilter>;
  /** Some related `UtxoOperation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UtxoOperationFilter>;
};

export type TransactionType = Node & {
  __typename?: 'TransactionType';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** Reads a single `Transaction` that is related to this `TransactionType`. */
  transaction?: Maybe<Transaction>;
  type: Scalars['Int']['output'];
};

/**
 * A condition to be used against `TransactionType` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TransactionTypeCondition = {
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `TransactionType` object types. All fields are combined with a logical ‘and.’ */
export type TransactionTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionTypeFilter>>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionTypeFilter>>;
  /** Filter by the object’s `transaction` relation. */
  transaction?: InputMaybe<TransactionFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<IntFilter>;
};

/** A connection to a list of `TransactionType` values. */
export type TransactionTypesConnection = {
  __typename?: 'TransactionTypesConnection';
  /** A list of edges which contains the `TransactionType` and cursor to aid in pagination. */
  edges: Array<TransactionTypesEdge>;
  /** A list of `TransactionType` objects. */
  nodes: Array<Maybe<TransactionType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransactionType` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TransactionType` edge in the connection. */
export type TransactionTypesEdge = {
  __typename?: 'TransactionTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TransactionType` at the end of the edge. */
  node?: Maybe<TransactionType>;
};

/** Methods to use when ordering `TransactionType`. */
export enum TransactionTypesOrderBy {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
}

/** A connection to a list of `Transaction` values. */
export type TransactionsConnection = {
  __typename?: 'TransactionsConnection';
  /** A list of edges which contains the `Transaction` and cursor to aid in pagination. */
  edges: Array<TransactionsEdge>;
  /** A list of `Transaction` objects. */
  nodes: Array<Maybe<Transaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Transaction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Transaction` edge in the connection. */
export type TransactionsEdge = {
  __typename?: 'TransactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Transaction` at the end of the edge. */
  node?: Maybe<Transaction>;
};

/** Methods to use when ordering `Transaction`. */
export enum TransactionsOrderBy {
  BlockHashAsc = 'BLOCK_HASH_ASC',
  BlockHashDesc = 'BLOCK_HASH_DESC',
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  FeeNanosAsc = 'FEE_NANOS_ASC',
  FeeNanosDesc = 'FEE_NANOS_DESC',
  IndexInBlockAsc = 'INDEX_IN_BLOCK_ASC',
  IndexInBlockDesc = 'INDEX_IN_BLOCK_DESC',
  IndexInWrapperTransactionAsc = 'INDEX_IN_WRAPPER_TRANSACTION_ASC',
  IndexInWrapperTransactionDesc = 'INDEX_IN_WRAPPER_TRANSACTION_DESC',
  InputsAsc = 'INPUTS_ASC',
  InputsDesc = 'INPUTS_DESC',
  Natural = 'NATURAL',
  NonceExpirationBlockHeightAsc = 'NONCE_EXPIRATION_BLOCK_HEIGHT_ASC',
  NonceExpirationBlockHeightDesc = 'NONCE_EXPIRATION_BLOCK_HEIGHT_DESC',
  NoncePartialIdAsc = 'NONCE_PARTIAL_ID_ASC',
  NoncePartialIdDesc = 'NONCE_PARTIAL_ID_DESC',
  OutputsAsc = 'OUTPUTS_ASC',
  OutputsDesc = 'OUTPUTS_DESC',
  PaymentUuidAsc = 'PAYMENT_UUID_ASC',
  PaymentUuidDesc = 'PAYMENT_UUID_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  SignatureAsc = 'SIGNATURE_ASC',
  SignatureDesc = 'SIGNATURE_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
  TransactionHashAsc = 'TRANSACTION_HASH_ASC',
  TransactionHashDesc = 'TRANSACTION_HASH_DESC',
  TransactionIdAsc = 'TRANSACTION_ID_ASC',
  TransactionIdDesc = 'TRANSACTION_ID_DESC',
  TxnBytesAsc = 'TXN_BYTES_ASC',
  TxnBytesDesc = 'TXN_BYTES_DESC',
  TxnMetaAsc = 'TXN_META_ASC',
  TxnMetaBytesAsc = 'TXN_META_BYTES_ASC',
  TxnMetaBytesDesc = 'TXN_META_BYTES_DESC',
  TxnMetaDesc = 'TXN_META_DESC',
  TxnTypeAsc = 'TXN_TYPE_ASC',
  TxnTypeDesc = 'TXN_TYPE_DESC',
  TxIndexBasicTransferMetadataAsc = 'TX_INDEX_BASIC_TRANSFER_METADATA_ASC',
  TxIndexBasicTransferMetadataDesc = 'TX_INDEX_BASIC_TRANSFER_METADATA_DESC',
  TxIndexMetadataAsc = 'TX_INDEX_METADATA_ASC',
  TxIndexMetadataDesc = 'TX_INDEX_METADATA_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC',
  WrapperTransactionHashAsc = 'WRAPPER_TRANSACTION_HASH_ASC',
  WrapperTransactionHashDesc = 'WRAPPER_TRANSACTION_HASH_DESC',
}

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UnreadMessagesCount = {
  __typename?: 'UnreadMessagesCount';
  /** Reads a single `Account` that is related to this `UnreadMessagesCount`. */
  account?: Maybe<Account>;
  lastViewedTimestamp?: Maybe<Scalars['Datetime']['output']>;
  /** Reads a single `FilterableMessageThread` that is related to this `UnreadMessagesCount`. */
  participant?: Maybe<FilterableMessageThread>;
  publicKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `MessageThread` that is related to this `UnreadMessagesCount`. */
  thread?: Maybe<MessageThread>;
  threadIdentifier?: Maybe<Scalars['String']['output']>;
  unreadCount?: Maybe<Scalars['BigInt']['output']>;
};

/**
 * A condition to be used against `UnreadMessagesCount` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type UnreadMessagesCountCondition = {
  /** Checks for equality with the object’s `lastViewedTimestamp` field. */
  lastViewedTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `unreadCount` field. */
  unreadCount?: InputMaybe<Scalars['BigInt']['input']>;
};

/** A filter to be used against `UnreadMessagesCount` object types. All fields are combined with a logical ‘and.’ */
export type UnreadMessagesCountFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UnreadMessagesCountFilter>>;
  /** Filter by the object’s `lastViewedTimestamp` field. */
  lastViewedTimestamp?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UnreadMessagesCountFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UnreadMessagesCountFilter>>;
  /** Filter by the object’s `participant` relation. */
  participant?: InputMaybe<FilterableMessageThreadFilter>;
  /** A related `participant` exists. */
  participantExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `thread` relation. */
  thread?: InputMaybe<MessageThreadFilter>;
  /** A related `thread` exists. */
  threadExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `threadIdentifier` field. */
  threadIdentifier?: InputMaybe<StringFilter>;
  /** Filter by the object’s `unreadCount` field. */
  unreadCount?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `UnreadMessagesCount` values. */
export type UnreadMessagesCountsConnection = {
  __typename?: 'UnreadMessagesCountsConnection';
  /** A list of edges which contains the `UnreadMessagesCount` and cursor to aid in pagination. */
  edges: Array<UnreadMessagesCountsEdge>;
  /** A list of `UnreadMessagesCount` objects. */
  nodes: Array<Maybe<UnreadMessagesCount>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UnreadMessagesCount` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `UnreadMessagesCount` edge in the connection. */
export type UnreadMessagesCountsEdge = {
  __typename?: 'UnreadMessagesCountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UnreadMessagesCount` at the end of the edge. */
  node?: Maybe<UnreadMessagesCount>;
};

/** Methods to use when ordering `UnreadMessagesCount`. */
export enum UnreadMessagesCountsOrderBy {
  LastViewedTimestampAsc = 'LAST_VIEWED_TIMESTAMP_ASC',
  LastViewedTimestampDesc = 'LAST_VIEWED_TIMESTAMP_DESC',
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  ThreadIdentifierAsc = 'THREAD_IDENTIFIER_ASC',
  ThreadIdentifierDesc = 'THREAD_IDENTIFIER_DESC',
  UnreadCountAsc = 'UNREAD_COUNT_ASC',
  UnreadCountDesc = 'UNREAD_COUNT_DESC',
}

export type UnreadMessagesTotalCount = {
  __typename?: 'UnreadMessagesTotalCount';
  /** Reads a single `Account` that is related to this `UnreadMessagesTotalCount`. */
  account?: Maybe<Account>;
  publicKey?: Maybe<Scalars['String']['output']>;
  unreadCount?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `UnreadMessagesTotalCount` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type UnreadMessagesTotalCountCondition = {
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `unreadCount` field. */
  unreadCount?: InputMaybe<Scalars['BigFloat']['input']>;
};

/** A filter to be used against `UnreadMessagesTotalCount` object types. All fields are combined with a logical ‘and.’ */
export type UnreadMessagesTotalCountFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UnreadMessagesTotalCountFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<UnreadMessagesTotalCountFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UnreadMessagesTotalCountFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `unreadCount` field. */
  unreadCount?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `UnreadMessagesTotalCount` values. */
export type UnreadMessagesTotalCountsConnection = {
  __typename?: 'UnreadMessagesTotalCountsConnection';
  /** A list of edges which contains the `UnreadMessagesTotalCount` and cursor to aid in pagination. */
  edges: Array<UnreadMessagesTotalCountsEdge>;
  /** A list of `UnreadMessagesTotalCount` objects. */
  nodes: Array<Maybe<UnreadMessagesTotalCount>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UnreadMessagesTotalCount` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `UnreadMessagesTotalCount` edge in the connection. */
export type UnreadMessagesTotalCountsEdge = {
  __typename?: 'UnreadMessagesTotalCountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UnreadMessagesTotalCount` at the end of the edge. */
  node?: Maybe<UnreadMessagesTotalCount>;
};

/** Methods to use when ordering `UnreadMessagesTotalCount`. */
export enum UnreadMessagesTotalCountsOrderBy {
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  UnreadCountAsc = 'UNREAD_COUNT_ASC',
  UnreadCountDesc = 'UNREAD_COUNT_DESC',
}

export type UnreadThreadsCount = {
  __typename?: 'UnreadThreadsCount';
  /** Reads a single `Account` that is related to this `UnreadThreadsCount`. */
  account?: Maybe<Account>;
  publicKey?: Maybe<Scalars['String']['output']>;
  unreadCount?: Maybe<Scalars['BigInt']['output']>;
};

/**
 * A condition to be used against `UnreadThreadsCount` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UnreadThreadsCountCondition = {
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `unreadCount` field. */
  unreadCount?: InputMaybe<Scalars['BigInt']['input']>;
};

/** A filter to be used against `UnreadThreadsCount` object types. All fields are combined with a logical ‘and.’ */
export type UnreadThreadsCountFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UnreadThreadsCountFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<UnreadThreadsCountFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UnreadThreadsCountFilter>>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `unreadCount` field. */
  unreadCount?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `UnreadThreadsCount` values. */
export type UnreadThreadsCountsConnection = {
  __typename?: 'UnreadThreadsCountsConnection';
  /** A list of edges which contains the `UnreadThreadsCount` and cursor to aid in pagination. */
  edges: Array<UnreadThreadsCountsEdge>;
  /** A list of `UnreadThreadsCount` objects. */
  nodes: Array<Maybe<UnreadThreadsCount>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UnreadThreadsCount` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `UnreadThreadsCount` edge in the connection. */
export type UnreadThreadsCountsEdge = {
  __typename?: 'UnreadThreadsCountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UnreadThreadsCount` at the end of the edge. */
  node?: Maybe<UnreadThreadsCount>;
};

/** Methods to use when ordering `UnreadThreadsCount`. */
export enum UnreadThreadsCountsOrderBy {
  Natural = 'NATURAL',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  UnreadCountAsc = 'UNREAD_COUNT_ASC',
  UnreadCountDesc = 'UNREAD_COUNT_DESC',
}

/** All input for the `updateInteractionsWithNewBlocks` mutation. */
export type UpdateInteractionsWithNewBlocksInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `updateInteractionsWithNewBlocks` mutation. */
export type UpdateInteractionsWithNewBlocksPayload = {
  __typename?: 'UpdateInteractionsWithNewBlocksPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updatePostScoresWithNewBlocks` mutation. */
export type UpdatePostScoresWithNewBlocksInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `updatePostScoresWithNewBlocks` mutation. */
export type UpdatePostScoresWithNewBlocksPayload = {
  __typename?: 'UpdatePostScoresWithNewBlocksPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateUserScoresWithNewBlocks` mutation. */
export type UpdateUserScoresWithNewBlocksInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionalPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `updateUserScoresWithNewBlocks` mutation. */
export type UpdateUserScoresWithNewBlocksPayload = {
  __typename?: 'UpdateUserScoresWithNewBlocksPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `usdCentsToDiamondLevel` mutation. */
export type UsdCentsToDiamondLevelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  usdCents?: InputMaybe<Scalars['BigInt']['input']>;
};

/** The output of our `usdCentsToDiamondLevel` mutation. */
export type UsdCentsToDiamondLevelPayload = {
  __typename?: 'UsdCentsToDiamondLevelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  integer?: Maybe<Scalars['Int']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type UserAssociation = {
  __typename?: 'UserAssociation';
  /** Reads a single `AccessGroup` that is related to this `UserAssociation`. */
  accessGroup?: Maybe<AccessGroup>;
  accessGroupKeyName?: Maybe<Scalars['String']['output']>;
  accessGroupOwnerPublicKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `UserAssociation` that is related to this `UserAssociation`. */
  activeFeed?: Maybe<UserAssociation>;
  activeFeedForeignKey?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `UserAssociation`. */
  activeFeedSubscriptions: UserAssociationsConnection;
  /** Reads a single `FeedUserAssociation` that is related to this `UserAssociation`. */
  activeFeedUserAssociation?: Maybe<FeedUserAssociation>;
  /** Reads a single `Account` that is related to this `UserAssociation`. */
  app?: Maybe<Account>;
  appPkid?: Maybe<Scalars['String']['output']>;
  associationId?: Maybe<Scalars['String']['output']>;
  associationType: Scalars['String']['output'];
  associationValue: Scalars['String']['output'];
  badgerKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Block` that is related to this `UserAssociation`. */
  block?: Maybe<Block>;
  blockHeight?: Maybe<Scalars['Int']['output']>;
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** Reads and enables pagination through a set of `FeedUserAssociation`. */
  feedAssociationForSettings: FeedUserAssociationsConnection;
  /** Reads and enables pagination through a set of `FeedUserAssociation`. */
  feedAssociationForSubTier: FeedUserAssociationsConnection;
  /** Reads a single `UserAssociation` that is related to this `UserAssociation`. */
  feedDefinition?: Maybe<UserAssociation>;
  /** Reads and enables pagination through a set of `UserAssociation`. */
  feedDefinitionForeignKeys: UserAssociationsConnection;
  feedForeignKey?: Maybe<Scalars['String']['output']>;
  feedId?: Maybe<Scalars['String']['output']>;
  feedPublicKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `UserAssociation` that is related to this `UserAssociation`. */
  feedSettings?: Maybe<UserAssociation>;
  feedSettingsForeignKey?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `UserAssociation`. */
  feedSettingsForeignKeys: UserAssociationsConnection;
  /** Reads a single `UserAssociation` that is related to this `UserAssociation`. */
  feedSubscriptionTier?: Maybe<UserAssociation>;
  paymentCadenceDays?: Maybe<Scalars['Int']['output']>;
  paymentRecipientPublicKey?: Maybe<Scalars['String']['output']>;
  paymentSenderPublicKey?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Transaction`. */
  paymentTransactions: TransactionsConnection;
  paymentUuid?: Maybe<Scalars['String']['output']>;
  subscriberPublicKey?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `UserAssociation`. */
  subscriptionFeed: UserAssociationsConnection;
  subscriptionPaymentTimestamp?: Maybe<Scalars['Datetime']['output']>;
  subscriptionStartTimestamp?: Maybe<Scalars['Datetime']['output']>;
  subscriptionTierForeignKey?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Account` that is related to this `UserAssociation`. */
  target?: Maybe<Account>;
  targetFocusTokenBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  targetTotalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  targetUsdBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  targetUserPkid?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Account` that is related to this `UserAssociation`. */
  transactor?: Maybe<Account>;
  transactorFocusTokenBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  transactorPkid?: Maybe<Scalars['String']['output']>;
  transactorTotalBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
  transactorUsdBalanceUsdCents?: Maybe<Scalars['Float']['output']>;
};

export type UserAssociationActiveFeedSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

export type UserAssociationFeedAssociationForSettingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedUserAssociationCondition>;
  filter?: InputMaybe<FeedUserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedUserAssociationsOrderBy>>;
};

export type UserAssociationFeedAssociationForSubTierArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<FeedUserAssociationCondition>;
  filter?: InputMaybe<FeedUserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedUserAssociationsOrderBy>>;
};

export type UserAssociationFeedDefinitionForeignKeysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

export type UserAssociationFeedSettingsForeignKeysArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

export type UserAssociationPaymentTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

export type UserAssociationSubscriptionFeedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserAssociationCondition>;
  filter?: InputMaybe<UserAssociationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAssociationsOrderBy>>;
};

/**
 * A condition to be used against `UserAssociation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserAssociationCondition = {
  /** Checks for equality with the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `activeFeedForeignKey` field. */
  activeFeedForeignKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `appPkid` field. */
  appPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationId` field. */
  associationId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationType` field. */
  associationType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `associationValue` field. */
  associationValue?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `feedForeignKey` field. */
  feedForeignKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `feedPublicKey` field. */
  feedPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `feedSettingsForeignKey` field. */
  feedSettingsForeignKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `paymentCadenceDays` field. */
  paymentCadenceDays?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `paymentRecipientPublicKey` field. */
  paymentRecipientPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `paymentSenderPublicKey` field. */
  paymentSenderPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `paymentUuid` field. */
  paymentUuid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `subscriberPublicKey` field. */
  subscriberPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `subscriptionPaymentTimestamp` field. */
  subscriptionPaymentTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `subscriptionStartTimestamp` field. */
  subscriptionStartTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `subscriptionTierForeignKey` field. */
  subscriptionTierForeignKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `targetFocusTokenBalanceUsdCents` field. */
  targetFocusTokenBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `targetTotalBalanceUsdCents` field. */
  targetTotalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `targetUsdBalanceUsdCents` field. */
  targetUsdBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `targetUserPkid` field. */
  targetUserPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transactorFocusTokenBalanceUsdCents` field. */
  transactorFocusTokenBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transactorTotalBalanceUsdCents` field. */
  transactorTotalBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `transactorUsdBalanceUsdCents` field. */
  transactorUsdBalanceUsdCents?: InputMaybe<Scalars['Float']['input']>;
};

/** A filter to be used against `UserAssociation` object types. All fields are combined with a logical ‘and.’ */
export type UserAssociationFilter = {
  /** Filter by the object’s `accessGroup` relation. */
  accessGroup?: InputMaybe<AccessGroupFilter>;
  /** A related `accessGroup` exists. */
  accessGroupExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `accessGroupKeyName` field. */
  accessGroupKeyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accessGroupOwnerPublicKey` field. */
  accessGroupOwnerPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `activeFeed` relation. */
  activeFeed?: InputMaybe<UserAssociationFilter>;
  /** A related `activeFeed` exists. */
  activeFeedExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `activeFeedForeignKey` field. */
  activeFeedForeignKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `activeFeedSubscriptions` relation. */
  activeFeedSubscriptions?: InputMaybe<UserAssociationToManyUserAssociationFilter>;
  /** Some related `activeFeedSubscriptions` exist. */
  activeFeedSubscriptionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `activeFeedUserAssociation` relation. */
  activeFeedUserAssociation?: InputMaybe<FeedUserAssociationFilter>;
  /** A related `activeFeedUserAssociation` exists. */
  activeFeedUserAssociationExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserAssociationFilter>>;
  /** Filter by the object’s `app` relation. */
  app?: InputMaybe<AccountFilter>;
  /** A related `app` exists. */
  appExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `appPkid` field. */
  appPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationId` field. */
  associationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationType` field. */
  associationType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `associationValue` field. */
  associationValue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `block` relation. */
  block?: InputMaybe<BlockFilter>;
  /** A related `block` exists. */
  blockExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<IntFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `feedAssociationForSettings` relation. */
  feedAssociationForSettings?: InputMaybe<UserAssociationToManyFeedUserAssociationFilter>;
  /** Some related `feedAssociationForSettings` exist. */
  feedAssociationForSettingsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feedAssociationForSubTier` relation. */
  feedAssociationForSubTier?: InputMaybe<UserAssociationToManyFeedUserAssociationFilter>;
  /** Some related `feedAssociationForSubTier` exist. */
  feedAssociationForSubTierExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feedDefinition` relation. */
  feedDefinition?: InputMaybe<UserAssociationFilter>;
  /** A related `feedDefinition` exists. */
  feedDefinitionExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feedDefinitionForeignKeys` relation. */
  feedDefinitionForeignKeys?: InputMaybe<UserAssociationToManyUserAssociationFilter>;
  /** Some related `feedDefinitionForeignKeys` exist. */
  feedDefinitionForeignKeysExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feedForeignKey` field. */
  feedForeignKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedId` field. */
  feedId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedPublicKey` field. */
  feedPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedSettings` relation. */
  feedSettings?: InputMaybe<UserAssociationFilter>;
  /** A related `feedSettings` exists. */
  feedSettingsExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feedSettingsForeignKey` field. */
  feedSettingsForeignKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedSettingsForeignKeys` relation. */
  feedSettingsForeignKeys?: InputMaybe<UserAssociationToManyUserAssociationFilter>;
  /** Some related `feedSettingsForeignKeys` exist. */
  feedSettingsForeignKeysExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feedSubscriptionTier` relation. */
  feedSubscriptionTier?: InputMaybe<UserAssociationFilter>;
  /** A related `feedSubscriptionTier` exists. */
  feedSubscriptionTierExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not?: InputMaybe<UserAssociationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserAssociationFilter>>;
  /** Filter by the object’s `paymentCadenceDays` field. */
  paymentCadenceDays?: InputMaybe<IntFilter>;
  /** Filter by the object’s `paymentRecipientPublicKey` field. */
  paymentRecipientPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `paymentSenderPublicKey` field. */
  paymentSenderPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `paymentTransactions` relation. */
  paymentTransactions?: InputMaybe<UserAssociationToManyTransactionFilter>;
  /** Some related `paymentTransactions` exist. */
  paymentTransactionsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `paymentUuid` field. */
  paymentUuid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subscriberPublicKey` field. */
  subscriberPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subscriptionFeed` relation. */
  subscriptionFeed?: InputMaybe<UserAssociationToManyUserAssociationFilter>;
  /** Some related `subscriptionFeed` exist. */
  subscriptionFeedExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `subscriptionPaymentTimestamp` field. */
  subscriptionPaymentTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `subscriptionStartTimestamp` field. */
  subscriptionStartTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `subscriptionTierForeignKey` field. */
  subscriptionTierForeignKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `target` relation. */
  target?: InputMaybe<AccountFilter>;
  /** A related `target` exists. */
  targetExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `targetFocusTokenBalanceUsdCents` field. */
  targetFocusTokenBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `targetTotalBalanceUsdCents` field. */
  targetTotalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `targetUsdBalanceUsdCents` field. */
  targetUsdBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `targetUserPkid` field. */
  targetUserPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactor` relation. */
  transactor?: InputMaybe<AccountFilter>;
  /** A related `transactor` exists. */
  transactorExists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `transactorFocusTokenBalanceUsdCents` field. */
  transactorFocusTokenBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `transactorPkid` field. */
  transactorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactorTotalBalanceUsdCents` field. */
  transactorTotalBalanceUsdCents?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `transactorUsdBalanceUsdCents` field. */
  transactorUsdBalanceUsdCents?: InputMaybe<FloatFilter>;
};

/** A filter to be used against many `FeedUserAssociation` object types. All fields are combined with a logical ‘and.’ */
export type UserAssociationToManyFeedUserAssociationFilter = {
  /** Every related `FeedUserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FeedUserAssociationFilter>;
  /** No related `FeedUserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FeedUserAssociationFilter>;
  /** Some related `FeedUserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FeedUserAssociationFilter>;
};

/** A filter to be used against many `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type UserAssociationToManyTransactionFilter = {
  /** Every related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TransactionFilter>;
  /** No related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TransactionFilter>;
  /** Some related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TransactionFilter>;
};

/** A filter to be used against many `UserAssociation` object types. All fields are combined with a logical ‘and.’ */
export type UserAssociationToManyUserAssociationFilter = {
  /** Every related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserAssociationFilter>;
  /** No related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserAssociationFilter>;
  /** Some related `UserAssociation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserAssociationFilter>;
};

/** A connection to a list of `UserAssociation` values. */
export type UserAssociationsConnection = {
  __typename?: 'UserAssociationsConnection';
  /** A list of edges which contains the `UserAssociation` and cursor to aid in pagination. */
  edges: Array<UserAssociationsEdge>;
  /** A list of `UserAssociation` objects. */
  nodes: Array<Maybe<UserAssociation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserAssociation` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `UserAssociation` edge in the connection. */
export type UserAssociationsEdge = {
  __typename?: 'UserAssociationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UserAssociation` at the end of the edge. */
  node?: Maybe<UserAssociation>;
};

/** Methods to use when ordering `UserAssociation`. */
export enum UserAssociationsOrderBy {
  AccessGroupKeyNameAsc = 'ACCESS_GROUP_KEY_NAME_ASC',
  AccessGroupKeyNameDesc = 'ACCESS_GROUP_KEY_NAME_DESC',
  AccessGroupOwnerPublicKeyAsc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_ASC',
  AccessGroupOwnerPublicKeyDesc = 'ACCESS_GROUP_OWNER_PUBLIC_KEY_DESC',
  ActiveFeedForeignKeyAsc = 'ACTIVE_FEED_FOREIGN_KEY_ASC',
  ActiveFeedForeignKeyDesc = 'ACTIVE_FEED_FOREIGN_KEY_DESC',
  AppPkidAsc = 'APP_PKID_ASC',
  AppPkidDesc = 'APP_PKID_DESC',
  AssociationIdAsc = 'ASSOCIATION_ID_ASC',
  AssociationIdDesc = 'ASSOCIATION_ID_DESC',
  AssociationTypeAsc = 'ASSOCIATION_TYPE_ASC',
  AssociationTypeDesc = 'ASSOCIATION_TYPE_DESC',
  AssociationValueAsc = 'ASSOCIATION_VALUE_ASC',
  AssociationValueDesc = 'ASSOCIATION_VALUE_DESC',
  BadgerKeyAsc = 'BADGER_KEY_ASC',
  BadgerKeyDesc = 'BADGER_KEY_DESC',
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  FeedForeignKeyAsc = 'FEED_FOREIGN_KEY_ASC',
  FeedForeignKeyDesc = 'FEED_FOREIGN_KEY_DESC',
  FeedIdAsc = 'FEED_ID_ASC',
  FeedIdDesc = 'FEED_ID_DESC',
  FeedPublicKeyAsc = 'FEED_PUBLIC_KEY_ASC',
  FeedPublicKeyDesc = 'FEED_PUBLIC_KEY_DESC',
  FeedSettingsForeignKeyAsc = 'FEED_SETTINGS_FOREIGN_KEY_ASC',
  FeedSettingsForeignKeyDesc = 'FEED_SETTINGS_FOREIGN_KEY_DESC',
  Natural = 'NATURAL',
  PaymentCadenceDaysAsc = 'PAYMENT_CADENCE_DAYS_ASC',
  PaymentCadenceDaysDesc = 'PAYMENT_CADENCE_DAYS_DESC',
  PaymentRecipientPublicKeyAsc = 'PAYMENT_RECIPIENT_PUBLIC_KEY_ASC',
  PaymentRecipientPublicKeyDesc = 'PAYMENT_RECIPIENT_PUBLIC_KEY_DESC',
  PaymentSenderPublicKeyAsc = 'PAYMENT_SENDER_PUBLIC_KEY_ASC',
  PaymentSenderPublicKeyDesc = 'PAYMENT_SENDER_PUBLIC_KEY_DESC',
  PaymentUuidAsc = 'PAYMENT_UUID_ASC',
  PaymentUuidDesc = 'PAYMENT_UUID_DESC',
  SubscriberPublicKeyAsc = 'SUBSCRIBER_PUBLIC_KEY_ASC',
  SubscriberPublicKeyDesc = 'SUBSCRIBER_PUBLIC_KEY_DESC',
  SubscriptionPaymentTimestampAsc = 'SUBSCRIPTION_PAYMENT_TIMESTAMP_ASC',
  SubscriptionPaymentTimestampDesc = 'SUBSCRIPTION_PAYMENT_TIMESTAMP_DESC',
  SubscriptionStartTimestampAsc = 'SUBSCRIPTION_START_TIMESTAMP_ASC',
  SubscriptionStartTimestampDesc = 'SUBSCRIPTION_START_TIMESTAMP_DESC',
  SubscriptionTierForeignKeyAsc = 'SUBSCRIPTION_TIER_FOREIGN_KEY_ASC',
  SubscriptionTierForeignKeyDesc = 'SUBSCRIPTION_TIER_FOREIGN_KEY_DESC',
  TargetFocusTokenBalanceUsdCentsAsc = 'TARGET_FOCUS_TOKEN_BALANCE_USD_CENTS_ASC',
  TargetFocusTokenBalanceUsdCentsDesc = 'TARGET_FOCUS_TOKEN_BALANCE_USD_CENTS_DESC',
  TargetTotalBalanceUsdCentsAsc = 'TARGET_TOTAL_BALANCE_USD_CENTS_ASC',
  TargetTotalBalanceUsdCentsDesc = 'TARGET_TOTAL_BALANCE_USD_CENTS_DESC',
  TargetUsdBalanceUsdCentsAsc = 'TARGET_USD_BALANCE_USD_CENTS_ASC',
  TargetUsdBalanceUsdCentsDesc = 'TARGET_USD_BALANCE_USD_CENTS_DESC',
  TargetUserPkidAsc = 'TARGET_USER_PKID_ASC',
  TargetUserPkidDesc = 'TARGET_USER_PKID_DESC',
  TransactorFocusTokenBalanceUsdCentsAsc = 'TRANSACTOR_FOCUS_TOKEN_BALANCE_USD_CENTS_ASC',
  TransactorFocusTokenBalanceUsdCentsDesc = 'TRANSACTOR_FOCUS_TOKEN_BALANCE_USD_CENTS_DESC',
  TransactorPkidAsc = 'TRANSACTOR_PKID_ASC',
  TransactorPkidDesc = 'TRANSACTOR_PKID_DESC',
  TransactorTotalBalanceUsdCentsAsc = 'TRANSACTOR_TOTAL_BALANCE_USD_CENTS_ASC',
  TransactorTotalBalanceUsdCentsDesc = 'TRANSACTOR_TOTAL_BALANCE_USD_CENTS_DESC',
  TransactorUsdBalanceUsdCentsAsc = 'TRANSACTOR_USD_BALANCE_USD_CENTS_ASC',
  TransactorUsdBalanceUsdCentsDesc = 'TRANSACTOR_USD_BALANCE_USD_CENTS_DESC',
}

export type UserDaoCoinStat = {
  __typename?: 'UserDaoCoinStat';
  apyBasisPoints?: Maybe<Scalars['BigInt']['output']>;
  circulatingMarketCapUsd?: Maybe<Scalars['Float']['output']>;
  circulatingSupply?: Maybe<Scalars['BigFloat']['output']>;
  coinCategory?: Maybe<Scalars['String']['output']>;
  coinDisplayName?: Maybe<Scalars['String']['output']>;
  coinPkid?: Maybe<Scalars['String']['output']>;
  coinProfilePicUrl?: Maybe<Scalars['String']['output']>;
  coinPublicKey?: Maybe<Scalars['String']['output']>;
  coinUsername?: Maybe<Scalars['String']['output']>;
  isUserWhitelisted?: Maybe<Scalars['Boolean']['output']>;
  marketCapUsd?: Maybe<Scalars['Float']['output']>;
  numHolders?: Maybe<Scalars['Int']['output']>;
  priceUsd?: Maybe<Scalars['Float']['output']>;
  priceUsdPctChange1H?: Maybe<Scalars['Float']['output']>;
  priceUsdPctChange7D?: Maybe<Scalars['Float']['output']>;
  priceUsdPctChange24H?: Maybe<Scalars['Float']['output']>;
  priceUsdPctChange30D?: Maybe<Scalars['Float']['output']>;
  totalSupply?: Maybe<Scalars['BigFloat']['output']>;
  userBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  userBalanceUsd?: Maybe<Scalars['Float']['output']>;
  userBalanceUsdChange24H?: Maybe<Scalars['Float']['output']>;
  userLockedBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  userLockedBalanceUsd?: Maybe<Scalars['Float']['output']>;
  userPublicKey?: Maybe<Scalars['String']['output']>;
  userUnlockedBalanceNanos?: Maybe<Scalars['BigFloat']['output']>;
  userUnlockedBalanceUsd?: Maybe<Scalars['Float']['output']>;
  volumeUsdPast1H?: Maybe<Scalars['Float']['output']>;
  volumeUsdPast7D?: Maybe<Scalars['Float']['output']>;
  volumeUsdPast24H?: Maybe<Scalars['Float']['output']>;
  volumeUsdPast30D?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `UserDaoCoinStat` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserDaoCoinStatCondition = {
  /** Checks for equality with the object’s `apyBasisPoints` field. */
  apyBasisPoints?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `circulatingMarketCapUsd` field. */
  circulatingMarketCapUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `circulatingSupply` field. */
  circulatingSupply?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `coinCategory` field. */
  coinCategory?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `coinDisplayName` field. */
  coinDisplayName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `coinPkid` field. */
  coinPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `coinProfilePicUrl` field. */
  coinProfilePicUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `coinPublicKey` field. */
  coinPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `coinUsername` field. */
  coinUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isUserWhitelisted` field. */
  isUserWhitelisted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `marketCapUsd` field. */
  marketCapUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `numHolders` field. */
  numHolders?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `priceUsd` field. */
  priceUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `priceUsdPctChange1H` field. */
  priceUsdPctChange1H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `priceUsdPctChange7D` field. */
  priceUsdPctChange7D?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `priceUsdPctChange24H` field. */
  priceUsdPctChange24H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `priceUsdPctChange30D` field. */
  priceUsdPctChange30D?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `totalSupply` field. */
  totalSupply?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `userBalanceNanos` field. */
  userBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `userBalanceUsd` field. */
  userBalanceUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `userBalanceUsdChange24H` field. */
  userBalanceUsdChange24H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `userLockedBalanceNanos` field. */
  userLockedBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `userLockedBalanceUsd` field. */
  userLockedBalanceUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `userPublicKey` field. */
  userPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `userUnlockedBalanceNanos` field. */
  userUnlockedBalanceNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `userUnlockedBalanceUsd` field. */
  userUnlockedBalanceUsd?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `volumeUsdPast1H` field. */
  volumeUsdPast1H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `volumeUsdPast7D` field. */
  volumeUsdPast7D?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `volumeUsdPast24H` field. */
  volumeUsdPast24H?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `volumeUsdPast30D` field. */
  volumeUsdPast30D?: InputMaybe<Scalars['Float']['input']>;
};

/** A filter to be used against `UserDaoCoinStat` object types. All fields are combined with a logical ‘and.’ */
export type UserDaoCoinStatFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserDaoCoinStatFilter>>;
  /** Filter by the object’s `apyBasisPoints` field. */
  apyBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `circulatingMarketCapUsd` field. */
  circulatingMarketCapUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `circulatingSupply` field. */
  circulatingSupply?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `coinCategory` field. */
  coinCategory?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coinDisplayName` field. */
  coinDisplayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coinPkid` field. */
  coinPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coinProfilePicUrl` field. */
  coinProfilePicUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coinPublicKey` field. */
  coinPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coinUsername` field. */
  coinUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isUserWhitelisted` field. */
  isUserWhitelisted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `marketCapUsd` field. */
  marketCapUsd?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserDaoCoinStatFilter>;
  /** Filter by the object’s `numHolders` field. */
  numHolders?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserDaoCoinStatFilter>>;
  /** Filter by the object’s `priceUsd` field. */
  priceUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `priceUsdPctChange1H` field. */
  priceUsdPctChange1H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `priceUsdPctChange7D` field. */
  priceUsdPctChange7D?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `priceUsdPctChange24H` field. */
  priceUsdPctChange24H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `priceUsdPctChange30D` field. */
  priceUsdPctChange30D?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `totalSupply` field. */
  totalSupply?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `userBalanceNanos` field. */
  userBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `userBalanceUsd` field. */
  userBalanceUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `userBalanceUsdChange24H` field. */
  userBalanceUsdChange24H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `userLockedBalanceNanos` field. */
  userLockedBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `userLockedBalanceUsd` field. */
  userLockedBalanceUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `userPublicKey` field. */
  userPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userUnlockedBalanceNanos` field. */
  userUnlockedBalanceNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `userUnlockedBalanceUsd` field. */
  userUnlockedBalanceUsd?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `volumeUsdPast1H` field. */
  volumeUsdPast1H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `volumeUsdPast7D` field. */
  volumeUsdPast7D?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `volumeUsdPast24H` field. */
  volumeUsdPast24H?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `volumeUsdPast30D` field. */
  volumeUsdPast30D?: InputMaybe<FloatFilter>;
};

/** A connection to a list of `UserDaoCoinStat` values. */
export type UserDaoCoinStatsConnection = {
  __typename?: 'UserDaoCoinStatsConnection';
  /** A list of edges which contains the `UserDaoCoinStat` and cursor to aid in pagination. */
  edges: Array<UserDaoCoinStatsEdge>;
  /** A list of `UserDaoCoinStat` objects. */
  nodes: Array<Maybe<UserDaoCoinStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserDaoCoinStat` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `UserDaoCoinStat` edge in the connection. */
export type UserDaoCoinStatsEdge = {
  __typename?: 'UserDaoCoinStatsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UserDaoCoinStat` at the end of the edge. */
  node?: Maybe<UserDaoCoinStat>;
};

/** Methods to use when ordering `UserDaoCoinStat`. */
export enum UserDaoCoinStatsOrderBy {
  ApyBasisPointsAsc = 'APY_BASIS_POINTS_ASC',
  ApyBasisPointsDesc = 'APY_BASIS_POINTS_DESC',
  CirculatingMarketCapUsdAsc = 'CIRCULATING_MARKET_CAP_USD_ASC',
  CirculatingMarketCapUsdDesc = 'CIRCULATING_MARKET_CAP_USD_DESC',
  CirculatingSupplyAsc = 'CIRCULATING_SUPPLY_ASC',
  CirculatingSupplyDesc = 'CIRCULATING_SUPPLY_DESC',
  CoinCategoryAsc = 'COIN_CATEGORY_ASC',
  CoinCategoryDesc = 'COIN_CATEGORY_DESC',
  CoinDisplayNameAsc = 'COIN_DISPLAY_NAME_ASC',
  CoinDisplayNameDesc = 'COIN_DISPLAY_NAME_DESC',
  CoinPkidAsc = 'COIN_PKID_ASC',
  CoinPkidDesc = 'COIN_PKID_DESC',
  CoinProfilePicUrlAsc = 'COIN_PROFILE_PIC_URL_ASC',
  CoinProfilePicUrlDesc = 'COIN_PROFILE_PIC_URL_DESC',
  CoinPublicKeyAsc = 'COIN_PUBLIC_KEY_ASC',
  CoinPublicKeyDesc = 'COIN_PUBLIC_KEY_DESC',
  CoinUsernameAsc = 'COIN_USERNAME_ASC',
  CoinUsernameDesc = 'COIN_USERNAME_DESC',
  IsUserWhitelistedAsc = 'IS_USER_WHITELISTED_ASC',
  IsUserWhitelistedDesc = 'IS_USER_WHITELISTED_DESC',
  MarketCapUsdAsc = 'MARKET_CAP_USD_ASC',
  MarketCapUsdDesc = 'MARKET_CAP_USD_DESC',
  Natural = 'NATURAL',
  NumHoldersAsc = 'NUM_HOLDERS_ASC',
  NumHoldersDesc = 'NUM_HOLDERS_DESC',
  PriceUsdAsc = 'PRICE_USD_ASC',
  PriceUsdDesc = 'PRICE_USD_DESC',
  PriceUsdPctChange_1HAsc = 'PRICE_USD_PCT_CHANGE_1H_ASC',
  PriceUsdPctChange_1HDesc = 'PRICE_USD_PCT_CHANGE_1H_DESC',
  PriceUsdPctChange_7DAsc = 'PRICE_USD_PCT_CHANGE_7D_ASC',
  PriceUsdPctChange_7DDesc = 'PRICE_USD_PCT_CHANGE_7D_DESC',
  PriceUsdPctChange_24HAsc = 'PRICE_USD_PCT_CHANGE_24H_ASC',
  PriceUsdPctChange_24HDesc = 'PRICE_USD_PCT_CHANGE_24H_DESC',
  PriceUsdPctChange_30DAsc = 'PRICE_USD_PCT_CHANGE_30D_ASC',
  PriceUsdPctChange_30DDesc = 'PRICE_USD_PCT_CHANGE_30D_DESC',
  TotalSupplyAsc = 'TOTAL_SUPPLY_ASC',
  TotalSupplyDesc = 'TOTAL_SUPPLY_DESC',
  UserBalanceNanosAsc = 'USER_BALANCE_NANOS_ASC',
  UserBalanceNanosDesc = 'USER_BALANCE_NANOS_DESC',
  UserBalanceUsdAsc = 'USER_BALANCE_USD_ASC',
  UserBalanceUsdChange_24HAsc = 'USER_BALANCE_USD_CHANGE_24H_ASC',
  UserBalanceUsdChange_24HDesc = 'USER_BALANCE_USD_CHANGE_24H_DESC',
  UserBalanceUsdDesc = 'USER_BALANCE_USD_DESC',
  UserLockedBalanceNanosAsc = 'USER_LOCKED_BALANCE_NANOS_ASC',
  UserLockedBalanceNanosDesc = 'USER_LOCKED_BALANCE_NANOS_DESC',
  UserLockedBalanceUsdAsc = 'USER_LOCKED_BALANCE_USD_ASC',
  UserLockedBalanceUsdDesc = 'USER_LOCKED_BALANCE_USD_DESC',
  UserPublicKeyAsc = 'USER_PUBLIC_KEY_ASC',
  UserPublicKeyDesc = 'USER_PUBLIC_KEY_DESC',
  UserUnlockedBalanceNanosAsc = 'USER_UNLOCKED_BALANCE_NANOS_ASC',
  UserUnlockedBalanceNanosDesc = 'USER_UNLOCKED_BALANCE_NANOS_DESC',
  UserUnlockedBalanceUsdAsc = 'USER_UNLOCKED_BALANCE_USD_ASC',
  UserUnlockedBalanceUsdDesc = 'USER_UNLOCKED_BALANCE_USD_DESC',
  VolumeUsdPast_1HAsc = 'VOLUME_USD_PAST_1H_ASC',
  VolumeUsdPast_1HDesc = 'VOLUME_USD_PAST_1H_DESC',
  VolumeUsdPast_7DAsc = 'VOLUME_USD_PAST_7D_ASC',
  VolumeUsdPast_7DDesc = 'VOLUME_USD_PAST_7D_DESC',
  VolumeUsdPast_24HAsc = 'VOLUME_USD_PAST_24H_ASC',
  VolumeUsdPast_24HDesc = 'VOLUME_USD_PAST_24H_DESC',
  VolumeUsdPast_30DAsc = 'VOLUME_USD_PAST_30D_ASC',
  VolumeUsdPast_30DDesc = 'VOLUME_USD_PAST_30D_DESC',
}

export type UserModeration = {
  __typename?: 'UserModeration';
  associationId?: Maybe<Scalars['String']['output']>;
  blockHeight?: Maybe<Scalars['Int']['output']>;
  moderationReason?: Maybe<Scalars['String']['output']>;
  moderationTag?: Maybe<Scalars['String']['output']>;
  moderatorDisplayName?: Maybe<Scalars['String']['output']>;
  moderatorPublicKey?: Maybe<Scalars['String']['output']>;
  moderatorUsername?: Maybe<Scalars['String']['output']>;
  targetUserDisplayName?: Maybe<Scalars['String']['output']>;
  targetUserPublicKey?: Maybe<Scalars['String']['output']>;
  targetUserUsername?: Maybe<Scalars['String']['output']>;
};

/**
 * A condition to be used against `UserModeration` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserModerationCondition = {
  /** Checks for equality with the object’s `associationId` field. */
  associationId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `moderationReason` field. */
  moderationReason?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `moderationTag` field. */
  moderationTag?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `moderatorDisplayName` field. */
  moderatorDisplayName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `moderatorPublicKey` field. */
  moderatorPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `moderatorUsername` field. */
  moderatorUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `targetUserDisplayName` field. */
  targetUserDisplayName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `targetUserPublicKey` field. */
  targetUserPublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `targetUserUsername` field. */
  targetUserUsername?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `UserModeration` object types. All fields are combined with a logical ‘and.’ */
export type UserModerationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserModerationFilter>>;
  /** Filter by the object’s `associationId` field. */
  associationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<IntFilter>;
  /** Filter by the object’s `moderationReason` field. */
  moderationReason?: InputMaybe<StringFilter>;
  /** Filter by the object’s `moderationTag` field. */
  moderationTag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `moderatorDisplayName` field. */
  moderatorDisplayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `moderatorPublicKey` field. */
  moderatorPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `moderatorUsername` field. */
  moderatorUsername?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserModerationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserModerationFilter>>;
  /** Filter by the object’s `targetUserDisplayName` field. */
  targetUserDisplayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `targetUserPublicKey` field. */
  targetUserPublicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `targetUserUsername` field. */
  targetUserUsername?: InputMaybe<StringFilter>;
};

/** A connection to a list of `UserModeration` values. */
export type UserModerationsConnection = {
  __typename?: 'UserModerationsConnection';
  /** A list of edges which contains the `UserModeration` and cursor to aid in pagination. */
  edges: Array<UserModerationsEdge>;
  /** A list of `UserModeration` objects. */
  nodes: Array<Maybe<UserModeration>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserModeration` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `UserModeration` edge in the connection. */
export type UserModerationsEdge = {
  __typename?: 'UserModerationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UserModeration` at the end of the edge. */
  node?: Maybe<UserModeration>;
};

/** Methods to use when ordering `UserModeration`. */
export enum UserModerationsOrderBy {
  AssociationIdAsc = 'ASSOCIATION_ID_ASC',
  AssociationIdDesc = 'ASSOCIATION_ID_DESC',
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  ModerationReasonAsc = 'MODERATION_REASON_ASC',
  ModerationReasonDesc = 'MODERATION_REASON_DESC',
  ModerationTagAsc = 'MODERATION_TAG_ASC',
  ModerationTagDesc = 'MODERATION_TAG_DESC',
  ModeratorDisplayNameAsc = 'MODERATOR_DISPLAY_NAME_ASC',
  ModeratorDisplayNameDesc = 'MODERATOR_DISPLAY_NAME_DESC',
  ModeratorPublicKeyAsc = 'MODERATOR_PUBLIC_KEY_ASC',
  ModeratorPublicKeyDesc = 'MODERATOR_PUBLIC_KEY_DESC',
  ModeratorUsernameAsc = 'MODERATOR_USERNAME_ASC',
  ModeratorUsernameDesc = 'MODERATOR_USERNAME_DESC',
  Natural = 'NATURAL',
  TargetUserDisplayNameAsc = 'TARGET_USER_DISPLAY_NAME_ASC',
  TargetUserDisplayNameDesc = 'TARGET_USER_DISPLAY_NAME_DESC',
  TargetUserPublicKeyAsc = 'TARGET_USER_PUBLIC_KEY_ASC',
  TargetUserPublicKeyDesc = 'TARGET_USER_PUBLIC_KEY_DESC',
  TargetUserUsernameAsc = 'TARGET_USER_USERNAME_ASC',
  TargetUserUsernameDesc = 'TARGET_USER_USERNAME_DESC',
}

export type UserRelationshipCustomWeight = {
  __typename?: 'UserRelationshipCustomWeight';
  blockWeight?: Maybe<Scalars['Float']['output']>;
  followCountWeight?: Maybe<Scalars['Float']['output']>;
  negativeReactionWeight?: Maybe<Scalars['Float']['output']>;
  neutralReactionWeight?: Maybe<Scalars['Float']['output']>;
  positiveReactionWeight?: Maybe<Scalars['Float']['output']>;
  publicKey: Scalars['String']['output'];
  quoteWeight?: Maybe<Scalars['Float']['output']>;
  replyWeight?: Maybe<Scalars['Float']['output']>;
  reportWeight?: Maybe<Scalars['Float']['output']>;
  repostWeight?: Maybe<Scalars['Float']['output']>;
  showLessWeight?: Maybe<Scalars['Float']['output']>;
  tipWeight?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `UserRelationshipCustomWeight` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type UserRelationshipCustomWeightCondition = {
  /** Checks for equality with the object’s `blockWeight` field. */
  blockWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `followCountWeight` field. */
  followCountWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `negativeReactionWeight` field. */
  negativeReactionWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `neutralReactionWeight` field. */
  neutralReactionWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `positiveReactionWeight` field. */
  positiveReactionWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `publicKey` field. */
  publicKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `quoteWeight` field. */
  quoteWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `replyWeight` field. */
  replyWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `reportWeight` field. */
  reportWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `repostWeight` field. */
  repostWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `showLessWeight` field. */
  showLessWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `tipWeight` field. */
  tipWeight?: InputMaybe<Scalars['Float']['input']>;
};

/** A filter to be used against `UserRelationshipCustomWeight` object types. All fields are combined with a logical ‘and.’ */
export type UserRelationshipCustomWeightFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserRelationshipCustomWeightFilter>>;
  /** Filter by the object’s `blockWeight` field. */
  blockWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `followCountWeight` field. */
  followCountWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `negativeReactionWeight` field. */
  negativeReactionWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `neutralReactionWeight` field. */
  neutralReactionWeight?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserRelationshipCustomWeightFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserRelationshipCustomWeightFilter>>;
  /** Filter by the object’s `positiveReactionWeight` field. */
  positiveReactionWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `publicKey` field. */
  publicKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `quoteWeight` field. */
  quoteWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `replyWeight` field. */
  replyWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `reportWeight` field. */
  reportWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `repostWeight` field. */
  repostWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `showLessWeight` field. */
  showLessWeight?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `tipWeight` field. */
  tipWeight?: InputMaybe<FloatFilter>;
};

/** A connection to a list of `UserRelationshipCustomWeight` values. */
export type UserRelationshipCustomWeightsConnection = {
  __typename?: 'UserRelationshipCustomWeightsConnection';
  /** A list of edges which contains the `UserRelationshipCustomWeight` and cursor to aid in pagination. */
  edges: Array<UserRelationshipCustomWeightsEdge>;
  /** A list of `UserRelationshipCustomWeight` objects. */
  nodes: Array<Maybe<UserRelationshipCustomWeight>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserRelationshipCustomWeight` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `UserRelationshipCustomWeight` edge in the connection. */
export type UserRelationshipCustomWeightsEdge = {
  __typename?: 'UserRelationshipCustomWeightsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UserRelationshipCustomWeight` at the end of the edge. */
  node?: Maybe<UserRelationshipCustomWeight>;
};

/** Methods to use when ordering `UserRelationshipCustomWeight`. */
export enum UserRelationshipCustomWeightsOrderBy {
  BlockWeightAsc = 'BLOCK_WEIGHT_ASC',
  BlockWeightDesc = 'BLOCK_WEIGHT_DESC',
  FollowCountWeightAsc = 'FOLLOW_COUNT_WEIGHT_ASC',
  FollowCountWeightDesc = 'FOLLOW_COUNT_WEIGHT_DESC',
  Natural = 'NATURAL',
  NegativeReactionWeightAsc = 'NEGATIVE_REACTION_WEIGHT_ASC',
  NegativeReactionWeightDesc = 'NEGATIVE_REACTION_WEIGHT_DESC',
  NeutralReactionWeightAsc = 'NEUTRAL_REACTION_WEIGHT_ASC',
  NeutralReactionWeightDesc = 'NEUTRAL_REACTION_WEIGHT_DESC',
  PositiveReactionWeightAsc = 'POSITIVE_REACTION_WEIGHT_ASC',
  PositiveReactionWeightDesc = 'POSITIVE_REACTION_WEIGHT_DESC',
  PublicKeyAsc = 'PUBLIC_KEY_ASC',
  PublicKeyDesc = 'PUBLIC_KEY_DESC',
  QuoteWeightAsc = 'QUOTE_WEIGHT_ASC',
  QuoteWeightDesc = 'QUOTE_WEIGHT_DESC',
  ReplyWeightAsc = 'REPLY_WEIGHT_ASC',
  ReplyWeightDesc = 'REPLY_WEIGHT_DESC',
  ReportWeightAsc = 'REPORT_WEIGHT_ASC',
  ReportWeightDesc = 'REPORT_WEIGHT_DESC',
  RepostWeightAsc = 'REPOST_WEIGHT_ASC',
  RepostWeightDesc = 'REPOST_WEIGHT_DESC',
  ShowLessWeightAsc = 'SHOW_LESS_WEIGHT_ASC',
  ShowLessWeightDesc = 'SHOW_LESS_WEIGHT_DESC',
  TipWeightAsc = 'TIP_WEIGHT_ASC',
  TipWeightDesc = 'TIP_WEIGHT_DESC',
}

export type UserScoreUpdate = Node & {
  __typename?: 'UserScoreUpdate';
  blockHeight?: Maybe<Scalars['BigInt']['output']>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  rowId: Scalars['Int']['output'];
};

/**
 * A condition to be used against `UserScoreUpdate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserScoreUpdateCondition = {
  /** Checks for equality with the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `UserScoreUpdate` object types. All fields are combined with a logical ‘and.’ */
export type UserScoreUpdateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserScoreUpdateFilter>>;
  /** Filter by the object’s `blockHeight` field. */
  blockHeight?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserScoreUpdateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserScoreUpdateFilter>>;
  /** Filter by the object’s `rowId` field. */
  rowId?: InputMaybe<IntFilter>;
};

/** A connection to a list of `UserScoreUpdate` values. */
export type UserScoreUpdatesConnection = {
  __typename?: 'UserScoreUpdatesConnection';
  /** A list of edges which contains the `UserScoreUpdate` and cursor to aid in pagination. */
  edges: Array<UserScoreUpdatesEdge>;
  /** A list of `UserScoreUpdate` objects. */
  nodes: Array<Maybe<UserScoreUpdate>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserScoreUpdate` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `UserScoreUpdate` edge in the connection. */
export type UserScoreUpdatesEdge = {
  __typename?: 'UserScoreUpdatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UserScoreUpdate` at the end of the edge. */
  node?: Maybe<UserScoreUpdate>;
};

/** Methods to use when ordering `UserScoreUpdate`. */
export enum UserScoreUpdatesOrderBy {
  BlockHeightAsc = 'BLOCK_HEIGHT_ASC',
  BlockHeightDesc = 'BLOCK_HEIGHT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type UtxoOperation = {
  __typename?: 'UtxoOperation';
  badgerKey: Scalars['String']['output'];
  blockHash: Scalars['String']['output'];
  operationType: Scalars['Int']['output'];
  /** Reads a single `Transaction` that is related to this `UtxoOperation`. */
  transaction?: Maybe<Transaction>;
  transactionIndex: Scalars['Int']['output'];
  utxoOpBytes: Scalars['String']['output'];
  utxoOpIndex: Scalars['Int']['output'];
};

/**
 * A condition to be used against `UtxoOperation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UtxoOperationCondition = {
  /** Checks for equality with the object’s `badgerKey` field. */
  badgerKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `blockHash` field. */
  blockHash?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `operationType` field. */
  operationType?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `transactionIndex` field. */
  transactionIndex?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `utxoOpBytes` field. */
  utxoOpBytes?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `utxoOpIndex` field. */
  utxoOpIndex?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `UtxoOperation` object types. All fields are combined with a logical ‘and.’ */
export type UtxoOperationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UtxoOperationFilter>>;
  /** Filter by the object’s `blockHash` field. */
  blockHash?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UtxoOperationFilter>;
  /** Filter by the object’s `operationType` field. */
  operationType?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UtxoOperationFilter>>;
  /** Filter by the object’s `transaction` relation. */
  transaction?: InputMaybe<TransactionFilter>;
  /** Filter by the object’s `transactionIndex` field. */
  transactionIndex?: InputMaybe<IntFilter>;
  /** Filter by the object’s `utxoOpIndex` field. */
  utxoOpIndex?: InputMaybe<IntFilter>;
};

/** A connection to a list of `UtxoOperation` values. */
export type UtxoOperationsConnection = {
  __typename?: 'UtxoOperationsConnection';
  /** A list of edges which contains the `UtxoOperation` and cursor to aid in pagination. */
  edges: Array<UtxoOperationsEdge>;
  /** A list of `UtxoOperation` objects. */
  nodes: Array<Maybe<UtxoOperation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UtxoOperation` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `UtxoOperation` edge in the connection. */
export type UtxoOperationsEdge = {
  __typename?: 'UtxoOperationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UtxoOperation` at the end of the edge. */
  node?: Maybe<UtxoOperation>;
};

/** Methods to use when ordering `UtxoOperation`. */
export enum UtxoOperationsOrderBy {
  BadgerKeyAsc = 'BADGER_KEY_ASC',
  BadgerKeyDesc = 'BADGER_KEY_DESC',
  BlockHashAsc = 'BLOCK_HASH_ASC',
  BlockHashDesc = 'BLOCK_HASH_DESC',
  Natural = 'NATURAL',
  OperationTypeAsc = 'OPERATION_TYPE_ASC',
  OperationTypeDesc = 'OPERATION_TYPE_DESC',
  TransactionIndexAsc = 'TRANSACTION_INDEX_ASC',
  TransactionIndexDesc = 'TRANSACTION_INDEX_DESC',
  UtxoOpBytesAsc = 'UTXO_OP_BYTES_ASC',
  UtxoOpBytesDesc = 'UTXO_OP_BYTES_DESC',
  UtxoOpIndexAsc = 'UTXO_OP_INDEX_ASC',
  UtxoOpIndexDesc = 'UTXO_OP_INDEX_DESC',
}

/** A connection to a list of `ValidatorEntry` values. */
export type ValidatorEntriesConnection = {
  __typename?: 'ValidatorEntriesConnection';
  /** A list of edges which contains the `ValidatorEntry` and cursor to aid in pagination. */
  edges: Array<ValidatorEntriesEdge>;
  /** A list of `ValidatorEntry` objects. */
  nodes: Array<Maybe<ValidatorEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ValidatorEntry` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `ValidatorEntry` edge in the connection. */
export type ValidatorEntriesEdge = {
  __typename?: 'ValidatorEntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `ValidatorEntry` at the end of the edge. */
  node?: Maybe<ValidatorEntry>;
};

/** Methods to use when ordering `ValidatorEntry`. */
export enum ValidatorEntriesOrderBy {
  DelegatedStakeCommissionBasisPointsAsc = 'DELEGATED_STAKE_COMMISSION_BASIS_POINTS_ASC',
  DelegatedStakeCommissionBasisPointsDesc = 'DELEGATED_STAKE_COMMISSION_BASIS_POINTS_DESC',
  DisableDelegatedStakeAsc = 'DISABLE_DELEGATED_STAKE_ASC',
  DisableDelegatedStakeDesc = 'DISABLE_DELEGATED_STAKE_DESC',
  DomainsAsc = 'DOMAINS_ASC',
  DomainsDesc = 'DOMAINS_DESC',
  ExtraDataAsc = 'EXTRA_DATA_ASC',
  ExtraDataDesc = 'EXTRA_DATA_DESC',
  JailedAtEpochNumberAsc = 'JAILED_AT_EPOCH_NUMBER_ASC',
  JailedAtEpochNumberDesc = 'JAILED_AT_EPOCH_NUMBER_DESC',
  LastActiveAtEpochNumberAsc = 'LAST_ACTIVE_AT_EPOCH_NUMBER_ASC',
  LastActiveAtEpochNumberDesc = 'LAST_ACTIVE_AT_EPOCH_NUMBER_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TotalStakeAmountNanosAsc = 'TOTAL_STAKE_AMOUNT_NANOS_ASC',
  TotalStakeAmountNanosDesc = 'TOTAL_STAKE_AMOUNT_NANOS_DESC',
  ValidatorPkidAsc = 'VALIDATOR_PKID_ASC',
  ValidatorPkidDesc = 'VALIDATOR_PKID_DESC',
  VotingAuthorizationAsc = 'VOTING_AUTHORIZATION_ASC',
  VotingAuthorizationDesc = 'VOTING_AUTHORIZATION_DESC',
  VotingPublicKeyAsc = 'VOTING_PUBLIC_KEY_ASC',
  VotingPublicKeyDesc = 'VOTING_PUBLIC_KEY_DESC',
}

export type ValidatorEntry = Node & {
  __typename?: 'ValidatorEntry';
  /** Reads a single `Account` that is related to this `ValidatorEntry`. */
  account?: Maybe<Account>;
  delegatedStakeCommissionBasisPoints?: Maybe<Scalars['BigInt']['output']>;
  disableDelegatedStake?: Maybe<Scalars['Boolean']['output']>;
  domains?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  extraData?: Maybe<Scalars['JSON']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  jailedAtEpochNumber?: Maybe<Scalars['BigInt']['output']>;
  /** Reads and enables pagination through a set of `JailedHistoryEvent`. */
  jailedHistoryEvents: JailedHistoryEventsConnection;
  lastActiveAtEpochNumber?: Maybe<Scalars['BigInt']['output']>;
  /** Reads and enables pagination through a set of `LeaderScheduleEntry`. */
  leaderScheduleEntries: LeaderScheduleEntriesConnection;
  /** Reads and enables pagination through a set of `StakeEntry`. */
  stakeEntries: StakeEntriesConnection;
  totalStakeAmountNanos: Scalars['BigFloat']['output'];
  /** Reads and enables pagination through a set of `LockedStakeEntry`. */
  validatorLockedStakeEntries: LockedStakeEntriesConnection;
  validatorPkid: Scalars['String']['output'];
  votingAuthorization?: Maybe<Scalars['String']['output']>;
  votingPublicKey?: Maybe<Scalars['String']['output']>;
};

export type ValidatorEntryJailedHistoryEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<JailedHistoryEventCondition>;
  filter?: InputMaybe<JailedHistoryEventFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JailedHistoryEventsOrderBy>>;
};

export type ValidatorEntryLeaderScheduleEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LeaderScheduleEntryCondition>;
  filter?: InputMaybe<LeaderScheduleEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LeaderScheduleEntriesOrderBy>>;
};

export type ValidatorEntryStakeEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<StakeEntryCondition>;
  filter?: InputMaybe<StakeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StakeEntriesOrderBy>>;
};

export type ValidatorEntryValidatorLockedStakeEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LockedStakeEntryCondition>;
  filter?: InputMaybe<LockedStakeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LockedStakeEntriesOrderBy>>;
};

/**
 * A condition to be used against `ValidatorEntry` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ValidatorEntryCondition = {
  /** Checks for equality with the object’s `delegatedStakeCommissionBasisPoints` field. */
  delegatedStakeCommissionBasisPoints?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `disableDelegatedStake` field. */
  disableDelegatedStake?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `domains` field. */
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `extraData` field. */
  extraData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `jailedAtEpochNumber` field. */
  jailedAtEpochNumber?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `lastActiveAtEpochNumber` field. */
  lastActiveAtEpochNumber?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `totalStakeAmountNanos` field. */
  totalStakeAmountNanos?: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `votingAuthorization` field. */
  votingAuthorization?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `votingPublicKey` field. */
  votingPublicKey?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `ValidatorEntry` object types. All fields are combined with a logical ‘and.’ */
export type ValidatorEntryFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ValidatorEntryFilter>>;
  /** Filter by the object’s `delegatedStakeCommissionBasisPoints` field. */
  delegatedStakeCommissionBasisPoints?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `disableDelegatedStake` field. */
  disableDelegatedStake?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `domains` field. */
  domains?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `extraData` field. */
  extraData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `jailedAtEpochNumber` field. */
  jailedAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `jailedHistoryEvents` relation. */
  jailedHistoryEvents?: InputMaybe<ValidatorEntryToManyJailedHistoryEventFilter>;
  /** Some related `jailedHistoryEvents` exist. */
  jailedHistoryEventsExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `lastActiveAtEpochNumber` field. */
  lastActiveAtEpochNumber?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `leaderScheduleEntries` relation. */
  leaderScheduleEntries?: InputMaybe<ValidatorEntryToManyLeaderScheduleEntryFilter>;
  /** Some related `leaderScheduleEntries` exist. */
  leaderScheduleEntriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not?: InputMaybe<ValidatorEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ValidatorEntryFilter>>;
  /** Filter by the object’s `stakeEntries` relation. */
  stakeEntries?: InputMaybe<ValidatorEntryToManyStakeEntryFilter>;
  /** Some related `stakeEntries` exist. */
  stakeEntriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `totalStakeAmountNanos` field. */
  totalStakeAmountNanos?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `validatorLockedStakeEntries` relation. */
  validatorLockedStakeEntries?: InputMaybe<ValidatorEntryToManyLockedStakeEntryFilter>;
  /** Some related `validatorLockedStakeEntries` exist. */
  validatorLockedStakeEntriesExist?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `validatorPkid` field. */
  validatorPkid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `votingAuthorization` field. */
  votingAuthorization?: InputMaybe<StringFilter>;
  /** Filter by the object’s `votingPublicKey` field. */
  votingPublicKey?: InputMaybe<StringFilter>;
};

/** A filter to be used against many `JailedHistoryEvent` object types. All fields are combined with a logical ‘and.’ */
export type ValidatorEntryToManyJailedHistoryEventFilter = {
  /** Every related `JailedHistoryEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<JailedHistoryEventFilter>;
  /** No related `JailedHistoryEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<JailedHistoryEventFilter>;
  /** Some related `JailedHistoryEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<JailedHistoryEventFilter>;
};

/** A filter to be used against many `LeaderScheduleEntry` object types. All fields are combined with a logical ‘and.’ */
export type ValidatorEntryToManyLeaderScheduleEntryFilter = {
  /** Every related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LeaderScheduleEntryFilter>;
  /** No related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LeaderScheduleEntryFilter>;
  /** Some related `LeaderScheduleEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LeaderScheduleEntryFilter>;
};

/** A filter to be used against many `LockedStakeEntry` object types. All fields are combined with a logical ‘and.’ */
export type ValidatorEntryToManyLockedStakeEntryFilter = {
  /** Every related `LockedStakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LockedStakeEntryFilter>;
  /** No related `LockedStakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LockedStakeEntryFilter>;
  /** Some related `LockedStakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LockedStakeEntryFilter>;
};

/** A filter to be used against many `StakeEntry` object types. All fields are combined with a logical ‘and.’ */
export type ValidatorEntryToManyStakeEntryFilter = {
  /** Every related `StakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<StakeEntryFilter>;
  /** No related `StakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<StakeEntryFilter>;
  /** Some related `StakeEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<StakeEntryFilter>;
};

export type YieldCurvePoint = Node & {
  __typename?: 'YieldCurvePoint';
  /** Reads a single `Account` that is related to this `YieldCurvePoint`. */
  account?: Maybe<Account>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID']['output'];
  lockupDurationNanoSecs: Scalars['BigInt']['output'];
  lockupYieldApyBasisPoints: Scalars['BigInt']['output'];
  profilePkid: Scalars['String']['output'];
};

/**
 * A condition to be used against `YieldCurvePoint` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type YieldCurvePointCondition = {
  /** Checks for equality with the object’s `lockupDurationNanoSecs` field. */
  lockupDurationNanoSecs?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `lockupYieldApyBasisPoints` field. */
  lockupYieldApyBasisPoints?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `profilePkid` field. */
  profilePkid?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `YieldCurvePoint` object types. All fields are combined with a logical ‘and.’ */
export type YieldCurvePointFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<YieldCurvePointFilter>>;
  /** Filter by the object’s `lockupDurationNanoSecs` field. */
  lockupDurationNanoSecs?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `lockupYieldApyBasisPoints` field. */
  lockupYieldApyBasisPoints?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<YieldCurvePointFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<YieldCurvePointFilter>>;
  /** Filter by the object’s `profilePkid` field. */
  profilePkid?: InputMaybe<StringFilter>;
};

/** A connection to a list of `YieldCurvePoint` values. */
export type YieldCurvePointsConnection = {
  __typename?: 'YieldCurvePointsConnection';
  /** A list of edges which contains the `YieldCurvePoint` and cursor to aid in pagination. */
  edges: Array<YieldCurvePointsEdge>;
  /** A list of `YieldCurvePoint` objects. */
  nodes: Array<Maybe<YieldCurvePoint>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `YieldCurvePoint` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `YieldCurvePoint` edge in the connection. */
export type YieldCurvePointsEdge = {
  __typename?: 'YieldCurvePointsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `YieldCurvePoint` at the end of the edge. */
  node?: Maybe<YieldCurvePoint>;
};

/** Methods to use when ordering `YieldCurvePoint`. */
export enum YieldCurvePointsOrderBy {
  LockupDurationNanoSecsAsc = 'LOCKUP_DURATION_NANO_SECS_ASC',
  LockupDurationNanoSecsDesc = 'LOCKUP_DURATION_NANO_SECS_DESC',
  LockupYieldApyBasisPointsAsc = 'LOCKUP_YIELD_APY_BASIS_POINTS_ASC',
  LockupYieldApyBasisPointsDesc = 'LOCKUP_YIELD_APY_BASIS_POINTS_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProfilePkidAsc = 'PROFILE_PKID_ASC',
  ProfilePkidDesc = 'PROFILE_PKID_DESC',
}

export type TokenLimitOrderFieldsFragment = {
  __typename?: 'DesoTokenLimitOrder';
  operationType?: number | null;
  fillType?: number | null;
  scaledExchangeRateCoinsToSellPerCoinToBuyNumeric?: any | null;
  quantityToFillInBaseUnitsNumeric?: any | null;
  orderId?: string | null;
  blockHeight?: any | null;
  buyingDaoCoinCreatorPkid?: string | null;
  sellingDaoCoinCreatorPkid?: string | null;
  transactorAccount?: {
    __typename?: 'Account';
    publicKey: string;
    desoBalance?: { __typename?: 'DesoBalance'; balanceNanos: any } | null;
  } | null;
  creatorSoldAccount?: { __typename?: 'Account'; username?: string | null; publicKey: string } | null;
  creatorBoughtAccount?: { __typename?: 'Account'; username?: string | null; publicKey: string } | null;
};

export type TokenLimitOrdersDesoBuyingQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy> | DesoTokenLimitOrdersOrderBy>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
}>;

export type TokenLimitOrdersDesoBuyingQuery = {
  __typename?: 'Query';
  desoTokenLimitOrders?: {
    __typename?: 'DesoTokenLimitOrdersConnection';
    nodes: Array<{
      __typename?: 'DesoTokenLimitOrder';
      operationType?: number | null;
      fillType?: number | null;
      scaledExchangeRateCoinsToSellPerCoinToBuyNumeric?: any | null;
      quantityToFillInBaseUnitsNumeric?: any | null;
      orderId?: string | null;
      blockHeight?: any | null;
      buyingDaoCoinCreatorPkid?: string | null;
      sellingDaoCoinCreatorPkid?: string | null;
      transactorSellingTokenBalance?: { __typename?: 'TokenBalance'; balanceNanos: any } | null;
      transactorAccount?: {
        __typename?: 'Account';
        publicKey: string;
        desoBalance?: { __typename?: 'DesoBalance'; balanceNanos: any } | null;
      } | null;
      creatorSoldAccount?: { __typename?: 'Account'; username?: string | null; publicKey: string } | null;
      creatorBoughtAccount?: { __typename?: 'Account'; username?: string | null; publicKey: string } | null;
    } | null>;
  } | null;
};

export type TokenLimitOrdersDesoSellingQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy> | DesoTokenLimitOrdersOrderBy>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
}>;

export type TokenLimitOrdersDesoSellingQuery = {
  __typename?: 'Query';
  desoTokenLimitOrders?: {
    __typename?: 'DesoTokenLimitOrdersConnection';
    nodes: Array<{
      __typename?: 'DesoTokenLimitOrder';
      operationType?: number | null;
      fillType?: number | null;
      scaledExchangeRateCoinsToSellPerCoinToBuyNumeric?: any | null;
      quantityToFillInBaseUnitsNumeric?: any | null;
      orderId?: string | null;
      blockHeight?: any | null;
      buyingDaoCoinCreatorPkid?: string | null;
      sellingDaoCoinCreatorPkid?: string | null;
      transactorBuyingTokenBalance?: { __typename?: 'TokenBalance'; balanceNanos: any } | null;
      transactorAccount?: {
        __typename?: 'Account';
        publicKey: string;
        desoBalance?: { __typename?: 'DesoBalance'; balanceNanos: any } | null;
      } | null;
      creatorSoldAccount?: { __typename?: 'Account'; username?: string | null; publicKey: string } | null;
      creatorBoughtAccount?: { __typename?: 'Account'; username?: string | null; publicKey: string } | null;
    } | null>;
  } | null;
};

export type TokenLimitOrdersNoDesoQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DesoTokenLimitOrdersOrderBy> | DesoTokenLimitOrdersOrderBy>;
  filter?: InputMaybe<DesoTokenLimitOrderFilter>;
}>;

export type TokenLimitOrdersNoDesoQuery = {
  __typename?: 'Query';
  desoTokenLimitOrders?: {
    __typename?: 'DesoTokenLimitOrdersConnection';
    nodes: Array<{
      __typename?: 'DesoTokenLimitOrder';
      operationType?: number | null;
      fillType?: number | null;
      scaledExchangeRateCoinsToSellPerCoinToBuyNumeric?: any | null;
      quantityToFillInBaseUnitsNumeric?: any | null;
      orderId?: string | null;
      blockHeight?: any | null;
      buyingDaoCoinCreatorPkid?: string | null;
      sellingDaoCoinCreatorPkid?: string | null;
      transactorSellingTokenBalance?: { __typename?: 'TokenBalance'; balanceNanos: any } | null;
      transactorBuyingTokenBalance?: { __typename?: 'TokenBalance'; balanceNanos: any } | null;
      transactorAccount?: {
        __typename?: 'Account';
        publicKey: string;
        desoBalance?: { __typename?: 'DesoBalance'; balanceNanos: any } | null;
      } | null;
      creatorSoldAccount?: { __typename?: 'Account'; username?: string | null; publicKey: string } | null;
      creatorBoughtAccount?: { __typename?: 'Account'; username?: string | null; publicKey: string } | null;
    } | null>;
  } | null;
};

export type TransactionByTransactionHashQueryVariables = Exact<{
  transactionHash: Scalars['String']['input'];
}>;

export type TransactionByTransactionHashQuery = {
  __typename?: 'Query';
  transactionByTransactionHash?: { __typename?: 'Transaction'; transactionHash?: string | null } | null;
};

export type TokenMarketVolumeSummariesQueryVariables = Exact<{
  marketVolumeFilter?: InputMaybe<TokenMarketVolumeSummaryFilter>;
}>;

export type TokenMarketVolumeSummariesQuery = {
  __typename?: 'Query';
  tokenMarketVolumeSummaries?: {
    __typename?: 'TokenMarketVolumeSummariesConnection';
    nodes: Array<{
      __typename?: 'TokenMarketVolumeSummary';
      midPriceUsdCents?: any | null;
      marketCapTokens?: any | null;
      marketCapUsdCents?: any | null;
      totalSupplyLockedNanos?: any | null;
      totalSupplyNanos?: any | null;
      totalSupplyUnlockedNanos?: any | null;
      unlockedMarketCapTokens?: any | null;
      unlockedMarketCapUsdCents?: any | null;
      lockedMarketCapTokens?: any | null;
      lockedMarketCapUsdCents?: any | null;
    } | null>;
  } | null;
};

export type TokenMarketVolumeSummariesExtendedQueryVariables = Exact<{
  marketVolumeFilter?: InputMaybe<TokenMarketVolumeSummaryFilter>;
  founderBalanceFilter?: InputMaybe<TokenBalanceSummaryFilter>;
  ammBalanceFilter?: InputMaybe<TokenBalanceSummaryFilter>;
  userBalanceFilter?: InputMaybe<TokenBalanceSummaryFilter>;
  lockedBalancesFirst?: InputMaybe<Scalars['Int']['input']>;
  lockedBalancesOrderBy?: InputMaybe<Array<LockedBalanceEntriesOrderBy> | LockedBalanceEntriesOrderBy>;
  founderUnlockFilter?: InputMaybe<LockedBalanceEntryFilter>;
}>;

export type TokenMarketVolumeSummariesExtendedQuery = {
  __typename?: 'Query';
  tokenMarketVolumeSummaries?: {
    __typename?: 'TokenMarketVolumeSummariesConnection';
    nodes: Array<{
      __typename?: 'TokenMarketVolumeSummary';
      id: string;
      midPriceUsdCents?: any | null;
      marketCapTokens?: any | null;
      marketCapUsdCents?: any | null;
      totalSupplyLockedNanos?: any | null;
      totalSupplyNanos?: any | null;
      totalSupplyUnlockedNanos?: any | null;
      unlockedMarketCapTokens?: any | null;
      unlockedMarketCapUsdCents?: any | null;
      lockedMarketCapTokens?: any | null;
      lockedMarketCapUsdCents?: any | null;
      hodlerCount?: any | null;
      midPrice?: any | null;
      coin1Account?: {
        __typename?: 'Account';
        publicKey: string;
        founderBalance: {
          __typename?: 'TokenBalanceSummariesConnection';
          nodes: Array<{
            __typename?: 'TokenBalanceSummary';
            lockedBalanceNanos?: any | null;
            unlockedBalanceNanos?: any | null;
            totalBalance?: any | null;
          } | null>;
        };
        ammBalance: {
          __typename?: 'TokenBalanceSummariesConnection';
          nodes: Array<{
            __typename?: 'TokenBalanceSummary';
            lockedBalanceNanos?: any | null;
            unlockedBalanceNanos?: any | null;
            totalBalance?: any | null;
          } | null>;
        };
        userBalance: {
          __typename?: 'TokenBalanceSummariesConnection';
          nodes: Array<{
            __typename?: 'TokenBalanceSummary';
            lockedBalanceNanos?: any | null;
            unlockedBalanceNanos?: any | null;
            totalBalance?: any | null;
          } | null>;
        };
        nextFounderUnlock: {
          __typename?: 'LockedBalanceEntriesConnection';
          nodes: Array<{ __typename?: 'LockedBalanceEntry'; unlockTimestampNanoSecs: any } | null>;
        };
        nextUnlock: {
          __typename?: 'LockedBalanceEntriesConnection';
          nodes: Array<{ __typename?: 'LockedBalanceEntry'; unlockTimestampNanoSecs: any } | null>;
        };
      } | null;
    } | null>;
  } | null;
};

export type DaoCoinStatQueryVariables = Exact<{
  coinPublicKey: Scalars['String']['input'];
}>;

export type DaoCoinStatQuery = {
  __typename?: 'Query';
  daoCoinStat?: {
    __typename?: 'DaoCoinStat';
    coinPublicKey: string;
    coinUsername?: string | null;
    coinCategory?: string | null;
    coinProfilePicUrl?: string | null;
    priceUsd?: number | null;
    marketCapUsd?: number | null;
    priceUsdPctChange24H?: number | null;
    volumeUsdPast24H?: number | null;
  } | null;
};

export type TradingRecentTradesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TradingRecentTradesOrderBy> | TradingRecentTradesOrderBy>;
  filter?: InputMaybe<TradingRecentTradeFilter>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type TradingRecentTradesQuery = {
  __typename?: 'Query';
  tradingRecentTrades?: {
    __typename?: 'TradingRecentTradesConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'TradingRecentTrade';
      denominatedCoinPublicKey?: string | null;
      tradeTimestamp?: any | null;
      tradeType?: string | null;
      traderPublicKey?: string | null;
      traderUsername?: string | null;
      traderDisplayName?: string | null;
      traderProfilePicUrl?: string | null;
      tokenPublicKey?: string | null;
      tokenUsername?: string | null;
      tokenProfilePicUrl?: string | null;
      tokenCategory?: string | null;
      tradeValueUsd?: number | null;
      tradeValueFocus?: number | null;
      tradeValueDeso?: number | null;
      tradePriceUsd?: number | null;
      tradePriceFocus?: number | null;
      tradePriceDeso?: number | null;
      tokenMarketCapUsd?: number | null;
      tokenMarketCapFocus?: number | null;
      txnHashHex?: string | null;
      tradeBuyCoinPublicKey?: string | null;
      tradeSellCoinPublicKey?: string | null;
      tradeBuyQuantity?: number | null;
      tradeSellQuantity?: number | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  } | null;
};

export type AccountsQueryVariables = Exact<{
  filter?: InputMaybe<AccountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy> | AccountsOrderBy>;
  includeAccounts: Scalars['Boolean']['input'];
}>;

export type AccountsQuery = {
  __typename?: 'Query';
  accounts?: {
    __typename?: 'AccountsConnection';
    nodes: Array<{
      __typename?: 'Account';
      id: string;
      username?: string | null;
      publicKey: string;
      extraData?: any | null;
      totalBalanceUsdCents?: number | null;
    } | null>;
  } | null;
};

export type AccountByUsernameQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type AccountByUsernameQuery = {
  __typename?: 'Query';
  accountByUsername?: {
    __typename?: 'Account';
    id: string;
    username?: string | null;
    publicKey: string;
    extraData?: any | null;
    totalBalanceUsdCents?: number | null;
  } | null;
};

export type DaoCoinStatsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<DaoCoinStatsOrderBy> | DaoCoinStatsOrderBy>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<DaoCoinStatFilter>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
}>;

export type DaoCoinStatsQuery = {
  __typename?: 'Query';
  id: string;
  daoCoinStats?: {
    __typename?: 'DaoCoinStatsConnection';
    nodes: Array<{
      __typename?: 'DaoCoinStat';
      coinPublicKey: string;
      coinUsername?: string | null;
      coinCategory?: string | null;
      coinProfilePicUrl?: string | null;
      priceUsd?: number | null;
      marketCapUsd?: number | null;
      priceUsdPctChange24H?: number | null;
      volumeUsdPast24H?: number | null;
    } | null>;
  } | null;
};

export type TokensByTotalVolumesQueryVariables = Exact<{
  filter?: InputMaybe<TokensByTotalVolumeFilter>;
  orderBy?: InputMaybe<Array<TokensByTotalVolumesOrderBy> | TokensByTotalVolumesOrderBy>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type TokensByTotalVolumesQuery = {
  __typename?: 'Query';
  tokensByTotalVolumes?: {
    __typename?: 'TokensByTotalVolumesConnection';
    nodes: Array<{
      __typename?: 'TokensByTotalVolume';
      tokenPublicKey?: string | null;
      tokenUsername?: string | null;
      tokenDisplayName?: string | null;
      tokenProfilePicUrl?: string | null;
      totalVolumeUsd?: number | null;
    } | null>;
  } | null;
};

export type UserAssociationsQueryVariables = Exact<{
  filter?: InputMaybe<UserAssociationFilter>;
}>;

export type UserAssociationsQuery = {
  __typename?: 'Query';
  userAssociations?: {
    __typename?: 'UserAssociationsConnection';
    nodes: Array<{
      __typename?: 'UserAssociation';
      associationId?: string | null;
      target?: { __typename?: 'Account'; publicKey: string; username?: string | null } | null;
    } | null>;
  } | null;
};

export const TokenLimitOrderFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TokenLimitOrderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesoTokenLimitOrder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'operationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scaledExchangeRateCoinsToSellPerCoinToBuyNumeric' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityToFillInBaseUnitsNumeric' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blockHeight' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactorAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'desoBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'balanceNanos' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorSoldAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'buyingDaoCoinCreatorPkid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingDaoCoinCreatorPkid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorBoughtAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TokenLimitOrderFieldsFragment, unknown>;
export const TokenLimitOrdersDesoBuyingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TokenLimitOrdersDesoBuying' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DesoTokenLimitOrdersOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DesoTokenLimitOrderFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'desoTokenLimitOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TokenLimitOrderFields' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transactorSellingTokenBalance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'balanceNanos' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TokenLimitOrderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesoTokenLimitOrder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'operationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scaledExchangeRateCoinsToSellPerCoinToBuyNumeric' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityToFillInBaseUnitsNumeric' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blockHeight' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactorAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'desoBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'balanceNanos' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorSoldAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'buyingDaoCoinCreatorPkid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingDaoCoinCreatorPkid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorBoughtAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TokenLimitOrdersDesoBuyingQuery, TokenLimitOrdersDesoBuyingQueryVariables>;
export const TokenLimitOrdersDesoSellingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TokenLimitOrdersDesoSelling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DesoTokenLimitOrdersOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DesoTokenLimitOrderFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'desoTokenLimitOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TokenLimitOrderFields' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transactorBuyingTokenBalance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'balanceNanos' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TokenLimitOrderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesoTokenLimitOrder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'operationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scaledExchangeRateCoinsToSellPerCoinToBuyNumeric' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityToFillInBaseUnitsNumeric' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blockHeight' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactorAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'desoBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'balanceNanos' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorSoldAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'buyingDaoCoinCreatorPkid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingDaoCoinCreatorPkid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorBoughtAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TokenLimitOrdersDesoSellingQuery, TokenLimitOrdersDesoSellingQueryVariables>;
export const TokenLimitOrdersNoDesoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TokenLimitOrdersNoDeso' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DesoTokenLimitOrdersOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DesoTokenLimitOrderFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'desoTokenLimitOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TokenLimitOrderFields' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transactorSellingTokenBalance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'balanceNanos' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transactorBuyingTokenBalance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'balanceNanos' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TokenLimitOrderFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesoTokenLimitOrder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'operationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fillType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scaledExchangeRateCoinsToSellPerCoinToBuyNumeric' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityToFillInBaseUnitsNumeric' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blockHeight' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactorAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'desoBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'balanceNanos' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorSoldAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'buyingDaoCoinCreatorPkid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellingDaoCoinCreatorPkid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creatorBoughtAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TokenLimitOrdersNoDesoQuery, TokenLimitOrdersNoDesoQueryVariables>;
export const TransactionByTransactionHashDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TransactionByTransactionHash' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transactionHash' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transactionByTransactionHash' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'transactionHash' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transactionHash' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'transactionHash' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionByTransactionHashQuery, TransactionByTransactionHashQueryVariables>;
export const TokenMarketVolumeSummariesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TokenMarketVolumeSummaries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'marketVolumeFilter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TokenMarketVolumeSummaryFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tokenMarketVolumeSummaries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'marketVolumeFilter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'midPriceUsdCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketCapTokens' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketCapUsdCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSupplyLockedNanos' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSupplyNanos' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSupplyUnlockedNanos' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unlockedMarketCapTokens' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unlockedMarketCapUsdCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lockedMarketCapTokens' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lockedMarketCapUsdCents' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TokenMarketVolumeSummariesQuery, TokenMarketVolumeSummariesQueryVariables>;
export const TokenMarketVolumeSummariesExtendedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TokenMarketVolumeSummariesExtended' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'marketVolumeFilter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TokenMarketVolumeSummaryFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'founderBalanceFilter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TokenBalanceSummaryFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ammBalanceFilter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TokenBalanceSummaryFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userBalanceFilter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TokenBalanceSummaryFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lockedBalancesFirst' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lockedBalancesOrderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'LockedBalanceEntriesOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'founderUnlockFilter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LockedBalanceEntryFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tokenMarketVolumeSummaries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'marketVolumeFilter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'midPriceUsdCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketCapTokens' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketCapUsdCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSupplyLockedNanos' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSupplyNanos' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSupplyUnlockedNanos' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unlockedMarketCapTokens' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unlockedMarketCapUsdCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lockedMarketCapTokens' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lockedMarketCapUsdCents' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hodlerCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'midPrice' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coin1Account' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'founderBalance' },
                              name: { kind: 'Name', value: 'tokenBalanceSummariesAsCreator' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'founderBalanceFilter' } },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'lockedBalanceNanos' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'unlockedBalanceNanos' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'totalBalance' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'ammBalance' },
                              name: { kind: 'Name', value: 'tokenBalanceSummariesAsCreator' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'ammBalanceFilter' } },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'lockedBalanceNanos' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'unlockedBalanceNanos' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'totalBalance' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'userBalance' },
                              name: { kind: 'Name', value: 'tokenBalanceSummariesAsCreator' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'userBalanceFilter' } },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'lockedBalanceNanos' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'unlockedBalanceNanos' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'totalBalance' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'nextFounderUnlock' },
                              name: { kind: 'Name', value: 'profileLockedBalanceEntries' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'first' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'lockedBalancesFirst' } },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'orderBy' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'lockedBalancesOrderBy' } },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'founderUnlockFilter' } },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'unlockTimestampNanoSecs' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'nextUnlock' },
                              name: { kind: 'Name', value: 'profileLockedBalanceEntries' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'first' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'lockedBalancesFirst' } },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'orderBy' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'lockedBalancesOrderBy' } },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'unlockTimestampNanoSecs' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TokenMarketVolumeSummariesExtendedQuery, TokenMarketVolumeSummariesExtendedQueryVariables>;
export const DaoCoinStatDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DaoCoinStat' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'coinPublicKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'daoCoinStat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'coinPublicKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'coinPublicKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coinPublicKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coinUsername' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coinCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coinProfilePicUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceUsd' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketCapUsd' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceUsdPctChange24H' } },
                { kind: 'Field', name: { kind: 'Name', value: 'volumeUsdPast24H' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DaoCoinStatQuery, DaoCoinStatQueryVariables>;
export const TradingRecentTradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TradingRecentTrades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'TradingRecentTradesOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TradingRecentTradeFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tradingRecentTrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'denominatedCoinPublicKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tradeTimestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tradeType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'traderPublicKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'traderUsername' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'traderDisplayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'traderProfilePicUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tokenPublicKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tokenUsername' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tokenProfilePicUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tokenCategory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tradeValueUsd' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tradeValueFocus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tradeValueDeso' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tradePriceUsd' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tradePriceFocus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tradePriceDeso' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tokenMarketCapUsd' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tokenMarketCapFocus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txnHashHex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tradeBuyCoinPublicKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tradeSellCoinPublicKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tradeBuyQuantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tradeSellQuantity' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TradingRecentTradesQuery, TradingRecentTradesQueryVariables>;
export const AccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Accounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountsOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeAccounts' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeAccounts' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'extraData' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalBalanceUsdCents' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountsQuery, AccountsQueryVariables>;
export const AccountByUsernameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountByUsername' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByUsername' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'username' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extraData' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalBalanceUsdCents' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountByUsernameQuery, AccountByUsernameQueryVariables>;
export const DaoCoinStatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DaoCoinStats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DaoCoinStatsOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DaoCoinStatFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'daoCoinStats' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'coinPublicKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinUsername' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinCategory' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinProfilePicUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceUsd' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketCapUsd' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceUsdPctChange24H' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'volumeUsdPast24H' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DaoCoinStatsQuery, DaoCoinStatsQueryVariables>;
export const TokensByTotalVolumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TokensByTotalVolumes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TokensByTotalVolumeFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'TokensByTotalVolumesOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tokensByTotalVolumes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'tokenPublicKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tokenUsername' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tokenDisplayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tokenProfilePicUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalVolumeUsd' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TokensByTotalVolumesQuery, TokensByTotalVolumesQueryVariables>;
export const UserAssociationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserAssociations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserAssociationFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAssociations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'associationId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'target' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserAssociationsQuery, UserAssociationsQueryVariables>;
