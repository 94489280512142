import { OpenfundUser } from 'services/Openfund';
import { baseUnitsToTokens } from 'utils/currency';
import { Account } from '../graphql/codegen/graphql';
import { getWrappedAsset, shortenLongWord } from './deso';

export function canCreateProfile(user: OpenfundUser | null) {
  return !!(user?.CanCreateProfile && user?.BalanceNanos > 30000);
}

export function getUSDBalance(user: OpenfundUser) {
  return baseUnitsToTokens(user.usdBalanceEntry?.BalanceNanosUint256.toString() ?? '0x0');
}

export function getUSDBalanceBaseUnits(user: OpenfundUser) {
  return BigInt(user.usdBalanceEntry?.BalanceNanosUint256.toString() ?? '0x0');
}

export const getUserName = ({
  customPrefix = '',
  fallback = 'N/A',
  account,
}: {
  customPrefix?: string;
  account?: Account | null;
  fallback?: string;
}) => {
  let username = account?.username;
  const publicKey = account?.publicKey || '';

  if (!username && fallback !== 'N/A') {
    return `${customPrefix}${fallback}`;
  }

  const wrappedAsset = getWrappedAsset(username || '');

  if (!!wrappedAsset) {
    username = wrappedAsset.displayName;
  }

  return (username ? `${customPrefix}${username}` : shortenLongWord(publicKey)) || `${customPrefix}${fallback}`;
};
