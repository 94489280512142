'use client';

import { ReactNode, useState } from 'react';
import { IoCloseCircle } from '@react-icons/all-files/io5/IoCloseCircle';
import { classNames } from '../../utils/classNames';

const bannerVariants = {
  error: 'bg-red-dark text-white',
};

const SiteBanner = ({
  id,
  variant = 'error',
  children,
  visible = true,
  onClose,
  contentClassName,
}: {
  id: string;
  variant: 'error'; // TODO: add other variants 'success' | 'info' | 'warning' | 'neutral'
  className?: string;
  children?: ReactNode;
  visible?: boolean;
  onClose?: () => void;
  contentClassName?: string;
}) => {
  const [isVisible, setIsVisible] = useState(visible);

  const handleClose = () => {
    setIsVisible(false);
    onClose?.();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={classNames('relative z-50 flex px-12 py-2 bg-red-800 text-red-500', bannerVariants[variant])}>
      <div className="absolute right-2 top-1/2 h-auto -translate-y-1/2 cursor-pointer p-1" onClick={handleClose}>
        <IoCloseCircle size={20} />
      </div>

      <div className={classNames('flex items-center justify-center w-full gap-1 text-sm', contentClassName)}>
        {children}
      </div>
    </div>
  );
};

export default SiteBanner;
