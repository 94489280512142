'use client';

import { useTheme } from '../../../contexts/ThemeContext';
import { useEffect, useState } from 'react';
import { LuFocus, LuLeaf, LuMoon, LuScanLine, LuSun, LuWifi, LuWifiOff, LuZap } from 'react-icons/lu';

import { Button } from './button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './dropdown-menu';
import { THEME_OPTIONS } from 'utils/constants';

export function ThemeToggle() {
  const { theme, setTheme } = useTheme();
  const [mounted, setMounted] = useState(false);

  // https://github.com/pacocoursey/next-themes?tab=readme-ov-file#avoid-hydration-mismatch
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  const getIcon = () => {
    switch (theme) {
      case THEME_OPTIONS.lightMode:
        return <LuWifiOff className="text-lg" />;
      case THEME_OPTIONS.darkMode:
        return <LuWifi className="text-lg" />;
      case THEME_OPTIONS.modernLightMode:
        return <LuSun className="text-lg" />;
      case THEME_OPTIONS.modernDarkMode:
        return <LuMoon className="text-lg" />;
      case THEME_OPTIONS.paperLightMode:
        return <LuLeaf className="text-lg" />;
      case THEME_OPTIONS.paperDarkMode:
        return <LuZap className="text-lg" />;
      case THEME_OPTIONS.radarDarkMode:
        return <LuFocus className="text-lg" />;
      case THEME_OPTIONS.radarLightMode:
        return <LuScanLine className="text-lg" />;
      default:
        return <LuSun className="text-lg" />;
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="px-3">
          {getIcon()}
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end">
        <DropdownMenuItem className="cursor-pointer" onClick={() => setTheme(THEME_OPTIONS.radarDarkMode)}>
          Radar Green
        </DropdownMenuItem>

        <DropdownMenuItem className="cursor-pointer" onClick={() => setTheme(THEME_OPTIONS.radarLightMode)}>
          Radar Red
        </DropdownMenuItem>

        <DropdownMenuItem className="cursor-pointer" onClick={() => setTheme(THEME_OPTIONS.lightMode)}>
          Classic Light
        </DropdownMenuItem>

        <DropdownMenuItem className="cursor-pointer" onClick={() => setTheme(THEME_OPTIONS.darkMode)}>
          Classic Dark
        </DropdownMenuItem>

        <DropdownMenuItem className="cursor-pointer" onClick={() => setTheme(THEME_OPTIONS.modernLightMode)}>
          Modern Light
        </DropdownMenuItem>

        <DropdownMenuItem className="cursor-pointer" onClick={() => setTheme(THEME_OPTIONS.modernDarkMode)}>
          Modern Dark
        </DropdownMenuItem>

        <DropdownMenuItem className="cursor-pointer" onClick={() => setTheme(THEME_OPTIONS.paperLightMode)}>
          Paper Light
        </DropdownMenuItem>

        <DropdownMenuItem className="cursor-pointer" onClick={() => setTheme(THEME_OPTIONS.paperDarkMode)}>
          Paper Dark
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
