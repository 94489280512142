import { App } from 'components/App';
import { NotFound } from 'components/app-ui/NotFound';
import { SiteLoader } from 'components/app-ui/SiteLoader';
import { SiteContainer } from 'components/layout/SiteContainer';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RoutePaths } from 'RoutePaths';

// code split and lazy load by page
const Home = lazy(() => import('components/pages/Home'));
const Activity = lazy(() => import('components/pages/Activity'));
const ProjectDetail = lazy(() => import('components/pages/ProjectDetail'));
const Discover = lazy(() => import('components/pages/Discover'));
const MyOrders = lazy(() => import('components/pages/MyOrders'));
const MyWallet = lazy(() => import('components/pages/MyWallet'));
const Notifications = lazy(() => import('components/pages/Notifications'));
const Onboarding = lazy(() => import('components/pages/Onboarding'));
const ProjectDetails = lazy(() => import('components/pages/Onboarding/ProjectDetails'));
const EnterEmailAddress = lazy(() => import('components/pages/Onboarding/EnterEmailAddress'));
const FollowTopProjects = lazy(() => import('components/pages/Onboarding/FollowTopProjects'));
const ProfileInfo = lazy(() => import('components/pages/Onboarding/ProfileInfo'));
const OnePager = lazy(() => import('components/pages/OnePager'));
const ProfileDetail = lazy(() => import('components/pages/ProfileDetail'));
const Settings = lazy(() => import('components/pages/Settings'));
const Advanced = lazy(() => import('components/pages/Settings/Advanced'));
const ProjectDistribution = lazy(() => import('components/pages/Settings/ProjectDistribution'));
const FundRaising = lazy(() => import('components/pages/Settings/FundRaising'));
const NotificationSettings = lazy(() => import('components/pages/Settings/Notifications'));
const Profile = lazy(() => import('components/pages/Settings/Profile'));
const Project = lazy(() => import('components/pages/Settings/Project'));
const Proposals = lazy(() => import('components/pages/Settings/Proposals'));
const Trade = lazy(() => import('components/pages/Trade'));
const VerifyEmail = lazy(() => import('components/pages/VerifyEmail'));

export function AppRouter() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="text-center mx-auto h-full flex flex-col justify-center">
            <SiteLoader />
          </div>
        }
      >
        <Routes>
          <Route path={RoutePaths.home.path} element={<App />}>
            <Route path={RoutePaths.home.path} element={<SiteContainer />}>
              <Route path={RoutePaths.home.path} element={<Home />} />
              <Route path={RoutePaths.discover.path} element={<Discover />} />
              <Route path={RoutePaths.activity.path} element={<Activity />} />
              <Route path={RoutePaths.activityDetail.path} element={<Activity />} />
              <Route path={RoutePaths.fund.path} element={<ProjectDetail />} />
              <Route path={RoutePaths.profile.path} element={<ProfileDetail />} />
              <Route path={RoutePaths.wallet.path} element={<MyWallet />} />
              <Route path={RoutePaths.orders.path} element={<MyOrders showAll={true} />} />
              <Route path={RoutePaths.trade.path} element={<Trade />} />
              <Route path={RoutePaths.tradeToken.path} element={<Trade />} />
              <Route path={RoutePaths.notifications.path} element={<Notifications />} />
              <Route path={RoutePaths.onePager.path} element={<OnePager />} />
              <Route path={RoutePaths.verifyEmail.path} element={<VerifyEmail />} />
              <Route path={RoutePaths.settings.path} element={<Settings />}>
                <Route index element={<Profile />} />
                <Route path={RoutePaths.settings.profile.path} element={<Profile />} />
                <Route path={RoutePaths.settings.project.path} element={<Project />} />
                <Route path={RoutePaths.settings.fundraising.path} element={<FundRaising />} />
                <Route path={RoutePaths.settings.fundraising.path} element={<FundRaising />} />
                <Route path={RoutePaths.settings.distribution.path} element={<ProjectDistribution />} />
                <Route path={RoutePaths.settings.proposals.path} element={<Proposals />} />
                <Route path={RoutePaths.settings.notifications.path} element={<NotificationSettings />} />
                <Route path={RoutePaths.settings.advanced.path} element={<Advanced />} />
              </Route>
            </Route>
            <Route path={RoutePaths.projectOnboarding.path} element={<Onboarding onboardingType="PROJECT" />}>
              {/* <Route index element={<EnterEmailAddress />} /> */}
              {/* <Route path={RoutePaths.projectOnboarding.email.path} element={<EnterEmailAddress />} /> */}
              <Route path={RoutePaths.projectOnboarding.profile.path} element={<ProfileInfo />} />
              <Route path={RoutePaths.projectOnboarding.details.path} element={<ProjectDetails />} />
            </Route>
            <Route path={RoutePaths.userOnboarding.path} element={<Onboarding onboardingType="USER" />}>
              {/* <Route index element={<EnterEmailAddress />} /> */}
              {/* <Route path={RoutePaths.userOnboarding.email.path} element={<EnterEmailAddress />} /> */}
              <Route path={RoutePaths.userOnboarding.profile.path} element={<ProfileInfo />} />
              <Route path={RoutePaths.userOnboarding.follow.path} element={<FollowTopProjects />} />
            </Route>
            <Route path="*" element={<SiteContainer />}>
              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
