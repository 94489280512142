import { AxiosInstance } from 'axios';
import { DESO_DOLLAR_PROFILE_NAME, GLOBAL_DESO_LIMIT_BUFFER } from 'constants/AppConstants';
import { DERIVED_KEY_PURPOSES } from 'constants/DerivedKeysConstants';
import { INVESTMENT_STATUS } from 'constants/InvestmentConstants';
import {
  BalanceEntryResponse,
  GetHodlersForPublicKeyResponse,
  PostEntryResponse,
  ProfileEntryResponse,
  SubmitPostResponse,
  TransactionSpendingLimitResponse,
  User,
  identity,
} from 'deso-protocol';
import { Deso } from 'services/Deso';
import { HeroSwapper } from 'services/HeroSwapper';
import Swal from 'sweetalert2';
import { toHex, tokenToBaseUnits } from 'utils/currency';
import { trackingIdentifyUser, trackingLogEvent } from 'utils/tracking';

interface CreateProfileParams {
  Username: string;
  Email: string;
  Description: string;
  FeaturedImageURL: string;
  DisplayName: string;
  WebsiteURL: string;
  TwitterURL: string;
  DiscordURL: string;
  MarkdownDescription: string;
  EnableDaoNFTGallery: boolean;
  ProfilePic?: string;
  TelegramURL?: string;
  PhoneNumber?: string;
}

// This is the amount of time we wait for a txn to be
// broadcast to all the nodes in our infrastructure
// TODO: We should be able to get rid of this if we get
// node affinity right
export const NODE_PROPAGATION_MILLIS = 1250;

export enum FUNDING_ROUND_STATUSES {
  OPEN = 'OPEN',
  PAUSED = 'PAUSED',
  FINALIZED = 'FINALIZED',
}

export interface FundingRound {
  IsPasswordProtected: boolean;
  AllowOverflow: boolean;
  AmountToRaiseUsdCents: number;
  AmountRaisedUSDCents: number;
  AmountRaisedDesoNanos: number;
  CreatedAt: string;
  StartDaoCoinBaseUnitsPerTreasuryUnitHex: string;
  TreasuryCurrencyUnit: 'DESO' | 'DAO_COIN';
  TreasuryDaoCoin: string;
  PriceIncreaseIncrementBasisPoints: number;
  PriceIncreaseUnit: string;
  PriceIncreaseAmount: number;
  DaoOwnerPkidBase58check: string;
  DerivedKeys: null;
  EndTime: string;
  GlobalReferralRateBasisPoints: number;
  IsInvestmentImmediatelyRefundable: boolean;
  OffChainDesoBalanceNanos: number;
  ReserveRateBasisPoints: number;
  RoundID: string;
  RoundName: string;
  RoundStatus: FUNDING_ROUND_STATUSES;
  StartTime: string;
  TermsAndConditions: string;
  DerivedPublicKeyBase58Check: string;
}

export interface Proposal {
  Id: string;
  UserPkidBase58Check: string;
  DaoUserPkidBase58Check: string;
  Status: string;
  Title: string;
  Description: string;
  PostHashHex: string;
  SummaryCommentHashHex: string;
  CreatedAt: string;
  DeletedAt: string;
  Options: ProposalOption[];
}

export interface ProposalTally {
  PollOption: string;
  PollOptionIndex: number;
  DaoCoinsVotedHex: string;
  PercentVotersInFavorBP: number;
  PercentDaoInFavorBP: number;
}

export interface ProposalTallyResult {
  PollOptionResults: ProposalTally[];
  TotalCoinsVotedHex: string;
  TotalCoinsInCirculationHex: string;
  TotalProfilesParticipating: number;
}

export interface ProposalOption {
  Id: string;
  ProposalId: string;
  Index: number;
  Description: string;
  CreatedAt: string;
  DeletedAt: string;
  Proposal: Proposal;
}

export interface SubmittedPosts {
  [key: string]: SubmittedPost;
}

export interface SubmittedPost {
  PostEntryResponse: PostEntryResponse;
  SubmittedAt: Date;
}

export interface FundingRoundPostData {
  RoundName: string;
  RoundStatus: string;
  DaoOwnerPkidBase58check: string;
  AmountToRaiseUsdCents: number;
  AllowOverflow: boolean;
  OffChainDesoBalanceNanos: number;
  ReserveRateBasisPoints: number;
  GlobalReferralRateBasisPoints: number;
  StartTime: string;
  EndTime: string | null;
  StartDaoCoinBaseUnitsPerTreasuryUnitHex: string;
  TreasuryCurrencyUnit: 'DESO' | 'DAO_COIN';
  TreasuryDaoCoin: string;
  PriceIncreaseIncrementBasisPoints: number;
  PriceIncreaseUnit: string;
  PriceIncreaseAmount: number;
  IsInvestmentImmediatelyRefundable: boolean;
  TermsAndConditions: string;
}

export interface ProposalPostData {
  DaoPubkeyBase58check: string;
  ProposerPubkeyBase58check: string;
  DerivedPublicKeyBase58Check: string;
  // String array containing the various options for the poll. Index in the array will determine which option it is.
  PollOptions: string[];
  // Text describing the poll.
  PollDescription: string;
  // Text for the title of the poll.
  PollTitle: string;
}

export interface ReferralCodePostData {
  RoundID: string;
  ReferrerPkidBase58check: string;
}

export interface FinalizeFundingRoundPostData {
  FundingRoundId: string;
  DaoPublicKeyBase58Check: string;
}

export interface Investment {
  RoundID: string;
  InvestmentID: string;
  DaoOwnerPkidBase58check: string;
  InvestorPkidBase58check: string;
  Status: INVESTMENT_STATUS;
  AmountInvestedDesoNanos: number;
  AmountRefundedDesoNanos: number;
  AmountInvestedDaoCoinsHex: string;
  AmountRefundedDaoCoinsHex: string;
  ReferrerReferralID: string;
  ReserveRateBasisPoints: number;
  DaoCoinsIssuedHex: string;
  DaoCoinsRedeemedHex: string;
  CreatedAt: string;
}

interface InvestmentCreatePayload {
  RoundID: string;
  InvestorPkidBase58check: string;
  AmountInvestedDesoNanos: number;
  AmountInvestedDaoCoinsHex: string;
  ReferrerReferralID: string;
  ReserveRateBasisPoints: number;
  FundingRoundPassword?: string;
}
export interface CreateInvestmentPostData {
  Investment: InvestmentCreatePayload;
  DerivedPublicKeyBase58Check: string;
}

interface TimeRange {
  start: string;
  end: string;
}

export interface ChartDataItem {
  Date: string;
  InvestmentUSDCentsTotal: number;
}
export interface ChartDataResponse {
  ChartData: ChartDataItem[];
}

interface DerivedKey {
  DerivedKeyId: string;
  DerivedPublicKey: string;
  DerivedPrivateKey: string;
  ExpirationBlock: number;
  AccessSignature: string;
  AppUserPkidBase58Check: string;
  InvestmentId: string;
  FundingRoundId: string;
  Purpose: string;
  Status: string;
  DeletedAt: string;
  Investment: Investment | null;
  FundingRound: FundingRound | null;
}

export interface AppUser {
  PkidBase58check: string;
  Username: string;
  DerivedPubkeyBase58check: string;
  DerivedPrivkeyBase58check: string;
  Email: string;
  EmailVerificationCode: string;
  EmailIsVerified: false;
  PhoneNumber: string;
  PhoneNumberVerificationCode: string;
  PhoneNumberIsVerified: boolean;
  DaoProposalsEnabledStatus: string;
  DaoProposalThresholdHex: string;
  DerivedKeys: DerivedKey[];
  DesoMessengerGroupChatId: string;
  DesoMessengerGroupChatChannel: string;
  EnableDaoNFTGallery: boolean;
}

export type OpenfundUser = User & { appUser: AppUser | null } & {
  isProjectOwner: boolean;
} & { profilePicDataUrl?: string } & { usdBalanceEntry?: BalanceEntryResponse | null };

export interface LeaderBoardData {
  Username: string;
  Description: string;
  PkidBase58check: string;
  CreatedAt: Date;
  CoinPriceDenominatingCoin: number;
  CoinPriceDenominatingCoinYesterday: number;
  CoinPriceDenominatingCoinLastWeek: number;
  MarketCapDenominatingCoin: number;
  UniqueCoinHodlers: number;
  TotalInvestedDesoNanos: number;
  TotalInvestedDesoNanosPastDay: number;
  TotalInvestedDesoNanosPastSevenDays: number;
  PercentChangeCoinPriceDenominatingCoinDayOverDay: number;
  PercentChangeCoinPriceDenominatingCoinWeekOverWeek: number;
  TotalCoinsCirculatingHex: string;
  VolumeDenominatingCoinBaseUnitsPastDay: number;
  TotalInvestedUSDCents: number;
  TotalInvestedUSDCentsPastDay: number;
  TotalInvestedUSDCentsPastSevenDays: number;
}

export interface GetNotificationResultData {
  Notifications: NotificationData[];
  NotificationCounts: NotificationCountData;
  NotificationsActorUserMap: {
    [key: string]: User;
  };
}

export interface NotificationData {
  Id: string;
  AppUserNotificationScanId: string;
  NotificationDigestId: string;
  RoundId: string;
  Status: string;
  AppUserPkidBase58Check: string;
  PostHashHex: string;
  Message: string;
  Category: string;
  Amount: number;
  ActorPkidBase58Check: string;
  Read: boolean;
  ActorUser: User;
  CreatedAt: string;
  DeletedAt: string;
}

export interface NotificationCountData {
  UnreadCount: number;
  TotalCount: number;
}

export interface AddIngressCookieToUserRequest {
  IngressCookieContent: string;
  UserPubkeyBase58check: string;
}

export interface ProjectProfilesCount {
  TotalDAOProfileCount: number;
}

export interface ProjectProfile {
  PublicKeyBase58Check: string;
  Username: string;

  BalanceNanos: number;
  DaoCoinsInCirculationNanos: string;
}

export interface CoinHistoricalPriceGetResponse {
  TradingDaoCoinPublicKeyBase58Check: string;
  DenominatedDaoCoinPublicKeyBase58Check: string;
  StartTime: string;
  EndTime: string;
  TimeUnits: string;
  Prices: CoinHistoricalPriceStats[];
}

export enum CoinHistoricalPriceTimeWindow {
  TimeWindow6H = '6H',
  TimeWindow24H = '24H',
  TimeWindow7D = '7D',
  TimeWindow30D = '30D',
  TimeWindowAll = 'ALL',
}

export enum CoinHistoricalPriceTimeUnits {
  TimeUnits15M = '15M',
  TimeUnits1H = '1H',
  TimeUnits6H = '6H',
  TimeUnits1D = '1D',
}

export interface CoinHistoricalPriceStats {
  HighPrice: number;
  LowPrice: number;
  OpenPrice: number;
  ClosePrice: number;
  AvgPrice: number;
  Timestamp: string;
}

export interface CoinsForAmountInvested {
  TotalDAOCoinToMint: string;
  DAOCoinToTransferToUser: string;
}

export interface ProcessedTokensForAmountInvested {
  TotalTokensToMint: number;
  TokensToTransferToUser: number;
  AmountInvestedNanos: bigint;
  PricePerToken: number;
}

export interface FundingRoundAccess {
  Password: string;
  Enabled: boolean;
}

export interface AppUserReferral {
  DaoUsername: string;
  DaoPkidBase58check: string;
  ReferralID: string;
  RoundID: string;
  RoundName: string;
  RoundStatus: string;
  InvestmentStatus: string;
  ReserveRateBasisPoints: number;
  GlobalReferralRateBasisPoints: number;
  ReferralCount: number;
  ReferralAmountEarnedDesoNanos: number;
  ReferralAmountEarnedDaoCoinsHex: string;
  TreasuryCurrencyUnit: 'DAO_COIN' | 'DESO';
}
export interface GetUserReferralsResponse {
  TotalReferralAmountEarnedDesoNanos: number;
  TotalReferralAmountEarnedDaoCoinsHex: string;
  TotalReferralsCount: number;
  Referrals: AppUserReferral[];
}

export interface ProjectReferral {
  ReferrerPkidBase58check: string;
  ReferrerUsername: string;
  ReferralCount: number;
  ReferralAmountEarnedDesoNanos: number;
  ReferralAmountEarnedDaoCoinsHex: string;
}

export interface GetProjectReferralsResponse {
  TotalReferralAmountEarnedDesoNanos: number;
  TotalReferralAmountEarnedDaoCoinsHex: string;
  TotalReferralsCount: number;
  Referrals: ProjectReferral[];
}

export interface EventFeedItem {
  Id: string;
  UserPkidBase58Check: string;
  Username: string;
  DaoOwnerPkidBase58Check: string;
  DaoOwnerUsername: string;
  EventType: string;
  PriceUsdCents: number;
  PriceBaseUnits: number;
  QuantityBaseUnitsHex: string;
  PostHashHex: string;
  CreatedAt: string;
  DenominatingCoinPublicKeyBase58Check: string;
}

export interface DesoMessengerMessage {
  groupId: string;
  channelId: string;
  authorId: string;
  content: string;
  attachments: [];
  type: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  decryptedContent: string;
  timestamp: number;
  isSender: boolean;
  isEncrypted: boolean;
  ProfileEntryResponse: ProfileEntryResponse;
}

export function waitForPropagation() {
  if (process.env.NODE_ENV !== 'production') {
    // Wait for the txn to propagate
    console.log(`Waiting ${NODE_PROPAGATION_MILLIS / 1000}s for propagation`);
  }

  return new Promise((resolve) => setTimeout(resolve, NODE_PROPAGATION_MILLIS));
}

const DEFAULT_FUNDING_ROUND_EXPIRATION_DAYS = 365;

export class Openfund {
  private initialized = false;
  private axios: AxiosInstance;
  private heroswap: HeroSwapper;
  private _currentUser: OpenfundUser | null = null;
  private fundingRoundsCache: Record<string, FundingRound[]> = {};
  private referralCodeCache: Record<string, string> = {};
  private deso: Deso;

  get currentUser() {
    return this._currentUser;
  }

  constructor(axiosClient: AxiosInstance, heroswap: HeroSwapper, deso: Deso) {
    this.axios = axiosClient;
    this.heroswap = heroswap;
    this.deso = deso;
  }

  async reloadUser(publicKey: string) {
    let user = await this.deso
      .getUsers([publicKey], {
        SkipForLeaderboard: false,
        refreshCache: true,
      })
      .then(({ UserList }) => UserList?.[0] ?? null);
    trackingIdentifyUser(publicKey, { Username: user?.ProfileEntryResponse?.Username });
    return user;
  }

  async reloadCurrentUserData(): Promise<OpenfundUser | null> {
    if (!this._currentUser) {
      throw new Error('Attempted to reload current user data without a logged in user');
    }
    const user = await this.reloadUser(this._currentUser.PublicKeyBase58Check);
    await this.updateCurrentUser(user);
    return this._currentUser;
  }

  async login(onIdentityFlowComplete?: () => void): Promise<OpenfundUser | null> {
    trackingLogEvent('account : login : start');
    const data = await identity.login();

    onIdentityFlowComplete?.();
    trackingLogEvent('account : login : identityFlowComplete');
    await waitForPropagation();
    let user = await this.deso
      .getUsers([data.publicKeyAdded || ''], {
        SkipForLeaderboard: false,
      })
      .then(({ UserList }) => UserList?.[0] ?? null);

    if (!user?.BalanceNanos) {
      trackingLogEvent('account : login : showNotEnoughMoney');
      let swalRes = await Swal.fire({
        title: 'Continue without $DESO?',
        html: `You can't post to the decentralized social network unless you have some starter DESO.
                <br />
          <br />
          Most features won't work without it.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Continue without $DESO (not advised)',
        confirmButtonText: 'Get Starter $DESO',
      });
      if (swalRes.isConfirmed) {
        trackingLogEvent('account : login : clickGetStarterDesoAgain');
        await identity.getDeso();
        await waitForPropagation();
        user = await this.deso
          .getUsers([data.publicKeyAdded || ''], {
            SkipForLeaderboard: false,
            refreshCache: true,
          })
          .then(({ UserList }) => UserList?.[0] ?? null);
      } else {
        trackingLogEvent('account : login : clickContinueWithoutDesoBad');
      }
    }
    if (!user?.BalanceNanos) {
      trackingLogEvent('account : login : notEnoughDesoAfterLoginBadJourney');
    } else {
      trackingLogEvent('account : login : userHasEnoughDesoAfterLoginGoodJourney');
    }
    await this.updateCurrentUser(user);

    return this._currentUser;
  }

  async logout(onIdentityFlowComplete?: () => void): Promise<OpenfundUser | null> {
    await identity.logout();
    onIdentityFlowComplete?.();
    this._currentUser = null;
    await this.updateCurrentUser(this._currentUser);
    return this._currentUser;
  }

  async updateCurrentUser(user: User | null): Promise<OpenfundUser | null> {
    let appUser = null;
    let isProjectOwner = false;
    let usdBalanceEntry = null;

    if (!!user?.ProfileEntryResponse) {
      const fundingRounds = (await this.getFundingRoundsByUsername(user.ProfileEntryResponse.Username)) ?? [];
      isProjectOwner = fundingRounds.length > 0;
    }

    if (user) {
      // Set the user in all of our analytics tools
      trackingIdentifyUser(user.PublicKeyBase58Check, { Username: user.ProfileEntryResponse?.Username });

      try {
        const [appUserData, desoUSDProfileResponse] = await Promise.all([
          this.axios.get(
            `app-user/${user.PublicKeyBase58Check}?derivedKeyPurpose=PROFILE`,
            await this.getAuthHeaders(),
          ),
          this.deso.getProfileByUsername(DESO_DOLLAR_PROFILE_NAME),
        ]);

        appUser = appUserData.data.AppUser;

        if (!desoUSDProfileResponse.Profile) {
          throw new Error(`Unable to retrieve USD Balance`);
        }

        usdBalanceEntry = await this.deso.getProjectHolding(
          user.PublicKeyBase58Check,
          desoUSDProfileResponse.Profile.PublicKeyBase58Check,
        );
      } catch (e: any) {
        // NOTE: this nested try catch is gross, but we always want to attempt to register users if the call
        // for app-user fails with 404.
        if (e?.response?.status === 404) {
          try {
            await this.registerProfile(user.PublicKeyBase58Check, user.ProfileEntryResponse?.Username);
            const { data } = await this.axios.get(
              `app-user/${user.PublicKeyBase58Check}?derivedKeyPurpose=PROFILE`,
              await this.getAuthHeaders(),
            );
            appUser = data.AppUser;
          } catch (_) {
            appUser = null;
          }
        } else {
          throw e;
        }
      }
    }

    this._currentUser = user ? { ...user, appUser, isProjectOwner, usdBalanceEntry } : null;
    return this._currentUser;
  }

  async setIngressCookieForUser(userPublicKeyBase58Check: string): Promise<void> {
    const ingressCookie = await this.deso.getIngressCookie();
    const savedIngressCookie = localStorage.getItem('ingressCookie');
    // If the ingress cookie exists and hasn't already been set for this user, set it now.
    if ('CookieValue' in ingressCookie && (!savedIngressCookie || savedIngressCookie !== ingressCookie.CookieValue)) {
      await this.updateUserWithIngressCookie({
        IngressCookieContent: ingressCookie.CookieValue,
        UserPubkeyBase58check: userPublicKeyBase58Check,
      });
      localStorage.setItem('ingressCookie', ingressCookie.CookieValue);
    }
    return;
  }

  async updateUserWithIngressCookie(request: AddIngressCookieToUserRequest): Promise<void> {
    const headers = await this.getAuthHeaders();
    return this.axios.put(`add-ingress-cookie-user`, request, headers);
  }

  async initializeAuthenticatedUser(): Promise<OpenfundUser | null> {
    if (this.initialized) {
      return this._currentUser;
    }

    const identityState = await identity.snapshot();

    const user = identityState.currentUser
      ? await this.deso
          .getUsers([identityState.currentUser.publicKey], { SkipForLeaderboard: false })
          .then(({ UserList }) => UserList?.[0] ?? null)
      : null;

    if (user) {
      try {
        await this.setIngressCookieForUser(user.PublicKeyBase58Check);
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Error setting ingress cookie for user: ', e);
        }
      }
    }

    const currentUser = await this.updateCurrentUser(user);
    this.initialized = true;
    return currentUser;
  }

  async registerProfile(
    OwnerPubkeyBase58check: string,
    Username = '',
    Email: string = '',
    DaoProposalsEnabledStatus: string = 'DAO_ONLY',
    DaoProposalThresholdHex: string = toHex(tokenToBaseUnits(100000)),
    EnableDaoNFTGallery: boolean = true,
  ): Promise<undefined> {
    const jwt = await identity.jwt();
    const { data } = await this.axios.post(
      'register-user',
      {
        OwnerPubkeyBase58check,
        Username,
        Email,
        DaoProposalsEnabledStatus,
        DaoProposalThresholdHex,
        EnableDaoNFTGallery,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    return data;
  }

  async createDerivedKey(
    PublicKeyBase58Check: string,
    Purpose: string,
  ): Promise<{ DerivedPublicKeyBase58Check: string }> {
    const jwt = await identity.jwt();
    const { data } = await this.axios.post(
      'create-derived-key',
      {
        PublicKeyBase58Check,
        Purpose,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    return data;
  }

  async authorizeDerivedKey(
    OwnerPublicKeyBase58Check: string,
    DerivedPublicKeyBase58Check: string,
    ExpirationBlock: number,
    AccessSignature: string,
    DeleteKey: boolean,
    TransactionSpendingLimitHex: string,
  ) {
    const jwt = await identity.jwt();
    const { data } = await this.axios.post(
      'authorize-derived-key',
      {
        OwnerPublicKeyBase58Check,
        DerivedPublicKeyBase58Check,
        ExpirationBlock,
        AccessSignature,
        DeleteKey,
        TransactionSpendingLimitHex,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    return data;
  }

  async updateUserProfile(
    createProfileParams: CreateProfileParams,
    onDerivedKeyAuthorized = () => {},
    derivedKey: string,
  ): Promise<void> {
    if (!derivedKey) {
      throw new Error('Missing derived key.');
    }
    const state = await identity.snapshot();

    if (!state.currentUser) {
      throw new Error('Cannot update profile without a logged in user.');
    }

    const DerivedPublicKeyBase58Check = await this.signAndAuthorizeDerivedKey(state.currentUser.publicKey, derivedKey, {
      GlobalDESOLimit: GLOBAL_DESO_LIMIT_BUFFER,
      TransactionCountLimitMap: {
        UPDATE_PROFILE: 1,
      },
    });
    onDerivedKeyAuthorized();
    const headers = await this.getAuthHeaders();
    const { data } = await this.axios.post(
      'create-user-profile',
      {
        OwnerPubkeyBase58check: state.currentUser.publicKey,
        DerivedPublicKeyBase58Check,
        ...createProfileParams,
      },
      headers,
    );

    return data;
  }

  async signAndAuthorizeDerivedKey(
    OwnerPubkeyBase58check: string,
    derivedKey: string,
    transactionSpendingLimitResponse: TransactionSpendingLimitResponse,
    expirationDays?: number,
    onDerivedKeySigned?: () => void,
  ) {
    const txCountLimitMap = {
      ...transactionSpendingLimitResponse.TransactionCountLimitMap,
      AUTHORIZE_DERIVED_KEY: 1,
    };

    const resp = await identity.derive(
      {
        ...transactionSpendingLimitResponse,
        TransactionCountLimitMap: txCountLimitMap,
      },
      {
        expirationDays,
        ownerPublicKey: OwnerPubkeyBase58check,
        derivedPublicKey: derivedKey,
      },
    );

    onDerivedKeySigned?.();

    await this.authorizeDerivedKey(
      OwnerPubkeyBase58check,
      resp.derivedPublicKeyBase58Check,
      resp.expirationBlock,
      resp.accessSignature,
      false,
      resp.transactionSpendingLimitHex,
    );

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 2000);
    });

    return resp.derivedPublicKeyBase58Check;
  }

  async getInvestments(publicKeyBase58Check: string, status: string): Promise<Investment[]> {
    let requestUrl = 'investments';
    if (publicKeyBase58Check && status) {
      requestUrl += `?publicKeyBase58Check=${publicKeyBase58Check}&status=${status}`;
    }
    const res = await this.axios.get(requestUrl);
    return res.data;
  }

  async getAllFundingRounds(): Promise<FundingRound[]> {
    const res = await this.axios.get('funding-rounds');
    return res.data;
  }

  async getAllProposalsForProject(projectPublicKey: string): Promise<Proposal[]> {
    const res = await this.axios.get(`polls/${projectPublicKey}`);
    return res.data.Proposals;
  }

  async getSingleProposalForProject(proposalId: string): Promise<Proposal> {
    const res = await this.axios.get(`polls/proposal/${proposalId}`);
    return res.data.Proposal;
  }

  async getProposalTally(proposalPostHashHex: string): Promise<ProposalTallyResult> {
    const res = await this.axios.get(`polls/${proposalPostHashHex}/tally`);
    return res.data;
  }

  async finalizePoll(PollPostHashHex: string, PollPublicKey: string, derivedKey: string) {
    const headers = await this.getAuthHeaders();
    const DerivedPublicKeyBase58Check = await this.signAndAuthorizeDerivedKey(PollPublicKey, derivedKey, {
      GlobalDESOLimit: GLOBAL_DESO_LIMIT_BUFFER,
      TransactionCountLimitMap: {
        SUBMIT_POST: 1,
      },
    });
    const res = await this.axios.post(
      'finalize-poll',
      {
        PollPostHashHex,
        DerivedPublicKeyBase58Check,
      },
      headers,
    );

    return res.data;
  }

  async voteInProposal(
    PollPostHashHex: string,
    VoterPublicKey: string,
    SelectedPollOption: number,
    CommentBody: string,
    derivedKey: string,
    hasVoted: boolean,
  ): Promise<SubmitPostResponse> {
    const DerivedPublicKeyBase58Check = await this.signAndAuthorizeDerivedKey(VoterPublicKey, derivedKey, {
      GlobalDESOLimit: GLOBAL_DESO_LIMIT_BUFFER,
      TransactionCountLimitMap: {
        // If the user has voted we need an extra submit post to hide the original vote.
        SUBMIT_POST: hasVoted ? 2 : 1,
      },
    });
    const headers = await this.getAuthHeaders();
    const res = await this.axios.post(
      'vote',
      {
        PollPostHashHex,
        VoterPublicKey,
        SelectedPollOption,
        CommentBody,
        DerivedPublicKeyBase58Check,
      },
      headers,
    );

    return res.data;
  }

  async getFundingRoundsByUsername(username: string, refreshCache = false): Promise<FundingRound[]> {
    const params = new URLSearchParams({
      username,
    });

    const queryParams = params.toString();

    if (this.fundingRoundsCache[queryParams] && !refreshCache) {
      return this.fundingRoundsCache[queryParams];
    }

    const res = await this.axios.get(`funding-rounds?${queryParams}`);

    this.fundingRoundsCache[queryParams] = res.data;

    return this.fundingRoundsCache[queryParams];
  }

  async updateFundingRound(fundingRoundId: string, data: FundingRoundPostData, derivedKey: string) {
    const headers = await this.getAuthHeaders();

    if (data.EndTime === '') {
      data.EndTime = null;
    }

    if (derivedKey) {
      const expirationDays = data.EndTime
        ? Math.ceil((new Date(data.EndTime).getTime() - new Date(data.StartTime).getTime()) / 86400000) + 1
        : DEFAULT_FUNDING_ROUND_EXPIRATION_DAYS;
      await this.signAndAuthorizeDerivedKey(
        data.DaoOwnerPkidBase58check,
        derivedKey,
        {
          GlobalDESOLimit: GLOBAL_DESO_LIMIT_BUFFER,
          TransactionCountLimitMap: {
            DAO_COIN: Number.MAX_SAFE_INTEGER,
            DAO_COIN_TRANSFER: Number.MAX_SAFE_INTEGER,
          },
          DAOCoinOperationLimitMap: {
            [data.DaoOwnerPkidBase58check]: {
              mint: Number.MAX_SAFE_INTEGER,
              burn: Number.MAX_SAFE_INTEGER,
              transfer: Number.MAX_SAFE_INTEGER,
            },
          },
        },
        expirationDays,
      );
    }

    const res = await this.axios.put(`funding-rounds/${fundingRoundId}`, data, headers);
    return res.data;
  }

  async createFundingRound(data: FundingRoundPostData, derivedKey: string): Promise<FundingRound> {
    const expirationDays = data.EndTime
      ? Math.ceil((new Date(data.EndTime).getTime() - new Date(data.StartTime).getTime()) / 86400000) + 1
      : DEFAULT_FUNDING_ROUND_EXPIRATION_DAYS;
    const DerivedPublicKeyBase58Check = await this.signAndAuthorizeDerivedKey(
      data.DaoOwnerPkidBase58check,
      derivedKey,
      {
        GlobalDESOLimit: GLOBAL_DESO_LIMIT_BUFFER,
        TransactionCountLimitMap: {
          DAO_COIN: Number.MAX_SAFE_INTEGER,
          DAO_COIN_TRANSFER: Number.MAX_SAFE_INTEGER,
        },
        DAOCoinOperationLimitMap: {
          [data.DaoOwnerPkidBase58check]: {
            mint: Number.MAX_SAFE_INTEGER,
            burn: Number.MAX_SAFE_INTEGER,
            transfer: Number.MAX_SAFE_INTEGER,
          },
        },
      },
      expirationDays,
    );
    const headers = await this.getAuthHeaders();
    const res = await this._createFundingRound(headers, data, DerivedPublicKeyBase58Check);

    if (this._currentUser?.appUser?.DesoMessengerGroupChatId === '') {
      await this.createGroupMessage(this._currentUser.appUser);
    }

    return res.data.FundingRound;
  }

  async getAuthHeaders() {
    const jwt = await identity.jwt();
    return {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
  }

  async createProposal(data: ProposalPostData, derivedKey: string) {
    const DerivedPublicKeyBase58Check = await this.signAndAuthorizeDerivedKey(
      data.ProposerPubkeyBase58check,
      derivedKey,
      {
        GlobalDESOLimit: GLOBAL_DESO_LIMIT_BUFFER,
        TransactionCountLimitMap: {
          SUBMIT_POST: 3,
        },
      },
    );
    data.DerivedPublicKeyBase58Check = DerivedPublicKeyBase58Check;
    const headers = await this.getAuthHeaders();
    const res = await this.axios.post(`polls`, data, headers);
    return res.data;
  }

  async withdrawInvestment(InvestmentId: string, InvestorPkidBase58check: string) {
    const headers = await this.getAuthHeaders();
    const res = await this.axios.post(
      'withdraw-investment',
      {
        InvestmentId,
        InvestorPkidBase58check,
      },
      headers,
    );
    return res.data;
  }

  async finalizeFundingRound(FundingRoundId: string, DaoPublicKeyBase58Check: string) {
    const jwt = await identity.jwt();
    const { data } = await this.axios.post(
      'finalize-funding',
      {
        FundingRoundId,
        DaoPublicKeyBase58Check,
      } as FinalizeFundingRoundPostData,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );
    return data;
  }

  async createInvestment(
    payload: InvestmentCreatePayload,
    projectOwnerPublicKey: string,
    derivedKey: string,
    derivedKeyExpirationDays: number,
    {
      tokenTreasuryPublicKey = '',
      password = '',
      onDerivedKeyAuthorized = () => {},
    }: { tokenTreasuryPublicKey?: string; password?: string; onDerivedKeyAuthorized?: () => void },
  ) {
    trackingLogEvent('invest : create : start', { ...payload, daoOwnerPublicKey: projectOwnerPublicKey });
    let investmentDerivedKey = derivedKey;

    if (!investmentDerivedKey) {
      const { DerivedPublicKeyBase58Check } = await this.createDerivedKey(
        payload.InvestorPkidBase58check,
        DERIVED_KEY_PURPOSES.INVESTMENT,
      );
      investmentDerivedKey = DerivedPublicKeyBase58Check;
    }

    const DerivedPublicKeyBase58Check = await this.signAndAuthorizeDerivedKey(
      payload.InvestorPkidBase58check,
      investmentDerivedKey,
      {
        GlobalDESOLimit: GLOBAL_DESO_LIMIT_BUFFER + payload.AmountInvestedDesoNanos,
        TransactionCountLimitMap: {
          DAO_COIN: 2,
          BASIC_TRANSFER: 1,
        },
        DAOCoinOperationLimitMap: {
          [projectOwnerPublicKey]: {
            burn: Number.MAX_SAFE_INTEGER,
          },
          ...(!!tokenTreasuryPublicKey && {
            [tokenTreasuryPublicKey]: {
              transfer: Number.MAX_SAFE_INTEGER,
            },
          }),
        },
      },
      derivedKeyExpirationDays,
      onDerivedKeyAuthorized,
    );

    const headers = await this.getAuthHeaders();
    const { data } = await this.axios.post(
      'investments',
      {
        Investment: payload,
        DerivedPublicKeyBase58Check,
        FundingRoundPassword: password,
      } as CreateInvestmentPostData,
      headers,
    );

    trackingLogEvent('invest : create : success', { ...payload, daoOwnerPublicKey: projectOwnerPublicKey, ...data });

    return data;
  }

  async computeTokensForAmountInvested(
    RoundID: string,
    AmountToInvestBaseUnits: bigint, // could be either deso nanos or token "base units"
    treasuryUnit: 'DESO' | 'DAO_COIN',
  ): Promise<CoinsForAmountInvested> {
    const { data } = await this.axios.post('compute-dao-coin-base-units-expected', {
      RoundID,
      ...(treasuryUnit === 'DAO_COIN'
        ? {
            DaoCoinsToInvestHex: toHex(AmountToInvestBaseUnits),
          }
        : {
            DesoNanosToInvest: Number(AmountToInvestBaseUnits),
          }),
    });

    return data;
  }

  async getTokenHistoricalPrices(
    tradingTokenPublicKeyBase58Check: string,
    denominatedTokenPublicKeyBase58Check: string,
    startTime: Date,
    endTime: Date,
    timeUnits: CoinHistoricalPriceTimeUnits,
  ): Promise<CoinHistoricalPriceGetResponse> {
    const { data } = await this.axios.get(
      'dao-coin-historical-prices?' +
        `&trading_dao_coin_public_key_base58_check=${tradingTokenPublicKeyBase58Check}` +
        `&denominated_dao_coin_public_key_base58_check=${denominatedTokenPublicKeyBase58Check}` +
        `&start_time=${startTime.toISOString()}` +
        `&end_time=${endTime.toISOString()}` +
        `&time_units=${timeUnits}`,
    );
    return data;
  }

  async getProjectProfilesCount(): Promise<ProjectProfilesCount> {
    const { data } = await this.axios.get('dao-profiles-count');
    return data;
  }

  async getProjectProfiles(
    username: string = '',
    options: { offset: number; limit: number } = { offset: 0, limit: 100 },
  ): Promise<ProjectProfile[]> {
    const { offset, limit } = options;
    // Get project profiles.
    const { data } = await this.axios.get(
      `dao-profiles?offset=${offset}&limit=${limit}&username=${encodeURIComponent(username)}`,
    );
    if (!data) {
      return [];
    }
    // Filter out graylisted and blacklisted profiles.
    const users = await this.deso.getUsers(
      data.map((profile: ProjectProfile): string => profile.PublicKeyBase58Check),
      { SkipForLeaderboard: true },
    );
    const userMap = new Map<string, User>();
    (users.UserList || []).forEach((user: User) => userMap.set(user.PublicKeyBase58Check, user));
    return data.filter((profile: ProjectProfile): boolean => {
      const user = userMap.get(profile.PublicKeyBase58Check);
      return !user || (!user.IsGraylisted && !user.IsBlacklisted);
    });
  }

  async getInvestmentTimeSeriesData(projectOwnerPkid: string, { start, end }: TimeRange): Promise<ChartDataResponse> {
    const { data } = await this.axios.get(`investment-chart/${projectOwnerPkid}?startDate=${start}&endDate=${end}`);
    return data;
  }

  async getNotifications(
    userPkid: string,
    page: number,
    limit: number,
    filters: string[],
  ): Promise<GetNotificationResultData> {
    let requestUrl = `notifications/${userPkid}?page=${page}&limit=${limit}`;
    if (filters.length > 0) {
      requestUrl = requestUrl + `&filters=${filters.join(',')}`;
    }
    const { data } = await this.axios.get(requestUrl);
    return data ?? {};
  }

  async markNotificationsRead(userPublicKey: string, notificationIds: string[]): Promise<void> {
    let requestUrl = `notifications`;
    const requestBody = {
      UserPublicKey: userPublicKey,
      NotificationIds: notificationIds,
    };
    const jwt = await identity.jwt();
    return this.axios.put(requestUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
  }

  async getTopProjects(params: {
    username?: string;
    limit?: number;
    offset?: number;
    order?: string;
    denominatingCoinPublicKeyBase58Check?: string;
  }): Promise<LeaderBoardData[]> {
    const queryParams =
      Object.keys(params).length > 0
        ? new URLSearchParams(
            Object.keys(params).reduce(
              (result, k) => {
                const valueAsString = String((params as any)[k]);
                if (valueAsString.length > 0) {
                  result[k] = valueAsString;
                }
                return result;
              },
              {} as Record<string, string>,
            ),
          )
        : null;

    const { data } = await this.axios.get(`top-daos${queryParams ? `?${queryParams.toString()}` : ''}`);
    return data.TopDaos ?? [];
  }

  async getTopProjectHolders(
    projectUsername: string,
    sortType: string = 'wealth',
    purchasedOnly: boolean = true,
  ): Promise<GetHodlersForPublicKeyResponse> {
    const { data } = await this.axios.get(
      `top-dao-holders/${projectUsername}?sort_type=${sortType}&purchased_only=${purchasedOnly}&limit=1000000`,
    );
    const response = data as GetHodlersForPublicKeyResponse;
    // NOTE: this currently always returns the project owner as the first item, regardless of their wealth or tokens held.
    // Once this is fixed we should stop removing the first item here.
    if (response.Hodlers?.[0]?.ProfileEntryResponse?.Username === projectUsername) {
      response.Hodlers.shift();
    }

    return response;
  }

  async getReferralCode(postData: ReferralCodePostData): Promise<string> {
    const cacheKey = JSON.stringify(postData);
    if (this.referralCodeCache[cacheKey]) {
      return this.referralCodeCache[cacheKey];
    }

    const headers = await this.getAuthHeaders();
    const { data } = await this.axios.post('referrals', postData, headers);

    this.referralCodeCache[cacheKey] = data.ReferralID;
    return this.referralCodeCache[cacheKey];
  }

  async updateProfileWithDefaultFundingRound(
    profileData: CreateProfileParams,
    fundingRoundData: FundingRoundPostData,
    derivedKey: string,
    onDerivedKeyAuthorized?: () => void,
  ): Promise<FundingRound> {
    const DerivedPublicKeyBase58Check = await this.signAndAuthorizeDerivedKey(
      fundingRoundData.DaoOwnerPkidBase58check,
      derivedKey,
      {
        GlobalDESOLimit: GLOBAL_DESO_LIMIT_BUFFER,
        TransactionCountLimitMap: {
          UPDATE_PROFILE: 1,
          DAO_COIN: Number.MAX_SAFE_INTEGER,
          DAO_COIN_TRANSFER: Number.MAX_SAFE_INTEGER,
        },
        DAOCoinOperationLimitMap: {
          [fundingRoundData.DaoOwnerPkidBase58check]: {
            mint: Number.MAX_SAFE_INTEGER,
            burn: Number.MAX_SAFE_INTEGER,
            transfer: Number.MAX_SAFE_INTEGER,
          },
        },
      },
      DEFAULT_FUNDING_ROUND_EXPIRATION_DAYS,
    );

    onDerivedKeyAuthorized?.();

    const headers = await this.getAuthHeaders();
    await this.axios.post(
      'create-user-profile',
      {
        OwnerPubkeyBase58check: fundingRoundData.DaoOwnerPkidBase58check,
        DerivedPublicKeyBase58Check,
        ...profileData,
      },
      headers,
    );

    const res = await this._createFundingRound(headers, fundingRoundData, DerivedPublicKeyBase58Check);

    return res.data.FundingRound;
  }

  async getUserReferrals(
    userPkid: string,
    sortType = 'referral_amount_earned_deso_nanos',
  ): Promise<GetUserReferralsResponse> {
    try {
      const { data } = await this.axios.get(
        `app-user/${userPkid}/referrals?sortType=${sortType}`,
        await this.getAuthHeaders(),
      );
      return data;
    } catch (e) {
      return Promise.resolve({
        TotalReferralAmountEarnedDesoNanos: 0,
        TotalReferralAmountEarnedDaoCoinsHex: '0x0',
        TotalReferralsCount: 0,
        Referrals: [],
      });
    }
  }

  async getProjectReferrals(username: string, fundingRoundID?: string): Promise<GetProjectReferralsResponse> {
    try {
      const { data } = await this.axios.get(
        `dao-user/${username}/referrals${fundingRoundID ? `?fundingRoundID=${fundingRoundID}` : ''}`,
      );

      return data;
    } catch (e) {
      return Promise.resolve({
        TotalReferralAmountEarnedDesoNanos: 0,
        TotalReferralAmountEarnedDaoCoinsHex: '0x0',
        TotalReferralsCount: 0,
        Referrals: [],
      });
    }
  }

  async getFundingRoundPassword(roundID: string): Promise<FundingRoundAccess> {
    const { data } = await this.axios.get(`funding-rounds/${roundID}/password`, await this.getAuthHeaders());
    return data;
  }

  async upsertFundingRoundPassword(roundID: string, params: FundingRoundAccess) {
    const { data } = await this.axios.post(`funding-rounds/${roundID}/password`, params, await this.getAuthHeaders());

    return data;
  }

  async updateFundingRoundPassword(roundID: string, params: FundingRoundAccess) {
    const { data } = await this.axios.put(`funding-rounds/${roundID}/password`, params, await this.getAuthHeaders());

    return data;
  }

  async deleteFundingRoundPassword(roundID: string) {
    const { data } = await this.axios.delete(`funding-rounds/${roundID}/password`, await this.getAuthHeaders());
    return data;
  }

  async verifyFundingRoundPassword(roundID: string, Password: string) {
    const { data } = await this.axios.post(`funding-rounds/${roundID}/verify-password`, {
      Password,
    });
    return data;
  }

  async getEmailNotificationPreferences(userPk: string) {
    const { data } = await this.axios.get(
      `app-users/${userPk}/email-notification-preferences`,
      await this.getAuthHeaders(),
    );
    return data;
  }

  async saveEmailNotificationPreferences(userPk: string, Preferences: Array<string>) {
    const { data } = await this.axios.post(
      `app-users/${userPk}/email-notification-preferences`,
      { Preferences },
      await this.getAuthHeaders(),
    );
    return data;
  }

  async refundFundingRound(roundID: string) {
    const { data } = await this.axios.post(`funding-rounds/${roundID}/refunds`, {}, await this.getAuthHeaders());
    return data;
  }

  async submitEmailVerification(emailVerificationCode: string, userPublicKeyBase58Check: string) {
    const { data } = await this.axios.post(
      `submit-email-verification`,
      {
        PublicKeyBase58Check: userPublicKeyBase58Check,
        EmailHash: emailVerificationCode,
      },
      await this.getAuthHeaders(),
    );
    return data;
  }

  async validateAccessPassword(Password: string) {
    const { data } = await this.axios.post('validate-access-password', {
      Password,
    });
    return data;
  }

  async getEventFeed(params: {
    username?: string;
    projectUsername?: string;
    type?: string | string[];
    limit?: number;
    offset?: number;
  }): Promise<EventFeedItem[]> {
    const queryParams = new URLSearchParams(
      Object.entries(params).reduce(
        (result, [k, v]) => {
          if (Array.isArray(v)) {
            if (v.length > 0) {
              result[k] = v.join(',');
            }
          } else if (v) {
            result[k === 'projectUsername' ? 'dao' : k] = v.toString();
          }
          return result;
        },
        {} as { [key: string]: string },
      ),
    );
    const { data } = await this.axios.get(`app-user-events?${queryParams}`);
    return data;
  }

  async getGroupMessages(projectPublicKey?: string, limit?: number, skip?: number): Promise<DesoMessengerMessage[]> {
    let queryParamQuestionMark = '';
    let queryParamAmpersand = '';
    let queryParamLimit = '';
    let queryParamSkip = '';
    if (limit !== undefined || skip !== undefined) {
      queryParamQuestionMark = '?';
    }
    if (limit !== undefined && skip !== undefined) {
      queryParamAmpersand = '&';
    }
    if (limit !== undefined) {
      queryParamLimit = `limit=${limit}`;
    }

    if (skip !== undefined) {
      queryParamSkip = `skip=${skip}`;
    }

    const { data } = await this.axios.get(
      `group-messages/${projectPublicKey}${queryParamQuestionMark}${queryParamLimit}${queryParamAmpersand}${queryParamSkip}`,
    );
    return data.messages;
  }

  async getProjectsWithMessaging(): Promise<AppUser[]> {
    const { data } = await this.axios.get('daos-with-messaging');
    return data;
  }

  async getAppUserWithPubKey(userPublicKey: string): Promise<AppUser> {
    const { data } = await this.axios.get(
      `app-user/${userPublicKey}`,
      this._currentUser ? await this.getAuthHeaders() : undefined,
    );
    return data.AppUser;
  }

  async sendGroupMessage(
    authorPublicKey: string,
    projectGroupChatId: string,
    projectGroupChatChannel: string,
    messageContent: string,
  ): Promise<DesoMessengerMessage> {
    const { data } = await this.axios.post(
      `group-messages`,
      {
        AuthorId: authorPublicKey,
        IsEncrypted: false,
        IsSender: false,
        Legacy: false,
        PublicKey: '',
        Content: messageContent,
        GroupId: projectGroupChatId,
        ChannelId: projectGroupChatChannel,
        Type: 'TEXT',
        Timestamp: 0,
        Attachments: [],
      },
      await this.getAuthHeaders(),
    );
    return data.messages;
  }

  async createGroupMessage(appUser: AppUser): Promise<DesoMessengerMessage> {
    const { data } = await this.axios.post(
      `group-messages/create-group`,
      {
        Name: appUser.Username,
        Thumbnail:
          'https://bitclout.com/api/v0/get-single-profile-picture/BC1YLiWoa5iAHSvGXKv9b7fvjY8KagbQEZCtkvxRY9CndmUvEpvDcn4?fallback=https://bitclout.com/assets/img/default_profile_pic.png',
        Description: '',
        Participants: [
          {
            Id: appUser.PkidBase58check,
            Username: appUser.Username,
            Role: 'OWNER',
          },
        ],
        IsRead: true,
        IsPublic: true,
        Type: 'GROUP',
        UserPublicKey: appUser.PkidBase58check,
      },
      await this.getAuthHeaders(),
    );
    return data.messages;
  }

  private _createFundingRound(
    headers: { headers: { Authorization: string } },
    roundData: FundingRoundPostData,
    derivedKey: string,
  ) {
    const promises = [
      this.axios.post(
        'funding-rounds',
        {
          FundingRound: {
            ...roundData,
            EndTime: !!roundData.EndTime ? roundData.EndTime : null,
          },
          DerivedPublicKeyBase58Check: derivedKey,
        },
        headers,
      ),
    ];

    // If the project hasn't minted any tokens yet, we update the transfer status to
    // disable trading by default. They can manually enable this at any time.
    const tokensInCirculation = BigInt(
      this._currentUser?.ProfileEntryResponse?.DAOCoinEntry?.CoinsInCirculationNanos.toString() ?? 0,
    );
    if (
      tokensInCirculation === BigInt(0) &&
      this._currentUser?.ProfileEntryResponse?.DAOCoinEntry?.TransferRestrictionStatus !== 'profile_owner_only'
    ) {
      // TODO: fix this
      // @ts-ignore
      promises.push(this.deso.changeMyTokenTransferRestrictionStatus('profile_owner_only'));
    }

    return Promise.all(promises).then(([res]) => res);
  }
}
