'use client';

import React, { createContext, useContext, useState, useEffect } from 'react';
import { LOCAL_STORAGE_KEYS, THEME_OPTIONS } from '../utils/constants';

type Theme = (typeof THEME_OPTIONS)[keyof typeof THEME_OPTIONS];

interface ThemeContextType {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  darkTheme: boolean;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useState<Theme>(THEME_OPTIONS.modernDarkMode);

  useEffect(() => {
    const storedTheme = localStorage.getItem(LOCAL_STORAGE_KEYS.theme) as Theme;
    if (storedTheme && Object.values(THEME_OPTIONS).includes(storedTheme)) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.theme, theme);
    document.documentElement.className = theme;
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        darkTheme: [
          THEME_OPTIONS.darkMode,
          THEME_OPTIONS.modernDarkMode,
          THEME_OPTIONS.paperDarkMode,
          THEME_OPTIONS.radarDarkMode,
          THEME_OPTIONS.radarLightMode,
        ].includes(theme),
      }}
    >
      {(theme === THEME_OPTIONS.radarDarkMode || theme === THEME_OPTIONS.radarLightMode) && (
        <>
          <div className="lines"></div>
          <div className="noise"></div>
          <div className="overlay"></div>
        </>
      )}
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}
