import { useToast } from 'components/hooks/use-toast';
import { IoCheckmarkCircleOutline, IoCloseCircleOutline, IoTimeOutline, IoWarningOutline } from 'react-icons/io5';
import { useEffect } from 'react';
import { RouteLink } from './RouteLink';
import { DESO_EXPLORER_URL } from 'constants/AppConstants';

/**
 * Global toast component. To render a toast from any component, use useToast hook:
 * @example
 * ```js
 * import { useToast } from 'components/hooks/useToast';
 *
 * function SomeComponent(...) {
 *   const toast = useToast();
 *
 *   return <button onClick={() => {
 *     toast.show({ message: 'you did it!', type: 'success'});
 *   }}>Show Toast</button>
 * }
 * ```
 */
export interface ToastProps {
  id?: string;
  message: string | JSX.Element;
  type: 'success' | 'error' | 'warning' | 'waiting';
  link?: string;
  manualID?: string;
  sticky?: boolean;
}

export type ToastShowProps = Pick<ToastProps, 'message' | 'type' | 'manualID' | 'sticky'>;
export type ToastCloseProps = Pick<ToastProps, 'manualID'>;

export function ViewTransactionLink({ txnHashHex }: { txnHashHex: string }) {
  return (
    <div className="text-xs m-0 mt-4 !ml-0 underline underline-offset-4 text-muted-foreground">
      <RouteLink to={`${DESO_EXPLORER_URL}/txn/${txnHashHex}`} target="_blank">
        View Transaction
      </RouteLink>
    </div>
  );
}

export interface ToastShow {
  show: ({ message, type, manualID, sticky }: ToastShowProps) => void;
  removeToastWithManualID: ({ manualID }: ToastCloseProps) => void;
  hasManualID: ({ manualID }: ToastCloseProps) => Promise<boolean>;
}

export function Toast({ message, type, link = '', sticky = true }: Omit<ToastProps, 'id' | 'manualID'>) {
  const { toast } = useToast();

  useEffect(() => {
    let Icon;
    switch (type) {
      case 'error':
        Icon = IoCloseCircleOutline;
        break;
      case 'warning':
        Icon = IoWarningOutline;
        break;
      case 'success':
        Icon = IoCheckmarkCircleOutline;
        break;
      case 'waiting':
        Icon = IoTimeOutline;
        break;
    }

    const content = (
      <div className="flex items-start gap-2">
        {type !== 'waiting' && <Icon className="h-5 w-5" />}
        {type === 'waiting' && <IoTimeOutline className="h-5 w-5 animate-spin" />}
        <div>{message}</div>
      </div>
    );

    const description = link ? (
      <a href={link} target="_blank" rel="noreferrer" className="text-blue-500 hover:underline">
        {content}
      </a>
    ) : (
      content
    );

    toast({
      title: type,
      description,
      duration: sticky ? undefined : 3000,
      variant: type,
    });
  }, [message, type, link, sticky, toast]);

  return null;
}
