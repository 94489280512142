import { Avatar } from 'components/core/Avatar';
import { Button } from 'components/shadcn/ui/button';
import { openfund, deso } from 'services';
import { useToast } from 'hooks/useToast';
import { NavLink, useNavigate } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { useContext, useState, Fragment, useEffect } from 'react';
import { OpenfundContext } from 'contexts/Openfund';
import { getErrorMsg } from 'utils/getErrorMsg';
import { LoadingSpinner } from 'components/core/LoadingSpinner';
import {
  IoPersonAddOutline,
  IoPowerOutline,
  IoSettingsOutline,
  IoSwapVerticalSharp,
  IoWalletOutline,
} from 'react-icons/io5';
import { classNames } from 'utils/classNames';
import { v4 as uuid } from 'uuid';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/shadcn/ui/dropdown-menu';
import LoginModal from '../core/LoginModal';

const MENU_TRIGGER_ID = uuid();
const COMMON_MENU_ITEM_CLASSES = 'flex items-center w-full px-6 lg:px-4 py-2';
const COMMON_MENU_ITEM_ACTIVE_CLASSES = 'bg-gray-eee text-black';
const getMenuItemClassNames = (active: boolean) =>
  classNames(COMMON_MENU_ITEM_CLASSES, active && COMMON_MENU_ITEM_ACTIVE_CLASSES);

export function ProfileNavItem({ className }: React.HTMLAttributes<HTMLElement>) {
  const dropdownTriggerId = 'site-nave-profile-dropdown-trigger';
  const loginButtonId = 'site-nav-login-btn';
  const { currentUser, setCurrentUser } = useContext(OpenfundContext);
  const setFocusToDropdownTrigger = () => setTimeout(() => document.getElementById(dropdownTriggerId)?.focus(), 1);
  const toast = useToast();
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [mailToAttr, setMailToAttr] = useState<string>(getHelpMailToAttr());

  const login = async () => {
    try {
      const users = await openfund.login(() => setIsPending(true));
      setCurrentUser(users);
      setFocusToDropdownTrigger();
    } catch (e) {
      toast.show({
        message: getErrorMsg(e),
        type: 'error',
      });
    }
    setIsPending(false);
  };

  function getHelpMailToAttr(): string {
    const bodyContent = currentUser?.PublicKeyBase58Check
      ? encodeURIComponent(
          `The below information helps support address your case.\nMy public key: ${currentUser.PublicKeyBase58Check}`,
        )
      : '';
    return `mailto:support@deso.org${bodyContent ? `?body=${bodyContent}` : ''}`;
  }

  useEffect(() => {
    setMailToAttr(getHelpMailToAttr());
  }, [currentUser?.PublicKeyBase58Check]);

  if (isPending) {
    return (
      <div className={className}>
        <LoadingSpinner className="h-8 w-8" />
      </div>
    );
  }

  if (!currentUser) {
    return (
      <LoginModal
        onClick={async (closeModal) => {
          login();
          closeModal();
        }}
      >
        <Button id={loginButtonId} className={className} variant="outline">
          Login
        </Button>
      </LoginModal>
    );
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div id={MENU_TRIGGER_ID} className="cursor-pointer border rounded-full">
            {currentUser?.ProfileEntryResponse ? (
              <Avatar
                className="bg-gray-faint min-h-[38px] min-w-[38px] rounded-full border"
                src={deso.profilePicUrl(currentUser.PublicKeyBase58Check)}
                alt={currentUser.ProfileEntryResponse.Username}
              />
            ) : (
              <Avatar alt="Anonymous User" size="sm" className="min-h-[38px] min-w-[38px] rounded-full border" />
            )}
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-60">
          <div className="text-gray-dark">
            <DropdownMenuItem
              onClick={() => {
                if (currentUser.isProjectOwner && currentUser.ProfileEntryResponse?.Username) {
                  navigate(Routes.fund(currentUser.ProfileEntryResponse?.Username));
                } else if (currentUser.ProfileEntryResponse) {
                  navigate(Routes.profile(currentUser.ProfileEntryResponse.Username));
                } else {
                  navigate(Routes.settingsProfile());
                }
              }}
            >
              <Avatar
                src={deso.profilePicUrl(currentUser.PublicKeyBase58Check)}
                size="md"
                className="rounded-full border"
              />
              <div className="ml-4 overflow-hidden text-left">
                <div
                  className="text-md font-bold text-muted-foreground truncate"
                  title={currentUser.ProfileEntryResponse?.Username ?? currentUser.PublicKeyBase58Check}
                >
                  {currentUser.ProfileEntryResponse?.Username ?? currentUser.PublicKeyBase58Check}
                </div>
                <div className="text-sm">{currentUser.ProfileEntryResponse ? 'View profile' : 'Update profile'}</div>
              </div>
            </DropdownMenuItem>

            <DropdownMenuSeparator />

            <DropdownMenuItem asChild>
              <NavLink to={Routes.settingsProfile()}>
                <IoSettingsOutline className="mr-2 text-muted text-lg" /> My Settings
              </NavLink>
            </DropdownMenuItem>

            <DropdownMenuItem asChild>
              <NavLink to={Routes.wallet()}>
                <IoWalletOutline className="mr-2 text-muted text-lg" /> My Wallet
              </NavLink>
            </DropdownMenuItem>

            <DropdownMenuSeparator />

            <DropdownMenuItem onClick={login}>
              <IoSwapVerticalSharp className="mr-2 text-muted text-lg" /> Switch Account
            </DropdownMenuItem>

            <DropdownMenuItem onClick={login}>
              <IoPersonAddOutline className="mr-2 text-muted text-lg" /> Create Account
            </DropdownMenuItem>

            <DropdownMenuItem
              onClick={async () => {
                try {
                  await openfund.logout(() => setIsPending(true));
                  setCurrentUser(null);
                  setTimeout(() => document.getElementById(loginButtonId)?.focus(), 1);
                } catch (e) {
                  toast.show({
                    message: getErrorMsg(e),
                    type: 'error',
                  });
                }
                setIsPending(false);
              }}
            >
              <IoPowerOutline className="mr-2 text-muted text-lg" /> Logout
            </DropdownMenuItem>
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
