import { AxiosInstance } from 'axios';
import { FOCUS_TOKEN_PUBLIC_KEY } from 'constants/AppConstants';
import {
  BalanceEntryResponse,
  BlockPublicKeyResponse,
  CreateLikeStatelessRequest,
  DAOCoinLimitOrderEntryResponse,
  DAOCoinLimitOrderWithCancelOrderIDRequest,
  DAOCoinRequest,
  GetExchangeRateResponse,
  GetFollowsResponse,
  GetHodlersForPublicKeyResponse,
  GetNFTsCreatedByPublicKeyRequest,
  GetNFTsCreatedByPublicKeyResponse,
  GetPostsForPublicKeyResponse,
  GetSinglePostResponse,
  GetSingleProfileResponse,
  GetUsersResponse,
  GetUsersStatelessRequest,
  HodlersSortType,
  HotFeedPageRequest,
  HotFeedPageResponse,
  PostEntryResponse,
  ProfileEntryResponse,
  SendDiamondsRequest,
  SubmitPostRequest,
  UploadVideoV2Response,
  blockPublicKey,
  buildProfilePictureUrl,
  burnDeSoToken,
  cancelDeSoTokenLimitOrder,
  disableMintingDeSoToken,
  getFollowersForUser,
  getHodlersForUser,
  getHotFeed,
  getIsHodling,
  getPostsForUser,
  getPostsStateless,
  getProfiles,
  getSinglePost,
  getSingleProfile,
  getTransaction,
  getUsersStateless,
  identity,
  mintDeSoToken,
  sendDeso,
  sendDiamonds,
  submitPost,
  transferDeSoToken,
  updateDeSoTokenTransferRestrictionStatus,
  updateFollowingStatus,
  updateLikeStatus,
  updateProfile,
  uploadImage,
  uploadVideo,
  createDeSoTokenMarketOrderWithFee,
  DeSoTokenMarketOrderWithFeeRequest,
  SubmitTransactionAtomicResponse,
} from 'deso-protocol';
import orderBy from 'lodash/orderBy';

export const ProjectPublicKeysPurchasedKey = 'DAOPublicKeysPurchased';

export type GetExchangeRateUpdatedResponse = GetExchangeRateResponse & {
  USDCentsPerDeSoCoinbase: number;
};

export interface GetDaoCoinMarketFeesResponse {
  FeeBasisPointsByPublicKey: Record<string, number>;
}

export enum OperationTypeWithFee {
  BID = 'BID',
  ASK = 'ASK',
}

export interface TokenLimitOrderEntryResponse {
  TransactorPublicKeyBase58Check: string;
  BuyingDAOCoinCreatorPublicKeyBase58Check: string;
  SellingDAOCoinCreatorPublicKeyBase58Check: string;
  Price: string;
  Quantity: string;
  ExchangeRateCoinsToSellPerCoinToBuy: number;
  QuantityToFill: number;
  OperationType: string;
  OrderID: string;
}

export interface GetQuoteCurrencyPriceInUsdResponse {
  BestAsk: string;
  BestBid: string;
  MidPrice: string;
}

export interface BaseCurrencyPriceEntry {
  QuoteCurrencyPriceInUsd: number;
  MidPriceInQuoteCurrency: number;
  MidPriceInUsd: number;
  BestAskInQuoteCurrency: number;
  BestAskInUsd: number;
  BestBidInQuoteCurrency: number;
  BestBidInUsd: number;
  ReceiveAmountInQuoteCurrency: string;
  ReceiveAmountInUsd: string;
  ExecutionAmountInBaseCurrency: string;
  ExecutionPriceInQuoteCurrency: string;
  ExecutionPriceInUsd: string;
}

export interface BaseCurrencyPriceResponse {
  Entries: Array<BaseCurrencyPriceEntry>;
}

export class Deso {
  private transactionQueue: Function[] = [];
  private transactionProcessing: boolean = false;
  private axios: AxiosInstance;
  private getUsersCache: Record<string, GetUsersResponse> = {};
  private singleProfileResponseCache: Record<string, GetSingleProfileResponse> = {};

  get cacheState() {
    return {
      getUsersStateless: this.getUsersCache,
      getSingleProfile: this.singleProfileResponseCache,
    };
  }

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async getProfileByUsername(
    username: string,
    errorOnMissing = true,
    refreshCache = false,
  ): Promise<GetSingleProfileResponse> {
    if (!refreshCache && this.singleProfileResponseCache[username] !== undefined) {
      return this.singleProfileResponseCache[username];
    }

    const res = await getSingleProfile({
      PublicKeyBase58Check: '',
      Username: username,
      NoErrorOnMissing: !errorOnMissing,
    });

    if (res?.Profile?.Username) {
      this.singleProfileResponseCache[res.Profile.Username] = res;
    }
    // Hack to patch up ExtraData when it doesn't get set. This is mainly needed
    // prior to the Openfund hard fork.
    if (res && res.Profile && !res.Profile.ExtraData) {
      res.Profile.ExtraData = {};
    }

    return res;
  }

  async getProfilesByUsername(
    UsernamePrefix: string,
    ReaderPublicKeyBase58Check?: string,
    NumToFetch = 5,
  ): Promise<Array<ProfileEntryResponse>> {
    const normalizedPrefix = UsernamePrefix.toLowerCase().trim();

    let usersList = await getProfiles({
      PublicKeyBase58Check: '',
      Username: '',
      UsernamePrefix: normalizedPrefix,
      Description: '',
      // TODO: should this be set to something?
      OrderBy: '',
      NumToFetch,
      ReaderPublicKeyBase58Check: ReaderPublicKeyBase58Check || '',
      ModerationType: '',
      FetchUsersThatHODL: false,
      AddGlobalFeedBool: false,
    }).then((data) => data.ProfilesFound || []);

    const hasExactMatch = usersList.some((e) => e.Username.toLowerCase() === normalizedPrefix);

    if (hasExactMatch) {
      usersList = orderBy(usersList, [(user) => (user.Username.toLowerCase() === normalizedPrefix ? 0 : 1)]);
    } else {
      const exactUserMatch = await this.getProfileByUsername(normalizedPrefix)
        .then((e) => e.Profile)
        .catch(() => null);

      if (exactUserMatch) {
        usersList = [exactUserMatch, ...usersList];
      }
    }

    return usersList;
  }

  async getUsers(
    publicKeys: readonly string[],
    {
      SkipForLeaderboard = true,
      refreshCache = false,
      IncludeBalance = true,
    }: Partial<GetUsersStatelessRequest> & { SkipHodlings?: boolean; refreshCache?: boolean } = {},
  ) {
    const cacheKey = `${publicKeys.join(':')}${JSON.stringify({
      SkipForLeaderboard,
      IncludeBalance,
    })}`;

    if (this.getUsersCache[cacheKey] && !refreshCache) {
      return this.getUsersCache[cacheKey];
    }

    const res = await getUsersStateless({
      SkipForLeaderboard,
      PublicKeysBase58Check: publicKeys.slice(),
      IncludeBalance,
    });

    this.getUsersCache[cacheKey] = res;

    this.getUsersCache[cacheKey].UserList?.forEach((user) => {
      this.singleProfileResponseCache[user.PublicKeyBase58Check] = {
        IsBlacklisted: user.IsBlacklisted,
        IsGraylisted: user.IsGraylisted,
        Profile: user.ProfileEntryResponse,
      };
    });

    return this.getUsersCache[cacheKey];
  }

  async uploadImage(file: File): Promise<string> {
    const state = await identity.snapshot();

    if (!state.currentUser) {
      throw new Error('Cannot upload image without a logged in user.');
    }

    const res = await uploadImage({
      UserPublicKeyBase58Check: state.currentUser.publicKey,
      file,
    });

    return res.ImageURL;
  }

  async uploadVideo(file: File): Promise<UploadVideoV2Response> {
    const state = await identity.snapshot();

    if (!state.currentUser) {
      throw new Error('Cannot upload image without a logged in user.');
    }

    return uploadVideo({
      file: file,
      UserPublicKeyBase58Check: state.currentUser.publicKey,
    });
  }

  async getFollows(
    Username: string,
    GetEntriesFollowingUsername: boolean,
    LastPublicKeyBase58Check: string,
    NumToFetch: number = 20,
  ): Promise<GetFollowsResponse> {
    return getFollowersForUser({
      Username,
      GetEntriesFollowingUsername,
      LastPublicKeyBase58Check,
      NumToFetch,
    });
  }

  async addProjectPurchasedToProfile(purchasedProjectPublicKey: string) {
    if (purchasedProjectPublicKey === 'DESO') {
      return null;
    }

    const state = await identity.snapshot();

    if (!state.currentUser) {
      throw new Error('Cannot update profile without a logged in user.');
    }

    const userStatelessResponse = await this.getUsers([state.currentUser.publicKey]);
    if (!userStatelessResponse?.UserList?.length) {
      return null;
    }
    const user = userStatelessResponse.UserList[0];
    if (!user?.ProfileEntryResponse?.Username) {
      return null;
    }
    let projectsPurchased = '';
    if (user.ProfileEntryResponse.ExtraData && user.ProfileEntryResponse.ExtraData[ProjectPublicKeysPurchasedKey]) {
      projectsPurchased = user.ProfileEntryResponse.ExtraData[ProjectPublicKeysPurchasedKey];
    }
    // This public key has already been purchased, don't need to readd to list.
    if (projectsPurchased.split(',').includes(purchasedProjectPublicKey)) {
      return null;
    }

    projectsPurchased = projectsPurchased.length
      ? `${projectsPurchased},${purchasedProjectPublicKey}`
      : purchasedProjectPublicKey;

    return updateProfile({
      NewCreatorBasisPoints: user.ProfileEntryResponse.CoinEntry?.CreatorBasisPoints ?? 0,
      NewStakeMultipleBasisPoints: 1.25 * 100 * 100,
      ExtraData: {
        [ProjectPublicKeysPurchasedKey]: projectsPurchased,
      },
      UpdaterPublicKeyBase58Check: state.currentUser.publicKey,
      ProfilePublicKeyBase58Check: state.currentUser.publicKey,
    });
  }

  async blockUser(BlockPublicKeyBase58Check: string, Unblock: boolean = false): Promise<BlockPublicKeyResponse> {
    const state = await identity.snapshot();

    if (!state.currentUser) {
      throw new Error('Cannot block user without a logged in user.');
    }

    return blockPublicKey({
      PublicKeyBase58Check: state.currentUser.publicKey,
      BlockPublicKeyBase58Check,
      Unblock,
    });
  }

  profilePicUrl(profilePublicKey: string = '') {
    return buildProfilePictureUrl(profilePublicKey, {
      fallbackImageUrl: `${window.location.origin}/images/ghost-profile-image.png`,
    });
  }

  follow(FollowedPublicKeyBase58Check: string) {
    // Add to transaction queue
    // During callback, recursively call next function
    const funcParam = () => this.toggleFollow(FollowedPublicKeyBase58Check, false);
    return this.queueTransaction(funcParam);
  }

  // This function gets called after a transaction gets confirmed.
  // It checks to see if there are any outstanding transactions, and calls them.
  async queuedTxnCallback(comp: any) {
    if (comp.transactionQueue.length > 0) {
      setTimeout(async () => {
        const nextFunction = comp.transactionQueue.pop();
        const txn = await nextFunction();
        const txnHashHex = txn.TxnHashHex;
        return comp.node.waitForTransaction(txnHashHex, comp.queuedTxnCallback, comp.queuedTxnErrorCallback, comp);
      }, 200);
    } else {
      comp.transactionProcessing = false;
    }
  }

  queuedTxnErrorCallback() {
    console.log('Error during callback');
  }

  async queueTransaction(f: Function) {
    // If there isn't anything currently processing, we execute the function and await the completion
    if (!this.transactionProcessing) {
      this.transactionProcessing = true;
      const txn = await f();
      const txnHashHex = txn.TxnHashHex;
      this.waitForTransaction(txnHashHex, this.queuedTxnCallback, this.queuedTxnErrorCallback, this);
    } else {
      // If there is something currently being waited for, we push the next transaction to the queue.
      this.transactionQueue.push(f);
    }
  }

  unfollow(FollowedPublicKeyBase58Check: string) {
    return this.toggleFollow(FollowedPublicKeyBase58Check, true);
  }

  async getPostsForPublicKey(
    publicKey: string,
    lastPostHashHex: string,
    numToFetch: number,
    username: string = '',
  ): Promise<GetPostsForPublicKeyResponse> {
    const state = await identity.snapshot();

    return getPostsForUser({
      PublicKeyBase58Check: publicKey,
      Username: username,
      LastPostHashHex: lastPostHashHex,
      NumToFetch: numToFetch,
      ReaderPublicKeyBase58Check: state?.currentUser?.publicKey,
    });
  }

  async getSinglePost(
    PostHashHex: string,
    options: {
      FetchParents?: boolean;
      CommentOffset?: number;
      CommentLimit?: number;
      ThreadLevelLimit?: number;
      ThreadLeafLimit?: number;
      LoadAuthorThread?: boolean;
      AddGlobalFeedBool?: boolean;
    } = {
      FetchParents: false,
      CommentOffset: 0,
      CommentLimit: 10,
      ThreadLevelLimit: 0,
      ThreadLeafLimit: 0,
      LoadAuthorThread: false,
      AddGlobalFeedBool: false,
    },
  ): Promise<GetSinglePostResponse> {
    const state = await identity.snapshot();

    return getSinglePost({
      PostHashHex,
      ReaderPublicKeyBase58Check: state?.currentUser?.publicKey,
      ...options,
    });
  }

  async getHotFeed(
    seenPosts: string[] = [],
    responseLimit: number = 20,
    tag: string = '',
    sortByNew: boolean = false,
  ): Promise<HotFeedPageResponse> {
    const state = await identity.snapshot();

    const request: HotFeedPageRequest = {
      ReaderPublicKeyBase58Check: state.currentUser?.publicKey ?? '',
      SeenPosts: seenPosts,
      Tag: tag,
      SortByNew: sortByNew,
      ResponseLimit: responseLimit,
    };
    return getHotFeed(request).then((res) => {
      res.HotFeedPage = res.HotFeedPage?.filter(({ IsHidden }) => !IsHidden) ?? null;
      return res;
    });
  }

  async sendDeso(SenderPublicKeyBase58Check: string, RecipientPublicKeyOrUsername: string, AmountNanos: number) {
    return sendDeso({
      SenderPublicKeyBase58Check,
      RecipientPublicKeyOrUsername,
      AmountNanos,
    });
  }

  async createPost(params: SubmitPostRequest) {
    return submitPost(params);
  }

  async likePost(params: CreateLikeStatelessRequest) {
    return updateLikeStatus(params);
  }

  async diamondPost(params: SendDiamondsRequest) {
    return sendDiamonds(params);
  }

  async mintMyTokens(tokensToMintBaseUnits: string) {
    const state = await identity.snapshot();

    if (!state.currentUser) {
      throw new Error('Cannot mint tokens without a logged in user.');
    }

    return mintDeSoToken({
      CoinsToMintNanos: tokensToMintBaseUnits,
      UpdaterPublicKeyBase58Check: state.currentUser.publicKey,
      ProfilePublicKeyBase58CheckOrUsername: state.currentUser.publicKey,
    });
  }

  async burnTokens(tokensToBurnBaseUnits: string, projectPublicKey: string) {
    const state = await identity.snapshot();

    if (!state.currentUser) {
      throw new Error('Cannot burn tokens without a logged in user.');
    }

    return burnDeSoToken({
      CoinsToBurnNanos: tokensToBurnBaseUnits,
      UpdaterPublicKeyBase58Check: state.currentUser.publicKey,
      ProfilePublicKeyBase58CheckOrUsername: projectPublicKey,
    });
  }

  async changeMyTokenTransferRestrictionStatus(TransferRestrictionStatus: DAOCoinRequest['TransferRestrictionStatus']) {
    const state = await identity.snapshot();

    if (!state.currentUser) {
      throw new Error('Cannot change token transfer restriction status without a logged in user.');
    }

    return updateDeSoTokenTransferRestrictionStatus({
      TransferRestrictionStatus,
      UpdaterPublicKeyBase58Check: state.currentUser.publicKey,
      ProfilePublicKeyBase58CheckOrUsername: state.currentUser.publicKey,
    });
  }

  async disableMintingMyToken() {
    const state = await identity.snapshot();

    if (!state.currentUser) {
      throw new Error('Cannot disable minting without a logged in user.');
    }

    return disableMintingDeSoToken({
      UpdaterPublicKeyBase58Check: state.currentUser.publicKey,
      ProfilePublicKeyBase58CheckOrUsername: state.currentUser.publicKey,
    });
  }

  async transferTokens(projectIdentifier: string, receiverIdentifier: string, amountTokenBaseUnitsHex: string) {
    const state = await identity.snapshot();

    if (!state.currentUser) {
      throw new Error('Cannot transfer tokens without a logged in user.');
    }

    return transferDeSoToken(
      {
        SenderPublicKeyBase58Check: state.currentUser.publicKey,
        ProfilePublicKeyBase58CheckOrUsername: projectIdentifier,
        ReceiverPublicKeyBase58CheckOrUsername: receiverIdentifier,
        DAOCoinToTransferNanos: amountTokenBaseUnitsHex,
      },
      { checkPermissions: false },
    );
  }

  async createTokenMarketOrderWithFee(
    params: Pick<
      DeSoTokenMarketOrderWithFeeRequest,
      | 'OperationType'
      | 'QuoteCurrencyPublicKeyBase58Check'
      | 'BaseCurrencyPublicKeyBase58Check'
      | 'Quantity'
      | 'FillType'
      | 'QuantityCurrencyType'
      | 'Price'
      | 'PriceCurrencyType'
    >,
  ) {
    const { currentUser } = await identity.snapshot();

    if (!currentUser) {
      throw new Error('Cannot create a market order without a logged in user.');
    }

    const { constructedTransactionResponse, submittedTransactionResponse } = await createDeSoTokenMarketOrderWithFee({
      ...params,
      TransactorPublicKeyBase58Check: currentUser.publicKey,
      MinFeeRateNanosPerKB: 0,
      TransactionFees: null,
      OptionalPrecedingTransactions: null,
    });

    return {
      constructedTransactionResponse,
      submittedTransactionResponse: submittedTransactionResponse as SubmitTransactionAtomicResponse,
    };
  }

  async simulateTokenOrderWithFee(
    params: Pick<
      DeSoTokenMarketOrderWithFeeRequest,
      | 'OperationType'
      | 'QuoteCurrencyPublicKeyBase58Check'
      | 'BaseCurrencyPublicKeyBase58Check'
      | 'Quantity'
      | 'FillType'
      | 'QuantityCurrencyType'
      | 'Price'
      | 'PriceCurrencyType'
    >,
  ) {
    const { currentUser } = await identity.snapshot();

    if (!currentUser) {
      throw new Error('Cannot create a market order without a logged in user.');
    }

    const { constructedTransactionResponse } = await createDeSoTokenMarketOrderWithFee(
      {
        ...params,
        TransactorPublicKeyBase58Check: currentUser.publicKey,
        MinFeeRateNanosPerKB: 0,
        TransactionFees: null,
        OptionalPrecedingTransactions: null,
      },
      {
        broadcast: false,
        checkPermissions: false,
      },
    );

    return constructedTransactionResponse;
  }

  async cancelTokenLimitOrder(params: Pick<DAOCoinLimitOrderWithCancelOrderIDRequest, 'CancelOrderID'>) {
    const state = await identity.snapshot();

    if (!state.currentUser) {
      throw new Error('Cannot cancel a limit order without a logged in user.');
    }

    return cancelDeSoTokenLimitOrder({
      TransactorPublicKeyBase58Check: state.currentUser.publicKey,
      CancelOrderID: params.CancelOrderID,
    });
  }

  async getFollowerFeed(
    ReaderPublicKeyBase58Check: string | undefined,
    lastSeenPostHashHex: string = '',
  ): Promise<PostEntryResponse[]> {
    return getPostsStateless({
      PostHashHex: lastSeenPostHashHex,
      ReaderPublicKeyBase58Check,
      OrderBy: 'newest',
      StartTstampSecs: undefined,
      PostContent: '',
      NumToFetch: 25,
      FetchSubcomments: false,
      GetPostsForFollowFeed: true,
      GetPostsForGlobalWhitelist: true,
      GetPostsByDESO: false,
      MediaRequired: false,
      PostsByDESOMinutesLookback: 0,
      AddGlobalFeedBool: true,
    }).then((res) => {
      return (res.PostsFound as PostEntryResponse[])?.filter(({ IsHidden }) => !IsHidden) ?? [];
    });
  }

  async getFeedForUser(
    PublicKeyBase58Check: string,
    ReaderPublicKeyBase58Check: string,
    lastSeenPostHashHex: string = '',
  ): Promise<PostEntryResponse[]> {
    return getPostsForUser({
      PublicKeyBase58Check,
      ReaderPublicKeyBase58Check,
      LastPostHashHex: lastSeenPostHashHex,
      NumToFetch: 25,
      MediaRequired: false,
    }).then((res) => {
      return (res.Posts as PostEntryResponse[])?.filter(({ IsHidden }) => !IsHidden) ?? [];
    });
  }

  async getGlobalActivityFeed(
    ReaderPublicKeyBase58Check?: string,
    lastSeenPostHashHex: string = '',
  ): Promise<PostEntryResponse[]> {
    return getPostsStateless({
      PostHashHex: lastSeenPostHashHex,
      ReaderPublicKeyBase58Check: ReaderPublicKeyBase58Check,
      OrderBy: 'newest',
      StartTstampSecs: undefined,
      PostContent: '',
      NumToFetch: 50,
      FetchSubcomments: false,
      GetPostsForFollowFeed: false,
      GetPostsForGlobalWhitelist: false,
      GetPostsByDESO: false,
      MediaRequired: false,
      PostsByDESOMinutesLookback: 0,
      AddGlobalFeedBool: false,
    }).then((res) => {
      return (res.PostsFound as PostEntryResponse[])?.filter(({ IsHidden }) => !IsHidden) ?? [];
    });
  }

  // NOTE: We *might* need to revisit this in the future for pagination. For now, we just
  // fetch everything.
  async getAllProjectHoldings(PublicKeyBase58Check: string): Promise<GetHodlersForPublicKeyResponse> {
    return getHodlersForUser({
      FetchAll: true,
      FetchHodlings: true,
      IsDAOCoin: true,
      PublicKeyBase58Check,
    });
  }

  async getProjectHolders(PublicKeyBase58Check: string): Promise<GetHodlersForPublicKeyResponse> {
    return await getHodlersForUser({
      FetchAll: true,
      FetchHodlings: false,
      IsDAOCoin: true,
      PublicKeyBase58Check,
    });
  }

  async getProjectHoldings(
    Username: string,
    SortType: HodlersSortType = HodlersSortType.wealth,
    NumToFetch: number = 20,
    FetchAll: boolean = false,
  ): Promise<GetHodlersForPublicKeyResponse> {
    return await getHodlersForUser({
      FetchAll,
      FetchHodlings: true,
      NumToFetch,
      IsDAOCoin: true,
      Username,
      SortType,
    });
  }

  async getProjectHoldingsByPublicKey(
    PublicKey: string,
    SortType: HodlersSortType = HodlersSortType.wealth,
    NumToFetch: number = 20,
    FetchAll: boolean = false,
  ): Promise<GetHodlersForPublicKeyResponse> {
    return await getHodlersForUser({
      FetchAll,
      FetchHodlings: true,
      NumToFetch,
      IsDAOCoin: true,
      PublicKeyBase58Check: PublicKey,
      SortType,
    });
  }

  async getProjectHolding(
    PublicKeyBase58Check: string,
    IsHodlingPublicKeyBase58Check: string,
  ): Promise<BalanceEntryResponse | null> {
    return await getIsHodling({
      PublicKeyBase58Check,
      IsHodlingPublicKeyBase58Check,
      IsDAOCoin: true,
    }).then((e) => e.BalanceEntry);
  }

  waitForTransaction(
    waitTxn: string = '',
    successCallback: (comp: any) => void = () => {},
    errorCallback: (comp: any) => void = () => {},
    comp: any = '',
  ) {
    // If we have a transaction to wait for, we do a GetTxn call for a maximum of 10s (250ms * 40).
    // There is a success and error callback so that the caller gets feedback on the polling.
    if (waitTxn !== '') {
      let attempts = 0;
      let numTries = 160;
      let timeoutMillis = 750;
      // Set an interval to repeat
      let interval = setInterval(() => {
        if (attempts >= numTries) {
          errorCallback(comp);
          clearInterval(interval);
        }
        getTransaction({ TxnHashHex: waitTxn })
          .then(
            (res: any) => {
              if (!res.TxnFound) {
                return;
              }
              clearInterval(interval);
              successCallback(comp);
            },
            () => {
              clearInterval(interval);
              errorCallback(comp);
            },
          )
          .finally(() => attempts++);
      }, timeoutMillis) as any;
    }
  }

  async getIngressCookie(): Promise<{ CookieValue: string }> {
    // This fails for localhost so we just skip it.
    if (window.location.hostname === 'localhost') {
      return { CookieValue: '' };
    }

    delete this.axios.defaults.headers.common['Cookie'];
    const { data } = await this.axios.get('get-ingress-cookie');
    return data;
  }

  async getHodlersCountForPublicKeys(
    PublicKeysBase58Check: string[],
    IsDAOCoin: boolean,
  ): Promise<{ [publicKey: string]: number }> {
    const { data } = await this.axios.post('get-hodlers-count-for-public-keys', {
      PublicKeysBase58Check,
      IsDAOCoin,
    });
    return data;
  }

  async getAllTokenLimitOrders(
    projectPublicKey: string,
    daoCoinPublicKey: string,
  ): Promise<DAOCoinLimitOrderEntryResponse[]> {
    const { data } = await this.axios.post('get-dao-coin-limit-orders', {
      DAOCoin1CreatorPublicKeyBase58Check: projectPublicKey,
      DAOCoin2CreatorPublicKeyBase58Check: daoCoinPublicKey,
    });

    return data.Orders || [];
  }

  async getAllTransactorTokenLimitOrders(publicKeyBase58Check: string): Promise<DAOCoinLimitOrderEntryResponse[]> {
    const { data } = await this.axios.post('get-transactor-dao-coin-limit-orders', {
      TransactorPublicKeyBase58Check: publicKeyBase58Check,
    });

    return data.Orders;
  }

  async getFollowerCountByUsername(Username: string): Promise<number> {
    return await getFollowersForUser({
      Username,
      GetEntriesFollowingUsername: true,
      LastPublicKeyBase58Check: '',
      NumToFetch: 0,
      PublicKeyBase58Check: '',
    }).then((e) => e.NumFollowers);
  }

  async getFollowingCountByUsername(Username: string): Promise<number> {
    return getFollowersForUser({
      Username,
      GetEntriesFollowingUsername: false,
      LastPublicKeyBase58Check: '',
      NumToFetch: 0,
      PublicKeyBase58Check: '',
    }).then((e) => e.NumFollowers);
  }

  async getNFTsCreatedByUser(params: GetNFTsCreatedByPublicKeyRequest): Promise<GetNFTsCreatedByPublicKeyResponse> {
    const { data } = await this.axios.post('get-nfts-created-by-public-key', params);
    return data;
  }

  async getVideoStatus(videoId: string): Promise<{ status: { phase: string } }> {
    return this.axios.get(`https://media.deso.org/api/v0/get-video-status/${videoId}`).then((res) => res.data);
  }

  async getBaseCurrencyPrice(
    priceEntries: Array<{
      BaseCurrencyPublicKeyBase58Check: string;
      BaseCurrencyQuantityToSell: number;
      QuoteCurrencyPublicKeyBase58Check: string;
    }>,
  ) {
    const response = await this.axios.post<BaseCurrencyPriceResponse>(`get-base-currency-price`, {
      Entries: priceEntries,
    });
    return response.data.Entries;
  }

  async getDaoCoinMarketFees(ProfilePublicKeyBase58Check: string) {
    const { data } = await this.axios.post<GetDaoCoinMarketFeesResponse>('get-dao-coin-market-fees', {
      ProfilePublicKeyBase58Check,
    });

    return data;
  }

  private async toggleFollow(FollowedPublicKeyBase58Check: string, IsUnfollow: boolean) {
    const state = await identity.snapshot();

    if (!state.currentUser) {
      throw new Error('Attempted to toggle following status but there is not a logged in user');
    }

    return updateFollowingStatus({
      IsUnfollow,
      FollowedPublicKeyBase58Check,
      FollowerPublicKeyBase58Check: state.currentUser.publicKey,
    });
  }

  async getCoinPriceInUsd(ProfilePublicKeyBase58Check: string) {
    const { data } = await this.axios.post<GetQuoteCurrencyPriceInUsdResponse>('get-quote-currency-price-in-usd', {
      QuoteCurrencyPublicKeyBase58Check: ProfilePublicKeyBase58Check,
    });

    return data;
  }

  async getTotalSupply() {
    const { data } = await this.axios.get('total-supply');

    return data;
  }
}
