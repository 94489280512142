import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { createHttpLink } from '@apollo/client/link/http';
import { GRAPHQL_URL } from '../constants/AppConstants';

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});

export const client = new ApolloClient({
  link: ApolloLink.from([httpLink]),
  cache: new InMemoryCache(),
});
